import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';

export function usePreviewUpsellSeen() {
  const [previewUpsellSeen, setPreviewUpsellSeen] = useLocalStorage(
    PREVIEW_UPSELL_LOCAL_STORAGE_KEY,
    false
  );

  const markPreviewUpsellSeen = () => {
    setPreviewUpsellSeen(true);
  };

  return { previewUpsellSeen, markPreviewUpsellSeen };
}

const PREVIEW_UPSELL_LOCAL_STORAGE_KEY = 'document-preview-upsell-seen';
