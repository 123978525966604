import { Button, Color, Flex, Grid, Tag, Text } from '@candisio/design-system';
import {
  animationVariants,
  transition,
} from 'components/NewPromotions/toolkit/animation';
import { CrossfadeImage } from 'components/NewPromotions/toolkit/CrossfadeImage';
import { PromotionCardWrapper } from 'components/NewPromotions/toolkit/PromotionCardWrapper';
import { motion } from 'framer-motion';

type NewsCardProps = {
  imageUrl: string;
  tag: string;
  tagColor?: Color;
  title: string;
  description?: string;
  buttonText: string;
  onButtonClick: () => void;
  backgroundColor: string;
};

const MotionGrid = motion(Grid);

export const NewsCard = ({
  imageUrl,
  tag,
  tagColor = 'gray',
  title,
  description,
  buttonText,
  onButtonClick,
  backgroundColor,
}: NewsCardProps) => {
  return (
    <PromotionCardWrapper>
      <Grid background={backgroundColor}>
        <CrossfadeImage src={imageUrl} />
      </Grid>
      <MotionGrid
        transition={transition}
        variants={animationVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        direction="column"
        padding="space24"
        alignContent="space-between"
        justifyItems="start"
        color="gray800">
        <Flex direction="column" gap="space4">
          <Tag color={tagColor}>{tag}</Tag>
          <Text fontSize="medium">{title}</Text>
          <Flex direction="column" fontSize="basic" color="gray600">
            <Text>{description}</Text>
          </Flex>
        </Flex>
        <Button
          color="gray"
          variant="secondary"
          icon="arrowRight"
          iconPosition="right"
          onClick={onButtonClick}>
          {buttonText}
        </Button>
      </MotionGrid>
    </PromotionCardWrapper>
  );
};
