import { Link } from '@candisio/design-system';
import { ReactNode } from 'react';

interface BasicLink {
  id?: string;
}

interface LinkWithTranslation extends BasicLink {
  translation: string;
}

interface LinkWithChildren extends BasicLink {
  children: ReactNode;
}

type IntercomLinkProps = LinkWithTranslation | LinkWithChildren;

export const IntercomLink = (props: IntercomLinkProps) => {
  const handleIntercomClick = () => {
    window.Intercom('showSpace', 'messages');
  };

  const linkText = 'children' in props ? props.children : props.translation;

  return (
    <Link
      as="button"
      icon="mail"
      id={props.id}
      iconPosition="left"
      onClick={!props.id ? handleIntercomClick : undefined}
      textAlign="left"
      external>
      {linkText}
    </Link>
  );
};
