import { Skeleton } from '@candisio/design-system';
import { WorkflowVisualization } from 'components/WorkflowVisualization/WorkflowVisualization';
import { useMonitoringActionsQuery } from 'generated-types/graphql.types';
import { MonitoringActions } from './MonitoringActions';

export interface MonitoringActionsContainerProps {
  /** Document to be fetched */
  documentId: string;
  /** Called when document should be edited */
  onEdit?: () => void;
}

/** Handles data fetching for MonitoringActions component */
export const MonitoringActionsContainer = ({
  documentId,
  onEdit,
}: MonitoringActionsContainerProps) => {
  const { data, loading } = useMonitoringActionsQuery({
    variables: { documentId },
  });

  const workflow = data?.document?.workflow;

  if (loading) return <Skeleton width="100%" height="space40" />;

  return (
    <MonitoringActions
      onEdit={onEdit}
      workflowVisualization={
        workflow ? <WorkflowVisualization workflow={workflow} /> : undefined
      }
    />
  );
};
