import {
  Filter,
  Flex,
  Table,
  Tag,
  TruncatedText,
} from '@candisio/design-system';
import { amountCellProps } from 'components/Table/Cells/Amount';
import { DateRangeFilter } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCardholderId } from 'providers/EntityLoader/EntityLoader';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { TRANSACTION_COLUMNS } from 'views/Inbox/Transactions/useTransactionListFilters';
import { AmountCellTransaction } from '../AmountCell/AmountCell';
import { DateText as DateCell } from '../DateText/DateText';
import { TransactionStatusTag } from '../TransactionStatusTag/TransactionStatusTag';
import { TypeCell } from '../TypeCell/TypeCell';
import { CategoryCell } from './CategoryCell/CategoryCell';
import { TransactionsTableToolbar } from './components/TransactionsTableStates/components/TransactionsTableToolbar';
import { GenericCell as Cell } from './GenericCell/GenericCell';
import { MerchantNameCell } from './MerchantNameCell/MerchantNameCell';
import { TransactionsTableData, TransactionsTableProps } from './types';
import { Header, getFullName, getInvoiceStatusTag } from './util';

export const TransactionsTable = ({
  onRowClick,
  data,
  selectionOptions,
  columns: shownColumns,
  filterOptions,
  defaultFilters,
  defaultSortBy,
  customEmptyState: CustomEmptyState,
  onEndReached,
  borderTopRadius,
  onSearch,
  search,
  configurations,
  onUpdateConfigurations,
  onResetTableConfigurations,
  onDownloadCSV,
  isLoading,
  isSavingConfigurations,
  isDownloadingCSV,
  downloadableTransactionsCount,
  isFilterLoading,
  rowOverlay,
  ...restProps
}: TransactionsTableProps) => {
  const [t] = useTranslation([
    LOCALE_NAME_SPACE.DOCUMENTS_TABLE,
    LOCALE_NAME_SPACE.TRANSACTIONS,
  ]);

  const { cardholderId } = useCardholderId();
  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  const defaultColumn = useMemo(
    (): Partial<Column<TransactionsTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            let pinnedOption = undefined;
            const filterOption =
              filterOptions[column.id as keyof TransactionsTableData];

            if (
              cardholderId &&
              column.id.includes(TRANSACTION_COLUMNS.cardholderName)
            ) {
              pinnedOption = {
                id: cardholderId,
                label: t('transactions:cardholderPinnedOption'),
              };
            }

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              return (
                <Filter<TransactionsTableData>
                  column={column}
                  options={filterOption.data}
                  pinnedOption={pinnedOption}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                  filterLabel={t('documents-table:filterLabel')}
                  applyFilterButton={t('documents-table:filterApply')}
                  resetFilterButton={t('documents-table:filterReset')}
                  searchFieldPlaceholder={t(
                    'documents-table:filterSearchPlaceholder'
                  )}
                />
              );
            }

            if (filterOption === true) {
              return (
                <DateRangeFilter
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
      disableFilters: !Boolean(filterOptions),
    }),
    [filterOptions, cardholderId, t]
  );

  const columns: Array<Column<TransactionsTableData>> = useMemo(() => {
    const allColumns: Array<Column<TransactionsTableData>> = [
      {
        accessor: 'type',
        disableSortBy: true,
        Cell: TypeCell,
      },
      {
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ value, row }) => {
          if (!value) {
            return null;
          }

          return (
            <TransactionStatusTag
              status={value}
              declinedReason={row.original.localizedDeclineReason}
            />
          );
        },
      },
      {
        accessor: 'merchantName',

        Cell: ({ value, row }) => (
          <MerchantNameCell
            merchantName={value ?? undefined}
            merchantLogoUrl={row.original.merchantLogoUrl}
            category={row.original.category}
          />
        ),
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'grossAmount',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: AmountCellTransaction,
        disableFilters: true,
        ...amountCellProps,
      },
      {
        accessor: 'transactionCreatedAt',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        sortType: 'datetime',
      },
      {
        accessor: 'cardRefNum',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'cardholderName',
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          return (
            <Flex alignItems="center" gap="space8">
              <AvatarWithStatusContainer
                flex="none"
                name={`${value?.firstName} ${value?.lastName}`}
                img={value?.avatarUrl ?? undefined}
                userId={value?.membershipId ?? undefined}
                size="small"
                hasTooltip={false}
              />

              <TruncatedText wordBreak="break-word" color="gray800">
                {getFullName(value)}
              </TruncatedText>
            </Flex>
          );
        },
      },
      {
        accessor: 'invoiceAssociationStatus',
        disableSortBy: true,
        disableFilters: !Boolean(filterOptions?.['invoiceAssociationStatus']),
        Cell: ({ value }) => {
          if (!value) {
            return;
          }

          const tag = getInvoiceStatusTag({
            status: value,
            showTag: creditCardsAutomatedMatchingFF,
          });

          if (!tag) {
            return null;
          }

          return (
            <Tag color={tag.color} variant="secondary">
              {t(tag?.description)}
            </Tag>
          );
        },
      },
      {
        accessor: 'category',
        Cell: CategoryCell,
        width: '180px',
        disableSortBy: true,
      },
    ];

    if (shownColumns && shownColumns.length > 0) {
      return shownColumns.reduce<Array<Column<TransactionsTableData>>>(
        (result, columnId) => {
          // Remove the showInvoiceTag property and value once the creditCardsAutomatedMatchingFF is removed
          const column = allColumns.find(col => col.accessor === columnId);

          if (column) {
            result.push(column);
          }

          return result;
        },
        []
      );
    }

    return allColumns;
  }, [creditCardsAutomatedMatchingFF, filterOptions, shownColumns, t]);

  const isToolbarVisible = !!onSearch;

  const isTableFiltered =
    (defaultFilters ?? []).length > 0 || (search ?? []).length > 0;

  return (
    <Flex height="100%" overflow="hidden" direction="column">
      {isToolbarVisible && (
        <TransactionsTableToolbar
          key={`tx-table-toolbar-${columns.join('-')}`}
          onSearch={onSearch}
          onUpdateConfigurations={onUpdateConfigurations}
          onResetTableConfigurations={onResetTableConfigurations}
          onDownloadCSV={onDownloadCSV}
          search={search}
          isLoading={isLoading || isSavingConfigurations}
          isDownloadingCSV={isDownloadingCSV}
          configurations={configurations}
          isTableFiltered={isTableFiltered}
          downloadableTransactionsCount={downloadableTransactionsCount}
        />
      )}
      <Table<TransactionsTableData>
        columns={columns}
        data={data}
        defaultColumn={defaultColumn}
        initialState={{
          filters: defaultFilters ?? [],
          sortBy: defaultSortBy ?? [],
        }}
        onRowSelected={selectionOptions?.onSelectionRowChanged}
        onRowClick={
          onRowClick
            ? row => {
                const { id, cursor } = row?.original;
                onRowClick({ id, cursor });
              }
            : undefined
        }
        isSingleSelect
        selectedRowIds={selectionOptions?.selectedRowsIds}
        customEmptyState={CustomEmptyState}
        onEndReached={onEndReached}
        overscan={100}
        selectionOptions={selectionOptions}
        borderTopRadius={borderTopRadius}
        isLoading={isLoading}
        key={`tx-table-inbox-${isFilterLoading}-${columns.join('-')}`}
        rowOverlay={rowOverlay}
        {...restProps}
      />
    </Flex>
  );
};
