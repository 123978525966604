import { useLayoutEffect, useRef, useState } from 'react';

const isOverflowing = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}: Element) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

/**
 * Check if an element is overflowing.
 */
export const useOverflow = () => {
  const ref = useRef<Element>(null);
  const [overflowing, setOverflowing] = useState<boolean>();

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver;
    if (ref.current) {
      setOverflowing(isOverflowing(ref.current));

      resizeObserver = new ResizeObserver((entries) => {
        entries.forEach(() => {
          if (ref.current) {
            setOverflowing(isOverflowing(ref.current));
          }
        });
      });
      resizeObserver.observe(ref.current);
    }

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, []);

  return { overflowing, overflowRef: ref };
};
