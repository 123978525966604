import {
  GeneralExpenseItem,
  HospitalityExpenseItem,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { mapToGeneralExpense } from './mapToGeneralExpense';
import { mapToHospitalityExpense } from './mapToHospitalityExpense';

export const mapToExpense = (
  item:
    | Omit<GeneralExpenseItem, '__typename' | 'bookings'>
    | Omit<HospitalityExpenseItem, '__typename' | 'bookings'>
) => {
  if (item.type === ReimbursementItemType.GeneralExpense) {
    return mapToGeneralExpense(item);
  }

  return mapToHospitalityExpense(item);
};
