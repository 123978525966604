import {
  DocumentStatus,
  useDocumentWorkflowStatusQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useMemo } from 'react';
import { useUserInActiveStep } from 'utils/isUserInActiveFirstWFStep';

export type CanAddAttachments = {
  fromDisk: boolean;
  fromCandis: boolean;
};

const isNotAllowedToAttach = {
  fromDisk: false,
  fromCandis: false,
} as const;

const fullPermissions = {
  fromDisk: true,
  fromCandis: true,
} as const;

export const useCanAddAttachments = (
  documentId: string | undefined
): CanAddAttachments => {
  const { data } = useDocumentWorkflowStatusQuery({
    variables: { id: documentId ?? '' },
    skip: !documentId,
  });

  const { isUserInActiveFirstWFStep } = useUserInActiveStep();

  const currentUser = useCurrentUser();
  const { isAdmin, isRequester, isAccountant } = useUserRoles();

  const status = data?.getDocument?.status;

  const permissions = useMemo(() => {
    if (!documentId) {
      return isNotAllowedToAttach;
    }

    const userHasAccess = isAdmin || isRequester || isAccountant;

    const isDocumentExported =
      status === DocumentStatus.Exporting || status === DocumentStatus.Exported;

    if (userHasAccess && !isDocumentExported) {
      return fullPermissions;
    }

    const isDocumentOpen = status === DocumentStatus.Open;

    const isFirstWFStepActive = isUserInActiveFirstWFStep(
      data?.getDocument,
      currentUser?.id
    );

    if (isDocumentOpen && isFirstWFStepActive) {
      return {
        fromDisk: true,
        fromCandis: userHasAccess,
      };
    }

    return isNotAllowedToAttach;
  }, [
    currentUser?.id,
    data?.getDocument,
    documentId,
    isAccountant,
    isAdmin,
    isRequester,
    isUserInActiveFirstWFStep,
    status,
  ]);

  return permissions;
};
