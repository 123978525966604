import { Grid, Text, Paragraph, Flex } from '@candisio/design-system';
import { LottieAnimation, noemailData } from 'components/Lottie/Lottie';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';

export const EmptyNotificationList = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  const [t] = useTranslation();
  const mainNavigationRefactor = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%">
      <LottieAnimation
        width={78}
        height={55}
        options={{ animationData: noemailData }}
      />
      <Grid gap="space10">
        <Text as="h3" fontSize="xlarge" fontWeight="bold">
          {mainNavigationRefactor ? title : t('uploads.noNotifications')}
        </Text>
      </Grid>
      <Paragraph color="gray600" fontSize="small" width="14rem">
        {mainNavigationRefactor
          ? description
          : t('uploads.noNotificationsFurtherInfo')}
      </Paragraph>
    </Flex>
  );
};
