import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { ProvisionEntityType } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ComponentProps, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useGetColumnIds } from 'views/Integrations/Export/hooks/useGetColumnIds';
import { DocumentsTable } from 'views/Integrations/Export/toolkit/components/DocumentsTable';
import { TableCustomEmptyState } from 'views/Integrations/Export/toolkit/components/EmptyState/components/TableCustomEmptyState';
import { EmptyExportView } from 'views/Integrations/Export/toolkit/components/EmptyState/ExportEmptyState';
import {
  ExportTablesWithSepartors,
  ExportedDocumentsTable,
  groupExportDocuments,
  mapGroupToTables,
} from 'views/Integrations/Export/toolkit/components/ExportedDocumentsTable';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { Entity, ExportEntityRow, View } from 'views/Integrations/Export/types';
import {
  TableFilters,
  buildExportFilters,
  useProvisionOnRowClick,
} from '../utils';
import { toDocumentTableData } from './utils';

const availableFilters: Array<TableFilters> = [
  'contact',
  'exportStatus',
  'exportMethod',
] as const;

type ReversalsTableProps = {
  data: ExportEntityRow[];
};

export const Reversals = ({ data }: ReversalsTableProps) => {
  const [errorVisibilityImprovementFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.errorVisibilityImprovement,
  ]);

  const [search] = useSearchParams();
  const state = useExportContext();

  const { failedDocuments } = useExportDataGigaFactory(state);

  const searchFilter = buildExportFilters(availableFilters, search);

  const tableData = useMemo(
    () => data.filter(searchFilter).map(toDocumentTableData(failedDocuments)),
    [data, failedDocuments, searchFilter]
  );

  const onRowClick = useProvisionOnRowClick({ data });

  const tableId = state.tableId;

  const columnIds = useGetColumnIds<DocumentTableColumnKeys>({
    tableId,
  });

  if (!errorVisibilityImprovementFF || state.view !== View.HISTORY) {
    return (
      <DocumentsTable
        columns={columnIds}
        data={tableData}
        onRowClick={onRowClick}
        customEmptyState={TableCustomEmptyState}
        key={`${tableId}-${columnIds.join('-')}`}
      />
    );
  }

  const {
    exportedDocuments,
    partiallyExportedDocuments,
    groupedFailedDocuments,
  } = groupExportDocuments(tableData);

  const Table = (props: ComponentProps<typeof ExportedDocumentsTable>) => (
    <ExportedDocumentsTable
      columns={columnIds}
      data={props.data}
      onRowClick={onRowClick}
      variant={props.variant}
      code={props.code}
    />
  );

  const tables = [
    ...mapGroupToTables(groupedFailedDocuments, Table, 'error'),
    ...mapGroupToTables(partiallyExportedDocuments, Table, 'warning'),
    ...mapGroupToTables(exportedDocuments, Table, 'success'),
  ];

  return <ExportTablesWithSepartors tables={tables} />;
};

const entityMap = {
  [Entity.DOCUMENT]: ProvisionEntityType.Document,
  [Entity.TRANSACTION]: ProvisionEntityType.Transaction,
  [Entity.SETTLEMENT]: null,
  [Entity.CORE_DATA]: null,
  [Entity.REIMBURSEMENT_ITEMS]: null,
} as const;

export const ReversalsContainer = () => {
  const state = useExportContext();

  const {
    exportRowEntities: {
      ready: { reversalEntities },
    },
    isLoading: { loadingReversals },
  } = useExportDataGigaFactory(state);

  const relevantReversals = reversalEntities.filter(
    p => p.provisionEntityType === entityMap[state.entity]
  );

  const isNotReadyView = state.view === View.NOT_READY;

  const data = isNotReadyView ? [] : relevantReversals;

  const isLoading = loadingReversals;
  const isEmpty = data.length === 0;

  if (isLoading || isEmpty) {
    return <EmptyExportView isLoading={isLoading} />;
  }

  return <Reversals data={data} />;
};
