import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useReimbursement } from './useReimbursement';

interface UseReimbursementsPromotionsReturn {
  variant: 'noPromotion' | 'promotionForEntitlements' | 'promotionForLegacy';
}

export const useReimbursementsPromotions =
  (): UseReimbursementsPromotionsReturn => {
    const travelExpenseManagementFF = useCandisFeatureFlags(
      FEATURE_FLAGS.travelExpenseManagement
    );

    const { canUseReimbursement } = useReimbursement();

    const { ft, show } = useFeatureToggle();

    if (canUseReimbursement) {
      return { variant: 'noPromotion' };
    }

    if (!travelExpenseManagementFF && show(ft.REIMBURSEMENT)) {
      return { variant: 'promotionForEntitlements' };
    }

    return { variant: 'promotionForLegacy' };
  };
