import { Avatar, AvatarStack, Grid, Text } from '@candisio/design-system';
import { getCollaboratorTestId } from 'components/DocumentViewer/PdfViewer/Collaborators/utils';
import { useTranslation } from 'react-i18next';
import { Collaborator } from 'views/DocumentDetails/hooks/useSubscribeDocumentCollabs';

interface CollaboratorsProps {
  collaborators: Collaborator[];
}

export const Collaborators = ({ collaborators }: CollaboratorsProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      autoFlow="column"
      justifyContent="center"
      alignItems="center"
      gap="space4">
      <AvatarStack size="small" limit={4}>
        {collaborators.map(({ membership: { avatarUrl, id, name } }) => (
          <Avatar
            key={id}
            name={name}
            size="small"
            img={avatarUrl}
            data-testid={getCollaboratorTestId(id ?? '')}
          />
        ))}
      </AvatarStack>
      <Text color="white">
        {t('document.collaborators.info', {
          count: collaborators.length,
        })}
      </Text>
    </Grid>
  );
};
