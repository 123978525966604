import { Flex, Tag, Text } from '@candisio/design-system';
import { usePromotionsContext } from 'components/NewPromotions/context/PromotionsProvider';
import { PromotionBenefit } from 'components/NewPromotions/types';

type PromotionBenefitItemProps = {
  benefit: PromotionBenefit;
  index: number;
};

const inlinePaddingPixel = 20;
const borderPixel = 2;

export const PromotionBenefitItem = ({
  benefit,
  index,
}: PromotionBenefitItemProps) => {
  const { text, tags, color = 'purple' } = benefit;
  const { currentBenefitIndex, goToBenefit } = usePromotionsContext();

  const isActive = currentBenefitIndex === index;

  return (
    <Flex
      paddingRight="space12"
      alignItems="center"
      {...(isActive && {
        borderLeft: `${borderPixel}px solid ${color}500`,
        background: `${color}bg`,
      })}
      {...(goToBenefit && {
        cursor: 'pointer',
        hover: { background: `${color}bg` },
        onClick: () => goToBenefit?.(index),
      })}>
      <Flex
        justifyContent={tags?.length ? 'space-between' : 'start'}
        alignItems="center"
        gap="space8"
        width="100%"
        paddingY="space4"
        paddingLeft={
          isActive ? inlinePaddingPixel - borderPixel : inlinePaddingPixel
        }>
        <Text style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{text}</Text>
        {tags &&
          tags.map(tag => (
            <Tag color={color} key={tag} variant="secondary">
              {tag}
            </Tag>
          ))}
      </Flex>
    </Flex>
  );
};
