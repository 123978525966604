import { ScrollBox } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { ReactNode } from 'react';
import { getThreeWayMatchGeneralState } from 'utils/three-way-match';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import { ThreeWayMatchIndicator } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchIndicator';
import { AddNewPurchaseOrderButton } from '../PurchaseOrderSection/AddNewPurchaseOrderButton';
import { useGetMatchStateStyles } from './hooks/useGetMatchStateStyles';

interface ThreeWayMatchIndicatorProps {
  children: ReactNode;
}

function getCardProps(variant: string, leftSectionColor: string) {
  return {
    background: variant === 'default' ? undefined : leftSectionColor,
    headerProps: {
      background: variant === 'default' ? leftSectionColor : 'transparent',
      hoverColor: variant === 'default' ? undefined : 'none',
    },
  };
}

export function ThreeWayMatchWrapper({
  children,
}: ThreeWayMatchIndicatorProps) {
  const {
    newData,
    documentId,
    contactName,
    documentAmount,
    accountsPayableNumber,
    isPurchaseOrderEditable,
  } = useThreeWayMatchContext();

  const variant = getThreeWayMatchGeneralState(newData);
  const getMatchingStyles = useGetMatchStateStyles();
  const { leftSectionColor } = getMatchingStyles(variant);
  const cardProps = getCardProps(variant, leftSectionColor);

  return (
    <CollapsibleCard
      data-cy="three-way-match-wrapper"
      id="three-way-match-wrapper"
      borderRadius="medium"
      background={cardProps.background}
      header={<ThreeWayMatchIndicator />}
      headerProps={cardProps.headerProps}
      defaultOpen>
      <ScrollBox
        padding="0"
        borderRadius={variant === 'default' ? 'none' : 'medium'}
        scrollDirection="y"
        maxHeight="400px"
        background="gray0"
        style={
          variant !== 'default'
            ? {
                margin: '0 .25rem',
              }
            : {}
        }
        boxShadow="noShadow">
        {children}
      </ScrollBox>
      {variant !== 'default' && (
        <AddNewPurchaseOrderButton
          documentId={documentId ?? ''}
          contactName={contactName}
          documentAmount={documentAmount}
          accountsPayableNumber={accountsPayableNumber}
          disabled={!isPurchaseOrderEditable}
        />
      )}
    </CollapsibleCard>
  );
}
