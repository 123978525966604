import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  defaultUIConfig,
  UIConfigProps,
} from '../hooks/useReimbursementUIConfig';
import {
  FormRegistration,
  ReimbursementFormsContext,
} from './ReimbursementFormsContext';

interface ReimbursementFormsProviderProps {
  children: ReactNode;
  initialUIConfig?: UIConfigProps;
}

export const ReimbursementFormsProvider = ({
  children,
  initialUIConfig,
}: ReimbursementFormsProviderProps) => {
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const formsRef = useRef<Record<string, FormRegistration>>({});
  const [lastModifiedExpense, setLastModifiedExpense] = useState<null | number>(
    null
  );

  const [visibleItemId, setVisibleItemId] = useState<string | null>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const uiConfig = initialUIConfig || defaultUIConfig;

  const [reimbursementUIConfig, setReimbursementUIConfig] =
    useState<UIConfigProps>(uiConfig);

  const registerForm = (formKey: string, registration: FormRegistration) => {
    formsRef.current = {
      ...formsRef.current,
      [formKey]: registration,
    };
  };

  const updateLastModifiedExpense = useCallback((expenseFormIndex: number) => {
    setLastModifiedExpense(expenseFormIndex);
  }, []);

  const triggerAllValidations = useCallback(() => {
    Object.values(formsRef.current).forEach(form => form.trigger?.());
  }, []);

  const handleSetSelectedItemId = useCallback((id: string | null) => {
    setSelectedItemId(id);
  }, []);

  useEffect(() => {
    setReimbursementUIConfig(uiConfig);
  }, [uiConfig]);

  return (
    <ReimbursementFormsContext.Provider
      value={{
        lastModifiedExpense,
        updateLastModifiedExpense,
        registerForm,
        triggerAllValidations,
        reimbursementUIConfig,
        setReimbursementUIConfig,
        visibleItemId,
        setVisibleItemId,
        itemsContainerRef,
        itemRefs,
        selectedItemId,
        setSelectedItemId: handleSetSelectedItemId,
      }}>
      {children}
    </ReimbursementFormsContext.Provider>
  );
};
