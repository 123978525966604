import Lottie, { LottieComponentProps } from 'lottie-react';
import React from 'react';
import { AnimationKey, animations } from './animations';

interface LottieAnimationProps
  extends Omit<LottieComponentProps, 'animationData'> {
  animationData: AnimationKey;
}

export const LottieAnimation = React.forwardRef<
  HTMLDivElement,
  LottieAnimationProps
>(({ animationData, height = 140, width = 140, ...restProps }, ref) => {
  return (
    <Lottie
      animationData={animations[animationData]}
      autoplay={true}
      loop={false}
      ref={ref}
      style={{ height: height, width: width }}
      {...restProps}
    />
  );
});
