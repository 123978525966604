import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  Ref,
  useRef,
  useState,
} from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { Button } from '../../Atoms/Button';
import {
  FieldContainer,
  FieldContainerProps,
  useFieldContext,
} from '../../Atoms/FieldContainer';
import { Grid } from '../../Atoms/Grid';
import { Text } from '../../Atoms/Typography/Text';
import { useTheme } from '../../Theme';
import { StandardInputHTMLAttributes } from '../../types';
import { Input } from '../TextField';

export interface FileFieldProps extends Omit<FieldContainerProps, 'children'> {
  /** Props to pass directly to the file input */
  input?: StandardInputHTMLAttributes<HTMLInputElement>;
  /** Ref to the file input */
  inputRef?: Ref<HTMLInputElement>;
  /** Text to show when no file is selected */
  placeholder?: string;
  /** Label to show in upload button */
  buttonLabel: string;
}

/**
 * An input field for uploading a file.
 * [Storybook]{@link (https://candisio.github.io/design-system/?path=/docs/molecules-forms-filefield))}
 */
export const FileField = forwardRef<HTMLDivElement, FileFieldProps>(
  (
    {
      input,
      inputRef = null,
      disabled,
      placeholder,
      buttonLabel,
      label,
      variant,
      message,
      readOnly,
      optionalHint,
      ...restProps
    },
    forwardedRef
  ) => {
    const { fontSizes, textField } = useTheme();
    const [fileName, setFileName] = useState<string>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
      fileInputRef.current?.click();
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const fileName = event.target?.files?.[0]?.name;
      setFileName(fileName);

      // This connects the inner 'onChange' event of the hidden input field with the callback from the 'input.onChange' prop,
      // so that changes are upstream. (e.g. needed for libraries like React Hook Form)
      input?.onChange?.(event);
    };

    return (
      <Grid
        templateColumns="1fr auto"
        gap="space16"
        alignItems="flex-end"
        ref={forwardedRef}
        {...restProps}>
        <FieldContainer
          label={label}
          disabled={disabled}
          variant={variant}
          message={message}
          readOnly={readOnly}
          optionalHint={optionalHint}>
          <Text
            css={{
              color: fileName ? textField.color : textField.placeholderColor,
              ...(disabled && textField.disabled),
              fontStyle: disabled ? 'italic' : 'initial',
            }}
            fontFamily="body"
            fontSize="basic"
            lineHeight={fontSizes.xlarge}>
            {fileName ?? placeholder}
          </Text>

          <Input
            {...input}
            type="file"
            message={message}
            variant={variant}
            onChange={handleInputChange}
            ref={mergeRefs([fileInputRef, inputRef])}
            style={{ display: 'none' }}
          />
        </FieldContainer>
        <Button
          disabled={disabled}
          onClick={handleButtonClick}
          variant="secondary">
          {buttonLabel}
        </Button>
      </Grid>
    );
  }
);

export const FileInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, forwardedRef) => {
  const { inputProps, inputRef } = useFieldContext();

  return (
    <input
      {...mergeProps(inputProps, props)}
      type="file"
      ref={mergeRefs([inputRef, forwardedRef])}
    />
  );
});
