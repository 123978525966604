import { Cell, CellProps } from '@candisio/design-system';
import { DateFormatters } from 'utils/date_formatter';

export type DateCellProps<TTableData extends object> = CellProps<
  TTableData,
  Date
>;

export const DateCell = <TTableData extends object>({
  value,
  ...restProps
}: DateCellProps<TTableData>) => {
  return (
    <Cell
      {...restProps}
      value={value ? DateFormatters.compact(value) : undefined}
    />
  );
};
