import { useEffect } from 'react';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';

interface UseFormEffectsProps {
  hasErrors: boolean;
  remainingAmount: number;
  handleSetRemainingAmount: (remainingAmount: number) => void;
  handleShowErrors: (show: boolean) => void;
}

export const useFormEffects = ({
  hasErrors,
  remainingAmount,
  handleSetRemainingAmount,
  handleShowErrors,
}: UseFormEffectsProps) => {
  const { workflow, triggerBookingsValidation } =
    useReimbursementSplitBookingHelpers();
  const isApproveWorkflow = workflow === 'approve';

  // Surface errors in the action box when in the approve workflow on component mount
  useEffect(() => {
    if (isApproveWorkflow) {
      handleShowErrors(hasErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSetRemainingAmount(remainingAmount);
  }, [remainingAmount, handleSetRemainingAmount]);

  useEffect(() => {
    if (!hasErrors) {
      handleShowErrors(false);
    }
  }, [hasErrors, handleShowErrors]);

  useEffect(() => {
    if (isApproveWorkflow) {
      triggerBookingsValidation();
    }
  }, [isApproveWorkflow, triggerBookingsValidation]);
};
