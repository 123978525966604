import { Flex } from '@candisio/design-system';
import { ApproversAccessCardContainer } from 'components/DocumentAccess/ApproversAccessCardContainer';
import { BuiltInRolesAccessCardContainer } from 'components/DocumentAccess/BuiltInRolesAccessCardContainer';
import { ContractAccessCardContainer } from 'components/DocumentAccess/ContractAccessCardContainer';
import { SensitiveContractAccessCardContainer } from 'components/DocumentAccess/SensitiveContractAccessCardContainer';
import { ViewersAccessCardContainer } from 'components/DocumentAccess/ViewersAccessCardContainer';
import { DocumentType } from 'generated-types/graphql.types';
import { useMemo } from 'react';

interface DocumentAccessSectionsProps {
  globalDocumentId: string;
  documentType: DocumentType;
  isSensitiveDocument: boolean;
}

export const DocumentAccessSections = ({
  globalDocumentId,
  documentType,
  isSensitiveDocument,
}: DocumentAccessSectionsProps) => {
  const accessSections = useMemo(() => {
    switch (documentType) {
      case DocumentType.Invoice:
        return InvoiceAccessSections(globalDocumentId);
      case DocumentType.Contract:
        return isSensitiveDocument
          ? SensitiveContractAccessSections(globalDocumentId)
          : ContractAccessSections(globalDocumentId);
      default:
        return GenericEcmAccessSections(globalDocumentId);
    }
  }, [documentType, isSensitiveDocument, globalDocumentId]);

  return <>{accessSections}</>;
};

const InvoiceAccessSections = (globalDocumentId: string) => (
  <Flex direction="column" gap="space16">
    <ViewersAccessCardContainer globalDocumentId={globalDocumentId} />
    <ApproversAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </Flex>
);

const SensitiveContractAccessSections = (globalDocumentId: string) => (
  <Flex direction="column" gap="space16">
    <ViewersAccessCardContainer globalDocumentId={globalDocumentId} />
    <SensitiveContractAccessCardContainer globalDocumentId={globalDocumentId} />
  </Flex>
);

const ContractAccessSections = (globalDocumentId: string) => (
  <Flex direction="column" gap="space16">
    <ViewersAccessCardContainer globalDocumentId={globalDocumentId} />
    <ContractAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </Flex>
);

const GenericEcmAccessSections = (globalDocumentId: string) => (
  <Flex direction="column" gap="space16">
    <ViewersAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </Flex>
);
