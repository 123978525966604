import {
  ExportableEntityInfo,
  ExportableEntityType,
} from 'generated-types/graphql.types';
import { ExportEntity } from '../../types';
import {
  ExportReimbursementItem,
  mapReimbursementItemToExportEntity,
} from './mapReimbursementItemToExportEntity';

export const toReimbursementItemExportEntity =
  ({
    exportableEntitiesInfo,
  }: {
    exportableEntitiesInfo: ExportableEntityInfo[];
  }) =>
  (reimbursementItem: ExportReimbursementItem): ExportEntity => {
    const exportReimbursementItem =
      mapReimbursementItemToExportEntity(reimbursementItem);

    const exportableEntityInfo = exportableEntitiesInfo?.find(
      exportableEntity => exportableEntity.id === reimbursementItem.id
    );

    return {
      ...exportReimbursementItem,
      exportStatus: {
        status: exportableEntityInfo?.status,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        details: (exportableEntityInfo?.details || []).map(s => s!),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        warnings: (exportableEntityInfo?.warnings || []).map(s => s!),
        entityType: ExportableEntityType.ReimbursementItem,
        exportMethod: exportableEntityInfo?.exportMethod,
        ...(exportableEntityInfo?.exportError
          ? {
              exportError: exportableEntityInfo?.exportError,
            }
          : null),
      },
    };
  };
