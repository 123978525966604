// eslint-disable-next-line no-restricted-imports
import { useExpenseSectionComponent } from 'views/Reimbursement/hooks/useExpenseSectionComponent';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useReimbursementMenuButtonActions } from 'views/Reimbursement/hooks/useReimbursementMenuButtonActions';
import { useExpenseFormsHelpers } from './utils/useExpenseFormsHelpers';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

export interface ExpenseSectionContainerProps {
  expenseCount: number;
  reimbursement?: Reimbursement;
  formHelpers?: ReturnType<typeof useExpenseFormsHelpers>;
  reimbursmentItems?: ReimbursementItemListLeftSection[];
}

export const ExpenseSectionContainer = ({
  expenseCount,
  reimbursement,
  formHelpers,
  reimbursmentItems,
}: ExpenseSectionContainerProps) => {
  const ExpenseSection = useExpenseSectionComponent(reimbursement);
  const {
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
    isCreatingExpenseItem,
  } = useReimbursementMenuButtonActions({ formHelpers });

  const { totalAmount } = reimbursement ?? {};

  return (
    <ExpenseSection
      totalAmount={totalAmount}
      expenseCount={expenseCount}
      creatingExpense={isCreatingExpenseItem}
      onGeneralExpenseClick={handleCreateGeneralExpense}
      onHospitalityExpenseClick={handleCreateHospitalityExpense}
      reimbursmentItems={reimbursmentItems}
    />
  );
};
