import { MenuButton, MenuItem, TruncatedText } from '@candisio/design-system';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import { useTranslation } from 'react-i18next';
import { useDisabledAttachmentButtonLabelText } from 'views/Inbox/DocumentProcessing/util/useDisabledAttachmentButtonLabelText';

export interface AttachMenuButtonProps {
  items: MenuItem[];
  canAttachFiles: CanAddAttachments;
  isAttachingFiles?: boolean;
}

export const AttachMenuButton = ({
  items,
  canAttachFiles,
  isAttachingFiles,
}: AttachMenuButtonProps) => {
  const [t] = useTranslation();

  const disabledLabelKey = useDisabledAttachmentButtonLabelText(
    canAttachFiles.fromCandis
  );

  return (
    <MenuButton
      items={items}
      icon={undefined}
      variant="tertiary"
      selectionMode="single"
      size="small"
      loading={isAttachingFiles}
      label={disabledLabelKey}
      disabled={!canAttachFiles?.fromCandis}>
      <TruncatedText>{t('document.attachments.attachPdf')}</TruncatedText>
    </MenuButton>
  );
};
