import { Flex } from '@candisio/design-system';
import { EmptyState } from 'components/EmptyViews/EmptyState';
import { EmptyDataText } from 'components/EmptyViews/styles';
import { nodocumentData } from 'components/Lottie/Lottie';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { useCountOpenApprovalsQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useExportContext } from 'views/Integrations/Export/Context';
import { Entity, Table } from 'views/Integrations/Export/types';

const translationKeys = {
  [Entity.DOCUMENT]: {
    title: 'emptyState.invoices.title',
    descriptionWithDocumentsToApprove:
      'emptyState.invoices.descriptionWithDocumentsToApprove',
    descriptionWithNoDocumentsToApprove:
      'emptyState.invoices.descriptionWithNoDocumentsToApprove',
  },
  [Entity.TRANSACTION]: {
    title: 'emptyState.creditCardTransactions.title',
    descriptionWithDocumentsToApprove:
      'emptyState.creditCardTransactions.descriptionWithDocumentsToApprove',
    descriptionWithNoDocumentsToApprove:
      'emptyState.creditCardTransactions.descriptionWithNoDocumentsToApprove',
  },
} as const;

const translationKeysTables = {
  [Table.REVERSALS]: {
    title: 'emptyState.reversals.title',
    description: 'emptyState.reversals.description',
  },
  [Table.PROVISIONS]: {
    title: 'emptyState.provisions.title',
    description: 'emptyState.provisions.description',
  },
  [Table.SETTLEMENTS]: {
    title: 'emptyState.cardSettlements.title',
    description: 'emptyState.cardSettlements.description',
  },
} as const;

export const Init = () => {
  const { entity, table } = useExportContext();
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const organizationSlug = useOrganizationId();

  const {
    data: { countOpenApprovals: { count: approvalCount = null } = {} } = {},
  } = useCountOpenApprovalsQuery({ fetchPolicy: 'cache-first' });

  const areThereDocsToApprove = !!approvalCount;
  const linkToProcessing = `/${organizationSlug}${Routes.INBOX}/`;
  const linkToApprovals = `/${organizationSlug}${Routes.APPROVALS}/`;

  let message: ReactNode = null;

  if (
    table === Table.PROVISIONS ||
    table === Table.REVERSALS ||
    table === Table.SETTLEMENTS
  ) {
    message = t(translationKeysTables[table].description);
  }

  const isApprovalsEmptyState =
    table === Table.APPROVALS &&
    (entity === Entity.DOCUMENT || entity === Entity.TRANSACTION);

  if (isApprovalsEmptyState && areThereDocsToApprove) {
    message = (
      <Trans
        t={t}
        i18nKey={translationKeys[entity].descriptionWithDocumentsToApprove}>
        To be able to export documents
        <RouterLink to={linkToApprovals}>start approving</RouterLink>.
      </Trans>
    );
  }

  if (isApprovalsEmptyState && !areThereDocsToApprove) {
    message = (
      <Trans
        t={t}
        i18nKey={translationKeys[entity].descriptionWithNoDocumentsToApprove}>
        To be able to export documents
        <RouterLink to={linkToProcessing}>start processing</RouterLink>
        and then
        <RouterLink to={linkToApprovals}>approving</RouterLink>.
      </Trans>
    );
  }

  const emptyDataTextKey =
    entity !== Entity.CORE_DATA && entity !== Entity.REIMBURSEMENT_ITEMS
      ? translationKeys[entity].title
      : translationKeys[Entity.DOCUMENT].title;

  return (
    <EmptyState
      animationData={nodocumentData}
      emptyDataText={t(emptyDataTextKey)}>
      <Flex justifyContent="center">
        <EmptyDataText>{message}</EmptyDataText>
      </Flex>
    </EmptyState>
  );
};
