import { Text } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { nocontactData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useContractCustomSubCategoryListLogic } from '../../toolkit/useContractCustomSubCategoryListLogic';

export const ContractCustomSubCategoriesEmptyDataState = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { isArchived } = useContractCustomSubCategoryListLogic();

  const noDataText = isArchived
    ? t('contractCustomSubCategories.noData.archiveMessage')
    : t('contractCustomSubCategories.noData.activeMessage');

  return (
    <EmptyState animationData={nocontactData}>
      <Text>{noDataText}</Text>
    </EmptyState>
  );
};
