import { DocumentType } from 'generated-types/graphql.types';
import { formatDecimalToIntegerAmount } from 'hooks/useMoneyFormatter';
import { isNil, isNumber } from 'lodash';
import { StorageFormValues } from './storageFormSchema';

/**
 * Serialise storage form values before submitting.
 *
 * Used for `storeEcmDocument` and `updateEcmDocument` mutations
 */
export const mapStorageFormValues = (
  /** Storage form values */
  formValues: StorageFormValues,
  /** Pass `dateStringToDateTimeString` from `useDateConverter` */
  dateConverter: (date: string) => string,
  /**
   * `storeSensitiveEcmDocument` mutation does not support `isSensitive`
   * field, in which case we need to exclude it from the payload
   */
  isNewSensitiveContract?: boolean
) => {
  const {
    amount,
    contact,
    costCenter,
    currency,
    documentDate,
    documentName,
    documentNumber,
    documentType,
    endDate,
    notes,
    notifyPerson,
    responsiblePerson,
    startDate,
    documentSubCategory,
    terminationDate,
    terminationReminderDate,
    isSensitive,
  } = formValues;

  const shouldResetContractFields = documentType !== DocumentType.Contract;

  return {
    contactId: contact.value,
    documentDate: documentDate ? dateConverter(documentDate) : null,
    documentName: documentName,
    documentNumber: documentNumber,
    documentType: documentType,
    notes: notes,
    documentSubCategory:
      shouldResetContractFields || isNil(documentSubCategory)
        ? null
        : documentSubCategory,
    costCenterId:
      shouldResetContractFields || isNil(costCenter.value)
        ? null
        : costCenter.value,
    amount:
      // We allow amount to be "0 / 0,00"
      shouldResetContractFields || !isNumber(amount) || isNil(currency)
        ? null
        : {
            amount: formatDecimalToIntegerAmount(amount),
            currency,
            precision: 2,
          },
    startDate:
      shouldResetContractFields || isNil(startDate)
        ? null
        : dateConverter(startDate),
    endDate:
      shouldResetContractFields || isNil(endDate)
        ? null
        : dateConverter(endDate),
    terminationDate:
      shouldResetContractFields || isNil(terminationDate)
        ? null
        : dateConverter(terminationDate),
    terminationReminderDate:
      shouldResetContractFields || isNil(terminationReminderDate)
        ? null
        : dateConverter(terminationReminderDate),
    // Currently, the user can only select one responsible / notify person but
    // the backend is already prepared for storing multiple in the
    // future.
    responsiblePerson: {
      primary:
        shouldResetContractFields || isNil(responsiblePerson)
          ? []
          : responsiblePerson,
    },
    notifyPerson: {
      primary:
        shouldResetContractFields || isNil(notifyPerson) ? [] : notifyPerson,
    },
    ...(!isNewSensitiveContract && { isSensitive }),
  };
};
