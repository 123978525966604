import {
  ExpenseItemArchivedEventDataFragment,
  ExpenseItemExpenseDateUpdatedEventDataFragment,
  ExpenseItemFileAddedEventDataFragment,
  ExpenseItemFileRemovedEventDataFragment,
  ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment,
  ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment,
  ExpenseItemHospitalityLocationUpdatedEventDataFragment,
  ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment,
  ExpenseItemHospitalityTipAmountUpdatedEventDataFragment,
  ExpenseItemTitleUpdatedEventDataFragment,
  ExpenseItemTotalAmountUpdatedEventDataFragment,
  ReimbursementCaseAmendmentRequestedEventDataFragment,
  ReimbursementCaseApprovedEventDataFragment,
  ReimbursementCaseCommentAddedEventDataFragment,
  ReimbursementCaseCreatedEventDataFragment,
  ReimbursementCaseNoteUpdatedEventDataFragment,
  ReimbursementCaseSubmittedForReviewEventDataFragment,
  ReimbursementCaseTargetMembershipUpdatedEventDataFragment,
  ReimbursementCaseTitleUpdatedEventDataFragment,
  ReimbursementTimeLineItem,
  useGetReimbursementHistoryQuery,
} from 'generated-types/graphql.types';

export type SupportedFragmentEvents =
  | ReimbursementCaseTitleUpdatedEventDataFragment
  | ExpenseItemTitleUpdatedEventDataFragment
  | ReimbursementCaseAmendmentRequestedEventDataFragment
  | ReimbursementCaseApprovedEventDataFragment
  | ReimbursementCaseCreatedEventDataFragment
  | ReimbursementCaseSubmittedForReviewEventDataFragment
  | ReimbursementCaseTargetMembershipUpdatedEventDataFragment
  | ReimbursementCaseNoteUpdatedEventDataFragment
  | ExpenseItemTotalAmountUpdatedEventDataFragment
  | ExpenseItemFileAddedEventDataFragment
  | ExpenseItemFileRemovedEventDataFragment
  | ExpenseItemExpenseDateUpdatedEventDataFragment
  | ReimbursementCaseCommentAddedEventDataFragment
  | ExpenseItemArchivedEventDataFragment
  | ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment
  | ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment
  | ExpenseItemHospitalityLocationUpdatedEventDataFragment
  | ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment
  | ExpenseItemHospitalityTipAmountUpdatedEventDataFragment;

export type SuppertedReimbursementTimeLineEvents = ReimbursementTimeLineItem &
  SupportedFragmentEvents;

interface UseReimbursementHistoryParams {
  id: string;
}

export const useReimbursementHistory = ({
  id,
}: UseReimbursementHistoryParams) => {
  const { data, loading } = useGetReimbursementHistoryQuery({
    variables: { id },
  });

  const timeline = data?.reimbursementCaseById.timeline ?? [];
  return { loading, timeline };
};
