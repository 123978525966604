import { forwardRef } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { ListProps } from 'react-virtuoso';
import { List } from './List';
import { VirtuosoContext } from './types';

export const VirtuosoList = forwardRef<
  HTMLUListElement,
  ListProps & VirtuosoContext
>(({ context, ...restProps }, forwardedRef) => {
  const { ref, ...listProps } = context.listProps;

  return (
    <List
      {...mergeProps(listProps, restProps)}
      ref={ref ? mergeRefs([ref, forwardedRef]) : forwardedRef}
    />
  );
});
