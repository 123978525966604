import { Box, Grid } from '@candisio/design-system';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { noop } from 'lodash';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { FormProvider, useForm } from 'react-hook-form';
import { ExpenseDetailsCardWithAction } from './components/MiddleSection/ExpenseDetailsCardWithAction';
import { HospitalityExpenseForm } from './components/MiddleSection/HospitalityExpenseForm';
import { ExpensesFormOutput } from './toolkit/expensesFormSchema';

export const ReimbursementsDashboardPromotion = () => {
  const { reimbursementsPromotionLegacy, reimbursementsPromotionNonLegacy } =
    usePromotionContent();

  const { documentMoneyFormatter } = useMoneyFormatter();

  const { variant } = useReimbursementsPromotions();

  const promotionVariant =
    variant === 'promotionForEntitlements'
      ? reimbursementsPromotionNonLegacy
      : reimbursementsPromotionLegacy;

  const form = useForm<ExpensesFormOutput>({
    defaultValues: {
      expenses: [
        {
          expenseId: 'example-1',
          reason: '',
          dayOfExpense: '',
          expenseType: 'hospitality',
          totalAmount: null,
          receiptAmount: null,
          location: '',
          tipAmount: null,
          internalGuests: null,
          externalGuests: null,
          files: [],
        },
      ],
    },
  });

  return (
    <Grid height="100%" padding="space24 space24">
      <FormProvider {...form}>
        <Grid height="min-content">
          <Grid templateRows="auto 1fr" gap="space16" opacity={0.7}>
            <ExpenseDetailsCardWithAction
              totalAmount={documentMoneyFormatter({
                amount: 4876,
                currency: DocumentCurrency.Eur,
                precision: 2,
              })}
              expenseCount={1}
              creatingExpense={false}
              isPromotion
              onGeneralExpenseClick={noop}
              backgroundColor="gray100"
              onHospitalityExpenseClick={noop}
            />

            <HospitalityExpenseForm
              canManageReimbursementItems={true}
              clearableField=""
              expenseId="expense-1"
              formIndex={0}
              formMethods={form}
              isPromotion
              isCreatingInvoice={{ 0: false }}
              isDeletingForm={{ 0: false }}
              isReadOnly={false}
              onCleareableField={() => noop}
              onInvoiceChange={Promise.resolve}
              onRemoveForm={() => Promise.resolve}
              onReplaceInvoice={() => Promise.resolve}
              onUpdateHospitality={() => Promise.resolve}
              onUpdateTotalAmount={() => Promise.resolve}
            />
          </Grid>
          {/** Overlay on top of our elements */}
          <Grid position="absolute" height="100%" opacity={0} width="100%" />
          <Box
            justifySelf="center"
            width="45rem"
            height="28rem"
            top="25%"
            position="absolute">
            <PromotionsProvider promotions={[promotionVariant]}>
              <PromotionCard promotion={promotionVariant} closable={false} />
            </PromotionsProvider>
          </Box>
        </Grid>
      </FormProvider>
    </Grid>
  );
};
