import { useTranslation } from 'react-i18next';
import { DocumentType } from '../../../../types';
import { SummaryAccordionCard } from '../../../components/SummaryAccordionCard';

interface ReimbursementItemsSummaryProps {
  readyToExportEntitiesCount: Record<DocumentType, number>;
  loading?: boolean;
}

export const ReimbursementItemsSummary = ({
  readyToExportEntitiesCount,
  loading,
}: ReimbursementItemsSummaryProps) => {
  const [t] = useTranslation();
  const reimbursementItemsCount =
    readyToExportEntitiesCount.REIMBURSEMENT_ITEMS ?? 0;

  const approvalsSummary = [
    {
      title: t('export.exportSummary.reimbursementItemsCount', {
        count: reimbursementItemsCount,
      }),
      content: '',
    },
  ];

  return reimbursementItemsCount ? (
    <SummaryAccordionCard
      items={approvalsSummary}
      loading={loading}
      collapsable={false}
    />
  ) : null;
};
