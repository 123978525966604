import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormNumberFieldProps } from 'components/HookFormFields/HookFormNumberField';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AmountFieldProps<TFormValues extends FieldValues>
  extends HookFormNumberFieldProps<TFormValues> {
  name: Path<TFormValues>;
}

export const AmountField = <TFormValues extends FieldValues>({
  name,
  ...props
}: AmountFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <HookFormAmountField
      name={name}
      currency={DocumentCurrency.Eur}
      placeholder={t('splitBookings.splitAmount.placeholder.enabled')}
      {...props}
    />
  );
};
