import {
  autoUpdate,
  flip,
  offset,
  size,
  useFloating,
} from '@floating-ui/react-dom';
import { ReactElement, ReactNode, forwardRef, useRef } from 'react';
import {
  DismissButton,
  FocusScope,
  OverlayContainer,
  mergeProps,
  useOverlay,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { SelectState } from 'react-stately';
import { useTheme } from '../../Theme';
import { LayoutProps } from '../../types';
import { Card } from '../Card';
import {
  GroupedListBox,
  GroupedListBoxProps,
  groupedListPadding,
} from '../ListBox/GroupedList/GroupedListBox';
import { ListBox, ListBoxProps } from '../ListBox/ListBox';

export interface ListBoxPopupProps extends LayoutProps {
  listBoxProps: ListBoxProps | GroupedListBoxProps;
  renderCustomDropdown?: (list: ReactElement) => ReactNode;
  state: SelectState<any>;
  triggerElement: HTMLElement;
  isGroupedListMode?: boolean;
}

export const ListBoxPopup = forwardRef<HTMLDivElement, ListBoxPopupProps>(
  (
    {
      listBoxProps,
      renderCustomDropdown,
      state,
      triggerElement,
      isGroupedListMode,
      ...restProps
    },
    forwardedRef
  ) => {
    const { space } = useTheme();

    const popupRef = useRef(null);
    // Handle events that should cause the popup to close,
    // e.g. blur, clicking outside, or pressing the escape key.
    const { overlayProps } = useOverlay(
      {
        isDismissable: false,
        isOpen: state.isOpen,
        onClose: state.close,
        shouldCloseOnBlur: true,
      },
      popupRef
    );

    const { x, y, refs, strategy } = useFloating({
      placement: 'bottom',
      middleware: [
        flip(),
        size({
          apply({ availableHeight, elements }) {
            Object.assign(elements.floating.style, {
              // should only flip when it cannot have a minimum height of
              // 120px, leaving a buffer of space16 at the viewport edge
              maxHeight: `calc(${Math.max(120, availableHeight)}px - ${
                space.space16
              })`,
            });
          },
        }),
        offset(8), // doesn’t support non-px values
      ],
      elements: {
        reference: triggerElement,
      },
      whileElementsMounted: autoUpdate,
    });

    const ListComponent = isGroupedListMode ? GroupedListBox : ListBox;

    const renderListBox = renderCustomDropdown?.(
      <ListComponent {...listBoxProps} maxHeight="30cqh" state={state} />
    ) ?? <ListComponent {...listBoxProps} maxHeight="40cqh" state={state} />;
    // this is how Floating UI suggests to handle external refs, triggering the
    // callback ref with the passed element
    // useLayoutEffect(() => {
    //   reference(triggerElement);
    //   floating(popupRef.current);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [triggerElement]);

    // Wrap in <FocusScope> so that focus is restored back to the
    // trigger when the popup is closed. In addition, add hidden
    // <DismissButton> components at the start and end of the list
    // to allow screen reader users to dismiss the popup easily.
    return (
      <OverlayContainer>
        <FocusScope restoreFocus>
          <Card
            boxShadow="elevatedShadow3"
            corners="all"
            padding={0}
            overflow="hidden"
            // apply Floating UI styles inline for best performance
            style={{
              position: strategy,
              top: y ?? undefined,
              left: x ?? undefined,
            }}
            width={triggerElement.offsetWidth ?? undefined}
            // to achieve stacking order from top to bottom: Tooltip >
            // ListBoxPopup > Popover
            zIndex={100001}
            {...mergeProps(overlayProps, restProps)}
            ref={mergeRefs([popupRef, forwardedRef, refs.setFloating])}
            {...(isGroupedListMode && groupedListPadding(space.space16))}>
            <DismissButton onDismiss={state.close} />
            {/* ListBox inherits the `maxHeight` when we *don’t* have a custom //
            dropdown */}
            {renderListBox}
            <DismissButton onDismiss={state.close} />
          </Card>
        </FocusScope>
      </OverlayContainer>
    );
  }
);
