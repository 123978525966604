import { Box } from '@candisio/design-system';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { DocumentPreviewUpsell } from './DocumentPreviewUpsell';

interface DocumentPreviewUpsellDrawerProps {
  closePreviewDrawer: () => void;
  isOpen: boolean;
}

export function DocumentPreviewUpsellDrawer({
  isOpen,
  closePreviewDrawer,
}: DocumentPreviewUpsellDrawerProps) {
  const [animationFinished, setAnimationFinished] = useState(false);

  useEffect(() => {
    return () => {
      setAnimationFinished(false);
    };
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          onAnimationStart={() => {
            setAnimationFinished(false);
          }}
          onAnimationComplete={() => {
            setAnimationFinished(true);
          }}
          key="documentPreviewUpsellWrapper"
          id="documentPreviewUpsell"
          initial="closed"
          animate="open"
          exit="closed"
          variants={VARIANTS}
          transition={TRANSITIONS}
          height="100%"
          minHeight="0">
          <DocumentPreviewUpsell
            onClose={closePreviewDrawer}
            showContent={animationFinished}
          />
        </MotionBox>
      )}
    </AnimatePresence>
  );
}

const MotionBox = motion(Box);

const VARIANTS = {
  open: { width: '464px' },
  closed: { width: '0' },
};

const TRANSITIONS = { ease: 'easeOut' };
