import {
  Grid,
  Heading,
  Link,
  Spinner,
  Switch,
  Text,
} from '@candisio/design-system';
import { AnimatePresence, AnimationProps, motion } from 'framer-motion';
import { isNil } from 'lodash';
import { Trans } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import {
  ContactSettingsFormOutput,
  useContactSettingsFormSchema,
} from './contactSettingsFormSchema';
import { StartValueField } from './StartValueField';
import { useApnSuggestionSettings } from './useApnSuggestionSettings';

const START_VALUE_NAME = 'startValue';

const MotionGrid = motion(Grid);
const MotionSpinner = motion(Spinner);

export const animationProps: AnimationProps = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
        delay: 0.15,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
      },
    },
  },
};

export const spinnerAnimationProps: AnimationProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      opacity: {
        duration: 0.25,
        delay: 0.15,
      },
    },
  },
  exit: {
    opacity: 0,
    transition: {
      opacity: {
        duration: 0.25,
      },
    },
  },
};

export type ContactSettingsFormProps = Omit<
  ReturnType<typeof useApnSuggestionSettings>,
  'loadingInitialValues'
>;

export const ContactSettingsForm = ({
  enabled,
  startValue,
  setEnabled,
  setStartValue,
  submitting,
}: ContactSettingsFormProps) => {
  const [t] = useTranslation();

  const contactSettingsFormSchema = useContactSettingsFormSchema();

  const form = useForm<ContactSettingsFormOutput>({
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema: contactSettingsFormSchema,
      errorMessages: {
        startValue: {
          label: 'settings.contacts.apnSuggestions.startValueLabel',
        },
      },
    }),
    defaultValues: {
      startValue: !isNil(startValue) ? String(startValue) : null,
    },
  });

  const handleSubmit = async (data: ContactSettingsFormOutput) => {
    if (data.startValue) {
      const result = await setStartValue(data.startValue);

      if (result.data) {
        form.resetField('startValue', { defaultValue: data.startValue });
      }
    }
  };

  return (
    <Grid gap="space16">
      <Grid
        justifyContent="space-between"
        alignItems="center"
        autoFlow="column">
        <Heading as="h3">{t('settings.contacts.apnSuggestions.title')}</Heading>
        <Grid
          autoFlow="column"
          justifyContent="space-between"
          alignItems="center"
          gap="space8">
          <AnimatePresence>
            {submitting && (
              <MotionSpinner size="space20" {...spinnerAnimationProps} />
            )}
          </AnimatePresence>
          <Switch
            label={t('settings.contacts.apnSuggestions.switchLabel')}
            checked={enabled}
            disabled={submitting}
            onChange={isSelected => {
              void setEnabled(isSelected);
            }}
          />
        </Grid>
      </Grid>
      <Grid gap="space16">
        <AnimatePresence initial={false}>
          {enabled && (
            <MotionGrid gap="space16" {...animationProps}>
              <FormProvider {...form}>
                <Grid
                  as="form"
                  autoFlow="column"
                  justifyContent="start"
                  alignItems="flex-end"
                  gap="space8"
                  onSubmit={form.handleSubmit(handleSubmit)}>
                  <StartValueField<ContactSettingsFormOutput>
                    name={START_VALUE_NAME}
                    label={t(
                      'settings.contacts.apnSuggestions.startValueLabel'
                    )}
                    readOnly={submitting}
                  />
                  <QuickSaveButtons
                    isFormDirty={form.formState.isDirty}
                    resetField={() => form.resetField('startValue')}
                    isLoading={submitting}
                  />
                </Grid>
              </FormProvider>
            </MotionGrid>
          )}
        </AnimatePresence>
        <Trans
          i18nKey="settings.contacts.apnSuggestions.description"
          parent="span">
          <Text fontSize="small" color="gray800" lineHeight="paragraph"></Text>{' '}
          <Link
            href={t('settings.contacts.apnSuggestions.helpCenterLink')}
            external></Link>
        </Trans>
      </Grid>
    </Grid>
  );
};
