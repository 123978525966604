type FieldKeys =
  | 'expenseId'
  | 'reason'
  | 'totalAmount'
  | 'receiptAmount'
  | 'files'
  | 'location'
  | 'tipAmount'
  | 'dayOfExpense'
  | 'externalGuests'
  | 'internalGuests';

type FieldNames = {
  [K in FieldKeys]: `expenses.${number}.${K & string}`;
};

export const generateFieldNames = (index: number): FieldNames => ({
  expenseId: `expenses.${index}.expenseId`,
  reason: `expenses.${index}.reason`,
  totalAmount: `expenses.${index}.totalAmount`,
  receiptAmount: `expenses.${index}.receiptAmount`,
  files: `expenses.${index}.files`,
  location: `expenses.${index}.location`,
  tipAmount: `expenses.${index}.tipAmount`,
  dayOfExpense: `expenses.${index}.dayOfExpense`,
  externalGuests: `expenses.${index}.externalGuests`,
  internalGuests: `expenses.${index}.internalGuests`,
});
