// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const NotificationItemContainer = styled.li`
  margin: 0.5rem 0;
`;

export const FilledPopoverContainer = styled.div`
  padding: 14px 8px 8px;
`;
