import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ReimbursementCaseNoteUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ReimbursementCaseNoteUpdatedEvent = ({
  properties,
}: ReimbursementCaseNoteUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  if (!properties.newNote) {
    return null;
  }

  return (
    <Grid>
      <Text>{t('timeline.oneFieldChanged')}</Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t('reimbursementView.rightSection.form.note')}
          </Text>
          <Paragraph>{properties.newNote}</Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
