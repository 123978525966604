import {
  Flex,
  Grid,
  Skeleton,
  useId,
  usePopover,
} from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrganizationCard } from './OrganizationCard';
import { UserDropdownItem } from 'components/UserDropdownItem/UserDropdownItem';
import { ConfirmationPopover } from 'components/ConfirmationPopover/ConfirmationPopover';
import { useMemo } from 'react';
import { useTransferOwnership } from 'views/Settings/Organization/hooks/useTransferOwnership';
import { useGetUsers } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { BuiltinRoleName } from 'generated-types/graphql.types';

export const OrganizationOwner = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { users: admins, isLoadingUsers: isLoadingAdmins } = useGetUsers({
    filters: { roleNames: [BuiltinRoleName.Admin] },
  });

  const {
    isOpen: isDoubleConfirmationOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
    setOpen: setDoubleConfirmationOpen,
    close: closeDoubleConfirmation,
  } = usePopover({ placement: 'right' });

  const {
    onChange,
    onConfirm,
    handleCancelSubmit,
    form,
    selectedOwnerName,
    isLoading,
    isDisabled,
  } = useTransferOwnership({
    setDoubleConfirmationOpen,
    closeDoubleConfirmation,
  });

  const formId = useId();

  const items = useMemo(() => {
    return admins.map(admin => ({
      children: (
        <UserDropdownItem
          name={admin.name}
          avatarUrl={admin.avatarUrl ?? undefined}
        />
      ),
      key: admin.id,
    }));
  }, [admins]);

  return (
    <FormProvider {...form}>
      <Grid id={formId} as="form">
        <OrganizationCard
          title={t('organisation.owner.title')}
          paragraph={t('organisation.owner.description')}
          isLoading={isLoading}>
          {isLoading || isLoadingAdmins ? (
            <Skeleton height="space48" width="100%" />
          ) : (
            <Flex
              gap="space16"
              width="max-content"
              height="space48"
              alignItems="center"
              ref={triggerRef}
              {...triggerProps}>
              <HookFormSelectField
                name="owner"
                items={items}
                readOnly={isLoading || isLoadingAdmins || isDisabled}
                placeholder={t('organisation.placeholder.select')}
                onChange={onChange}
                width="space256"
              />
              {!isDisabled && isDoubleConfirmationOpen && (
                <ConfirmationPopover
                  onConfirm={onConfirm}
                  title={t(
                    'organisation.owner.transferOwnership.doubleConfirmation',
                    {
                      ownerName: selectedOwnerName,
                    }
                  )}
                  onClose={handleCancelSubmit}
                  closeButtonProps={{
                    onClick: handleCancelSubmit,
                  }}
                  {...popoverProps}
                  ref={popoverRef}
                />
              )}
            </Flex>
          )}
        </OrganizationCard>
      </Grid>
    </FormProvider>
  );
};
