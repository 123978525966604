import React from 'react';
import {
  AriaPositionProps,
  mergeProps,
  useOverlayPosition,
  usePress,
  useTooltipTrigger,
} from 'react-aria';
import { useTooltipTriggerState, TooltipTriggerProps } from 'react-stately';
import { StandardHTMLAttributes } from '../../types';
import { TooltipProps } from './Tooltip';

export interface UseTooltipConfig extends TooltipTriggerProps {
  /**
   * Where to place the tooltip, relative to the trigger
   */
  placement?: AriaPositionProps['placement'];
  /**
   * Usually a tooltip is associated with an “active” trigger element such as
   * a button. Set this prop when the associated trigger is a “passive” element
   * (e.g. some plain text)
   */
  passiveTrigger?: boolean;
}

/**
 * Associates a Tooltip with a trigger element and manages its position and
 * state
 */
export const useTooltip = ({
  placement = 'top',
  isOpen,
  defaultOpen,
  delay = 0,
  isDisabled,
  onOpenChange,
  trigger,
  passiveTrigger = false,
}: UseTooltipConfig = {}) => {
  /**
     Manages state for a tooltip trigger.
     Tracks whether the tooltip is open, and provides methods to toggle this state.
     Ensures only one tooltip is open at a time and controls the delay for showing a tooltip.
  */
  const state = useTooltipTriggerState({
    isOpen,
    defaultOpen,
    delay,
    isDisabled,
    onOpenChange,
    trigger,
  });

  const triggerRef = React.useRef(null);
  const tooltipRef = React.useRef(null);

  // Get tooltip positioning props relative to the trigger
  const {
    overlayProps: positionProps,
    arrowProps,
    placement: placementAxis,
  } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: tooltipRef,
    placement,
    offset: 8,
    isOpen: state.isOpen,
  });

  // Get aria props for trigger and tooltip
  const { triggerProps: triggerAriaProps, tooltipProps: tooltipAriaProps } =
    useTooltipTrigger(
      { isOpen, defaultOpen, delay, isDisabled, onOpenChange, trigger },
      state,
      triggerRef
    ) as {
      triggerProps: StandardHTMLAttributes<HTMLElement>;
      tooltipProps: StandardHTMLAttributes<HTMLElement>;
    };

  // React Aria’s useTooltipTrigger suppresses click events (React.MouseEvent)
  // triggered by keyboard “clicks” (pressing space or enter key). This breaks
  // our assumptions about how onClick works in React and can also make
  // integrating with third-party libraries a headache.
  //
  // We manually trigger a click to ensure that keyboard “clicks” trigger a
  // React.MouseEvent click as expected.
  const { pressProps } = usePress({
    onPress: (e) => {
      if (e.pointerType === 'keyboard') {
        (triggerRef.current as HTMLElement | null)?.click();
      }
    },
  }) as {
    pressProps: StandardHTMLAttributes<HTMLElement>;
  };

  const triggerProps: StandardHTMLAttributes<HTMLElement | SVGElement> =
    mergeProps(pressProps, triggerAriaProps);

  if (passiveTrigger) {
    delete triggerProps.onBlur;
    delete triggerProps.onClick;
    delete triggerProps.onDragStart;
    delete triggerProps.onFocus;
    delete triggerProps.onKeyDown;
    delete triggerProps.onKeyUp;
    delete triggerProps.onMouseDown;
    delete triggerProps.onPointerDown;
    delete triggerProps.onPointerUp;
    delete triggerProps.tabIndex;
  }

  const tooltipProps: TooltipProps = mergeProps(
    tooltipAriaProps,
    positionProps,
    {
      arrowStyle: arrowProps.style,
      isOpen: state.isOpen,
      placementAxis,
      state,
    }
  );

  return {
    close: state.close,
    isOpen: state.isOpen,
    open: state.open,
    tooltipProps,
    tooltipRef,
    triggerProps,
    triggerRef,
    state,
  };
};
