import { useUpdateGeneralExpenseItemMutation } from 'generated-types/graphql.types';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { ExpenseSchemaOutput } from '../toolkit/expensesFormSchema';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import { sanitizeGeneralExpenseFormOutput } from '../utils/sanitizeGeneralExpenseFormOutput';

const UPDATE_EXPENSE_DELAY = 1000;

export const useUpdateGeneralExpense = ({
  shouldDebounce = true,
}: {
  shouldDebounce?: boolean;
} = {}) => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const [updateGeneralExpense, { loading: isUpdateGeneralExpensePending }] =
    useUpdateGeneralExpenseItemMutation();

  const handleUpdateGeneralExpense = useCallback(
    async (formValues: ExpenseSchemaOutput) => {
      const input = {
        reimbursementCaseId: reimbursementId,
        ...sanitizeGeneralExpenseFormOutput(formValues),
      };

      const variables = {
        id: formValues.expenseId,
        input,
      };

      const { errors } = await updateGeneralExpense({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementItemsListQuery,
            variables: { filters: { reimbursementCaseId: reimbursementId } },
          },
          {
            query: reimbursementItemsSplitBookingsQuery,
            variables: { filters: { reimbursementCaseId: reimbursementId } },
          },
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
        ],
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    [reimbursementId, updateGeneralExpense]
  );

  const debouncedFunction = useMemo(
    () => debounce(handleUpdateGeneralExpense, UPDATE_EXPENSE_DELAY),
    [handleUpdateGeneralExpense]
  );

  useEffect(() => {
    return () => {
      debouncedFunction.cancel();
    };
  }, [debouncedFunction]);

  return {
    isUpdateGeneralExpensePending,
    updateGeneralExpense: shouldDebounce
      ? debouncedFunction
      : handleUpdateGeneralExpense,
  };
};
