import { Button, Flex, Heading } from '@candisio/design-system';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import {
  generatePath,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat';
import { orgSettingsParam } from './CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import { CreditCardsTab } from './CreditCardsTabs/CreditCardsTabs';
import { exportCardId, statementCardId } from './utils/utils';

const creditCardSearchParamKey: 'onCreditCardsView' = 'onCreditCardsView';

export const CreditCardsHeader = ({
  selectedTab,
}: {
  selectedTab: CreditCardsTab;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationSlug } = useParams<{
    creditCardId: string;
    organizationSlug: string;
  }>();

  const { canIssueCards } = usePermissionsForCreditCards();

  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const settingsCCLink = `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`;

  const openIssueNewCardDrawer = () => {
    const existingSearchParams = new URLSearchParams(location.search);
    existingSearchParams.append(creditCardSearchParamKey, 'true');
    if (selectedTab === CreditCardsTab.Dashboard) {
      navigate({
        pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}/request`,
        search: existingSearchParams.toString(),
      });
    } else if (selectedTab === CreditCardsTab.Table) {
      navigate({
        pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/request`,
        search: existingSearchParams.toString(),
      });
    } else {
      navigate({
        pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}/request`,
        search: existingSearchParams.toString(),
      });
    }
  };

  const handleOrganizationSettingsClick = () => {
    if (selectedTab === CreditCardsTab.Statements) {
      const organizationSettingsDrawerLink = generatePath(
        `${location.pathname}/:statementId?`,
        { statementId: orgSettingsParam }
      );

      navigate(organizationSettingsDrawerLink);

      return;
    }

    const organizationSettingsDrawerLink = generatePath(
      `${location.pathname}/:creditCardId?`,
      { creditCardId: orgSettingsParam }
    );

    navigate(organizationSettingsDrawerLink);
  };

  const handleClick = () => {
    navigate({
      pathname: settingsCCLink,
      hash: statementCardId,
    });
  };

  return (
    <Flex justifyContent="space-between">
      <Heading as="h1">{t('creditCardPage.title')}</Heading>
      <Flex gap="space24" alignItems="center">
        <Button variant="secondary" onClick={handleOrganizationSettingsClick}>
          {t('creditCardPage.organizationSettings')}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            navigate({
              pathname: settingsCCLink,
              hash: exportCardId,
            });
          }}>
          {t('creditCardPage.exportSettingsLink')}
        </Button>
        {!creditCardsStatementsFF && (
          <Button variant="secondary" onClick={handleClick}>
            {t('creditCardPage.creditCardSettingLink')}
          </Button>
        )}

        <Button alignSelf="center" onClick={openIssueNewCardDrawer}>
          {canIssueCards
            ? t('creditCardPage.issueNewCardCTA')
            : t('transactions:carousel.requestNewCard')}
        </Button>
      </Flex>
    </Flex>
  );
};
