import { KeyboardEvent } from 'react';

const arrowKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'];

export const preventInputPropagation = (e: KeyboardEvent<HTMLInputElement>) => {
  if (arrowKeys.includes(e.key)) e.stopPropagation();
};

export const MIN_CONTRACT_SUBCATEGORY_NAME_LENGTH = 3;
export const MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH = 30;

export enum ContractSubcategoriesSortBy {
  alphabetically = 'alphabetically',
  newestFirst = 'newestFirst',
  mostUsed = 'mostUsed',
}
