import {
  InvoiceAssociationStatus,
  TransactionCategory,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';

export const transactionData = Array.from({ length: 20 }, (_, index) => ({
  cursor: `eyJpdiI6Ii1wRGJPblBaWUFYbFdnaXVMbEcyWEEiLCJkYXRhIjoiVTh3QnFiaTlkLVljX2NLanlRbktDai11cjBJQkEwNFZPMXd0TWlvMW1BRkJHOTB${index}`,
  status:
    index % 2 === 0 ? TransactionStatus.Confirmed : TransactionStatus.Declined,
  id: `f703b614-4821-4398-88ad-d9e2a51d65${index}`,
  merchantName: `Merchant ${index}`,
  transactionAmount: {
    // __typename: 'TransactionAmount',
    value: Math.random() * -1000,
    precision: 2,
    currency: 'EUR',
  },
  grossAmount: {
    value: Math.random() * -1000,
    currency: 'EUR',
  },
  transactionCreatedAt: new Date(Date.now() - Math.floor(Math.random() * 1e10)),
  cardRefNum: `•••• ${Math.floor(1000 + Math.random() * 9000)}`,
  cardholderName: {
    firstName: `FirstName${index}`,
    lastName: `LastName${index}`,
    avatarUrl: null,
    membershipId: `20fc0d65-c465-4b01-a6e2-8ee75a51d0${index}`,
  },
  invoiceAssociationStatus:
    index % 3 === 0
      ? InvoiceAssociationStatus.Uploaded
      : InvoiceAssociationStatus.NotNeeded,
  type: index % 2 === 0 ? TransactionType.Purchase : TransactionType.Refund,
  category:
    index % 3 === 0
      ? TransactionCategory.AdvertisingAndMarketing
      : TransactionCategory.FoodAndDrinks,
}));
