import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  DocumentRelationshipsQuery,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';

type LinkedDocument =
  DocumentRelationshipsQuery['documentRelationships']['relationships'][number]['linkedDocument'];
type RelatedDocument = Extract<
  NonNullable<LinkedDocument>,
  { __typename?: 'RelatedDocument' }
>['document'];

type DocumentRelationship = Extract<
  RelatedDocument,
  { __typename?: 'Document' }
> & {
  globalDocumentId?: string;
};

type AggregatedEcmDocumentRelationship = Extract<
  RelatedDocument,
  { __typename?: 'AggregatedEcmDocument' }
> & {
  globalDocumentId?: string;
};

const mapFromDocumentType = (
  document: DocumentRelationship,
  id?: string | null
): EcmDocumentsTableData[] => [
  {
    id: id ?? '',
    globalDocumentId: document.globalDocumentId ?? '',
    documentType: 'INVOICE',
    contact: document.contact?.value.name.value ?? '',
    invoiceId: id ?? '',
    notes: document.note?.value ?? '',
    documentName: document.documentFile?.name ?? '',
    documentNumber: document.invoiceNumber?.value ?? '',
    documentDate: document.createdAt ? new Date(document.createdAt) : undefined,
    documentStatus: {
      status: (document.status as unknown as EcmDocumentStatus) ?? undefined,
      isSensitive: false,
    },
    tags: document.tags ?? [],
  },
];

const mapFromAggregatedEcmDocumentType = (
  document: AggregatedEcmDocumentRelationship,
  id?: string | null
): EcmDocumentsTableData[] => [
  {
    id: id ?? '',
    globalDocumentId: document.globalDocumentId ?? '',
    documentType: document.type ?? '',
    contact: document.contactName ?? '',
    invoiceId: id ?? '',
    notes: document?.notes ?? '',
    documentName: document?.documentName ?? '',
    documentNumber: document?.documentNumber ?? '',
    documentDate: document?.documentDate
      ? new Date(document?.documentDate)
      : undefined,
    documentStatus: {
      status: document?.documentStatus ?? undefined,
      isSensitive: document?.isSensitive ?? false,
    },
    tags: document?.tags ?? [],
  },
];

export const mapRelationshipToTableRows = (
  r: DocumentRelationshipsQuery['documentRelationships']['relationships'][number]
): EcmDocumentsTableData[] => {
  if (r.linkedDocument?.__typename !== 'RelatedDocument') return [];

  switch (r.linkedDocument.document?.__typename) {
    case 'Document':
      return mapFromDocumentType(
        r.linkedDocument.document,
        r.linkedDocument.id
      );
    case 'AggregatedEcmDocument':
      return mapFromAggregatedEcmDocumentType(
        r.linkedDocument.document,
        r.linkedDocument.id
      );
    default:
      return [];
  }
};
