import React from 'react';
import {
  ColorProps,
  LayoutProps,
  PaddingProps,
  StandardHTMLAttributes,
} from '../../types';
import { Flex } from '../Flex';

export interface OverlayProps
  extends LayoutProps,
    PaddingProps,
    ColorProps,
    StandardHTMLAttributes<HTMLDivElement> {}

/**
 * Overlay atom provides backdrop for various dialog and drawer molecules.
 */
export const OverlayBackground = React.forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      background,
      children,
      position = 'fixed',
      inset = 0,
      zIndex = 100,
      ...restProps
    },
    ref
  ) => {
    return (
      // this is a legit use case for Flexbox - don’t change to Grid!
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        background={background ?? 'rgba(0, 0, 0, 0.4)'}
        position={position}
        inset={inset}
        zIndex={zIndex}
        {...restProps}
        ref={ref}>
        {children}
      </Flex>
    );
  }
);
