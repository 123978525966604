import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import {
  HookFormSelectField,
  HookFormSelectFieldProps,
} from 'components/HookFormFields/HookFormSelectField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectFieldOptions } from 'views/Reimbursement/hooks/types';

interface TaxRepresentationFieldProps<TFormValues extends FieldValues>
  extends HookFormSelectFieldProps<TFormValues> {
  name: Path<TFormValues>;
}

export const TaxRepresentationField = <TFormValues extends FieldValues>({
  name,
  ...props
}: TaxRepresentationFieldProps<TFormValues> & SelectFieldOptions) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <HookFormSelectField
      name={name}
      items={[
        {
          children: t('splitBookings.taxPresentation.net'),
          key: TaxPresentation.Net,
        },
        {
          children: t('splitBookings.taxPresentation.gross'),
          key: TaxPresentation.Gross,
        },
      ]}
      {...props}
    />
  );
};
