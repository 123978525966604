import { Image, Flex, Grid, Text } from '@candisio/design-system';
import { DocumentTransactionLinkedEventDataFragment } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { isNil } from 'lodash';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import sparkles from 'views/CreditCards/CreditCardsInsights/media/sparkles.svg';

export const DocumentTransactionLinkedComponent = ({
  merchantName,
  amount,
  createdAt,
  receiptAutomatchingTaskId,
}: DocumentTransactionLinkedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  if (!createdAt || !merchantName || isNil(amount)) {
    return <Text>{t('documentHistoryCCEvents.transactionLinked')}</Text>;
  }

  const formattedAmount =
    amountFormat(amount.amount, amount.currency, {
      convertToBasicMonetaryUnit: true,
    }) || '-';

  const formattedDate = DateFormatters.compact(new Date(createdAt));
  const showAutoMatchedInfo =
    creditCardsAutomatedMatchingFF && receiptAutomatchingTaskId;

  return (
    <Grid gap="space4">
      <Text>
        {t('documentHistoryCCEvents.transactionLinkedWithDetails', {
          merchantName: merchantName,
          amount: formattedAmount,
          createdAt: formattedDate,
        })}
      </Text>
      {showAutoMatchedInfo && (
        <Flex gap="space4" alignItems="center">
          <Image
            alt="sparkles"
            src={sparkles}
            height="space24"
            width="space24"
          />
          <Text color="blue500">{t('documentStatus.autoMatched')}</Text>
        </Flex>
      )}
    </Grid>
  );
};
