import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { SplitDS } from 'components/Form/SplitBookingsForm/types';
import { useBookingKeysActiveQuery } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { useCallback, useMemo } from 'react';

export const useHasReverseChargeTaxCode = (
  bookings:
    | SplitDS[]
    | ProcessingFormValues['bookings']
    | DocumentApprovalFormValues['bookings']
) => {
  const { shouldUseSapNetAmount } = useSap();

  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery({
    skip: !shouldUseSapNetAmount,
  });

  const availableBookingKeys = bookingKeysActiveData?.bookingKeysActive ?? [];

  return bookings.some(({ taxCode }) => {
    const taxCodeItem = availableBookingKeys.find(item => item.id === taxCode);

    return taxCodeItem && taxCodeItem.isAcquisitionReverse;
  });
};

export const useGetIsReverseChargeTaxCode = () => {
  const { shouldUseSapNetAmount } = useSap();

  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery({
    skip: !shouldUseSapNetAmount,
  });

  const availableBookingKeys = useMemo(
    () => bookingKeysActiveData?.bookingKeysActive ?? [],
    [bookingKeysActiveData]
  );

  const isReverseChargeTaxCode = useCallback(
    (taxCode?: string) => {
      if (!availableBookingKeys.length || !taxCode) {
        return false;
      }

      return availableBookingKeys.some(
        bookingKey =>
          bookingKey.id === taxCode && bookingKey.isAcquisitionReverse
      );
    },
    [availableBookingKeys]
  );

  return isReverseChargeTaxCode;
};
