import { Grid, Spinner } from '@candisio/design-system';
import { PdfViewer } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { memo } from 'react';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ReplaceButton } from './ReplaceButton';

interface ExpenseInvoicePreviewContainerProps {
  invoice?: ExpenseInvoice | null;
  createInvoicePending?: boolean;
  onReplaceInvoice?: () => void;
  isReadOnly?: boolean;
}

export const ExpenseInvoicePreviewContainer = memo(
  ({
    invoice,
    createInvoicePending,
    onReplaceInvoice,
    isReadOnly,
  }: ExpenseInvoicePreviewContainerProps) => {
    if (createInvoicePending) {
      return (
        <Grid
          minHeight="190px"
          background="gray0"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%">
          <Spinner size="space40" />
        </Grid>
      );
    }

    if (!invoice) {
      return null;
    }

    return (
      <Grid
        gap="space24"
        height="100%"
        background="gray0"
        overflow="auto"
        padding="space8 space16"
        borderRadius="medium">
        <PdfViewer
          pdfWrapperId={`exp-invoice-${invoice.id}`}
          documentFile={invoice}
          maxHeight="550px"
          disabledControls={['downloadFiles']}
          attachmentSection={
            <ReplaceButton
              onConfirm={onReplaceInvoice}
              disabled={isReadOnly || createInvoicePending}
            />
          }
        />
      </Grid>
    );
  },
  (prevProps, nextProps) => prevProps.invoice?.id === nextProps.invoice?.id
);
