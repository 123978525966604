import { HistoryLayout } from 'components/History/styles';
import { History } from 'components/History/History';
import { SuppertedReimbursementTimeLineEvents } from 'views/Reimbursement/hooks/useReimbursementHistory';
import moment from 'moment';
import { getActivityTypeToReimbursementHistoryComponent } from 'views/Reimbursement/utils/getActivityTypeToReimbursementHistoryComponent';
import { HistoryLoading } from 'components/History/HistoryLoading';

interface ReimbursementHistoryProps {
  isLoading: boolean;
  timeline: Array<SuppertedReimbursementTimeLineEvents>;
}

export const ReimbursementHistory = ({
  isLoading,
  timeline,
}: ReimbursementHistoryProps) => {
  if (isLoading) {
    return <HistoryLoading itemCount={4} />;
  }

  if (timeline.length === 0) {
    return null;
  }

  return (
    <HistoryLayout>
      <History>
        {timeline
          .map((event, index) => {
            const typename = event.__typename;
            if (!typename) {
              return null;
            }

            const user = event.user;
            const author = user?.name;
            const avatarUrl = user.avatarUrl ?? undefined;

            const historyItem =
              getActivityTypeToReimbursementHistoryComponent[typename];

            if (!historyItem) {
              return null;
            }

            const {
              item: HistoryItem,
              content: HistoryContent,
              ...others
            } = getActivityTypeToReimbursementHistoryComponent[typename];

            const createdAt = moment(event.createdAt);

            return (
              <HistoryItem
                size="big"
                type={typename}
                key={event.id || index}
                author={author}
                avatarUrl={avatarUrl}
                absoluteTime={createdAt.format('LLL')}
                header={createdAt.fromNow()}
                isCollapsible={others.collapsible}
                hideTail={timeline.length - 1 === index}
                defaultCollapse={false}>
                <HistoryContent {...(event as any)} />
              </HistoryItem>
            );
          })
          .filter(Boolean)}
      </History>
    </HistoryLayout>
  );
};
