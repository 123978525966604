import { Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface LinkCardActionProps {
  onClick: () => void;
  vendorName: string;
  hasTransactions: boolean;
}

export const LinkCardToVendorAction = ({
  onClick,
  vendorName,
  hasTransactions,
}: LinkCardActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const handleCreateCardClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  const handleLinkCardClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  if (!hasTransactions) {
    return (
      <Button
        icon="plus"
        size="small"
        variant="tertiary"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          handleCreateCardClick(e)
        }>
        {t('vendorListSuggestion.addCardLabel')}
      </Button>
    );
  }

  return (
    <Button
      icon="creditCardOutline"
      size="small"
      variant="tertiary"
      onClick={(e: MouseEvent<HTMLButtonElement>) => handleLinkCardClick(e)}>
      {t('vendorListSuggestion.linkedCardLabel')}
    </Button>
  );
};
