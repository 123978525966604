import { CSSObject } from '@emotion/react';
import React from 'react';
import { PopoverAria } from 'react-aria';
import { useTheme } from '../Theme';
import { Theme, TokenOrCSSValue } from '../types';

export interface UseArrowCSSConfig {
  arrowStyle?: React.CSSProperties;
  background?: TokenOrCSSValue<'colors', 'background'>;
  placementAxis?: PopoverAria['placement'];
}

/**
 * Hook to generate the CSS ::after pseudo-element for tooltip/popover arrow
 */
export const useArrowCss = ({
  arrowStyle = {},
  background = 'gray0',
  placementAxis = 'top',
}: UseArrowCSSConfig): CSSObject => {
  const { colors, space } = useTheme();

  const placementStyle: Record<string, CSSObject> = {
    top: {
      bottom: `-${space.space4}`,
      transform: 'rotate(45deg) translateX(-50%)',
      transformOrigin: 'left',
    },
    bottom: {
      top: `-${space.space4}`,
      transform: 'rotate(45deg) translateX(-50%)',
      transformOrigin: 'left',
    },
    left: {
      right: `-${space.space4}`,
      transform: 'rotate(45deg) translateY(-50%)',
      transformOrigin: 'top',
    },
    right: {
      left: `-${space.space4}`,
      transform: 'rotate(45deg) translateY(-50%)',
      transformOrigin: 'top',
    },
  };

  return {
    '&::after': {
      position: 'absolute',
      content: '""',
      width: space.space16,
      height: space.space16,
      background:
        background in colors
          ? colors[background as keyof Theme['colors']]
          : background,
      ...arrowStyle,
      ...placementStyle[placementAxis],
      zIndex: -999,
    },
  };
};
