import { DocumentType, Locale } from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useSap } from 'orgConfig/sap';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPE_TRANSLATION_MAP } from 'views/Settings/DocumentTypes/toolkit/consts';
import { useDocumentTypesData } from 'views/Settings/DocumentTypes/toolkit/useDocumentTypesData';

export interface StorageFormDocumentTypeFieldItem {
  key: DocumentType;
  children: string;
}

interface FilterItem {
  id: DocumentType;
  label: string;
}

export type StorageFormDocumentTypeList = [StorageFormDocumentTypeFieldItem];

interface StorageFormDocumentTypeReturn {
  isLoading: boolean;
  /** A map of the DocumentType enum to the translated string */
  ecmDocumentTypeTranslationMap: Record<DocumentType, string>;
  /** A list of select items used in the select component */
  ecmDocumentTypeSelectItems: Array<StorageFormDocumentTypeFieldItem>;
  /** A list of filter items used in the table */
  ecmDocumentTypeFilterOptions: Array<FilterItem>;
}

/**
 * Document types that should be available for SAP users
 */
export const documentTypesForSapUsers = [
  DocumentType.Contract,
  DocumentType.Invoice,
  DocumentType.Offer,
  DocumentType.OrderConfirmation,
  DocumentType.Other,
];

const byLocalCompare = (
  [_a, translationA]: string[],
  [_b, translationB]: string[]
): number => {
  // push "Other" to the end of the list
  if (_a === DocumentType.Other) return 1;
  if (_b === DocumentType.Other) return -1;

  return translationA.localeCompare(translationB);
};

/**
 * A custom hook to get the translation map and filter items for the ECM
 * document type based on the DocumentType enum
 */
export const useEcmDocumentTypeOptions = (): StorageFormDocumentTypeReturn => {
  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const { canUseReimbursement } = useReimbursement();

  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const ecmDocumentTypeTranslationMap = useMemo(() => {
    const translatedMap = Object.entries(DOCUMENT_TYPE_TRANSLATION_MAP).map(
      ([documentType, translationKey]) => [documentType, t(translationKey)]
    );
    return Object.fromEntries(translatedMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, locale]);

  const { isActive: isSapActive } = useSap();
  const { data, loading } = useDocumentTypesData({ isVisible: true });

  const ecmDocumentTypeList = useMemo(() => {
    if (!data?.ecmDocumentTypeSettings) return [];
    return (
      data.ecmDocumentTypeSettings
        // Check if SAP is active to filter out document types that are irrelevant to SAP users
        // Sort list alphabetically, then append "Other" to the end of the list
        .filter(
          ({ name }) =>
            !isSapActive ||
            documentTypesForSapUsers.includes(name as DocumentType)
        )
        .filter(({ name }) => name in ecmDocumentTypeTranslationMap)
        .map(({ name }) => [
          name,
          ecmDocumentTypeTranslationMap[name as DocumentType],
        ])
        .sort(byLocalCompare)
    );
  }, [data, isSapActive, ecmDocumentTypeTranslationMap]);

  // Transform list to match the format of the Select component
  const ecmDocumentTypeSelectItems = ecmDocumentTypeList.map(
    ([key, children]) => ({ key, children } as StorageFormDocumentTypeFieldItem)
  );

  // Transform list to match the format of the table filter
  const ecmDocumentTypeFilterOptions = ecmDocumentTypeList.map(
    ([id, label]) => ({ id, label } as FilterItem)
  );

  const filterOptions = canUseReimbursement
    ? [
        {
          id: DocumentType.ExpenseReimbursementItem,
          label:
            ecmDocumentTypeTranslationMap[
              DocumentType.ExpenseReimbursementItem
            ],
        },
        ...ecmDocumentTypeFilterOptions,
      ]
    : ecmDocumentTypeFilterOptions;

  return {
    ecmDocumentTypeTranslationMap,
    ecmDocumentTypeSelectItems,
    ecmDocumentTypeFilterOptions: filterOptions,
    isLoading: loading,
  };
};
