import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useContext } from 'react';
import { CreditCardsSettlementsPromo } from 'views/Integrations/Export/elements/table/promo/CreditCardsSettlementsPromo';
import { CreditCardsTransactionsPromo } from 'views/Integrations/Export/elements/table/promo/CreditCardsTransactionsPromo';
import { ExportContext } from '../../Context';
import { BdsPromotionCardContainer } from '../../toolkit/components/PromotionCard';
import { Entity, Table } from '../../types';
import {
  ApprovalsDocumentsContainer,
  ApprovalsReimbursementItemsContainer,
  ApprovalsTransactionsContainer,
} from './approvals/Approvals';
import { ContactsContainer } from './contacts/Contacts';
import { PaymentConditionsContainer } from './paymentConditions/PaymentConditions';
import { ProvisionsContainer } from './provisions/Provisions';
import { ReversalsContainer } from './reversals/Reversals';
import { Settlements } from './settlements/Settlements';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ReimbursementPromotionExport } from 'components/ProductPromotions/Reimbursement/ReimbursementsPromotionExport';

export const ExportTable = () => {
  const [errorVisibilityImprovementFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.errorVisibilityImprovement,
  ]);

  const state = useContext(ExportContext);
  const { entity, table } = state;
  const { bdsBought } = useDatev(); // BDS-checked
  const creditCards = useCreditCardsSetup();

  const reimbursementsPromotions = useReimbursementsPromotions();

  const isPromotionForReimbursementsVisible =
    reimbursementsPromotions.variant !== 'noPromotion';

  const showBdsPromo = !errorVisibilityImprovementFF && !bdsBought;

  if (entity === Entity.TRANSACTION && table === Table.APPROVALS) {
    if (creditCards.showPromo) return <CreditCardsTransactionsPromo />;

    return <ApprovalsTransactionsContainer />;
  }

  if (entity === Entity.REIMBURSEMENT_ITEMS) {
    if (isPromotionForReimbursementsVisible) {
      return <ReimbursementPromotionExport />;
    }

    return <ApprovalsReimbursementItemsContainer />;
  }

  if (table === Table.SETTLEMENTS) {
    if (creditCards.showPromo) return <CreditCardsSettlementsPromo />;

    return <Settlements />;
  }

  if (table === Table.REVERSALS) {
    return (
      <>
        {showBdsPromo && <BdsPromotionCardContainer />}
        <ReversalsContainer />
      </>
    );
  }

  if (table === Table.PROVISIONS) {
    return (
      <>
        {showBdsPromo && <BdsPromotionCardContainer />}
        <ProvisionsContainer />
      </>
    );
  }

  if (entity === Entity.DOCUMENT && table === Table.APPROVALS) {
    return <ApprovalsDocumentsContainer />;
  }

  if (table === Table.CONTACTS) {
    return <ContactsContainer />;
  }

  if (table === Table.PAYMENT_CONDITIONS) {
    return <PaymentConditionsContainer />;
  }

  return null;
};
