import { Avatar, Color, Flex, Grid, Tag, Text } from '@candisio/design-system';
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { AnimatePresence } from 'framer-motion';
import { Locale, Maybe, UserRole } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { VerifyEmailCard } from './VerifyCard';

const roleToColorMap: Record<UserRole, Color> = {
  // ACCOUNTANT role is available without the rolesManagementFF feature flag,
  // in that case we are not using colors.
  ACCOUNTANT: 'yellow',
  // OWNER role is not going to be available on user profile page
  OWNER: 'red',
  ADMIN: 'blue',
  APPROVER: 'green',
  CARD_MANAGER: 'pink',
  REQUESTER: 'yellow',
};

export interface UserProfileUpdateFormProps {
  avatar?: Maybe<string>;
  name?: string;
  readOnly?: {
    firstName?: boolean;
    lastName?: boolean;
  };
  newEmail?: string;
  onCancelChangeEmailRequest: () => Promise<void>;
  onRemoveAvatar: () => Promise<void>;
  onResendVerificationEmail: () => Promise<void>;
  onUploadAvatar: (f: File) => Promise<Response | undefined>;
  removeAvatarLoading?: boolean;
  uploadAvatarLoading?: boolean;
  userId?: string;
  userRoles: Maybe<UserRole[]>;
  cancelChangeEmailIsLoading: boolean;
  resendChangeEmailIsLoading: boolean;
  readableMembershipRoles: string[];
}

export const UserProfileUpdateForm = ({
  avatar,
  name,
  newEmail,
  onCancelChangeEmailRequest,
  onRemoveAvatar,
  onResendVerificationEmail,
  onUploadAvatar,
  readOnly,
  removeAvatarLoading,
  uploadAvatarLoading,
  userRoles,
  cancelChangeEmailIsLoading,
  resendChangeEmailIsLoading,
  readableMembershipRoles,
}: UserProfileUpdateFormProps) => {
  const [t] = useTranslation();
  const hasPendingChangeEmailRequest = Boolean(newEmail);
  const rolesManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.rolesManagement
  );

  const { canUseReimbursement } = useReimbursement();

  const locales = Object.values(Locale).map(value => ({
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    children: t(`locales.${value}`),
    key: value,
  }));

  const isNotLastRole = (index: number) =>
    index !== readableMembershipRoles.length - 1;

  const roles = rolesManagementFF ? (
    <Flex
      gap="space8"
      justifySelf="center"
      wrap="wrap"
      alignItems="center"
      justifyContent="center">
      {readableMembershipRoles.map((role, index) => (
        <Flex
          key={role}
          gap="space8"
          fontSize="basic"
          color="gray500"
          alignItems="center">
          <Text>{role}</Text>
          {isNotLastRole(index) && <div>•</div>}
        </Flex>
      ))}
    </Flex>
  ) : (
    <Grid
      autoFlow="column"
      gap="space8"
      justifyContent="center"
      alignItems="center">
      <Text color="gray500" fontSize="basic">
        {t('header.profile.tabs.update.form.yourRole', {
          count: userRoles?.length,
        })}
      </Text>
      {userRoles?.map(role => (
        <Tag key={role} color={roleToColorMap[role]}>
          {
            // eslint-disable-next-line candis/no-template-strings-inside-translation
            t(`settings.teamMembers.roles.${role}.title`)
          }
        </Tag>
      ))}
    </Grid>
  );

  return (
    <Grid gap="space24">
      <AvatarUpload
        onRemove={onRemoveAvatar}
        onUpload={onUploadAvatar}
        hasAvatar={Boolean(avatar)}
        removeAvatarLoading={removeAvatarLoading}
        uploadAvatarLoading={uploadAvatarLoading}>
        <Avatar
          name={name}
          img={avatar ?? undefined}
          size="large"
          hasTooltip={false}
        />
      </AvatarUpload>
      {roles}
      <Grid
        background="gray100"
        borderRadius="8px"
        padding="space16"
        rowGap="space12"
        columnGap={canUseReimbursement ? 'space16' : 'space32'}
        templateColumns="1fr 1fr 1fr 1fr"
        templateRows="auto auto auto">
        <Grid gridRow="1" gridColumn="1/3">
          <HookFormTextField
            readOnly={readOnly?.firstName}
            disabled={readOnly?.firstName}
            label={t('header.profile.tabs.update.form.firstName.label')}
            name="firstName"
          />
        </Grid>
        <Grid gridRow="1" gridColumn="3/5">
          <HookFormTextField
            readOnly={readOnly?.lastName}
            disabled={readOnly?.lastName}
            name="lastName"
            label={t('header.profile.tabs.update.form.lastName.label')}
          />
        </Grid>
        <Grid gridRow="2" gridColumn="1/5" justifyContent="stretch">
          <AnimatePresence>
            {!hasPendingChangeEmailRequest && (
              <HookFormTextField
                name="email"
                label={t('header.profile.tabs.update.form.email.label')}
                inputProps={{
                  type: 'email',
                }}
              />
            )}
          </AnimatePresence>
          <VerifyEmailCard
            newEmail={newEmail}
            hasPendingChangeEmailRequest={Boolean(hasPendingChangeEmailRequest)}
            onCancelChangeEmailRequest={onCancelChangeEmailRequest}
            onResendVerificationEmail={onResendVerificationEmail}
            resendChangeEmailIsLoading={resendChangeEmailIsLoading}
            cancelChangeEmailIsLoading={cancelChangeEmailIsLoading}
          />
        </Grid>
        <Grid gridRow="3" gridColumn="1/3">
          <HookFormSelectField
            name="locale"
            label={t('header.profile.tabs.update.form.language.label')}
            items={locales}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
