import {
  Grid,
  Icon,
  Tooltip,
  useTheme,
  useTooltip,
} from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

interface ReadonlyEmailFieldProps {
  fieldName: string;
}

/**
 * Displays the email as read only in the form with a tooltip.
 * Needs to be mounted within a ReactHookForm context.
 */
export const ReadonlyEmailField = ({ fieldName }: ReadonlyEmailFieldProps) => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const { colors } = useTheme();

  return (
    <>
      <HookFormTextField
        name={fieldName}
        label={t('settings.teamMembers.form.email.label')}
        inputProps={{
          type: 'email',
          style: {
            color: colors.gray500, // TODO this should be possible from top lvl prop
          },
        }}
        disabled={true}
        readOnly={true}
      />
      <Grid paddingRight="space20">
        <Icon
          icon="infoOutline"
          size="space18"
          {...triggerProps}
          ref={triggerRef}
        />
      </Grid>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('settings.teamMembers.form.email.description')}
        </Tooltip>
      )}
    </>
  );
};
