import {
  Flex,
  Grid,
  Link,
  LottieAnimation,
  Text,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ReimbursementsTableEmptyStateArchiveProps {
  isTableFiltered: boolean;
  resetFilters: () => void;
}

export const ReimbursementsTableEmptyStateArchive = ({
  isTableFiltered,
  resetFilters,
}: ReimbursementsTableEmptyStateArchiveProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid
      color="gray800"
      paddingTop="6.5rem"
      paddingBottom="4.5rem"
      minHeight="100%"
      justifyItems="center"
      gap="space16"
      alignItems="center">
      <LottieAnimation
        animationData={isTableFiltered ? 'noresultsData' : 'nocontactData'}
      />
      <Flex direction="column">
        <Text textAlign="center" whiteSpace="pre-wrap">
          {t('table.emptyState.isEmpty')}
        </Text>
        {isTableFiltered && (
          <Link as="button" onClick={resetFilters}>
            {t('table.emptyState.resetFilter')}
          </Link>
        )}
      </Flex>
    </Grid>
  );
};
