import { forwardRef, Ref } from 'react';
import { DateInput } from '../../Atoms/DateInput';
import {
  FieldContainer,
  FieldContainerProps,
} from '../../Atoms/FieldContainer';

export interface DateFieldProps
  extends Omit<FieldContainerProps, 'defaultValue' | 'onChange'> {
  /** Should field receive focus on render? */
  autoFocus?: boolean;
  /** Allow to clear value */
  clearable?: boolean;
  /** message to display in popover of clear button */
  clearLabel?: string;
  /** Locale to use to format date */
  locale?: string;
  /** (Uncontrolled) initial value of the field in ISO 8601 date format */
  defaultValue?: string;
  /** Is field disabled? */
  disabled?: boolean;
  /** Reference to the date input element */
  inputRef?: Ref<HTMLDivElement>;
  /** Called when the value is changed by the user */
  onChange?: (value: string) => void;
  /** Is field read only? */
  readOnly?: boolean;
  /** (Controlled) value of the field in ISO 8601 date format */
  value?: string;
  /** Show message on focus*/
  showMessageOnFocus?: boolean;
}

/** A field for entering dates with the keyboard */
export const DateField = forwardRef<HTMLDivElement, DateFieldProps>(
  (
    {
      'aria-describedby': ariaDescribedby,
      'aria-invalid': ariaInvalid,
      'aria-labelledby': ariaLabelledby,
      autoFocus,
      clearable,
      clearLabel,
      defaultValue,
      disabled,
      id,
      inputRef,
      locale,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      readOnly,
      value,
      showMessageOnFocus = false,
      ...restProps
    },
    ref
  ) => {
    return (
      <FieldContainer
        disabled={disabled}
        readOnly={readOnly}
        {...restProps}
        ref={ref}>
        <DateInput
          aria-describedby={ariaDescribedby}
          aria-invalid={ariaInvalid}
          aria-labelledby={ariaLabelledby}
          autoFocus={autoFocus}
          clearable={clearable}
          clearLabel={clearLabel}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          locale={locale}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          readOnly={readOnly}
          value={value}
          ref={inputRef}
          showMessageOnFocus={showMessageOnFocus}
        />
      </FieldContainer>
    );
  }
);
