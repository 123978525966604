import { useQuery } from '@apollo/client';
import {
  Locale,
  Query,
  useGetMembershipsQuery,
  UserRole,
} from 'generated-types/graphql.types';
import gql from 'graphql-tag';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { organizationDataFragment } from '../queries';

const query = gql`
  query getOrganizations($input: GetOrganizationsInput, $sort: [SortInput!]) {
    getOrganizations(input: $input, sort: $sort) {
      ...OrganizationData
    }
  }
  ${organizationDataFragment}
`;

export const useOrganizationData = () => {
  const organizationId = useOrganizationId();
  const { data, loading: isLoading } = useQuery<Query>(query);

  const { data: organizationMembershipsData, loading: loadingMembershipsData } =
    useGetMembershipsQuery();

  const { organizationMemberships = [] } = organizationMembershipsData || {};

  const organization = data?.getOrganizations?.find(
    org => org.slug === organizationId
  );

  const hasOrgTaxDetails = Boolean(
    organization?.organizationContactInformation?.taxNumber ||
      organization?.organizationContactInformation?.vatId
  );

  const currentOrganizationOwnerId = organizationMemberships.filter(
    membership => membership?.roles?.includes(UserRole.Owner)
  )[0]?.id; // only one owner per organization

  return {
    isLoading,
    locale: organization?.locale ?? Locale.De,
    name: organization?.name ?? '',
    taxNumber: organization?.organizationContactInformation?.taxNumber ?? '',
    vatId: organization?.organizationContactInformation?.vatId ?? '',
    ssoConfigurationId: organization?.ssoConfigurationId ?? '',
    organization,
    hasOrgTaxDetails,
    loadingMembershipsData,
    currentOrganizationOwnerId,
  };
};
