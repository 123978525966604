import {
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { PaymentFrequencyType } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { paymentFrequencyTranslation } from 'views/Settings/CreditCards/CreditCardSettlements/utils';
import { Progressbar } from './Progressbar';

interface FinancialOverviewCreditOrgProps {
  total: number;
  available: number;
  currency: string;
  precision: number;
  plannedPaymentDate: Date | undefined;
  paymentFrequency: PaymentFrequencyType | undefined;
}

const formatterCustomOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
};

export const FinancialOverviewCreditOrg = ({
  available,
  currency,
  precision,
  total,
  plannedPaymentDate,
  paymentFrequency,
}: FinancialOverviewCreditOrgProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { transactionAmountFormatter } = useMoneyFormatter();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const paymentFrequencyText = paymentFrequency
    ? t(paymentFrequencyTranslation[paymentFrequency])
    : undefined;

  const spentAmount = total - available;

  const readableCreditLimit = transactionAmountFormatter(
    {
      currency,
      precision,
      value: total,
    },
    formatterCustomOptions
  );

  const readableAvailableLimit = transactionAmountFormatter(
    {
      currency,
      precision,
      value: available,
    },
    formatterCustomOptions
  );

  const readableSpentAmount = transactionAmountFormatter(
    {
      currency,
      precision,
      value: spentAmount,
    },
    formatterCustomOptions
  );

  return (
    <Card boxShadow="none" background="gray50">
      <Grid gap="space16" height="100%" templateRows="auto 1fr">
        <Grid templateColumns="repeat(2, 1fr)">
          <Flex direction="column">
            <Flex gap="space16" alignItems="center">
              <Heading as="h3" justifySelf="start">
                {t('organizationLimit.details.available')}
              </Heading>
              <Icon
                icon="infoOutline"
                size="space16"
                {...triggerProps}
                ref={triggerRef}
              />
            </Flex>
            {plannedPaymentDate ? (
              <Text>
                {t('organizationLimit.details.plannedPaymentDate', {
                  plannedPaymentDate:
                    DateFormatters.compact(plannedPaymentDate),
                })}
              </Text>
            ) : null}
          </Flex>
          <Heading as="h3" justifySelf="end">
            {readableAvailableLimit}
          </Heading>
        </Grid>
        <Grid gap="space4" alignSelf="end">
          <Grid templateColumns="repeat(2, 1fr)">
            {paymentFrequencyText ? (
              <Text fontSize="basic">
                {t('organizationLimit.details.periodSettlementWithAmount', {
                  settlementPeriod: paymentFrequencyText,
                  amount: readableCreditLimit,
                })}
              </Text>
            ) : null}
            <Text justifySelf="end" fontSize="basic">
              {t('organizationLimit.details.spentWithAmount', {
                amount: readableSpentAmount,
              })}
            </Text>
          </Grid>
          <Progressbar available={available} total={total} />
        </Grid>
      </Grid>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Grid gap="space12">
            <Text>{t('organizationLimit.info.credit.text')}</Text>
            <Link external href={t('organizationLimit.info.credit.url')}>
              {t('organizationLimit.linkText')}
            </Link>
          </Grid>
        </Tooltip>
      )}
    </Card>
  );
};
