import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  MenuItem,
  Modal,
  defaultTheme,
  usePopover,
} from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { AttachmentsTable } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTable';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { Filename } from 'components/Filename/Filename';
import {
  DocumentStatus,
  EcmDocumentStatus,
  DocumentType,
  Money,
} from 'generated-types/graphql.types';
import { Attachment } from 'hooks/useAttachments/useAttachments';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import {
  ComponentProps,
  useRef,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useInboxContext } from 'views/Inbox/Context';
import { useDisabledAttachmentButtonLabelText } from 'views/Inbox/DocumentProcessing/util/useDisabledAttachmentButtonLabelText';
import { ActionConfirm } from './ActionConfirm';
import { ActionsMenu } from './ActionsMenu';
import { ContactSupplierLink } from './ContactSupplier/ContactSupplierLink';
import {
  DocumentSummaryCard,
  DocumentSummaryCardList,
  DocumentSummaryCardListContainer,
} from './DocumentSummaryCard';
import { DocumentSummaryCardActionMenu } from './DocumentSummaryCardActionMenu';
import { useDeleteAction } from './hooks/useDeleteAction';
import { useDetachAction } from './hooks/useDetachAction';
import { RelatedDocument } from './hooks/useDocumentRelations';
import { useFilesSelected } from './useFilesSelected';

const AttachmentIcon = memo(() => (
  <Icon icon="attachment" size="space16" color="gray500" minWidth="space16" />
));

const translationMap = {
  delete: 'document.compact.history.documentAttachment.remove.warning',
  detach: 'document.compact.history.documentAttachment.detach.warning',
  '': '',
} as const;

type allowedState = 'delete' | 'detach' | '';

const AttachmentItemWrapper = ({
  children,
  isActive,
  ...rest
}: ComponentProps<typeof Flex>) => (
  <Box
    as="li"
    role="row"
    width="100%"
    cursor="pointer"
    paddingY="space5"
    paddingLeft="space16"
    paddingRight="space8"
    background={isActive ? 'gray250' : 'gray50'}
    hover={{ background: 'gray250' }}
    {...rest}>
    {children}
  </Box>
);

const AttachmentItem = ({
  isActive = false,
  attachment,
  onAction,
}: {
  isActive?: boolean;
  attachment: Attachment;
  onAction: (attachment: any) => void;
}) => {
  const [t] = useTranslation();

  const [isDetachPending, setIsDetachPending] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const [openedConfirm, setOpenedConfirm] = useState<allowedState>('');

  const {
    open: openPopover,
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerRef,
  } = usePopover({ placement: 'right top' });

  const handleDetach = useDetachAction(attachment, setIsDetachPending);
  const handleDelete = useDeleteAction(attachment, setIsDeletePending);

  const actionsMap = useMemo(
    () => ({
      delete: handleDelete,
      detach: handleDetach,
      '': Promise.resolve,
    }),
    [handleDelete, handleDetach]
  );

  const handleClick = () => onAction(attachment);

  const menuItems: MenuItem[] = [
    attachment.isRemovable && {
      id: 'remove',
      label: t('document.tabs.attachments.deleteAttachment'),
      onAction: () => {
        setOpenedConfirm('delete');
        openPopover();
      },
    },
    attachment.isDetachable && {
      id: 'detach',
      label: t('document.tabs.attachments.detachAttachment'),
      onAction: () => {
        setOpenedConfirm('detach');
        openPopover();
      },
    },
  ].filter(Boolean) as MenuItem[];

  const warningText = t(translationMap[openedConfirm]);

  const actionMenuLabel = t('documentContextMenu.openMenu');
  const isActionPending = isDetachPending || isDeletePending;

  return (
    <>
      <AttachmentItemWrapper
        onPointerDown={handleClick}
        isActive={isActive}
        aria-describedby={attachment.id + '-item'}>
        <Grid
          role="gridcell"
          gap="space4"
          autoFlow="column"
          alignItems="center"
          templateColumns="auto auto 1fr">
          <AttachmentIcon />
          <Filename
            fontSize="small"
            color="gray800"
            fontWeight="semibold"
            id={attachment.id + '-item'}>
            {attachment.name}
          </Filename>
          <Box justifySelf="end" paddingRight="space2">
            <ActionsMenu
              label={actionMenuLabel}
              isActionPending={isActionPending}
              items={menuItems}
              ref={triggerRef}
              size="small"
            />
          </Box>
        </Grid>
      </AttachmentItemWrapper>
      <ActionConfirm
        ref={popoverRef}
        informationText={warningText}
        popoverProps={popoverProps}
        isActionPending={isActionPending}
        closePopover={closePopover}
        handleConfirm={actionsMap[openedConfirm]}
      />
    </>
  );
};

export const DocumentSummaryInformationAdapter = () => {
  const {
    documentId,
    amount,
    invoiceDate,
    invoiceNumber,
    documentStatus,
    selectedContactEmail,
    contactName,
    isEInvoice,
    isDocumentFileLoading: isLoading,
    mainDocumentFile: documentFile,
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
  } = useInboxContext();

  return (
    <DocumentSummaryInformation
      documentId={documentId}
      amount={amount}
      invoiceDate={invoiceDate}
      invoiceNumber={invoiceNumber}
      documentStatus={documentStatus}
      selectedContactEmail={selectedContactEmail}
      contactName={contactName}
      isEInvoice={isEInvoice}
      isLoading={isLoading}
      documentFile={documentFile}
      attachments={attachments}
      attachPermissions={attachPermissions}
      selectedPdf={selectedPdf}
      onAttachmentClick={setSelectedPdf}
    />
  );
};

export type DocumentSummaryInformationProps = {
  documentId: string;
  amount: Money | null;
  invoiceDate: string | undefined;
  invoiceNumber: string | undefined;
  isEInvoice?: boolean;
  documentStatus: DocumentStatus | EcmDocumentStatus | undefined;
  selectedContactEmail?: string;
  contactName: string | undefined;
  isLoading: boolean;
  documentFile: PDFDetails;
  attachments: Attachment[];
  attachPermissions: CanAddAttachments;
  selectedPdf: PDFDetails;
  onAttachmentClick: (..._args: any) => void;
};

export const DocumentSummaryInformation = ({
  documentId,
  amount,
  invoiceDate,
  invoiceNumber,
  isEInvoice,
  documentStatus,
  selectedContactEmail,
  contactName,
  isLoading,
  documentFile,
  attachments,
  attachPermissions,
  selectedPdf,
  onAttachmentClick,
}: DocumentSummaryInformationProps) => {
  const [t] = useTranslation();

  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const openModal = useCallback(() => setAttachmentModalIsOpen(true), []);
  const closeModal = useCallback(() => setAttachmentModalIsOpen(false), []);

  const { onAttachFiles, isAttachingFiles } = useFilesSelected(
    attachments,
    documentFile,
    documentId
  );

  const hasAttachments = attachments.length > 0;

  const canAttach = attachPermissions.fromDisk || attachPermissions.fromCandis;

  const canOnlyAttachFromDisk =
    attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const isAttachActionDisabled =
    !attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const disabledLabel = useDisabledAttachmentButtonLabelText(canAttach);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const onUploadFileClick = () => {
    fileUploadRef.current?.click();
  };

  const handleFileUpload = () => {
    closeModal();
    onUploadFileClick();
  };

  const onFilesChanged = () => {
    if (!fileUploadRef.current?.files) return;

    onAttachFiles(Array.from(fileUploadRef.current.files));
    fileUploadRef.current.value = '';
  };

  // TODO: align the handlers for Summary Cards and Atttachments
  const handleItemClick = hasAttachments
    ? () => onAttachmentClick(documentFile)
    : undefined;

  const document: RelatedDocument = {
    id: documentId,
    amount,
    name: contactName ?? null,
    isEInvoice,
    number: invoiceNumber ?? null,
    status: documentStatus ?? null,
    date: invoiceDate ?? null,
    type: DocumentType.Invoice,
  };

  const attachmentsTitle = t('document.tabs.attachments.title', {
    count: attachments.length,
  });

  return (
    <>
      <DocumentSummaryCardListContainer>
        <DocumentSummaryCard
          as="div"
          borderTopRadius="medium"
          isLoading={isLoading}
          document={document}
          onAction={handleItemClick}
          action={
            <ContactSupplierLink
              selectedContactEmail={selectedContactEmail}
              documentId={documentId}
            />
          }
        />
        {hasAttachments && (
          <CollapsibleCard
            maxHeight="35cqh"
            overflow="hidden"
            id="attachments-section-open"
            headerProps={{
              background: 'gray50',
              hover: { background: 'gray250' },
            }}
            header={attachmentsTitle}
            defaultOpen>
            <DocumentSummaryCardListContainer key="attachments">
              <DocumentSummaryCardList background="gray50">
                {attachments.map(attachment => (
                  <AttachmentItem
                    key={attachment.id}
                    isActive={selectedPdf.id === attachment.id}
                    attachment={attachment}
                    onAction={onAttachmentClick}
                  />
                ))}
              </DocumentSummaryCardList>
            </DocumentSummaryCardListContainer>
          </CollapsibleCard>
        )}
        <AttachActionButton
          openModal={openModal}
          isLoading={isAttachingFiles}
          disabledLabel={disabledLabel}
          isAttachActionDisabled={isAttachActionDisabled}
          canOnlyAttachFromDisk={canOnlyAttachFromDisk}
          onUploadFileClick={onUploadFileClick}
        />
      </DocumentSummaryCardListContainer>

      <input
        type="file"
        accept="application/pdf"
        ref={fileUploadRef}
        onChange={onFilesChanged}
        disabled={!canAttach}
        multiple
        hidden
      />

      <Modal
        background="gray200"
        closeLabel={t('common.close')}
        width="80vw"
        isOpen={attachmentModalIsOpen}
        onClose={closeModal}
        padding="0"
        scrollDirection="none"
        title={t('document.attachments.attachPdfFromCandis')}>
        <AttachmentsTable
          documentId={documentId}
          onDocumentsAttached={closeModal}
          onUploadFileClick={handleFileUpload}
        />
      </Modal>
    </>
  );
};

type AttachActionButtonProps = {
  onUploadFileClick: () => void;
  openModal: () => void;
  isLoading: boolean;
  isAttachActionDisabled: boolean;
  canOnlyAttachFromDisk: boolean;
  disabledLabel?: string;
};

const AttachActionButton = ({
  openModal,
  isAttachActionDisabled,
  canOnlyAttachFromDisk,
  disabledLabel,
  onUploadFileClick,
  isLoading: isAttachingFiles,
}: AttachActionButtonProps) => {
  const [t] = useTranslation();

  if (canOnlyAttachFromDisk || isAttachActionDisabled) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        background="gray50"
        borderBottomLeftRadius="medium"
        borderBottomRightRadius="medium">
        <Button
          icon="plus"
          variant="tertiary"
          style={{
            flexGrow: '1',
            padding: '.75rem 1rem',
            borderRadius: '0',
            borderEndStartRadius: defaultTheme.radii.medium,
            borderEndEndRadius: defaultTheme.radii.medium,
          }}
          label={disabledLabel}
          loading={isAttachingFiles}
          disabled={isAttachActionDisabled}
          onClick={onUploadFileClick}>
          {t('document.tabs.attachments.addAttachment')}
        </Button>
      </Flex>
    );
  }

  return (
    <DocumentSummaryCardActionMenu
      items={[
        {
          id: 'fromCandis',
          label: t('document.attachments.attachPdfFromDisk'),
          onAction: onUploadFileClick,
        },
        {
          id: 'fromDisk',
          label: t('document.attachments.attachPdfFromCandis'),
          onAction: openModal,
        },
      ]}
      loading={isAttachingFiles}>
      {t('document.tabs.attachments.addAttachment')}
    </DocumentSummaryCardActionMenu>
  );
};
