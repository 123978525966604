import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCompletedReimbursementsCursorBasedData } from '../hooks/useCompletedReimbursementsCursorBasedData';
import { CompletedReimbursementList } from './CompletedReimbursementList';

// Completed reimbursement statuses
const statuses = [
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Rejected,
];

export const CompletedReimbursementListContainer = () => {
  const currentUserId = useCurrentUser()?.id ?? '';
  const { loading, reimbursementList, totalReimbursements, loadMore } =
    useCompletedReimbursementsCursorBasedData({
      filters: { statuses, targetMembershipIds: [currentUserId] },
    });

  return (
    <CompletedReimbursementList
      isLoading={loading}
      onEndReached={loadMore}
      reimbursements={reimbursementList}
      reimbursementsCount={totalReimbursements}
    />
  );
};
