import { mergeRefs, useId } from '@candisio/design-system';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { getPageId } from './utils';

const DEFAULT_PDF_RESIZE_WAIT_TIME_IN_MS = 100;
const SCROLLBAR_WIDTH = 10;
const ZOOM_STEP = 0.1;
const INITIAL_SCALE = 1;
const MIN_SCALE = 0.1;
const MAX_SCALE = 4;
type Rotation = 0 | 270 | 180 | 90 | number;
const ROTATION_OPTIONS: Rotation[] = [270, 180, 90, 0];
export const PDF_WRAPPER_ID = 'pdf-wrapper';

export interface UseReactPdfControlsProps {
  smoothScroll?: boolean;
  initialScale?: number;
  pdfWrapperId?: string;
}

export const useReactPdfControls = (
  props: UseReactPdfControlsProps | undefined
) => {
  const smoothScroll = props?.smoothScroll;
  const initialScale = props?.initialScale ?? INITIAL_SCALE;
  const pdfWrapperId = props?.pdfWrapperId ?? PDF_WRAPPER_ID;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [scale, setScale] = useState(initialScale);
  const [rotation, setRotation] = useState<Rotation>(); // we start with rotation === undefined, see TG-2582
  const [fitType, setFitType] = useState<'width' | 'height'>('width');
  const pdfViewerInstanceId = useId();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollContainer = useScrollContainer({
    mouseScroll: {
      ignoreElements: 'span, a',
      inertia: false,
      overscroll: false,
      rubberBand: false,
    },
  });

  const scrollOptions: ScrollIntoViewOptions | undefined = useMemo(() => {
    return smoothScroll ? { behavior: 'smooth' } : undefined;
  }, [smoothScroll]);

  const onZoomIn = useCallback(() => {
    setScale(prevScale => {
      if (prevScale >= MAX_SCALE) return prevScale;
      const newScale = prevScale + ZOOM_STEP;

      return newScale;
    });
  }, []);

  const onZoomOut = useCallback(() => {
    setScale(prevScale => {
      if (prevScale <= MIN_SCALE) return prevScale;
      const newScale = prevScale - ZOOM_STEP;

      return newScale;
    });
  }, []);

  const onFitPageWidth = useCallback(() => {
    setScale(initialScale);
    setFitType('width');
  }, [initialScale]);

  const onFitPageHeight = useCallback(() => {
    setScale(initialScale);
    setFitType('height');
  }, [initialScale]);

  const onRotateLeft = useCallback(() => {
    const currentRotationIndex = ROTATION_OPTIONS.findIndex(
      value => value === rotation
    );

    const nextRotationIndex =
      currentRotationIndex >= ROTATION_OPTIONS.length - 1
        ? 0
        : currentRotationIndex + 1;

    setRotation(ROTATION_OPTIONS[nextRotationIndex]);
  }, [rotation]);

  const onGoToPreviousPage = useCallback(() => {
    const element = document.getElementById(
      getPageId(pdfViewerInstanceId, currentPage - 1)
    );

    element?.scrollIntoView(scrollOptions);
  }, [currentPage, pdfViewerInstanceId, scrollOptions]);

  const onGoToNextPage = useCallback(() => {
    const element = document.getElementById(
      getPageId(pdfViewerInstanceId, currentPage + 1)
    );

    element?.scrollIntoView(scrollOptions);
  }, [currentPage, pdfViewerInstanceId, scrollOptions]);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const handleResize = debounce(() => {
      if (!wrapperRef.current) return;

      setWidth(wrapperRef.current.offsetWidth - SCROLLBAR_WIDTH);
      setHeight(wrapperRef.current.offsetHeight);
    }, DEFAULT_PDF_RESIZE_WAIT_TIME_IN_MS);

    handleResize();
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    const pdfWrapper = document.getElementById(pdfWrapperId);
    if (!pdfWrapper) return;
    resizeObserver.observe(pdfWrapper);

    return () => {
      resizeObserver.unobserve(pdfWrapper);
      resizeObserver.disconnect();
    };
  }, [pdfWrapperId]);

  const roundedScale = Math.round((scale + Number.EPSILON) * 100) / 100;
  const maxZoomInReached = roundedScale >= MAX_SCALE;
  const maxZoomOutReached = roundedScale <= MIN_SCALE;

  const memoizedRef = useMemo(() => {
    return mergeRefs([wrapperRef, scrollContainer.ref]);
  }, [scrollContainer.ref]);

  return {
    currentPage,
    setCurrentPage,
    scale,
    width,
    height,
    rotation,
    fitType,
    onZoomIn,
    maxZoomInReached,
    maxZoomOutReached,
    onZoomOut,
    onRotateLeft,
    onGoToPreviousPage,
    onGoToNextPage,
    onFitPageWidth,
    onFitPageHeight,
    wrapperRef: memoizedRef,
    pdfViewerInstanceId,
  };
};
