import { useUpdateUserPaymentInfoMutation } from 'generated-types/graphql.types';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';

export const useUpdateUserPaymentInfo = () => {
  const [updateUserPaymentInfoMutation, { loading }] =
    useUpdateUserPaymentInfoMutation();

  const handleUpdateUserPaymentInfo = async (
    iban: string | null,
    bic: string | null
  ) => {
    const response = await updateUserPaymentInfoMutation({
      variables: { iban, swiftCode: bic },
      awaitRefetchQueries: true,
      refetchQueries: [
        currentUserQuery,
        'organizationMemberships',
        'reimbursementCaseById',
      ],
    });

    if (response.data?.updateUserPaymentInfo?.success === false) {
      return { status: 'error' };
    }

    return { status: 'success' };
  };

  return {
    handleUpdateUserPaymentInfo,
    isUpdateUserPaymentInfoPending: loading,
  };
};
