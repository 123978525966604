import { Button, ButtonProps, Card, usePopover } from '@candisio/design-system';
import { ConfirmationPopover } from 'components/ConfirmationPopover/ConfirmationPopover';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useResetReimbursementApproval } from 'views/Reimbursement/hooks/useResetReimbursementApproval';

export const ApprovedStatusFooterActionBox = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { error, success } = useToastMessage();

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover();

  const { resetReimbursementApproval, resetReimbursementApprovalPending } =
    useResetReimbursementApproval();

  const handleClick = async () => {
    const { status } = await resetReimbursementApproval();
    if (status === 'error') {
      error(
        t('reimbursementView.rightSection.approvedFooterAction.resetError')
      );
      return;
    }

    success(
      t('reimbursementView.rightSection.approvedFooterAction.resetSuccess')
    );
  };

  const confirmButtonProps: ButtonProps = {
    color: 'blue',
    loading: resetReimbursementApprovalPending,
    disabled: resetReimbursementApprovalPending,
  };

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      <Button
        variant="tertiary"
        width="100%"
        disabled={resetReimbursementApprovalPending}
        loading={resetReimbursementApprovalPending}
        {...triggerProps}
        ref={triggerRef}>
        {t('reimbursementView.rightSection.approvedFooterAction.request')}
      </Button>
      {isOpen && (
        <ConfirmationPopover
          {...popoverProps}
          ref={popoverRef}
          onClose={close}
          onConfirm={handleClick}
          title={t(
            'reimbursementView.rightSection.approvedFooterAction.doubleConfirm.desc'
          )}
          confirmText={t(
            'reimbursementView.rightSection.approvedFooterAction.doubleConfirm.yes'
          )}
          confirmButtonProps={confirmButtonProps}
          closeText={t(
            'reimbursementView.rightSection.approvedFooterAction.doubleConfirm.no'
          )}
        />
      )}
    </Card>
  );
};
