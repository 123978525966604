import React from 'react';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import { ColorProps, LayoutProps, TypographyProps } from '../../../types';
import { Box } from '../../Box';

const DEFAULT_ELEMENT = 'span';

interface TextOwnProps extends LayoutProps, ColorProps, TypographyProps {
  as?: keyof JSX.IntrinsicElements;
}

export type TextProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> = PolymorphicComponentProps<TElement, TextOwnProps>;

type TextType = <TElement extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: TextProps<TElement>
) => React.ReactElement | null;

/**
 * Text is the most generic typography component. Use it for generic text.
 *
 * @param {keyof JSX.IntrinsicElements} as Type of element to be rendered as text. Default `span`.
 *
 * @see [Storybook](https://candisio.github.io/design-system/?path=/docs/atoms-typography-text)
 */
export const Text = React.forwardRef(
  <TElement extends React.ElementType>(
    {
      // Explicitly setting all inheritable TypographyProps to `inherit` to
      // override any global styles
      color = 'inherit',
      fontSize = 'inherit',
      fontWeight = 'inherit',
      fontFamily = 'inherit',
      fontStyle = 'inherit',
      lineHeight = 'inherit',
      textAlign = 'inherit',
      listStyle = 'inherit',
      textTransform = 'inherit',
      whiteSpace = 'inherit',
      overflowWrap = 'inherit',
      wordBreak = 'inherit',
      ...restProps
    }: TextProps<TElement>,
    ref: typeof restProps.ref
  ) => {
    return (
      /** @ts-ignore `as` type mismatch after react upgrade */
      <Box
        as={DEFAULT_ELEMENT}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        lineHeight={lineHeight}
        textAlign={textAlign}
        listStyle={listStyle}
        textTransform={textTransform}
        whiteSpace={whiteSpace}
        overflowWrap={overflowWrap}
        wordBreak={wordBreak}
        {...restProps}
        ref={ref}
      />
    );
  }
) as TextType;
