import { Link } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { nocontactData } from 'components/Lottie/Lottie';
import { Trans } from 'providers/LocaleProvider';

export function DocumentTypesListEmptySearchState({
  onSearchReset,
}: {
  onSearchReset: () => void;
}) {
  return (
    <EmptyState animationData={nocontactData}>
      <Trans i18nKey="settings:emptySearchData">
        No search data.
        <Link as="button" onClick={onSearchReset} />
      </Trans>
    </EmptyState>
  );
}
