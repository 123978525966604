import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useCreditCardRequestCountData } from 'views/CreditCards/hooks/useCreditCardRequestCountData';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuCreditCards = ({ ...iconProps }) => {
  const organization = useFullOrganization();
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const { isAdmin, isAccountant, isCardManager, hasRoles } = useUserRoles();

  const { loading, totalRequests } = useCreditCardRequestCountData();

  const creditCardsRoute =
    isAdmin || isCardManager || isAccountant
      ? Routes.CREDIT_CARDS_DASHBOARD
      : Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW;

  const showCreditCardMenuItem =
    !!organization?.creditCardsDetails && showAllExtraFeatures;

  return (
    <NavigationIcon
      icon="menuCreditCards"
      {...iconProps}
      showMenuIcon={showCreditCardMenuItem && hasRoles}
      route={creditCardsRoute}
      count={isCardManager ? totalRequests : undefined}
      countLoading={loading}
    />
  );
};
