import {
  CardIssuerSettlementMethod,
  CardIssuerSettlementStatus,
  CardIssuerSettlementType,
  CardIssuerSettlementsFilterSortField,
  CardIssuerSettlementsPageBasedQuery,
  CardIssuerSettlementsPageBasedQueryVariables,
  DateFilterOption,
  SortDirection,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { DateFormatters } from 'utils/date_formatter';
import { getCardIssuerSettlementsPageBasedQuery } from '../gql';

export interface Settlement {
  id: string;
  publicPaymentId: string;
  cutOffDate: Date | undefined;
  paymentDate: Date | undefined;
  method: CardIssuerSettlementMethod;
  type: CardIssuerSettlementType;
  amount: string;

  status: CardIssuerSettlementStatus;
}

interface UseGetSettlementsPageBasedDataProps {
  dateFrom?: Date;
  dateTo?: Date;
  limit?: number;
  status: CardIssuerSettlementStatus | undefined;
}

export const useGetSettlementsPageBasedData = ({
  limit,
  status,
  dateFrom,
  dateTo,
}: UseGetSettlementsPageBasedDataProps) => {
  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const { transactionAmountFormatter } = useMoneyFormatter();

  const { data, loading, onLoadMore } = usePagePagination<
    CardIssuerSettlementsPageBasedQuery,
    CardIssuerSettlementsPageBasedQueryVariables
  >(getCardIssuerSettlementsPageBasedQuery, 'cardIssuerSettlementsPageBased', {
    variables: {
      input: {
        limit: limit ?? undefined,
      },
      filters: {
        statuses: status ? [status] : undefined,
      },
      sortBy: {
        direction: SortDirection.Desc,
        field: CardIssuerSettlementsFilterSortField.PaymentDate,
      },
      dateRangeFilters:
        dateFrom && dateTo
          ? {
              paymentDateFilter: {
                dateFrom: DateFormatters.germanDate(dateFrom),
                dateTo: DateFormatters.germanDate(dateTo),
                filterOption: DateFilterOption.Custom,
              },
            }
          : undefined,
    },
    skip: !creditCardsStatementsFF,
  });

  const totalCount =
    data?.cardIssuerSettlementsPageBased?.pageInfo?.totalCount ?? 0;

  const hasMore =
    (data?.cardIssuerSettlementsPageBased?.records?.length ?? 0) < totalCount;

  const settlements: Settlement[] = (
    data?.cardIssuerSettlementsPageBased.records ?? []
  ).map(p => {
    return {
      id: p.id,
      publicPaymentId: p.publicPaymentId,
      cutOffDate: p.cutOffDate ? new Date(p.cutOffDate) : undefined,
      paymentDate: p.paymentDate ? new Date(p.paymentDate) : undefined,
      method: p.method,
      status: p.status,

      type: p.type,
      amount: transactionAmountFormatter({
        currency: p.amount.currency,
        precision: p.amount.precision,
        value: p.amount.value,
      }),
    };
  });

  const settlementList = {
    settlements,
    totalCount,
  };

  return { loading, settlementList, onLoadMore, hasMore };
};
