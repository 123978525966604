import { createContext, useContext } from 'react';
import { ProcessingFormValues } from './processingFormSchema';

export const PROCESSING_FORM_FIELD_METADATA_SOURCES = [
  'contact',
  'costCenter',
  'gini-approver',
  'gini',
  'eInvoice',
  'smartFieldSuggestions',
  'templateBased',
  'transaction',
  'creditCard',
  'purchaseOrder',
] as const;

export type ProcessingFormFieldMetadataSource =
  (typeof PROCESSING_FORM_FIELD_METADATA_SOURCES)[number];

/** Metadata for individual processing form field */
export interface ProcessingFormFieldMetadata {
  confidence: number;
  source: ProcessingFormFieldMetadataSource;
}

/** Represents processing form metadata mirroring structure of the form data */
export type ProcessingFormMetadata<TValues = ProcessingFormValues> = Partial<{
  [TKey in keyof TValues]: TValues[TKey] extends Record<string, any>
    ? ProcessingFormFieldMetadata | ProcessingFormMetadata<TValues[TKey]>
    : ProcessingFormFieldMetadata;
}>;

export const ProcessingFormMetadataContext =
  createContext<ProcessingFormMetadata>({});

/** Returns processing form metadata */
export const useProcessingFormMetadata = () => {
  return useContext(ProcessingFormMetadataContext);
};
