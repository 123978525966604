import { Flex } from '@candisio/design-system';
import { EmptyState } from 'components/EmptyViews/EmptyState';
import { EmptyDataText } from 'components/EmptyViews/styles';
import { nodocumentData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import { Entity } from 'views/Integrations/Export/types';

const translationKeys = {
  [Entity.DOCUMENT]: {
    title: 'exportedEmptyState.invoices.title',
    description: 'exportedEmptyState.invoices.description',
  },
  [Entity.TRANSACTION]: {
    title: 'exportedEmptyState.creditCardTransactions.title',
    description: 'exportedEmptyState.creditCardTransactions.description',
  },
  [Entity.CORE_DATA]: {
    title: 'exportedEmptyState.coreData.title',
    description: 'exportedEmptyState.coreData.description',
  },
  [Entity.REIMBURSEMENT_ITEMS]: {
    title: 'exportedEmptyState.expenses.title',
    description: 'exportedEmptyState.expenses.description',
  },
};

export const HistoryDocuments = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const { entity } = useContext(ExportContext);

  const { title, description } = translationKeys[entity];

  return (
    <EmptyState animationData={nodocumentData} emptyDataText={t(title)}>
      <Flex justifyContent="center">
        <EmptyDataText>
          <Trans t={t} i18nKey={description}></Trans>
        </EmptyDataText>
      </Flex>
    </EmptyState>
  );
};
