import { usePromotionsContext } from 'components/NewPromotions/context/PromotionsProvider';
import { Promotion } from 'components/NewPromotions/types';
import { CrossfadeImage } from '../../toolkit/CrossfadeImage';
export const PromotionImages = ({ promotion }: { promotion: Promotion }) => {
  const { benefits, contents } = promotion;
  const { currentBenefitIndex } = usePromotionsContext();

  let src;
  if (benefits) src = benefits?.[currentBenefitIndex]?.imageUrl;
  if (contents) src = contents.imageUrl;

  if (src === undefined) return null;

  return <CrossfadeImage src={src} />;
};
