import { Flex, Button, Heading, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { DateFormatters } from 'utils/date_formatter';
import {
  phoneNumberCardId,
  scrollToTargetParam,
} from 'views/AppContainer/components/Header/components/UserProfile/AccountSecurity/PhoneNumberMfa/PhoneNumberCard';

interface MissingPhoneNumberProps {
  createdAt: string;
}

export const MissingPhoneNumber = ({ createdAt }: MissingPhoneNumberProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set(scrollToTargetParam, phoneNumberCardId);
    navigate({
      pathname: location.pathname,
      hash: 'userProfile',
      search: urlSearchParams.toString(),
    });
  };

  return (
    <Flex direction="column" gap="space24">
      <Flex direction="column" gap="space4">
        <Heading as="h2">{t('carousel.processing.title')}</Heading>
        <Text color="gray600">
          {t('carousel.processing.issuedOn', {
            date: DateFormatters.compact(new Date(createdAt)),
          })}
        </Text>
      </Flex>
      <Flex direction="column" gap="space8" width="70%">
        <Text fontSize="basic">
          {t('carousel.processing.missingPhoneNumberText')}
        </Text>
        {/** Remove inline style when antD is completely removed */}
        <Button
          as="a"
          onClick={handleClick}
          alignSelf="start"
          style={{ color: 'white' }}>
          {t('carousel.processing.ctaMissingPhoneNumber')}
        </Button>
      </Flex>
    </Flex>
  );
};
