import {
  Box,
  FieldContainer,
  Flex,
  Grid,
  Icon,
  Link,
  Text,
} from '@candisio/design-system';
import { DocumentTypesFieldItem } from 'components/Form/hooks/useDocumentTypeFieldOptions';
import { ComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { DocumentTagsFieldContainer } from 'containers/Tags/DocumentTagsFieldContainer';
import { motion } from 'framer-motion';
import {
  EcmDocumentStatus,
  DocumentType,
  User,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { getTranslationContext } from 'orgConfig';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, ReactNode, isValidElement } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { ProcessingFormOverlay } from '../AddContact/ProcessingFormOverlay';
import { StorageFormActions } from './StorageFormActions';
import { StorageFormAmountField } from './StorageFormAmountField';
import { StorageFormComboBoxField } from './StorageFormComboBoxField';
import {
  StorageFormContactField,
  StorageFormContactFieldItem,
} from './StorageFormContactField';
import { StorageFormContractTypeField } from './StorageFormContractTypeField';
import { StorageFormDateField } from './StorageFormDateField';
import { StorageFormDocumentTypeField } from './StorageFormDocumentTypeField';
import { storageFormErrors } from './storageFormErrors';
import { StorageFormMetadata } from './StorageFormMetadataContext';
import { StorageFormMetadataProvider } from './StorageFormMetadataProvider';
import { StorageFormPaginatedComboBoxField } from './StorageFormPaginatedComboBoxField';
import { StorageFormReminderDateFields } from './StorageFormReminderDateFields';
import {
  StorageFormMultiplePeopleFieldItem,
  StorageFormNotifyPersonField,
  StorageFormResponsiblePersonField,
} from './StorageFormResponsiblePersonField';
import { StorageFormValues, storageFormSchema } from './storageFormSchema';
import { StorageFormSensitiveSwitchField } from './StorageFormSensitiveSwitchField';
import { StorageFormTextField } from './StorageFormTextField';
import { StorageFormDocumentTypeFieldItem } from './useEcmDocumentTypeItems';
import { UseStorageFormInitialDataReturn } from './useStorageFormInitialData';
import { useWatchStorageForm } from './useWatchStorageForm';
import { AddDocumentTypeActionBox } from '../AddActionBox/AddDocumentTypeActionBox';
import { useUserRoles } from 'hooks/useUserRoles';

const MotionGrid = motion(Grid);
export interface SelectFieldOptions<
  TItem extends { key: Key } = { key: Key; children: ReactNode }
> {
  items?: TItem[];
}

interface PaginatedComboBoxFieldOptions<
  TItem extends { key: Key } = { key: Key; children: ReactNode }
> {
  getItem?: (value: string) => Promise<TItem | undefined>;
  items?: TItem[];
  onEndReached?: () => void;
  onSearch?: (inputValue: string) => Promise<void>;
}

export interface StorageFormFieldOptions {
  /** Options for document type field */
  documentType?: {
    /** @deprecated */
    items?: StorageFormDocumentTypeFieldItem[];
    newItems?: DocumentTypesFieldItem[];
    groupedItems?: DocumentTypesFieldItem[][];
  };
  /** Options for contact field */
  contact?: PaginatedComboBoxFieldOptions<StorageFormContactFieldItem>;
  /** Options for contract type field */
  documentSubCategory?: ComboBoxFieldOptions;
  /** Options for cost center field or `false` to hide the field */
  costCenter?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for currency field */
  currency?: ComboBoxFieldOptions;
  /** Options for responsible person field */
  responsiblePerson?: ComboBoxFieldOptions<StorageFormMultiplePeopleFieldItem>;
  /** Options for informed person field */
  notifyPerson?: ComboBoxFieldOptions<StorageFormMultiplePeopleFieldItem>;
}

export type StorageFormDefaultValues = DefaultValues<StorageFormValues>;

export type StorageFormProps = {
  createdBy?: Pick<User, 'avatarUrl' | 'id' | 'name'>;
  /** Initial field metadata */
  defaultMetadata?: StorageFormMetadata;
  /** Initial values when the form is first rendered  */
  defaultValues?: StorageFormDefaultValues;
  /** Document status */
  documentStatus?: EcmDocumentStatus;
  /** Individual field options */
  fieldOptions?: StorageFormFieldOptions;
  /** Determines which document data should be fetched/updated, invoice or ECM */
  isInvoice?: boolean;
  /** Called when add contact form should be shown. */
  onAddContact?: () => void;
  /** Called when the document should be deleted */
  onDeleteDocument?: () => Promise<void>;
  /** Called when edit contact form should be shown */
  onEditContact?: () => void;
  /** Called when form is submitted */
  onSubmit: (values: StorageFormValues) => void;
  /** Display form as read only */
  readOnly?: boolean;
  /** Subform to show in an overlay on top of the form  */
  subform?: ReactNode;
  /** Global document id that will replace documentId and ecmDocumentId in the future */
  globalDocumentId: string;
  /** ID of the document that the form renders. Can be either ecmDocumentId or documentId. */
  documentId?: string;
} & Pick<UseStorageFormInitialDataReturn, 'tags'>;

const fallbackDefaultValues = {
  contact: { value: null, inputValue: '' },
  documentDate: null,
  documentNumber: null,
  notes: null,
  costCenter: { value: null, inputValue: '' },
  responsiblePerson: null,
  isSensitive: false,
} satisfies DefaultValues<StorageFormValues>;

const Form = ({
  createdBy,
  defaultValues,
  documentStatus,
  fieldOptions,
  isInvoice,
  onAddContact,
  onDeleteDocument,
  onEditContact,
  onSubmit,
  subform,
  globalDocumentId,
  documentId,
  tags,
  readOnly,
}: StorageFormProps) => {
  const { showDocumentTags, showEcmContractManagement } = useEcm();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const [classificationFrontendFF, documentTypesSettingFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.classificationFrontend,
      FEATURE_FLAGS.documentTypesSetting,
    ]);
  const { isAdmin } = useUserRoles();

  // Only documents uploaded as sensitive contracts have status "New"
  // until they are stored, the rest has status "Stored"
  const isNewSensitiveContract = documentStatus === EcmDocumentStatus.New;

  const form = useForm<StorageFormValues>({
    defaultValues: defaultValues ?? fallbackDefaultValues,
    // Validation will trigger on the first blur event. After that, it will
    // trigger on every change event.
    mode: 'onTouched',
    resolver: zodResolver({
      zodSchema: storageFormSchema,
      errorMessages: storageFormErrors,
      translationNamespace: LOCALE_NAME_SPACE.ECM,
    }),
  });

  useWatchStorageForm({ form });

  const resetIsSensitive = () => form.setValue('isSensitive', true);

  const isContract = form.watch('documentType') === DocumentType.Contract;
  const isSensitiveDocument = defaultValues?.isSensitive ?? false;

  const showContractFields = showEcmContractManagement && isContract;

  const showCostCenterField = !fieldOptions?.costCenter?.hidden;
  const hasSubform = isValidElement(subform);

  const helpCenterLink = {
    url: isContract
      ? t('storageForm.helpCenter.cmUrl')
      : t('storageForm.helpCenter.ecmUrl'),
    text: isContract
      ? t('storageForm.helpCenter.cmText')
      : t('storageForm.helpCenter.ecmText'),
  };

  return (
    <FormProvider {...form}>
      <MotionGrid
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
        gap="space16"
        alignContent="space-between"
        height="100%"
        animate={hasSubform ? 'hidden' : 'visible'}
        variants={{
          visible: { display: 'grid', opacity: 1 },
          hidden: { opacity: 0, transitionEnd: { display: 'none' } },
        }}>
        <Grid gap="space16" paddingX="space8">
          {isInvoice && (
            // show a link to help center tutorial if document is an invoice
            // delete this block when we no longer need to show help center link
            <Grid justifyContent="center">
              <Link href={helpCenterLink.url} external lineHeight="space16">
                {helpCenterLink.text}
              </Link>
            </Grid>
          )}

          {isSensitiveDocument && (
            // We don't use Card here because `borderBottom` needs to
            // have full length of the container, and this way we don't
            // need to completely override Card's padding to achieve this
            <Grid as="fieldset" background="gray50" borderRadius="basic">
              {!isNewSensitiveContract && (
                <Box borderBottom="1px solid gray250" padding="0 space4">
                  <StorageFormSensitiveSwitchField
                    name="isSensitive"
                    label={t('storageForm.fields.restrictedAccess.label')}
                    disabled={readOnly}
                    onReset={resetIsSensitive}
                  />
                </Box>
              )}

              <Grid gap="space16" padding="space12">
                <Flex fontSize="basic" alignItems="center" gap="space8">
                  <Icon icon="lockFilled" />
                  <Text>{t('storageForm.visibility')}</Text>
                </Flex>

                <Grid as="fieldset" gap="space16">
                  <StorageFormResponsiblePersonField
                    name="responsiblePerson"
                    label={t('storageForm.fields.responsiblePerson.label')}
                    readOnly={readOnly}
                    placeholder={t(
                      'storageForm.fields.responsiblePerson.placeholder'
                    )}
                    {...fieldOptions?.responsiblePerson}
                  />

                  <StorageFormNotifyPersonField
                    name="notifyPerson"
                    readOnly={readOnly}
                    placeholder={t(
                      'storageForm.fields.notifyPerson.placeholder'
                    )}
                    {...fieldOptions?.notifyPerson}
                  />
                </Grid>

                {/*
                 * "Uploaded by" is not form's field, but we want to make it
                 * appear as a read-only TextField to better fit in with
                 * other fields
                 */}
                <FieldContainer
                  label={t('storageForm.fields.uplader')}
                  readOnly
                  style={{ paddingBottom: 'space8' }}>
                  <Text color="gray600" fontSize="basic">
                    {createdBy?.name}
                  </Text>
                </FieldContainer>
              </Grid>
            </Grid>
          )}

          <Grid as="fieldset" gap="space16">
            {classificationFrontendFF &&
            documentStatus !== EcmDocumentStatus.Stored ? (
              <StorageFormDocumentTypeField
                name="documentType"
                label={t('storageForm.fields.documentType.label')}
                readOnly={
                  isSensitiveDocument || isNewSensitiveContract || readOnly
                }
                placeholder={t('storageForm.fields.documentType.placeholder')}
                {...fieldOptions?.documentType}
              />
            ) : (
              <HookFormSelectField
                name="documentType"
                label={t('storageForm.fields.documentType.label')}
                readOnly={
                  isSensitiveDocument || isNewSensitiveContract || readOnly
                }
                placeholder={t('storageForm.fields.documentType.placeholder')}
                {...fieldOptions?.documentType}
                renderCustomDropdown={list => (
                  <>
                    {list}
                    {documentTypesSettingFF && isAdmin && (
                      <AddDocumentTypeActionBox />
                    )}
                  </>
                )}
                dataCy="documentType"
              />
            )}

            {showContractFields && (
              <StorageFormContractTypeField
                name="documentSubCategory"
                label={t('storageForm.fields.contractType.label')}
                readOnly={readOnly}
                {...fieldOptions?.documentSubCategory}
              />
            )}
            <StorageFormTextField
              name="documentName"
              label={t('storageForm.fields.documentName.label')}
              readOnly={readOnly}
              placeholder={t('storageForm.fields.documentName.placeholder')}
            />
            <StorageFormContactField
              name="contact"
              label={t('storageForm.fields.contact.label')}
              readOnly={readOnly}
              placeholder={t('storageForm.fields.contact.placeholder')}
              onAddContact={onAddContact}
              onEditContact={onEditContact}
              {...fieldOptions?.contact}
            />

            {showDocumentTags && (
              <DocumentTagsFieldContainer
                globalDocumentId={globalDocumentId}
                documentId={documentId}
                isInvoice={isInvoice}
                documentTags={tags?.map(t => t.id) ?? []}
                readOnly={readOnly}
              />
            )}

            <StorageFormDateField
              name="documentDate"
              label={t('storageForm.fields.documentDate.label')}
              readOnly={readOnly}
            />
            <StorageFormTextField
              name="documentNumber"
              label={t('storageForm.fields.documentNumber.label')}
              readOnly={readOnly}
              placeholder={t('storageForm.fields.documentNumber.placeholder')}
            />

            {showContractFields && showCostCenterField && (
              <StorageFormPaginatedComboBoxField
                name="costCenter"
                label={t(
                  'common:document.requestApproval.inputs.costCenter.label',
                  {
                    context: getTranslationContext(),
                  }
                )}
                readOnly={readOnly}
                placeholder={t(
                  'common:document.requestApproval.inputs.costCenter.placeholder'
                )}
                {...fieldOptions?.costCenter?.props}
              />
            )}

            {showContractFields && (
              <Grid columns={2} gap="space8">
                <StorageFormAmountField
                  name="amount"
                  label={t('storageForm.fields.amount.label')}
                  readOnly={readOnly}
                  placeholder={t('storageForm.fields.amount.placeholder')}
                />

                <StorageFormComboBoxField
                  name="currency"
                  label={t('storageForm.fields.currency.label')}
                  readOnly={readOnly}
                  placeholder={t('storageForm.fields.currency.placeholder')}
                  {...fieldOptions?.currency}
                />
              </Grid>
            )}
          </Grid>

          {showContractFields && (
            <>
              <Grid as="fieldset" gap="space16">
                <Grid
                  columnGap="space8"
                  rowGap="space16"
                  templateColumns="repeat(auto-fit, minmax(10rem ,1fr))">
                  <StorageFormDateField
                    name="startDate"
                    label={t('storageForm.fields.startDate.label')}
                    readOnly={readOnly}
                  />

                  <StorageFormDateField
                    name="endDate"
                    label={t('storageForm.fields.endDate.label')}
                    readOnly={readOnly}
                  />
                </Grid>

                <StorageFormDateField
                  name="terminationDate"
                  label={t('storageForm.fields.terminationDate.label')}
                  readOnly={readOnly}
                />

                <StorageFormReminderDateFields readOnly={readOnly} />
              </Grid>

              {!isSensitiveDocument && (
                <Grid as="fieldset" gap="space16">
                  <StorageFormResponsiblePersonField
                    name="responsiblePerson"
                    label={t('storageForm.fields.responsiblePerson.label')}
                    readOnly={readOnly}
                    placeholder={t(
                      'storageForm.fields.responsiblePerson.placeholder'
                    )}
                    {...fieldOptions?.responsiblePerson}
                  />

                  <StorageFormNotifyPersonField
                    name="notifyPerson"
                    readOnly={readOnly}
                    placeholder={t(
                      'storageForm.fields.notifyPerson.placeholder'
                    )}
                    {...fieldOptions?.notifyPerson}
                  />
                </Grid>
              )}
            </>
          )}

          <HookFormTextareaField
            name="notes"
            label={t('storageForm.fields.notes.label')}
            readOnly={readOnly}
            placeholder={t('storageForm.fields.notes.placeholder')}
          />
        </Grid>

        {!readOnly && (
          <StorageFormActions
            isInvoice={isInvoice}
            isNewSensitiveContract={isNewSensitiveContract}
            onDeleteDocument={onDeleteDocument}
          />
        )}
      </MotionGrid>

      <ProcessingFormOverlay isOpen={hasSubform}>
        {subform}
      </ProcessingFormOverlay>
    </FormProvider>
  );
};

// We need this separate component so we can update storage form metadata
// context value at the top level of the form without rerendering the whole form
export const StorageForm = ({
  defaultMetadata,
  ...restProps
}: StorageFormProps) => {
  return (
    <StorageFormMetadataProvider defaultMetadata={defaultMetadata}>
      <Form {...restProps} />
    </StorageFormMetadataProvider>
  );
};
