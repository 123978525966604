import { noop } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { isExportTypeWithTimeframe } from './toolkit/utils';
import {
  Entity,
  ExportType,
  SEARCH_PARAM,
  Sidebar,
  Table,
  View,
} from './types';

export type AvailableEntity = Exclude<
  keyof typeof Entity,
  typeof Entity.SETTLEMENT
>;

export type TableType = keyof typeof Table;

export type ExportContextProps = {
  view: View;
  setView: (view: View) => void;

  entity: AvailableEntity;
  setEntity: (entity: AvailableEntity) => void;

  table: TableType;
  setTable: (table: TableType) => void;

  sidebar: Sidebar;
  setSidebar: (sidebar: Sidebar) => void;

  exportId: string | null;
  setExportId: (exportId: string | null) => void;

  exportType: ExportType | null;
  setExportType: (exportType: ExportType | null) => void;

  timeframe: string | null;
  setTimeframe: (timeframe: string | null) => void;

  isReExport: boolean;
  setIsReExport: (isReExport: boolean) => void;

  shouldTriggerDownload: boolean;
  setShouldTriggerDownload: (shouldTriggerDownload: boolean) => void;

  selectedProvisions: string[];
  setSelectedProvisions: Dispatch<SetStateAction<string[]>>;

  includeEntitiesWithoutDoc: boolean;
  setIncludeEntitiesWithoutDoc: Dispatch<SetStateAction<boolean>>;

  includeAllContacts: boolean;
  setIncludeAllContacts: Dispatch<SetStateAction<boolean>>;

  includeAllPaymentConditions: boolean;
  setIncludeAllPaymentConditions: Dispatch<SetStateAction<boolean>>;

  // view config
  availableExportTypes: ExportType[];
  setAvailableExportTypes: (availableExportTypes: ExportType[]) => void;

  availableEntities: AvailableEntity[];
  setAvailableEntities: (availableEntities: AvailableEntity[]) => void;

  availableTables: TableType[];
  setAvailableTables: (availableTables: TableType[]) => void;

  availableViews: View[];
  setAvailableViews: (availableViews: View[]) => void;

  tableId: `export-${View}-${AvailableEntity}-${TableType}-table-configurations`;
};

export const exportTypes = {
  default: [
    ExportType.DATEV,
    ExportType.DATEV_BDS,
    ExportType.DATEV_DXSO_ZIP,
    ExportType.DATEV_CSV,
    ExportType.CANDIS_CSV,
    ExportType.ADDISON_FORMAT,
    ExportType.ZIP,
  ],
  datev: [ExportType.DATEV_DXSO_ZIP, ExportType.DATEV_CSV, ExportType.ZIP],
  datevAdjacent: [
    ExportType.DATEV_DXSO_ZIP,
    ExportType.DATEV_CSV,
    ExportType.ADDISON_FORMAT,
    ExportType.ZIP,
  ],
  sap: [ExportType.SAP_CSV, ExportType.CANDIS_CSV, ExportType.ZIP],
  sapWithB1: [
    ExportType.SAP_B1,
    ExportType.SAP_CSV,
    ExportType.CANDIS_CSV,
    ExportType.ZIP,
  ],
  other: [ExportType.CANDIS_CSV, ExportType.ZIP],
  candisApi: [ExportType.API],
};

export const entities: {
  default: AvailableEntity[];
  sap: AvailableEntity[];
  candisApi: AvailableEntity[];
} = {
  default: [
    Entity.DOCUMENT,
    Entity.TRANSACTION,
    Entity.CORE_DATA,
    Entity.REIMBURSEMENT_ITEMS,
  ],
  sap: [Entity.DOCUMENT],
  candisApi: [Entity.DOCUMENT],
};

export const tables = {
  default: [
    Table.APPROVALS,
    Table.PROVISIONS,
    Table.REVERSALS,
    Table.SETTLEMENTS,
    Table.CONTACTS,
    Table.PAYMENT_CONDITIONS,
  ],
  sap: [Table.APPROVALS],
  candisApi: [Table.APPROVALS],
};

export const views = {
  default: [View.READY, View.NOT_READY, View.HISTORY],
  sap: [View.READY, View.HISTORY],
  candisApi: [View.READY, View.HISTORY],
};

const initialTableId: ExportContextProps['tableId'] = `export-${View.READY}-${Entity.DOCUMENT}-APPROVALS-table-configurations`;

const initialState = {
  view: View.READY,
  setView: () => null,
  entity: Entity.DOCUMENT,
  setEntity: () => null,
  table: Table.APPROVALS,
  setTable: () => null,
  sidebar: Sidebar.INIT_NEW_EXPORT,
  setSidebar: () => null,
  exportId: null,
  setExportId: () => null,
  exportType: null,
  setExportType: () => null,
  timeframe: null,
  setTimeframe: () => null,
  isReExport: false,
  setIsReExport: () => null,
  shouldTriggerDownload: false,
  setShouldTriggerDownload: () => null,
  selectedProvisions: [],
  setSelectedProvisions: noop,
  includeEntitiesWithoutDoc: false,
  setIncludeEntitiesWithoutDoc: noop,
  includeAllContacts: false,
  setIncludeAllContacts: noop,
  includeAllPaymentConditions: false,
  setIncludeAllPaymentConditions: noop,

  // view config
  availableExportTypes: exportTypes.default,
  setAvailableExportTypes: () => null,
  availableEntities: entities.default,
  setAvailableEntities: () => null,
  availableTables: tables.default,
  setAvailableTables: () => null,
  availableViews: views.default,
  setAvailableViews: () => null,

  tableId: initialTableId,
};

export const ExportContext = createContext<ExportContextProps>(initialState);

interface ExportProviderProps {
  children: ReactNode;
  exportTypes?: ExportType[];
  entities?: AvailableEntity[];
  tables?: TableType[];
  views?: View[];
}

export const IntegrationsExportProvider = ({
  children,
  exportTypes: initialExportTypes,
  entities: initialEntities,
  tables: initialTables,
  views: initialViews,
}: ExportProviderProps) => {
  const [search, setSearch] = useSearchParams();

  // View Config
  const [availableExportTypes, setAvailableExportTypes] = useState<
    ExportType[]
  >(initialExportTypes ?? exportTypes.default);

  const [availableEntities, setAvailableEntities] = useState<AvailableEntity[]>(
    initialEntities ?? entities.default
  );

  const [availableTables, setAvailableTables] = useState<TableType[]>(
    initialTables ?? tables.default
  );

  const [availableViews, setAvailableViews] = useState<View[]>(
    initialViews ?? views.default
  );

  // View State
  const [selectedProvisions, setSelectedProvisions] = useState<string[]>([]);

  const [includeEntitiesWithoutDoc, setIncludeEntitiesWithoutDoc] =
    useState<boolean>(false);

  const [includeAllContacts, setIncludeAllContacts] = useState<boolean>(false);

  const [includeAllPaymentConditions, setIncludeAllPaymentConditions] =
    useState<boolean>(false);

  const view = (search.get(SEARCH_PARAM.VIEW) as View | null) ?? View.READY;
  const entity =
    (search.get(SEARCH_PARAM.ENTITY) as AvailableEntity | null) ??
    Entity.DOCUMENT;

  const table =
    (search.get(SEARCH_PARAM.TABLE) as TableType | null) ?? Table.APPROVALS;

  const sidebar =
    (search.get(SEARCH_PARAM.SIDEBAR) as Sidebar | null) ??
    Sidebar.INIT_NEW_EXPORT;

  const exportType = search.get(SEARCH_PARAM.EXPORT_TYPE) as ExportType | null;

  const exportId = search.get(SEARCH_PARAM.EXPORT_ID);
  const timeframe = search.get(SEARCH_PARAM.TIME_FRAME);
  const isReExport = search.get(SEARCH_PARAM.IS_RE_EXPORT) === 'true';
  const shouldTriggerDownload =
    search.get(SEARCH_PARAM.TRIGGER_DOWNLOAD) === 'true';

  useEffect(() => {
    if (view === View.READY) return;

    setIncludeAllContacts(false);
    setIncludeAllPaymentConditions(false);
  }, [view]);

  const setShouldTriggerDownload = (shouldTriggerDownload: boolean) => {
    search.set(SEARCH_PARAM.TRIGGER_DOWNLOAD, '' + shouldTriggerDownload);
    setSearch(search);
  };

  const setView = (view: View) => {
    search.set(SEARCH_PARAM.VIEW, view);
    setSearch(search);
  };

  const setSidebar = (sidebar: Sidebar) => {
    search.set(SEARCH_PARAM.SIDEBAR, sidebar);
    setSearch(search);
  };

  const setIsReExport = (isReExport: boolean) => {
    search.set(SEARCH_PARAM.IS_RE_EXPORT, '' + isReExport);
    setSearch(search);
  };

  const setExportId = (exportId: string | null) => {
    if (exportId) {
      search.set(SEARCH_PARAM.EXPORT_ID, exportId);
    } else {
      search.delete(SEARCH_PARAM.EXPORT_ID);
    }

    setSearch(search);
  };

  const setEntity = (entity: AvailableEntity) => {
    search.set(SEARCH_PARAM.ENTITY, entity);
    setSearch(search);
  };

  const setTable = (table: TableType) => {
    search.set(SEARCH_PARAM.TABLE, table);
    setSearch(search);
  };

  const setExportType = (exportType: ExportType | null) => {
    if (availableExportTypes.length === 1) {
      exportType = availableExportTypes[0];
    }

    if (exportType) {
      if (!isExportTypeWithTimeframe(exportType)) {
        search.delete(SEARCH_PARAM.TIME_FRAME);
      }

      search.set(SEARCH_PARAM.EXPORT_TYPE, exportType);
    } else {
      search.delete(SEARCH_PARAM.TIME_FRAME);
      search.delete(SEARCH_PARAM.EXPORT_TYPE);
    }

    setSearch(search);
  };

  const setTimeframe = (timeframe: string | null) => {
    if (timeframe) {
      search.set(SEARCH_PARAM.TIME_FRAME, timeframe);
    } else {
      search.delete(SEARCH_PARAM.TIME_FRAME);
    }

    setSearch(search);
  };

  const tableId: ExportContextProps['tableId'] = `export-${view}-${entity}-${table}-table-configurations`;

  return (
    <ExportContext.Provider
      value={{
        // get
        availableEntities,
        availableExportTypes,
        availableTables,
        availableViews,
        entity,
        exportId,
        exportType,
        includeEntitiesWithoutDoc,
        includeAllContacts,
        includeAllPaymentConditions,
        isReExport,
        selectedProvisions,
        shouldTriggerDownload,
        sidebar,
        table,
        tableId,
        timeframe,
        view,
        // set
        setAvailableEntities,
        setAvailableExportTypes,
        setAvailableTables,
        setAvailableViews,
        setEntity,
        setExportId,
        setExportType,
        setIncludeEntitiesWithoutDoc,
        setIncludeAllContacts,
        setIncludeAllPaymentConditions,
        setIsReExport,
        setSelectedProvisions,
        setShouldTriggerDownload,
        setSidebar,
        setTable,
        setTimeframe,
        setView,
      }}>
      {children}
    </ExportContext.Provider>
  );
};

export const useExportContext = () => useContext(ExportContext);
