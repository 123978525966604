import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ReimbursementDashboardLayout } from './components/ReimbursementDashboardLayout';
import { CompletedReimbursementListContainer } from './Lists/CompletedReImbursmentListContainer';
import { CreateReimbursementCardContainer } from './Lists/CreateReimbursementCardContainer';
import { OpenReimbursementListContainer } from './Lists/OpenReimbursementListContainer';
import { ReimbursementsDashboardPromotion } from './ReimbursementsDashboardPromotion';

export const ReimbursementDashboard = () => {
  const { variant } = useReimbursementsPromotions();

  const isPromotionForReimbursementsVisible = variant !== 'noPromotion';

  if (isPromotionForReimbursementsVisible) {
    return <ReimbursementsDashboardPromotion />;
  }

  return (
    <ReimbursementDashboardLayout>
      <CreateReimbursementCardContainer />
      <OpenReimbursementListContainer />
      <CompletedReimbursementListContainer />
    </ReimbursementDashboardLayout>
  );
};
