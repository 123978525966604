import { Item, TabPanel, Tabs } from '@candisio/design-system';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { useTabs } from 'components/Tabs/useTabs';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { ContractSubCategoriesInfoPanel } from './ContractSubCategoriesInfoPanel';
import { ContractSubCategoryDetailsRoute } from './elements/Drawer/ContractSubCategoryDetailsRoute';
import { ContractCustomSubCategoriesEmptyDataState } from './elements/List/ContractCustomSubCategoriesEmptyDataState';
import { ContractCustomSubCategoriesListItem } from './elements/List/ContractCustomSubCategoriesListItem';
import { useContractCustomSubCategoryListLogic } from './toolkit/useContractCustomSubCategoryListLogic';
import { ContractSubcategoriesSortBy } from './toolkit/utils';

const CONTRACT_SUBCATEGORIES_WIDTH = '50rem';

export const ContractSubCategories = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const sortMenuItems = [
    {
      id: ContractSubcategoriesSortBy.alphabetically,
      label: t('contractCustomSubCategories.sort.alphabetically'),
    },
    {
      id: ContractSubcategoriesSortBy.mostUsed,
      label: t('contractCustomSubCategories.sort.mostUsed'),
    },
    {
      id: ContractSubcategoriesSortBy.newestFirst,
      label: t('contractCustomSubCategories.sort.newestFirst'),
    },
  ] as const;

  const {
    handleCreateSubCategory,
    onSearchFilter,
    onSelect,
    onTabChanged,
    queryString,
    selectedKey,
    activeCount,
    archivedCount,
    contractSubCategories,
    countLoading,
    onSortBy,
    sortBy,
  } = useContractCustomSubCategoryListLogic();

  const filterableList = (
    <FilterableList
      selectionMode="none"
      emptyDataState={<ContractCustomSubCategoriesEmptyDataState />}
      width={CONTRACT_SUBCATEGORIES_WIDTH}
      onAction={onSelect}
      isLoading={countLoading}
      onCreate={{
        value: handleCreateSubCategory,
        text: t('contractCustomSubCategories.actions.new'),
      }}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: value => {
            if (value?.length) {
              onSortBy(value[0]);
            }
          },
          text: sortMenuItems.find(s => s.id === sortBy)?.label ?? '',
          menuButtonItems: sortMenuItems,
        },
      ]}
      searchField={{
        onSearchFilter,
        placeholder: t('contractCustomSubCategories.searchPlaceholder'),
        searchQuery: queryString,
      }}>
      {contractSubCategories.map(contractSubCategory => (
        <Item key={contractSubCategory.id} textValue={contractSubCategory.name}>
          <FilterableListItem itemId={contractSubCategory.id} hasAction>
            <ContractCustomSubCategoriesListItem
              contractSubCategory={contractSubCategory}
              queryString={queryString}
            />
          </FilterableListItem>
        </Item>
      ))}
    </FilterableList>
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('contractCustomSubCategories.actions.activeTab'),
        badge:
          typeof activeCount === 'number' ? String(activeCount) : undefined,
        children: filterableList,
      },

      {
        key: 'archived',
        title: t('contractCustomSubCategories.actions.archiveTab'),
        badge:
          typeof archivedCount === 'number' ? String(archivedCount) : undefined,
        children: filterableList,
      },
    ],
    onSelectionChange: onTabChanged,
    selectedKey,
  });

  return (
    <SettingsLayout
      title={t('contractCustomSubCategories.title')}
      width={CONTRACT_SUBCATEGORIES_WIDTH}
      actions={<Tabs {...tabsProps} />}>
      <TabPanel {...tabPanelProps} />

      <ContractSubCategoriesInfoPanel />

      <ContractSubCategoryDetailsRoute />
    </SettingsLayout>
  );
};
