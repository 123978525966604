import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { CreateReimbursementCard } from '../components/CreateReimbursementCard';
import { useCreateReimbursement } from '../hooks/useCreateReimbursement';
import { REIMBURSEMENT_VIEW_ROUTES } from '../hooks/useReimbursementNavigation';

export const CreateReimbursementCardContainer = () => {
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { createReimbursement, createReimbursementPending } =
    useCreateReimbursement();

  const handleCreateReimbursement = async () => {
    const reimbursementId = await createReimbursement();

    if (!reimbursementId) {
      return;
    }

    const pathname = generatePath(
      REIMBURSEMENT_VIEW_ROUTES[Routes.REIMBURSEMENTS_DASHBOARD],
      { organizationSlug, reimbursementId }
    );

    navigate(pathname);
  };

  return (
    <CreateReimbursementCard
      isLoading={createReimbursementPending}
      onCreateReimbursement={handleCreateReimbursement}
    />
  );
};
