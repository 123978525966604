import { defaultTheme } from '@candisio/design-system';
import { FunctionComponent } from 'react';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { PaymentStatus } from 'views/Payments/types';
import { mapToPaymentStatusIcon } from 'views/Payments/utils/mapToPaymentStatus';
import { BaseItem, BaseItemProps } from './BaseItem';

type PaymentProps = BaseItemProps & {
  active: string; // string is needed for css attribute
  status: PaymentStatus;
  nohover?: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type StyledBaseItemProps = FunctionComponent<
  BaseItemProps & { active: string; nohover?: string }
>;

/**
 * Don't forward styling properties to BaseItem (we do this manually because we're not using style-components 5.0x)
 *
 * https://github.com/styled-components/styled-components/issues/135
 */
const StyledBaseItem = styled<StyledBaseItemProps>(({ ...props }) => (
  <BaseItem {...props} />
))`
  transition: background-color 0.3s;
  cursor: pointer;

  &[active='true'] {
    background-color: ${defaultTheme.colors.gray0} !important;
  }

  &[active='false']:hover {
    background-color: ${defaultTheme.colors.gray50} !important;
  }

  // main item
  ${p =>
    p.size === 'big' &&
    `
    height: 4.3125rem;
    margin-bottom: 10px !important;
    .ant-timeline-item-head {
      top: 50%;
      width: 1.5rem;
      height: 1.5rem;
    }
    .ant-timeline-item-tail {
      top: calc(50% + 0.75rem);
      height: calc(100% - 1rem);
    }
  `}
`;

export const Payment = ({ status, ...props }: PaymentProps) => (
  <StyledBaseItem src={mapToPaymentStatusIcon(status)} {...props} />
);
