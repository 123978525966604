import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { noop } from 'lodash';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const SidebarContext = createContext<{
  isNavigationOpen: boolean;
  toggleSidebar: () => void;
}>({
  isNavigationOpen: false,
  toggleSidebar: () => noop,
});

export const LARGE_SCREEN_WIDTH_THRESHOLD = 1640;
export const MAIN_NAVIGATION_OPEN_ONLOAD = 'main-navigation-open-onload';

export const useNavigationSidebarContext = () => useContext(SidebarContext);

export const NavigationSidebarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const isLargeDesktop = window.innerWidth >= LARGE_SCREEN_WIDTH_THRESHOLD;

  const [isOpen, setIsOpen] = useLocalStorage(
    MAIN_NAVIGATION_OPEN_ONLOAD,
    isLargeDesktop
  );

  const [isNavigationOpen, setIsNavigationOpen] = useState(
    isLargeDesktop ? isOpen : false
  );

  const [userToggled, setUserToggled] = useState(false);

  const toggleSidebar = useCallback(() => {
    setUserToggled(true);
    setIsNavigationOpen(!isNavigationOpen);
    setIsOpen(!isOpen);
  }, [isNavigationOpen, isOpen, setIsOpen]);

  useEffect(() => {
    let previousWidth = window.innerWidth;

    const handleResize = () => {
      const currentWidth = window.innerWidth;

      const resizingToSmaller =
        currentWidth < LARGE_SCREEN_WIDTH_THRESHOLD &&
        previousWidth >= LARGE_SCREEN_WIDTH_THRESHOLD;

      const resizingToLarger =
        currentWidth >= LARGE_SCREEN_WIDTH_THRESHOLD &&
        previousWidth < LARGE_SCREEN_WIDTH_THRESHOLD;

      if (resizingToSmaller) {
        // Resizing to smaller: Close the sidebar if it's open
        if (isNavigationOpen) {
          setIsNavigationOpen(false);
        }
      } else if (resizingToLarger) {
        // Resizing to larger: Open the sidebar if user has not manually closed it
        if (!isNavigationOpen && !userToggled) {
          setIsNavigationOpen(true);
        }
      }

      previousWidth = currentWidth;
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [isNavigationOpen, userToggled]);

  return (
    <SidebarContext.Provider
      value={{
        isNavigationOpen,
        toggleSidebar,
      }}>
      {children}
    </SidebarContext.Provider>
  );
};
