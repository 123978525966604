import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import {
  PromotionWrapper,
  TableWrapper,
  Wrapper,
} from 'components/ProductPromotions/Wrapper';
import { TransactionsTable } from 'components/Transactions/Table/TransactionsTable';
import { transactionData } from './tableData';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { useTranslation } from 'react-i18next';
import { PromotionDetail } from 'components/NewPromotions/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const CreditCardsPromotionTransaction = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { creditCardsPromotion } = usePromotionContent();
  const {
    promotionDetails: { ctaButton, ...restDetails },
  } = usePromotionDetailsModalContent(creditCardsPromotion.id);

  const customPromotionDetails: PromotionDetail = {
    ...restDetails,
    ctaButton: t('promotionDetailsModal.creditCards.ctaButtonTransaction', {
      returnObjects: true,
    }),
  };

  return (
    <Wrapper>
      <TableWrapper>
        <TransactionsTable data={transactionData} onEndReached={() => {}} />
      </TableWrapper>
      <PromotionWrapper>
        <PromotionsProvider promotions={[creditCardsPromotion]}>
          <PromotionCard
            promotion={creditCardsPromotion}
            closable={false}
            customPromotionDetails={customPromotionDetails}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};
