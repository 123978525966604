import { HookFormNumberFieldProps } from 'components/HookFormFields/HookFormNumberField';
import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface VatRateFieldProps<TFormValues extends FieldValues>
  extends HookFormNumberFieldProps<TFormValues> {
  name: Path<TFormValues>;
}

export const VatRateField = <TFormValues extends FieldValues>({
  name,
  ...props
}: VatRateFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <HookFormPercentField
      name={name}
      label={t('splitBookings.vatRate.label')}
      placeholder={t('splitBookings.vatRate.placeholder.enabled')}
      minValue={0}
      {...props}
    />
  );
};
