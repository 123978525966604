import {
  GoodsReceipt,
  Maybe,
  PurchaseOrder,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { goodsReceiptSapStatuses } from 'views/Archive/GoodsReceipts/consts';
import { statusInSapFilters } from 'views/Archive/PurchaseOrders/utils';
import { DetailsBox } from './DetailsBox';
import { Divider } from './Divider';

const GoodsReceiptInfoBox = ({
  goodsReceipt,
}: {
  goodsReceipt: Maybe<GoodsReceipt>;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { receiptNumber, deliveryDate, status } = goodsReceipt || {};

  const statusLabel = useMemo(() => {
    return goodsReceiptSapStatuses.find(option => option.id === status)?.label;
  }, [status]);

  return (
    <DetailsBox
      itemNumber={receiptNumber}
      date={deliveryDate}
      statusLabel={statusLabel ? t('' + statusLabel) : ''}
      boxType="goodsReceipt"
    />
  );
};

const PurchaseOrderInfoBox = ({
  purchaseOrder,
}: {
  purchaseOrder: Maybe<PurchaseOrder>;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { orderNumber, postingDate, amount, status } = purchaseOrder || {};

  const statusLabel = useMemo(() => {
    return statusInSapFilters.find(option => option.id === status)?.label;
  }, [status]);

  return (
    <DetailsBox
      boxType="purchaseOrder"
      itemNumber={orderNumber}
      date={postingDate}
      statusLabel={statusLabel ? t('' + statusLabel) : ''}
      amount={amount}
    />
  );
};

const renderGoodsReceipts = (purchaseOrder: Maybe<PurchaseOrder>) => {
  const goodsReceipts = purchaseOrder?.goodsReceipts;

  return goodsReceipts?.length
    ? goodsReceipts.map((goodsReceipt, index) => (
        <Fragment key={goodsReceipt?._id}>
          {index === 0 && <Divider showLinkedIcon />}
          <GoodsReceiptInfoBox goodsReceipt={goodsReceipt} />
          {index !== goodsReceipts.length - 1 && <Divider showLinkedIcon />}
        </Fragment>
      ))
    : null;
};

export const PurchaseOrderDetailsCard = ({
  purchaseOrder,
  hasNextPurchaseOrder,
}: {
  purchaseOrder: Maybe<PurchaseOrder>;
  hasNextPurchaseOrder?: boolean;
}) => {
  return (
    <>
      {hasNextPurchaseOrder && <Divider />}
      <PurchaseOrderInfoBox purchaseOrder={purchaseOrder} />
      {renderGoodsReceipts(purchaseOrder)}
    </>
  );
};
