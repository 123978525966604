import { Image } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { VideoCard } from 'containers/Entitlements/components/Upsell/components/VideoCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import thumbnail from './toolkit/assets/teaser.png';

export const TagsInfoPanel = ({
  withFeedback = true,
}: {
  withFeedback?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const intercomLink = withFeedback ? (
    <IntercomLink id="settings-tags-intercom-link">
      {t('documentTags.infoPanel.feedbackButton')}
    </IntercomLink>
  ) : null;

  return (
    <InfoPanel
      sections={t('documentTags.infoPanel.sections', { returnObjects: true })}
      title={t('documentTags.infoPanel.title')}
      externalLink={{
        href: t('documentTags.infoPanel.helpCenterArticle.url'),
        text: t('documentTags.infoPanel.helpCenterArticle.label'),
      }}
      intercomLink={intercomLink}>
      <VideoCard
        videoTourUrl={t('documentTags.drawer.links.videoTour.url')}
        videoTourTitle={t('documentTags.drawer.links.videoTour.label')}
        withFilter>
        <Image src={thumbnail} alt="" height="10rem" />
      </VideoCard>
    </InfoPanel>
  );
};
