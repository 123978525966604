import { getMemberships } from 'components/Comment/gql';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  Locale,
  useForceUpdateMembershipMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDatevSettings } from 'hooks/useDatevSettings';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { isNil } from 'lodash';
import { useOtherIntegration } from 'orgConfig/other';
import { useSSO } from 'orgConfig/sso/sso';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import useRouter from 'use-react-router';
import { zodResolver } from 'utils/zodFormValidation';
import { absenceFormErrorMessages } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormErrorMessages';
import {
  AbsenceFormSchemaOptions,
  absenceFormSchema,
} from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormSchema';
import { useCheckCircularSubstitutionDependency } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/checkCircularSubstitutionDependency';
import { useCheckAccountsNumber } from 'views/Contacts/ContactDetails/useCheckAccountsNumber';
import { useGetAccountsPayableNumber } from 'views/Settings/TeamMembers/hooks/useGetAccountsPayableNumber';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { usePermissionsForTeamMembers } from 'views/Settings/TeamMembers/hooks/usePermissionsForTeamMembers';
import { useTeamMemberAbsenceUpdate } from 'views/Settings/TeamMembers/hooks/useTeamMemberAbsenceUpdate';
import { useUpdateAccountsPayableNumber } from 'views/Settings/TeamMembers/hooks/useUpdateAccountsPayableNumber';
import { getApproversQuery } from 'views/Settings/TeamMembers/queries';
import {
  isInvolvedResponse,
  isOnlyAdminResponse,
  isPendingApprovalsResponse,
  isSuccess,
} from 'views/Settings/TeamMembers/responses';
import { scrollToInvolvementInfo } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/components/MembershipInvolvement/MembershipInvolvement';
import { useDeactivateMembership } from 'views/Settings/TeamMembers/useDeactivateMembership';
import { useForceDeactivateMembership } from 'views/Settings/TeamMembers/useForceDeactivateMembership';
import { LocalMembershipStatus } from 'views/Settings/TeamMembers/UsersListView';
import { useUpdateMembership } from 'views/Settings/TeamMembers/useUpdateMembership';
import {
  generateTeamMembersPath,
  getUserInfo,
  sanitizeUpdateFormData,
} from 'views/Settings/TeamMembers/utils';
import { useGetTeams } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { useEcmDocumentsInvolvementCount } from '../../components/MembershipInvolvement/hooks/useEcmDocumentsInvolvement';
import { ToastMessage } from '../../components/ToastMessage';

import { teamMemberFormErrorMessages } from '../../teamMemberFormErrorMessages';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
  teamMemberFormSchema,
} from '../../teamMemberFormSchema';
import { useShowPendingApprovalsAckMsg } from '../hooks/useShowAcknowledgeMessage';
import {
  ActiveTeamMemberForm,
  TeamMemberFormDirtyFields,
  TeamMemberWithAbsenceFormOutput,
} from './ActiveTeamMemberForm';
import { useSap } from 'orgConfig/sap';

type Props = {
  closeDrawer: () => void;
  loading?: boolean;
  selectedUser: User;
};

const ACTIVE_TEAM_MEMBER_FORM_ID = 'active-team-member';

export const ActiveFormContainer = ({
  closeDrawer,
  loading,
  selectedUser,
}: Props) => {
  const {
    history,
    match: {
      url,
      path,
      params: { organizationSlug },
    },
  } = useRouter<{ organizationSlug: string }>();

  const sso = useSSO();
  const { data: datevSettings } = useDatevSettings();
  const integrationSetting = useIntegrationSettings();
  const { canModifyPersonalInfo, canModifyMemberRoles } =
    usePermissionsForTeamMembers();
  const { shouldUseSapContacts } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const {
    defaultValues: absenceInitialValues,
    handleSubmit: handleUpdateAbsence,
    loading: absenceInitialValuesLoading,
  } = useTeamMemberAbsenceUpdate(selectedUser.id);

  const { accountsPayableNumber, isLoadingAccountsPayableNumber } =
    useGetAccountsPayableNumber({ userId: selectedUser.id });

  const { teams, loading: isLoadingTeams } = useGetTeams({
    membershipIds: [selectedUser.id],
  });

  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const [involvementErrorMessage, setInvolvementErrorMessage] = useState<
    string | undefined
  >();

  const checkCircularDependency = useCheckCircularSubstitutionDependency();

  const { hasEcmInvolvement } = useEcmDocumentsInvolvementCount({
    membershipId: selectedUser.id,
  });

  const {
    updateUser,
    mutationProps: { loading: isSubmittingUpdateMembership },
  } = useUpdateMembership();

  const [
    forceUpdateMembership,
    { loading: isSubmittingForceUpdateMembership },
  ] = useForceUpdateMembershipMutation();

  const [deactivateMembership, { loading: isSubmittingDeactivateMembership }] =
    useDeactivateMembership();

  const [
    forceDeactivateMembership,
    { loading: isSubmittingForceDeactivateMembership },
  ] = useForceDeactivateMembership();

  const { updateAccountPayableNumber, isUpdateAccountPayableNumberPending } =
    useUpdateAccountsPayableNumber();

  const { showPendingApprovalsAckMsg, setShowPendingApprovalsAckMsg } =
    useShowPendingApprovalsAckMsg();

  const onSubmit = useCallback(
    async (
      values: TeamMemberWithAbsenceFormOutput,
      dirtyFields?: TeamMemberFormDirtyFields
    ) => {
      const { issueCreditCard, accountsPayableNumber, ...userData } = values;
      const hasAbsenceChanged =
        dirtyFields?.fromDate ||
        dirtyFields?.toDate ||
        dirtyFields?.note ||
        dirtyFields?.substitute;

      if (hasAbsenceChanged) {
        await handleUpdateAbsence(userData);
      }

      const hasAccountPayableNumberChanged = dirtyFields?.accountsPayableNumber;

      if (hasAccountPayableNumberChanged) {
        await updateAccountPayableNumber(
          accountsPayableNumber,
          selectedUser.id
        );
      }

      const hasTeamMemberDataChanged =
        dirtyFields?.email ||
        dirtyFields?.firstName ||
        dirtyFields?.lastName ||
        dirtyFields?.locale ||
        dirtyFields?.roles;

      const shouldCloseDrawer = !hasTeamMemberDataChanged;

      if (shouldCloseDrawer) {
        closeDrawer();

        return;
      }

      try {
        // update user
        const updateMembershipResult = await updateUser(selectedUser.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          roles: values.roles,
          locale: values.locale,
        });

        if (isInvolvedResponse(updateMembershipResult)) {
          setInvolvementErrorMessage(
            t('settings.teamMembers.form.involvement.headerErrorUpdate')
          );
          setInitialValues(userData);
        } else if (isOnlyAdminResponse(updateMembershipResult)) {
          error(t('settings.teamMembers.errors.orgShouldHaveAdmin'));
          setInitialValues(userData);
        } else if (isPendingApprovalsResponse(updateMembershipResult)) {
          setShowPendingApprovalsAckMsg({ action: 'show', errorCtx: 'update' });
        } else if (isSuccess(updateMembershipResult)) {
          // update success
          success(t('settings.teamMembers.actions.userUpdated'));

          closeDrawer();
        }
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }

      return;
    },
    [
      closeDrawer,
      error,
      handleUpdateAbsence,
      selectedUser.id,
      setShowPendingApprovalsAckMsg,
      success,
      t,
      updateAccountPayableNumber,
      updateUser,
    ]
  );

  const resetShowPendingApprovalsAckMsg = useCallback(() => {
    setShowPendingApprovalsAckMsg({ action: 'reset' });
  }, [setShowPendingApprovalsAckMsg]);

  const counterQueries = useCounterQueries();

  const onForceUpdate = useCallback(
    async (values: TeamMemberFormOutput) => {
      const sanitizedInput = sanitizeUpdateFormData(values);
      try {
        await forceUpdateMembership({
          variables: {
            input: sanitizedInput,
            id: selectedUser.id,
          },
          refetchQueries: [
            ...counterQueries,
            'organizationMemberships',
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
            {
              query: getMemberships,
              variables: membershipsQueryFilter.Active,
            },
          ],
        });

        closeDrawer();
        resetShowPendingApprovalsAckMsg();
        success(t('settings.teamMembers.actions.userUpdated'));
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }
    },
    [
      forceUpdateMembership,
      selectedUser.id,
      counterQueries,
      closeDrawer,
      resetShowPendingApprovalsAckMsg,
      success,
      t,
      error,
    ]
  );

  const onDeactivateMembership = useCallback(async () => {
    try {
      const deactivateMembershipResult = await deactivateMembership(
        selectedUser.id
      );

      if (isInvolvedResponse(deactivateMembershipResult) || hasEcmInvolvement) {
        setInvolvementErrorMessage(
          t('settings.teamMembers.form.involvement.headerErrorDeactivate')
        );

        setTimeout(() => {
          scrollToInvolvementInfo();
        }, 250);
      } else if (isOnlyAdminResponse(deactivateMembershipResult)) {
        error(t('settings.teamMembers.errors.orgShouldHaveAdmin'));
      } else if (isPendingApprovalsResponse(deactivateMembershipResult)) {
        setShowPendingApprovalsAckMsg({
          action: 'show',
          errorCtx: 'deactivate',
        });

        // HACK: we also set involvement error message in case the user has
        // involvement in addition to pending workflows.
        //
        // Reason: The API might not return the INVOLVED error code if the team
        // member has pending workflows but the user needs to resolve any
        // involvement *first*, before resetting any pending workflows.
        //
        // @TODO: TeamMemberDetails view should be refactored to avoid such hacks.
        setInvolvementErrorMessage(
          t('settings.teamMembers.form.involvement.headerErrorDeactivate')
        );

        setTimeout(() => {
          scrollToInvolvementInfo();
        }, 250);
      } else if (isSuccess(deactivateMembershipResult)) {
        const nameAndEmail = getUserInfo(selectedUser);
        const pathname = generateTeamMembersPath({
          organizationSlug,
          path,
          url,
          userId: selectedUser.id,
        });

        const content = (
          <ToastMessage
            history={history}
            nameAndEmail={nameAndEmail}
            pathname={pathname}
            status={LocalMembershipStatus.INACTIVE}
            translationKey="settings.teamMembers.actions.deactivatedMembership"
          />
        );

        info(content);

        closeDrawer();
      }
    } catch (e) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(e as Error).message}`));
    }
  }, [
    closeDrawer,
    deactivateMembership,
    error,
    hasEcmInvolvement,
    history,
    info,
    organizationSlug,
    path,
    selectedUser,
    setShowPendingApprovalsAckMsg,
    t,
    url,
  ]);

  const onForceDeactivateMembership = useCallback(async () => {
    try {
      await forceDeactivateMembership(selectedUser.id);
      const nameAndEmail = getUserInfo(selectedUser);
      const pathname = generateTeamMembersPath({
        organizationSlug,
        path,
        url,
        userId: selectedUser.id,
      });

      const content = (
        <ToastMessage
          history={history}
          nameAndEmail={nameAndEmail}
          pathname={pathname}
          status={LocalMembershipStatus.INACTIVE}
          translationKey="settings.teamMembers.actions.deactivatedMembership"
        />
      );

      info(content);

      closeDrawer();
      resetShowPendingApprovalsAckMsg();
    } catch (err) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(err as Error).message}`));
    }
  }, [
    closeDrawer,
    error,
    forceDeactivateMembership,
    history,
    info,
    organizationSlug,
    path,
    resetShowPendingApprovalsAckMsg,
    selectedUser,
    t,
    url,
  ]);

  const userRoles: TeamMemberFormOutput['roles'] = (
    selectedUser.membershipRoles ?? []
  ).map(role => ({
    id: role.id,
    name: role.name,
    type: role.isBuiltinRole ? 'BuiltinRole' : 'CustomRole',
  }));

  const [initialValues, setInitialValues] = useState<TeamMemberFormOutput>({
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    locale: selectedUser.locale ?? Locale.De,
    email: selectedUser.email,
    iban: selectedUser.paymentInfo.iban,
    swiftCode: selectedUser.paymentInfo.swiftCode,
    accountsPayableNumber,
    roles: userRoles,
  });

  const isUpdatingMembership =
    isSubmittingUpdateMembership ||
    isSubmittingForceUpdateMembership ||
    isUpdateAccountPayableNumberPending;

  const isDeactivatingMembership =
    isSubmittingDeactivateMembership || isSubmittingForceDeactivateMembership;

  const absenceFormContext: AbsenceFormSchemaOptions = {
    checkCircularSubstitutionDependency: checkCircularDependency,
    selectedUser: selectedUser,
  };

  const initialFormValues = { ...initialValues, ...absenceInitialValues };

  const errorMessages = {
    ...teamMemberFormErrorMessages,
    ...absenceFormErrorMessages,
  };

  const glaLength = !isNil(
    datevSettings?.new_datevSettings?.chartOfAccount?.accountLength
  )
    ? Number(datevSettings?.new_datevSettings?.chartOfAccount?.accountLength)
    : undefined;

  const { checkAccountsPayableNumber } = useCheckAccountsNumber({
    originalPayableValue: accountsPayableNumber,
    contactId: selectedUser.id,
  });

  const form = useForm<
    TeamMemberWithAbsenceFormOutput,
    TeamMemberFormSchemaOptions
  >({
    mode: 'all',
    defaultValues: initialFormValues,
    values: {
      ...initialFormValues,
      accountsPayableNumber: accountsPayableNumber,
    },
    resolver: zodResolver({
      zodSchema: teamMemberFormSchema({
        integration: integrationSetting,
        glaLength,
        checkAccountsPayableNumber,
      }).and(absenceFormSchema(absenceFormContext)),
      errorMessages,
    }),
  });

  const showForm =
    !absenceInitialValuesLoading &&
    !isLoadingTeams &&
    !isLoadingAccountsPayableNumber;

  return showForm ? (
    <FormProvider {...form}>
      <ActiveTeamMemberForm
        teams={teams}
        selectedUser={selectedUser}
        onSubmit={onSubmit}
        organizationSlug={organizationSlug}
        involvementErrorMessage={involvementErrorMessage}
        isSubmittingDeactivateMembership={isDeactivatingMembership}
        isSubmittingUpdateUser={isUpdatingMembership}
        onDeactivateMembership={onDeactivateMembership}
        onForceUpdate={onForceUpdate}
        shouldUseCoreDataApi={shouldUseCoreDataApi}
        shouldUseSapContacts={shouldUseSapContacts}
        showPendingApprovalsAckMsg={showPendingApprovalsAckMsg}
        resetShowPendingApprovalsAckMsg={resetShowPendingApprovalsAckMsg}
        loading={Boolean(loading)}
        onForceDeactivateMembership={onForceDeactivateMembership}
        absenceValuesLoading={absenceInitialValuesLoading}
        closeDrawer={closeDrawer}
        formId={ACTIVE_TEAM_MEMBER_FORM_ID}
        readOnly={{
          firstName: sso.provides.firstName || !canModifyPersonalInfo,
          lastName: sso.provides.lastName || !canModifyPersonalInfo,
          locale: !canModifyPersonalInfo,
          roles: sso.provides.roles || !canModifyMemberRoles,
        }}
      />
    </FormProvider>
  ) : null;
};
