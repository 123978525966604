import {
  useAccountsPayableNumberSettingsForEmployeesQuery,
  useSetEmployeesAccountsPayableNumberMutation,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCallback } from 'react';
import { accountsPayableNumberSettingsForEmployeesQuery } from '../ContactSettings/queries';

export const useSetEmployeesAccountsPayableNumber = () => {
  const { canUseEmployeeAPNSettings } = useReimbursement();

  const { data, loading: loadingInitialValues } =
    useAccountsPayableNumberSettingsForEmployeesQuery({
      skip: !canUseEmployeeAPNSettings,
    });

  const { enabled = false, startNumber: startValue } =
    data?.accountsPayableNumberSettingsForEmployees ?? {};

  const [setAccountsPayableNumber, { loading: isSettingAccountsPayable }] =
    useSetEmployeesAccountsPayableNumberMutation({
      update: (cache, { data }) => {
        if (
          data?.setAccountsPayableNumberSettingsForEmployees.__typename ===
          'AccountsPayableNumberSettings'
        ) {
          cache.writeQuery({
            query: accountsPayableNumberSettingsForEmployeesQuery,
            data: {
              accountsPayableNumberSettingsForEmployees:
                data.setAccountsPayableNumberSettingsForEmployees,
            },
          });
        }
      },
    });

  const handleSetStartValue = useCallback(
    async (startValue: string) => {
      const result = await setAccountsPayableNumber({
        variables: {
          settings: {
            startNumber: Number(startValue.replaceAll(' ', '')),
          },
        },
      });

      return result;
    },
    [setAccountsPayableNumber]
  );

  const handleSetEnabled = useCallback(
    async (enabled: boolean) => {
      await setAccountsPayableNumber({
        variables: {
          settings: {
            enabled,
          },
        },
      });
    },
    [setAccountsPayableNumber]
  );

  return {
    isSettingAccountsPayable,
    loadingInitialValues,
    enabled,
    startValue,
    setStartValue: handleSetStartValue,
    setEnabled: handleSetEnabled,
  };
};
