import { Button, Flex, Heading } from '@candisio/design-system';
import { UploadDocumentMenuButton } from 'components/UploadDocumentMenuButton';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';

type SensitiveDocumentsHeaderProps = {
  startProcessingLink?: string;
  isLinkDisabled?: boolean;
};

export const SensitiveDocumentsHeader = ({
  startProcessingLink,
  isLinkDisabled = true,
}: SensitiveDocumentsHeaderProps) => {
  const [t] = useTranslation();
  const { showEcmSensitiveDocuments } = useEcm();

  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap="space16"
      paddingRight="space32"
      paddingLeft="space32"
      paddingTop={mainNavigationRefactorFF ? 'space20' : 'space40'}
      paddingBottom="space16">
      <Flex alignItems="center" gap="space16">
        <Heading as="h1">{t('inbox.title')}</Heading>
        {startProcessingLink && (
          <Button
            as="a"
            size="small"
            variant="primary"
            href={startProcessingLink}
            disabled={isLinkDisabled}>
            {t('approvals.actions.startApproving')}
          </Button>
        )}
      </Flex>
      {showEcmSensitiveDocuments && <UploadDocumentMenuButton />}
    </Flex>
  );
};
