import { getTranslationContext } from 'orgConfig';
import { ErrorMessages } from 'utils/zodFormValidation';
import { processingFormSchema } from './processingFormSchema';

type ExcludeKeys =
  | 'mode'
  | 'approvalMode'
  | 'bookings.0.bookingId'
  | 'contact.inputValue'
  | 'accountingArea.inputValue'
  | 'bookings.0.generalLedgerAccount'
  | 'bookings.0.generalLedgerAccount.inputValue'
  | 'bookings.0.costCenter'
  | 'bookings.0.costCenter.inputValue'
  | 'bookings.0.costObject'
  | 'bookings.0.costObject.inputValue'
  | 'bookings.0.extraCostInfo'
  | 'bookings.0.extraCostInfo.inputValue'
  | 'bookings.0.sapExpenseType';

/** Gets processing form validation error messages */
export const getProcessingFormErrorMessages = (): ErrorMessages<
  ReturnType<typeof processingFormSchema>,
  ExcludeKeys
> => {
  return {
    type: { label: 'document.requestApproval.inputs.document.label' },
    'contact.value': {
      label: 'document.requestApproval.inputs.contact.label',
    },
    accountsPayableNumber: {
      label: 'settings.contacts.details.edit.accountsPayableNumber.label',
    },
    accountsReceivableNumber: {
      label: 'settings.contacts.details.edit.accountsReceivableNumber.label',
    },
    'accountingArea.value': {
      label: 'document.requestApproval.inputs.accountingArea.label',
    },
    invoiceDate: { label: 'document.requestApproval.inputs.from.label' },
    deliveryDate: {
      label: 'document.requestApproval.inputs.invoiceDeliveryDate.label',
    },
    postingDate: {
      label: 'document.requestApproval.inputs.invoicePostingDate.label',
    },
    invoiceNumber: {
      label: 'document.requestApproval.inputs.invoiceNumber.label',
    },
    roundingAmount: {
      label: 'document.requestApproval.inputs.roundingAmount.label',
    },
    netAmount: {
      label: 'document.requestApproval.inputs.netAmount.label',
    },
    taxAmount: {
      label: 'document.requestApproval.inputs.taxAmount.label',
    },
    purchaseOrderNumber: {
      label: 'document.requestApproval.inputs.purchaseOrderNumber.label',
    },
    grossAmount: { label: 'document.requestApproval.inputs.amount.label' },
    'bookings.0.quantity': {
      label: 'document.requestApproval.inputs.quantity.label',
    },
    currency: { label: 'document.requestApproval.inputs.currency.label' },
    'bookings.0.amount': { label: 'split-bookings:inputs.amount.label' },
    'bookings.0.netAmount': { label: 'split-bookings:inputs.netAmount.label' },
    'bookings.0.taxAmount': { label: 'split-bookings:inputs.taxAmount.label' },
    'bookings.0.vatRate': {
      label: 'document.requestApproval.inputs.vatRate.label',
    },
    'bookings.0.taxPresentation': {
      label: 'document.requestApproval.inputs.taxPresentation.label',
    },
    'bookings.0.dueDate': {
      label: 'document.requestApproval.inputs.invoiceDueDate.label',
    },
    'bookings.0.postingText': {
      label: 'document.requestApproval.inputs.postingText.label',
    },
    'bookings.0.note': {
      label:
        getTranslationContext() === 'sap'
          ? 'document.requestApproval.inputs.note.label_sap'
          : 'document.requestApproval.inputs.note.label',
    },
    'bookings.0.generalLedgerAccount.value': {
      label: 'document.requestApproval.inputs.generalLedger.label',
    },
    'bookings.0.taxCode': {
      label: 'document.requestApproval.inputs.bookingKey.label',
    },
    'bookings.0.costCenter.value': {
      label:
        getTranslationContext() === 'sap'
          ? 'document.requestApproval.inputs.costCenter.label_sap'
          : 'document.requestApproval.inputs.costCenter.label',
    },
    'bookings.0.costObject.value': {
      label:
        getTranslationContext() === 'sap'
          ? 'document.requestApproval.inputs.costObject.label_sap'
          : 'document.requestApproval.inputs.costObject.label',
    },
    'bookings.0.artistSocialInsuranceCode': {
      label: 'document.requestApproval.inputs.artistSocialInsuranceCode.label',
    },
    'bookings.0.extraCostInfo.value': {
      label: 'document.requestApproval.inputs.extraCostInfo.label',
    },
    iban: { label: 'document.requestApproval.inputs.iban.label' },
    swiftCode: { label: 'document.requestApproval.inputs.swiftCode.label' },
    createTransfer: {
      label: 'document.requestApproval.inputs.createTransfer.label',
    },
    paymentCondition: {
      label: 'document.requestApproval.inputs.paymentCondition.label',
    },
    discountDate: {
      label: 'cash-discounts:fields.dueDate.label',
    },
    discountPercentage: {
      label: 'cash-discounts:fields.percentage.label',
    },
    discountAmount: {
      label: 'cash-discounts:fields.amount.label',
    },
    workflow: {
      label: 'document.requestApproval.inputs.workflow.label',
    },
    approvers: {
      label: 'document.requestApproval.inputs.approvalStep.errorLabel',
    },
    'approvers.0.approvers': {
      label: 'document.requestApproval.inputs.approvalStep.errorLabel',
    },
    'bookings.0.unitPrice': { label: 'split-bookings:inputs.unitPrice.label' },
  };
};
