import { Grid } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Header } from './components/LeftSection/Header';
import { ReimbursementItemsCardContainer } from './components/LeftSection/ReimbursementItemsCardContainer';
import { ReimbursementNavigator } from './components/LeftSection/ReimbursementNavigator';
import { ReimbursementSection } from './components/LeftSection/ReimbursementSection';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { SectionLayout } from './components/SectionLayout';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import {
  ReimbursementNavigationResult,
  ReimbursementRouteParams,
} from './hooks/useReimbursementNavigation';
import { REIMBURSEMENT_URL_PARAM } from './Reimbursement';
import { HistoryAndCommentSectionContainer } from './components/LeftSection/HistoryAndCommentSectionContainer';

interface LeftSectionContainerProps
  extends Partial<ReimbursementNavigationResult> {
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
  isLoading: boolean;
}

export const LeftSectionContainer = ({
  formHelpers,
  isLoading,
  nextReimbursementLink,
  prevReimbursementLink,
  reimbursementCount,
}: LeftSectionContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const navigate = useNavigate();
  const { state } = useLocation<{ from: string; search: string }>();
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const isItemsViewActive = !!searchParams.get(REIMBURSEMENT_URL_PARAM.VIEW);
  const cursor = searchParams.get(REIMBURSEMENT_URL_PARAM.CURSOR);
  const isNavigatorVisible = !isItemsViewActive && !!cursor;

  const { reimbursementItems, reimbursementItemsCount } =
    useReimbursementExpenseItemsList();

  const { reimbursement, loading } = useGetReimbursementById({
    reimbursementId,
  });

  const { title, totalAmount, status } = reimbursement ?? {};

  const backText = isItemsViewActive
    ? 'reimbursementView.leftSection.header.backAction'
    : 'reimbursementView.leftSection.header.backToList';

  const handleBack = () => {
    if (isItemsViewActive) {
      updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, '');
      if (state?.from) {
        return navigate({
          pathname: state.from,
          search: state.search,
        });
      }

      return;
    }

    searchParams.delete(REIMBURSEMENT_URL_PARAM.CURSOR);
    navigate(
      { pathname: '..', search: searchParams.toString() },
      { relative: 'path' }
    );
  };

  return (
    <SectionLayout
      headerElement={
        <Grid templateColumns="1fr .95fr">
          <Header onBackClick={handleBack} backText={t(backText)} />
          {isNavigatorVisible && (
            <ReimbursementNavigator
              loading={isLoading}
              reimbursementCount={reimbursementCount}
              prevReimbursementLink={prevReimbursementLink}
              nextReimbursementLink={nextReimbursementLink}
            />
          )}
        </Grid>
      }>
      <Grid gap="space8" overflowY="auto" templateRows="auto auto 1fr">
        <ReimbursementSection
          loading={loading || isLoading}
          title={title}
          status={status}
          totalAmount={totalAmount}
          reimbursementItemsCount={reimbursementItemsCount}
        />
        {Boolean(reimbursementItems.length) && isItemsViewActive && (
          <ReimbursementItemsCardContainer
            reimbursementItems={reimbursementItems}
            formHelpers={formHelpers}
          />
        )}
        <HistoryAndCommentSectionContainer reimbursement={reimbursement} />
      </Grid>
    </SectionLayout>
  );
};
