import { CSSObject } from '@emotion/react';
import React from 'react';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box } from '../Box';

export type BadgeColor =
  | 'gray'
  | 'green'
  | 'red'
  | 'yellow'
  | 'blue'
  | 'darkGray'
  | 'purple';

export interface BadgeProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  color?: BadgeColor;
  transparentBackground?: boolean;
}

/**
 * Badge component displays concise additional information
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-feedback-badge}
 *
 * @param {Color} color Color type
 * @param {boolean} transparentBackground Used for less prominent design
 */
export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, color = 'blue', transparentBackground, ...restProps }, ref) => {
    const { badge } = useTheme();

    const baseStyle: CSSObject = {
      alignItems: 'center',
      border: `1px solid ${badge.border[color]}`,
      fontWeight: badge.fontWeight,
      fontSize: badge.fontSize,
      lineHeight: 1,
      borderRadius: badge.borderRadius,
      display: 'flex',
      justifyContent: 'center',
      gap: badge.gap,
      padding: badge.padding,
      whiteSpace: 'nowrap',
      height: badge.height,
      minWidth: badge.minWidth,
      width: 'max-content',
      backgroundColor: badge.backgroundColor[color],
      color: badge.color[color],
      textTransform: 'uppercase',
    };

    if (transparentBackground) {
      baseStyle.backgroundColor = 'transparent';
      baseStyle.border = '1px solid transparent';
      // use inverse color as background is transparent except for gray
      baseStyle.color =
        color === 'gray' ? badge.color['gray'] : badge.backgroundColor[color];
    }

    return (
      <Box css={baseStyle} {...restProps} ref={ref}>
        {children}
      </Box>
    );
  }
);
