import { Button, useModal, Modal } from '@candisio/design-system';
import { PurchaseOrderList } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/PurchaseOrderList';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

type AddNewPurchaseOrderButtonProps = {
  documentId: string;
  documentAmount?: number | null;
  contactName?: string;
  accountsPayableNumber?: string | null;
  disabled?: boolean;
};
// TODO select modal should be separate component since it's used in multiple places

export const AddNewPurchaseOrderButton = ({
  documentId,
  contactName,
  documentAmount,
  accountsPayableNumber,
  disabled,
}: AddNewPurchaseOrderButtonProps) => {
  const { triggerProps, modalProps, isOpen, close } = useModal();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const titleText = t('addPurchaseOrderModal.headerWithContactName', {
    contactName,
  });

  return (
    <>
      <Button
        icon="plus"
        variant="tertiary"
        style={{
          flexGrow: '1',
          borderRadius: '0',
          padding: '.75rem',
          borderEndStartRadius: 'medium',
          borderEndEndRadius: 'medium',
          width: '100%',
          backgroundColor: 'transparent',
        }}
        label={
          disabled
            ? t('threeWayMatch.indicator.disabledLinkingTooltip')
            : undefined
        }
        disabled={disabled}
        {...triggerProps}>
        {t('threeWayMatch.indicator.addNewPurchaseOrderButtonText')}
      </Button>
      {!disabled && isOpen && (
        <Modal
          {...modalProps}
          isOpen={isOpen}
          background="gray200"
          padding={0}
          minHeight="70vh"
          minWidth="80vw"
          shouldCloseOnBlur={true}
          closeLabel={t('addPurchaseOrderModal.close')}
          title={titleText}>
          <PurchaseOrderList
            documentAmount={documentAmount}
            accountsPayableNumber={accountsPayableNumber}
            documentId={documentId}
            closeModal={close}
            contactName={contactName}
          />
        </Modal>
      )}
    </>
  );
};
