import {
  Box,
  Flex,
  Grid,
  Heading,
  ScrollBox,
  Tag,
  Theme,
} from '@candisio/design-system';
import { NavLink } from 'components/NavLink/NavLinkDeprecated';
import { AddOnIcon } from 'containers/Entitlements/components/Upsell/components/AddOnIcon';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useTeams } from 'orgConfig/teams/useTeams';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { MouseEventHandler, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';
import { useCheckDatevSetup } from './Integrations/DATEV/setup-checks';
import {
  useNeedsSapConfig,
  useNeedsSapCreditCardConfig,
} from './Integrations/SAP/utils';
import { SETTINGS_ROUTES } from './types';
import { DATEV_DOCUMENT_TYPES } from './Integrations/DATEV/consts';
import { useNavigate } from 'react-router-dom-v5-compat';

export interface SettingsNavSectionProps {
  children: ReactNode;
}
const PADDING: keyof Theme['space'] = 'space32';

const scrollToTop = () => {
  const mainContent = document.getElementById('main_content');
  if (mainContent) {
    mainContent.scrollTo({
      top: 0,
    });
  }
};

export const SettingsNavSection = ({ children }: SettingsNavSectionProps) => {
  return (
    <Grid gap="space8" listStyle="none" padding={0}>
      {children}
    </Grid>
  );
};

const NewTag = memo(() => {
  const [t] = useTranslation();

  return (
    <Tag color="blue" variant="secondary" callout>
      {t('tag.new')}
    </Tag>
  );
});

/** @deprecated **/
export const SettingsNavigationDeprecated = () => {
  const [t] = useTranslation();
  const { organizationSlug } = useParams<{ organizationSlug?: string }>();

  const { isAdmin, isAccountant } = useUserRoles();
  const isAdminOrAccountant = isAdmin || isAccountant;

  const { showMissingIBANIndicator } = useOrganizationOwnIbansData();

  const [newSettingsPromotionsFF, documentTypesSettingsFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.newSettingsPromotions,
      FEATURE_FLAGS.documentTypesSetting,
    ]);

  const { isTeamsFeatureEnabled } = useTeams();
  const { showDocumentTags, showContractCustomSubCategories } = useEcm();
  const { shouldUseSapConfig } = useSap();
  const creditCards = useCreditCardsSetup();
  const { shouldUseCoreDataApi, shouldUseCandisApi, shouldUseAccountingAreas } =
    useOtherIntegration();

  const indicateSapSetupNeeded = useNeedsSapConfig();
  const indicateSapCreditCardSetupNeeded = useNeedsSapCreditCardConfig();
  const {
    indicateDatevCreditCardSetupNeeded,
    hasReadClientsButNotSelected,
    hasProvisionsAndRequiresSetup,
    accountingCoreDataMissing,
  } = useCheckDatevSetup();

  const { hasOrgTaxDetails } = useOrganizationData();

  const {
    bdsBoughtButNotConnected,
    exportProvisionsFF,
    hide: hideDatev,
  } = useDatev(); // BDS-checked

  const showTagsMenu =
    (showDocumentTags || newSettingsPromotionsFF) && isAdminOrAccountant;

  const showContractSubTypesMenu =
    (showContractCustomSubCategories || newSettingsPromotionsFF) &&
    isAdminOrAccountant;

  const showDocumentTypesSettings =
    documentTypesSettingsFF && isAdminOrAccountant;
  const showDatevDocumentTypes = !hideDatev && isAdminOrAccountant;

  const STATUS_PAGE_URL = import.meta.env.STATUS_PAGE_URL;

  // HACK: we don't want datev document type hash link to show active state
  const navigate = useNavigate();
  const goToDatevDocumentType: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    const path = `/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}#${DATEV_DOCUMENT_TYPES}`;
    navigate(path);
    e.currentTarget.blur();
  };

  return (
    <Grid
      as="nav"
      height="100%"
      overflow="hidden"
      templateRows="auto 1fr"
      gap="space16"
      paddingY={PADDING}
      alignContent="start">
      <Box paddingX={PADDING}>
        <Heading as="h1">{t('settings.heading')}</Heading>
      </Box>
      <ScrollBox paddingX={PADDING}>
        <Grid gap="space24">
          <SettingsNavSection>
            {isAdminOrAccountant && (
              <NavLink
                showIndicator={!hasOrgTaxDetails || showMissingIBANIndicator}
                data-cy="settings.navigation.general.items.organization"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.ORGANIZATION}`}
                onClick={scrollToTop}>
                {t('settings.navigation.general.items.organization')}
              </NavLink>
            )}

            {isTeamsFeatureEnabled && isAdminOrAccountant ? (
              <NavLink
                data-cy="settings.navigation.organization.items.users"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.TEAM_MEMBERS}`}
                onClick={scrollToTop}>
                {t('settings.navigation.general.items.users')}
              </NavLink>
            ) : (
              isAdminOrAccountant && (
                <NavLink
                  data-cy="settings.navigation.organization.items.teamMembers"
                  to={`/${organizationSlug}${Routes.SETTINGS}${Routes.TEAM_MEMBERS}`}
                  onClick={scrollToTop}>
                  {t('settings.navigation.general.items.teamMembers')}
                </NavLink>
              )
            )}

            {isTeamsFeatureEnabled && isAdmin && (
              <Flex alignItems="center" gap="space8">
                <NavLink
                  data-cy="settings.navigation.organization.items.teams"
                  to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}`}
                  onClick={scrollToTop}>
                  {t('settings.navigation.general.items.teams')}
                </NavLink>
                <Tag color="blue" callout>
                  {t('tag.new')}
                </Tag>
              </Flex>
            )}

            <NavLink
              data-cy="settings.navigation.organization.items.mobileApplication"
              to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.MOBILE_APPLICATION}`}
              onClick={scrollToTop}>
              {t('settings.navigation.organization.items.mobileApplication')}
            </NavLink>
            {STATUS_PAGE_URL && (
              <NavLink
                data-cy="settings.navigation.general.items.statusPage"
                to={`/${organizationSlug}${Routes.SETTINGS}${STATUS_PAGE_URL}`}
                onClick={scrollToTop}>
                {t('settings.navigation.general.items.statusPage')}
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <Flex gap="space4">
                <NavLink
                  to={`/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`}
                  onClick={scrollToTop}
                  showIndicator={
                    shouldUseSapConfig
                      ? indicateSapCreditCardSetupNeeded
                      : indicateDatevCreditCardSetupNeeded
                  }
                  requiresUpgrade={!creditCards.isAddonBought}
                  source="credit-cards">
                  {t('settings.navigation.general.items.creditCards')}
                </NavLink>
                {!creditCards.isAddonBought && <AddOnIcon />}
              </Flex>
            )}
          </SettingsNavSection>

          <SettingsNavSection>
            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.automation.items.workflow"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.WORKFLOWS}`}
                onClick={scrollToTop}>
                {t('settings.navigation.automation.items.workflow')}
              </NavLink>
            )}

            {isAdmin && (
              <NavLink
                data-cy="settings.navigation.automation.items.smartFieldSuggestions"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.SMART_FIELD_SUGGESTIONS}`}
                onClick={scrollToTop}>
                {t(
                  'settings.navigation.automation.items.smartFieldSuggestions'
                )}
              </NavLink>
            )}
          </SettingsNavSection>

          <SettingsNavSection>
            {showDocumentTypesSettings && (
              <Flex alignItems="center" gap="space8">
                <NavLink
                  data-cy="settings.navigation.organization.items.documentTypes"
                  to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DOCUMENT_TYPES}`}
                  onClick={scrollToTop}>
                  {t('settings.navigation.organization.items.documentTypes')}
                </NavLink>
                <NewTag />
              </Flex>
            )}

            {showContractSubTypesMenu && (
              <Flex alignItems="center" gap="space8">
                <NavLink
                  data-cy="settings.navigation.organization.items.contractCustomSubCategories"
                  to={`/${organizationSlug}${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}`}
                  onClick={scrollToTop}>
                  {t(
                    'settings.navigation.organization.items.contractCustomSubCategories'
                  )}
                </NavLink>
                {showContractCustomSubCategories && <NewTag />}
                {!showContractCustomSubCategories &&
                  newSettingsPromotionsFF && <AddOnIcon />}
              </Flex>
            )}

            {showDatevDocumentTypes && (
              <NavLink
                data-cy="settings.navigation.organization.items.datevDocumentTypes"
                to={DATEV_DOCUMENT_TYPES}
                onClick={goToDatevDocumentType}>
                {t('settings.navigation.organization.items.datevDocumentTypes')}
              </NavLink>
            )}
          </SettingsNavSection>

          <SettingsNavSection>
            {showTagsMenu && (
              <Flex alignItems="center" gap="space8">
                <NavLink
                  data-cy="settings.navigation.organization.items.documentTags"
                  to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DOCUMENT_TAGS}`}>
                  {t('settings.navigation.organization.items.documentTags')}
                </NavLink>
                {showDocumentTags && <NewTag />}
                {!showDocumentTags && newSettingsPromotionsFF && <AddOnIcon />}
              </Flex>
            )}
            {!documentTypesSettingsFF && showContractSubTypesMenu && (
              <Flex alignItems="center" gap="space8">
                <NavLink
                  data-cy="settings.navigation.organization.items.contractCustomSubCategories"
                  to={`/${organizationSlug}${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}`}>
                  {t(
                    'settings.navigation.organization.items.contractCustomSubCategories'
                  )}
                </NavLink>
                {showContractCustomSubCategories && <NewTag />}
                {!showContractCustomSubCategories &&
                  newSettingsPromotionsFF && <AddOnIcon />}
              </Flex>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.costCenters"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.COST_CENTER}`}
                onClick={scrollToTop}>
                {t('settings.navigation.organization.items.costCenters')}
              </NavLink>
            )}

            {shouldUseAccountingAreas && isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.accountingAreas"
                to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.ACCOUNTING_AREAS}`}
                onClick={scrollToTop}>
                {t('settings.navigation.organization.items.accountingAreas')}
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.bookingAccounts"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}`}
                onClick={scrollToTop}>
                {t('settings.navigation.organization.items.bookingAccounts')}
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.bookingKeys"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.TAX_CODES}`}
                onClick={scrollToTop}>
                {t('settings.navigation.organization.items.bookingKeys')}
              </NavLink>
            )}

            {!hideDatev && isAdminOrAccountant && (
              <Flex alignItems="center" gap="space4">
                <NavLink
                  data-cy="settings.navigation.organization.items.provisions"
                  to={`/${organizationSlug}${Routes.SETTINGS}${Routes.PROVISIONS}`}
                  onClick={scrollToTop}
                  showIndicator={
                    exportProvisionsFF
                      ? hasProvisionsAndRequiresSetup
                      : bdsBoughtButNotConnected
                  }
                  requiresUpgrade={!exportProvisionsFF}
                  source="provisions">
                  {t('settings.navigation.organization.items.provisions')}
                </NavLink>
                {!exportProvisionsFF && <AddOnIcon />}
              </Flex>
            )}

            {shouldUseSapConfig && isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.sap"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.SAP}`}
                onClick={scrollToTop}
                showIndicator={indicateSapSetupNeeded}
                source="sap">
                {t('settings.navigation.organization.items.sap')}
              </NavLink>
            )}

            {!hideDatev && isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.datev"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`}
                onClick={scrollToTop}
                showIndicator={
                  hasReadClientsButNotSelected ||
                  accountingCoreDataMissing ||
                  bdsBoughtButNotConnected
                }
                indicatorStatus="default"
                source="datev">
                {t('settings.navigation.organization.items.datev')}
              </NavLink>
            )}

            {shouldUseCandisApi && isAdmin && (
              <NavLink
                data-cy="settings.navigation.organization.items.candisAPI"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.CANDIS_API}`}
                onClick={scrollToTop}
                source="apiSettings">
                {t('settings.navigation.organization.items.apiSettings')}
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.mailSync"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}`}
                onClick={scrollToTop}>
                {t('settings.navigation.organization.items.mailSync')}
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.paymentConditions"
                to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.PAYMENT_CONDITIONS}`}
                onClick={scrollToTop}>
                {t('settings.navigation.organization.items.paymentConditions')}
              </NavLink>
            )}

            {!shouldUseCoreDataApi && isAdmin && (
              <NavLink
                data-cy="settings.navigation.organization.items.payments"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.PAYMENTS}`}
                onClick={scrollToTop}>
                {t('settings.navigation.organization.items.payments')}
              </NavLink>
            )}
          </SettingsNavSection>

          {localStorage.getItem('enableDevTools') !== null && (
            <SettingsNavSection>
              <NavLink
                data-cy="settings.navigation.developerTools.items.graphiql.sidebarName"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DEV_TOOLS}/graphiql`}
                onClick={scrollToTop}>
                {t(
                  'settings.navigation.developerTools.items.graphiql.sidebarName'
                )}
              </NavLink>
            </SettingsNavSection>
          )}
        </Grid>
      </ScrollBox>
    </Grid>
  );
};
