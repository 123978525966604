import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import {
  SmartFields,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import {
  grossToNet,
  sumNetAmounts,
  sumTaxAmounts,
} from 'containers/SplitBookings/toolkit/utils';
import {
  GetDocumentForDraftQuery,
  GetDocumentQuery,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useGetBookingAssociation } from 'hooks/useGetBookingAssociation';
import { useGetSuggestedTaxCodeIfMatched } from 'hooks/useGetSuggestedTaxCodeIfMatched';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { useSap } from 'orgConfig/sap';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { getInitialCashDiscount } from 'views/utils/getInitialCashDiscount';
import {
  useShouldShowField,
  useShowCashDiscountFields,
  useShowDueDateField,
  useShowInvoiceCorrectionField,
  useShowPurchaseOrderNumberField,
} from 'views/utils/useShouldShowField';
import { DocumentApprovalFormInitialData } from '../types';

export interface useDocumentFormInitialDataOptions {
  document: NonNullable<GetDocumentForDraftQuery['getDocument']>;
  smartFields?: SmartFields | null;
}

export const useApprovalFormInitialData = ({
  document,
  smartFields,
}: useDocumentFormInitialDataOptions): DocumentApprovalFormInitialData => {
  const {
    isActive: isSapActive,
    shouldUseSapPurchaseOrder,
    isActiveIntegration: isSapIntegration,
    shouldUseSapNetAmount,
    shouldUsePackageFreight,
  } = useSap();

  const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();

  const { isQuantityRequired, getBookingAssociationData } =
    useGetBookingAssociation(document.id);

  const { data: documentData, loading: isLoading } =
    useGetDocumentForDraftQuery({
      variables: { id: document?.id as string },
      skip: typeof document?.id !== 'string',
    });

  const {
    amount,
    bookings = [],
    category,
    contact,
    createTransfer,
    accountingArea,
    currency,
    hasTransactionLinked,
    iban,
    invoiceDate,
    invoiceDeliveryDate,
    invoiceNumber,
    invoicePostingDate,
    purchaseOrderData,
    swiftCode,
    roundingAmount,
  } = documentData?.getDocument ?? {};

  const showInvoiceCorrection = useShowInvoiceCorrectionField();
  const showPurchaseOrderNumber = useShowPurchaseOrderNumberField();

  const isNegativeAmount =
    !isNil(amount?.value) && Math.abs(amount?.value) !== amount?.value;

  const getSuggestedTaxCodeIfMatched = useGetSuggestedTaxCodeIfMatched();

  const mappedBookings = (bookings ?? []).map(booking => {
    const netAmount = grossToNet(
      booking.amount?.value ?? 0,
      booking.vatRate?.value ?? 0
    );

    const taxAmount = roundToCurrencyPrecision(
      (booking.amount?.value ?? 0) - netAmount
    );

    const {
      quantity: associatedBookingQuantity,
      sapExpenseType,
      unitPrice,
    } = getBookingAssociationData(booking.id);

    const bookingUnitPrice = formatIntegerAmountToDecimal(
      unitPrice?.amount ?? 0,
      unitPrice?.precision ?? 0
    );

    return {
      // XXX the BE type is a domain mismatch as the amount can never be undefined here
      amount: booking.amount?.value as number,
      taxAmount: booking.taxAmount ?? taxAmount,
      netAmount: booking.netAmount ?? netAmount,
      artistSocialInsuranceCode: booking.artistSocialInsuranceCode ?? undefined,
      bookingId: booking.id ?? '',
      taxCode:
        booking.bookingKey?.value.id ||
        getSuggestedTaxCodeIfMatched(
          booking.vatRate?.value,
          smartFields?.bookingKey?.id
        ),
      costCenter:
        booking.costCenter?.value.id && booking.costCenter?.value.readableName
          ? {
              value: booking.costCenter?.value.id,
              inputValue: booking.costCenter?.value.readableName,
            }
          : smartFields?.costCenter?.id && smartFields?.costCenter?.readableName
          ? {
              value: smartFields?.costCenter?.id,
              inputValue: smartFields?.costCenter?.readableName,
            }
          : {
              value: null,
              inputValue: '',
            },
      costObject:
        booking.costObject?.value.id && booking.costObject?.value.readableName
          ? {
              value: booking.costObject?.value.id,
              inputValue: booking.costObject?.value.readableName,
            }
          : smartFields?.costObject?.id && smartFields?.costObject?.readableName
          ? {
              value: smartFields?.costObject?.id,
              inputValue: smartFields?.costObject?.readableName,
            }
          : {
              value: null,
              inputValue: '',
            },
      dueDate: booking.dueDate?.value ? new Date(booking.dueDate?.value) : null,
      extraCostInfo:
        booking.extraCostInfo?.value.id &&
        booking.extraCostInfo?.value.readableName
          ? {
              value: booking.extraCostInfo?.value.id,
              inputValue: booking.extraCostInfo?.value.readableName,
            }
          : {
              value: null,
              inputValue: '',
            },
      generalLedgerAccount:
        booking.generalLedgerAccount?.value.id &&
        booking.generalLedgerAccount?.value.readableName
          ? {
              value: booking.generalLedgerAccount?.value.id,
              inputValue: booking.generalLedgerAccount?.value.readableName,
            }
          : smartFields?.generalLedgerAccount?.id &&
            smartFields?.generalLedgerAccount?.readableName
          ? {
              value: smartFields?.generalLedgerAccount?.id,
              inputValue: smartFields?.generalLedgerAccount?.readableName,
            }
          : {
              value: null,
              inputValue: '',
            },
      note: booking.note?.value ?? '',
      postingText: booking.postingText ?? '',
      ...(isQuantityRequired
        ? { quantity: associatedBookingQuantity, unitPrice: bookingUnitPrice }
        : {}),
      ...(shouldUsePackageFreight && sapExpenseType ? { sapExpenseType } : {}),
      vatRate: booking.vatRate?.value ?? undefined,
      taxPresentation: TaxPresentation.Gross,
    };
  });

  const initialValues: DocumentApprovalFormValues = {
    accountingArea: {
      value: accountingArea?.value?.id,
      inputValue: accountingArea?.value?.name,
    },
    accountsPayableNumber: friendlyFormatAccountsNumber(
      contact?.value.accountsPayableNumber ?? ''
    ),
    accountsReceivableNumber: contact?.value.accountsReceivableNumber ?? '',
    rejected: false,
    bookings: mappedBookings,
    cashDiscount:
      getInitialCashDiscount(
        (documentData?.getDocument as NonNullable<
          | GetDocumentForDraftQuery['getDocument']
          | GetDocumentQuery['getDocument']
        >) || {}
      ) ?? undefined,
    category: {
      type: category?.documentType?.value || undefined,
      direction: category?.direction?.value || undefined,
    },
    contact: contact?.value.name.value ?? '',
    contactId: contact?.value.id ?? '',
    createTransfer: createTransfer?.value ?? false,
    currency: currency?.value ?? '',
    // XXX the BE type is a domain mismatch as the amount can never be undefined here
    grossAmount: Number(amount?.value) as number,
    hasTransactions: hasTransactionLinked ?? false,
    iban: friendlyFormatIBAN(iban?.value) ?? null,
    invoiceCorrection:
      !isNil(amount?.value) && Math.abs(amount?.value) !== amount?.value,
    invoiceDate: invoiceDate?.value ? new Date(invoiceDate?.value) : null,
    invoiceDeliveryDate: invoiceDeliveryDate?.value
      ? new Date(invoiceDeliveryDate?.value)
      : null,
    invoicePostingDate: invoicePostingDate?.value
      ? new Date(invoicePostingDate?.value)
      : null,
    invoiceNumber: invoiceNumber?.value ?? '',
    purchaseOrderNumber: purchaseOrderData?.orderNumber ?? '',
    swiftCode: swiftCode?.value ?? null,
    vatRate: bookings?.[0]?.vatRate
      ? Number(bookings?.[0]?.vatRate.value)
      : undefined,
    roundingAmount: roundingAmount ?? 0,
    netAmount: sumNetAmounts(mappedBookings),
    taxAmount: sumTaxAmounts(mappedBookings),
  };

  const { showAccountsPayableNumber, showAccountsReceivableNumber } =
    useShouldShowField(document || {});

  const allDueDatesAreSame = Boolean(
    bookings?.every(({ dueDate }) => dueDate === bookings[0].dueDate)
  );

  const showDueDateField = useShowDueDateField({
    allDueDatesAreSame,
  });

  const isIncomingInvoice =
    initialValues.category?.direction === DocumentDirection.invoices_received;

  const showCashDiscountFields = useShowCashDiscountFields({
    isInvoiceCorrection: isNegativeAmount,
    hasTransaction: Boolean(hasTransactionLinked),
    isIncomingInvoice,
  });

  const showAccountsPayableNumberField = showAccountsPayableNumber({
    isIncomingInvoice,
  });

  const isOutgoingInvoice =
    initialValues.category?.direction === DocumentDirection.outgoing_invoices;

  const showAccountsReceivableNumberField = showAccountsReceivableNumber({
    isOutgoingInvoice,
    isSapActive,
  });

  const showDeliveryDate = !isSapIntegration;

  const showPostingDate = isSapIntegration;

  return {
    documentId: document?.id,
    fieldConditions: {
      showAccountsPayableNumberField,
      showAccountsReceivableNumberField,
      shouldUseSapPurchaseOrder,
      showCashDiscountFields,
      showDueDateField,
      showDeliveryDate,
      showPostingDate,
      showInvoiceCorrection,
      showPurchaseOrderNumber,
      shouldUseSapNetAmount,
    },
    initialValues,
    isLoading,
  };
};
