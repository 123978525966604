import { Avatar, Card, Grid, Link, Text } from '@candisio/design-system';

import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { ExportContext } from 'views/Integrations/Export/Context';
import { ExportType } from 'views/Integrations/Export/types';
import { getTeamMemberFullName } from 'views/utils/getTeamMemberFullName';
import { useExportDataGigaFactory } from '../../../hooks/useExportDataGigaFactory';
import { hasTransaction } from '../../../utils';
import { ApprovalsSummary } from './ApprovalsSummary';
import { BdsSummary } from './BdsSummary';
import { CoreDataSummary } from './CoreDataSummary';
import { DxsoProtocol } from './DxsoProtocol';
import { ReimbursementItemsSummary } from './ReimbursementItemsSummary';
import { TransactionsSummary } from './TransactionsSummary';

const ExportCreator = ({
  creator,
}: {
  creator: {
    name: string;
    avatarUrl?: string | null;
    createdAt?: Date;
  };
}) => {
  const [t] = useTranslation();

  return (
    <Card background="gray100" padding="space12">
      <Grid alignItems="center" gap="space8" templateColumns="auto 1fr">
        <Avatar
          name={creator.name}
          img={creator.avatarUrl ?? undefined}
          hasTooltip={false}
        />
        <Grid>
          <Text fontSize="small">{creator.name}</Text>
          {creator.createdAt && (
            <Text fontSize="small" color="gray600">
              {t('export.exportDetails.createdAt', {
                createdAt: DateFormatters.compactDatetime(creator.createdAt),
              })}
            </Text>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

const ExportHistoryInstructions = () => {
  const [t] = useTranslation();

  return (
    <Card background="gray100" padding="space12">
      <Grid gap="space8">
        <Text fontSize="small">
          {t('export.exportSummaryInstructions.title')}
        </Text>
        <Grid gap="space4">
          <Link
            href={t('export.exportSummaryInstructions.bookingsLink')}
            external>
            {t('export.exportSummaryInstructions.bookingsLinkText')}
          </Link>
          <Link
            href={t('export.exportSummaryInstructions.otherDocumentsLink')}
            external>
            {t('export.exportSummaryInstructions.otherDocumentsLinkText')}
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

export const ExportHistorySummary = () => {
  const [t] = useTranslation();
  const state = useContext(ExportContext);
  const { canUseReimbursement } = useReimbursement();

  const { entitiesForReExport, exportDetails } =
    useExportDataGigaFactory(state);

  const exportHistoryEntities = {
    documents: entitiesForReExport?.documents.filter(
      doc => !hasTransaction(doc)
    ),
    transactions: [
      ...entitiesForReExport?.documents.filter(doc => hasTransaction(doc)),
      ...entitiesForReExport?.transactions,
    ],
    settlements: entitiesForReExport.settlements,
    provisions: entitiesForReExport.provisions,
    reversals: entitiesForReExport.reversals,
    contacts: exportDetails?.exportedContactsInfo,
    paymentConditions: exportDetails?.exportedPaymentConditionsInfo,
    reimbursementItems: entitiesForReExport.reimbursementItems,
  };

  const creator = {
    name: exportDetails?.creator
      ? getTeamMemberFullName(exportDetails.creator)
      : '',
    avatarUrl: exportDetails?.creator?.avatarUrl,
    createdAt: exportDetails?.createdAt,
  };

  const isCandisApiExport = exportDetails?.type === ExportType.API;

  const numberOfExportBatches = Object.values(exportHistoryEntities).filter(
    Boolean
  ).length;

  return (
    <Grid
      gap="space32"
      paddingTop="space24"
      paddingBottom="space12"
      color="gray800">
      <Grid gap="space12">
        <Text>
          {exportDetails?.type === ExportType.DATEV_BDS
            ? numberOfExportBatches > 0
              ? t('export.exportHistorySummary.batchesCount', {
                  count: numberOfExportBatches,
                })
              : ''
            : t('export.exportHistorySummary.exportedTitle')}
        </Text>
        <ApprovalsSummary approvals={exportHistoryEntities.documents} />
        <BdsSummary
          provisions={exportHistoryEntities.provisions}
          reversals={exportHistoryEntities.reversals}
        />
        <TransactionsSummary
          cardTransactions={exportHistoryEntities.transactions}
          cardSettlements={exportHistoryEntities.settlements}
        />
        {canUseReimbursement && (
          <ReimbursementItemsSummary
            reimbursementItems={exportHistoryEntities.reimbursementItems}
          />
        )}

        <CoreDataSummary
          contacts={exportHistoryEntities.contacts}
          paymentConditions={exportHistoryEntities.paymentConditions}
        />
      </Grid>
      <Grid gap="space12">
        {!isCandisApiExport && (
          // we hide the creator for API exports because it's not the actual creator but the user who created the API session
          <ExportCreator creator={creator} />
        )}
        {exportDetails?.type === ExportType.DATEV && (
          <DxsoProtocol
            details={exportDetails?.integration?.details}
            targetClient={exportDetails?.client}
          />
        )}
        {exportDetails?.type === ExportType.DATEV_BDS && (
          <ExportHistoryInstructions />
        )}
      </Grid>
    </Grid>
  );
};
