import {
  ComboBox,
  ComboBoxProps,
  FieldContainer,
  FieldContainerProps,
  Item,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { LayoutProps } from '@candisio/design-system/src/types';
import {
  FocusEventHandler,
  Key,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormField } from './useHookFormField';

/** @ts-expect-error TODO: React upgrade props types mismatch */
export interface HookFormComboBoxFieldProps<TFormValues extends FieldValues>
  extends Omit<FieldContainerProps, 'onChange'>,
    Pick<ComboBoxProps, 'groupHeaders' | 'groupedLists' | 'isGroupedListMode'> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Keep the input value even if not found in list items */
  allowsCustomValue?: boolean;
  /** Show a placeholder when combo box dropdown is empty? */
  allowsEmptyCollection?: boolean;
  /** Should field focus on mount? */
  autoFocus?: boolean;
  /**
   * `<Item>` elements representing combo box options, or custom item render
   * function
   */
  children?: ComboBoxProps['children'];
  /** (Uncontrolled) initial value for combo box input */
  defaultInputValue?: string;
  /** Initial combo box options */
  defaultItems?: any[];
  /** Is field disabled? */
  disabled?: boolean;
  /** Shown when dropdown list is empty */
  emptyListPlaceholder?: ReactNode;
  /** (Controlled) value of the combo box input */
  inputValue?: string;
  initialTopMostItemIndex?: ComboBoxProps['initialTopMostItemIndex'];
  /** Use list virtualization? */
  isVirtualized?: boolean;
  /** Necessary if we want to render inside the dropdown items taller than 32px */
  itemHeight?: LayoutProps['height'];
  /** Combo box options */
  items?: ComboBoxProps['items'];
  /** Disabled options */
  disabledKeys?: ComboBoxProps['disabledKeys'];
  /** Field label */
  label: string | ReactNode;
  /** Indicates if the options inside the field are still to be loaded */
  loading?: boolean;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Called when field value changes */
  onChange?: (newValue: Key | null) => void;
  /** Called when user scrolls to end of dropdown list */
  onEndReached?: (index: number) => void;
  /** Called when the combo box input changes */
  onInputChange?: (value: string) => void;
  /** Called when user searches in the input field */
  onSearch?: (inputValue: string) => void | Promise<void>;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Custom render function for combo box dropdown */
  renderCustomDropdown?: (listbox: ReactElement) => ReactNode;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Allow to clear field */
  clearable?: boolean;
  /** Register input's ref into the hook form, please note that it can have unintended side effects */
  forceInputFieldRef?: boolean;
  /** Should show error message even field is read only */
  shouldShowErrorOnReadOnly?: boolean;
}

// @TODO Design system ComboBox should already do something similar
const defaultChildren: ComboBoxProps['children'] = item => <Item {...item} />;

/**
 * Controlled combo-box field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormComboBoxField = <TFormValues extends FieldValues>({
  allowsCustomValue,
  allowsEmptyCollection,
  autoFocus,
  children = defaultChildren,
  control,
  defaultInputValue,
  defaultItems = [],
  disabled,
  disabledKeys,
  emptyListPlaceholder,
  initialTopMostItemIndex,
  inputValue,
  isVirtualized,
  itemHeight,
  items,
  label,
  loading,
  message,
  name,
  onChange: onChangeProp,
  onEndReached,
  onInputChange,
  onSearch,
  placeholder,
  readOnly: readOnlyProp,
  renderCustomDropdown,
  variant,
  isLoading,
  clearable = true,
  forceInputFieldRef,
  shouldShowErrorOnReadOnly = false,
  ...restProps
}: HookFormComboBoxFieldProps<TFormValues>) => {
  const [t] = useTranslation();

  const {
    fieldContainerProps,
    inputProps: { onChange, value, readOnly, ...inputProps },
  } = useHookFormField<TFormValues>({
    control,
    disabled,
    message,
    name,
    onChange: onChangeProp,
    readOnly: readOnlyProp,
    variant,
    forceInputFieldRef,
  });

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  const [searching, setSearching] = useState(false);
  const { fieldState } = useController({ control, name });
  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.target.select();
  };

  return (
    <FieldContainer
      label={label}
      isLoading={isLoading}
      {...(mergeProps(fieldContainerProps, restProps, labelProps) as Omit<
        FieldContainerProps,
        'color'
      >)}>
      {/** @ts-expect-error TODO: React upgrade props types mismatch */}
      <ComboBox
        allowsCustomValue={allowsCustomValue}
        allowsEmptyCollection={allowsEmptyCollection}
        autoFocus={autoFocus}
        defaultInputValue={defaultInputValue}
        defaultItems={defaultItems}
        disabledKeys={disabledKeys}
        emptyListPlaceholder={emptyListPlaceholder ?? t('common.nothingFound')}
        initialTopMostItemIndex={initialTopMostItemIndex}
        inputValue={inputValue}
        isVirtualized={isVirtualized}
        itemHeight={itemHeight}
        items={items}
        loading={loading || searching}
        onEndReached={onEndReached}
        onInputChange={async (newInputValue: string) => {
          onInputChange?.(newInputValue);

          setSearching(true);
          await onSearch?.(newInputValue);
          setSearching(false);
        }}
        onSelectionChange={newValue => {
          if (newValue === value) {
            return;
          }

          if (newValue === null && !allowsCustomValue) {
            onInputChange?.('');
          }

          flushSync(() => {
            onChange(newValue);
            onChangeProp?.(newValue);
          });
        }}
        placeholder={readOnly ? '–' : placeholder}
        renderCustomDropdown={renderCustomDropdown}
        selectedKey={value ?? null}
        message={errorMessage ?? message}
        variant={hasError ? 'error' : variant}
        clearable={clearable}
        clearLabel={t('common.clear')}
        showMessageOnFocus={hasError || variant === 'error'}
        onFocus={handleFocus}
        showStatusOnReadOnly={hasError && shouldShowErrorOnReadOnly}
        {...mergeProps(inputProps, restProps, labelFieldProps)}
        data-cy={name}>
        {children}
      </ComboBox>
    </FieldContainer>
  );
};
