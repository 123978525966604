import { ClassNames, ClassNamesContent } from '@emotion/react';
import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import {
  CSSTransitionClassNames,
  CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import { AnimationCSSObject } from '../AnimationBox';

type CastCSSObject = (cssObject: ClassNamesContent) => string;

type SwitchAnimationBoxProps = {
  mode: 'out-in' | 'in-out';
  animation: AnimationCSSObject;
  trigger: boolean;
} & Omit<CSSTransitionProps, 'children'>;

/**
 * AnimationsBox wraps the CSSTransition component from [React Transition Group](https://reactcommunity.org/) and makes it usable with [Emotion](https://emotion.sh/).
 *
 * @param {AnimationCSSObject} animation CSS transition styling as object
 */
export const SwitchAnimationBox = React.forwardRef<
  HTMLDivElement,
  SwitchAnimationBoxProps
>(
  (
    { mode = 'out-in', animation, trigger, children, ...props },
    forwardedRef
  ) => {
    // Map the classnames created by Emotion to the classnames used by CSSTransition
    const createClassNames = (
      css: CastCSSObject,
      animation: AnimationCSSObject
    ) => {
      return Object.assign(
        {},
        ...Object.entries(animation).map(([animationName, cssObject]) => ({
          [animationName]: css(cssObject as unknown as ClassNamesContent),
        }))
      ) as CSSTransitionClassNames;
    };

    return (
      <div ref={forwardedRef}>
        <ClassNames>
          {({ css }) => (
            <SwitchTransition mode={mode}>
              <CSSTransition
                key={trigger ? 'A' : 'B'}
                addEndListener={(node, done) => {
                  node.addEventListener('transitionend', done, false);
                }}
                classNames={createClassNames(
                  css as unknown as CastCSSObject,
                  animation
                )}
                {...props}>
                <div>{children}</div>
              </CSSTransition>
            </SwitchTransition>
          )}
        </ClassNames>
      </div>
    );
  }
);
