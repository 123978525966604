import { CSSObject } from '@emotion/react';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Box } from '../../Atoms/Box';
import { Radii, Skeleton } from '../../Atoms/Skeleton';

const MotionBox = motion(Box);
const MotionSkeleton = motion(Skeleton);

const boxVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.6, ease: 'easeInOut' },
  },
  hidden: {
    opacity: 0,
  },
};

const skeletonVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' },
  },
  hidden: {
    opacity: 0,
  },
};

interface LoadingSkeletonProps {
  children: ReactNode;
  isLoading: boolean | undefined;
  borderRadius?: Radii;
  isBlockLevel?: boolean;
}

/**
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-inlineskeleton}
 *
 * @typedef {Object} LoadingSkeletonProps
 * @property {ReactNode} children pass in the element/s that to be wrapped by the Skeleton
 * @property {boolean} isLoading boolean responsible for show and hiding the Skeleton
 * @property {Radii} borderRadius optional Radii value to match wrapped elements border radius i.e an input field
 *
 */
export const InlineSkeleton = React.forwardRef<
  HTMLDivElement,
  LoadingSkeletonProps
>(
  (
    { children, isLoading, borderRadius, isBlockLevel = false, ...restProps },
    ref
  ) => {
    const [style, setStyle] = useState<any>();
    const boxRef = useRef<HTMLElement>(null);

    const boxStyle: CSSObject = {
      display: isBlockLevel ? 'block' : 'inline-block',
      cursor: isLoading ? 'default' : undefined,
    };

    const skeletonStyle: CSSObject = {
      position: 'absolute',
      top: '0',
      borderRadius: borderRadius,
    };

    useEffect(() => {
      setStyle({
        width: `${boxRef.current?.clientWidth}px`,
        height: `${boxRef.current?.clientHeight}px`,
      });
    }, [boxRef]);

    return (
      <Box ref={ref} {...restProps}>
        <MotionSkeleton
          css={[skeletonStyle, style]}
          variants={skeletonVariants}
          animate={isLoading ? 'visible' : 'hidden'}
          // passing height and width only because they are required props
          height="auto"
          width="auto"
        />
        <MotionBox
          css={boxStyle}
          variants={boxVariants}
          initial="hidden"
          animate={isLoading ? 'hidden' : 'visible'}
          ref={boxRef}
          {...restProps}>
          {children}
        </MotionBox>
      </Box>
    );
  }
);
