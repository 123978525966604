import {
  ReimbursementCaseFiltersInput,
  ReimbursementCaseSortInput,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { inboxDefaultFiltersBE } from 'views/Inbox/Reimbursements/toolkit/hooks/useInboxReimbursementsData';
import { defaultReimbursementDashboardSort } from './useOpenReimbursementsCursorBasedData';
import { RouteType } from './useReimbursementNavigation';

type DEFAULT_PARAMETERS =
  | {
      filters?: ReimbursementCaseFiltersInput;
      sortBy?: ReimbursementCaseSortInput;
    }
  | undefined;

export const useDefaultQueryParameters = (props: RouteType) => {
  const currentUserId = useCurrentUser()?.id ?? '';

  const parameters: Record<RouteType, DEFAULT_PARAMETERS> = {
    [Routes.ARCHIVE]: undefined,
    [Routes.INBOX]: {
      filters: inboxDefaultFiltersBE,
    },
    [Routes.REIMBURSEMENTS_DASHBOARD]: {
      filters: {
        targetMembershipIds: [currentUserId],
      },
      sortBy: defaultReimbursementDashboardSort,
    },
  };

  return parameters[props];
};
