import { Flex, Image, Link, Text } from '@candisio/design-system';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  PromotionTrackingEvents,
  PromotionFeatures,
} from 'providers/AnalyticsProvider/events/MobileAppPromotionTrackingEvents';
import { Trans } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileAppPromotionModal } from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/MobileAppPromotionModal';
import qrCode from './media/Approvals_empty_state_QR_code.jpg';
import mobileAppPromoImage from './media/mobile-app-promo-approvals-empty-state.png';

export const ApprovalsEmptyStateMobilePromo = () => {
  const [t] = useTranslation();
  const { track } = useAnalytics();

  const [mobileAppPromotionModalVisible, setMobileAppPromotionModalVisible] =
    useState(false);

  return (
    <>
      <Flex
        gap="space16"
        justifyContent="center"
        direction="column"
        alignItems="center">
        <Image
          width="200px"
          height="150px"
          alt={t('documents-table:emptyState.mobileAppPromotion.imageAlt')}
          src={mobileAppPromoImage}
        />
        <Flex width="200px" alignItems="center" direction="column" gap="space2">
          <Text textAlign="center" wordBreak="break-word">
            <Trans i18nKey="documents-table:emptyState.mobileAppPromotion.description">
              Did you know that you can use the Candis mobile app to
              <Text fontWeight="bold">approve your invoices</Text> quickly and
              easily?
            </Trans>
          </Text>
          <Link
            icon="arrowDownCircle"
            iconPosition="right"
            fontSize="basic"
            onClick={() => {
              setMobileAppPromotionModalVisible(true);
              track(PromotionTrackingEvents.ADOPTION_MODAL_OPENED, {
                feature: PromotionFeatures.MOBILE_APP,
              });
            }}>
            {t('documents-table:emptyState.mobileAppPromotion.linkLabel')}
          </Link>
        </Flex>
      </Flex>
      <MobileAppPromotionModal
        qrCode={qrCode}
        visible={mobileAppPromotionModalVisible}
        onCancel={() => setMobileAppPromotionModalVisible(false)}
      />
    </>
  );
};
