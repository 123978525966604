import { WatchQueryFetchPolicy } from '@apollo/client';
import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import {
  DocumentCurrency,
  useCalculateDocumentGrossNetTaxRoundingAmountsLazyQuery,
  useCalculateDocumentGrossNetTaxRoundingAmountsQuery,
} from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { useCallback } from 'react';
import { ProcessingFormValues } from '../ProcessingForm/processingFormSchema';
import { SplitDS } from '../SplitBookingsForm/types';

type CalculateRoundingAmountCallBackProps = {
  currency: DocumentCurrency;
  grossAmount: number;
  bookings:
    | SplitDS[]
    | DocumentApprovalFormValues['bookings']
    | ProcessingFormValues['bookings'];
};
type UseCalculateRoundingAmountProps = {
  fetchPolicy?: WatchQueryFetchPolicy;
};
export const useGetCalculateRoundingAmount = ({
  fetchPolicy = 'no-cache',
}: UseCalculateRoundingAmountProps = {}) => {
  const [calculateDocumentGrossNetTaxRoundingAmounts] =
    useCalculateDocumentGrossNetTaxRoundingAmountsLazyQuery({ fetchPolicy });

  return useCallback(
    async ({
      currency,
      grossAmount,
      bookings,
    }: CalculateRoundingAmountCallBackProps) => {
      const { data } = await calculateDocumentGrossNetTaxRoundingAmounts({
        variables: {
          input: {
            currency,
            grossAmount,
            splitBookingsAmounts: bookings.map(booking => ({
              netAmount: booking.netAmount ?? 0,
              taxAmount: booking.taxAmount ?? 0,
            })),
          },
        },
      });

      if (
        data?.calculateDocumentGrossNetTaxRoundingAmounts.__typename ===
        'CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult'
      ) {
        return data.calculateDocumentGrossNetTaxRoundingAmounts.rounding;
      }

      return null;
    },
    [calculateDocumentGrossNetTaxRoundingAmounts]
  );
};

export const useCalculatedRoundingAmountResult = ({
  currency,
  grossAmount,
  bookings,
}: CalculateRoundingAmountCallBackProps) => {
  const { shouldUseSapNetAmount } = useSap();
  const { data: roundingAmountResponseData } =
    useCalculateDocumentGrossNetTaxRoundingAmountsQuery({
      variables: {
        input: {
          currency: currency,
          grossAmount: grossAmount,
          splitBookingsAmounts: bookings.map(booking => ({
            netAmount: booking.netAmount ?? 0,
            taxAmount: booking.taxAmount ?? 0,
          })),
        },
      },
      skip:
        !shouldUseSapNetAmount || !grossAmount || !currency || !bookings.length,
    });

  const updatedRoundingAmount =
    roundingAmountResponseData?.calculateDocumentGrossNetTaxRoundingAmounts
      .__typename === 'CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult'
      ? roundingAmountResponseData?.calculateDocumentGrossNetTaxRoundingAmounts
          ?.rounding
      : 0;

  return updatedRoundingAmount;
};
