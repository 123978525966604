import {
  SortDirection,
  TeamsFilterInput,
  TeamsSortField,
  useTeamInvolvementsByMembershipIdLazyQuery,
} from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';
import { useTeams } from 'orgConfig/teams/useTeams';

export interface Team {
  id: string;
  name: string;
}

export const useTeamInvolvements = (filters: TeamsFilterInput = {}) => {
  const { isTeamsFeatureEnabled } = useTeams();

  const [fetchTeams, { data: currentData, loading, previousData }] =
    useTeamInvolvementsByMembershipIdLazyQuery({
      variables: {
        input: {
          page: 1,
          limit: 100,
        },
        filters: {
          isArchived: false,
          adminMembershipIds: filters.adminMembershipIds ?? undefined,
        },
        sortBy: {
          direction: SortDirection.Desc,
          field: TeamsSortField.Name,
        },
      },
      fetchPolicy: 'no-cache',
    });

  useFetchOnWindowFocus({
    fetch: fetchTeams,
    skip: !isTeamsFeatureEnabled,
  });

  const data = !loading
    ? currentData?.teamInvolvementsByMembershipId
    : previousData?.teamInvolvementsByMembershipId;

  const records = data?.records ?? [];
  const teamsCount = data?.pageInfo?.totalCount ?? 0;

  const teams: Team[] = records.map(team => ({
    id: team.id,
    name: team.name,
  }));

  return {
    teams,
    teamsCount,
    isTeamsLoading: loading && typeof previousData === 'undefined',
  };
};
