import { Card, Grid, Skeleton } from '@candisio/design-system';

export const ContactSettingsLoader = () => {
  return (
    <Card paddingX="space24" paddingY="space16">
      <Grid gap="space16">
        <Skeleton height="space40" width="100%" />
        <Grid autoFlow="column" justifyContent="start" gap="space16">
          <Skeleton height="space40" width="space256" />
          <Skeleton height="space40" width="space64" />
        </Grid>
      </Grid>
    </Card>
  );
};
