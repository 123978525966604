import { HookFormUserFieldItem } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import {
  MembershipStatus,
  OrganizationMembershipsQueryVariables,
  useOrganizationMembershipsQuery,
} from 'generated-types/graphql.types';

export type MembershipStatusType =
  | keyof typeof MembershipStatus
  | 'Pending'
  | 'ActiveAndPending';

export type MembershipsQueryFilter = Record<
  MembershipStatusType,
  OrganizationMembershipsQueryVariables
>;

export const membershipsQueryFilter: MembershipsQueryFilter = {
  Pending: {
    filters: { status: MembershipStatus.Active, emailVerified: false },
  },
  Active: {
    filters: { status: MembershipStatus.Active, emailVerified: true },
  },
  Inactive: {
    filters: { status: MembershipStatus.Inactive },
  },
  ActiveAndPending: {
    filters: { status: MembershipStatus.Active },
  },
};

export interface UserFieldItem extends HookFormUserFieldItem {
  key: string;
  children: string;
}

interface UseUsersListOptionsProps {
  status?: MembershipStatusType;
}

export const useUsersFieldOptions = ({
  status,
}: UseUsersListOptionsProps = {}) => {
  const { data, loading } = useOrganizationMembershipsQuery({
    variables: status ? membershipsQueryFilter[status] : undefined,
  });

  const userData = data?.organizationMemberships ?? [];

  const users: UserFieldItem[] = userData.map(user => {
    return {
      key: user.id,
      children: user.name,
      avatarUrl: user.avatarUrl ?? undefined,
    };
  });

  return { users, loading };
};
