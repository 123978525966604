import { Flex, Text } from '@candisio/design-system';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import type { DocumentTypeSettingFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { DOCUMENT_TYPE_TRANSLATION_MAP } from '../toolkit/consts';
import { DocumentTypesSettingIsVisibleSwitch } from './DocumentTypesIsVisibleSwitch';
import { DocumentTypesViewDocumentLink } from './DocumentTypesViewDocumentLink';

interface DocumentTypesListItemProps {
  documentType: DocumentTypeSettingFragment;
  query: string;
  viewDocumentsLinkPrefix: string;
}

export function DocumentTypesListItem({
  documentType,
  query,
  viewDocumentsLinkPrefix,
}: DocumentTypesListItemProps) {
  const [t] = useTranslation();
  const translationKey =
    DOCUMENT_TYPE_TRANSLATION_MAP[
      documentType.name as keyof typeof DOCUMENT_TYPE_TRANSLATION_MAP
    ];

  const name = t(translationKey);
  const viewDocumentLink = viewDocumentsLinkPrefix + documentType.name;

  return (
    <StyledFilterableListItem
      itemId={documentType.name}
      hasAction
      templateColumns="auto 1fr">
      <Flex direction="column">
        <Text height={22} fontSize="basic">
          {renderQueryHighlight({
            value: name ?? '',
            queryString: query,
          })}
        </Text>

        <Text color="gray500">
          {t('settings:contractCustomSubCategories.list.documentCount', {
            count: documentType.documentCount,
          })}
        </Text>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" gap="space10">
        <DocumentTypesViewDocumentLink link={viewDocumentLink} />
        <DocumentTypesSettingIsVisibleSwitch documentType={documentType} />
      </Flex>
    </StyledFilterableListItem>
  );
}

const StyledFilterableListItem = styled(FilterableListItem)`
  --link-opacity: 0;
  --link-pointer: none;
  --link-scale: 0.9;

  &:hover {
    --link-opacity: 1;
    --link-pointer: auto;
    --link-scale: 1;
  }
`;
