import { Flex, Text, Tag } from '@candisio/design-system';
import { Money } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentInfoTag } from 'views/Inbox/DocumentProcessing/components/utils';
import { formatDate } from './utils';

type DetailsBoxProps = {
  boxType: 'purchaseOrder' | 'goodsReceipt';
  statusLabel?: string;
  itemNumber?: string;
  date?: string;
  amount?: Money | null;
};

export const DetailsBox = ({
  boxType,
  statusLabel,
  itemNumber,
  date,
  amount,
}: DetailsBoxProps) => {
  const { documentMoneyFormatter } = useMoneyFormatter();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <Flex
      gap="space12"
      padding="space16 space12"
      data-cy="purchase-order-details-box"
      direction="column">
      <Flex gap="space4" fontSize="basic">
        <DocumentInfoTag>
          {boxType === 'purchaseOrder'
            ? t('detailsCard.title')
            : t('detailsCard.goodsReceiptTitle')}
        </DocumentInfoTag>
      </Flex>

      <Flex justifyContent="space-between">
        <Flex gap="space4" borderRadius="medium" direction="column">
          <Text
            fontWeight="semibold"
            fontSize="basic"
            color="gray800"
            lineHeight="140%">
            {itemNumber ?? ''}
          </Text>
          <Text color="gray500" fontSize="basic" lineHeight="140%">
            {formatDate(date)}
          </Text>
        </Flex>
        <Flex direction="column" gap="space4">
          <Tag
            variant="secondary"
            color="gray"
            style={{
              marginLeft: 'auto',
              marginRight: '0',
            }}>
            {statusLabel}
          </Tag>
          {amount && (
            <Text color="gray500" fontSize="basic" lineHeight="140%">
              {documentMoneyFormatter(amount)}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
