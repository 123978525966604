import {
  DocumentCurrency,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const availabeFiltersAndSorts = [
  'approver',
  'createdAt',
  'grossAmount',
  'isPaid',
  'paidAt',
  'requesterOfTheReimbursement',
  'status',
  'title',
];

export const sampleData: ReimbursementTableRow[] = [
  {
    id: '1',
    isPaid: false,
    createdAt: new Date('10/1/2024'),
    requesterOfTheReimbursement: {
      membershipId: '1',
      firstName: 'Arnold',
      lastName: 'Wagner',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Kundentermin',
    iban: 'DE45 1234 5678 9012 3456 78',
    grossAmount: {
      amount: 2089,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Nicoleta',
      membershipId: 'member-1',
      lastName: ' Marin',
    },
  },
  {
    id: '2',
    isPaid: false,
    createdAt: new Date('10/5/2024'),
    iban: 'DE98 8765 4321 0987 6543 21',
    requesterOfTheReimbursement: {
      membershipId: '2',
      firstName: 'Yara ',
      lastName: 'Kimura',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Bewirtung_Firma_Kraus',
    grossAmount: {
      amount: 2000,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Elias',
      membershipId: 'member-1',
      lastName: 'Schuster',
    },
  },
  {
    id: '3',
    isPaid: false,
    createdAt: new Date('10/3/2024'),
    iban: 'DE23 1098 7654 3210 9876 54',
    requesterOfTheReimbursement: {
      membershipId: '3',
      firstName: 'Mateo',
      lastName: 'Fernández',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Getränke Büro',
    grossAmount: {
      amount: 3450,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Elias',
      membershipId: 'member-1',
      lastName: 'Schuster',
    },
  },
  {
    id: '4',
    isPaid: false,
    createdAt: new Date('10/10/2024'),
    iban: 'DE67 2345 6789 0123 4567 89',
    requesterOfTheReimbursement: {
      membershipId: '4',
      firstName: 'David',
      lastName: ' Müller',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'MacBook',
    grossAmount: {
      amount: 11063,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Elias',
      membershipId: 'member-1',
      lastName: 'Schuster',
    },
  },
  {
    id: '5',
    isPaid: false,
    createdAt: new Date('10/12/2024'),
    iban: 'DE12 3456 7890 1234 5678 90',
    requesterOfTheReimbursement: {
      membershipId: '5',
      firstName: 'Zara',
      lastName: 'Patel',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Kundentermin',
    grossAmount: {
      amount: 83000,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Nicoleta',
      membershipId: 'member-1',
      lastName: 'Marin',
    },
  },
  {
    id: '6',
    isPaid: false,
    createdAt: new Date('10/9/2024'),
    iban: 'DE76 5432 1098 7654 3210 98',
    requesterOfTheReimbursement: {
      membershipId: '6',
      firstName: 'Sophie',
      lastName: ' Kramer',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Snacks Büro',
    grossAmount: {
      amount: 4360,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Elias',
      membershipId: 'member-1',
      lastName: 'Schuster',
    },
  },
  {
    id: '7',
    isPaid: false,
    createdAt: new Date('10/7/2024'),
    iban: 'DE89 6789 0123 4567 8901 23',
    requesterOfTheReimbursement: {
      membershipId: '7',
      firstName: 'Svenja',
      lastName: ' Johansson',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Laptop',
    grossAmount: {
      amount: 98000,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Elias',
      membershipId: 'member-1',
      lastName: 'Schuster',
    },
  },
  {
    id: '8',
    isPaid: false,
    createdAt: new Date('8/10/2024'),
    iban: 'DE54 2109 8765 4321 0987 65',
    requesterOfTheReimbursement: {
      membershipId: '8',
      firstName: 'Julia',
      lastName: 'Schäfer',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Kundentermin',
    grossAmount: {
      amount: 1258,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Nicoleta',
      membershipId: 'member-1',
      lastName: 'Marin',
    },
  },
  {
    id: '9',
    isPaid: false,
    createdAt: new Date('2/10/2024'),
    iban: 'DE31 5678 9012 3456 7890 12',
    requesterOfTheReimbursement: {
      membershipId: '9',
      firstName: 'Hannah',
      lastName: ' Beck',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Geschäftsessen_Firma_ABC',
    grossAmount: {
      amount: 14868,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Nicoleta',
      membershipId: 'member-1',
      lastName: 'Marin',
    },
  },
  {
    id: '10',
    isPaid: false,
    createdAt: new Date('1/10/2024'),
    iban: 'DE65 4321 0987 6543 2109 87',
    requesterOfTheReimbursement: {
      membershipId: '10',
      firstName: 'Marie',
      lastName: ' Braun',
    },
    status: ReimbursementCaseStatus.Check,
    title: 'Verpflegung_Event',
    grossAmount: {
      amount: 3810,
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
    approver: {
      firstName: 'Elias',
      membershipId: 'member-1',
      lastName: 'Schuster',
    },
  },
];
