import { MutationResult } from '@apollo/client';
import { getMemberships } from 'components/Comment/gql';
import {
  UpdateUserInput,
  useUpdateMembershipMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { useCallback } from 'react';
import { getApproversQuery } from './queries';
import {
  MembershipInvolvedResponse,
  MembershipOnlyAdminResponse,
  MembershipPendingApprovalsResponse,
} from './responses';
import { TeamMemberFormOutput } from './TeamMemberDetails/containers/teamMemberFormSchema';
import { sanitizeUpdateFormData } from './utils';

type UpdateMembershipCallbackMembershipRoles = (
  id: string,
  input: Omit<TeamMemberFormOutput, 'email'>
) => Promise<
  | MembershipInvolvedResponse
  | MembershipOnlyAdminResponse
  | MembershipPendingApprovalsResponse
  | void
>;
type UpdateMembershipCallback = (
  id: string,
  input: UpdateUserInput
) => Promise<
  | MembershipInvolvedResponse
  | MembershipOnlyAdminResponse
  | MembershipPendingApprovalsResponse
  | void
>;

type UpdateMembershipResponse = {
  updateMembership: UpdateMembershipCallback;
  mutationProps: MutationResult;
  updateUser: UpdateMembershipCallbackMembershipRoles;
};

export const useUpdateMembership = (): UpdateMembershipResponse => {
  const [updateMembershipMutation, mutationProps] =
    useUpdateMembershipMutation();

  const counterQueries = useCounterQueries();

  const updateMembershipLegacyRoles = useCallback<UpdateMembershipCallback>(
    async (id, input) => {
      const { errors } = await updateMembershipMutation({
        variables: { input, id },
        refetchQueries(result) {
          if (result.errors) {
            return [];
          }

          return [
            ...counterQueries,
            'organizationMemberships',
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
            {
              query: getMemberships,
              variables: membershipsQueryFilter.Active,
            },
          ];
        },
      });

      if (errors?.length) {
        for (const e of errors) {
          if (e.extensions?.code === 'INVOLVED') {
            return { _tag: 'INVOLVED' };
          }

          if (e.extensions?.code === 'ONLY_ADMIN') {
            return { _tag: 'ONLY_ADMIN' };
          }

          if (e.extensions?.code === 'PENDING_APPROVALS') {
            return {
              _tag: 'PENDING_APPROVALS',
              numberOfPendingApprovals: (
                e.extensions?.exception as MembershipPendingApprovalsResponse
              )?.numberOfPendingApprovals,
            };
          }
        }
      }
    },
    [updateMembershipMutation, counterQueries]
  );

  const updateUser = useCallback<UpdateMembershipCallbackMembershipRoles>(
    async (id, input) => {
      const sanitizedFormData = sanitizeUpdateFormData(input);
      const { errors } = await updateMembershipMutation({
        variables: { input: sanitizedFormData, id },
        refetchQueries(result) {
          if (result.errors) {
            return [];
          }

          return [
            ...counterQueries,
            'organizationMemberships',
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
          ];
        },
      });

      if (errors?.length) {
        for (const e of errors) {
          if (e.extensions?.code === 'INVOLVED') {
            return { _tag: 'INVOLVED' };
          }

          if (e.extensions?.code === 'ONLY_ADMIN') {
            return { _tag: 'ONLY_ADMIN' };
          }

          if (e.extensions?.code === 'PENDING_APPROVALS') {
            return {
              _tag: 'PENDING_APPROVALS',
              numberOfPendingApprovals: (
                e.extensions?.exception as MembershipPendingApprovalsResponse
              )?.numberOfPendingApprovals,
            };
          }
        }
      }
    },
    [updateMembershipMutation, counterQueries]
  );

  return {
    updateMembership: updateMembershipLegacyRoles,
    mutationProps: mutationProps as MutationResult,
    updateUser,
  };
};
