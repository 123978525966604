import { Grid, Text } from '@candisio/design-system';
import {
  DocumentAssociationsCreatedEvent,
  GoodsReceiptAssociation,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentAssociationsCreatedEventComponent = ({
  associatedPurchaseOrders,
}: DocumentAssociationsCreatedEvent) => {
  const [t] = useTranslation();

  if (!associatedPurchaseOrders?.length) return null;

  const formatGoodsReceiptNumbers = (
    goodsReceipts: GoodsReceiptAssociation[]
  ) => {
    return goodsReceipts?.map(i => i?.receiptNumber).join(', ');
  };

  const getGoodsReceiptLabel = (
    associatedGoodsReceipts: GoodsReceiptAssociation[]
  ) => {
    const count = associatedGoodsReceipts?.length;

    return count === 1
      ? t(
          'document.compact.history.documentAssociationsCreatedEvent.goodsReceipt.singular',
          {
            receiptNumber: associatedGoodsReceipts[0]?.receiptNumber,
          }
        )
      : t(
          'document.compact.history.documentAssociationsCreatedEvent.goodsReceipt.plural',
          {
            receiptNumbers: formatGoodsReceiptNumbers(associatedGoodsReceipts),
          }
        );
  };

  return (
    <Grid>
      {associatedPurchaseOrders?.length === 1 ? (
        <Text>
          {t('document.compact.history.documentAssociationsCreatedEvent.one', {
            orderNumber: associatedPurchaseOrders[0]?.orderNumber,
            goodsReceiptLabel: getGoodsReceiptLabel(
              (associatedPurchaseOrders?.[0]?.associatedGoodsReceipts ??
                []) as GoodsReceiptAssociation[]
            ),
          })}
        </Text>
      ) : (
        <Text>
          {t('document.compact.history.documentAssociationsCreatedEvent.many', {
            orderNumbers: associatedPurchaseOrders
              .map(i => i?.orderNumber)
              .join(', '),
            goodsReceiptLabel: getGoodsReceiptLabel(
              (associatedPurchaseOrders?.flatMap(
                i => i?.associatedGoodsReceipts
              ) ?? []) as GoodsReceiptAssociation[]
            ),
          })}
        </Text>
      )}
    </Grid>
  );
};
