import { ApolloCache, ApolloClient, useApolloClient } from '@apollo/client';
import { captureException, captureMessage } from '@sentry/react';
import { WAIT_BEFORE_REFETCHING_DOCUMENT_HISTORY_MS } from 'components/Comment/utils';
import { Query } from 'generated-types/graphql.types';
import { ecmDocumentHistoryQuery } from 'views/EcmDocument/queries';
import { documentHistoryQuery } from 'views/queries';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { useOptimisticComment } from './useOptimisticComment';

const refetchDocumentCacheWithDelay = (
  client: ApolloClient<any>,
  documentId: string
) => {
  setTimeout(async () => {
    try {
      await client.query({
        query: isEcmDocumentId(documentId)
          ? ecmDocumentHistoryQuery
          : documentHistoryQuery,
        variables: { id: documentId },
        fetchPolicy: 'network-only',
      });

      captureMessage('Refetched document history and ECM data after delay');
    } catch (e) {
      captureException(new Error(`Error refetching cache: ${e}`));
    }
  }, WAIT_BEFORE_REFETCHING_DOCUMENT_HISTORY_MS);
};

interface UseOptimisticCommentCacheUpdateProps {
  documentId: string;
  mention: string;
}

export const useOptimisticCommentCacheUpdate = () => {
  const { getOptimisticComment } = useOptimisticComment();
  const client = useApolloClient();

  const optimisticCommentCacheUpdate = ({
    documentId,
    mention,
  }: UseOptimisticCommentCacheUpdateProps) => {
    return (proxy: ApolloCache<any>) => {
      try {
        const docCacheData = proxy.readQuery<Pick<Query, 'getDocument'>>({
          query: documentHistoryQuery,
          variables: { id: documentId },
        });

        const ecmCacheData = proxy.readQuery<
          Pick<Query, 'getAggregatedEcmDocument'>
        >({
          query: ecmDocumentHistoryQuery,
          variables: { id: documentId },
        });

        if (!isEcmDocumentId(documentId)) {
          if (!docCacheData?.getDocument) {
            refetchDocumentCacheWithDelay(client, documentId);

            return;
          }

          const optimisticComment = getOptimisticComment({ text: mention });
          const updatedTimeline = [
            optimisticComment,
            ...(docCacheData.getDocument.timeline ?? []),
          ];

          proxy.writeQuery({
            query: documentHistoryQuery,
            variables: { id: documentId },
            data: {
              ...docCacheData,
              getDocument: {
                ...docCacheData.getDocument,
                timeline: updatedTimeline,
              },
            },
          });

          return;
        }

        if (!ecmCacheData?.getAggregatedEcmDocument) {
          refetchDocumentCacheWithDelay(client, documentId);

          return;
        }

        const optimisticComment = getOptimisticComment({ text: mention });
        const updatedTimeline = [
          optimisticComment,
          ...(ecmCacheData.getAggregatedEcmDocument.timeline ?? []),
        ];

        proxy.writeQuery({
          query: ecmDocumentHistoryQuery,
          variables: { id: documentId },
          data: {
            getAggregatedEcmDocument: {
              ...ecmCacheData.getAggregatedEcmDocument,
              timeline: updatedTimeline,
            },
          },
        });
      } catch (e) {
        captureException(new Error(`Error updating cache: ${e}`));
        refetchDocumentCacheWithDelay(client, documentId); // Fallback to refetch
      }
    };
  };

  return { optimisticCommentCacheUpdate };
};
