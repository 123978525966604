import creditcardIsCreated from './lf-creditcardiscreated.json';
import creditorNotMissing from './lf-creditornotmissing.json';
import documentdeleteData from './lf-documentdelete.json';
import documenterrorData from './lf-documenterror.json';
import logoData from './lf-logo.json';
import nocontactData from './lf-nocontact.json';
import nodocumentData from './lf-nodocument.json';
import noemailData from './lf-noemail.json';
import nohistoryData from './lf-nohistory.json';
import noPayableDocuments from './lf-nopayabledocuments.json';
import noresultsData from './lf-noresults.json';
import pulseBlue from './lf-pulse-blue.json';
import pulsePurple from './lf-pulse-purple.json';
import successData from './lf-success.json';
import workflowData from './lf-workflow.json';

export const animations = {
  creditorNotMissing,
  documentdeleteData,
  documenterrorData,
  logoData,
  nocontactData,
  nodocumentData,
  noemailData,
  nohistoryData,
  noPayableDocuments,
  noresultsData,
  pulseBlue,
  pulsePurple,
  successData,
  workflowData,
  creditcardIsCreated,
};

export type AnimationKey = keyof typeof animations;
