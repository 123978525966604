import { CSSObject } from '@emotion/react';
import { forwardRef } from 'react';
import { useTheme } from '../../Theme';
import { StandardHTMLAttributes } from '../../types';

interface LabelProps extends StandardHTMLAttributes<HTMLLabelElement> {
  readOnly?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  (
    {
      children,
      readOnly = false,
      disabled = false,
      isLoading = false,
      ...restProps
    },
    ref
  ) => {
    const { fieldContainer } = useTheme();

    const baseStyle: CSSObject = {
      display: 'block',
      lineHeight: 1,
      ...fieldContainer.label.default,
    };

    return (
      <label
        css={[
          baseStyle,
          readOnly && fieldContainer.label.readOnly,
          disabled && fieldContainer.label.disabled,
          isLoading && fieldContainer.label.isLoading,
        ]}
        {...restProps}
        ref={ref}>
        {children}
      </label>
    );
  }
);
