import { Button } from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  * > svg {
    color: ${colors.gray500};
  }
`;
