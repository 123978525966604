import React, { ComponentProps } from 'react';
import { Box } from '../../Atoms/Box';
import { Flex } from '../../Atoms/Flex';
import { Grid } from '../../Atoms/Grid';
import { Icon } from '../../Atoms/Icon';
import { Text } from '../../Atoms/Typography/Text';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';

type InfoPanelType = 'error' | 'information' | 'warning' | 'promo';

type InfoPanelSize = 'normal' | 'large';

const sizeToPropsMap: Record<
  InfoPanelSize,
  {
    fontSize: ComponentProps<typeof Text>['fontSize'];
    iconSize: ComponentProps<typeof Icon>['size'];
  }
> = {
  normal: {
    fontSize: 'small',
    iconSize: 'space16',
  },
  large: {
    fontSize: 'basic',
    iconSize: 'space20',
  },
};

export interface InfoPanelProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  title?: string;
  variant: InfoPanelType;
  message?: string;
  size?: InfoPanelSize;
}

/**
 * Info panel component to display messages to the user.
 * [Storybook]{@link https://candisio.github.io/design-system/https://candisio.github.io/design-system/?path=/story/molecules-infopanel--error}
 *
 * @param {string} [title] Optional Component title component
 * @param {InfoPanelType} [variant] Component theme variant
 * @param {string} [message] Optional message
 * @param {InfoPanelSize} [size] Optional size of infopanel. Default large.
 */
export const InfoPanel = React.forwardRef<HTMLDivElement, InfoPanelProps>(
  (
    { title, variant, message, size = 'normal', children, ...restProps },
    ref
  ) => {
    const { infoPanel } = useTheme();
    const { fontSize, iconSize } = sizeToPropsMap[size];

    return (
      <Grid
        {...restProps}
        ref={ref}
        width="100%"
        background={infoPanel[variant].backgroundColor}
        borderRadius="medium"
        templateColumns="auto 1fr"
        templateRows="auto auto"
        columnGap="space8"
        rowGap={message ? 'space4' : 0}
        padding="space16">
        <Flex
          gridRow="1"
          gridColumn="1"
          alignItems={title ? 'center' : 'start'}>
          <Icon
            icon={infoPanel[variant].icon.type}
            color={infoPanel[variant].icon.color}
            size={iconSize}
          />
        </Flex>

        {title && (
          <Text
            color={infoPanel[variant].color}
            gridRow="1"
            gridColumn="2"
            fontWeight="semibold"
            fontSize={fontSize}
            lineHeight="paragraph">
            {title}
          </Text>
        )}
        <Box gridRow={title ? '2' : '1'} gridColumn="2">
          {message && (
            <Text
              gridRow="2"
              gridColumn="2"
              fontSize={fontSize}
              color={infoPanel[variant].color}
              lineHeight="body">
              {message}
            </Text>
          )}
          {children}
        </Box>
      </Grid>
    );
  }
);
