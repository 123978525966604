import { CSSObject } from '@emotion/react';
import React from 'react';
import { useTheme } from '../../Theme';
import { ColorProps, LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box } from '../Box';

export interface TableElementProps
  extends StandardHTMLAttributes<HTMLTableElement>,
    LayoutProps,
    ColorProps {}

/**
 * Top level atom that wraps all other table elements.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-data-table}
 */
export const TableElement = React.forwardRef<
  HTMLTableElement,
  TableElementProps
>(({ children, color = 'gray800', width = '100%', ...restProps }, ref) => {
  const { table } = useTheme();

  const baseStyle: CSSObject = {
    isolation: 'isolate',
    borderCollapse: 'collapse',
  };

  return (
    <Box
      as="table"
      css={baseStyle}
      color={color}
      fontSize={table.fontSize}
      width={width}
      ref={ref}
      {...restProps}>
      {children}
    </Box>
  );
});
