import { useCountPendingDocumentsByMembershipLazyQuery } from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';

interface UsePendingDocumentsCountProps {
  membershipId: string;
  setPendingCount: (value: number) => void;
}

export const usePendingDocumentsCount = ({
  membershipId,
  setPendingCount,
}: UsePendingDocumentsCountProps) => {
  const [
    fetchCountPendingDocuments,
    { loading: pendingDocumentsLoading, previousData },
  ] = useCountPendingDocumentsByMembershipLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
    onCompleted: data => {
      const pendingDocumentsCount =
        data?.countPendingDocumentsByMembership.count ?? 0;

      setPendingCount(pendingDocumentsCount);
    },
  });

  useFetchOnWindowFocus({ fetch: fetchCountPendingDocuments });

  return {
    pendingDocumentsLoading:
      pendingDocumentsLoading &&
      typeof previousData?.countPendingDocumentsByMembership === 'undefined',
  };
};
