import { useSapPurchaseOrders } from 'views/DocumentDetails/components/ThreeWayMatch/hooks/useSapPurchaseOrders';
import { getIsQuantityRequired } from './util/getIsQuantityRequired';

/** Fetches linked purchase order for given invoice id */
export const useLinkedPurchaseOrder = (documentId?: string) => {
  const { purchaseOrders, isLoading } = useSapPurchaseOrders(documentId);

  const purchaseOrder = purchaseOrders?.[0];

  return {
    purchaseOrder,
    loading: isLoading,
    isQuantityRequired: getIsQuantityRequired(purchaseOrder?.purchaseOrderType),
  };
};

// TODO - remove this when all forms are migrated to use purchaseOrders
