import {
  useState,
  DragEvent,
  ChangeEvent,
  RefObject,
  useMemo,
  useCallback,
} from 'react';

export const acceptedFileType = 'application/pdf';
interface DragAndDropProps {
  fileUploadRef: RefObject<HTMLInputElement>;
  onFileSelected: (files: File[]) => void;
  isDisabled?: boolean;
}

export const useDragAndDrop = ({
  fileUploadRef,
  onFileSelected,
  isDisabled,
}: DragAndDropProps) => {
  const [isFileDraggable, setIsFileDraggable] = useState<boolean>(false);
  const [isAcceptedFileType, setIsAcceptedFileType] = useState<boolean>(false);
  const [draggedFileCount, setDraggedFileCount] = useState<number>(0);

  const filesSelected = useCallback(
    (files: File[]) => {
      onFileSelected(files);
    },
    [onFileSelected]
  );

  const onFileDrop = useMemo(
    () => (evt: DragEvent<HTMLDivElement>) => {
      if (isDisabled) return;

      const droppedFileCount = evt.dataTransfer.items.length;

      evt.stopPropagation();
      evt.preventDefault();
      setIsFileDraggable(false);
      setDraggedFileCount(0);

      if (droppedFileCount > 1 || !isAcceptedFileType) {
        return;
      }

      filesSelected(Array.from(evt.dataTransfer.files));
    },
    [filesSelected, isAcceptedFileType, isDisabled]
  );

  const onDragOver = useMemo(
    () => (evt: DragEvent<HTMLDivElement>) => {
      if (isDisabled) return;

      const draggedOverFileCount = evt.dataTransfer.items.length;

      const isAccepted = Array.from(evt.dataTransfer.items).every(
        itm => itm.type === acceptedFileType
      );

      setIsAcceptedFileType(isAccepted);

      evt.stopPropagation();
      evt.preventDefault();
      setIsFileDraggable(true);
      setDraggedFileCount(draggedOverFileCount);
    },
    [isDisabled]
  );

  const onDragLeave = () => {
    setIsFileDraggable(false);
    setDraggedFileCount(0);
  };

  const onDropZoneClick = () => {
    if (!fileUploadRef.current || isDisabled) {
      return;
    }

    fileUploadRef.current.click();
  };

  const onFilesChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!evt.target.files || isDisabled) {
      return;
    }

    filesSelected(Array.from(evt.target.files));
  };

  return {
    draggedFileCount,
    isAcceptedFileType,
    isFileDraggable,
    onFileDrop,
    onDragLeave,
    onDragOver,
    onDropZoneClick,
    onFilesChanged,
  };
};
