import {
  HookFormPaginatedComboBoxField,
  HookFormPaginatedComboBoxFieldProps,
} from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AccountingFieldItem,
  PaginatedComboBoxFieldOptions,
} from 'views/Reimbursement/hooks/types';

interface PaginatedComboBoxFieldProps<TFormValues extends FieldValues>
  extends HookFormPaginatedComboBoxFieldProps<TFormValues> {
  name: Path<TFormValues>;
}

export const PaginatedComboBoxField = <TFormValues extends FieldValues>({
  name,
  ...props
}: PaginatedComboBoxFieldProps<TFormValues> &
  PaginatedComboBoxFieldOptions<AccountingFieldItem>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <HookFormPaginatedComboBoxField
      name={name}
      {...props}
      placeholder={t('splitBookings.costObject.placeholder.enabled')}
    />
  );
};
