import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ExpenseItemHospitalityLocationUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { mappedExpenseTypeToGeneralTitle } from 'views/Reimbursement/utils/getActivityTypeToReimbursementHistoryComponent';

export const ExpenseItemHospitalityLocationUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemHospitalityLocationUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const fallBackTitle = reimbursementItemMetadata?.type
    ? t(mappedExpenseTypeToGeneralTitle[reimbursementItemMetadata?.type])
    : undefined;
  return (
    <Grid>
      <Text>
        {t('timeline.expenseChanged', { title: expenseTitle ?? fallBackTitle })}
      </Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t(
              'reimbursementView.middleSection.form.hospitality.location.label'
            )}
          </Text>
          <Paragraph>
            {properties.newLocation
              ? properties.newLocation
              : t('timeline.empty')}
          </Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
