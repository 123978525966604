import { useListIbansQuery } from 'generated-types/graphql.types';
import { toIbanItem } from 'views/Settings/PaymentsSettings/toIbanItem';

export const useOrganizationOwnIbansData = () => {
  const { data: listIbansData, loading: isIbansListDataLoading } =
    useListIbansQuery();

  const ibanItems = listIbansData?.listIbans.map(toIbanItem);

  const organizationOwnIBANs = listIbansData?.listIbans.map
    ? listIbansData.listIbans.map(({ iban }) => iban)
    : [];

  const hasOrgOwnIBANs = organizationOwnIBANs.length > 0;

  const showMissingIBANIndicator = !hasOrgOwnIBANs;

  return {
    ibanItems,
    organizationOwnIBANs,
    isIbansListDataLoading,
    hasOrgOwnIBANs,
    showMissingIBANIndicator,
  };
};
