import { Row } from 'react-table';
import { Checkbox } from '../../../Atoms/Checkbox';
import { Grid } from '../../../Atoms/Grid';
import { BaseTableDataType } from '../Table';

// We are using an older version of typescript where the use of object type is not recommended and therefore we need the following line. See issue https://github.com/microsoft/TypeScript/issues/21732
// eslint-disable-next-line @typescript-eslint/ban-types
interface SelectionHeaderProps<TableData extends BaseTableDataType> {
  selectedFlatRows: Row<TableData>[];
  selectableRows: TableData[];
  onAllRowsSelected: (selectedFlatRows: TableData[]) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const SelectionHeader = <TableData extends BaseTableDataType>({
  selectedFlatRows,
  onAllRowsSelected,
  selectableRows,
}: SelectionHeaderProps<TableData>) => {
  const hasSelectedRows = selectedFlatRows && selectedFlatRows.length > 0;
  const areAllSelectableRowsSelected =
    selectedFlatRows?.length === selectableRows.length;

  const hasSelectableRows = selectableRows.length > 0;

  const handleOnAllRowsSelected = () => {
    if (hasSelectedRows) {
      onAllRowsSelected([]);
    } else {
      onAllRowsSelected?.(selectableRows);
    }
  };

  return (
    <Grid>
      <Checkbox
        height="100%"
        width="100%"
        children="select"
        labelHidden
        onChange={handleOnAllRowsSelected}
        isIndeterminate={!areAllSelectableRowsSelected && hasSelectedRows}
        isSelected={areAllSelectableRowsSelected && hasSelectableRows}
        isDisabled={!hasSelectableRows}
      />
    </Grid>
  );
};
