import { CellProps, Text, Tag, Color } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

const mappedReimbursementStatuses: Record<
  ReimbursementCaseStatus,
  { color: Color; key: string }
> = {
  APPROVED: { color: 'green', key: 'status.approved' },
  APPROVING: { color: 'yellow', key: 'status.approving' },
  ARCHIVED: { color: 'gray', key: 'status.archived' },
  CHECK: { color: 'red', key: 'status.check' },
  DRAFT: { color: 'gray', key: 'status.draft' },
  EXPORTED: { color: 'blue', key: 'status.exported' },
  REJECTED: { color: 'red', key: 'status.rejected' },
  REVIEW: { color: 'yellow', key: 'status.review' },
  PARTIALLY_EXPORTED: { color: 'yellow', key: 'Partially Exported' },
  EXPORTING: { color: 'blue', key: 'Exporting' },
};

export const ReimbursementStatusCell = ({
  value,
}: CellProps<ReimbursementTableRow, ReimbursementCaseStatus>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  if (!value) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  const statusVariant = mappedReimbursementStatuses[value];

  return (
    <Tag color={statusVariant.color} variant="secondary">
      {t(statusVariant.key)}
    </Tag>
  );
};
