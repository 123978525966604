import { Button, defaultTheme, Flex, Tag, Text } from '@candisio/design-system';
import type {
  ButtonProps,
  FlexProps,
  TagProps,
  TextProps,
} from '@candisio/design-system';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

const AddNewActionBox = (props: PropsWithChildren<FlexProps>) => {
  return (
    <Flex
      direction="column"
      gap="space16"
      padding="space16"
      borderRadius="medium"
      {...props}
    />
  );
};

AddNewActionBox.Content = (props: PropsWithChildren<FlexProps>) => {
  return (
    <Flex direction="column" gap="space8" justifyContent="start" {...props} />
  );
};

AddNewActionBox.Header = (props: PropsWithChildren<FlexProps>) => {
  return (
    <Flex
      direction="row"
      gap="space8"
      justifyContent="start"
      alignItems="center"
      wrap="wrap"
      {...props}
    />
  );
};

AddNewActionBox.HeaderTag = ({
  color = 'blue',
  ...props
}: AddNewActionBoxHeaderTagProps) => {
  return <Tag variant="primary" color={color} callout {...props} />;
};

interface AddNewActionBoxHeaderTagProps
  extends PropsWithChildren<Omit<TagProps, 'color'>> {
  color?: TagProps['color'];
}

AddNewActionBox.HeaderText = (props: PropsWithChildren<TextProps>) => {
  return (
    <Text fontSize="basic" fontWeight="semibold" color="gray800" {...props} />
  );
};

AddNewActionBox.Message = (props: PropsWithChildren<TextProps>) => {
  return (
    <Text
      as="p"
      fontSize="basic"
      fontWeight="normal"
      color="gray700"
      {...props}
    />
  );
};

AddNewActionBox.ButtonLink = (props: PropsWithChildren<ButtonProps<'a'>>) => {
  return (
    <StyledButton
      variant="secondary"
      iconPosition="left"
      icon="plus"
      as="a"
      whileHover={{ color: defaultTheme.colors.gray800 }}
      {...props}
    />
  );
};

AddNewActionBox.Button = (props: PropsWithChildren<ButtonProps<'button'>>) => {
  return (
    <StyledButton
      variant="secondary"
      iconPosition="left"
      icon="plus"
      {...props}
    />
  );
};

export { AddNewActionBox };

const StyledButton = motion(Button);
