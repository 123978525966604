import {
  Flex,
  Grid,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { DocumentTag } from 'components/DocumentTag/DocumentTag';
import { TagFragment } from 'generated-types/graphql.types';
import { isEmpty, isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

type Value = TagFragment[] | undefined | null;

export const TagsCell = <TTableData extends object>({
  value,
}: CellProps<TTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  if (isEmpty(value) || isNil(value)) return t('emptyCellPlaceholder');

  const sortedTags = value.toSorted((a, b) => a.name.localeCompare(b.name));

  const limit = 2;
  const visibleTags = sortedTags.slice(0, limit);
  const tagsForTooltip = sortedTags.slice(limit);

  // cursed: we need negative margin to compensate for tags size
  // cursed: the rows with tags have different height if not provided
  return (
    <Grid style={{ marginBlock: '-2' }}>
      <Flex justifyContent="start" gap="space5">
        {visibleTags.map(tag => (
          <DocumentTag key={tag.id}>
            <TruncatedText fontSize="xsmall">{tag.name}</TruncatedText>
          </DocumentTag>
        ))}
        {tagsForTooltip.length > 0 && (
          <MoreTags count={tagsForTooltip.length}>
            <Flex maxWidth="space256" gap="space8" wrap="wrap">
              {tagsForTooltip.map(tag => (
                <DocumentTag key={tag.id}>{tag.name}</DocumentTag>
              ))}
            </Flex>
          </MoreTags>
        )}
      </Flex>
    </Grid>
  );
};

const MoreTags = ({
  count,
  children,
}: {
  count: number;
  children: ReactNode;
}) => {
  const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
    useTooltip();

  return (
    <>
      <DocumentTag {...triggerProps} ref={triggerRef}>
        +{count}
      </DocumentTag>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {children}
        </Tooltip>
      )}
    </>
  );
};
