export enum DocumentType {
  AccountStatement = 'ACCOUNT_STATEMENT',
  Contract = 'CONTRACT',
  DeliveryNote = 'DELIVERY_NOTE',
  ExpenseReimbursementItem = 'EXPENSE_REIMBURSEMENT_ITEM',
  FeeNotice = 'FEE_NOTICE',
  Guarantee = 'GUARANTEE',
  Invoice = 'INVOICE',
  Offer = 'OFFER',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  Other = 'OTHER',
  PurchaseOrder = 'PURCHASE_ORDER',
  Reminder = 'REMINDER',
  RemittanceAdvise = 'REMITTANCE_ADVISE',
  TravelReimbursementItem = 'TRAVEL_REIMBURSEMENT_ITEM',
}
