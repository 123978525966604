import {
  Button,
  Item,
  TabPanel,
  Tabs,
  useId,
  useTabListState,
} from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';
import { CONTACT_TABS } from 'views/Contacts/consts';
import {
  ContactForm,
  ContactFormProps,
} from 'views/Contacts/ContactDetails/ContactForm';
import { CheckAccountsNumber } from 'views/Contacts/ContactDetails/ContactForm/accountsNumberSchema';
import { CheckContactName } from 'views/Contacts/ContactDetails/ContactForm/contactNameSchema';
import { PaymentConditionItemProps } from 'views/Contacts/ContactDetails/ContactForm/PaymentConditionItem';
import { ContactFormSkeleton } from 'views/Contacts/ContactDetails/ContactFormSkeleton';
import { ContactHistoryContainer } from 'views/Contacts/ContactDetails/ContactHistory/ContactHistoryContainer';
import { PaymentConditionsFormOutput } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';
import { useProcessingFormOverlayContext } from './ProcessingFormOverlay';

export interface AddContactProps {
  checkContactName?: CheckContactName;
  checkAccountsPayableNumber?: CheckAccountsNumber;
  checkAccountsReceivableNumber?: CheckAccountsNumber;
  defaultValues?: ContactFormProps['defaultValues'];
  loading?: boolean;
  onClose?: () => void;
  onCreatePaymentCondition?: (
    values: PaymentConditionsFormOutput
  ) => Promise<string | undefined>;
  onSubmit: ContactFormProps['onSubmit'];
  paymentConditionItems?: PaymentConditionItemProps[];
  submitting?: boolean;
  suggestedAccountsPayableNumber?: string;
}

/**
 * Add contact overlay containing form for creating a contact
 *
 * The layout is a copy-paste of `components/DrawerLayout`
 * */
export const AddContact = ({
  checkContactName,
  checkAccountsPayableNumber,
  checkAccountsReceivableNumber,
  defaultValues,
  loading = false,
  onClose: onCloseProp,
  onCreatePaymentCondition,
  onSubmit,
  paymentConditionItems,
  submitting = false,
  suggestedAccountsPayableNumber,
}: AddContactProps) => {
  const { animating, headingId, onClose } = useProcessingFormOverlayContext();

  const { shouldUseSapContacts } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const [t] = useTranslation();
  const formId = useId();

  const tabsProps = {
    'aria-label': t('settings.contacts.actions.new'),
    items: [
      {
        key: CONTACT_TABS.details,
        title: t('settings.contacts.actions.new'),
        children:
          animating || loading ? (
            <ContactFormSkeleton />
          ) : (
            <ContactForm
              action="create"
              checkContactName={checkContactName}
              checkAccountsPayableNumber={checkAccountsPayableNumber}
              checkAccountsReceivableNumber={checkAccountsReceivableNumber}
              defaultValues={defaultValues}
              id={formId}
              onCreatePaymentCondition={onCreatePaymentCondition}
              onSubmit={onSubmit}
              paymentConditionItems={paymentConditionItems}
              suggestedAccountsPayableNumber={suggestedAccountsPayableNumber}
            />
          ),
      },
      {
        key: CONTACT_TABS.history,
        title: t('common:settings.contacts.details.tabs.history'),
        children: <ContactHistoryContainer />,
      } as const,
    ],
    defaultSelectedKey: CONTACT_TABS.details,
    children: (item: any) => (
      <Item key={item.key} title={item.title} textValue={item.title}>
        {item.children}
      </Item>
    ),
  };

  const tabsState = useTabListState(tabsProps);

  const showSaveButton =
    !shouldUseSapContacts &&
    !shouldUseCoreDataApi &&
    tabsState.selectedItem?.key === CONTACT_TABS.details;

  const handleClose = () => {
    tabsState.setSelectedKey(CONTACT_TABS.details);

    onClose?.();
    onCloseProp?.();
  };

  return (
    <DrawerLayout
      compact
      id="add_contact_sidebar"
      onClose={handleClose}
      hasTabs
      footer={
        showSaveButton && (
          <Button
            type="submit"
            form={formId}
            loading={submitting}
            data-cy="contacts-draw-save-action">
            {t('settings.contacts.details.edit.submit')}
          </Button>
        )
      }
      header={<Tabs key={headingId} {...tabsProps} state={tabsState} />}>
      <TabPanel key={tabsState.selectedItem?.key} state={tabsState} />
    </DrawerLayout>
  );
};
