import React from 'react';
import {
  ColorProps,
  LayoutProps,
  StandardHTMLAttributes,
  TypographyProps,
} from '../../../types';
import { HeadingLevel } from '../Heading';

import { Text } from '../Text';

export interface SectionHeadingProps
  extends LayoutProps,
    ColorProps,
    StandardHTMLAttributes<HTMLHeadingElement> {
  as: HeadingLevel;
}

/**
 * SectionHeading component.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-typography-sectionheading}
 *
 * @param {HeadingLevel} as Level of the heading ['h1' | 'h2' | 'h3']
 */
export const SectionHeading = React.forwardRef<
  HTMLHeadingElement,
  SectionHeadingProps
>(({ children, as, ...restProps }, ref) => {
  const typographyProps: TypographyProps = {
    fontWeight: 'regular',
    fontSize: 'small',
    lineHeight: 'body',
    fontFamily: 'body',
    textTransform: 'uppercase',
  };

  const colorProps: ColorProps = {
    color: 'gray500',
  };

  const textProps: ColorProps & TypographyProps = {
    ...colorProps,
    ...typographyProps,
  };

  return (
    <Text as={as} {...restProps} {...textProps} ref={ref}>
      {children}
    </Text>
  );
});
