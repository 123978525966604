import { queryParameter } from 'components/Table/consts';
import {
  ListApprovalsDocumentsBasicQuery,
  useListApprovalsDocumentsBasicQuery,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import { computePaginationVariables } from '../../queries';
import { getApprovalDocumentsBasicsQuery } from '../queries';

const useApprovalsData = (filter: Record<string, any>) => {
  return useListApprovalsDocumentsBasicQuery({
    variables: {
      filters: {
        ...filter,
      },
    },
  });
};

/**
 * @deprecated
 */
export const useApprovalTabNavigationDataDeprecated = () => {
  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.id || '';
  const { searchParams } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';

  // Compose variables that ensure the user is an Approver for the first document on startWork
  const startWorkVariables = computePaginationVariables();
  startWorkVariables.limit = 1;

  if (currentUser?.id && !startWorkVariables.filters.approverIds) {
    startWorkVariables.filters.approverIds = [currentUser.id];
  }

  const computedFilters = useComputeQueryFilters();

  const approvalDocumentsBasicsQueryResult =
    usePagination<ListApprovalsDocumentsBasicQuery>(
      getApprovalDocumentsBasicsQuery,
      'listApprovalsDocuments',
      {
        variables: { ...computedFilters, query: searchQuery },
      }
    );

  const approvalsRequestedByCurrentUser = useApprovalsData({
    requesterIds: [currentUserId],
  });

  const approvalsAssignedToCurrentUser = useApprovalsData({
    approverIds: [currentUserId],
  });

  const allApprovals = useApprovalsData({});

  const approvalsRequestedByCurrentUserCount =
    approvalsRequestedByCurrentUser.loading ||
    approvalsRequestedByCurrentUser.data?.listApprovalsDocuments.pageInfo
      ?.totalCount === 0
      ? undefined
      : approvalsRequestedByCurrentUser.data?.listApprovalsDocuments.pageInfo?.totalCount?.toString();

  const approvalsAssignedToCurrentUserCount =
    approvalsAssignedToCurrentUser.loading ||
    approvalsAssignedToCurrentUser.data?.listApprovalsDocuments.pageInfo
      ?.totalCount === 0
      ? undefined
      : approvalsAssignedToCurrentUser.data?.listApprovalsDocuments.pageInfo?.totalCount?.toString();

  const allDocumentsCount =
    allApprovals.loading ||
    allApprovals.data?.listApprovalsDocuments.pageInfo?.totalCount === 0
      ? undefined
      : allApprovals.data?.listApprovalsDocuments.pageInfo?.totalCount?.toString();

  return {
    approvalDocumentsBasicsQueryResult,
    approvalsRequestedByCurrentUserCount,
    approvalsAssignedToCurrentUserCount,
    allDocumentsCount,
  };
};
