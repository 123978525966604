import { Text } from '@candisio/design-system';
import { EntitlementsModal } from 'containers/Entitlements/components/Modal/EntitlementsModal';
import { Absence } from 'containers/Entitlements/components/Upsell/components/Absence';
import { Contracts } from 'containers/Entitlements/components/Upsell/components/Contracts';
import { FullTextSearch } from 'containers/Entitlements/components/Upsell/components/FullTextSearch';
import { Provisions } from 'containers/Entitlements/components/Upsell/components/Provisions';
import { SepaXML } from 'containers/Entitlements/components/Upsell/components/SepaXML';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { Children, ReactElement, cloneElement, useState } from 'react';

interface UpsellPromoProps {
  feature: FEATURE;
  children?: ReactElement;
  CustomPromoMaterial?: ReactElement;
  trackMoreInfo?: () => void;
  trackDemo?: () => void;
  hidePackages?: boolean;
  shortTitle?: boolean;
}

export const UpsellPromo = ({
  feature,
  children,
  CustomPromoMaterial,
  trackMoreInfo,
  trackDemo,
  hidePackages = false,
  shortTitle = false,
}: UpsellPromoProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => {
    setIsOpen(true);
    trackMoreInfo?.();
  };

  const featurePromo: Record<FEATURE, ReactElement> = {
    [FEATURE.PROVISIONS]: <Provisions />,
    [FEATURE.ABSENCE_MANAGEMENT]: <Absence />,
    [FEATURE.BDS]: <Text>{FEATURE.BDS}</Text>,
    [FEATURE.CONTRACTS]: <Contracts />,
    [FEATURE.FULL_TEXT_SEARCH]: <FullTextSearch />,
    [FEATURE.INTEGRATION_SAP]: <Text>{FEATURE.INTEGRATION_SAP}</Text>,
    [FEATURE.POSTING_TEXT]: <Text>{FEATURE.POSTING_TEXT}</Text>,
    [FEATURE.PURCHASE_ORDERS]: <Text>{FEATURE.PURCHASE_ORDERS}</Text>,
    [FEATURE.SEPA_XML]: <SepaXML />,
    [FEATURE.DOCUMENT_RELATIONS]: <Text>{FEATURE.DOCUMENT_RELATIONS}</Text>,
    [FEATURE.ECM]: <Text>{FEATURE.ECM}</Text>,
    [FEATURE.REIMBURSEMENT]: <Text>{FEATURE.REIMBURSEMENT}</Text>,
    [FEATURE.DOCUMENT_PREVIEW]: <Text>{FEATURE.DOCUMENT_PREVIEW}</Text>,
    [FEATURE.TAGS]: <Text>{FEATURE.TAGS}</Text>,
  };

  const callToAction = children
    ? Children.only(children)
    : featurePromo[feature];

  return (
    <>
      {cloneElement(callToAction, { onClick: open })}
      <EntitlementsModal
        isOpen={isOpen}
        onClose={close}
        feature={feature}
        CustomPromoMaterial={CustomPromoMaterial}
        hidePackages={hidePackages}
        shortTitle={shortTitle}
        trackDemo={trackDemo}
      />
    </>
  );
};
