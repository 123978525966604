import { Grid } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { noop } from 'lodash';

// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { ExpenseSectionContainer } from './components/MiddleSection/ExpenseSectionContainer';
import { ExpensesFormsContainer } from './components/MiddleSection/ExpensesFormsContainer';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { SectionLayout } from './components/SectionLayout';
import { useFormValidationTrigger } from './hooks/useFormValidationTrigger';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import { MiddleSectionSkeleton } from './MiddleSectionSkeleton';
import { REIMBURSEMENT_URL_PARAM } from './Reimbursement';

const FORM_KEY = 'expenseForms';
interface MiddleSectionContainerProps {
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
  isLoading: boolean;
}

export const MiddleSectionContainer = ({
  formHelpers,
  isLoading,
}: MiddleSectionContainerProps) => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { searchParams } = useMutateSearchParams();
  const isItemsViewActive = searchParams.get(REIMBURSEMENT_URL_PARAM.VIEW);
  const { reimbursementItems, reimbursementItemsCount } =
    useReimbursementExpenseItemsList();

  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });

  const { forms, onRemoveForm, formMethods } = formHelpers;

  useFormValidationTrigger(
    FORM_KEY,
    () => void formMethods.handleSubmit(noop)()
  );

  if (isLoading) {
    return (
      <SectionLayout>
        <MiddleSectionSkeleton isItemsViewActive={!!isItemsViewActive} />
      </SectionLayout>
    );
  }

  const showItemsView = Boolean(isItemsViewActive && forms.length);

  return (
    <SectionLayout>
      <Grid
        height="100%"
        paddingTop="40.5px"
        paddingLeft="space16"
        paddingRight="space8"
        overflowY="auto"
        templateRows={showItemsView ? 'auto' : 'auto 1fr'}>
        {showItemsView ? (
          <ExpensesFormsContainer
            forms={forms}
            onRemoveForm={onRemoveForm}
            formMethods={formMethods}
          />
        ) : (
          <ExpenseSectionContainer
            formHelpers={formHelpers}
            reimbursement={reimbursement}
            expenseCount={reimbursementItemsCount}
            reimbursmentItems={reimbursementItems}
          />
        )}
      </Grid>
    </SectionLayout>
  );
};
