import { useSubmitReimbursementCaseForReviewMutation } from 'generated-types/graphql.types';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';

interface UseSubmitReimbursementCaseForReviewParams {
  reimbursementItemsCount: number;
}

const EXCLUDED_ERROR_TYPE_FIELD = 'totalAmount.amount';

export const useSubmitReimbursementCaseForReview = ({
  reimbursementItemsCount,
}: UseSubmitReimbursementCaseForReviewParams) => {
  const [submitReimbursmentCaseForReview, { loading }] =
    useSubmitReimbursementCaseForReviewMutation();

  return {
    submitReimbursmentCaseForReview: async (reimbursementId: string) => {
      const response = await submitReimbursmentCaseForReview({
        variables: { id: reimbursementId },
        refetchQueries: [
          'reimbursementCasesCount',
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
        ],
      });

      if (response.data?.submitReimbursementCaseForReview.success === true) {
        return {
          status: 'success',
          reimbursementItemsErrors: [],
          hasReimbursementCaseErrors: false,
        };
      }

      const reimbursementItemsErrors = (
        response.data?.submitReimbursementCaseForReview.errors
          .reimbursementItemsErrors ?? []
      ).map(reimbursementItem => ({
        id: reimbursementItem.id,
        errors: reimbursementItem.errors.length,
        title: reimbursementItem.title ?? undefined,
        type: reimbursementItem.type,
      }));

      // XXX: Backend should remove this error on their side
      const reimbursementCaseErrors = (
        response.data?.submitReimbursementCaseForReview.errors
          .reimbursementCaseErrors?.errors ?? []
      ).filter(errorField => errorField.field !== EXCLUDED_ERROR_TYPE_FIELD);

      return {
        status: 'error',
        reimbursementItemsErrors: reimbursementItemsErrors,
        hasNoReimbursements: reimbursementItemsCount === 0,
        hasReimbursementCaseErrors: reimbursementCaseErrors.length > 0,
      };
    },
    isSubmitReimbursmentCaseForReviewPending: loading,
  };
};
