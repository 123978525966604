import {
  Box,
  Button,
  Grid,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { Mentions } from 'antd/es';
import { UserWithAbsence } from 'containers/absence/UserWithAbsence';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  COMMENT_LENGTH_MAX,
  COMMENT_LENGTH_MIN,
  COMMENT_SUBMIT_BUTTON_TEST_ID,
  COMMENT_TEST_ID,
} from './constants';
import { MentionsInputDeprecated } from './styles';
import { CommentProps } from './types';

const { Option } = Mentions;

export const CommentLegacy = ({
  onCreateComment,
  isSubmitting,
  memberships,
  loadingMemberships,
  restrictMentions,
}: CommentProps) => {
  const { isDocumentRelationsAvailable } = useEcm();

  const [t] = useTranslation();
  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>([]);
  const [userInput, setUserInput] = useState<string>('');

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const membershipsFiltered = restrictMentions ? [] : memberships;

  const notFoundContentLabel = t(
    restrictMentions ? 'inputs.notAllowed' : 'inputs.noUserFound'
  );

  const mentionsInputPlaceholder = t(
    restrictMentions
      ? 'comment.mentioningRestrictedPlaceholder'
      : 'comment.placeholder'
  );

  const membershipMap = useMemo(
    () => new Map(memberships.map(m => [m.id, m.name])),
    [memberships]
  );

  return (
    <Form
      onSubmit={async ({ mention }) => {
        await onCreateComment({
          mention,
          mentionedUserIds,
        });

        setMentionedUserIds([]);
      }}>
      {({ handleSubmit, form, values }) => {
        const hasValue = (values.mention ?? '').length > 0;

        const handleFormSubmit = async () => {
          await handleSubmit();
          form.reset();
        };

        const isMac = () => {
          return window.navigator.userAgent.includes('Mac');
        };

        const onKeyDown = async (e: React.KeyboardEvent) => {
          if ((isMac() ? e.metaKey : e.ctrlKey) && e.key === 'Enter') {
            e.preventDefault();
            await handleFormSubmit();
          }
        };

        return (
          <Box position="relative">
            <form
              onKeyDown={e => onKeyDown(e)}
              onSubmit={async e => {
                e.preventDefault();
                await handleFormSubmit();
              }}>
              <Field
                name="mention"
                render={field => (
                  <MentionsInputDeprecated
                    disabled={loadingMemberships}
                    {...field.input}
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    data-show-document-relations={isDocumentRelationsAvailable}
                    placeholder={mentionsInputPlaceholder}
                    // TODO GRM-154 implement validation instead when refactoring
                    maxLength={COMMENT_LENGTH_MAX}
                    minLength={COMMENT_LENGTH_MIN}
                    onChange={e => {
                      field.input.onChange(e);

                      setMentionedUserIds(prevIds =>
                        prevIds.filter(id =>
                          e.includes(`@${membershipMap.get(id) ?? ''}`)
                        )
                      );
                    }}
                    notFoundContent={<span>{notFoundContentLabel}</span>}
                    data-testid={COMMENT_TEST_ID}
                    onSearch={v => setUserInput(v)}
                    onSelect={({ key }) => {
                      if (key) {
                        setMentionedUserIds(prevIds => [...prevIds, key]);
                      }
                    }}
                    placement="top">
                    {membershipsFiltered
                      .filter(u =>
                        u.name.toLowerCase().includes(userInput.toLowerCase())
                      )
                      .map(({ name, id, avatarUrl }) => (
                        <Option key={id} value={name}>
                          <UserWithAbsence
                            label={name}
                            value={id}
                            image={avatarUrl}
                          />
                        </Option>
                      ))}
                  </MentionsInputDeprecated>
                )}
              />
              <Grid
                autoFlow="column"
                gap="space4"
                position="absolute"
                bottom="space4"
                right="space8">
                {hasValue && (
                  <Button
                    onClick={() => form.reset()}
                    icon="close"
                    size="small"
                    label={t('comment.clear')}
                    tabIndex={-1}
                    variant="tertiary"
                  />
                )}
                <Button
                  type="submit"
                  data-testid={COMMENT_SUBMIT_BUTTON_TEST_ID}
                  icon={isSubmitting ? 'sandclock' : 'send'}
                  disabled={isSubmitting || !hasValue}
                  size="small"
                  variant="tertiary"
                  {...triggerProps}
                  ref={triggerRef}
                />
                {isOpen && (
                  <Tooltip {...tooltipProps} ref={tooltipRef}>
                    {isSubmitting ? (
                      <Text color="gray800" fontSize="basic">
                        {t('comment.creation.submittingTitle')}
                      </Text>
                    ) : (
                      <Grid>
                        <Text color="gray800" fontSize="basic">
                          {t('comment.creation.title')}
                        </Text>
                        <Text color="gray500" fontSize="small">
                          {t(
                            isMac()
                              ? 'comment.creation.shortcutInfoMac'
                              : 'comment.creation.shortcutInfo'
                          )}
                        </Text>{' '}
                      </Grid>
                    )}
                  </Tooltip>
                )}
              </Grid>
            </form>
          </Box>
        );
      }}
    </Form>
  );
};
