import { Box, Theme, useTheme } from '@candisio/design-system';
import {
  AnimatePresence,
  DragHandlers,
  motion,
  useMotionValue,
} from 'framer-motion';
import { clamp, debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import { prefix } from 'utils/localStorage';
import { DocumentPreviewActivationPopover } from './DocumentPreviewActivationPopover';
import {
  DocumentPreviewContainer,
  DocumentPreviewContainerProps,
} from './DocumentPreviewContainer';
import { useDocumentPreviewActivationPopover } from './useDocumentPreviewActivationPopover';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { DocumentPreviewEvents } from 'providers/AnalyticsProvider/events/DocumentPreviewEvents';

const MotionBox = motion(Box);

const DragHandleWrapperBox = styled(MotionBox)<{ ['data-theme']: Theme }>`
  .drag-handle {
    width: 1px;
  }

  &:hover {
    .drag-handle {
      width: ${({ 'data-theme': theme }) => theme.space.space2};
      background-color: ${({ 'data-theme': theme }) => theme.colors.blue100};
    }
  }
`;

const vwToPx = (vw: number) => (vw / 100) * window.innerWidth;

const DEFAULT_DRAWER_WIDTH = Math.min(vwToPx(30), 464);
const MIN_DRAWER_WIDTH = Math.min(vwToPx(25), 440);
const MAX_DRAWER_WIDTH = Math.max(vwToPx(45), 750);

interface DocumentPreviewDrawerProps {
  documentId: string | null;
  openDocument: () => void;
  closePreviewDrawer: () => void;
  documentPreviewView?: (props: DocumentPreviewContainerProps) => JSX.Element;
}

interface DocumentPreviewDrawerContentProps
  extends Omit<DocumentPreviewDrawerProps, 'documentId'> {
  documentId: string;
}

export const DocumentPreviewDrawer = ({
  documentId,
  closePreviewDrawer,
  openDocument,
  documentPreviewView,
}: DocumentPreviewDrawerProps) => (
  <AnimatePresence>
    {documentId && (
      <DocumentPreviewDrawerContent
        openDocument={openDocument}
        closePreviewDrawer={closePreviewDrawer}
        documentId={documentId}
        documentPreviewView={documentPreviewView}
      />
    )}
  </AnimatePresence>
);

const DOCUMENT_PREVIEW_WIDTH_KEY = `${prefix}-document-preview-width`;

export const DocumentPreviewDrawerContent = ({
  closePreviewDrawer,
  openDocument,
  documentId,
  documentPreviewView: DocumentPreviewView = DocumentPreviewContainer,
}: DocumentPreviewDrawerContentProps) => {
  const { track } = useAnalytics();
  useEffect(() => {
    track(DocumentPreviewEvents.DOCUMENT_PREVIEW_OPENED, { documentId });
  }, [documentId, track]);

  const theme = useTheme();

  const { targetProps, targetRef, isOpen, popoverProps, popoverRef, close } =
    useDocumentPreviewActivationPopover();

  const initialWidth =
    localStorage.getItem(DOCUMENT_PREVIEW_WIDTH_KEY) ?? DEFAULT_DRAWER_WIDTH;

  const motionWidth = useMotionValue(Number(initialWidth));

  const [animationFinished, setAnimationFinished] = useState(false);
  const updateAnimationFinished = useMemo(
    () => debounce(setAnimationFinished, 300),
    []
  );

  const changeWidth: DragHandlers['onDrag'] = (_event, info) => {
    if (isOpen) return;

    let width = clamp(
      motionWidth.get() - Math.floor(info.delta.x),
      MIN_DRAWER_WIDTH,
      MAX_DRAWER_WIDTH
    );

    motionWidth.set(width);
    localStorage.setItem(DOCUMENT_PREVIEW_WIDTH_KEY, width + '');
  };

  const resetWidth = () => {
    if (isOpen) return;

    motionWidth.set(DEFAULT_DRAWER_WIDTH);
    localStorage.setItem(DOCUMENT_PREVIEW_WIDTH_KEY, DEFAULT_DRAWER_WIDTH + '');
  };

  return (
    <MotionBox
      onAnimationStart={() => {
        updateAnimationFinished(false);
      }}
      onAnimationComplete={() => {
        updateAnimationFinished(true);
      }}
      key="previewWrapper"
      id="previewWrapper"
      initial="closed"
      animate="open"
      exit="closed"
      variants={{
        open: { width: `${initialWidth}px` },
        closed: { width: 0 },
      }}
      transition={{ ease: 'easeOut' }}
      height="100%"
      maxWidth={MAX_DRAWER_WIDTH}
      minWidth="0"
      minHeight="0"
      style={{ width: motionWidth }}>
      <DragHandleWrapperBox
        drag="x"
        onDrag={changeWidth}
        onDoubleClick={resetWidth}
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        dragSnapToOrigin
        dragElastic="0"
        dragMomentum={false}
        position="absolute"
        zIndex="10"
        height="100%"
        paddingX="space10"
        left="-space10"
        cursor="col-resize"
        data-theme={theme}>
        <Box
          ref={targetRef}
          {...targetProps}
          className="drag-handle"
          height="100%"
          background="gray250"
        />
      </DragHandleWrapperBox>
      <DocumentPreviewView
        documentId={documentId}
        onOpen={openDocument}
        onClose={closePreviewDrawer}
        showPdf={animationFinished}
      />

      {isOpen && (
        <DocumentPreviewActivationPopover
          onClose={close}
          popoverProps={popoverProps}
          popoverRef={popoverRef}
        />
      )}
    </MotionBox>
  );
};
