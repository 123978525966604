// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';

enum PARAMS {
  PAGE = 'page',
}

export const getCurrentPage = () => {
  const params = qs.parse(window.location.search);
  const page = params[PARAMS.PAGE] as string;

  return (page && parseInt(page)) || 1;
};
