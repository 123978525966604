import { Theme } from '../types';
import {
  breakpoints,
  colors,
  focusStyle,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  space,
  timingFunctions,
} from './themeValues';

export const defaultTheme: Theme = {
  name: 'default',
  breakpoints,
  colors,
  focusStyle,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  space,
  timingFunctions,

  avatar: {
    focus: {
      boxShadow: `0 0 0 1px ${colors.gray0}`,
    },
  },

  badge: {
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xsmall,
    borderRadius: radii.full,
    gap: space.space4,
    padding: `0 ${space.space4}`,
    height: space.space16,
    minWidth: space.space16,
    backgroundColor: {
      gray: colors.gray300,
      green: colors.green500,
      red: colors.red500,
      yellow: colors.yellow500,
      blue: colors.blue500,
      darkGray: colors.gray800,
      purple: colors.purple100,
    },
    color: {
      gray: colors.gray500,
      green: colors.gray0,
      red: colors.gray0,
      yellow: colors.gray0,
      blue: colors.gray0,
      darkGray: colors.gray0,
      purple: colors.purple600,
    },
    border: {
      gray: colors.gray300,
      green: colors.green500,
      red: colors.red500,
      yellow: colors.yellow500,
      blue: colors.blue500,
      darkGray: colors.gray800,
      purple: colors.purple100,
    },
  },

  button: {
    primary: {
      default: {
        default: {
          background: colors.gray800,
          color: colors.gray0,
        },
        hovered: {
          background: colors.gray700,
        },
        pressed: {
          background: colors.gray600,
        },
        focused: {
          background: colors.gray500,
          ...focusStyle,
        },
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.gray800,
        },
      },
      gray: {
        default: {
          background: colors.gray800,
          color: colors.gray0,
        },
        hovered: {
          background: colors.gray700,
        },
        pressed: {
          background: colors.gray600,
        },
        focused: {
          background: colors.gray500,
          ...focusStyle,
        },
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.gray800,
        },
      },
      warning: {
        default: {
          background: colors.yellow500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.yellow600,
        },
        pressed: {
          background: colors.yellow700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.yellow700,
        },
      },
      yellow: {
        default: {
          background: colors.yellow500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.yellow600,
        },
        pressed: {
          background: colors.yellow700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.yellow700,
        },
      },
      error: {
        default: {
          background: colors.red500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.red600,
        },
        pressed: {
          background: colors.red700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.red700,
        },
      },
      red: {
        default: {
          background: colors.red500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.red600,
        },
        pressed: {
          background: colors.red700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.red700,
        },
      },
      success: {
        default: {
          background: colors.green500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.green600,
        },
        pressed: {
          background: colors.green700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.green700,
        },
      },
      green: {
        default: {
          background: colors.green500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.green600,
        },
        pressed: {
          background: colors.green700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.green700,
        },
      },
      info: {
        default: {
          background: colors.gray0,
          color: colors.blue500,
        },
        hovered: {
          background: colors.gray200,
        },
        pressed: {
          background: colors.blue300,
          color: colors.gray0,
        },
        focused: focusStyle,
        disabled: {
          background: colors.gray300,
          color: colors.gray200,
        },
        loading: {
          background: colors.blue400,
          color: colors.gray0,
        },
      },
      whiteBlue: {
        default: {
          background: colors.gray0,
          color: colors.blue500,
        },
        hovered: {
          background: colors.gray200,
        },
        pressed: {
          background: colors.blue300,
          color: colors.gray0,
        },
        focused: focusStyle,
        disabled: {
          background: colors.gray300,
          color: colors.gray200,
        },
        loading: {
          background: colors.blue400,
          color: colors.gray0,
        },
      },
      util: {
        default: {
          background: colors.blue500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.blue600,
        },
        pressed: {
          background: colors.blue700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.blue700,
        },
      },
      blue: {
        default: {
          background: colors.blue500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.blue600,
        },
        pressed: {
          background: colors.blue700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.blue700,
        },
      },
      promo: {
        default: {
          background: colors.purple500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.purple600,
        },
        pressed: {
          background: colors.purple700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.purple700,
        },
      },
      purple: {
        default: {
          background: colors.purple500,
          color: colors.gray0,
        },
        hovered: {
          background: colors.purple600,
        },
        pressed: {
          background: colors.purple700,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.purple700,
        },
      },
    },
    secondary: {
      default: {
        default: {
          background: colors.gray250,
          color: colors.gray800,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.gray300,
        },
        pressed: {
          background: colors.gray400,
        },
        focused: {
          boxShadow: `0 0 0 3px ${colors.blue200}`,
        },
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.gray400,
        },
      },
      gray: {
        default: {
          background: colors.gray250,
          color: colors.gray800,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.gray300,
        },
        pressed: {
          background: colors.gray400,
        },
        focused: {
          boxShadow: `0 0 0 3px ${colors.blue200}`,
        },
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.gray400,
        },
      },
      warning: {
        default: {
          background: colors.yellowbg,
          color: colors.yellow700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.yellow200,
        },
        pressed: {
          background: colors.yellow300,
        },
        focused: {
          background: colors.yellow300,
          ...focusStyle,
        },
        disabled: {
          background: colors.gray100,
          opacity: 0.3,
        },
        loading: {
          background: colors.yellow300,
          color: colors.yellow700,
        },
      },
      yellow: {
        default: {
          background: colors.yellowbg,
          color: colors.yellow700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.yellow200,
        },
        pressed: {
          background: colors.yellow300,
        },
        focused: {
          background: colors.yellow300,
          ...focusStyle,
        },
        disabled: {
          background: colors.gray100,
          opacity: 0.3,
        },
        loading: {
          background: colors.yellow300,
          color: colors.yellow700,
        },
      },
      error: {
        default: {
          background: colors.redbg,
          color: colors.red700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.red200,
        },
        pressed: {
          background: colors.red300,
        },
        focused: {
          background: colors.red300,
          ...focusStyle,
        },
        disabled: {
          background: colors.red100,
          opacity: 0.3,
        },
        loading: {
          background: colors.red300,
        },
      },
      red: {
        default: {
          background: colors.redbg,
          color: colors.red700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.red200,
        },
        pressed: {
          background: colors.red300,
        },
        focused: {
          background: colors.red300,
          ...focusStyle,
        },
        disabled: {
          background: colors.red100,
          opacity: 0.3,
        },
        loading: {
          background: colors.red300,
        },
      },
      success: {
        default: {
          background: colors.greenbg,
          color: colors.green700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.green200,
        },
        pressed: {
          background: colors.green300,
        },
        focused: {
          background: colors.green300,
          ...focusStyle,
        },
        disabled: {
          background: colors.green100,
          opacity: 0.3,
        },
        loading: {
          background: colors.green300,
        },
      },
      green: {
        default: {
          background: colors.greenbg,
          color: colors.green700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.green200,
        },
        pressed: {
          background: colors.green300,
        },
        focused: {
          background: colors.green300,
          ...focusStyle,
        },
        disabled: {
          background: colors.green100,
          opacity: 0.3,
        },
        loading: {
          background: colors.green300,
        },
      },
      info: {
        default: {
          background: 'transparent',
          border: `1px solid ${colors.gray0}`,
          color: colors.gray0,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.gray0,
          border: `1px solid ${colors.blue500}`,
          color: colors.blue500,
        },
        pressed: {
          background: colors.blue300,
          color: colors.gray0,
        },
        focused: focusStyle,
        disabled: {
          background: 'transparent',
          border: `1px solid ${colors.gray500}`,
          color: colors.gray500,
        },
        loading: {
          color: colors.gray700,
        },
      },
      whiteBlue: {
        default: {
          background: 'transparent',
          border: `1px solid ${colors.gray0}`,
          color: colors.gray0,
        },
        hovered: {
          background: colors.gray0,
          border: `1px solid ${colors.blue500}`,
          color: colors.blue500,
        },
        pressed: {
          background: colors.blue300,
          color: colors.gray0,
        },
        focused: focusStyle,
        disabled: {
          background: 'transparent',
          border: `1px solid ${colors.gray500}`,
          color: colors.gray500,
        },
        loading: {
          color: colors.gray700,
        },
      },
      util: {
        default: {
          background: colors.bluebg,
          color: colors.blue600,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.blue200,
        },
        pressed: {
          background: colors.blue300,
        },
        focused: {
          background: colors.blue300,
          ...focusStyle,
        },
        disabled: {
          background: colors.blue100,
          opacity: 0.3,
        },
        loading: {
          background: colors.blue300,
        },
      },
      blue: {
        default: {
          background: colors.bluebg,
          color: colors.blue600,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.blue200,
        },
        pressed: {
          background: colors.blue300,
        },
        focused: {
          background: colors.blue300,
          ...focusStyle,
        },
        disabled: {
          background: colors.blue100,
          opacity: 0.3,
        },
        loading: {
          background: colors.blue300,
        },
      },
      promo: {
        default: {
          background: colors.purplebg,
          color: colors.purple600,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.purple200,
        },
        pressed: {
          background: colors.purple300,
        },
        focused: {
          background: colors.purple300,
          ...focusStyle,
        },
        disabled: {
          background: colors.purple100,
          opacity: 0.3,
        },
        loading: {
          background: colors.purple300,
        },
      },
      purple: {
        default: {
          background: colors.purplebg,
          color: colors.purple600,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.purple200,
        },
        pressed: {
          background: colors.purple300,
        },
        focused: {
          background: colors.purple300,
          ...focusStyle,
        },
        disabled: {
          background: colors.purple100,
          opacity: 0.3,
        },
        loading: {
          background: colors.purple300,
        },
      },
    },
    tertiary: {
      default: {
        default: {
          background: 'transparent',
          color: colors.gray800,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.gray250,
        },
        pressed: {
          background: colors.gray300,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.gray300,
        },
      },
      gray: {
        default: {
          background: 'transparent',
          color: colors.gray800,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.gray250,
        },
        pressed: {
          background: colors.gray300,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.gray300,
        },
      },
      warning: {
        default: {
          background: 'transparent',
          color: colors.yellow700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.yellow100,
        },
        pressed: {
          background: colors.yellow200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.yellow200,
          color: colors.yellow700,
        },
      },
      yellow: {
        default: {
          background: 'transparent',
          color: colors.yellow700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.yellow100,
        },
        pressed: {
          background: colors.yellow200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.yellow200,
          color: colors.yellow700,
        },
      },
      error: {
        default: {
          background: 'transparent',
          color: colors.red700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.red100,
        },
        pressed: {
          background: colors.red200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.red200,
        },
      },
      red: {
        default: {
          background: 'transparent',
          color: colors.red700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.red100,
        },
        pressed: {
          background: colors.red200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.red200,
        },
      },
      success: {
        default: {
          background: 'transparent',
          color: colors.green700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.green100,
        },
        pressed: {
          background: colors.green200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.green200,
        },
      },
      green: {
        default: {
          background: 'transparent',
          color: colors.green700,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.green100,
        },
        pressed: {
          background: colors.green200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.green200,
        },
      },
      info: {
        default: {
          background: 'transparent',
          color: colors.gray0,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.blue300,
        },
        pressed: {
          background: colors.blue300,
          color: colors.blue600,
        },
        focused: focusStyle,
        disabled: {
          color: colors.gray500,
        },
        loading: {
          color: colors.gray300,
        },
      },
      whiteBlue: {
        default: {
          background: 'transparent',
          color: colors.gray0,
        },
        hovered: {
          background: colors.blue300,
        },
        pressed: {
          background: colors.blue300,
          color: colors.blue600,
        },
        focused: focusStyle,
        disabled: {
          color: colors.gray500,
        },
        loading: {
          color: colors.gray300,
        },
      },
      util: {
        default: {
          background: 'transparent',
          color: colors.blue500,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.blue100,
        },
        pressed: {
          background: colors.blue200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.blue200,
        },
      },
      blue: {
        default: {
          background: 'transparent',
          color: colors.blue500,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.blue100,
        },
        pressed: {
          background: colors.blue200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.blue200,
        },
      },
      promo: {
        default: {
          background: 'transparent',
          color: colors.purple500,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.purple100,
        },
        pressed: {
          background: colors.purple200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.purple200,
        },
      },
      purple: {
        default: {
          background: 'transparent',
          color: colors.purple500,
          mixBlendMode: 'multiply',
        },
        hovered: {
          background: colors.purple100,
        },
        pressed: {
          background: colors.purple200,
        },
        focused: focusStyle,
        disabled: {
          opacity: 0.3,
        },
        loading: {
          background: colors.purple200,
        },
      },
    },
    xsmall: {
      default: {
        padding: `${space.space4} ${space.space8}`,
        fontSize: space.space10,
        borderRadius: radii.full,
      },
      iconOnly: {
        padding: space.space4,
        borderRadius: radii.full,
      },
      iconPosition: {
        left: {
          paddingLeft: space.space4,
        },
        right: {
          paddingRight: space.space4,
        },
      },
      iconSize: space.space16,
      spinnerSize: space.space16,
    },
    small: {
      default: {
        padding: `${space.space8} ${space.space12}`,
        fontSize: space.space12,
        borderRadius: radii.full,
      },
      iconOnly: {
        padding: space.space8,
        borderRadius: radii.full,
      },
      iconPosition: {
        left: {
          paddingLeft: space.space8,
        },
        right: {
          paddingRight: space.space8,
        },
      },
      iconSize: space.space16,
      spinnerSize: space.space16,
    },
    medium: {
      default: {
        padding: `${space.space8} ${space.space16}`,
        fontSize: space.space14,
        borderRadius: radii.full,
      },
      iconOnly: {
        padding: space.space8,
        borderRadius: radii.full,
      },
      iconPosition: {
        left: {
          paddingLeft: space.space8,
        },
        right: {
          paddingRight: space.space8,
        },
      },
      iconSize: space.space20,
      spinnerSize: space.space20,
    },
    hasOpacity: {
      opacity: 0.4,
      mixBlendMode: 'multiply',
    },
  },
  messageBox: {
    warning: {
      background: colors.yellowbg,
      borderColor: colors.yellow200,
    },
    info: {
      background: colors.bluebg,
      borderColor: colors.blue500,
    },
    error: {
      background: colors.redbg,
      borderColor: colors.red500,
    },
    success: {
      background: colors.greenbg,
      borderColor: colors.green500,
    },
  },

  card: {
    default: {
      background: colors.gray0,
      color: colors.gray800,
    },
    info: {
      background: colors.blue500,
      color: colors.gray0,
    },
    warning: {
      background: colors.yellow500,
      color: colors.gray0,
    },
  },

  checkbox: {
    box: {
      borderRadius: radii.xsmall,
      height: space.space16,
      width: space.space16,
    },

    label: {
      color: colors.gray800,
      gap: space.space8,
      fontSize: fontSizes.basic,
      lineHeight: space.space20,
    },

    default: {
      label: {
        default: {
          cursor: 'pointer',
          color: 'inherit',
          ':hover .checkbox': {
            borderColor: colors.blue500,
          },
        },
      },
      checkbox: {
        default: {
          cursor: 'pointer',
          border: `1px solid ${colors.gray500}`,
          backgroundColor: colors.gray0,
          svg: {
            visibility: 'hidden',
            opacity: 0,
          },
        },
        indeterminate: {
          border: 'unset',
          backgroundColor: colors.blue500,
          svg: {
            visibility: 'visible',
            opacity: 1,
          },
        },
        selected: {
          border: 'unset',
          backgroundColor: colors.blue500,
          svg: {
            visibility: 'visible',
            opacity: 1,
          },
        },
        focused: {
          boxShadow: `0 0 0 3px ${colors.blue400}`,
        },
        ':hover': {
          border: `1px solid ${colors.blue500}`,
        },
      },
    },

    readonly: {
      label: {
        default: {
          cursor: 'default',
          color: 'inherit',
        },
      },
      checkbox: {
        default: {
          cursor: 'default',
          border: `1px solid ${colors.gray500}`,
          backgroundColor: colors.gray200,
          svg: {
            visibility: 'hidden',
            opacity: 0,
          },
        },
        indeterminate: {
          border: 'unset',
          backgroundColor: colors.gray500,
          svg: {
            visibility: 'visible',
            opacity: 1,
          },
        },
        selected: {
          border: 'unset',
          backgroundColor: colors.gray500,
          svg: {
            visibility: 'visible',
            opacity: 1,
          },
        },
        focused: {
          boxShadow: `0 0 0 3px ${colors.blue400}`,
        },
      },
    },

    disabled: {
      label: {
        default: {
          cursor: 'not-allowed',
          color: colors.gray400,
        },
      },
      checkbox: {
        default: {
          cursor: 'not-allowed',
          border: `1px solid ${colors.gray300}`,
          backgroundColor: colors.gray300,
          svg: {
            visibility: 'hidden',
            opacity: 0,
          },
        },
        indeterminate: {
          border: 'unset',
          backgroundColor: colors.blue400,
          svg: {
            visibility: 'visible',
            opacity: 1,
          },
        },
        selected: {
          border: 'unset',
          backgroundColor: colors.blue400,
          svg: {
            visibility: 'visible',
            opacity: 1,
          },
        },
        focused: {
          boxShadow: `0 0 0 3px ${colors.blue300}`,
          border: 'unset',
        },
      },
    },
  },

  chip: {
    paddings: {
      xsmall: {
        paddingLeft: space.space8,
        paddingRight: space.space4,
        paddingLeftWithAvatar: space.space4,
        paddingRightBasic: space.space12,
        paddingRightWhenDisabled: space.space8,
      },

      small: {
        paddingLeft: space.space12,
        paddingRight: space.space4,
        paddingRightBasic: space.space12,
        paddingLeftWithAvatar: space.space4,
        paddingRightWhenDisabled: space.space12,
      },
    },

    xsmall: {
      height: space.space24,
      fontSize: fontSizes.xsmall,
      icon: {
        width: space.space16,
        height: space.space16,
        padding: space.space2,
      },
    },

    small: {
      height: space.space32,
      fontSize: fontSizes.small,
      icon: {
        width: space.space24,
        height: space.space24,
        padding: space.space4,
      },
    },

    blue: {
      default: {
        background: colors.blue100,
        color: colors.blue700,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.blue700,
            },
            avatar: {
              background: colors.gray0,
              color: colors.blue700,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.blue700,
            },
            avatar: {
              background: colors.blue100,
              color: colors.blue700,
            },
          },
        },
      },

      hovered: {
        background: colors.blue200,
        color: colors.blue700,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.blue700,
            },
            avatar: {
              background: colors.gray0,
              color: colors.blue700,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.blue700,
            },
            avatar: {
              background: colors.blue200,
              color: colors.blue700,
            },
          },
        },
      },

      pressed: {
        background: colors.blue400,
        color: colors.blue600,
        icon: {
          xsmall: {
            default: {
              background: colors.blue300,
              color: colors.blue600,
            },
            avatar: {
              background: colors.blue300,
              color: colors.blue600,
            },
          },
          small: {
            default: {
              background: colors.blue300,
              color: colors.blue600,
            },
            avatar: {
              background: colors.blue300,
              color: colors.blue600,
            },
          },
        },
      },

      focused: {
        ...focusStyle,
        background: colors.blue300,
        color: colors.blue500,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.blue500,
            },
            avatar: {
              background: colors.gray0,
              color: colors.blue500,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.blue500,
            },
            avatar: {
              background: colors.blue300,
              color: colors.blue500,
            },
          },
        },
      },

      selectedHovered: {
        background: colors.blue300,
        color: colors.blue500,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.blue500,
            },
            avatar: {
              background: colors.gray0,
              color: colors.blue500,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.blue500,
            },
            avatar: {
              background: colors.blue300,
              color: colors.blue600,
            },
          },
        },
      },

      selected: {
        background: colors.blue500,
        color: colors.gray0,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.blue700,
            },
            avatar: {
              background: colors.gray0,
              color: colors.blue700,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.blue700,
            },
            avatar: {
              background: colors.gray0,
              color: colors.blue700,
            },
          },
        },
      },
    },
    lightGray: {
      default: {
        background: colors.gray50,
        color: colors.gray800,
        border: `1px solid ${colors.gray250}`,
        icon: undefined,
      },
      selectedHovered: undefined,
      selected: undefined,
      hovered: undefined,
      pressed: undefined,
      focused: undefined,
    },
    darkGray: {
      default: {
        background: colors.gray300,
        color: colors.gray700,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.gray700,
            },
            avatar: {
              background: colors.gray0,
              color: colors.gray700,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.gray700,
            },
            avatar: {
              background: colors.gray300,
              color: colors.gray700,
            },
          },
        },
      },

      selectedHovered: {
        background: colors.gray300,
        color: colors.gray800,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray0,
              color: colors.gray800,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray300,
              color: colors.gray800,
            },
          },
        },
      },

      selected: {
        background: colors.gray500,
        color: colors.gray800,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray0,
              color: colors.gray800,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray200,
              color: colors.gray800,
            },
          },
        },
      },

      hovered: {
        background: colors.gray300,
        color: colors.gray800,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray0,
              color: colors.gray800,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray200,
              color: colors.gray800,
            },
          },
        },
      },

      pressed: {
        background: colors.gray300,
        color: colors.gray800,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray0,
              color: colors.gray800,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray200,
              color: colors.gray800,
            },
          },
        },
      },

      focused: {
        ...focusStyle,
        background: colors.gray300,
        color: colors.gray700,
        icon: {
          xsmall: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray0,
              color: colors.gray800,
            },
          },
          small: {
            default: {
              background: colors.gray0,
              color: colors.gray800,
            },
            avatar: {
              background: colors.gray300,
              color: colors.gray800,
            },
          },
        },
      },
    },

    disabled: {
      background: colors.gray200,
      color: colors.gray700,
      cursor: 'not-allowed',
    },
  },

  fieldContainer: {
    label: {
      default: {
        color: colors.gray800,
        fontSize: fontSizes.small,
      },
      readOnly: {
        cursor: 'default',
      },
      disabled: {
        color: colors.gray500,
        cursor: 'not-allowed',
      },
      isLoading: {
        color: 'transparent',
      },
    },
    color: {
      default: { background: colors.gray250, borderColor: colors.gray250 },
      white: { background: colors.gray0, borderColor: colors.gray250 },
    },
    background: {
      warning: {
        default: {
          background: colors.gray0,
        },
        hovered: {
          background: colors.gray50,
          borderColor: colors.gray300,
        },
        focused: {
          background: colors.gray0,
          borderColor: colors.blue300,
          outline: `1px solid ${colors.blue300}`,
        },
      },
      error: {
        default: {
          background: colors.redbg,
        },
        hovered: {
          background: colors.redbg,
          borderColor: colors.red300,
        },
        focused: {
          background: colors.redbg,
          borderColor: colors.red300,
          outline: `1px solid ${colors.red300}`,
        },
      },
      white: {
        default: {
          background: colors.gray100,
        },
        hovered: {
          background: colors.gray200,
          borderColor: colors.gray300,
        },
        focused: {
          background: colors.gray100,
          borderColor: colors.gray300,
          outline: `1px solid ${colors.gray300}`,
        },
      },
      default: {
        default: {
          background: colors.gray0,
        },
        hovered: {
          background: colors.gray50,
          borderColor: colors.gray300,
        },
        focused: {
          background: colors.gray0,
          borderColor: colors.blue500,
          outline: `1px solid ${colors.blue500}`,
        },
      },
    },
    radii: {
      default: radii.medium,
      rounded: radii.full,
    },
    default: {
      background: colors.gray0,

      cornerColor: {
        borderColor: `transparent ${colors.gray500} transparent transparent`,
      },
    },
    error: {
      background: colors.gray0,
      borderLeft: `${space.space4} solid ${colors.red500}`,
      paddingLeft: space.space8,

      cornerColor: {
        borderColor: `transparent ${colors.red500} transparent transparent`,
      },
    },
    warning: {
      background: colors.yellow200,

      cornerColor: {
        borderColor: `transparent ${colors.yellow500} transparent transparent`,
      },
    },
    success: {
      background: colors.green200,

      cornerColor: {
        borderColor: `transparent ${colors.green500} transparent transparent`,
      },
    },

    readOnly: {
      container: {
        cursor: 'default',
        background: 'transparent',
      },
      input: {
        cursor: 'default',
      },
    },
    disabled: {
      container: {
        background: colors.gray0,
        cursor: 'not-allowed',
        color: colors.gray500,
      },
      input: {
        color: colors.gray500,
        cursor: 'not-allowed',
      },
    },
    description: {
      error: { color: colors.red500 },
      warning: { color: colors.yellow700 },
    },
  },

  infoPanel: {
    error: {
      backgroundColor: 'redbg',
      color: 'gray800',
      icon: {
        color: 'red700',
        type: 'failCircle',
      },
    },
    information: {
      backgroundColor: 'bluebg',
      color: 'gray800',
      icon: {
        color: 'blue700',
        type: 'infoCircle',
      },
    },
    warning: {
      backgroundColor: 'yellowbg',
      color: 'gray800',
      icon: {
        color: 'yellow700',
        type: 'warning',
      },
    },
    promo: {
      backgroundColor: colors.purplebg,
      color: colors.gray800,
      icon: {
        color: colors.purple500,
        type: 'infoCircle',
      },
    },
  },

  link: {
    primary: {
      blue: {
        color: colors.blue500,
        '&:hover': {
          color: colors.blue600,
        },
        '&:active': {
          color: colors.blue700,
        },
      },

      gray: {
        color: colors.gray800,
        '&:hover': {
          color: colors.gray700,
        },
        '&:active': {
          color: colors.gray600,
        },
      },

      purple: {
        color: colors.purple500,
        '&:hover': {
          color: colors.purple600,
        },
        '&:active': {
          color: colors.purple700,
        },
      },

      yellow: {
        color: colors.yellow600,
        '&:hover': {
          color: colors.yellow700,
        },
        '&:active': {
          color: colors.yellow700,
        },
      },

      red: {
        color: colors.red500,
        '&:hover': {
          color: colors.red600,
        },
        '&:active': {
          color: colors.red700,
        },
      },

      green: {
        color: colors.green600,
        '&:hover': {
          color: colors.green700,
        },
        '&:active': {
          color: colors.green700,
        },
      },

      disabled: {
        color: colors.gray500,
        cursor: 'not-allowed',

        '&:hover': {
          color: colors.gray500,
        },
        '&:active': {
          color: colors.gray500,
        },
      },
    },

    secondary: {
      gray: {
        color: colors.gray0,
        '&:hover': {
          color: colors.blue400,
        },
      },

      blue: {
        color: colors.blue100,
        '&:hover': {
          color: colors.blue200,
        },
        '&:active': {
          color: colors.blue300,
        },
      },

      purple: {
        color: colors.purple100,
        '&:hover': {
          color: colors.purple200,
        },
        '&:active': {
          color: colors.purple300,
        },
      },

      yellow: {
        color: colors.yellow100,
        '&:hover': {
          color: colors.yellow200,
        },
        '&:active': {
          color: colors.yellow300,
        },
      },

      red: {
        color: colors.red100,
        '&:hover': {
          color: colors.red200,
        },
        '&:active': {
          color: colors.red300,
        },
      },

      green: {
        color: colors.green100,
        '&:hover': {
          color: colors.green200,
        },
        '&:active': {
          color: colors.green300,
        },
      },

      disabled: {
        color: colors.gray500,
        opacity: 0.5,
        cursor: 'not-allowed',

        '&:hover': {
          color: colors.gray500,
        },
        '&:active': {
          color: colors.gray500,
        },
      },
    },
  },

  menuListItem: {
    default: {
      color: colors.gray800,
      fontWeight: fontWeights.regular,
      background: 'transparent',
      cursor: 'pointer',
    },

    selected: {
      fontWeight: fontWeights.semibold,
    },

    focused: {
      background: colors.bluebg,
    },

    disabled: {
      color: colors.gray500,
      cursor: 'not-allowed',
    },
  },

  option: {
    default: {
      background: 'transparent',
    },

    active: {
      background: colors.bluebg,
    },
  },

  radio: {
    default: {
      radioButton: {
        fill: colors.gray0,
        cursor: 'pointer',
        stroke: colors.gray500,

        selected: {
          stroke: colors.blue500,
        },
      },
      label: {
        cursor: 'pointer',
        ':hover svg': {
          stroke: colors.blue500,
        },
      },
    },

    readOnlyState: {
      radioButton: {
        fill: colors.gray500,
        stroke: colors.gray500,
        cursor: 'default',

        selected: {
          fill: colors.gray0,
        },
      },
      label: {
        cursor: 'default',
      },
    },

    disabledState: {
      radioButton: {
        fill: colors.gray300,
        stroke: colors.gray500,
        cursor: 'not-allowed',

        selected: {
          fill: colors.gray0,
          stroke: colors.blue400,
        },
      },
      label: {
        color: colors.gray500,
        cursor: 'not-allowed',
      },
    },
  },

  selectField: {
    color: colors.gray800,
    placeholderColor: colors.gray450,
    disabled: {
      color: colors.gray500,
    },
  },

  splitButton: {
    gray: colors.graybg,
    red: colors.redbg,
    green: colors.greenbg,
    yellow: colors.yellowbg,
    purple: colors.purplebg,
    blue: colors.bluebg,
    whiteBlue: colors.bluebg,
  },

  spinner: {
    color: colors.gray500,
    opacity: 0.5,
  },

  tab: {
    color: colors.gray800,

    opaque: {
      hover: {
        background: 'transparent',
      },
    },

    default: {
      hover: {
        background: colors.gray250,
      },
    },

    border: {
      background: colors.gray800,
    },

    disabled: {
      color: colors.gray500,
    },

    badge: 'darkGray',

    tag: {
      color: colors.gray0,
      background: colors.blue700,
      fontSize: fontSizes.small,
      borderRadius: '4px',
      callout: true,
    },
  },

  table: {
    fontSize: fontSizes.basic,

    tableHead: {
      th: {
        background: colors.gray0,
        fontWeight: 400, // TO-DO: REB-107 update all fontWeights
        color: colors.gray500,
      },
      tr: { background: colors.gray0 },
    },

    tableBody: {
      tr: {
        background: colors.gray0,

        hover: {
          background: colors.gray100,
        },

        disabled: {
          background: colors.gray50,

          hover: {
            background: colors.gray100,
          },
        },
      },

      th: {
        fontWeight: 400, // TO-DO: REB-107 update all fontWeights
      },

      td: {
        fontWeight: 400, // TO-DO: REB-107 update all fontWeights
      },
    },
  },

  tag: {
    small: {
      fontSize: fontSizes.small,
      padding: `${space.space2} ${space.space4}`,
      fontWeight: 'inherit',
    },
    large: {
      fontSize: fontSizes.basic,
      padding: `${space.space8} ${space.space8}`,
      fontWeight: 'inherit',
    },

    primary: {
      gray: {
        backgroundColor: colors.gray800,
        fontWeight: fontWeights.semibold,
        color: colors.gray0,
      },
      green: {
        backgroundColor: colors.green400,
        fontWeight: fontWeights.semibold,
        color: colors.gray800,
      },
      red: {
        backgroundColor: colors.red500,
        fontWeight: fontWeights.semibold,
        color: colors.gray800,
      },
      yellow: {
        backgroundColor: colors.yellow400,
        fontWeight: fontWeights.semibold,
        color: colors.gray800,
      },
      blue: {
        background: colors.blue300,
        fontWeight: fontWeights.semibold,
        color: colors.gray800,
      },
      purple: {
        background: colors.purple300,
        fontWeight: fontWeights.semibold,
        color: colors.gray800,
      },
      pink: {
        background: colors.pink300,
        fontWeight: fontWeights.semibold,
        color: colors.gray800,
      },
    },

    secondary: {
      gray: {
        backgroundColor: colors.gray200,
        color: colors.gray700,
        border: `1px solid ${colors.gray400}`,
        fontWeight: fontWeights.regular,
        padding: `${space.space2} ${space.space4}`,
      },
      green: {
        backgroundColor: colors.greenbg,
        color: colors.green800,
        border: `1px solid ${colors.green200}`,
        fontWeight: fontWeights.regular,
        padding: `${space.space2} ${space.space4}`,
      },
      red: {
        backgroundColor: colors.redbg,
        color: colors.red800,
        border: `1px solid ${colors.red200}`,
        fontWeight: fontWeights.regular,
        padding: `${space.space2} ${space.space4}`,
      },
      yellow: {
        backgroundColor: colors.yellowbg,
        color: colors.yellow800,
        border: `1px solid ${colors.yellow200}`,
        fontWeight: fontWeights.regular,
        padding: `${space.space2} ${space.space4}`,
      },
      blue: {
        backgroundColor: colors.bluebg,
        color: colors.blue800,
        border: `1px solid ${colors.blue200}`,
        fontWeight: fontWeights.regular,
        padding: `${space.space2} ${space.space4}`,
      },
      purple: {
        backgroundColor: colors.purplebg,
        color: colors.purple800,
        border: `1px solid ${colors.purple200}`,
        fontWeight: fontWeights.regular,
        padding: `${space.space2} ${space.space4}`,
      },
      pink: {
        backgroundColor: colors.pink100,
        color: colors.pink800,
        border: `1px solid ${colors.pink200}`,
        fontWeight: fontWeights.regular,
        padding: `${space.space2} ${space.space4}`,
      },
    },
    inactive: {
      backgroundColor: colors.gray200,
      color: colors.gray600,
      border: `1px solid ${colors.gray300}`,
      fontWeight: fontWeights.regular,
    },
    callout: {
      backgroundColor: colors.blue700,
      color: colors.gray0,
      border: 'transparent',
      fontSize: fontSizes.xsmall,
      fontWeight: fontWeights.semibold,
      padding: `0 ${space.space4}`,
    },
  },

  textField: {
    color: colors.gray800,
    placeholderColor: colors.gray450,
    lineHeight: lineHeights.paragraph,
    disabled: {
      color: colors.gray450,
    },
  },

  toast: {
    success: {
      variantStyle: {
        background: colors.greenbg,
        color: colors.gray800,
      },
      icon: {
        name: 'checkCircle',
        color: colors.green700,
      },
    },

    info: {
      variantStyle: {
        background: colors.bluebg,
        color: colors.gray800,
      },
      icon: {
        name: 'infoCircle',
        color: colors.blue700,
      },
    },

    warning: {
      variantStyle: {
        background: colors.yellowbg,
        color: colors.gray800,
      },
      icon: {
        name: 'infoCircle',
        color: colors.yellow700,
      },
    },

    error: {
      variantStyle: {
        background: colors.redbg,
        color: colors.gray800,
      },
      icon: {
        name: 'infoCircle',
        color: colors.red700,
      },
    },

    default: {
      variantStyle: {
        background: colors.gray0,
        color: colors.gray800,
      },
    },
  },

  typography: {
    h1: {
      fontWeight: fontWeights.regular,
      fontSize: fontSizes.xxxlarge,
      lineHeight: lineHeights.heading,
      fontFamily: fontFamilies.body,
    },

    h2: {
      fontWeight: fontWeights.regular,
      fontSize: fontSizes.xxlarge,
      lineHeight: lineHeights.heading,
      fontFamily: fontFamilies.body,
    },

    h3: {
      fontWeight: fontWeights.regular,
      fontSize: fontSizes.xlarge,
      lineHeight: lineHeights.heading,
      fontFamily: fontFamilies.body,
    },

    default: {
      fontWeight: fontWeights.regular,
      fontSize: fontSizes.xxlarge,
      lineHeight: lineHeights.heading,
      fontFamily: fontFamilies.body,
    },
  },

  calendar: {
    grid: {
      background: colors.gray0,
      height: '100%',
      width: 'max-content',
      borderRadius: radii.medium,
      padding: `${space.space16} ${space.space8} ${space.space8} ${space.space8}`,
    },

    header: {
      gap: 0,
      padding: `${space.space4} 0`,
    },

    table: {
      borderSpacing: 0,
      thead: {
        th: {
          display: 'table-cell',
          color: colors.gray500,
          fontWeight: fontWeights.semibold,
          textTransform: 'uppercase',
          padding: `${space.space16} ${space.space4}`,
          textAlign: 'center',
          verticalAlign: 'middle',
        },
      },
      tbody: {
        background: colors.gray50,
        td: {
          default: {
            width: space.space30,
            height: space.space30,
            outline: 'none',
            cursor: 'pointer',
            color: colors.gray700,
            borderRadius: radii.full,
            fontSize: fontSizes.large,
            fontWeight: fontWeights.regular,
            padding: 0,
          },

          disabled: {
            color: colors.gray400,
            cursor: 'not-allowed',
          },

          selected: {
            color: colors.blue700,
            background: colors.bluebg,
          },

          hovered: {
            background: colors.bluebg,
          },
        },
      },
    },

    todayMarker: {
      position: 'absolute',
      bottom: space.space3,
      left: `calc((${space.space16}) / 2)`,
      borderRadius: radii.full,
      background: colors.gray400,
      width: space.space14,
      height: space.space2,
    },
  },
};
