import { Text, Grid, Button, Icon } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface CreateReimbursementCardProps {
  isLoading: boolean;
  onCreateReimbursement: () => Promise<void>;
}

export const CreateReimbursementCard = ({
  isLoading,
  onCreateReimbursement,
}: CreateReimbursementCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid
      background="gray0"
      padding="space16"
      gap="space24"
      placeItems="center"
      placeContent="center"
      borderRadius="medium">
      <Grid gap="space12" templateColumns="repeat(3, auto)">
        <Grid
          background="green100"
          padding="space16"
          placeContent="center"
          borderRadius="medium">
          <Icon icon="receipt" size="space20" />
        </Grid>
        <Grid
          background="pink100"
          padding="space16"
          paddingLeft="space18"
          placeContent="center"
          borderRadius="medium">
          <Icon icon="hospitality" size="space20" />
        </Grid>
      </Grid>
      <Text textAlign="center" fontSize="basic">
        {t('dashboard.createExpense.text')}
      </Text>
      <Button onClick={onCreateReimbursement} loading={isLoading}>
        {t('dashboard.createExpense.createCTA')}
      </Button>
    </Grid>
  );
};
