import {
  Radio,
  RadioProps,
  Tooltip,
  TooltipProps,
  useTooltip,
  mergeProps,
  UseTooltipConfig,
} from '@candisio/design-system';

export interface ProcessingFormPurchaseOrderTypeFieldProps {
  documentId?: string;
  hasTooltip?: boolean;
  hasBorder?: boolean;
}

export interface RadioWithTooltipProps extends RadioProps {
  tooltipPlacement?: UseTooltipConfig['placement'];
  tooltipChildren?: TooltipProps['children'];
}

export const RadioWithTooltip = ({
  tooltipPlacement,
  tooltipChildren,
  children,
  ...restProps
}: RadioWithTooltipProps) => {
  const { isOpen, tooltipProps, triggerProps, triggerRef, tooltipRef } =
    useTooltip({ placement: tooltipPlacement });

  return (
    <>
      <Radio {...mergeProps(restProps, triggerProps)} ref={triggerRef}>
        {children}
      </Radio>
      {isOpen && tooltipChildren && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {tooltipChildren}
        </Tooltip>
      )}
    </>
  );
};
