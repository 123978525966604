import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ApprovalInvoiceDocumentsResponse } from 'generated-types/resolvers-types';
import { paginationWithRecordConnectionsMergeNoNodes } from '../pagination-results-merge';

const approvalInvoiceDocumentsPagination: FieldPolicy<ApprovalInvoiceDocumentsResponse> =
  {
    merge: paginationWithRecordConnectionsMergeNoNodes(
      'ApprovalInvoiceDocumentsResponse'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      approvalInvoiceDocuments: approvalInvoiceDocumentsPagination,
    },
  },
};
