import { useExportContext } from 'views/Integrations/Export/Context';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { isDatevExportType } from 'views/Integrations/Export/toolkit/utils';
import { ExportReimbursementItemsTableColumnKeys } from '../types';

const defaultColumns: ExportReimbursementItemsTableColumnKeys[] = [
  'targetedMembership',
  'reasonExpense',
  'expenseId',
  'submittedAt',
  'type',
  'titleReimbursement',
  'grossAmount',
];

export const useGetReimbursementItemsTableColumns = () => {
  const state = useExportContext();
  const { exportId, isReExport } = state;
  const { exportDetails } = useExportDataGigaFactory(state);
  const exportType = exportDetails?.type;

  const hasExportStatus = !!exportId && !isReExport;

  const hasExportMethod =
    hasExportStatus && !!exportType && isDatevExportType(exportType);

  const columns = Array<ExportReimbursementItemsTableColumnKeys | false>()
    .concat(
      hasExportStatus && 'exportStatus',
      hasExportMethod && 'exportMethod',
      defaultColumns
    )
    .filter(Boolean) as ExportReimbursementItemsTableColumnKeys[];

  return columns;
};
