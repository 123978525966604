import { Heading, Paragraph } from '@candisio/design-system';
import { DocumentAccessCard } from 'components/DocumentAccess/DocumentAcccessCard';
import { useDocumentAccessLevelsData } from 'components/DocumentAccess/useDocumentAccessLevelsData';
import { useTranslation } from 'react-i18next';

interface AccessByRoleCardContainerProps {
  globalDocumentId?: string;
}

export const ApproversAccessCardContainer = ({
  globalDocumentId,
}: AccessByRoleCardContainerProps) => {
  const { accessByApproversData, loading } = useDocumentAccessLevelsData({
    globalDocumentId,
  });

  const [t] = useTranslation();

  return (
    <DocumentAccessCard
      accessData={accessByApproversData}
      loading={loading}
      title={
        <Heading as="h3">
          {t('common:documentAccess.membership.access.byApprovers.title')}
        </Heading>
      }
      description={
        <Paragraph fontWeight="400" color="gray600" whiteSpace="pre-wrap">
          {t('common:documentAccess.membership.access.byApprovers.description')}
        </Paragraph>
      }
    />
  );
};
