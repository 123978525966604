import { Button, Grid, TruncatedText } from '@candisio/design-system';
import { Maybe } from '@graphql-tools/utils';
import { useProfiler } from '@sentry/react';
import { PurchaseOrderSplitItem } from 'components/PurchaseOrders/PurchaseOrderSplitItem';
import { isNil } from 'lodash';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';
import { amountFormat, percentageFormat } from 'utils/format';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';

export const viewSplitIconTestId = 'view-split-button';
export const deleteSplitIconTestId = 'delete-split-button';
const customStyle = 'auto 1fr 1fr 1.5fr auto';

export interface SummaryProps {
  icon?: 'edit' | 'view';
  onClick?: () => void;
  onClickItem?: () => void;
  onDelete?: () => void;
  splitNumber?: number;
  data: {
    currency?: Maybe<string>;
    costCenterName?: Maybe<string>;
    costObjectName?: Maybe<string>;
    amount?: Maybe<number>;
    vatRate?: Maybe<number>;
    quantity?: Maybe<number>;
    note?: Maybe<string>;
  };
}

export const formatBooking = (data: SummaryProps['data']) => {
  const {
    costCenterName,
    costObjectName,
    amount,
    currency,
    vatRate,
    note,
    quantity,
  } = data;

  const formattedAmount = amountFormat(amount, currency);

  const formattedVatRate = !isNil(vatRate) ? percentageFormat(vatRate) : null;

  return {
    costCenterName,
    costObjectName,
    amount: formattedAmount,
    vatRate: formattedVatRate,
    quantity: quantity ?? null,
    note: note ?? null,
  };
};

export const Summary = ({
  data,
  icon,
  onClick,
  onDelete,
  splitNumber,
  onClickItem,
}: SummaryProps) => {
  useProfiler('SplitBookingsSummary', { hasRenderSpan: true });
  const [tSplitBookings] = useTranslation('split-bookings');
  const { shouldUseSapPurchaseOrder } = useSap();

  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();

  const isOrgUsingOnlyCostObjects = !showCostCenterField && showCostObjectField;

  const formattedBooking = formatBooking(data);

  const fieldIndex = splitNumber ? splitNumber - 1 : 0;

  const iconLabel =
    icon === 'edit'
      ? tSplitBookings('editSplitIcon')
      : tSplitBookings('viewSplitIcon');

  return (
    <Grid
      onClick={onClickItem}
      alignItems="center"
      autoFlow="column"
      gap="space8"
      minHeight="space24"
      templateColumns={
        shouldUseSapPurchaseOrder ? customStyle : 'space16 2fr 1fr 3fr'
      }>
      <TruncatedText color="gray400">
        {splitNumber ? splitNumber.toString() : ''}
      </TruncatedText>
      <TruncatedText>{formattedBooking.amount ?? ''}</TruncatedText>
      {shouldUseSapPurchaseOrder ? (
        <PurchaseOrderSplitItem
          color="gray500"
          fieldIndex={fieldIndex}
          note={formattedBooking?.note}
          quantity={formattedBooking?.quantity}
        />
      ) : (
        <>
          <TruncatedText color="gray500">
            {formattedBooking.vatRate ?? ''}
          </TruncatedText>
          <TruncatedText color="gray500">
            {isOrgUsingOnlyCostObjects && formattedBooking.costObjectName
              ? formattedBooking.costObjectName
              : formattedBooking.costCenterName ?? ''}
          </TruncatedText>
        </>
      )}

      {icon && onClick ? (
        <Button
          icon={icon}
          data-testid={viewSplitIconTestId}
          label={iconLabel}
          size="xsmall"
          onClick={onClick}
          variant="tertiary"
          color="blue"
        />
      ) : null}
      {onDelete ? (
        <Button
          icon="trash"
          data-testid={deleteSplitIconTestId}
          label={tSplitBookings('deleteSplitIcon')}
          size="xsmall"
          onClick={onDelete}
          variant="tertiary"
          color="red"
        />
      ) : null}
    </Grid>
  );
};
