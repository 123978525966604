import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  BookingKey,
  useActivateBookingKeyMutation,
  useArchiveBookingKeyMutation,
  useBookingKeyQuery,
  useBookingKeysQuery,
  useBulkCreateBookingKeysMutation,
  useCreateBookingKeyMutation,
  useUpdateBookingKeyMutation,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingKeyFormData } from 'views/Settings/TaxCodes/components/TaxCodeDetails/types';
import { DefaultBookingKey } from 'views/Settings/TaxCodes/components/TaxCodeModals/DefaultImport';
import { useTaxCodesNavigation } from 'views/Settings/TaxCodes/useTaxCodesNavigation';
import {
  isCreated,
  isDuplicate,
  isUpdated,
  refetchBookingKeyQueries,
} from 'views/Settings/TaxCodes/utils';
import { extractPercentageValue } from 'views/Settings/TaxCodes/utils/taxPercentage';

export const useTaxCodes = () => {
  const { loading: bookingKeysLoading, data } = useBookingKeysQuery();
  const [createBookingKey] = useCreateBookingKeyMutation();
  const [updateBookingKey] = useUpdateBookingKeyMutation();
  const { navigateBackToList, bookingKeyId } = useTaxCodesNavigation();
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const { success, error } = useToastMessage();

  const [archiveBookingKey, { loading: archiveLoading }] =
    useArchiveBookingKeyMutation();

  const [activateBookingKey, { loading: activateLoading }] =
    useActivateBookingKeyMutation();

  const { data: singleBookingKeyData, loading: bookingKeyLoading } =
    useBookingKeyQuery({
      variables: {
        id: bookingKeyId || '',
      },
      skip: bookingKeyId === 'create' || !bookingKeyId,
    });

  const [bulkImport, { loading: importLoading }] =
    useBulkCreateBookingKeysMutation();

  const handleImport = useCallback(
    async (defaultBookingKeys: DefaultBookingKey[]) => {
      await bulkImport({
        variables: {
          input: defaultBookingKeys.map(bk => ({
            taxCode: bk.taxCode,
            description: bk.description,
          })),
        },
        refetchQueries: refetchBookingKeyQueries(),
      });

      navigateBackToList();

      success(t('import.defaultImport.success'));
    },
    [bulkImport, navigateBackToList, success, t]
  );

  const handleCreate = useCallback(
    async ({ description, taxCode, taxPercentage }: BookingKeyFormData) => {
      const result = await createBookingKey({
        variables: {
          input: {
            taxCode,
            description,
            taxPercentage: extractPercentageValue(taxPercentage),
          },
        },
        refetchQueries: refetchBookingKeyQueries(),
      });

      if (isCreated(result?.data?.createBookingKey)) {
        success(t('form.actions.toasts.created', { taxCode }));
        navigateBackToList();
      } else if (isDuplicate(result?.data?.createBookingKey)) {
        error(t('form.actions.toasts.duplicate', { taxCode }));
      }
    },
    [createBookingKey, error, navigateBackToList, success, t]
  );

  const handleUpdate = useCallback(
    async ({ description, taxCode, taxPercentage }: BookingKeyFormData) => {
      const result = await updateBookingKey({
        variables: {
          input: {
            taxCode,
            description,
            taxPercentage: extractPercentageValue(taxPercentage),
            id: bookingKeyId ?? '',
          },
        },
        refetchQueries: refetchBookingKeyQueries(),
      });

      if (isUpdated(result?.data?.updateBookingKey)) {
        success(t('form.actions.toasts.updated', { taxCode }));
        navigateBackToList();
      } else if (isDuplicate(result?.data?.updateBookingKey)) {
        error(t('form.actions.toasts.duplicate', { taxCode }));
      }
    },
    [updateBookingKey, bookingKeyId, success, t, navigateBackToList, error]
  );

  return {
    importLoading,
    bookingKeysLoading,
    archiveLoading,
    activateLoading,
    bookingKeyLoading,
    bookingKeys: (data?.bookingKeys || []) as BookingKey[],
    activeBookingKeys: (data?.bookingKeys.filter(x => !x.isArchived) ||
      []) as BookingKey[],
    archivedBookingKeys: (data?.bookingKeys.filter(x => x.isArchived) ||
      []) as BookingKey[],
    bookingKey: singleBookingKeyData?.bookingKey as BookingKey,
    handleImport,
    handleCreate,
    handleUpdate,
    archiveBookingKey,
    activateBookingKey,
  };
};
