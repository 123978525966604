import { Flex, Grid, Icon, Text } from '@candisio/design-system';
import { User } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { AbsenceTag } from './AbsenceTag';
import { AvatarStatus } from './AvatarWithStatus';

export interface AbsenceInfoProps {
  fromDate: Date;
  name?: string;
  note?: string;
  status: AvatarStatus;
  toDate: Date;
  substitute?: Pick<User, 'name'>;
}

export const AbsenceInfo = ({
  fromDate,
  name,
  note,
  status,
  toDate,
  substitute,
}: AbsenceInfoProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      gap="space8"
      aria-label={t('absence.tooltip.label')}
      width="17rem"
      maxWidth="17rem">
      <Grid gap="space2">
        <Flex
          columnGap="space8"
          justifyContent="start"
          alignItems="center"
          wrap="wrap">
          <Text
            color="gray800"
            fontSize="basic"
            lineHeight="paragraph"
            maxWidth="17rem">
            {name}
          </Text>
          {substitute && (
            <Flex gap="space8" justifyContent="start" alignItems="center">
              <Icon
                flex="1 0 auto"
                icon="arrowRight"
                size="space16"
                color="gray800"
              />
              <Text color="gray800" fontSize="basic" lineHeight="paragraph">
                {substitute.name}
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex
          wrap="wrap"
          gap="space4"
          justifyContent="start"
          alignItems="baseline">
          <AbsenceTag status={status} />
          <Text color="gray500" fontSize="small">
            {
              // eslint-disable-next-line candis/no-template-strings-inside-translation
              t(`absence.tooltip.timePeriod.${status}`, {
                fromDate: DateFormatters.compact(fromDate),
                toDate: DateFormatters.compact(toDate),
              })
            }
          </Text>
        </Flex>
      </Grid>
      {note && (
        <Text color="gray500" fontSize="small">
          {note}
        </Text>
      )}
    </Grid>
  );
};
