import { Grid, Text } from '@candisio/design-system';
import { ExpenseItemArchivedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { mappedExpenseTypeToGeneralTitle } from 'views/Reimbursement/utils/getActivityTypeToReimbursementHistoryComponent';

export const ExpenseItemArchived = ({
  reimbursementItemMetadata,
}: ExpenseItemArchivedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const fallBackTitle = reimbursementItemMetadata?.type
    ? t(mappedExpenseTypeToGeneralTitle[reimbursementItemMetadata?.type])
    : undefined;

  return (
    <Grid>
      <Text>
        {t('timeline.expenseRemoved', { title: expenseTitle ?? fallBackTitle })}
      </Text>
    </Grid>
  );
};
