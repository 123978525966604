import { useUserRoles } from 'hooks/useUserRoles';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useReimbursementsCount } from 'views/Reimbursement/hooks/useReimbursementsCount';
import { openReimbursementStatuses } from 'views/Reimbursement/Lists/OpenReimbursementListContainer';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuTravel = ({ ...iconProps }) => {
  const currentUser = useCurrentUser();
  const { hasRoles } = useUserRoles();

  const { isAdmin } = useUserRoles();

  const { canUseReimbursement } = useReimbursement();

  const isExpenseIconVisible = (canUseReimbursement || isAdmin) && hasRoles;

  const {
    count: countReimbursements,
    loadingCount: loadingCountReimbursements,
  } = useReimbursementsCount({
    filters: {
      statuses: openReimbursementStatuses,
      targetMembershipIds: currentUser?.id ? [currentUser?.id] : undefined,
    },
  });

  return (
    <NavigationIcon
      {...iconProps}
      icon="menuTravel"
      count={countReimbursements}
      countLoading={loadingCountReimbursements}
      showMenuIcon={isExpenseIconVisible}
    />
  );
};
