import React, { useRef } from 'react';
import { mergeProps, useTabPanel } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { TabListState } from 'react-stately';
import { LayoutProps } from '../../types';
import { Flex, FlexProps } from '../Flex';
import { TabItem } from './Tab';

export interface TabPanelProps
  extends LayoutProps,
    Omit<FlexProps, 'as' | 'ref'> {
  state: TabListState<TabItem>;
}

/**
 * Renders content related to selected tab from `Tabs` component
 *
 * [Storybook]{@link (https://candisio.github.io/design-system/?path=/docs/atoms-navigation-tabs)}
 *
 * @param {TabListState<TabItem>} state initial state of tabs derived from `useTabListState` hook
 * @param {React.Key} key key derived from selectedItem of state
 */
export const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>(
  ({ state, ...props }, forwardedRef) => {
    const tabPanelRef = useRef(null);

    const {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      ...restProps
    } = props;

    const tabPanelAriaProps = {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
    };

    const { tabPanelProps: tabPanelReturnProps } = useTabPanel(
      tabPanelAriaProps,
      state,
      tabPanelRef
    );

    return (
      <Flex
        direction="column"
        {...mergeProps(tabPanelReturnProps, restProps)}
        ref={mergeRefs([tabPanelRef, forwardedRef])}>
        {state.selectedItem?.props &&
          (state.selectedItem.props as TabPanelProps).children}
      </Flex>
    );
  }
);
