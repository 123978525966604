import { FilterOptionsAccessor } from '@candisio/design-system';
import {
  DocumentsTableData,
  NavigationTableParams,
} from 'components/DocumentsTable/types';
import {
  DocumentStatus,
  ExportStatus,
  ProvisionEntityType,
  useDocumentsForExportContactsQuery,
} from 'generated-types/graphql.types';
import { get, uniq } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { goTo } from 'views/Integrations/Export/toolkit/utils/goto';
import { ExportEntityRow } from 'views/Integrations/Export/types';
import { MAX_ROWS } from '../../toolkit/hooks/useExportTableData';
import { ExportReimbursementItemsTableColumnKeys } from './reimbursementItems/types';

const exportMethods = ['rds_1_0', 'accounting_documents'] as const;

const exportMethodsTranslationsMap: Record<
  (typeof exportMethods)[number],
  string
> = {
  rds_1_0: 'export.table.columns.exportMethod.methods.rds_1_0',
  accounting_documents:
    'export.table.columns.exportMethod.methods.accounting_documents',
};

const exportStatuses = [
  ExportStatus.Failed,
  ExportStatus.PartialyExported,
  ExportStatus.Exported,
] as const;

type AllowedStatuses = (typeof exportStatuses)[number];

const exportStatusTranslationsMap: Record<AllowedStatuses, string> = {
  EXPORTED: 'export.table.columns.status.statuses.exported',
  FAILED: 'export.table.columns.status.statuses.failed',
  PARTIALY_EXPORTED: 'export.table.columns.status.statuses.partialy_exported',
};

export type TableFilters = 'contact' | 'exportStatus' | 'exportMethod';

const EXPORT_ID_URL_PARAM = 'exportId';
interface Contact {
  [key: string]: string;
}

export const useExportTableFilters = (
  filters: TableFilters[],
  data: DocumentsTableData[]
) => {
  const [t] = useTranslation();
  const [search] = useSearchParams();
  const exportId = search.get(EXPORT_ID_URL_PARAM);

  const { data: exportData, loading } = useDocumentsForExportContactsQuery({
    variables: {
      limit: MAX_ROWS,
      filters: { status: [DocumentStatus.Approved] },
    },
    skip: !!exportId,
  });

  const documentsForExportContacts =
    exportData?.documentsForExportContacts?.node;

  // This method is crucial for the functionality of the contact filter;
  // to map each contact's name to their respective ID.
  // However, this is only necessary for new exports, i.e., documents without an 'exportId'.
  const matchNameToContactId = documentsForExportContacts?.reduce(
    (acc: Contact, { contact }) => {
      acc[contact?.name?.value] = contact.id;

      return acc;
    },
    {}
  );

  const uniqueContacts = uniq(
    (data ?? []).map(({ contact }) => contact).filter(Boolean)
  );

  const contactsForReExport = uniqueContacts.map(contact => ({
    id: contact,
    label: contact,
  }));

  const contactsForNewExport = uniqueContacts.map(contact => ({
    id: matchNameToContactId?.[contact ?? ''] ?? contact,
    label: contact,
  }));

  const filterMap = useMemo(
    () => ({
      contact: {
        data: exportId ? contactsForReExport : contactsForNewExport,
        isLoading: loading,
      },
      exportStatus: {
        data: exportStatuses.map(exportStatus => ({
          id: exportStatus,
          label: t(exportStatusTranslationsMap[exportStatus]),
        })),
      },
      exportMethod: {
        data: exportMethods.map(exportMethod => ({
          id: exportMethod,
          label: t(exportMethodsTranslationsMap[exportMethod]),
        })),
      },
    }),
    [contactsForNewExport, contactsForReExport, exportId, loading, t]
  );

  const filterOptions: FilterOptionsAccessor<DocumentsTableData> =
    useMemo(() => {
      return filters.reduce(
        (allFilters, currentFilter) => ({
          ...allFilters,
          [currentFilter]: filterMap[currentFilter],
        }),
        {}
      );
    }, [filterMap, filters]);

  return {
    filterOptions,
  };
};

export const buildExportFilters =
  (filters: Array<TableFilters>, search: URLSearchParams) =>
  (row: ExportEntityRow) => {
    const filtersWithValues = filters
      .map(f => ({
        key: f,
        values: search.getAll(f),
      }))
      .filter(f => f.values.length);

    // no filter values found
    if (filtersWithValues.length === 0) {
      return true;
    }

    const exportPropsPathMap: Record<TableFilters, string> = {
      exportMethod: 'exportStatus.exportMethod',
      contact: 'contact',
      exportStatus: 'exportStatus.status',
    };

    return filtersWithValues.every(f =>
      f.values.includes(get(row, exportPropsPathMap[f.key]))
    );
  };

export const buildExportFiltersForReimbursementTable =
  (
    filters: Array<ExportReimbursementItemsTableColumnKeys>,
    search: URLSearchParams
  ) =>
  (row: ExportEntityRow) => {
    const filtersWithValues = filters
      .map(f => ({
        key: f,
        values: search.getAll(f),
      }))
      .filter(f => f.values.length);

    // no filter values found
    if (filtersWithValues.length === 0) {
      return true;
    }

    const exportPropsPathMap: Record<
      ExportReimbursementItemsTableColumnKeys,
      string
    > = {
      exportMethod: 'exportStatus.exportMethod',
      grossAmount: 'grossAmount',
      submittedAt: 'submittedAt',
      exportStatus: 'exportStatus.status',
      targetedMembership: 'targetedMembership',
      type: 'type',
      reasonExpense: '',
      titleReimbursement: '',
      expenseId: '',
      canBeSelectedTooltipText: '',
      isDisabled: '',
      reimbursementColletionId: '',
    };

    return filtersWithValues.every(f =>
      f.values.includes(get(row, exportPropsPathMap[f.key]))
    );
  };

export const useDefaultOnRowClick = () => {
  const navigateToDocuments = goTo.useGoToDocument();
  const navigateToTransactions = goTo.useGoToTransaction();

  return ({ id, hasDocument }: NavigationTableParams) => {
    if (hasDocument) {
      navigateToDocuments(id);
    } else {
      navigateToTransactions(id);
    }
  };
};

export const useProvisionOnRowClick = ({
  data,
}: {
  data: ExportEntityRow[];
}) => {
  const navigateToDocuments = goTo.useGoToDocument();
  const navigateToTransactions = goTo.useGoToTransaction();

  return ({ id }: NavigationTableParams) => {
    let entity = data.find(entity => entity.id === id);

    if (!entity || !entity.provisionEntityId) {
      return;
    }

    let hasDocument =
      entity.provisionEntityType === ProvisionEntityType.Document;

    if (hasDocument) {
      navigateToDocuments(entity.provisionEntityId);
    } else {
      navigateToTransactions(entity.provisionEntityId);
    }
  };
};
