import {
  DocumentCurrency,
  GeneralExpenseItem,
  HospitalityExpenseItem,
  useReimbursementItemsSplitBookingsQuery,
} from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { ReimbursementSplitBookingsFormValues } from '../toolkit/reimbursementSplitBookingsFormSchema';
import { useFormattedBookings } from './useFormattedBookings';
import { calculateSumOfBookingsSplitAmounts } from '../components/RightSection/ReimbursementSplitBookings/hooks/useFormatAmountHelpers';

export type HospitalityExpense = Omit<HospitalityExpenseItem, '__typename'>;
export type GeneralExpense = Omit<GeneralExpenseItem, '__typename'>;

type ReimbursementItemSplitBookings =
  ReimbursementSplitBookingsFormValues['reimbursementItemBookings'];

export const useReimbursementSplitBookingsFormInitialValues = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();

  const { data, loading: loadingSplits } =
    useReimbursementItemsSplitBookingsQuery({
      variables: { filters: { reimbursementCaseId: reimbursementId } },
      skip: !reimbursementId || !canUseReimbursement,
      fetchPolicy: 'network-only',
    });

  const fetchedItems = data?.reimbursementItemsSplitBookings ?? [];

  const formattedBooking = useFormattedBookings();

  const reimbursementItemSplitBookings: ReimbursementItemSplitBookings =
    fetchedItems.map(item => {
      const grossAmount = !isNil(item.totalAmount?.amount)
        ? formatIntegerAmountToDecimal(
            item.totalAmount?.amount,
            item.totalAmount?.precision
          )
        : 0;

      const bookings = (item.bookings ?? []).map(formattedBooking);
      const remainingAmount =
        grossAmount - calculateSumOfBookingsSplitAmounts(bookings);

      return {
        bookings,
        grossAmount,
        remainingAmount,
        reimbursementItemType: item.type,
        reimbursementItemId: item.id,
        currency: item.totalAmount?.currency ?? DocumentCurrency.Eur,
      };
    });

  return {
    loadingSplits,
    reimbursementItemSplitBookings,
  };
};
