import {
  Maybe,
  PurchaseOrder,
  useGetSapPurchaseOrdersQuery,
} from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';

type Props = {
  isLoading?: boolean;
  purchaseOrders: Maybe<PurchaseOrder>[];
};

export const useSapPurchaseOrders = (documentId?: string): Props => {
  const { shouldUseSapPurchaseOrder } = useSap();
  const { data: sapPurchaseOrderDara, loading: isLoading } =
    useGetSapPurchaseOrdersQuery({
      variables: { id: documentId ?? '' },
      skip: !documentId || !shouldUseSapPurchaseOrder,
    });

  if (isLoading) {
    return {
      isLoading: true,
      purchaseOrders: [],
    };
  }

  const purchaseOrders = (sapPurchaseOrderDara?.getDocument?.purchaseOrders ??
    []) as Maybe<PurchaseOrder>[];

  return {
    purchaseOrders,
  };
};
