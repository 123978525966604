import { Flex, Grid, Text } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { DeleteReimbursementItemAction } from './DeleteReimbursementItem';
import { ExpenseFormWrapper } from './ExpenseFormWrapper';
import { ExpenseInvoiceInputField } from './ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from './ExpenseInvoicePreviewContainer';
import { ReadonlyHospitalityExpenseForm } from './ReadonlyHospitalityExpenseForm';
import { generateFieldNames } from './utils/generateHospitalityFormFieldNames';
import { BooleanMap } from './utils/useExpensesFormActions';

interface HospitalityExpenseFormProps {
  isReadOnly: boolean;
  isPromotion?: boolean;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  onUpdateHospitality: (index: number) => () => void;
  onReplaceInvoice: (index: number) => () => void;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  onUpdateTotalAmount: (index: number) => void;
  formIndex: number;
  isDeletingForm: BooleanMap;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  isCreatingInvoice: BooleanMap;
  clearableField: string | undefined;
  expenseId: string;
  canManageReimbursementItems: boolean;
}

export const HospitalityExpenseForm = ({
  isReadOnly,
  onRemoveForm,
  onCleareableField,
  onUpdateHospitality,
  onInvoiceChange,
  onReplaceInvoice,
  onUpdateTotalAmount,
  formIndex,
  clearableField,
  formMethods,
  isCreatingInvoice,
  expenseId,
  isDeletingForm,
  canManageReimbursementItems,
  isPromotion = false,
}: HospitalityExpenseFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { watch, getValues } = formMethods;

  const fields = generateFieldNames(formIndex);

  const invoices = watch(fields.files);

  const invoice = invoices?.[0];

  const totalAmount = getValues(fields.totalAmount);
  const reason = getValues(fields.reason);
  const showInvoicePreview = isCreatingInvoice[formIndex] || !!invoice;

  if (isReadOnly) {
    return (
      <ReadonlyHospitalityExpenseForm
        expenseId={expenseId}
        reason={reason}
        invoice={invoice}
        formIndex={formIndex}
        totalAmount={totalAmount}
      />
    );
  }

  return (
    <ExpenseFormWrapper
      expenseId={expenseId}
      totalAmount={totalAmount}
      expenseType="hospitality"
      reason={reason}>
      <Grid as="form" gap="space24">
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={isPromotion ? '' : fields.reason}
            maxLength={60}
            label={t(
              'reimbursementView.middleSection.form.hospitality.reason.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.reason.placeholder'
            )}
            clearable={clearableField === fields.reason}
            onFocus={onCleareableField(fields.reason)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
          <HookFormTextField
            name={isPromotion ? '' : fields.location}
            label={t(
              'reimbursementView.middleSection.form.hospitality.location.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.location.placeholder'
            )}
            clearable={clearableField === fields.location}
            onFocus={onCleareableField(fields.location)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        </Grid>
        <Grid columns={4} gap="space8">
          <HookFormDatePickerField
            name={fields.dayOfExpense}
            readOnly={isPromotion}
            label={t(
              'reimbursementView.middleSection.form.hospitality.date.label'
            )}
            clearable={clearableField === fields.dayOfExpense}
            onFocus={onCleareableField(fields.dayOfExpense)}
            onBlur={onUpdateHospitality(formIndex)}
          />
          <HookFormAmountField
            name={isPromotion ? '' : fields.receiptAmount}
            label={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.receiptAmount}
            onFocus={onCleareableField(fields.receiptAmount)}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
          <HookFormAmountField
            name={isPromotion ? '' : fields.tipAmount}
            label={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.tipAmount}
            onFocus={onCleareableField(fields.tipAmount)}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
          <HookFormAmountField
            name={isPromotion ? '' : fields.totalAmount}
            label={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.placeholder'
            )}
            readOnly
            currency="EUR"
            minValue={0}
          />
        </Grid>
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={isPromotion ? '' : fields.internalGuests}
            label={t(
              'reimbursementView.middleSection.form.hospitality.internalGuests.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.internalGuests.placeholder'
            )}
            infoTooltip={{
              message: t(
                'reimbursementView.middleSection.form.hospitality.internalGuests.tooltip'
              ),
            }}
            clearable={clearableField === fields.internalGuests}
            onFocus={onCleareableField(fields.internalGuests)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
          <HookFormTextField
            name={isPromotion ? '' : fields.externalGuests}
            label={
              /**
               * The other field aligned to this one has infoTooltip which makes the alignement impossible
               * thus the need of putting the label into a flex
               */
              <Flex height="space16" alignItems="center">
                <Text>
                  {t(
                    'reimbursementView.middleSection.form.hospitality.externalGuests.label'
                  )}
                </Text>
              </Flex>
            }
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.externalGuests.placeholder'
            )}
            clearable={clearableField === fields.externalGuests}
            onFocus={onCleareableField(fields.externalGuests)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        </Grid>

        {showInvoicePreview && (
          <ExpenseInvoicePreviewContainer
            invoice={invoice}
            createInvoicePending={isCreatingInvoice[formIndex]}
            onReplaceInvoice={onReplaceInvoice(formIndex)}
          />
        )}
        {!showInvoicePreview && (
          <ExpenseInvoiceInputField
            name={fields.files}
            id={`invoice-input-field-${fields.files}`}
            onChange={file => onInvoiceChange(file, formIndex)}
            onInvoiceUpdate={onUpdateHospitality(formIndex)}
            isPromotion={isPromotion}
          />
        )}
        {canManageReimbursementItems && (
          <DeleteReimbursementItemAction
            loading={isDeletingForm[formIndex]}
            onDelete={onRemoveForm(formIndex, expenseId)}
            isPromotion={isPromotion}
          />
        )}
      </Grid>
    </ExpenseFormWrapper>
  );
};
