import gql from 'graphql-tag';
import {
  documentListDataFragment,
  basicDocumentConnectionFragment,
  approvalInvoiceDocumentDataFragment,
  basicApprovalDocumentConnectionFragment,
} from '../queries/fragments';

export const getApprovalDocumentsQuery = gql`
  query listApprovalsDocuments(
    $limit: Int
    $after: String
    $filters: DocumentFilterInput
    $sort: SortInput
    $withPurchaseOrderData: Boolean = false
    $query: String
  ) {
    listApprovalsDocuments(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
      query: $query
    )
      @connection(
        key: "approvalDocumentsPagination"
        filter: ["filters", "sort", "query"]
      ) {
      edges {
        cursor
        node {
          ...DocumentListData
        }
      }
      pageInfo {
        startCursor
        endCursor
        totalCount
        hasNextPage
      }
    }
  }
  ${documentListDataFragment}
`;

export const getApprovalDocumentsBasicsQuery = gql`
  query listApprovalsDocumentsBasic(
    $limit: Int
    $before: String
    $after: String
    $filters: DocumentFilterInput
    $sort: SortInput
  ) {
    listApprovalsDocuments(
      limit: $limit
      before: $before
      after: $after
      filters: $filters
      sort: $sort
    ) @connection(key: "approvalsBasic", filter: ["filters", "sort"]) {
      ...BasicDocumentConnectionData
    }
  }
  ${basicDocumentConnectionFragment}
`;

export const getApprovalInvoiceDocumentsQuery = gql`
  query getApprovalInvoiceDocuments(
    $limit: Int
    $after: String
    $filters: ApprovalInvoicesFilterInput
    $sort: SortInput
    $withPurchaseOrderData: Boolean = false
    $query: String
  ) {
    approvalInvoiceDocuments(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
      query: $query
    )
      @connection(
        key: "approvalDocumentsPaginationV2"
        filter: ["filters", "sort", "query"]
      ) {
      edges {
        cursor
        node {
          ...ApprovalInvoiceDocumentData
        }
      }
      pageInfo {
        startCursor
        endCursor
        totalCount
        hasNextPage
      }
    }
  }
  ${approvalInvoiceDocumentDataFragment}
`;

export const getApprovalInvoiceDocumentsBasicsQuery = gql`
  query getApprovalInvoiceDocumentsBasics(
    $limit: Int
    $before: String
    $after: String
    $filters: ApprovalInvoicesFilterInput
    $sort: SortInput
  ) {
    approvalInvoiceDocuments(
      limit: $limit
      before: $before
      after: $after
      filters: $filters
      sort: $sort
    ) @connection(key: "approvalsBasic", filter: ["filters", "sort"]) {
      ...BasicApprovalDocumentConnectionData
    }
  }
  ${basicApprovalDocumentConnectionFragment}
`;
