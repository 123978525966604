import { Box, Flex, TruncatedText } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementCommentContainer } from './ReimbursementCommentContainer';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useParams } from 'react-router-dom';
import { useReimbursementHistory } from 'views/Reimbursement/hooks/useReimbursementHistory';
import { ReimbursementHistory } from './History/ReimbursementHistory';

interface HistoryAndCommentSectionContainerProps {
  reimbursement?: Reimbursement;
}

export const HistoryAndCommentSectionContainer = ({
  reimbursement,
}: HistoryAndCommentSectionContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { timeline, loading } = useReimbursementHistory({
    id: reimbursementId,
  });

  const baseTitle = t('reimbursementView.leftSection.protocol.title');
  const showCount = !loading && timeline.length > 0;
  const countSuffix = showCount ? ` (${timeline.length})` : '';
  const headerTitle = baseTitle + countSuffix;

  const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;

  return (
    <CollapsibleCard
      borderRadius="medium"
      paddingBottom="space10"
      paddingRight="space8"
      maxHeight="85cqh"
      id="history-open"
      headerProps={{
        background: 'gray0',
        borderRadius: 'medium',
      }}
      header={
        <TruncatedText charsAfterEllipsis={charsAfterEllipsis}>
          {headerTitle}
        </TruncatedText>
      }
      defaultOpen>
      <Flex
        background="gray0"
        direction="column"
        overflow="hidden"
        borderRadius="none none medium medium">
        <Box padding="space16" paddingLeft="space18">
          <ReimbursementCommentContainer reimbursement={reimbursement} />
        </Box>
        <Box padding="0 space16 space4 space2">
          <ReimbursementHistory isLoading={loading} timeline={timeline} />
        </Box>
      </Flex>
    </CollapsibleCard>
  );
};
