import { forwardRef } from 'react';
import { DatePicker } from '../../Atoms/DatePicker';
import {
  FieldContainer,
  FieldContainerProps,
} from '../../Atoms/FieldContainer';

export interface DatePickerFieldProps
  extends Omit<FieldContainerProps, 'onChange'> {
  /** Should field receive focus on render? */
  autoFocus?: boolean;
  /** Label for calendar button */
  calendarLabel?: string;
  /** Allow to clear value */
  clearable?: boolean;
  /** message to display in popover of clear button */
  clearLabel?: string;
  /** (Uncontrolled) initial value of the field in ISO 8601 date format */
  defaultValue?: string;
  /** Is field disabled? */
  disabled?: boolean;
  /** Locale to use to format date */
  locale?: string;
  /** Called when the value is changed by the user */
  onChange?: (value: string) => void;
  /** Is field read only? */
  readOnly?: boolean;
  /** (Controlled) value of the field in ISO 8601 date format */
  value?: string;
  /** Label for today button */
  todayLabel?: string;
  /** Label for next month button */
  nextMonthLabel?: string;
  /** Label for previous month button */
  prevMonthLabel?: string;
  /** Show message on focus*/
  showMessageOnFocus?: boolean;
}

/** A field for picking a date */
export const DatePickerField = forwardRef<HTMLDivElement, DatePickerFieldProps>(
  (
    {
      'aria-describedby': ariaDescribedby,
      'aria-invalid': ariaInvalid,
      'aria-labelledby': ariaLabelledby,
      autoFocus,
      calendarLabel,
      clearable,
      clearLabel,
      defaultValue,
      disabled,
      id,
      label,
      locale,
      nextMonthLabel,
      onChange,
      prevMonthLabel,
      readOnly,
      todayLabel,
      value,
      showMessageOnFocus = false,
      ...restProps
    },
    forwardedRef
  ) => {
    return (
      <FieldContainer
        disabled={disabled}
        label={label}
        readOnly={readOnly}
        {...restProps}
        ref={forwardedRef}>
        <DatePicker
          aria-describedby={ariaDescribedby}
          aria-invalid={ariaInvalid}
          aria-labelledby={ariaLabelledby}
          autoFocus={autoFocus}
          calendarLabel={calendarLabel}
          clearable={clearable}
          clearLabel={clearLabel}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          locale={locale}
          nextMonthLabel={nextMonthLabel}
          onChange={onChange}
          prevMonthLabel={prevMonthLabel}
          readOnly={readOnly}
          todayLabel={todayLabel}
          value={value}
          showMessageOnFocus={showMessageOnFocus}
        />
      </FieldContainer>
    );
  }
);
