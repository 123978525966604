import { Box, Button, Card, Grid, Separator } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useStickyCardRefContext } from 'providers/StickyCardRefProvider';
import { useTranslation } from 'react-i18next';
import { ActionRules } from './hooks/useReimbursementBookingsFieldRules';

export type BookingFooterActionsProps = {
  isLoading: boolean;
  errorMessage?: string;
  onAddNewSplit: () => void;
  onAcceptBookings: () => void;
  onCancelClick: () => void;
  actionRules: ActionRules;
};

export const BookingFooterActions = ({
  errorMessage,
  onAddNewSplit,
  onAcceptBookings,
  onCancelClick,
  isLoading,
  actionRules,
}: BookingFooterActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const cardRef = useStickyCardRefContext();

  const hideActions =
    !actionRules.canAddAndDeleteSplits && !actionRules.canAcceptAllSplits;

  if (hideActions) {
    return null;
  }

  return (
    <Card
      bottom={0}
      corners="top"
      position="sticky"
      boxShadow="elevatedShadow4"
      zIndex={10}
      ref={cardRef}>
      <Grid gap="space8">
        {actionRules.canAddAndDeleteSplits && (
          <Grid gap="space8">
            <Button
              onClick={onAddNewSplit}
              icon="plus"
              variant="secondary"
              disabled={isLoading}>
              {t('splitBookings.actions.addNewSplit')}
            </Button>
            <Grid padding="space8">
              <Separator />
            </Grid>
          </Grid>
        )}
        {errorMessage && <Box color="red500">{errorMessage}</Box>}
        {actionRules.canAcceptAllSplits && (
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={onAcceptBookings}>
            {t('splitBookings.actions.acceptSplit')}
          </Button>
        )}
        {actionRules.canAddAndDeleteSplits && (
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={onCancelClick}>
            {t('splitBookings.actions.cancel')}
          </Button>
        )}
      </Grid>
    </Card>
  );
};
