import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { CommentView } from 'components/DocumentHistory/styles';
import { ReimbursementCaseCommentAddedEventDataFragment } from 'generated-types/graphql.types';

export const ReimbursementCaseCommentAdded = ({
  properties,
}: ReimbursementCaseCommentAddedEventDataFragment) => {
  return (
    <TimelineEventWithMobileIndication
      iconName="mobileComment"
      eventComponent={<CommentView>{properties.comment?.text}</CommentView>}
    />
  );
};
