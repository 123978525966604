import { Grid, Text, TruncatedText, Icon } from '@candisio/design-system';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

interface ReimbursementItemProps {
  reimbursementItem: ReimbursementItemListLeftSection;
  isRoundedBorder: boolean;
  onClick: () => void;
}

export const mappedTypesToItems: Record<
  ReimbursementItemType,
  { placeholder: string; icon: JSX.Element }
> = {
  GENERAL_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: (
      <Grid
        background="green100"
        placeContent="center"
        borderRadius="small"
        padding="space4">
        <Icon icon="receipt" size="space18" />
      </Grid>
    ),
  },
  HOSPITALITY_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: (
      <Grid
        background="pink100"
        placeContent="center"
        borderRadius="small"
        paddingLeft="6px"
        padding="space4">
        <Icon icon="hospitality" size="space18" />
      </Grid>
    ),
  },
  MILEAGE: {
    placeholder: '',
    icon: <></>,
  },
  PER_DIEM: {
    placeholder: '',
    icon: <></>,
  },
};

export const ReimbursementItem = ({
  reimbursementItem,
  isRoundedBorder,
  onClick,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { visibleItemId } = useReimbursementFormsContext();

  const { id, type, reason, totalAmount } = reimbursementItem;

  const item = mappedTypesToItems[type];

  const text = reason ? reason : t(item.placeholder);

  const amount = totalAmount ? totalAmount : '-';

  const isItemVisible = visibleItemId === id;

  return (
    <Grid
      padding="space8 space16"
      templateColumns="auto 1fr"
      cursor="pointer"
      alignItems="center"
      borderTopRadius={isRoundedBorder ? 'medium' : undefined}
      onClick={onClick}
      background={isItemVisible ? 'gray250' : 'gray50'}
      hover={{ background: 'gray250' }}>
      <Grid templateColumns="auto 1fr" alignItems="center" gap="space8">
        {item.icon}
        <TruncatedText fontSize="basic">{text}</TruncatedText>
      </Grid>
      <Text justifySelf="right" fontSize="basic">
        {amount}
      </Text>
    </Grid>
  );
};
