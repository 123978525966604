import { Grid, Item, ListView, useTheme } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { VendorsForRecurringPaymentsSortField } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { RecurringPayment } from 'views/CreditCards/hooks/useRecurringPaymentData';
import { CollapsibleHeader } from './CollapsibleHeader';
import { RecurringPaymentItem } from './RecurringPaymentItem';
import { RecurringPaymentsListLoading } from './RecurringPaymentsListLoading';

export const createVendor = 'create_vendor';

interface RecurringPaymentsListProps {
  showRecurringPayments: boolean;
  hasMore: boolean;
  numOfVendorSuggestions: number;
  recurringPaymentsDetails: {
    recurringPayments: RecurringPayment[];
    totalCount: number;
  };
  sortBy: VendorsForRecurringPaymentsSortField;
  isLoading: boolean;
  onEndReached?: () => void;
  onSortClick: (value: Key[]) => void;
  onSetExpandibleCards: (
    toogle: 'vendorSuggestions' | 'recurringPayments'
  ) => void;
}

const MotionGrid = motion(Grid);

const variants = {
  close: {
    display: 'none',
    height: 0,
  },
  open: {
    height: 'auto',
    display: 'block',
  },
};

export const RecurringPaymentsList = ({
  hasMore,
  showRecurringPayments,
  recurringPaymentsDetails,
  numOfVendorSuggestions,
  sortBy,
  isLoading,
  onEndReached,
  onSetExpandibleCards,
  onSortClick,
}: RecurringPaymentsListProps) => {
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  const { colors } = useTheme();
  const { totalCount, recurringPayments } = recurringPaymentsDetails;

  const listHeight = totalCount > 10 ? '400px' : '270px';

  const handleCreatePaymentClick = () => {
    navigate(
      `/${orgId}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.RECURRING_PAYMENTS}/${createVendor}`
    );
  };

  const shouldShowSkeleton = isLoading && recurringPayments.length === 0;

  if (shouldShowSkeleton) {
    return (
      <Grid>
        <CollapsibleHeader
          totalRecurringPayments={totalCount}
          numOfVendorSuggestions={numOfVendorSuggestions}
          showSuggestedVendors={showRecurringPayments}
          sortBy={sortBy}
          isLoading={shouldShowSkeleton}
          onClick={onSetExpandibleCards}
          onCreatePaymentClick={handleCreatePaymentClick}
          onSortClick={onSortClick}
        />
        <RecurringPaymentsListLoading />
      </Grid>
    );
  }

  return (
    <Grid>
      <CollapsibleHeader
        totalRecurringPayments={totalCount}
        numOfVendorSuggestions={numOfVendorSuggestions}
        showSuggestedVendors={showRecurringPayments}
        sortBy={sortBy}
        isLoading={isLoading}
        onClick={onSetExpandibleCards}
        onCreatePaymentClick={handleCreatePaymentClick}
        onSortClick={onSortClick}
      />
      <MotionGrid
        padding={0}
        variants={variants}
        initial={variants.close}
        animate={
          totalCount > 0 && showRecurringPayments
            ? variants.open
            : variants.close
        }>
        <ListView
          style={{ background: colors.gray100 }}
          height={listHeight}
          onEndReached={hasMore ? onEndReached : undefined}
          isVirtualized>
          {recurringPayments.map(itm => (
            <Item textValue={itm.vendor.id} key={itm.vendor.id}>
              <RecurringPaymentItem
                linkedCard={itm.linkedCard}
                vendor={itm.vendor}
              />
            </Item>
          ))}
        </ListView>
      </MotionGrid>
    </Grid>
  );
};
