import isNil from 'lodash/isNil';
import { useSap } from 'orgConfig/sap';
import { useEffect } from 'react';
import { DefaultValues, FieldPath, get, UseFormReturn } from 'react-hook-form';
import { DocumentApprovalFormValues } from '../approvalFormSchema';

interface UseSyncApprovalFormDefaultValuesOptions {
  /**  form default values */
  defaultValues?: DefaultValues<DocumentApprovalFormValues>;
  form: UseFormReturn<DocumentApprovalFormValues>;
}

/**
 * register quantity field since quantity is not in the document form but in the split booking form
 */
export const useSyncApprovalFormDefaultValues = ({
  defaultValues,
  form,
}: UseSyncApprovalFormDefaultValuesOptions) => {
  const { shouldUseSapPurchaseOrder, shouldUsePackageFreight } = useSap();

  useEffect(() => {
    form.formState.defaultValues?.bookings?.forEach((booking, index) => {
      const fieldName =
        `bookings.${index}.quantity` as FieldPath<DocumentApprovalFormValues>;

      const unitPriceFieldName =
        `bookings.${index}.unitPrice` as FieldPath<DocumentApprovalFormValues>;

      const defaultBookingQuantity = get(defaultValues, fieldName);
      const defaultBookingUnitPrice = get(defaultValues, unitPriceFieldName);

      const shouldRegisterQuantityField =
        shouldUseSapPurchaseOrder &&
        !isNil(defaultBookingQuantity) &&
        (isNil(booking?.quantity) ||
          booking?.quantity !== defaultBookingQuantity);

      const shouldRegisterUnitPriceField =
        shouldUseSapPurchaseOrder &&
        !isNil(defaultBookingUnitPrice) &&
        (isNil(booking?.unitPrice) ||
          booking?.unitPrice !== defaultBookingUnitPrice);

      if (shouldRegisterQuantityField) {
        form.register(fieldName);
        form.resetField(fieldName, {
          defaultValue: get(defaultValues, fieldName),
        });
      }

      if (shouldRegisterUnitPriceField) {
        form.register(unitPriceFieldName);
        form.resetField(unitPriceFieldName, {
          defaultValue: defaultBookingUnitPrice,
        });
      }

      const expenseTypeFieldName =
        `bookings.${index}.sapExpenseType` as FieldPath<DocumentApprovalFormValues>;

      const defaultExpenseType = get(defaultValues, expenseTypeFieldName);

      const currentFormValue = form.getValues(expenseTypeFieldName);

      if (shouldUsePackageFreight && defaultExpenseType !== currentFormValue) {
        form.setValue(expenseTypeFieldName, defaultExpenseType);
      }
    });
  }, [defaultValues, form, shouldUsePackageFreight, shouldUseSapPurchaseOrder]);
};
