import { DocumentPreview, DocumentPreviewProps } from './DocumentPreview';
import { useDocumentPreviewData } from './useDocumentPreviewData';

export interface DocumentPreviewContainerProps
  extends Pick<DocumentPreviewProps, 'onClose' | 'onOpen' | 'showPdf'> {
  documentId: string;
}

export const DocumentPreviewContainer = ({
  documentId,
  onClose,
  onOpen,
  showPdf,
}: DocumentPreviewContainerProps) => {
  const { attachments, documentFile, documentLoading, ecmDocumentLoading } =
    useDocumentPreviewData({ documentId });

  return (
    <DocumentPreview
      documentFile={documentFile}
      loading={documentLoading || ecmDocumentLoading}
      attachments={attachments}
      onClose={onClose}
      onOpen={onOpen}
      showPdf={showPdf}
    />
  );
};
