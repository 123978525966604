import { Grid, Text } from '@candisio/design-system';
import {
  EInvoiceError,
  EInvoiceFormat,
  InvalidEInvoiceDetectedEventDataFragment,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

const baseFormatKey =
  'common:document.compact.history.invalidEInvoice.format.type';

const formatKey: Record<string, string> = {
  UNKNOWN: `${baseFormatKey}.UNKNOWN`,
  ZUGFERD_MINIMUM: `${baseFormatKey}.ZUGFERD_MINIMUM`,
  ZUGFERD_BASIC: `${baseFormatKey}.ZUGFERD_BASIC`,
  ZUGFERD_BASIC_WL: `${baseFormatKey}.ZUGFERD_BASIC_WL`,
  ZUGFERD_EN16931: `${baseFormatKey}.ZUGFERD_EN16931`,
  ZUGFERD_EXTENDED: `${baseFormatKey}.ZUGFERD_EXTENDED`,
  ZUGFERD_XRECHNUNG: `${baseFormatKey}.ZUGFERD_XRECHNUNG`,
  XRECHNUNG_CII: `${baseFormatKey}.XRECHNUNG_CII`,
  XRECHNUNG_UBL: `${baseFormatKey}.XRECHNUNG_UBL`,
} satisfies Partial<Record<`${EInvoiceFormat}`, string>>;

export const eInvoiceFormatTranslationKey = (
  format?: string | null,
  fallback?: string | null
): string => {
  if (!format) {
    return fallback ?? formatKey.UNKNOWN;
  }

  return formatKey[format] ?? fallback ?? formatKey.UNKNOWN;
};

const baseErrorKey =
  'common:document.compact.history.invalidEInvoice.error.reason';

const errorKey: Record<string, string> = {
  UNKNOWN: `${baseErrorKey}.UNKNOWN`,
  INVALID_XML: `${baseErrorKey}.INVALID_XML`,
  OUTDATED_FORMAT: `${baseErrorKey}.OUTDATED_FORMAT`,
} satisfies Partial<Record<`${EInvoiceError}` | 'UNKNOWN', string>>;

export const eInvoiceErrorTranslationKey = (
  error?: EInvoiceError | null
): string => {
  if (!error) {
    return errorKey.UNKNOWN;
  }

  return errorKey[error] ?? errorKey.UNKNOWN;
};

export const InvalidEInvoiceDetectedComponent = ({
  eInvoiceError,
  eInvoiceFormat,
}: InvalidEInvoiceDetectedEventDataFragment) => {
  const [t] = useTranslation();

  const format = eInvoiceFormatTranslationKey(eInvoiceFormat);
  const error = eInvoiceErrorTranslationKey(eInvoiceError);

  return (
    <Grid gap="space8">
      <Text>{t('document.compact.history.invalidEInvoice.title')}</Text>
      <Grid>
        <Text color="gray500">
          {t('document.compact.history.invalidEInvoice.format.title')}
        </Text>
        <Text>{t(format)}</Text>
      </Grid>
      <Grid>
        <Text color="gray500">
          {t('document.compact.history.invalidEInvoice.error.title')}
        </Text>
        <Text>{t(error)}</Text>
      </Grid>
    </Grid>
  );
};
