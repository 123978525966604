import { Box, Card, Grid, GridProps, Icon } from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { ComponentProps, ReactNode, useCallback } from 'react';

const StyledHeader = ({
  children,
  hoverColor,
  isOpen,
  ...rest
}: ComponentProps<typeof Grid> & { isOpen: boolean }) => (
  <Grid
    autoFlow="column"
    gap="space8"
    justifyContent="start"
    alignItems="center"
    templateColumns="auto 1fr"
    fontWeight="bold"
    fontSize="basic"
    height="44px"
    paddingX="space16"
    cursor="pointer"
    borderBottomRadius={isOpen ? 'none' : 'medium'}
    hover={{ background: hoverColor || 'gray100' }}
    {...rest}>
    {children}
  </Grid>
);

export interface CollapsibleCardProps extends ComponentProps<typeof Card> {
  id: string;
  children: ReactNode;
  header: ReactNode;
  defaultOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  headerProps?: Omit<GridProps, 'as'> & { hoverColor?: string };
}

export const CollapsibleCard = ({
  children,
  header,
  id,
  onOpenChange,
  headerProps,
  defaultOpen = false,
  ...rest
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useLocalStorage(id, defaultOpen);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  return (
    <Grid
      overflow="hidden"
      rowGap={isOpen ? '1px' : 'unset'}
      templateRows={isOpen ? 'auto 1fr' : 'auto 0'}
      {...rest}>
      <StyledHeader
        onClick={toggleIsOpen}
        data-isopen={isOpen}
        isOpen={isOpen}
        {...headerProps}>
        <Icon size="space16" icon={isOpen ? 'caretDown' : 'caretRight'} />
        {header}
      </StyledHeader>
      <Box overflow="auto">{children}</Box>
    </Grid>
  );
};
