import { Grid } from '@candisio/design-system';
import {
  ConfirmationPopover,
  ConfirmationPopoverProps,
} from 'components/ConfirmationPopover/ConfirmationPopover';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface AcknowledgePopoverProps extends ConfirmationPopoverProps {
  acknowledgeText: string;
}

const schema = z.object({
  confirm: z.literal(true),
});

type AcknowledgeFormValues = z.infer<typeof schema>;

const errorMessages = {
  confirm: {
    label: 'confirmation',
  },
};

export const AcknowledgePopover = forwardRef<
  HTMLDivElement,
  AcknowledgePopoverProps
>(
  (
    {
      children,
      acknowledgeText,
      closeButtonProps,
      closeText,
      confirmButtonProps,
      confirmText,
      onConfirm,
      title,
      onClose,
      ...restProps
    },
    ref
  ) => {
    const form = useForm<AcknowledgeFormValues>({
      mode: 'onSubmit',
      resolver: zodResolver({
        errorMessages,
        zodSchema: schema,
      }),
    });

    return (
      <FormProvider<AcknowledgeFormValues> {...form}>
        <Grid as="form">
          <ConfirmationPopover
            {...restProps}
            ref={ref}
            title={title}
            onConfirm={async () => {
              await form
                .trigger(['confirm'], { shouldFocus: true })
                .then(isValid => {
                  if (isValid) {
                    form.clearErrors();
                    onConfirm?.();
                  }
                });
            }}
            closeText={closeText}
            onClose={onClose}
            closeButtonProps={closeButtonProps}
            confirmText={confirmText}
            confirmButtonProps={confirmButtonProps}>
            <HookFormCheckboxField
              name="confirm"
              label={acknowledgeText}
              message=""
            />
          </ConfirmationPopover>
        </Grid>
      </FormProvider>
    );
  }
);
