import { DocumentTags } from 'views/Settings/DocumentTags/toolkit/hooks/useDocumentTags';
import {
  MAX_DOCUMENT_TAG_NAME_LENGTH,
  MIN_DOCUMENT_TAG_NAME_LENGTH,
} from 'views/Settings/DocumentTags/toolkit/utils';
import { z } from 'zod';

import { CUSTOM_NAME } from './MergeTagsForm';

const duplicateNameIssue = {
  code: z.ZodIssueCode.custom,
  path: ['customName'],
  params: {
    translationKey: 'documentTags.drawer.toast.duplicateName',
  },
};

const defaultValues = {
  tags: [],
  nameValidation: () => Promise.resolve(true),
};

export const mergeTagsFormSchema = ({
  tags,
  nameValidation,
}: MergeTagsFormSchemaOptions = defaultValues) =>
  z
    .object({
      targetTagId: z.string(),
      customName: z
        .string()
        .trim()
        .min(MIN_DOCUMENT_TAG_NAME_LENGTH)
        .max(MAX_DOCUMENT_TAG_NAME_LENGTH)
        .nullable(),
    })
    .refine(async ({ targetTagId, customName }) => {
      if (targetTagId !== CUSTOM_NAME) return true;
      if (customName === null) return true;

      const isLocalDuplicate = tags.some(({ name }) => name === customName);
      if (isLocalDuplicate) return false;

      return nameValidation(customName);
    }, duplicateNameIssue);

export type MergeTagsFormOutput = z.infer<
  ReturnType<typeof mergeTagsFormSchema>
>;

export interface MergeTagsFormSchemaOptions {
  tags: DocumentTags;
  nameValidation: (name: string) => Promise<boolean>;
}
