import { useGetUserInvolvementForEcmDocumentsLazyQuery } from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';

interface UseEcmDocumentsInvolvementCountOptions {
  membershipId: string;
}

export const useEcmDocumentsInvolvementCount = ({
  membershipId,
}: UseEcmDocumentsInvolvementCountOptions) => {
  const [
    fetchEcmDocumentsInvolvement,
    { data: currentData, loading, previousData },
  ] = useGetUserInvolvementForEcmDocumentsLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
  });

  useFetchOnWindowFocus({
    fetch: fetchEcmDocumentsInvolvement,
  });

  const data = !loading
    ? currentData?.getUserInvolvementForEcmDocuments
    : previousData?.getUserInvolvementForEcmDocuments;

  const responsiblePersonCount = data?.responsiblePerson.count ?? 0;

  const notifyPersonCount = data?.notifyPerson.count ?? 0;

  const hasEcmInvolvement = responsiblePersonCount > 0 || notifyPersonCount > 0;

  return {
    hasEcmInvolvement,
    notifyPersonCount,
    responsiblePersonCount,
    ecmDocumentsInvolvementLoading:
      loading && typeof previousData === 'undefined',
  };
};
