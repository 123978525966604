import {
  Grid,
  Icon,
  IconProps,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { StatusTag } from 'components/StatusTag';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { EInvoiceInfoIcon } from 'views/Inbox/DocumentProcessing/components/EInvoiceInfoIcon';
import { DocumentsTableData, Status } from '../types';

interface StatusIconProps extends IconProps {
  label: string;
  hasTooltip?: boolean;
}

export const StatusIcon = ({
  color,
  icon,
  label,
  hasTooltip = true,
}: StatusIconProps) => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  return (
    <>
      <Icon
        {...(hasTooltip && triggerProps)}
        aria-label={label}
        ref={triggerRef}
        icon={icon}
        color={color}
        size="space16"
      />
      {hasTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {label}
        </Tooltip>
      )}
    </>
  );
};

const StatusIcons = ({
  isOverdue,
  isDuplicate,
  isWaitingForClarification,
  hasAttachments,
  isEInvoice,
}: {
  isOverdue?: boolean;
  isDuplicate?: boolean;
  isWaitingForClarification?: boolean;
  hasAttachments?: boolean;
  isEInvoice?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const [visualiseWaitingForClarificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.visualiseWaitingForClarification,
  ]);

  if (
    !isOverdue &&
    !isDuplicate &&
    !isWaitingForClarification &&
    !hasAttachments &&
    !isEInvoice
  ) {
    return null;
  }

  return (
    <Grid autoFlow="column">
      {isOverdue && (
        <StatusIcon label={t('statuses.overdue')} icon="bell" color="red500" />
      )}
      {visualiseWaitingForClarificationFF && isWaitingForClarification && (
        <StatusIcon
          label={t('statuses.waitingForClarification')}
          icon="mail"
          color="gray800"
        />
      )}
      {isDuplicate && (
        <StatusIcon
          label={t('statuses.duplicate')}
          icon="duplicate"
          color="yellow500"
        />
      )}
      {hasAttachments && (
        <StatusIcon
          label={t('attachments')}
          icon="attachment"
          color="gray600"
        />
      )}
      <EInvoiceInfoIcon isEInvoice={isEInvoice} />
    </Grid>
  );
};

type CellValue = Status | undefined;

export const StatusCell = ({
  value,
}: CellProps<DocumentsTableData, CellValue>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  if (!value) return <Text>{t('emptyCellPlaceholder')}</Text>;

  const {
    isOverdue,
    isDuplicate,
    isWaitingForClarification,
    hasAttachments,
    status,
    isEInvoice,
  } = value;

  return (
    <Grid
      alignItems="center"
      autoFlow="column"
      justifyContent="start"
      gap="space4">
      <StatusTag status={status} />
      <StatusIcons
        isOverdue={isOverdue}
        isDuplicate={isDuplicate}
        isWaitingForClarification={isWaitingForClarification}
        hasAttachments={hasAttachments}
        isEInvoice={isEInvoice}
      />
    </Grid>
  );
};
