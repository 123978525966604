import { Grid, Separator, Text, TruncatedText } from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { some } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useReimbursementSplitBookingsData } from 'views/Reimbursement/hooks/useReimbursementSplitBookingsData';
import { ReimbursementItemSplitBookingsList } from './ReimbursementItemSplitBookingsList';

export interface ReimbursementItemsSplitBookingsContainerProps {
  isLoading: boolean;
  status?: ReimbursementCaseStatus;
}

const mappedTypesToItem: Record<ReimbursementItemType, string> = {
  GENERAL_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.general',
  HOSPITALITY_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
  MILEAGE: '',
  PER_DIEM: '',
};

export const ReimbursementItemsSplitBookingsContainer = ({
  isLoading,
  status,
}: ReimbursementItemsSplitBookingsContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { reimbursementItemSplitBookings, loadingSplits } =
    useReimbursementSplitBookingsData();

  const reimbursementItemSplitData = useMemo(
    () => reimbursementItemSplitBookings,
    [reimbursementItemSplitBookings]
  );

  const isSplitLoading = isLoading || loadingSplits;

  const hasReimbursementItemBookings = some(
    reimbursementItemSplitData,
    entry => entry.bookings.length > 0
  );

  return (
    <Grid gap="space8">
      {hasReimbursementItemBookings && (
        <Grid templateColumns="auto 1fr" alignItems="center" gap="space8">
          <Text fontSize="basic" fontWeight="semibold">
            {t('splitBookings.listTitle')}
          </Text>
          <Separator />
        </Grid>
      )}
      <Grid gap="space16">
        {reimbursementItemSplitData.map((entry, entryIndex) => {
          const { title, bookings, totalAmount, currency, type, id } =
            entry ?? {};

          const expense = mappedTypesToItem[type];
          const titleData = title ? title : t(expense);

          return (
            !!bookings?.length && (
              <Grid key={`bookings-${id}`}>
                <TruncatedText color="gray600">{titleData}</TruncatedText>
                <ReimbursementItemSplitBookingsList
                  reimbursementItemId={id}
                  title={titleData}
                  isLoading={isSplitLoading}
                  bookings={bookings}
                  entryIndex={entryIndex}
                  currency={currency ?? DocumentCurrency.Eur}
                  totalAmount={totalAmount ?? 0}
                  status={status}
                />
              </Grid>
            )
          );
        })}
      </Grid>
    </Grid>
  );
};
