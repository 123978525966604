import { useOrganizationReimbursementInsightsDetailsQuery } from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useOrganizationId } from 'providers/OrganizationProvider';

export const useOrganizationReimbursementInsightsData = () => {
  const { canUseReimbursement } = useReimbursement();
  const organizationId = useOrganizationId();

  const ids = organizationId ? [organizationId] : undefined;

  const { data, loading } = useOrganizationReimbursementInsightsDetailsQuery({
    variables: {
      ids,
    },
    skip: !canUseReimbursement || !ids,
  });

  const reimbursementInsightsDetails = (
    data?.organizationReimbursementInsightsDetails ?? []
  )?.find(({ organization }) => organization?.id === organizationId);

  const reimbursementCases =
    reimbursementInsightsDetails?.reimbursementCases ?? {};

  const reimbursementMembershipId =
    reimbursementInsightsDetails?.membership?.id ?? '';

  const toApproveTotal = reimbursementCases?.toApprove?.total ?? 0;
  const toCheckTotal = reimbursementCases?.toCheck?.total ?? 0;
  const toExportTotal = reimbursementCases?.toExport?.total ?? 0;
  const toReviewTotal = reimbursementCases?.toReview?.total ?? 0;

  return {
    loadingReimbursementTasks: loading,
    reimbursementMembershipId,
    toApproveTotal,
    toCheckTotal,
    toExportTotal,
    toReviewTotal,
  };
};
