import { IconProps } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type NavIconProps = {
  icon: IconProps['icon'];
  label?: string;
  route?: string;
  count?: number;
  countLoading?: boolean;
  showMenuIcon?: boolean;
  onClick?: () => void;
  pulseIndicator?: ReactNode;
};

type NavIconPropsReturnType = {
  icons: NavIconProps[];
  intercomIcon: NavIconProps;
  notificationsIcon: NavIconProps;
};

export const useNavigationIcons = (): NavIconPropsReturnType => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const currentUser = useCurrentUser();
  const { isAdmin, isAccountant, isRequester, hasRoles } = useUserRoles();

  const initialIcons: NavIconProps[] = useMemo(
    () => [
      {
        icon: 'menuDashboard',
        label: t('menuLabels.dashboard'),
        route: Routes.DASHBOARD,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuUpload',
        label: t('menuLabels.upload'),
        showMenuIcon: isAdmin || isRequester || isAccountant,
      },
      {
        icon: 'menuInbox',
        label: t('menuLabels.inbox'),
        route: Routes.INBOX,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuApprovals',
        label: t('menuLabels.approvals'),
        route: `${Routes.APPROVALS}?approvers=${currentUser?.id}`,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuPayments',
        label: t('menuLabels.payments'),
        route: Routes.PAYMENTS,
      },
      {
        icon: 'menuExport',
        label: t('menuLabels.export'),
        showMenuIcon: isAccountant || isAdmin,
      },
      {
        icon: 'menuArchive',
        label: t('menuLabels.archive'),
        route: Routes.ARCHIVE,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuTravel',
        label: t('menuLabels.travelAndExpense'),
        route: Routes.REIMBURSEMENTS_DASHBOARD,
      },
      {
        icon: 'menuCreditCards',
        label: t('menuLabels.creditCards'),
      },
      {
        icon: 'menuContactsAlt',
        label: t('menuLabels.contacts'),
        route: Routes.CONTACTS,
        showMenuIcon: isAccountant || isAdmin || isRequester,
      },
    ],
    [currentUser?.id, hasRoles, isAccountant, isAdmin, isRequester, t]
  );

  const intercomIcon: NavIconProps = useMemo(
    () => ({
      icon: 'menuIntercomAlternative',
      label: t('menuLabels.support'),
      showMenuIcon: hasRoles,
    }),
    [t, hasRoles]
  );

  const notificationsIcon: NavIconProps = useMemo(
    () => ({
      icon: 'menuNotifications',
      label: t('menuLabels.notifications'),
      showMenuIcon: hasRoles,
    }),
    [t, hasRoles]
  );

  return {
    icons: initialIcons,
    intercomIcon,
    notificationsIcon,
  };
};
