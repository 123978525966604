import { defaultTheme } from '@candisio/design-system';
import { Item } from 'components/History/items/styles';
import { hoverScrollbar } from 'styles/scrollbar';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const HistoryLayout = styled.section`
  // Document history is an exception from general styles and has smaller spaces and font

  line-height: 1rem;
  font-weight: normal;
  padding: 0;
  margin: 0;
  color: ${defaultTheme.colors.gray800};
  font-size: ${defaultTheme.fontSizes.small};

  ${Item}.ant-timeline-item {
    padding: 0.6rem 0.3125rem;
  }

  a {
    font-size: ${defaultTheme.fontSizes.small};
    & svg {
      width: ${defaultTheme.space.space14};
      height: ${defaultTheme.space.space14};
    }
  }

  ${hoverScrollbar}
`;
