import { Link, Text } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useBannerLinks } from '../utils';
import { AutoMatchBannerWrapper } from './AutoMatchBannerWrapper';

type UserType = 'approver' | 'adminRequester';

export const AutoMatchBanner = ({
  autoMatchCount,
}: {
  autoMatchCount: number;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const orgSlug = useOrganizationId() as string;
  const { isOnlyApprover } = useUserRoles();

  const { autoMatchTransactionsLink } = useBannerLinks(orgSlug);

  const autoMatchText =
    autoMatchCount === 1
      ? t('autoMatchBanner.oneTransaction')
      : t('autoMatchBanner.moreTransaction');

  const bannerBody: Record<UserType, ReactNode> = {
    approver: (
      <Text fontSize="basic">
        <Trans
          t={t}
          i18nKey="autoMatchBanner.approvers.desc"
          values={{ autoMatchCount }}>
          8 {autoMatchText} in den letzen 7 Tagen.
          <Link
            iconPosition="right"
            fontSize="basic"
            href={autoMatchTransactionsLink()}
            external>
            {t('autoMatchBanner.archiveTxLinkText')}
          </Link>
          Du mußt nichts mehr tun!
        </Trans>
      </Text>
    ),
    adminRequester: (
      <Text fontSize="basic">
        <Trans
          t={t}
          i18nKey="autoMatchBanner.adminAndRequesters.desc"
          values={{ autoMatchCount }}>
          8 {autoMatchText} in den letzen 7 Tagen.
          <Link
            iconPosition="right"
            href={autoMatchTransactionsLink()}
            external>
            {t('autoMatchBanner.archiveTxLinkText')}
          </Link>
        </Trans>
      </Text>
    ),
  };

  const userType: UserType = isOnlyApprover ? 'approver' : 'adminRequester';

  return (
    <AutoMatchBannerWrapper>{bannerBody[userType]}</AutoMatchBannerWrapper>
  );
};
