import { Node } from '@react-types/shared';
import { FC, Fragment } from 'react';
import { ListState } from 'react-stately';
import { LayoutProps } from '../../../types';
import { Box } from '../../Box';
import { Option } from '../../Option';

interface GroupedItemContentProps {
  index: number;
  groupIdx: number;
  groupCounts: number[];
  options: Node<any>[];
  state: ListState<any>;
  itemHeight: LayoutProps['height'];
  showSeparator: boolean;
  separatorStyle: string;
}

const getLastItemIndexInGroup = (groupIdx: number, groupCounts: number[]) => {
  const groupItemsTotal = groupCounts
    .slice(0, groupIdx + 1)
    .reduce((acc, count) => acc + count, 0);

  return groupItemsTotal - 1;
};

export const GroupedItemContent: FC<GroupedItemContentProps> = ({
  index,
  groupIdx,
  groupCounts,
  options,
  state,
  itemHeight,
  showSeparator,
  separatorStyle,
}) => {
  const isLastGroup = groupIdx === groupCounts.length - 1;
  const isLastItemInGroup =
    index === getLastItemIndexInGroup(groupIdx, groupCounts);

  const shouldShowSeparator = showSeparator && !isLastItemInGroup;
  const shouldAddGap = isLastItemInGroup && !isLastGroup;

  return (
    <Fragment key={options[index].key}>
      <Box borderBottom={shouldShowSeparator ? separatorStyle : undefined}>
        <Option item={options[index]} state={state} height={itemHeight} />
      </Box>
      {shouldAddGap && <Box height="space16" />}
    </Fragment>
  );
};
