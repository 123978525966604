import { Button, ButtonProps } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  SapB1ImportErrorCode,
  useImportSapB1ContactsMutation,
  useImportSapB1CostObjectsMutation,
  useImportSapB1GeneralLedgerAccountsMutation,
  useImportSapB1TaxCodesMutation,
  useImportSapB1PurchaseOrdersV2Mutation,
  useImportSapB1GoodsReceiptsV2Mutation,
} from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { prefix } from 'utils/localStorage';

const SKIP_SAP_SYNC_TIMER_LS_KEY = 'SKIP_SAP_SYNC_TIMER';

export enum SAP_SYNC {
  GeneralLedgerAccounts = 'GeneralLedgerAccounts',
  TaxCodes = 'TaxCodes',
  CostObjects = 'CostObjects',
  Contacts = 'Contacts',
  ContactsProcessing = 'ContactsProcessing',
  PaymentConditions = 'PaymentConditions',
  PurchaseOrders = 'PurchaseOrders',
  PurchaseOrdersProcessing = 'PurchaseOrdersProcessing',
  GoodsReceipts = 'GoodsReceipts',
  PurchaseOrdersWithFilters = 'PurchaseOrdersWithFilters',
}

type SyncFromSapProps = {
  type: SAP_SYNC;
  btnProps?: ButtonProps;
  accountsPayableNumber?: string;
  orderNumber?: string;
};

type SyncUtilProps = {
  localStorageKey: string;
  variant: ButtonProps['variant'];
  translationKeys: {
    button: string;
    toast: string;
  };
  onSync: (
    toastMessage: string,
    setLastSyncTime: (val: string) => void,
    isSyncRunning: boolean,
    mutationFilters?: { accountsPayableNumber?: string; orderNumber?: string }
  ) => void;
};

const apiErrorMap: Record<SapB1ImportErrorCode, string> = {
  [SapB1ImportErrorCode.AuthError]: 'sync.apiErrors.authError',
  [SapB1ImportErrorCode.UnexpectedError]: 'sync.apiErrors.unexpectedError',
  [SapB1ImportErrorCode.BadRequest]: 'sync.apiErrors.badRequest',
};

export const SyncFromSap = ({
  type,
  btnProps,
  orderNumber,
  accountsPayableNumber,
}: SyncFromSapProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const { error, info, warning } = useToastMessage();
  const orgId = useOrganizationId();

  const [importSapB1Contacts, { loading: loadingContacts }] =
    useImportSapB1ContactsMutation();

  const [importSapB1PurchaseOrdersV2, { loading: loadingPurchaseOrders }] =
    useImportSapB1PurchaseOrdersV2Mutation();

  const [importSapB1CostObjects, { loading: loadingCostObjects }] =
    useImportSapB1CostObjectsMutation();

  const [
    importSapB1GeneralLedgerAccounts,
    { loading: loadingGeneralLedgerAccounts },
  ] = useImportSapB1GeneralLedgerAccountsMutation();

  const [importSapB1TaxCodes, { loading: loadingTaxCodes }] =
    useImportSapB1TaxCodesMutation();

  const [importSapB1GoodsReceipts, { loading: loadingGoodsReceipts }] =
    useImportSapB1GoodsReceiptsV2Mutation();

  const getLocalStorageKey = (type: SAP_SYNC) => {
    return `${prefix}_${orgId}_sap_sync_${type}`;
  };

  const handleError = async (code: SapB1ImportErrorCode) => {
    error(t(apiErrorMap[code]));
  };

  const handleStartSync = async (toastMessage: string) => {
    info(toastMessage);
  };

  const handleWaitBeforeResync = async () => {
    warning(t('sync.waitBeforeResync'));
  };

  const purchaseOrderSyncUtils: SyncUtilProps = {
    localStorageKey: getLocalStorageKey(SAP_SYNC.PurchaseOrders),
    variant: 'tertiary',
    translationKeys: {
      button: 'sync.purchaseOrders.button',
      toast: 'sync.purchaseOrders.message',
    },
    onSync: async (
      toastMessage,
      setLastSyncTime,
      isSyncRunning,
      mutationFilters
    ) => {
      if (isSyncRunning) {
        void handleWaitBeforeResync();

        return;
      }

      void handleStartSync(toastMessage);
      const { data } = await importSapB1PurchaseOrdersV2({
        variables: { importFilters: mutationFilters },
      });

      if (
        data?.importSapB1PurchaseOrdersV2?.__typename === 'SapB1ImportResponse'
      ) {
        void handleError(data?.importSapB1PurchaseOrdersV2?.code);
      } else {
        setLastSyncTime('' + +new Date());
      }
    },
  };

  const syncMap: Record<SAP_SYNC, SyncUtilProps> = {
    [SAP_SYNC.ContactsProcessing]: {
      localStorageKey: getLocalStorageKey(SAP_SYNC.Contacts),
      variant: 'tertiary',
      translationKeys: {
        button: 'sync.contacts.button',
        toast: 'sync.contacts.message',
      },
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        if (isSyncRunning) {
          void handleWaitBeforeResync();

          return;
        }

        void handleStartSync(toastMessage);
        const { data } = await importSapB1Contacts();
        if (data?.importSapB1Contacts?.code) {
          void handleError(data?.importSapB1Contacts?.code);
        } else {
          setLastSyncTime('' + +new Date());
        }
      },
    },
    [SAP_SYNC.Contacts]: {
      localStorageKey: getLocalStorageKey(SAP_SYNC.Contacts),
      variant: 'tertiary',
      translationKeys: {
        button: 'sync.contacts.button',
        toast: 'sync.contacts.message',
      },
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        if (isSyncRunning) {
          void handleWaitBeforeResync();

          return;
        }

        void handleStartSync(toastMessage);
        const { data } = await importSapB1Contacts();
        if (data?.importSapB1Contacts?.code) {
          void handleError(data?.importSapB1Contacts?.code);
        } else {
          setLastSyncTime('' + +new Date());
        }
      },
    },
    [SAP_SYNC.PaymentConditions]: {
      localStorageKey: getLocalStorageKey(SAP_SYNC.PaymentConditions),
      variant: 'tertiary',
      translationKeys: {
        button: 'sync.paymentConditions.button',
        toast: 'sync.paymentConditions.message',
      },
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        if (isSyncRunning) {
          void handleWaitBeforeResync();

          return;
        }

        void handleStartSync(toastMessage);
        const { data } = await importSapB1Contacts();
        if (data?.importSapB1Contacts?.code) {
          void handleError(data?.importSapB1Contacts?.code);
        } else {
          setLastSyncTime('' + +new Date());
        }
      },
    },
    [SAP_SYNC.PurchaseOrders]: {
      ...purchaseOrderSyncUtils,
      variant: 'tertiary',
    },
    [SAP_SYNC.PurchaseOrdersProcessing]: {
      ...purchaseOrderSyncUtils,
      variant: 'tertiary',
    },
    [SAP_SYNC.GeneralLedgerAccounts]: {
      localStorageKey: getLocalStorageKey(SAP_SYNC.GeneralLedgerAccounts),
      variant: 'tertiary',
      translationKeys: {
        button: 'sync.generalLedgerAccounts.button',
        toast: 'sync.generalLedgerAccounts.message',
      },
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        if (isSyncRunning) {
          void handleWaitBeforeResync();

          return;
        }

        void handleStartSync(toastMessage);
        const { data } = await importSapB1GeneralLedgerAccounts();
        if (data?.importSapB1GeneralLedgerAccounts?.code) {
          void handleError(data?.importSapB1GeneralLedgerAccounts?.code);
        } else {
          setLastSyncTime('' + +new Date());
        }
      },
    },
    [SAP_SYNC.TaxCodes]: {
      localStorageKey: getLocalStorageKey(SAP_SYNC.TaxCodes),
      variant: 'tertiary',
      translationKeys: {
        button: 'sync.taxCodes.button',
        toast: 'sync.taxCodes.message',
      },
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        if (isSyncRunning) {
          void handleWaitBeforeResync();

          return;
        }

        void handleStartSync(toastMessage);
        const { data } = await importSapB1TaxCodes();
        if (data?.importSapB1TaxCodes?.code) {
          void handleError(data?.importSapB1TaxCodes?.code);
        } else {
          setLastSyncTime('' + +new Date());
        }
      },
    },
    [SAP_SYNC.CostObjects]: {
      localStorageKey: getLocalStorageKey(SAP_SYNC.CostObjects),
      variant: 'tertiary',
      translationKeys: {
        button: 'sync.costCenters.button',
        toast: 'sync.costCenters.message',
      },
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        if (isSyncRunning) {
          void handleWaitBeforeResync();

          return;
        }

        void handleStartSync(toastMessage);
        const { data } = await importSapB1CostObjects();
        if (data?.importSapB1CostObjects?.code) {
          void handleError(data?.importSapB1CostObjects?.code);
        } else {
          setLastSyncTime('' + +new Date());
        }
      },
    },
    [SAP_SYNC.GoodsReceipts]: {
      localStorageKey: getLocalStorageKey(SAP_SYNC.GoodsReceipts),
      variant: 'tertiary',
      translationKeys: {
        button: 'sync.goodsReceipts.button',
        toast: 'sync.goodsReceipts.message',
      },
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        if (isSyncRunning) {
          void handleWaitBeforeResync();

          return;
        }

        void handleStartSync(toastMessage);
        const { data } = await importSapB1GoodsReceipts();
        if (
          data?.importSapB1GoodsReceiptsV2?.__typename === 'SapB1ImportResponse'
        ) {
          void handleError(data?.importSapB1GoodsReceiptsV2?.code);
        } else {
          setLastSyncTime('' + +new Date());
        }
      },
    },
    [SAP_SYNC.PurchaseOrdersWithFilters]: {
      ...purchaseOrderSyncUtils,
      variant: 'tertiary',
      onSync: async (toastMessage, setLastSyncTime, isSyncRunning) => {
        const mutationFilters = { accountsPayableNumber, orderNumber };
        purchaseOrderSyncUtils.onSync(
          toastMessage,
          setLastSyncTime,
          isSyncRunning,
          mutationFilters
        );
      },
    },
  };

  const { onSync, translationKeys, variant, localStorageKey } = syncMap[type];

  const [lastSyncTime, setLastSyncTime] = useLocalStorage(localStorageKey, '');

  const currentTime = +new Date();
  const timeSyncIsRunning = 1000 * 60 * 5; // 5 minutes

  const skipSyncTimer =
    localStorage.getItem(SKIP_SAP_SYNC_TIMER_LS_KEY) === 'true';

  const recentlySynced = lastSyncTime
    ? +lastSyncTime > currentTime - timeSyncIsRunning
    : false;

  const isSyncRunning =
    !skipSyncTimer &&
    (recentlySynced ||
      loadingContacts ||
      loadingPurchaseOrders ||
      loadingCostObjects ||
      loadingGeneralLedgerAccounts ||
      loadingTaxCodes ||
      loadingGoodsReceipts);

  return (
    <Button
      {...btnProps}
      variant={btnProps?.variant ?? variant}
      justifySelf={btnProps?.justifySelf ?? 'end'}
      onClick={() =>
        onSync(t(translationKeys.toast), setLastSyncTime, isSyncRunning)
      }>
      {t(translationKeys.button)}
    </Button>
  );
};
