import { Grid } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  PromotionTrackingEvents,
  PromotionFeatures,
} from 'providers/AnalyticsProvider/events/MobileAppPromotionTrackingEvents';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { usePrivacyContext } from 'providers/PrivacyConsentProvider/PrivacyConsentProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { AuthService } from 'services/AuthService';
// TODO: Move the files to components/NavigationSidebar/UserProfile after removing new navigation FF
import { profileMenuLinks } from 'views/AppContainer/components/Header/components/UserProfile/profileMenuLinks';
import { UserMenuItem } from '../UserMenuItem/UserMenuItem';

type UserProfileMenuPopoverType = {
  closeUserProfileMenu: () => void;
  openMobilAppPromotionModal: () => void;
  openLegalModal: () => void;
};

export const UserProfileMenuPopover = ({
  closeUserProfileMenu,
  openMobilAppPromotionModal,
  openLegalModal,
}: UserProfileMenuPopoverType) => {
  const [t] = useTranslation();
  const currentUser = useCurrentUser();
  const creditCardsSetup = useCreditCardsSetup();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const { search } = useLocation();
  const { track } = useAnalytics();
  const { showConsentManager } = usePrivacyContext();

  const logout = useCallback(() => {
    void AuthService.logout();
  }, []);

  const faqUrl = t('header.profile.menu.items.faq.url');

  return (
    <Grid gap="space12" width="345px" padding="space12 0">
      <Grid gap="space4" padding="0 space8">
        {profileMenuLinks.map(
          ({
            description,
            title,
            icon,
            url,
            hash,
            saveQuery,
            dataTestId,
            allowedRoles,
          }) => (
            <UserRoleAccessWrapper key={title} allowedRoles={allowedRoles}>
              <UserMenuItem
                onClick={closeUserProfileMenu}
                icon={icon}
                dataTestId={dataTestId}
                description={description ? t(description) : undefined}
                routerLink={{
                  pathname: url && `/${organizationSlug}${url}`,
                  search: saveQuery ? search : undefined,
                  hash,
                }}
                title={t(title)}
              />
            </UserRoleAccessWrapper>
          )
        )}
        <UserMenuItem
          icon="mobile"
          onClick={() => {
            closeUserProfileMenu();
            track(PromotionTrackingEvents.ADOPTION_MODAL_OPENED, {
              feature: PromotionFeatures.MOBILE_APP,
            });
            openMobilAppPromotionModal();
          }}
          title={t('header.profile.menu.items.mobile.title')}
          description={t('header.profile.menu.items.mobile.description')}
        />
        <UserMenuItem
          icon="gift"
          backgroundColor="bluebg"
          description={t('header.profile.menu.items.referral.description')}
          onClick={() => {
            closeUserProfileMenu();
            window.open(
              t('header.profile.menu.items.referral.link', {
                email: currentUser?.email,
              }),
              '_blank'
            );
          }}
          title={t('header.profile.menu.items.referral.title')}
        />
      </Grid>
      <Grid
        borderTop="1px solid gray200"
        borderBottom="1px solid gray200"
        padding="space12"
        gap="space4">
        <UserMenuItem
          onClick={() => {
            closeUserProfileMenu();
            showConsentManager();
          }}
          title={t('header.profile.menu.items.privacy.title')}
        />
        {creditCardsSetup.isInUse && (
          <UserMenuItem
            onClick={() => {
              openLegalModal();
              closeUserProfileMenu();
            }}
            title={t('header.profile.menu.items.legalDocuments.title')}
          />
        )}
        <UserMenuItem
          onClick={() => {
            closeUserProfileMenu();
            window.open(t('header.profile.menu.items.support.url'), '_blank');
          }}
          title={t('header.profile.menu.items.support.title')}
        />
        {creditCardsSetup.isInUse && (
          <UserMenuItem
            onClick={() => {
              closeUserProfileMenu();
              window.open(faqUrl, '_blank');
            }}
            title={t('header.profile.menu.items.faq.title')}
          />
        )}
      </Grid>
      <Grid paddingX="space12">
        <UserMenuItem
          onClick={logout}
          title={t('header.profile.menu.logout')}
          dataTestId="profile-nav-logout"
        />
      </Grid>
    </Grid>
  );
};
