import { FeatureBenefit } from './components/PromotionCard';

export const contractBenefits: FeatureBenefit[] = [
  {
    icon: 'creditCardOutline',
    heading: 'promo.contractTable.modal.benefit1.heading',
    description: 'promo.contractTable.modal.benefit1.description',
    isUpcoming: false,
  },
  {
    icon: 'bell',
    heading: 'promo.contractTable.modal.benefit2.heading',
    description: 'promo.contractTable.modal.benefit2.description',
    isUpcoming: false,
  },
  {
    icon: 'copy',
    heading: 'promo.contractTable.modal.benefit3.heading',
    description: 'promo.contractTable.modal.benefit3.description',
    isUpcoming: true,
  },
  {
    icon: 'linkOutlined',
    heading: 'promo.contractTable.modal.benefit4.heading',
    description: 'promo.contractTable.modal.benefit4.description',
    isUpcoming: false,
  },
] as const;
