import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { UNTAGGED_ID } from 'components/DocumentsTable/constants';
import { availableEcmDocumentFilters } from 'components/EcmDocumentsTable/constants';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { DocumentType, EcmFilterInput } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { EcmDocumentsPaginationParams } from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useCallback, useMemo } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { InboxSensitiveDocumentsEcmEmptyState } from './EcmInboxSensitiveDocumentsEmptyState';
import { SensitiveDocumentsHeader } from './EcmSensitiveDocumentsHeader';
import { useEcmSensitiveDocumentsData } from './useEcmSensitiveDocumentsData';
import { useGetEcmInboxSensitiveDocumentsTableConfigs } from './useGetEcmInboxSensitiveDocumentsTableConfigs';

export const EcmInboxSensitiveDocuments = () => {
  const { organizationSlug } = useParams<AppRouteParams>();
  const tableRowLinkFF = useCandisFeatureFlags(FEATURE_FLAGS.tableRowLink);

  const { updateSearchParam, searchParams } = useMutateSearchParams();

  const navigate = useNavigate();
  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const { filters, onFilter, sortBy, onSort } =
    useUrlBasedSortAndFilter<EcmDocumentsTableData>({
      availableFilters: availableEcmDocumentFilters,
    });

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigurations,
    isResetPending,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useGetEcmInboxSensitiveDocumentsTableConfigs({ filters, sortBy });

  const handleRowClick = (
    documentId: string,
    documentType: DocumentType,
    cursor?: string
  ) => {
    if (!organizationSlug) {
      return;
    }

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ECM_SENSITIVE_CONTRACTS}/:documentId`,
      { organizationSlug, documentId }
    );

    const isInvoice = documentType === DocumentType.Invoice;

    if (isInvoice) {
      searchParams.set('isInvoice', 'true');
    }

    if (cursor) {
      searchParams.set('cursor', cursor);
    }

    if (queryStringFilter) {
      searchParams.set(queryParameter, queryStringFilter);
    }

    navigate({ pathname, search: searchParams.toString() });
  };

  const isTableFiltered = filters.length > 0;

  const formattedFilters: EcmFilterInput | undefined = isTableFiltered
    ? filters.reduce<EcmFilterInput>((acc, filter) => {
        if (filter.id === 'tags') {
          // TODO: fix type definition
          // `filter.value` is type `unknown`, so we typecast here
          if ((filter.value as string).includes(UNTAGGED_ID)) {
            acc.tags = [];
          } else {
            acc.tags = filter.value as Array<string>;
          }
        }

        return acc;
      }, {})
    : undefined;

  const paginationParams: EcmDocumentsPaginationParams = useMemo(
    () => ({
      filter: formattedFilters,
    }),
    [formattedFilters]
  );

  const ecmSensitiveDocumentsDataOptions = useMemo(
    () => ({
      params: paginationParams,
    }),
    [paginationParams]
  );

  const {
    data,
    isEmpty,
    isLoading,
    onLoadMore,
    sensitiveDocumentsCount,
    handleDebounceSearch,
  } = useEcmSensitiveDocumentsData(ecmSensitiveDocumentsDataOptions);

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return (
      <InboxSensitiveDocumentsEcmEmptyState
        isTableEmpty={isEmpty}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const firstDocument = data[0];
  const cursorParam = `cursor=${firstDocument?.cursor}`;
  const startProcessingLink = `/${organizationSlug}${Routes.ECM_SENSITIVE_CONTRACTS}/${firstDocument?.id}?${cursorParam}`;
  const isLinkDisabled = isLoading || isEmpty;

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const getPath = useCallback(
    ({ id, cursor }: EcmDocumentsTableData) => {
      if (!organizationSlug) {
        return '';
      }

      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ECM_SENSITIVE_CONTRACTS}/:documentId`,
        { organizationSlug, documentId: id }
      );

      if (cursor) {
        searchParams.set('cursor', cursor);
      }

      if (queryStringFilter) {
        searchParams.set(queryParameter, queryStringFilter);
      }

      return { pathname, search: searchParams.toString() };
    },
    [organizationSlug, queryStringFilter, searchParams]
  );

  const cellWrapper = useMemo(() => {
    if (!tableRowLinkFF) {
      return;
    }

    return getCellWrapperLink(getPath);
  }, [tableRowLinkFF, getPath]);

  const cellStyle = tableRowLinkFF
    ? () => ({
        padding: 'unset',
      })
    : undefined;

  return (
    <>
      <SensitiveDocumentsHeader
        startProcessingLink={startProcessingLink}
        isLinkDisabled={isLinkDisabled}
      />
      <InboxViewContainer activeTab={TabView.INBOX_ECM_SENSITIVE_DOCUMENTS}>
        <Flex
          direction="column"
          height="100%"
          paddingX="space32"
          paddingBottom="space24">
          <EcmDocumentsTable
            context="sensitiveDocuments"
            columns={availableDocumentColumnIds}
            data={data}
            isLoading={isLoading}
            isLoadingConfigurations={isLoadingConfigurations || isResetPending}
            defaultFilters={filters}
            isTableFiltered={isTableFiltered}
            configurationsTable={configurationsTable}
            searchQuery={queryStringFilter}
            selectedDocumentsCount={sensitiveDocumentsCount}
            defaultSortBy={sortBy}
            onRowClick={handleRowClick}
            onSearchChange={handleSearch}
            onUpdateConfigurations={handleUpdateConfigurations}
            onFilter={onFilter}
            onEndReached={onLoadMore}
            onSort={onSort}
            customEmptyState={customEmptyState}
            onResetTableConfigurations={handleResetTableConfigurations}
            cellWrapper={cellWrapper}
            getCellStyles={cellStyle}
          />
        </Flex>
      </InboxViewContainer>
    </>
  );
};
