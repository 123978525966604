import { Button, Card, Grid, Text } from '@candisio/design-system';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  commentFormErrorMessages,
  CommentFormOutput,
  commentFormSchema,
} from './utils';
import { zodResolver } from 'utils/zodFormValidation';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface SendBackToEmployeeFormProps {
  onSetIsFormVisible: (isVisible: boolean) => void;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
  isRequestAmendmentPending: boolean;
}

const CommentCard = ({
  onClose,
  isRequestAmendmentPending,
}: {
  onClose: () => void;
  isRequestAmendmentPending: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { fieldState } = useController({ name: 'comment' });

  const isFieldInvalid = !!fieldState.error;
  return (
    <Card
      corners="top"
      boxShadow="elevatedShadow3"
      padding="space20"
      border={isFieldInvalid ? '2px solid red' : 'none'}>
      <Grid gap="space8">
        <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
          {t(
            'reimbursementView.rightSection.footerActions.backToEmployee.title'
          )}
        </Text>
        <HookFormTextareaField
          autoFocus
          name="comment"
          placeholder={t(
            'reimbursementView.rightSection.footerActions.backToEmployee.messageForEmployee'
          )}
        />
        <Grid autoFlow="column" gap="space4">
          <Button
            onClick={onClose}
            variant="tertiary"
            disabled={isRequestAmendmentPending}>
            {t(
              'reimbursementView.rightSection.footerActions.backToEmployee.discard'
            )}
          </Button>
          <Button
            color="blue"
            type="submit"
            loading={isRequestAmendmentPending}
            disabled={isRequestAmendmentPending}>
            {t(
              'reimbursementView.rightSection.footerActions.backToEmployee.accept'
            )}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export const SendBackToEmployeeForm = ({
  onSetIsFormVisible,
  onRequestAmendment,
  isRequestAmendmentPending,
}: SendBackToEmployeeFormProps) => {
  const form = useForm<CommentFormOutput>({
    defaultValues: { comment: undefined },
    reValidateMode: 'onSubmit',
    resolver: zodResolver({
      errorMessages: commentFormErrorMessages,
      zodSchema: commentFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
    }),
  });

  const handleCloseForm = () => {
    onSetIsFormVisible(false);
  };

  const handleSendBackToEmployee = async ({ comment }: CommentFormOutput) => {
    await onRequestAmendment({ comment });
    handleCloseForm();
  };

  return (
    <FormProvider {...form}>
      <Grid as="form" onSubmit={form.handleSubmit(handleSendBackToEmployee)}>
        <CommentCard
          onClose={handleCloseForm}
          isRequestAmendmentPending={isRequestAmendmentPending}
        />
      </Grid>
    </FormProvider>
  );
};
