import {
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { PaymentFrequencyType } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { paymentFrequencyTranslation } from 'views/Settings/CreditCards/CreditCardSettlements/utils';
import { MixedProgressBar } from './MixedProgressBar';

export interface FinancialOverviewMixedOrgProps {
  total: number;
  available: number;
  currency: string;
  precision: number;
  paymentFrequency: PaymentFrequencyType | undefined;
  plannedPaymentDate: Date | undefined;
  onClick: () => void;
}

const maxPercentage = 100;

const formatterCustomOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
};

export const FinancialOverviewMixedOrg = ({
  available,
  currency,
  precision,
  total,
  paymentFrequency,
  plannedPaymentDate,
  onClick,
}: FinancialOverviewMixedOrgProps) => {
  const { transactionAmountFormatter } = useMoneyFormatter();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const prefundAmount = available - total;

  const prefundPercentage = (prefundAmount / available) * 100;

  const creditLimitPercentage = maxPercentage - prefundPercentage;

  const paymentFrequencyText = paymentFrequency
    ? t(paymentFrequencyTranslation[paymentFrequency])
    : undefined;

  const readablePrefund = transactionAmountFormatter(
    {
      currency,
      precision,
      value: prefundAmount,
    },
    formatterCustomOptions
  );

  const readableCreditLimit = transactionAmountFormatter(
    {
      currency,
      precision,
      value: total,
    },
    formatterCustomOptions
  );

  const readableAvailableLimit = transactionAmountFormatter(
    {
      currency,
      precision,
      value: available,
    },
    formatterCustomOptions
  );

  return (
    <Card boxShadow="none" background="gray50">
      <Grid gap="space4">
        <Grid gap="space16">
          <Grid templateColumns="repeat(2, 1fr)" alignItems="baseline">
            <Flex direction="column" gap="space4">
              <Flex gap="space16" alignItems="center">
                <Heading as="h3" justifySelf="start">
                  {t('organizationLimit.details.available')}
                </Heading>
                <Icon
                  icon="infoOutline"
                  size="space16"
                  {...triggerProps}
                  ref={triggerRef}
                />
              </Flex>
              {plannedPaymentDate ? (
                <Text>
                  {t('organizationLimit.details.plannedPaymentDate', {
                    plannedPaymentDate:
                      DateFormatters.compact(plannedPaymentDate),
                  })}
                </Text>
              ) : null}
            </Flex>

            <Flex direction="column" gap="space4" justifySelf="end">
              <Heading as="h3" alignSelf="end">
                {readableAvailableLimit}
              </Heading>
              <Link onClick={onClick} as="button">
                {t('prefunded.topUpButtonCTA')}
              </Link>
            </Flex>
          </Grid>
          <Grid templateColumns="repeat(2, 1fr)">
            {paymentFrequencyText ? (
              <Text fontSize="basic">
                {t('organizationLimit.details.periodSettlementWithAmount', {
                  settlementPeriod: paymentFrequencyText,
                  amount: readableCreditLimit,
                })}
              </Text>
            ) : null}
            <Text justifySelf="end" fontSize="basic">
              {t('organizationLimit.details.prefundAmount', {
                amount: readablePrefund,
              })}
            </Text>
          </Grid>
        </Grid>
        <MixedProgressBar
          creditLimitPercentage={creditLimitPercentage}
          prefundPercentage={prefundPercentage}
        />
      </Grid>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Grid gap="space12">
            <Text>{t('organizationLimit.info.mixed')}</Text>
            <Link
              href="containers/credit-cards/components/HelpCenterPanel"
              data-intercom="intercom-chat">
              {t('organizationLimit.linkText')}
            </Link>
          </Grid>
        </Tooltip>
      )}
    </Card>
  );
};
