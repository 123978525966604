import {
  PluginHook,
  useBlockLayout,
  useFilters,
  useResizeColumns,
  useRowSelect,
  useSortBy,
} from 'react-table';

interface UseTablePluginsParams {
  isResizable: boolean;
}

export const useTablePlugins = <TableDataType extends object>({
  isResizable,
}: UseTablePluginsParams) => {
  const defaultPlugins: PluginHook<TableDataType>[] = [
    useFilters,
    useSortBy,
    useRowSelect,
  ];

  const additionalPlugins = [];

  if (isResizable) {
    additionalPlugins.push(useBlockLayout);
    additionalPlugins.push(useResizeColumns);
  }

  return [...defaultPlugins, ...additionalPlugins];
};
