import { usePress } from 'react-aria';
import { OverlayTriggerProps, useOverlayTriggerState } from 'react-stately';
import { StandardHTMLAttributes } from '../../types';
import { ModalProps } from './Modal';

export interface UseModalConfig extends OverlayTriggerProps {}

/**
 * Associates a Modal with a trigger element and manages its state
 */
export const useModal = ({
  isOpen,
  defaultOpen,
  onOpenChange,
}: UseModalConfig = {}) => {
  const state = useOverlayTriggerState({
    isOpen,
    defaultOpen,
    onOpenChange,
  });

  const modalProps: ModalProps = { isOpen: state.isOpen, onClose: state.close };

  const { pressProps } = usePress({ onPress: state.open });
  const triggerProps: StandardHTMLAttributes<HTMLElement> = pressProps;

  return {
    open: state.open,
    close: state.close,
    isOpen: state.isOpen,
    toggle: state.toggle,
    triggerProps,
    modalProps,
  };
};
