import {
  Button,
  Flex,
  Grid,
  Popover,
  PopoverProps,
  Text,
} from '@candisio/design-system';
import {
  LottieAnimation,
  manageRelationshipsPreviewDE,
  manageRelationshipsPreviewEN,
} from 'components/Lottie/Lottie';
import { motion } from 'framer-motion';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

const MotionPopover = motion(Popover);

interface ManageRelationshipsActivationPopoverProps {
  popoverProps: PopoverProps;
  popoverRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}

const animation = {
  [Locale.De]: manageRelationshipsPreviewDE,
  [Locale.En]: manageRelationshipsPreviewEN,
};

export const DocumentPreviewActivationPopover = ({
  popoverProps,
  popoverRef,
  onClose,
}: ManageRelationshipsActivationPopoverProps) => {
  const [t] = useTranslation();

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const animationData = animation[locale];

  return (
    <MotionPopover
      {...(popoverProps as any)}
      ref={popoverRef}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
      transition={{ type: 'spring', duration: 2, bounce: 0.5 }}
      padding="space16"
      dismissWithOutsideClick={false}
      background="blue700"
      maxWidth="22rem">
      <Grid gap="space12">
        <Text
          color="white"
          fontSize="medium"
          fontWeight="semibold"
          lineHeight="paragraph">
          {t('documentPreview.activationPopover.title')}
        </Text>
        <LottieAnimation
          style={{ display: 'contents' }}
          options={{ animationData, loop: true }}
        />
        <Text color="white" fontSize="basic" lineHeight="paragraph">
          {t('documentPreview.activationPopover.description')}
        </Text>
        <Flex justifyContent="end" alignItems="center">
          <Button
            color="whiteBlue"
            size="small"
            variant="primary"
            onClick={onClose}>
            {t('documentPreview.activationPopover.closePopoverButton')}
          </Button>
        </Flex>
      </Grid>
    </MotionPopover>
  );
};
