import {
  Avatar,
  Button,
  Flex,
  Grid,
  Heading,
  Link,
  Text,
} from '@candisio/design-system';
import { ReActivateRow } from 'components/ArchiveWrapper/ArchiveWrapper';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { Maybe, User } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentTagDetailsDrawerProps } from './DocumentTagDetailsDrawer';
import {
  DocumentTagDetailsForm,
  DocumentTagDetailsFormProps,
} from './Form/DocumentTagDetailsForm';
import { DocumentTagDetailsFormValues } from './Form/documentTagDetailsFormSchema';
import { useCreateTagForm } from './useCreateTagForm';

const CREATE_DOCUMENT_TAG_FORM_ID = 'create-document-tag';

export interface DocumentTagDetailsProps
  extends Omit<DocumentTagDetailsDrawerProps, 'isOpen'> {
  defaultValues: DocumentTagDetailsFormValues;
  onSubmit: DocumentTagDetailsFormProps['onSubmit'];
  isSubmitPending: boolean;
  updatingStatus: boolean;
  onArchive: () => Promise<void>;
  onActivate: () => Promise<void>;
  isActive?: boolean;
  createdBy?: Maybe<Pick<User, 'id' | 'name' | 'avatarUrl'>>;
}

export const DocumentTagDetails = ({
  onCloseDrawer,
  compact,
  documentTagId,
  onArchive,
  defaultValues,
  onSubmit,
  isSubmitPending,
  updatingStatus,
  isActive,
  onActivate,
  createdBy,
}: DocumentTagDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { showConfirmation, form } = useCreateTagForm({
    defaultValues,
    documentTagId,
  });

  return (
    <DrawerLayout
      compact={compact}
      onClose={onCloseDrawer}
      header={
        <Heading as="h3">
          {documentTagId
            ? t('documentTags.drawer.titleEdit')
            : t('documentTags.drawer.titleNew')}
        </Heading>
      }
      footer={
        <Grid placeContent="space-between" autoFlow="column">
          {showConfirmation ? (
            <ConfirmationButton
              confirmButtonProps={{
                type: 'submit',
                form: CREATE_DOCUMENT_TAG_FORM_ID,
                loading: isSubmitPending,
                disabled: isSubmitPending,
              }}
              confirmationTitle={t(
                'documentTags.drawer.actions.confirmNameChange'
              )}
              onConfirm={form.handleSubmit(onSubmit)}
              loading={isSubmitPending}
              disabled={isSubmitPending}>
              {t('documentTags.drawer.actions.save')}
            </ConfirmationButton>
          ) : (
            <Button
              type="submit"
              form={CREATE_DOCUMENT_TAG_FORM_ID}
              loading={isSubmitPending}
              disabled={isSubmitPending}>
              {t('documentTags.drawer.actions.save')}
            </Button>
          )}
          {documentTagId && isActive && (
            <ConfirmationButton
              onConfirm={onArchive}
              confirmationTitle={t(
                'documentTags.drawer.actions.confirmArchive'
              )}
              variant="tertiary"
              color="red"
              loading={updatingStatus}
              disabled={updatingStatus}>
              {t('documentTags.drawer.actions.archive')}
            </ConfirmationButton>
          )}
        </Grid>
      }>
      {documentTagId && !isActive && (
        <ReActivateRow
          onRestore={onActivate}
          isSubmitting={updatingStatus}
          disabled={updatingStatus}
        />
      )}
      <DocumentTagDetailsForm
        form={form}
        formId={CREATE_DOCUMENT_TAG_FORM_ID}
        onSubmit={onSubmit}
      />
      {createdBy && (
        <Grid paddingTop="space24" gap="space4">
          <Text>{t('documentTags.drawer.fields.createdBy.label')}</Text>
          <Flex alignItems="center" gap="space8">
            <Avatar {...createdBy} />
            <Text fontSize="basic">{createdBy.name}</Text>
          </Flex>
        </Grid>
      )}
      <Grid
        top="space24"
        gap="space16"
        background="bluebg"
        padding="space16"
        borderRadius="medium"
        overflow="hidden">
        <Grid gap="space8">
          <Text
            as="h3"
            fontWeight="semibold"
            justifySelf="start"
            fontSize="medium">
            {t('documentTags.drawer.links.title')}
          </Text>
          <Text>{t('documentTags.drawer.links.hint')}</Text>
        </Grid>
        <Grid gap="space8">
          <Link external href={t('documentTags.drawer.links.videoTour.url')}>
            {t('documentTags.drawer.links.videoTour.label')}
          </Link>
          <Link
            external
            href={t('documentTags.drawer.links.helpCenterArticle.url')}>
            {t('documentTags.drawer.links.helpCenterArticle.label')}
          </Link>
        </Grid>
      </Grid>
    </DrawerLayout>
  );
};
