import { Grid } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { DeleteReimbursementItemAction } from '../DeleteReimbursementItem';
import { ExpenseFormWrapper } from '../ExpenseFormWrapper';
import { ExpenseInvoiceInputField } from '../ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from '../ExpenseInvoicePreviewContainer';
import { generateGeneralExpenseFormFieldNames } from '../utils/generateGeneralExpenseFormFieldNames';
import { BooleanMap } from '../utils/useExpensesFormActions';
import { ReadonlyGeneralExpensesForm } from './ReadonlyGeneralExpenseForm';

interface GeneralExpensesFormProps {
  isReadOnly: boolean;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  onUpdateExpense: (index: number) => () => void;
  onReplaceInvoice: (index: number) => () => void;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  formIndex: number;
  isDeletingForm: BooleanMap;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  isCreatingInvoice: BooleanMap;
  clearableField: string | undefined;
  expenseId: string;
  canManageReimbursementItems: boolean;
}
export const GeneralExpensesForm = ({
  isReadOnly,
  clearableField,
  expenseId,
  formIndex,
  formMethods,
  isCreatingInvoice,
  isDeletingForm,
  onCleareableField,
  onInvoiceChange,
  onReplaceInvoice,
  onUpdateExpense,
  onRemoveForm,
  canManageReimbursementItems,
}: GeneralExpensesFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { watch, getValues } = formMethods;

  const fields = generateGeneralExpenseFormFieldNames(formIndex);

  const invoices = watch(fields.files);

  const invoice = invoices?.[0];

  const totalAmount = getValues(fields.totalAmount);
  const reason = getValues(fields.reason);
  const showInvoicePreview = isCreatingInvoice[formIndex] || !!invoice;

  if (isReadOnly) {
    return (
      <ReadonlyGeneralExpensesForm
        expenseId={expenseId}
        reason={reason}
        invoice={invoice}
        formIndex={formIndex}
        totalAmount={totalAmount}
      />
    );
  }

  return (
    <ExpenseFormWrapper
      expenseId={expenseId}
      totalAmount={totalAmount}
      expenseType="general"
      reason={reason}>
      <Grid as="form" gap="space24">
        <Grid columns={3} gap="space8">
          <HookFormTextField
            name={fields.reason}
            maxLength={60}
            label={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            ariaLabel={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.reason.placeholder'
            )}
            clearable={clearableField === fields.reason}
            onFocus={() => onCleareableField(fields.reason)}
            inputProps={{
              onBlur: onUpdateExpense(formIndex),
            }}
          />
          <HookFormDatePickerField
            name={fields.expenseDate}
            label={t('reimbursementView.middleSection.form.general.date.label')}
            onBlur={onUpdateExpense(formIndex)}
            clearable={clearableField === fields.expenseDate}
            onFocus={onCleareableField(fields.expenseDate)}
          />
          <HookFormAmountField
            name={fields.totalAmount}
            label={t(
              'reimbursementView.middleSection.form.general.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.totalAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.totalAmount}
            onFocus={onCleareableField(fields.totalAmount)}
            onBlur={onUpdateExpense(formIndex)}
          />
        </Grid>

        {showInvoicePreview ? (
          <ExpenseInvoicePreviewContainer
            invoice={invoice}
            createInvoicePending={isCreatingInvoice[formIndex]}
            onReplaceInvoice={onReplaceInvoice(formIndex)}
          />
        ) : (
          <ExpenseInvoiceInputField
            name={fields.files}
            id={`invoice-input-field-${fields.files}`}
            onChange={file => onInvoiceChange(file, formIndex)}
            onInvoiceUpdate={onUpdateExpense(formIndex)}
          />
        )}
        {canManageReimbursementItems && (
          <DeleteReimbursementItemAction
            loading={isDeletingForm[formIndex]}
            onDelete={onRemoveForm(formIndex, expenseId)}
          />
        )}
      </Grid>
    </ExpenseFormWrapper>
  );
};
