import {
  GoodsReceiptsImportHistoryPaginationQuery,
  GoodsReceiptsImportHistoryPaginationQueryVariables,
  GoodsReceiptImportHistoryEntryStatus,
} from 'generated-types/graphql.types';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { goodsReceiptsImportHistoryPaginationQuery } from '../queries';
import { GoodsReceiptImportHistoryItemProps } from './GoodsReceiptsImportHistoryItem';

export const useGoodsReceiptsImportHistoryData = () => {
  const { data, loading, onLoadMore } = usePagePagination<
    GoodsReceiptsImportHistoryPaginationQuery,
    GoodsReceiptsImportHistoryPaginationQueryVariables
  >(
    goodsReceiptsImportHistoryPaginationQuery,
    'listGoodsReceiptImportHistoryEntries',
    {
      variables: {
        input: {
          page: 1,
          pageSize: 20,
        },
      },
    }
  );

  const items: GoodsReceiptImportHistoryItemProps[] = (
    data?.listGoodsReceiptImportHistoryEntries?.records ?? []
  )?.map(item => {
    return {
      id: item?.id,
      createdAt: item?.createdAt,
      importStatus: {
        failedToImportDocumentsCount:
          item?.importResult?.failedToImportDocumentsCount,
        successfullyCreatedDocumentsCount:
          item?.importResult?.successfullyCreatedDocumentsCount,
      },
      status: item?.status ?? GoodsReceiptImportHistoryEntryStatus.Success,
      username: item?.createdBy?.name,
    };
  });

  const totalCount =
    data?.listGoodsReceiptImportHistoryEntries.pageInfo?.totalCount ?? 0;

  const hasMore =
    (data?.listGoodsReceiptImportHistoryEntries.records?.length ?? 0) <
    totalCount;

  return {
    items,
    hasMore,
    loading,
    onLoadMore,
  };
};
