import { defaultTheme } from '@candisio/design-system';
import { AddNewActionBox } from 'components/AddNewActionBox/AddNewActionBox';
import { compiledRoutes } from 'models';

import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

export function AddDocumentTypeActionBox() {
  const organizationId = useOrganizationId();

  const link = `/${organizationId}${compiledRoutes.settingsDocumentTypesRoute}`;
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  return (
    <AddNewActionBox
      borderTop={`1px solid ${defaultTheme.colors.gray250}`}
      borderTopRadius="0">
      <AddNewActionBox.Content>
        <AddNewActionBox.Header>
          <AddNewActionBox.HeaderTag>
            {t('storageForm.fields.documentType.actionBox.tag')}
          </AddNewActionBox.HeaderTag>
          <AddNewActionBox.HeaderText>
            {t('storageForm.fields.documentType.actionBox.header')}
          </AddNewActionBox.HeaderText>
        </AddNewActionBox.Header>
        <AddNewActionBox.Message>
          {t('storageForm.fields.documentType.actionBox.message')}
        </AddNewActionBox.Message>
      </AddNewActionBox.Content>
      <AddNewActionBox.ButtonLink href={link} target="_blank">
        {t('storageForm.fields.documentType.actionBox.button')}
      </AddNewActionBox.ButtonLink>
    </AddNewActionBox>
  );
}
