import { useGetCardById } from 'containers/credit-cards/utils';
// eslint-disable-next-line no-restricted-imports
import { DateFormatters } from 'utils/date_formatter';
import { CardReplaced } from './CardReplaced';

interface CardReplacedContainerProps {
  cardId: string;
}

export const CardReplacedContainer = ({
  cardId,
}: CardReplacedContainerProps) => {
  const { card, loading } = useGetCardById({ cardId });

  const replacedCardId = card?.replacedCardId ?? undefined;

  const { card: cardReplaced, loading: cardReplaceddLoading } = useGetCardById({
    cardId: replacedCardId,
  });

  if (!cardReplaced || cardReplaceddLoading || loading) {
    return null;
  }

  const replacedCardDetails = {
    refNumber: cardReplaced.refNum ? `•••• ${cardReplaced.refNum}` : undefined,
    id: cardReplaced.id,
    replacedBy: card?.replacedBy
      ? `${card.replacedBy.firstName} ${card.replacedBy.lastName}`
      : undefined,
  };

  const replacedDate = card?.issuingDate
    ? DateFormatters.compact(new Date(card.issuingDate))
    : undefined;

  return replacedDate ? (
    <CardReplaced {...replacedCardDetails} replacedDate={replacedDate} />
  ) : null;
};
