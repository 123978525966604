import { Button, Flex, Heading } from '@candisio/design-system';
import {
  TransactionsTableData,
  ViewUsingTransactions,
} from 'components/Transactions/Table/types';
import { useCardIssuerTransactionsData } from 'components/Transactions/Table/useCardIssuerTransactionsData';
import { availableFilters } from 'components/Transactions/Table/util';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory, useParams } from 'react-router-dom';
import { appendParamsToQueryString } from 'utils/url-helper';

export const TransactionsHeader = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const history = useHistory();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const { search } = history.location;

  const { sortBy, filters } = useUrlBasedSortAndFilter<TransactionsTableData>({
    availableFilters,
  });

  const { transactionList, transactionEdges } = useCardIssuerTransactionsData({
    routeType: ViewUsingTransactions.INBOX,
    filters,
    sortBy,
  });

  const firstTransactionEdge = (transactionEdges ?? [])[0];
  const searchWithCursor = appendParamsToQueryString(search, {
    cursor: firstTransactionEdge?.cursor,
  });

  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  const startProcessingLink = `/${organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}/${firstTransactionEdge?.node.id}?${searchWithCursor}`;

  return (
    <Flex
      alignItems="center"
      gap="space16"
      paddingRight="space32"
      paddingLeft="space32"
      paddingTop={mainNavigationRefactorFF ? 'space20' : 'space40'}
      paddingBottom="space16">
      <Heading as="h1">{t('inbox.title')}</Heading>
      {Boolean(transactionList?.length) && (
        <Button
          size="small"
          variant="primary"
          onClick={() => history.push(startProcessingLink)}>
          {t('approvals.actions.startApproving')}
        </Button>
      )}
    </Flex>
  );
};
