import { CellProps, Text } from '@candisio/design-system';
import { AmountCell } from 'components/Table/Cells/Amount';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { ReimbursementAmount } from 'views/Reimbursement/toolkit/types';

export const AmountCellReimbursements = <TableDataType extends { id: string }>({
  value,
}: CellProps<TableDataType, ReimbursementAmount>) => {
  const { transactionAmountFormatter } = useMoneyFormatter();
  if (!value?.amount && !value?.currency && !value?.precision) {
    return <Text> - </Text>;
  }

  const formattedAmount = transactionAmountFormatter({
    value: value?.amount,
    currency: value?.currency,
    precision: value?.precision,
  });

  return <AmountCell formattedAmount={formattedAmount} />;
};
