import { forwardRef } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { ListProps } from 'react-virtuoso';
import { useTheme } from '../../Theme';
import { LIST_PADDING, List } from './List';
import { VirtuosoContext } from './types';

export const VirtuosoList = forwardRef<
  HTMLUListElement,
  ListProps & VirtuosoContext
>(({ context, style, ...restProps }, forwardedRef) => {
  const { space } = useTheme();
  const paddingY = space[LIST_PADDING];

  const { ref, ...listProps } = context.listProps;

  return (
    <List
      style={{
        ...style,
        // add our own padding top and bottom in addition to that set by Virtuoso
        paddingTop: `calc(${paddingY} + ${style?.paddingTop ?? '0'}px)`,
        paddingBottom: `calc(${paddingY} + ${style?.paddingBottom ?? '0'}px`,
      }}
      {...mergeProps(listProps, restProps)}
      ref={ref ? mergeRefs([ref, forwardedRef]) : forwardedRef}
    />
  );
});
