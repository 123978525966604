import { useExportableEntitiesCounter } from 'hooks/useExportableEntitiesCounter';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useInitialExportUrl } from 'views/Integrations/Export/Manifest';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuExport = ({ ...iconProps }) => {
  const { isAdmin, isAccountant } = useUserRoles();
  const exportViewDef = useInitialExportUrl();

  const {
    readyToExportEntitiesCount,
    loading: loadingExportableEntitiesCount,
  } = useExportableEntitiesCounter({
    fetchPolicy: 'cache-first',
    skip: !(isAdmin || isAccountant),
  });

  return (
    <NavigationIcon
      icon="menuExport"
      count={readyToExportEntitiesCount}
      countLoading={loadingExportableEntitiesCount}
      {...iconProps}
      route={exportViewDef.url || Routes.EXPORTS}
    />
  );
};
