import React, { ReactNode, useRef } from 'react';
import { useFocusVisible, useTab } from 'react-aria';
import { SingleSelectListState, TabListState } from 'react-stately';
import { IconKey } from '../../Particles/Spritemap';
import { useTheme } from '../../Theme';
import { ColorProps, LayoutProps, StandardHTMLAttributes } from '../../types';
import { Badge, BadgeColor } from '../Badge';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Grid } from '../Grid';
import { Icon } from '../Icon';
import { Color, Tag } from '../Tag';
import { Tooltip, useTooltip } from '../Tooltip';

export type TabSize = 'small' | 'medium' | 'large';

interface TabItemWithBadgeProps {
  children?: React.ReactNode;
  key?: React.Key;
  title?: string;
  /**@deprecated use suffix instead */
  badge?: string;
  /**@deprecated use suffix instead */
  badgeColor?: BadgeColor;
  /**@deprecated use suffix instead */
  icon?: never;
  /**@deprecated use suffix instead */
  iconColor?: never;
  /**@deprecated use suffix instead */
  iconTooltip?: never;
  /**@deprecated use suffix instead */
  iconTooltipWidth?: never;
  /**@deprecated use suffix instead */
  tag?: string; // Add the 'tag' property here
  /**@deprecated use suffix instead */
  tagColor?: Color;
  suffix?: ReactNode;
  prefix?: ReactNode;
}

interface TabItemWithIconProps {
  children?: React.ReactNode;
  key?: React.Key;
  title?: string;
  /**@deprecated use suffix instead */
  icon?: IconKey;
  /**@deprecated use suffix instead */
  iconTooltip?: ReactNode;
  /**@deprecated use suffix instead */
  iconColor?: ColorProps['color'];
  /**@deprecated use suffix instead */
  iconTooltipWidth?: LayoutProps['maxWidth'];
  /**@deprecated use suffix instead */
  badge?: never;
  /**@deprecated use suffix instead */
  badgeColor?: never;
  /**@deprecated use suffix instead */
  tag?: string; // Add the 'tag' property here
  /**@deprecated use suffix instead */
  tagColor?: Color | undefined; // Update the type of 'tagColor' property
  suffix?: ReactNode;
  prefix?: ReactNode;
}

export type TabItem = TabItemWithBadgeProps | TabItemWithIconProps;

export type TabItemNode = SingleSelectListState<
  TabItem | undefined
>['selectedItem'];

export interface TabProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  /**
   * `Item` props, potentially including:
   * @typedef {Object} TabItemProps
   * only accessible if one of the following is provided: 'badge', 'badgeColor'
   * @property {string} badge Badge content to be rendered.
   * @property {BadgeColor} badgeColor Badge color to be rendered.
   * only accessible if one of the following is provided: 'icon', 'iconColor', 'iconTooltip', 'iconTooltipWidth'
   * @property {IconKey} icon Icon from our DS collection
   * @property {Color} iconColor DS color token or CSS color value
   * @property {ReactNode} iconTooltip Icon Tooltip content to be rendered on hover.
   * @property {string} iconTooltipWidth Width of icon tooltip content to be rendered.
   */
  item: TabItemNode;
  state: TabListState<TabItem>;
  size: TabSize;
  variant?: 'default' | 'opaque';
}

/**
 * `Tab` component provides individually styled `Tab` to `Tabs` component.
 *
 * @param {TabItemNode} item Tab item to be rendered
 * @param {TabListState<TabItem>} state state of tab (disabled, selected)
 */
export const Tab = React.forwardRef<HTMLDivElement, TabProps>(
  ({ item, state, size, variant = 'default', ...restProps }, forwardedRef) => {
    const { colors, tab } = useTheme();
    const { isFocusVisible } = useFocusVisible();

    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({ delay: 500 });

    const { key, rendered, value } = item;

    const ref = useRef(null);

    const { tabProps } = useTab({ key }, state, ref);

    const isSelected = state.selectedKey === key;
    const isDisabled = state.disabledKeys.has(key);

    const baseStyle = {
      color: isDisabled ? tab.disabled.color : tab.color,

      '&:hover': {
        cursor: !isDisabled ? 'pointer' : 'not-allowed',
        background: !isDisabled ? tab[variant].hover.background : undefined,
      },

      '&:focus': {
        outline: isFocusVisible ? `3px solid ${colors.blue400}` : 'none',
        outlineOffset: '-3px',
      },
    };

    const borderStyle = {
      '&::after': {
        content: "''",
        position: 'absolute' as const,
        left: 0,
        right: 0,
        bottom: '-1px',
        height: '2px',
        background: tab.border.background,
      },
    };

    const fontSizeMap = {
      small: 'small',
      medium: 'basic',
      large: 'large',
    } as const;

    const iconSizeMap = {
      small: 'space16',
      medium: 'space18',
      large: 'space20',
    } as const;

    const showIconTooltip = !isDisabled && isOpen && value?.iconTooltip;

    const withBadgeOrIcon = (
      <>
        {value?.badge && (
          <Badge color={value?.badgeColor ?? tab.badge}>{value?.badge}</Badge>
        )}
        {value?.icon && (
          <Grid ref={triggerRef} {...triggerProps}>
            <Icon
              {...tabProps}
              icon={value?.icon}
              size={iconSizeMap[size]}
              style={{ outline: 'none' }}
              color={value?.iconColor ?? tab.color}
            />
            {showIconTooltip && (
              <Tooltip
                ref={tooltipRef}
                {...tooltipProps}
                maxWidth={value?.iconTooltipWidth}>
                {value?.iconTooltip}
              </Tooltip>
            )}
          </Grid>
        )}
        {value?.tag && (
          <Tag color={value?.tagColor ?? 'gray'} callout={true}>
            {value?.tag}
          </Tag> // Added callout prop as boolean
        )}
      </>
    );

    return (
      <Box
        {...restProps}
        position="relative"
        css={isSelected && borderStyle}
        ref={forwardedRef}>
        <Flex
          {...tabProps}
          fontSize={fontSizeMap[size]}
          alignItems="center"
          justifyContent="center"
          ref={ref}
          padding="space12"
          borderRadius="medium"
          gap="space8"
          css={baseStyle}>
          {value?.prefix}
          {rendered}
          {value?.suffix}
          {value?.badge && value?.icon ? null : withBadgeOrIcon}
        </Flex>
      </Box>
    );
  }
);
