import {
  Avatar,
  Box,
  Flex,
  Tag,
  TruncatedText,
  Image,
} from '@candisio/design-system';
import {
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { mappedCategoriesIcons } from '../Table/util';
import { TransactionStatusTag } from '../TransactionStatusTag/TransactionStatusTag';
import { AmountText } from './AmountText/AmountText';
import { Transaction, getAmountTextStyle } from './utils';

interface TransactionDetailsProps {
  transaction?: Transaction;
}

export const TransactionDetails = ({
  transaction,
}: TransactionDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const {
    type,
    status,
    transactionCreatedAt,
    transactionAmount,
    billingAmount,
    merchantLegalName,
    merchantName,
    merchantLogoUrl,
    category,
  } = transaction || {};

  const isTransactionPaidInForeignCurrency =
    !isNil(transactionAmount) && transactionAmount?.currency !== 'EUR';

  const isRefundType = type === TransactionType.Refund;

  const { color, textDecoration } = getAmountTextStyle(status, type);

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" gap="space8">
        <Flex color="gray700" gap="space12">
          {transactionCreatedAt && (
            <TruncatedText wordBreak="break-word">
              {DateFormatters.compactDatetime(new Date(transactionCreatedAt))}
            </TruncatedText>
          )}
        </Flex>
        <Flex gap="space4">
          {type ? (
            <Tag variant="secondary" color="gray">
              {
                // eslint-disable-next-line candis/no-template-strings-inside-translation
                t(`transactionDetailsCard.type.${type.toLocaleLowerCase()}`)
              }
            </Tag>
          ) : null}
          <TransactionStatusTag status={status as TransactionStatus} />
        </Flex>
      </Flex>
      <Flex
        fontSize="small"
        justifyContent="space-between"
        textAlign="center"
        fontWeight="semibold">
        <Flex alignItems="center" gap="space8">
          {merchantLogoUrl && (
            <Avatar name={merchantName} img={merchantLogoUrl} size="small" />
          )}
          {!merchantLogoUrl && category && (
            <Box
              borderRadius="full"
              border="1px solid gray200"
              padding="space2">
              <Image src={mappedCategoriesIcons[category]} alt="" />
            </Box>
          )}
          <Flex alignItems="center" gap="space2">
            {merchantLegalName && (
              <TruncatedText
                maxWidth="20ch"
                overflowWrap="anywhere"
                whiteSpace="normal">
                {merchantLegalName}
              </TruncatedText>
            )}
            {merchantName && merchantName !== merchantLegalName ? (
              <TruncatedText
                maxWidth="12ch"
                color="gray600"
                overflowWrap="anywhere"
                whiteSpace="normal">
                {`(${merchantName})`}
              </TruncatedText>
            ) : null}
          </Flex>
        </Flex>
        <Flex gap="space8" color={color} textDecoration={textDecoration}>
          {isTransactionPaidInForeignCurrency && (
            <AmountText
              value={transactionAmount}
              isRefundType={isRefundType}
              variant="mainCurrency"
            />
          )}
          {billingAmount && (
            <AmountText
              value={billingAmount}
              isRefundType={isRefundType}
              variant={
                isTransactionPaidInForeignCurrency
                  ? 'secondaryCurrency'
                  : 'mainCurrency'
              }
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
