import gql from 'graphql-tag';

export const accountsPayableNumberSettingsQuery = gql`
  query accountsPayableNumberSettings {
    accountsPayableNumberSettings {
      startNumber
      enabled
    }
  }
`;

export const accountsPayableNumberSettingsForEmployeesQuery = gql`
  query accountsPayableNumberSettingsForEmployees {
    accountsPayableNumberSettingsForEmployees {
      startNumber
      enabled
    }
  }
`;
