import gql from 'graphql-tag';
import { organizationDataFragment } from './queries';

export const updateOrganizationMutation = gql`
  mutation update($realmName: String, $value: UpdateOrganizationsInput) {
    updateOrganization(realmName: $realmName, input: $value) {
      ...OrganizationData
    }
  }
  ${organizationDataFragment}
`;

export const transferOwnershipMutation = gql`
  mutation transferOwnership($input: TransferOwnershipInput!) {
    transferOwnership(input: $input) {
      ... on BuiltinRole {
        id
      }
      ... on TransferOwnershipError {
        kind
        message
      }
    }
  }
`;
