import {
  Box,
  Button,
  Flex,
  Grid,
  Popover,
  usePopover,
  useTheme,
} from '@candisio/design-system';
import { Filename } from 'components/Filename/Filename';
import { ProgressMessage } from 'components/ProgressMessage/ProgressMessage';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  ExportStatus,
  ExportType,
  useExportNotificationsQuery,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { usePolling } from 'hooks/usePolling';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import {
  ExportItem,
  ExportNotificationsContext,
} from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { buildDatevProfileLink } from 'utils/build_datev_link';
import { EmptyNotificationList } from 'views/AppContainer/components/NavigationSidebar/components/MenuItems/MenuNotifications/NotificationsPopoverContent/EmptyNotificationList/EmptyNotificationList';
import { useDefineExportView } from 'views/Integrations/Export/Manifest';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { getExportsStatus, mapExportIcon, mapExportStatus } from '../../utils';
import {
  IndicatorStatus,
  ProgressIndicator,
  indicatorStatusTranslationMap,
} from '../ProgressIndicator';
import { NotificationItemContainer } from './styles';
import { exportTypeTranslationMap } from './utils';

const ExportNotificationItem = ({
  exportItem,
  onClose,
}: {
  exportItem: ExportItem;
  onClose: () => void;
}) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { id, status, errorDetails, documents, type, hash } = exportItem;

  const parsedStatus = mapExportStatus(status ?? ExportStatus.Failed);

  const viewDef = useDefineExportView({
    exportId: id,
    sidebar: Sidebar.HISTORY,
    view: View.HISTORY,
  });

  const exportHistoryLink = `/${organizationSlug}${viewDef.url}`;

  const getLabel = useCallback(
    ({ id, type, documents }: Omit<ExportItem, 'hash' | 'organization'>) => {
      if (
        type === ExportType.DatevDco ||
        type === ExportType.DatevDxso ||
        type === ExportType.Api
      )
        return;

      let label = (
        <Filename>
          {t('notification.label', {
            documents,
            type: t(exportTypeTranslationMap[type]),
            count: documents,
          })}
        </Filename>
      );

      if (id) {
        label = (
          <RouterLink onClick={onClose} to={exportHistoryLink}>
            {label}
          </RouterLink>
        );
      }

      return label;
    },
    [exportHistoryLink, t, onClose]
  );

  return (
    <NotificationItemContainer key={hash}>
      <ProgressMessage
        state={parsedStatus}
        errorDetails={errorDetails}
        statusLabel={t(indicatorStatusTranslationMap[parsedStatus])}
        icon={mapExportIcon(type)}
        label={getLabel({ type, documents, id, status })}
      />
    </NotificationItemContainer>
  );
};

const ExportsPopoverContent = ({
  exportItems,
  onClose,
  onClear,
}: {
  exportItems: ExportItem[];
  onClose: () => void;
  onClear: () => void;
}) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { isActive: isSapActive } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const { colors } = useTheme();

  const { data: { new_datevSettings: datevSettings = null } = {}, loading } =
    useNew_DatevSettingsQuery();

  const { consultantNumber, clientNumber } = datevSettings?.client || {};

  const datevProfileLink = buildDatevProfileLink({
    consultantNumber,
    clientNumber,
  });

  const navigate = useNavigate();
  const viewDef = useDefineExportView({
    sidebar: Sidebar.INIT_NEW_EXPORT,
    view: View.READY,
  });

  const startExportLink = `/${organizationSlug}${viewDef.url}`;

  const showDatevAction = !shouldUseCoreDataApi && !isSapActive;
  const showExportAction = !shouldUseCoreDataApi;

  return (
    <Flex
      height="100%"
      padding="14px 8px 8px"
      justifyContent="space-between"
      direction="column">
      <Button
        icon="close"
        position="absolute"
        top="space4"
        right="space8"
        label={t('uploads.close')}
        onClick={onClose}
        variant="tertiary"
        size="small"
      />
      {!!exportItems.length ? (
        <Box
          as="ul"
          listStyle="none"
          padding={0}
          maxHeight="40vh"
          overflowY="auto">
          {exportItems.map(exportItem => (
            <ExportNotificationItem
              key={exportItem.hash}
              exportItem={exportItem}
              onClose={onClose}
            />
          ))}
        </Box>
      ) : (
        <EmptyNotificationList />
      )}
      <Grid
        templateColumns="1fr auto"
        justifyContent="space-between"
        alignItems="center">
        <Button
          size="small"
          variant="tertiary"
          justifySelf={showExportAction ? 'start' : 'end'}
          onClick={onClear}
          disabled={!exportItems.length}>
          {t('uploads.clear')}
        </Button>
        <Flex justifyContent="space-between" alignItems="center" gap="space24">
          {showDatevAction && (
            <Button
              as="a"
              href={datevProfileLink}
              rel="noopener noreferrer"
              target="_blank"
              size="small"
              loading={loading}
              variant="tertiary"
              style={{ color: colors.gray800 }}>
              {t('exports.datevLabel')}
            </Button>
          )}
          {showExportAction && (
            <Button
              size="small"
              variant="tertiary"
              onClick={() => {
                onClose();
                navigate(startExportLink);
              }}>
              {t('exports.start')}
            </Button>
          )}
        </Flex>
      </Grid>
    </Flex>
  );
};

export const ExportNotifications = () => {
  const {
    close,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
    open,
  } = usePopover({ placement: 'bottom right' });

  const {
    clearExports,
    updateExport,
    notifications: allNotifications,
  } = useContext(ExportNotificationsContext);

  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();

  const notifications = useMemo(
    () =>
      allNotifications.filter(
        ({ organization }) => organizationSlug === organization
      ),
    [allNotifications, organizationSlug]
  );

  const activeHashes = useMemo(
    () =>
      notifications
        .filter(({ status }) => status === ExportStatus.Exporting)
        .map(({ hash }) => hash),
    [notifications]
  );

  // main notifications query to track user exports
  const { data, startPolling, stopPolling } = useExportNotificationsQuery({
    variables: { input: { hash: activeHashes } },
    fetchPolicy: 'network-only',
    skip: activeHashes.length < 1,
    context: { skipLoading: true },
  });

  usePolling({
    pollInterval: 3000,
    // when have at least one processing export, we should start tracking it
    skip: activeHashes.length < 1,
    startPolling,
    stopPolling,
  });

  // update active exports by statuses from notifications query
  useEffect(() => {
    data?.exports
      ?.filter(({ hash }) => hash && activeHashes.includes(hash))
      .forEach(({ hash, id, status }) => {
        const notification = notifications.find(n => n.hash === hash);
        const errorDetails =
          status === ExportStatus.Failed
            ? t('exports.genericError')
            : status === ExportStatus.PartialyExported
            ? t('exports.genericWarning')
            : undefined;

        if (hash && status && notification?.status !== status) {
          updateExport({
            hash,
            id,
            status,
            errorDetails,
          });
        }
      });
  }, [data?.exports, notifications, activeHashes, t, updateExport]);

  // calculate overall exporting status
  const indicatorStatus = useMemo(
    () => getExportsStatus(notifications),
    [notifications]
  );

  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const setStatusColor = () => {
    if (indicatorStatus === IndicatorStatus.IDLE && isHovered)
      return colors.blue500;

    switch (indicatorStatus) {
      case IndicatorStatus.DONE:
        return colors.green500;
      case IndicatorStatus.ERROR:
        return colors.red500;
      case IndicatorStatus.WARNING:
        return colors.yellow500;
      case IndicatorStatus.PROCESSING:
        return colors.blue500;
      default:
        return colors.gray800;
    }
  };

  return (
    <>
      <Flex
        {...triggerProps}
        justifyContent="space-between"
        onClick={open}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={triggerRef}
        style={{ cursor: 'pointer' }}>
        <Flex
          alignItems="center"
          color={setStatusColor()}
          gap="space8"
          justifyContent="space-between">
          {t('exports.label')}
          <ProgressIndicator status={indicatorStatus} direction="bottom" />
        </Flex>
      </Flex>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} width="450px">
          <ExportsPopoverContent
            onClose={() => close()}
            onClear={() => clearExports()}
            exportItems={notifications}
          />
        </Popover>
      )}
    </>
  );
};
