import { Node } from '@react-types/shared';
import { forwardRef } from 'react';
import { ItemProps } from 'react-virtuoso';
import { Option } from '../Option';
import { VirtuosoContext } from './types';

export const VirtuosoItem = forwardRef<
  HTMLLIElement,
  ItemProps<Node<any>> & VirtuosoContext
>(
  (
    {
      context,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      children: _, // Option component renders its own children
      item,
      ...restProps
    },
    ref
  ) => {
    const { state, itemProps } = context;

    return (
      <Option
        key={item.key}
        height={itemProps.itemHeight}
        item={item}
        state={state}
        showSeparator={itemProps.showSeparator}
        {...restProps}
        ref={ref}
      />
    );
  }
);
