import { ApolloCache, ApolloClient, useApolloClient } from '@apollo/client';
import { captureException, captureMessage } from '@sentry/react';
import {
  CreateCommentOnReimbursementCaseMutation,
  Query,
} from 'generated-types/graphql.types';
import { reimbursementHistoryQuery } from '../toolkit/queries';
import { useParams } from 'react-router-dom';
import { useOptimisticReimbursementComment } from './useOptimisticReimbursementComment';

const COMMENT_SYNC_DELAY_MS = 3000;

const refetchReimbursementHistoryWithDelay = async (
  client: ApolloClient<object>,
  reimbursementId: string
) => {
  setTimeout(async () => {
    try {
      await client.query({
        query: reimbursementHistoryQuery,
        variables: { id: reimbursementId },
        fetchPolicy: 'network-only',
      });
      captureMessage('Refetched reimbursement history after delay');
    } catch (err) {
      captureException(new Error(`Error refetching cache: ${err}`));
    }
  }, COMMENT_SYNC_DELAY_MS);
};

export const useOptimisticCacheUpdateForReimbursementComment = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const client = useApolloClient();

  const getOptimisticComment = useOptimisticReimbursementComment();

  const optimisticCacheUpdateForReimbursementComment = (text?: string) => {
    return async (
      proxy: ApolloCache<CreateCommentOnReimbursementCaseMutation>
    ) => {
      try {
        const reimbursementCacheData = proxy.readQuery<
          Pick<Query, 'reimbursementCaseById'>
        >({
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        });

        if (!reimbursementCacheData?.reimbursementCaseById) {
          await refetchReimbursementHistoryWithDelay(client, reimbursementId);

          return;
        }

        const currentTimeline =
          reimbursementCacheData.reimbursementCaseById.timeline ?? [];
        const optimisticComment = getOptimisticComment(text);

        const updatedTimeline = [optimisticComment, ...currentTimeline];

        proxy.writeQuery({
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
          data: {
            ...reimbursementCacheData,
            reimbursementCaseById: {
              ...reimbursementCacheData.reimbursementCaseById,
              timeline: updatedTimeline,
            },
          },
        });
      } catch (err) {
        captureException(new Error(`Error updating cache: ${err}`));
        await refetchReimbursementHistoryWithDelay(client, reimbursementId);
      }
    };
  };

  return optimisticCacheUpdateForReimbursementComment;
};
