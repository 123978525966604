import { isNil } from 'lodash';
import moment from 'moment';
import { DeepPartial } from 'react-hook-form';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z, ZodIssueCode } from 'zod';
const MIN_AMOUNT = 0.01;
const MAX_AMOUNT = 999999;
const MAX_AMOUNT_TIP = 999;
const fileSchema = z.object({
  name: z.string(),
  url: z.string(),
  id: z.string(),
});

const guestFieldsSchema = z.object({
  internalGuests: z.string().nullish(),
  externalGuests: z.string().nullish(),
});

export const expenseDateSchema = nonEmptyString.superRefine((value, ctx) => {
  const valueMoment = moment(value, moment.ISO_8601);
  const now = moment.tz('Europe/Berlin');
  const oneYearAgoDate = now.clone().subtract(1, 'year').toDate();
  if (valueMoment.isBefore(oneYearAgoDate)) {
    ctx.addIssue({
      code: 'custom',
      params: {
        translationKey:
          'reimbursementView.middleSection.form.hospitality.date.errorMinYear',
      },
    });
  }

  const nowDate = moment.tz('Europe/Berlin').toDate();
  if (valueMoment.isAfter(nowDate)) {
    ctx.addIssue({
      code: 'custom',
      params: {
        translationKey:
          'reimbursementView.middleSection.form.hospitality.date.errorMaxDate',
      },
    });
  }
});
const validatedGuestFieldsSchema = guestFieldsSchema.superRefine(
  (value, context) => {
    if (!value.internalGuests && !value.externalGuests) {
      context.addIssue({
        code: ZodIssueCode.custom,
        path: ['internalGuests'],
      });
      context.addIssue({
        code: ZodIssueCode.custom,
        path: ['externalGuests'],
      });
    }
  }
);

const amountsSchema = z.object({
  totalAmount: z.number().min(MIN_AMOUNT).max(MAX_AMOUNT).nullish(),
  receiptAmount: z.number().min(MIN_AMOUNT).max(MAX_AMOUNT).nullish(),
  tipAmount: z.number().min(MIN_AMOUNT).max(MAX_AMOUNT_TIP).nullish(),
});

const baseGeneralExpenseFormSchema = z.object({
  expenseId: z.string().uuid(),
  reason: nonEmptyString,
  expenseDate: expenseDateSchema,
  files: z.array(fileSchema).min(1),
  expenseType: z.literal('general'),
});

const baseHospitalityFormSchema = z.object({
  expenseId: z.string().uuid(),
  reason: nonEmptyString,
  location: nonEmptyString,
  dayOfExpense: expenseDateSchema,
  files: z.array(fileSchema).min(1),
  expenseType: z.literal('hospitality'),
});

const amountSchemaMandatory = amountsSchema
  .pick({
    totalAmount: true,
    receiptAmount: true,
    tipAmount: true,
  })
  .superRefine((value, ctx) => {
    if (isNil(value.totalAmount)) {
      ctx.addIssue({
        code: 'custom',
        path: ['totalAmount'],
        params: {
          translationKey:
            'reimbursementView.middleSection.form.general.totalAmount.required',
        },
      });
    }

    if (isNil(value.receiptAmount)) {
      ctx.addIssue({
        code: 'custom',
        path: ['receiptAmount'],
        params: {
          translationKey:
            'reimbursementView.middleSection.form.hospitality.receiptAmount.required',
        },
      });
    }
  });

const expense = z
  .discriminatedUnion('expenseType', [
    baseHospitalityFormSchema,
    baseGeneralExpenseFormSchema,
  ])
  .and(validatedGuestFieldsSchema)
  .and(amountSchemaMandatory);

export const expensesFormSchema = z.object({
  expenses: z.array(expense),
});

export type ExpensesFormOutput = z.infer<typeof expensesFormSchema>;

export type ExpenseSchema = z.infer<typeof expense>;

export type ExpenseSchemaOutput = z.infer<typeof expense>;

export type ExpensesFormValues = DeepPartial<ExpensesFormOutput>;
