import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatus } from 'generated-types/graphql.types';
import { ProcessingFormContainer } from 'views/Inbox/DocumentProcessing/ProcessingFormContainer';

interface RejectedProps {
  document: ProcessedDocument;
  purchaseOrderId?: string;
  contactItem?: ProcessingFormContactFieldItem;
  cycleDocument: () => void;
  onGetContactItem?: (value?: ProcessingFormContactFieldItem) => void;
}

export const Rejected = ({
  cycleDocument,
  document,
  purchaseOrderId,
  contactItem,
  onGetContactItem,
}: RejectedProps) => {
  return (
    <ProcessSidebar
      key={purchaseOrderId}
      documentId={document.id}
      globalDocumentId={document.globalDocumentId ?? undefined}
      documentStatus={DocumentStatus.Rejected}>
      <ProcessingFormContainer
        documentId={document.id}
        onApprove={cycleDocument}
        onDeleteDocument={cycleDocument}
        onRequestApproval={cycleDocument}
        contactItem={contactItem}
        onGetContactItem={onGetContactItem}
      />
    </ProcessSidebar>
  );
};
