import { Box } from '@candisio/design-system';
import { TooltipIcon } from 'components/Icons/TooltipIcon';
import { useHasReverseChargeTaxCode } from 'containers/SplitBookings/toolkit/hooks/useHasReverseChargeTaxCode';
import {
  calculateTaxAmount,
  sumTaxAmounts,
} from 'containers/SplitBookings/toolkit/utils';
import { IntegrationName } from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { DocumentApprovalFormValues } from '../approvalFormSchema';

export function useAmountFieldsTooltip(
  bookings: DocumentApprovalFormValues['bookings'],
  currency: DocumentApprovalFormValues['currency']
) {
  const [t] = useTranslation();
  const integration = useIntegrationSettings();

  const hasReverseTaxCode = useHasReverseChargeTaxCode(bookings);

  const bookingsWithHiddenTaxAmounts = bookings.map(b => {
    return {
      taxAmount: calculateTaxAmount(b.netAmount, b.vatRate),
    };
  });

  const totalTaxAmounts = sumTaxAmounts(bookingsWithHiddenTaxAmounts);

  const formattedAmount = amountFormat(totalTaxAmounts, currency);

  if (integration === IntegrationName.Sap && hasReverseTaxCode) {
    return {
      taxAmount: (
        <TooltipIcon>
          <Box width="space256">
            {t(
              'document.requestApproval.inputs.taxAmount.reverseChargeTooltip',
              { amount: formattedAmount }
            )}
          </Box>
        </TooltipIcon>
      ),
    };
  }

  return {};
}
