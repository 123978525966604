import { Card, Grid, Icon, ScrollBox, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const EmptyReimbursementDetailsCard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <ScrollBox scrollDirection="none" scrollbarGutter="stable">
      <Card padding="space24">
        <Grid gap="space12">
          <Grid
            gap="space12"
            templateColumns="repeat(2, auto)"
            placeContent="center">
            <Grid
              background="green100"
              padding="space16"
              placeContent="center"
              borderRadius="medium">
              <Icon icon="receipt" size="space20" />
            </Grid>
            <Grid
              background="pink100"
              padding="space16"
              paddingLeft="space18"
              placeContent="center"
              borderRadius="medium">
              <Icon icon="hospitality" size="space20" />
            </Grid>
          </Grid>
          <Text textAlign="center" fontSize="basic">
            {t('reimbursementView.leftSection.emptyDetails.description')}
          </Text>
        </Grid>
      </Card>
    </ScrollBox>
  );
};
