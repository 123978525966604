import React, { useRef, forwardRef } from 'react';
import mergeRefs from 'react-merge-refs';
import { useTheme } from '../../Theme';
import { Box } from '../Box';
import { ContentBox } from '../FieldContainer/ContentBox';
import { LoadingSkeleton } from '../FieldContainer/LoadingSkeleton';
import { Flex } from '../Flex';
import { Text } from '../Typography/Text';

export type MessageVariant = 'info' | 'error' | 'warning' | 'success';

export type MessageBoxProps = {
  variant: MessageVariant;
  message: string;
  additionalContent?: React.ReactNode;
  isLoading?: boolean;
};

export const MessageBox = forwardRef<HTMLDivElement, MessageBoxProps>(
  ({ variant, message, additionalContent, isLoading }, ref) => {
    const messageRef = useRef<HTMLDivElement>(null);
    const { messageBox } = useTheme();

    return (
      <Box>
        <Flex
          padding="space8 space10"
          borderRadius="medium"
          background={messageBox[variant].background}
          border={`1px solid ${messageBox[variant].borderColor}`}>
          <Text
            color={isLoading ? 'transparent' : 'gray800'}
            css={{
              display: 'inline-block',
              ...(additionalContent && { paddingRight: '0.25rem' }),
            }}
            ref={mergeRefs([messageRef, ref])}>
            <ContentBox
              element={mergeRefs([messageRef, ref])}
              isLoading={isLoading}>
              {message}
            </ContentBox>
          </Text>
          {additionalContent}
          <LoadingSkeleton isLoading={isLoading} element={messageRef} />
        </Flex>
      </Box>
    );
  }
);
