import { FunctionComponent, ReactNode } from 'react';
import { Flex } from '../../../Atoms/Flex';
import { Grid } from '../../../Atoms/Grid';
import { Link } from '../../../Atoms/Link';
import { LottieAnimation } from '../../../Atoms/LottieAnimation';

export interface EmptyStateLayoutProps {
  /** Indicates if there's any filter applied to the table */
  isFiltered: boolean;
  /** Whether text we want to show in the emtpy state*/
  children: ReactNode;
  /** It cleans all the filters applied to the table */
  resetFilters: () => void;
  resetFiltersTranslation?: string;
}

export const EmptyStateLayout: FunctionComponent<EmptyStateLayoutProps> = ({
  isFiltered,
  children,
  resetFilters,
  resetFiltersTranslation,
}: EmptyStateLayoutProps) => {
  return (
    <Grid
      color="gray800"
      minHeight="100%"
      justifyItems="center"
      gap="space16"
      alignItems="center">
      <LottieAnimation
        animationData={isFiltered ? 'noresultsData' : 'nocontactData'}
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        {children}
        {isFiltered && (
          <Link as="button" onClick={() => resetFilters()}>
            {resetFiltersTranslation}
          </Link>
        )}
      </Flex>
    </Grid>
  );
};
