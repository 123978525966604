import { StatusIcon } from 'components/DocumentsTable/Cells/StatusCell';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface EInvoiceInfoIconProps {
  isEInvoice?: boolean;
}

export const EInvoiceInfoIcon = ({ isEInvoice }: EInvoiceInfoIconProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const [allowProcessingOfZugferdEinvoicesFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowProcessingOfZugferdEinvoices,
  ]);

  const [allowProcessingOfXRechnungEinvoicesFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices,
  ]);

  if (
    (allowProcessingOfZugferdEinvoicesFF ||
      allowProcessingOfXRechnungEinvoicesFF) &&
    isEInvoice
  ) {
    return (
      <StatusIcon
        label={t('statuses.eInvoice')}
        icon="eInvoice"
        color="gray600"
      />
    );
  }
};
