import { Box, TruncatedText } from '@candisio/design-system';
import { castArray } from 'lodash';
import { DateFormatters } from 'utils/date_formatter';

export const DateText = ({ value }: { value: Date | [Date, ...Date[]] }) => {
  if (!value) {
    return <Box>-</Box>;
  }

  const values = castArray(value);

  return (
    <TruncatedText
      wordBreak="break-word"
      fontStyle={values.length > 1 ? 'italic' : undefined}>
      {DateFormatters.compact(values[0])}
    </TruncatedText>
  );
};
