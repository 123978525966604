import { Link, Paragraph } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

interface NoEmailCardProps {
  loading: boolean;
}

export const NoEmailCard = ({ loading }: NoEmailCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const email = t('emailInbox.infoPanel.importError.supportAddress');
  const timestamp = DateFormatters.longDatetime(new Date());
  const subject = t('emailInbox.infoPanel.importError.subject', {
    timestamp: timestamp,
  });

  const href = `mailto:${email}?subject=${subject}`;

  return (
    <InfoPanel
      emailInbox={
        <Paragraph>
          <Trans
            i18nKey="emailInbox.infoPanel.noImportEmail.paragraph"
            values={{ email }}>
            There are no registered email accounts for the organization. This
            happens in rare cases, please contact{' '}
            <Link href={href}>{email}</Link> so we can resolve that error.
          </Trans>
        </Paragraph>
      }
      loading={loading}
    />
  );
};
