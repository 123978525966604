import {
  Button,
  ButtonProps,
  Link,
  Popover,
  PopoverProps,
  Text,
} from '@candisio/design-system';
import { motion, MotionProps } from 'framer-motion';
import { ReactNode, RefObject } from 'react';

const MotionPopover = motion(Popover);

interface ManageRelationshipsActivationPopoverProps {
  popoverProps: PopoverProps;
  popoverRef: RefObject<HTMLDivElement>;
  children: ReactNode;
  width?: string;
}

export const WhatsNewWrapper = ({
  popoverProps,
  popoverRef,
  children,
  width,
}: ManageRelationshipsActivationPopoverProps) => {
  return (
    <MotionPopover
      {...(popoverProps as PopoverProps & MotionProps)}
      ref={popoverRef}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
      transition={{ type: 'spring', duration: 2, bounce: 0.5 }}
      padding="space16"
      dismissWithOutsideClick={false}
      background="blue700"
      width={width}
      maxWidth="22rem"
      arrowStyle={{ position: 'absolute', top: '36px' }}
      autoFocus={false}>
      {children}
    </MotionPopover>
  );
};

const WhatsNewTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      color="white"
      fontSize="medium"
      fontWeight="semibold"
      lineHeight="paragraph">
      {children}
    </Text>
  );
};

const WhatsNewText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      fontWeight="lighter"
      lineHeight="paragraph"
      color="white"
      fontSize="basic">
      {children}
    </Text>
  );
};

const WhatsNewButton = ({ onClick, children, ...rest }: ButtonProps) => {
  return (
    <Button
      color="whiteBlue"
      variant="primary"
      width="100%"
      minWidth="260px"
      onClick={onClick}
      {...rest}>
      {children}
    </Button>
  );
};

const WhatsNewLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => {
  return (
    <Link
      href={href}
      style={{ color: '#fff', padding: '8px' }}
      icon="newTab"
      iconPosition="right">
      {children}
    </Link>
  );
};

export const WhatsNew = {
  Wrapper: WhatsNewWrapper,
  Title: WhatsNewTitle,
  Text: WhatsNewText,
  Button: WhatsNewButton,
  Link: WhatsNewLink,
};
