import { useState } from 'react';
import { mergeProps, useHover } from 'react-aria';
import {
  HeaderGroup,
  TableHeaderProps as ReactTableHeaderProps,
} from 'react-table';
import { Box } from '../../Atoms/Box';
import { Button } from '../../Atoms/Button';
import { Grid } from '../../Atoms/Grid';
import { useTheme } from '../../Theme';
import { TruncatedText } from '../Typography';

interface ThProps<TableDataType extends object> {
  column: HeaderGroup<TableDataType>;
  isResizable?: boolean;
  isLastColumn: boolean;
  tableTranslations:
    | {
        sortAscendingLabel: string;
        sortDescendingLabel: string;
        resetSortLabel: string;
      }
    | undefined;
}

export const Th = <TableDataType extends object>({
  column,
  tableTranslations,
  isResizable,
  isLastColumn,
}: ThProps<TableDataType>) => {
  const { space } = useTheme();
  const { hoverProps, isHovered } = useHover({});
  const [isFilterBeingUsed, setIsFilterBeingUsed] = useState(false);

  const handleUpdateIsFilterBeingUsed = (value: boolean) => {
    setIsFilterBeingUsed(value);
  };

  const {
    onClick,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title, // Exclude the title attribute
    ...rest
  } = column.getHeaderProps(
    column.canSort ? column.getSortByToggleProps() : undefined
  ) as ReactTableHeaderProps & {
    // react-table types are incomplete...
    title: string;
    onClick: React.MouseEventHandler;
  };

  const isActionable = column.canFilter || column.canSort;

  const isSorted = column.isSorted;

  const isFiltered = column.filterValue?.length;

  const isFilteredOrSorted = isSorted || isFiltered;

  const renderedHeader = column.render('Header', {
    isFilteredOrSorted,
  });

  const headerText =
    typeof renderedHeader === 'string' ? (
      <TruncatedText color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
        {renderedHeader}
      </TruncatedText>
    ) : (
      renderedHeader
    );

  const isSortVisible =
    (isHovered || isSorted || isFiltered || isFilterBeingUsed) &&
    column.canSort;

  const isFilterVisible =
    column.canFilter &&
    column.Filter &&
    (isHovered || isFiltered || isSorted || isFilterBeingUsed);

  const isResizerVisible =
    (isHovered || column.isResizing) && isResizable && !isLastColumn;

  return (
    <th
      {...mergeProps(hoverProps, rest)}
      id={column.id}
      style={{
        width: column.width ?? space.space128,
        maxWidth: column.maxWidth,
        minWidth: isLastColumn ? 'unset' : column.minWidth,
      }}>
      <Grid>
        <Grid
          alignItems="center"
          autoFlow="column"
          gap="space2"
          justifySelf="start"
          borderRadius="medium"
          // We need to override the padding and make sure the checkbox in the header is aligned with
          // ... the rest of the checkboxes in the table rows. The misaligment happens because the header is small and
          // ... there is a border radius which reduces the space.
          paddingX={column.id === 'selection' ? '5px' : '6px'}
          paddingY="space2"
          background={
            (isHovered || isFilterBeingUsed) && isActionable
              ? 'gray100'
              : 'white'
          }
          justifyContent="start">
          <Grid
            alignItems="center"
            autoFlow="column"
            gap="space2"
            minHeight="space24"
            onClickCapture={onClick}
            borderRadius="small"
            style={{ cursor: isActionable ? 'pointer' : 'default' }}>
            {headerText}
            {isSortVisible && (
              <Button
                icon={
                  column.isSorted
                    ? column.isSortedDesc
                      ? 'caretDown'
                      : 'caretUp'
                    : 'sortOutlined'
                }
                label={
                  column.isSorted
                    ? column.isSortedDesc
                      ? tableTranslations?.resetSortLabel
                      : tableTranslations?.sortDescendingLabel
                    : tableTranslations?.sortAscendingLabel
                }
                size="xsmall"
                variant={column.isSorted ? 'primary' : 'secondary'}
                color="blue"
              />
            )}
          </Grid>
          {isFilterVisible &&
            column.render('Filter', { handleUpdateIsFilterBeingUsed })}
        </Grid>
        {isResizerVisible && (
          <Box
            style={{
              display: 'inline-block',
              transform: 'translateX(50%)',
              touchAction: 'none',
            }}
            background="blue500"
            width="space4"
            height="100%"
            position="absolute"
            right={0}
            top={0}
            zIndex={1}
            {...column.getResizerProps()}
          />
        )}
      </Grid>
    </th>
  );
};
