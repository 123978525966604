import {
  HookFormAmountField,
  HookFormAmountFieldProps,
} from 'components/HookFormFields/HookFormAmountField';
import { useTranslation } from 'react-i18next';
import { DocumentFormValues } from '../schema';

interface TaxAmountProps
  extends Partial<HookFormAmountFieldProps<DocumentFormValues>> {
  hasMultipleBookings: boolean;
}

export const TaxAmount = ({
  currency,
  hasMultipleBookings,
  info,
}: TaxAmountProps) => {
  const [t] = useTranslation();

  return (
    <HookFormAmountField
      name={hasMultipleBookings ? 'taxAmount' : 'bookings.0.taxAmount'}
      currency={currency}
      label={t('document.requestApproval.inputs.taxAmount.label')}
      placeholder={t('document.requestApproval.inputs.taxAmount.placeholder')}
      readOnly
      info={info}
    />
  );
};
