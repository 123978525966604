import {
  DocumentStoredAsEcmDocumentEvent,
  DocumentUpdatedAsEcmDocumentEvent,
  DocumentType,
  Money,
  User,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { isEmpty, isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { useEcmContractTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import { EcmStoredEventItem } from './EcmStoredEventItem';
import { EcmUpdatedEventItem } from './EcmUpdatedEventItem';

// Unfortunately, due to limitations in the backend, there is currently no consistent method available
// to reliably get a list of keys for the updated fields.
type UpdatedFieldKeys =
  | 'amount'
  | 'contactId'
  | 'costCenterId'
  | 'documentDate'
  | 'documentName'
  | 'documentNumber'
  | 'documentType'
  | 'endDate'
  | 'isSensitive'
  | 'notes'
  | 'notifyPerson'
  | 'responsiblePerson'
  | 'startDate'
  | 'documentSubCategory'
  | 'terminationDate'
  | 'terminationReminderDate';

type Event =
  | DocumentStoredAsEcmDocumentEvent
  | DocumentUpdatedAsEcmDocumentEvent;

export type EcmEventFields = Array<{
  key: UpdatedFieldKeys;
  label: string;
  value: any | undefined | null;
  formatter?: ((value: any) => string) | ((value: any) => string[]);
}>;

export const EcmEventsContainer = (event: Event) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { documentMoneyFormatter } = useMoneyFormatter();
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  const { getContractTypeLabel } = useEcmContractTypeOptions();

  const eventName = event.__typename;

  const dateFormatter = (value: string) =>
    DateFormatters.compact(new Date(value));

  const moneyFormatter = (value: Money) => documentMoneyFormatter(value);

  const documentTypeFormatter = (documentType: DocumentType) =>
    ecmDocumentTypeTranslationMap[documentType];

  const contractTypeFormatter = (id: string) => getContractTypeLabel(id) ?? '';

  // Please ensure that you update the `DocumentStoredAsEcmDocumentEventData` and `DocumentUpdatedAsEcmDocumentEventData` fragment
  // when adding a new event field.
  const fieldMap: EcmEventFields = [
    {
      key: 'amount',
      label: t('storageForm.fields.amount.label'),
      value: event.amount,
      formatter: moneyFormatter,
    },
    {
      key: 'contactId',
      label: t('storageForm.fields.contact.label'),
      value: event.contact?.name.value,
    },
    {
      key: 'costCenterId',
      label: t('common:document.requestApproval.inputs.costCenter.label'),
      value: event.costCenter?.readableName,
    },
    {
      key: 'documentDate',
      label: t('storageForm.fields.documentDate.label'),
      value: event.documentDate,
      formatter: dateFormatter,
    },
    {
      key: 'documentName',
      label: t('storageForm.fields.documentName.label'),
      value: event.documentName,
    },
    {
      key: 'documentNumber',
      label: t('storageForm.fields.documentNumber.label'),
      value: event.documentNumber,
    },
    {
      key: 'documentType',
      label: t('storageForm.fields.documentType.label'),
      value: event.documentType,
      formatter: documentTypeFormatter,
    },
    {
      key: 'endDate',
      label: t('storageForm.fields.endDate.label'),
      value: event.endDate,
      formatter: dateFormatter,
    },
    {
      key: 'isSensitive',
      label: t('storageForm.fields.restrictedAccess.label'),
      value: event.isSensitive,
    },
    {
      key: 'notes',
      label: t('storageForm.fields.notes.label'),
      value: event.notes,
    },
    {
      key: 'notifyPerson',
      label: t('storageForm.fields.notifyPerson.historyLabel'),
      value: event.notifyPerson?.primary,
      formatter: (value: User[]) => value.map(v => v.name),
    },
    {
      key: 'responsiblePerson',
      label: t('storageForm.fields.responsiblePerson.historyLabel'),
      value: event.responsiblePerson?.primary[0]?.name,
    },
    {
      key: 'startDate',
      label: t('storageForm.fields.startDate.label'),
      value: event.startDate,
      formatter: dateFormatter,
    },
    {
      key: 'documentSubCategory',
      label: t('storageForm.fields.contractType.label'),
      value: event.documentSubCategory?.id,
      formatter: contractTypeFormatter,
    },
    {
      key: 'terminationDate',
      label: t('storageForm.fields.terminationDate.label'),
      value: event.terminationDate,
      formatter: dateFormatter,
    },
    {
      key: 'terminationReminderDate',
      label: t('storageForm.fields.terminationReminderDate.label'),
      value: event.terminationReminderDate,
      formatter: dateFormatter,
    },
  ];

  const updatedFields = fieldMap.filter(({ key, value }) => {
    if (eventName === 'DocumentStoredAsEcmDocumentEvent') {
      return !isNil(value);
    }

    if (eventName === 'DocumentUpdatedAsEcmDocumentEvent') {
      return event.updatedFields.includes(key);
    }

    return false;
  });

  const formattedUpdatedFields = updatedFields.map(
    ({ key, value, formatter, label }) => {
      let formattedValue;

      if (isNil(value) || isEmpty(value)) {
        formattedValue = t('documentHistoryEvents.valueRemoved');
      } else {
        formattedValue = formatter?.(value) ?? String(value);
      }

      return { key, value: formattedValue, label };
    }
  );

  const documentNumber = event.documentNumber ?? undefined;

  const formattedCreatedAt = event.createdAt
    ? dateFormatter(event.createdAt)
    : undefined;

  const formattedDocumentType = event.documentType
    ? documentTypeFormatter(event.documentType as DocumentType)
    : undefined;

  return (
    <>
      {eventName === 'DocumentStoredAsEcmDocumentEvent' ? (
        <EcmStoredEventItem
          fields={formattedUpdatedFields}
          documentNumber={documentNumber}
          createdAt={formattedCreatedAt}
          documentType={formattedDocumentType}
        />
      ) : (
        <EcmUpdatedEventItem fields={formattedUpdatedFields} />
      )}
    </>
  );
};
