import { Box } from '@candisio/design-system';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';

const DOCUMENT_TAGS_GRID_WIDTH = '50rem';

export const ContractSubCategoriesPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { contractSubCategoriesPromotion } = usePromotionContent();

  return (
    <SettingsLayout
      title={t('contractCustomSubCategories.title')}
      width={DOCUMENT_TAGS_GRID_WIDTH}>
      <Box height="370px">
        <PromotionCard
          promotion={contractSubCategoriesPromotion}
          closable={false}
        />
      </Box>
    </SettingsLayout>
  );
};
