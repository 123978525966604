import React from 'react';
import {
  ColorProps,
  LayoutProps,
  StandardHTMLAttributes,
  TypographyProps,
} from '../../../types';

import { Text } from '../Text';

export interface ParagraphProps
  extends LayoutProps,
    TypographyProps,
    ColorProps,
    StandardHTMLAttributes<HTMLParagraphElement> {}

/**
 * Renders a HTML paragraph (`<p>` element). Sets the default line height
 * multiplier for paragraphs. Accepts color and typography props.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-typography-paragraph}
 */
export const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ children, lineHeight = 'paragraph', ...restProps }, ref) => {
    return (
      <Text as="p" lineHeight={lineHeight} {...restProps} ref={ref}>
        {children}
      </Text>
    );
  }
);
