import { css } from '@emotion/react';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { Row } from 'react-table';
import { Grid } from '../../Atoms/Grid';
import { useTheme } from '../../Theme';

interface TableCellProps {
  cellType: string;
  handleTextSelection:
    | React.MouseEventHandler<HTMLTableCellElement>
    | undefined;
  onRowClick: ((row: Row<any>) => void) | undefined;
  isRowDisabled?: boolean;
  style: CSSProperties | undefined;
  children: ReactNode;
}

export const TableCell = ({
  cellType,
  handleTextSelection,
  onRowClick,
  isRowDisabled,
  children,
  style,
}: TableCellProps) => {
  const { colors } = useTheme();

  const disabledRowStyle = useMemo(
    () =>
      isRowDisabled
        ? css`
            color: ${colors.gray500};
            cursor: default;
          `
        : '',
    [colors.gray500, isRowDisabled]
  );

  const cell = useMemo(() => {
    switch (cellType) {
      case 'selection':
        return (
          <td
            css={disabledRowStyle}
            style={{
              ...style,
              padding: 0,
              height: '1px',
            }}>
            <Grid
              as="label"
              paddingY="space10"
              paddingX="space16"
              alignItems="center"
              onClick={(e: React.MouseEvent<HTMLLabelElement>) => {
                e.stopPropagation();
              }}
              style={{
                cursor: isRowDisabled ? 'default' : 'pointer',
              }}>
              {children}
            </Grid>
          </td>
        );
      case 'openInDatev':
        return <td>{children}</td>;
      default:
        return (
          <td
            style={{
              ...style,
            }}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              !onRowClick ? handleTextSelection : undefined;
            }}
            css={disabledRowStyle}>
            {children}
          </td>
        );
    }
  }, [
    cellType,
    children,
    disabledRowStyle,
    handleTextSelection,
    isRowDisabled,
    onRowClick,
    style,
  ]);

  return cell;
};
