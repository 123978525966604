import { Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const RequestApprovalAction = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Button color="blue">
      {t('reimbursementView.rightSection.reviewfooterAction.request')}
    </Button>
  );
};
