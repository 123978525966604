import { FEATURE } from 'providers/FeatureToggleProvider/types';

export const appointmentLinks: Record<FEATURE, string> = {
  [FEATURE.ABSENCE_MANAGEMENT]:
    'entitlements:promo.modal.bookAppointmentLink.ABSENCE_MANAGEMENT',
  [FEATURE.BDS]: 'entitlements:promo.modal.bookAppointmentLink.BDS',
  [FEATURE.CONTRACTS]: 'entitlements:promo.modal.bookAppointmentLink.CONTRACTS',
  [FEATURE.FULL_TEXT_SEARCH]:
    'entitlements:promo.modal.bookAppointmentLink.FULL_TEXT_SEARCH',
  [FEATURE.INTEGRATION_SAP]: '',
  [FEATURE.POSTING_TEXT]: '',
  [FEATURE.PROVISIONS]:
    'entitlements:promo.modal.bookAppointmentLink.PROVISIONS',
  [FEATURE.PURCHASE_ORDERS]: '',
  [FEATURE.DOCUMENT_RELATIONS]:
    'entitlements:promo.modal.bookAppointmentLink.DOCUMENT_RELATIONS',
  [FEATURE.SEPA_XML]: 'entitlements:promo.modal.bookAppointmentLink.SEPA_XML',
  [FEATURE.ECM]: 'entitlements:promo.modal.bookAppointmentLink.ECM',
  [FEATURE.REIMBURSEMENT]: '',
  [FEATURE.DOCUMENT_PREVIEW]: '',
  [FEATURE.TAGS]: 'entitlements:promo.modal.bookAppointmentLink.ECM',
};
