import {
  DateFilterOption,
  useGetPlannedPaymentDateQuery,
} from 'generated-types/graphql.types';
import { DateFormatters } from 'utils/date_formatter';

export const useGetPlannedPaymentDate = () => {
  const todayDate = new Date();

  // XXX-HACK: We want to filter all the payments from today's date to the future and unfortunaly backend expects a mandatory dateTo field.
  // In order to make it work we calculate the date of a very far away day.

  const futureDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 10)
  );

  const { data, loading } = useGetPlannedPaymentDateQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      dateRangeFilters: {
        plannedPaymentDateFilter: {
          dateFrom: DateFormatters.germanDate(todayDate),
          dateTo: DateFormatters.germanDate(futureDate),
          filterOption: DateFilterOption.Custom,
        },
      },
    },
  });

  const plannedPaymentDateString =
    data?.cardIssuerSettlementsPageBased.records?.[0]?.plannedPaymentDate ??
    undefined;

  const plannedPaymentDate = plannedPaymentDateString
    ? new Date(plannedPaymentDateString)
    : undefined;

  return { loading, plannedPaymentDate };
};
