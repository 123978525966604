import { css } from '@emotion/react';
import { isNil, xor } from 'lodash';
import { forwardRef, ReactNode, useCallback, useMemo } from 'react';
import {
  CellProps,
  Column,
  Filters,
  Row,
  SortingRule,
  TableOptions,
  TableState,
  useTable,
  Cell as ReactTableCell,
} from 'react-table';

import { ListRange, TableVirtuoso } from 'react-virtuoso';
import { Flex } from '../../Atoms/Flex';
import { Grid } from '../../Atoms/Grid';
import { ScrollBox } from '../../Atoms/ScrollBox';
import { Spinner } from '../../Atoms/Spinner';
import {
  TableBody,
  TableElement,
  TableElementProps,
  TableHead,
  TableRow,
} from '../../Atoms/TableElement';
import { useTheme } from '../../Theme';
import { BorderProps } from '../../types';
import { Cell } from './Cell/Cell';
import { SelectionCell } from './Cell/SelectionCell';
import { EmptyStateLayout } from './EmptyState';
import { Filter } from './Filter';
import { SelectAllHeader } from './SelectAllHeader/SelectAllHeader';
import { TableCell } from './TableCell';
import { TableHeader } from './TableHeader';
import {
  CellWrapperProps,
  CustomEmptyStateProps,
  FilterOptionsAccessor,
  SelectionOptions,
} from './types';
import { useControlledFiltersAndSort } from './utils/useControlledFiltersAndSort';
import { useResizeObserver } from './utils/useResizeObserver';
import { useTablePlugins } from './utils/useTablePlugins';

// We are using an older version of typescript where the use of object type is not recommended and therefore we need the following line. See issue https://github.com/microsoft/TypeScript/issues/21732
// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<TableDataType extends object>
  extends Exclude<TableOptions<TableDataType>, 'getRowIds'> {
  borderBottomRadius?: BorderProps['borderBottomRadius'];
  borderTopRadius?: BorderProps['borderTopRadius'];
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  cellWrapper?: (props: CellWrapperProps<TableDataType>) => ReactNode | null;
  defaultEmptyStateContent?: ReactNode;
  filterOptions?: FilterOptionsAccessor<TableDataType>;
  filterPopoverTranslations?: {
    applyFilterButton: string;
    resetFilterButton: string;
    filterLabel: string;
    searchFieldPlaceholder?: string;
  };
  height?: string;
  isLoading?: boolean;
  isSingleSelect?: boolean;
  minWidth?: TableElementProps['minWidth'];
  onAllRowsSelected?: (selectedRows: TableDataType[]) => void;
  onEndReached?: (index: number) => void | undefined;
  onFilter?: (filters: Filters<TableDataType>) => void;
  onRangeChanged?: ((range: ListRange) => void) | undefined;
  onRowClick?: (row: Row<TableDataType>) => void;
  onRowSelected?: (row: TableDataType[]) => void;
  onSelectionChange?: (rowIds: string[]) => void;
  onSort?: (sortBy: SortingRule<TableDataType>[]) => void;
  /** Callback invoked when resizing ends, make sure it is memoized */
  onResizeEnd?: (columnId: string, columnSize: number) => void;
  overscan?: number;
  selectedRowIds?: string[];
  selectionOptions?: SelectionOptions<TableDataType>;
  showDefaultEmptyState?: boolean;
  style?: React.CSSProperties;
  tableFooter?: ReactNode;
  totalCount?: number;
  tableTranslations?: {
    sortAscendingLabel: string;
    sortDescendingLabel: string;
    resetSortLabel: string;
  };
  width?: TableElementProps['width'];
  isResizable?: boolean;
  rowOverlay?: (props: { data: TableDataType }) => JSX.Element;
  getCellStyles?: (
    cell: ReactTableCell<TableDataType, any>
  ) => React.CSSProperties;
}

export interface BaseTableDataType {
  id: string;
  canBeSelectedTooltipText?: string | undefined;
  highlighted?: boolean;
  selected?: boolean;
  isDisabled?: boolean;
}

export const Table = <TableDataType extends BaseTableDataType>({
  borderBottomRadius = 'basic',
  borderTopRadius = 'basic',
  columns,
  customEmptyState: CustomEmptyState,
  cellWrapper: CellWrapper,
  defaultColumn: defaultColumnProp,
  defaultEmptyStateContent,
  filterOptions,
  filterPopoverTranslations,
  height = '100%',
  isLoading,
  isSingleSelect = false,
  isResizable = false,
  minWidth,
  onAllRowsSelected,
  onEndReached,
  onFilter,
  onRangeChanged,
  onRowClick,
  onSelectionChange,
  onRowSelected,
  onResizeEnd,
  onSort,
  selectedRowIds,
  selectionOptions,
  showDefaultEmptyState = true,
  style,
  tableFooter,
  tableTranslations,
  overscan = 0,
  width,
  rowOverlay,
  getCellStyles,
  ...restProps
}: TableProps<TableDataType>) => {
  const { space } = useTheme();

  const defaultColumn = useMemo(
    (): Partial<Column<TableDataType>> => ({
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof TableDataType];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              return (
                <Filter<TableDataType>
                  column={column}
                  options={filterOption.data}
                  applyFilterButton={
                    filterPopoverTranslations?.applyFilterButton
                  }
                  filterLabel={filterPopoverTranslations?.filterLabel}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                  resetFilterButton={
                    filterPopoverTranslations?.resetFilterButton
                  }
                  searchFieldPlaceholder={
                    filterPopoverTranslations?.searchFieldPlaceholder
                  }
                />
              );
            }

            return null;
          }
        : undefined,
      width: space.space128,
      ...defaultColumnProp,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [space, filterOptions, defaultColumnProp]
  );

  const useControlledState = (state: TableState<TableDataType>) => {
    return useMemo(() => {
      if (isSingleSelect) {
        if (selectedRowIds && selectedRowIds.length === 1) {
          const selectedRowIdsObject: Record<string, boolean> = {
            [selectedRowIds[0]]: true,
          };

          return {
            ...state,
            selectedRowIds: selectedRowIdsObject,
          };
        }

        return state;
      }

      if (selectedRowIds) {
        return {
          ...state,
          selectedRowIds: selectedRowIds?.reduce<Record<string, boolean>>(
            (result, id) => {
              result[id] = true;

              return result;
            },
            {}
          ),
        };
      }

      return state;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, selectedRowIds]);
  };

  const getRowId = useCallback((row: TableDataType) => row.id, []);

  const plugins = useTablePlugins<TableDataType>({ isResizable });

  const {
    getTableBodyProps,
    getTableProps,
    selectedFlatRows,
    headerGroups,
    state: { sortBy, filters, columnResizing },
    prepareRow,
    rows,
    setAllFilters,
  } = useTable(
    {
      defaultCanFilter: false,
      defaultColumn,
      columns,
      disableMultiSort: true,
      getRowId,
      useControlledState,
      initialState: restProps.initialState,
      manualFilters: onFilter !== undefined,
      manualSortBy: onSort !== undefined,
      ...restProps,
    },
    ...plugins,
    (hooks) => {
      if (onRowSelected) {
        /** @ts-expect-error TODO React 18 Upgrade props mismatch */
        hooks.columns.push((columns) => {
          return [
            {
              id: 'selection',
              width: space.space48,
              Header: (props) =>
                !isSingleSelect && onAllRowsSelected ? (
                  <SelectAllHeader
                    canBeSelected={selectionOptions?.canBeSelected}
                    flatRows={props.flatRows}
                    selectedFlatRows={props.selectedFlatRows}
                    onAllRowsSelected={onAllRowsSelected}
                  />
                ) : null,
              Cell: (
                props: CellProps<TableDataType> & { isHovered: boolean }
              ) => {
                const selectCheckboxProps = {
                  isVisible: true,
                  isReadOnly: false,
                  forceSelected: false,
                  ...selectionOptions?.selectCheckboxProps?.(
                    props.row.original
                  ),
                };

                return (
                  <SelectionCell
                    {...props}
                    isDisabled={
                      selectionOptions?.canBeSelected
                        ? !selectionOptions.canBeSelected(props.row.original)
                        : undefined
                    }
                    onRowSelected={onRowSelected}
                    disabledReason={selectionOptions?.canBeSelectedTooltipText}
                    onSelectionChange={onSelectionChange}
                    selectedRowIds={selectedRowIds}
                    isSingleSelect={isSingleSelect}
                    isVisible={selectCheckboxProps?.isVisible}
                    isReadOnly={selectCheckboxProps?.isReadOnly}
                    forceSelected={selectCheckboxProps?.forceSelected}
                  />
                );
              },
            },
            ...columns,
          ];
        });
      }
    }
  );

  useControlledFiltersAndSort({ onSort, sortBy, onFilter, filters });

  const handleRowClick = (row: Row<TableDataType>) => {
    if (onRowClick) {
      onRowClick(row);
    } else {
      // When there is no callback to check if a row is selectable
      // ... selection is always allowed
      const isSelectionAllowed =
        (selectionOptions?.canBeSelected &&
          selectionOptions?.canBeSelected(row?.original)) ||
        !selectionOptions?.canBeSelected;

      if (isSelectionAllowed) {
        if (isSingleSelect) {
          onRowSelected?.(
            selectedRowIds?.[0] === row.original.id ? [] : [row.original]
          );
        } else {
          const newSelection = xor(selectedFlatRows, [row]);

          const selectedRows = newSelection.map(
            (selection) => selection.original
          );

          onRowSelected?.(selectedRows);
        }
      }
    }
  };

  const handleTextSelection = (row: Row<TableDataType>) => {
    const isRowDisabled = row.original?.isDisabled;
    const selectedText = document.getSelection();
    const isSelectionTypeOfRange =
      selectedText && selectedText.type === 'Range';

    if (!isSelectionTypeOfRange && !isRowDisabled && row.original.id) {
      onRowClick?.(row);
    }
  };

  useResizeObserver({ columnResizing }, (columnId, columnSize) => {
    onResizeEnd?.(columnId, columnSize);
  });

  const isFiltered = filters.length > 0;
  const isTableEmpty = rows.length < 1;
  const isFetchingData = rows.length > 0 && isLoading;

  return (
    <Flex
      direction="column"
      height={height}
      background="gray0"
      width="100%"
      position="relative"
      overflow="hidden"
      borderTopRadius={borderTopRadius}
      borderBottomRadius={borderBottomRadius}>
      <TableVirtuoso
        style={{
          ...style,
          width,
          minWidth,
        }}
        data={rows}
        overscan={overscan}
        endReached={onEndReached}
        rangeChanged={onRangeChanged}
        context={{
          isResizable,
        }}
        components={{
          Scroller: ScrollBox,
          Table: ({ style, ...props }) => (
            <TableElement
              {...getTableProps()}
              {...props}
              position="static"
              style={{
                ...style,
                tableLayout: 'fixed',
              }}
              // static positioning allows the loading spinner to be positioned relative to the element wrapping the table
              // !important and and below styles overwrites the tfoot elements default styles
              // and positions the table footer correctly depending on the tables state
              css={css`
                tfoot {
                  position: ${!tableFooter
                    ? 'static'
                    : isTableEmpty
                      ? 'absolute'
                      : 'relative'} !important;
                  ${tableFooter && isTableEmpty
                    ? 'bottom: 0; left: 50%; transform: translate(-50%);'
                    : null}
                }
              `}
            />
          ),
          /** @ts-expect-error TODO React 18 Upgrade props mismatch */
          TableHead: TableHead,
          /** @ts-expect-error TODO React 18 Upgrade props mismatch */
          TableBody: forwardRef((props, ref) => (
            <TableBody {...getTableBodyProps()} {...props} ref={ref} />
          )),
          TableRow: (props) => {
            const index = props['data-index'];
            const row = rows[index];
            const isRowDisabled = row.original?.isDisabled;

            const isRowClickable =
              !isNil(onRowClick) || !isNil(selectionOptions?.canBeSelected);

            return (
              <TableRow
                {...props}
                {...row.getRowProps()}
                disabled={isRowDisabled}
                selected={row.original.selected}
                disabledReason={row?.original?.canBeSelectedTooltipText}
                highlight={row.original.highlighted}
                id={row.id}
                isRowClickable={isRowClickable}
                key={index}
                onClick={() => handleRowClick(row)}
                overlay={rowOverlay}
              />
            );
          },
        }}
        fixedHeaderContent={() => {
          return (
            <TableHeader
              headerGroups={headerGroups}
              tableTranslations={tableTranslations}
              isResizable={isResizable}
              hasRowOverlay={!!rowOverlay}
            />
          );
        }}
        fixedFooterContent={() =>
          isFetchingData ? (
            <tr>
              <td>
                <Grid
                  padding="space20"
                  position="absolute"
                  left="50%"
                  style={{ transform: 'translate(-50%)' }}>
                  <Spinner size="space64" color="gray400" />
                </Grid>
              </td>
            </tr>
          ) : tableFooter ? (
            <tr>
              <td colSpan={onRowSelected ? columns.length + 1 : columns.length}>
                {tableFooter}
              </td>
            </tr>
          ) : null
        }
        itemContent={(_, row) => {
          prepareRow(row);

          const isRowDisabled = row.original?.isDisabled;

          return row.cells.map((cell, index) => {
            const cellStyle = getCellStyles ? getCellStyles(cell) : {};

            return cell ? (
              <TableCell
                style={{ ...cell.getCellProps().style, ...cellStyle }}
                cellType={cell.column.id}
                handleTextSelection={() => {
                  handleTextSelection(row);
                }}
                onRowClick={() => {
                  if (onRowClick) {
                    onRowClick(row);
                  }
                }}
                isRowDisabled={isRowDisabled}
                key={index}>
                {CellWrapper ? (
                  <CellWrapper row={row}>{cell.render('Cell')}</CellWrapper>
                ) : (
                  cell.render('Cell')
                )}
              </TableCell>
            ) : null;
          });
        }}
      />

      {isTableEmpty && showDefaultEmptyState ? (
        !isLoading ? (
          <Grid
            flex="1"
            padding="space32"
            placeContent="center"
            // We need to center this absolute positioned element to its parent while keeping the scrollbar
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%,-50%)' }}>
            {CustomEmptyState ? (
              <CustomEmptyState
                resetFilters={() => {
                  setAllFilters([]);
                }}
              />
            ) : (
              <EmptyStateLayout
                isFiltered={isFiltered}
                resetFilters={() => {
                  setAllFilters([]);
                }}>
                {defaultEmptyStateContent}
              </EmptyStateLayout>
            )}
          </Grid>
        ) : (
          <Grid
            flex="1"
            position="absolute"
            padding="space32"
            placeContent="center"
            background="gray0"
            // We need to center this absolute positioned element to its parent while keeping the scrollbar
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%,-50%)' }}>
            <Spinner
              size="space64"
              color="gray400"
              data-testid="generic-table-loading"
            />
          </Grid>
        )
      ) : null}
    </Flex>
  );
};
