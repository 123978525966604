import React from 'react';
import { useTheme } from '../../Theme';
import { BorderProps } from '../../types';
import { Box, BoxProps } from '../Box';

type Variant = 'default' | 'info' | 'warning';
type CornerVariant = 'top' | 'bottom' | 'left' | 'right' | 'all';

const cornerVariants: Partial<Record<CornerVariant, BorderProps>> = {
  top: { borderBottomLeftRadius: 'none', borderBottomRightRadius: 'none' },
  bottom: { borderTopLeftRadius: 'none', borderTopRightRadius: 'none' },
  left: { borderTopRightRadius: 'none', borderBottomRightRadius: 'none' },
  right: { borderBottomLeftRadius: 'none', borderTopLeftRadius: 'none' },
};

export interface CardProps extends Omit<BoxProps, 'as' | 'ref'> {
  corners?: CornerVariant;
  variant?: Variant;
}

/**
 * An elevated container with rounded corners and different variants for
 * different types of content.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/story/atoms-layout-card}
 *
 * @param {BoxShadow} boxShadow Box shadow around the card
 * @param {React.ReactNode} children Content
 * @param {CornerVariant} corners Specifies which corners are to be rounded
 * @param {Padding} padding Padding around Card content
 * @param {Variant} variant Visual variants of the Card
 */
export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      boxShadow = 'none',
      corners = 'all',
      padding = 'space16',
      variant = 'default',
      ...restProps
    },
    ref
  ) => {
    const { card } = useTheme();

    return (
      <Box
        borderRadius="medium"
        boxShadow={boxShadow}
        padding={padding}
        ref={ref}
        {...card[variant]}
        {...cornerVariants[corners]}
        {...restProps}
      />
    );
  }
);
