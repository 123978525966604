import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { Key, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { useContractCustomSubCategoryData } from './useContractCustomSubCategoryData';
import { ContractSubcategoriesSortBy } from './utils';

export const useContractCustomSubCategoryListLogic = () => {
  const organization = useOrganizationId();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [queryString, setQueryString] = useState('');
  const isArchived = searchParams.get('archived') === 'true';

  const [sortBy, setSortBy] = useState(
    ContractSubcategoriesSortBy.alphabetically
  );

  const {
    activeCount,
    archivedCount,
    countLoading,
    handleDebounceSearch,
    contractSubCategories,
  } = useContractCustomSubCategoryData({
    search: queryString,
    sortBy,
  });

  const handleCreateSubCategory = () => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}/create`,
      search: searchParams.toString(),
    });
  };

  const onSelect = (id: Key) => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}/${id}`,
      search: searchParams.toString(),
    });
  };

  const onSearchFilter = (filter: string) => {
    handleDebounceSearch(filter);
    setQueryString(filter);
  };

  const onTabChanged = (key: Key) => {
    if (key === 'archived') {
      searchParams.set('archived', 'true');
    } else {
      searchParams.delete('archived');
    }

    navigate({
      search: searchParams.toString(),
    });
  };

  const onSortBy = useCallback((key: Key) => {
    setSortBy(key as ContractSubcategoriesSortBy);
  }, []);

  return {
    isArchived,
    queryString,
    handleCreateSubCategory,
    onSearchFilter,
    onSelect,
    activeCount,
    archivedCount,
    countLoading,
    contractSubCategories,
    onTabChanged,
    selectedKey: isArchived ? 'archived' : 'active',
    onSortBy,
    sortBy,
  };
};
