import gql from 'graphql-tag';

export const tagFragment = gql`
  fragment Tag on Tag {
    id
    name
    description
    isActive
    createdAt
    createdBy {
      id
      name
      avatarUrl
    }
  }
`;

export const tagQuery = gql`
  query tag($id: String!) {
    tag(id: $id) {
      ...Tag
    }
  }
`;

export const tagsQuery = gql`
  query tags($input: GetTagsInput) {
    tags(input: $input) {
      ...Tag
      documentCount
    }
  }
`;

export const checkTagNameDuplicationQuery = gql`
  query checkTagNameDuplication($name: String!, $isActive: Boolean) {
    tagsForDuplicationCheck(name: $name, isActive: $isActive)
  }
`;

export const createTagMutation = gql`
  mutation createTag($input: CreateTagInput!) {
    createTag(input: $input) {
      ...Tag
    }
  }
`;

export const updateTagMutation = gql`
  mutation updateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      ...Tag
    }
  }
`;

export const updateTagStatusMutation = gql`
  mutation updateTagStatus($input: UpdateTagStatusInput!) {
    updateTagStatus(input: $input) {
      ...Tag
    }
  }
`;

export const updateDocumentTagsMutation = gql`
  mutation updateDocumentTags($input: UpdateDocumentTagsInput!) {
    updateDocumentTags(input: $input) {
      ...Tag
    }
  }
`;

export const mergeDocumentTagsMutation = gql`
  mutation mergeTags($input: MergeTagsInput!) {
    mergeTags(input: $input) {
      id
    }
  }
`;
