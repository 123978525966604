import {
  Card,
  Flex,
  Grid,
  TruncatedText,
  Box,
  Text,
  Icon,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';

type Expense = 'hospitality' | 'general';
interface ExpenseFormWrapperProps {
  totalAmount: number | null | undefined;
  reason: string | null | undefined;
  children: ReactNode;
  expenseType: Expense;
  expenseId: string;
}

const mappedTypesToItem: Record<
  Expense,
  { placeholder: string; icon: JSX.Element }
> = {
  general: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: (
      <Grid
        background="green100"
        placeContent="center"
        borderRadius="small"
        padding="space4">
        <Icon icon="receipt" size="space18" />
      </Grid>
    ),
  },
  hospitality: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: (
      <Grid
        background="pink100"
        placeContent="center"
        borderRadius="small"
        paddingLeft="6px"
        padding="space4">
        <Icon icon="hospitality" size="space18" />
      </Grid>
    ),
  },
};

export const ExpenseFormWrapper = ({
  totalAmount,
  expenseType,
  reason,
  children,
  expenseId,
}: ExpenseFormWrapperProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { itemRefs } = useReimbursementFormsContext();

  const formattedAmount = totalAmount
    ? amountFormatNonNull(totalAmount, 'EUR')
    : amountFormatNonNull(0, 'EUR');

  const expense = mappedTypesToItem[expenseType];

  const title = reason ? reason : t(expense.placeholder);

  return (
    <Box
      id={expenseId}
      ref={(elm: HTMLDivElement) => {
        if (itemRefs?.current) {
          itemRefs.current[expenseId] = elm;
        }
      }}>
      <Card
        background="gray100"
        padding="space16 space40"
        borderBottom="1px solid gray250"
        borderBottomRadius="none">
        <Grid gap="space4">
          <Grid templateColumns="2fr 1.5fr" gap="space16" alignItems="center">
            <Flex gap="space8" alignItems="center">
              {expense.icon}

              <TruncatedText fontSize="xxlarge" height="fit-content">
                {title}
              </TruncatedText>
            </Flex>
            <Box justifySelf="end" fontSize="large">
              <Trans
                values={{ totalAmount: formattedAmount }}
                parent={TruncatedText}
                i18nKey="reimbursementView.middleSection.form.hospitality.totalAmountTitle"
                t={t}>
                Total amount
                <Text fontSize="large" fontWeight="semibold">
                  {formattedAmount}
                </Text>
              </Trans>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card
        borderTopRadius="none"
        background="gray50"
        padding="space24 space40">
        {children}
      </Card>
    </Box>
  );
};
