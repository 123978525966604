import {
  Card,
  Grid,
  Heading,
  Image,
  Link,
  Paragraph,
  Skeleton,
  Text,
} from '@candisio/design-system';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

export interface InfoPanelProps {
  analyticsOnClick?: () => void;
  backgroundColor?: string;
  buttonTrigger?: ReactNode;
  children?: ReactNode;
  emailInbox?: ReactNode;
  externalLink?: {
    text: string;
    href: string;
  };
  intercomLink?: ReactNode;
  loading?: boolean;
  logo?: {
    alt?: string;
    src: string;
  };
  additionalParagraph?: string;
  sections?: {
    subtitle?: string;
    paragraphs: string[];
  }[];
  skeletonLength?: number;
  title?: string;
  width?: string;
}

export const INFO_PANEL_WIDTH = '288px';

const MotionGrid = motion(Grid);

export const InfoPanel = ({
  analyticsOnClick,
  backgroundColor = 'gray250',
  buttonTrigger,
  children,
  emailInbox,
  externalLink,
  intercomLink,
  loading,
  logo,
  additionalParagraph,
  sections,
  skeletonLength = 4,
  title,
  width = INFO_PANEL_WIDTH,
}: InfoPanelProps) => {
  const hasLinks = intercomLink || buttonTrigger || externalLink;

  return (
    <Card
      alignSelf="start"
      background={backgroundColor}
      padding="space20 space16"
      width={width}>
      {loading ? (
        <Grid gap="space4">
          {Array.from({ length: skeletonLength }, (_, index) => (
            <Skeleton height="space16" width="100%" key={index} />
          ))}
        </Grid>
      ) : (
        <MotionGrid
          gap="space16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}>
          {title && <Heading as="h3">{title}</Heading>}
          {logo && (
            <Image height="space64" src={logo.src} alt={logo.alt ?? ''} />
          )}
          {emailInbox}
          {Array.isArray(sections) &&
            sections.map((section, index) => (
              <Grid gap="space16" key={index}>
                {section.subtitle && (
                  <Text as="h4" fontWeight="semibold">
                    {section.subtitle}
                  </Text>
                )}
                {section.paragraphs.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Grid>
            ))}
          {additionalParagraph && <Paragraph>{additionalParagraph}</Paragraph>}
          {children}
          {hasLinks && (
            <Grid gap="space8">
              {intercomLink}
              {buttonTrigger}
              {externalLink && (
                <Link
                  href={externalLink.href}
                  onClick={analyticsOnClick}
                  external>
                  {externalLink.text}
                </Link>
              )}
            </Grid>
          )}
        </MotionGrid>
      )}
    </Card>
  );
};
