import { isNil } from 'lodash';
import { ComponentProps, forwardRef, useRef } from 'react';
import { AriaListBoxOptions, useListBox } from 'react-aria';
import { Item, ListProps, SelectState, useListState } from 'react-stately';
import { Components, Virtuoso } from 'react-virtuoso';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Option } from '../Option';
import { ScrollBox } from '../ScrollBox';
import { List, LIST_PADDING } from './List';
import { VirtuosoItem } from './VirtuosoItem';
import { VirtuosoList } from './VirtuosoList';
import { VirtuosoScroller } from './VirtuosoScroller';

export interface ListBoxProps<TListItem = any>
  extends LayoutProps,
    Omit<StandardHTMLAttributes<HTMLDivElement>, 'autoFocus' | 'children'> {
  autoFocus?: AriaListBoxOptions<TListItem>['autoFocus'];
  children?: ListProps<TListItem>['children'];
  defaultSelectedKeys?: AriaListBoxOptions<TListItem>['defaultSelectedKeys'];
  disabledKeys?: AriaListBoxOptions<TListItem>['disabledKeys'];
  disallowEmptySelection?: AriaListBoxOptions<TListItem>['disallowEmptySelection'];
  height?: string;
  /** Use together with `isVirtualized` to specify initial scroll position of selected item */
  initialTopMostItemIndex?: ComponentProps<
    typeof Virtuoso
  >['initialTopMostItemIndex'];
  isVirtualized?: AriaListBoxOptions<TListItem>['isVirtualized'];
  /** Necessary if we need to render inside the dropdown items taller than 32px */
  itemHeight?: LayoutProps['height'];
  items?: ListProps<TListItem>['items'];
  keyboardDelegate?: AriaListBoxOptions<TListItem>['keyboardDelegate'];
  label?: AriaListBoxOptions<TListItem>['label'];
  onAction?: AriaListBoxOptions<TListItem>['onAction'];
  onBlur?: AriaListBoxOptions<TListItem>['onBlur'];
  /** Called when user scrolls to the end of the list */
  onEndReached?: (index: number) => void;
  onFocus?: AriaListBoxOptions<TListItem>['onFocus'];
  onFocusChange?: AriaListBoxOptions<TListItem>['onFocusChange'];
  onSelectionChange?: AriaListBoxOptions<TListItem>['onSelectionChange'];
  selectedKeys?: AriaListBoxOptions<TListItem>['selectedKeys'];
  selectionBehavior?: AriaListBoxOptions<TListItem>['selectionBehavior'];
  selectionMode?: AriaListBoxOptions<TListItem>['selectionMode'];
  shouldFocusOnHover?: AriaListBoxOptions<TListItem>['shouldFocusOnHover'];
  shouldFocusWrap?: AriaListBoxOptions<TListItem>['shouldFocusWrap'];
  shouldSelectOnPressUp?: AriaListBoxOptions<TListItem>['shouldSelectOnPressUp'];
  shouldUseVirtualFocus?: AriaListBoxOptions<TListItem>['shouldUseVirtualFocus'];
  /** Optional externally-controlled state */
  state?: SelectState<any>;
  showSeparator?: boolean;
}

export const defaultChildren: ListProps<any>['children'] = ({
  key,
  children,
  textValue,
}) => (
  <Item key={key} textValue={textValue}>
    {children}
  </Item>
);

export const ListBox = forwardRef<HTMLDivElement, ListBoxProps>(
  (
    {
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      autoFocus,
      children = defaultChildren,
      defaultSelectedKeys,
      disabledKeys,
      disallowEmptySelection,
      height = '0',
      id,
      initialTopMostItemIndex,
      isVirtualized,
      items = [],
      keyboardDelegate,
      label,
      maxHeight,
      onAction,
      onBlur,
      onEndReached,
      onFocus,
      onFocusChange,
      onSelectionChange,
      selectedKeys,
      selectionBehavior,
      selectionMode,
      shouldFocusOnHover,
      shouldFocusWrap,
      shouldSelectOnPressUp,
      shouldUseVirtualFocus,
      state: stateProp,
      itemHeight = 'space32',
      showSeparator = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const listRef = useRef<HTMLUListElement>(null);

    const localState = useListState({
      defaultSelectedKeys,
      disabledKeys,
      disallowEmptySelection,
      items,
      onSelectionChange,
      selectedKeys,
      selectionBehavior,
      selectionMode,
      children,
    });

    const state = stateProp ?? localState;

    // Get props for the listbox
    const { listBoxProps } = useListBox(
      {
        'aria-describedby': ariaDescribedBy,
        'aria-details': ariaDetails,
        'aria-label':
          // suppress console warning
          ariaLabel || ariaLabelledBy ? ariaLabel : 'List',
        'aria-labelledby': ariaLabelledBy,
        autoFocus: stateProp?.focusStrategy ?? autoFocus,
        defaultSelectedKeys,
        disabledKeys,
        disallowEmptySelection: true,
        id,
        isVirtualized,
        items,
        keyboardDelegate,
        label,
        onAction,
        onBlur,
        onFocus,
        onFocusChange,
        onSelectionChange,
        selectedKeys,
        selectionBehavior,
        selectionMode,
        shouldFocusOnHover,
        shouldFocusWrap,
        shouldSelectOnPressUp,
        shouldUseVirtualFocus,
      },
      state,
      listRef
    );

    // We're using `Array.from(state.collection)` rather than
    // `[...state.collection]` to avoid problems downstream in frontend-web
    const options = Array.from(state.collection);

    const itemsPixelHeight =
      parseInt(itemHeight.toString().replace('space', '')) * options.length;

    // tests if space token's name has changed
    if (!itemHeight.toString().includes('space')) {
      console.error(
        `Theme token ${itemHeight} does not include "space" prefixing the number. This is needed to calculate the popover height in ListBox`
      );
    }

    const heightPixelHeight = parseInt(height);

    // calculates if fixed height is larger that the items height
    // the lesser is used to set the height of the popover
    const popoverHeight =
      itemsPixelHeight < heightPixelHeight || heightPixelHeight === 0
        ? `calc((${itemHeight} * ${options.length}) + (${LIST_PADDING} * 2))`
        : `${heightPixelHeight}px`;

    if (isVirtualized) {
      return (
        <Virtuoso
          components={{
            Scroller: VirtuosoScroller as Components['Scroller'],
            List: VirtuosoList as Components['List'],
            Item: VirtuosoItem as Components['Item'],
          }}
          tabIndex={undefined}
          context={{
            listProps: {
              ...listBoxProps,
              ref: listRef,
            },
            scrollerProps: {
              // Hack to make it work with `maxHeight`
              height:
                popoverHeight ??
                `calc((${itemHeight} * ${options.length}) + (${LIST_PADDING} * 2))`,
              maxHeight: maxHeight,
              ...restProps,
              ref: forwardedRef,
            },
            itemProps: {
              itemHeight,
              showSeparator,
            },
            state,
          }}
          data={options}
          endReached={onEndReached}
          {...(!isNil(initialTopMostItemIndex) && { initialTopMostItemIndex })}
        />
      );
    }

    return (
      <ScrollBox maxHeight={maxHeight} {...restProps} ref={forwardedRef}>
        <List {...listBoxProps} ref={listRef}>
          {options.map((option) => (
            <Option
              key={option.key}
              item={option}
              state={state}
              showSeparator={showSeparator}
            />
          ))}
        </List>
      </ScrollBox>
    );
  }
);
