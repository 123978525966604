import { getTranslationContext } from 'orgConfig';
import { ErrorMessages } from 'utils/zodFormValidation';
import { reimbursementSplitBookingsFormSchema } from './reimbursementSplitBookingsFormSchema';

type ExcludeKeys =
  | 'reimbursementItemBookings'
  | 'reimbursementItemBookings.0.bookings'
  | 'reimbursementItemBookings.0.bookings.0.bookingId'
  | 'reimbursementItemBookings.0.reimbursementItemId'
  | 'reimbursementItemBookings.0.currency'
  | 'reimbursementItemBookings.0.reimbursementItemType'
  | 'reimbursementItemBookings.0.grossAmount'
  | 'reimbursementItemBookings.0.bookings.0.costCenter'
  | 'reimbursementItemBookings.0.bookings.0.costCenter.inputValue'
  | 'reimbursementItemBookings.0.bookings.0.costObject'
  | 'reimbursementItemBookings.0.bookings.0.costObject.inputValue'
  | 'reimbursementItemBookings.0.bookings.0.extraCostInfo'
  | 'reimbursementItemBookings.0.bookings.0.extraCostInfo.inputValue'
  | 'reimbursementItemBookings.0.bookings.0.generalLedgerAccount'
  | 'reimbursementItemBookings.0.bookings.0.generalLedgerAccount.inputValue'
  | 'reimbursementItemBookings.0.bookings.0.taxPresentation';

export const reimbursementSplitBookingsFormErrorMessages: ErrorMessages<
  ReturnType<typeof reimbursementSplitBookingsFormSchema>,
  ExcludeKeys
> = {
  'reimbursementItemBookings.0.remainingAmount': {
    label: 'splitBookings.remainingAmount.label',
    custom: {
      translationKey: 'splitBookings.remainingAmount.sumAmountError',
    },
  },
  'reimbursementItemBookings.0.bookings.0.splitAmount': {
    label: 'splitBookings.splitAmount.label',
    custom: {
      translationKey: 'splitBookings.splitAmount.minAmountError',
    },
  },
  'reimbursementItemBookings.0.bookings.0.costCenter.value': {
    label: 'splitBookings.costCenter.label',
    custom: {
      translationKey: 'splitBookings.costCenter.notSetError',
    },
  },
  'reimbursementItemBookings.0.bookings.0.costObject.value': {
    label: 'splitBookings.costObject.label',
    custom: {
      translationKey: 'splitBookings.costObject.notSetError',
    },
  },
  'reimbursementItemBookings.0.bookings.0.generalLedgerAccount.value': {
    label: 'splitBookings.generalLedgerAccount.label',
    custom: {
      translationKey: 'splitBookings.generalLedgerAccount.notSetError',
    },
  },
  'reimbursementItemBookings.0.bookings.0.vatRate': {
    label: 'splitBookings.vatRate.label',
    custom: {
      translationKey: 'splitBookings.vatRate.notSetError',
    },
  },
  'reimbursementItemBookings.0.bookings.0.taxCode': {
    label: 'splitBookings.taxCode.label',
    custom: {
      translationKey: 'splitBookings.taxCode.notSetError',
    },
  },
  'reimbursementItemBookings.0.bookings.0.postingText': {
    label: 'splitBookings.postingText.label',
  },
  'reimbursementItemBookings.0.bookings.0.extraCostInfo.value': {
    label: 'splitBookings.extraCostInfo.label',
  },
  'reimbursementItemBookings.0.bookings.0.artistSocialInsuranceCode': {
    label: 'splitBookings.artistSocialInsuranceCode.label',
  },
  'reimbursementItemBookings.0.bookings.0.note': {
    label:
      getTranslationContext() === 'sap'
        ? 'splitBookings.note.label_sap'
        : 'splitBookings.note.label',
  },
};
