import { Node } from '@react-types/shared';
import { forwardRef } from 'react';
import { ItemProps } from 'react-virtuoso';
import { ListItem } from './ListItem';
import { VirtuosoContext } from './types';

export const VirtuosoItem = forwardRef<
  HTMLLIElement,
  ItemProps<Node<any>> & VirtuosoContext
>(
  (
    {
      context,
      children: _, // ListItem component renders its own children
      item,
      ...restProps
    },
    ref
  ) => {
    const { state, rowStyles } = context;

    return (
      <ListItem
        key={item.key}
        item={item}
        state={state}
        {...restProps}
        rowStyles={rowStyles}
        ref={ref}
      />
    );
  }
);
