import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PromotionDetail, PromotionsIds } from '../types';

export const usePromotionDetailsModalContent = (
  promotionId: (typeof PromotionsIds)[number]
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  const contractManagementDetail: PromotionDetail = {
    title: t('promotionDetailsModal.contractManagement.title'),
    imageUrl: t('promotionDetailsModal.contractManagement.imageUrl'),
    wistiaId: t('promotionDetailsModal.contractManagement.video.wistiaId'),
    benefits: [
      {
        title: t('promotionDetailsModal.contractManagement.benefit1.title'),
        description: t(
          'promotionDetailsModal.contractManagement.benefit1.description'
        ),
      },
      {
        title: t('promotionDetailsModal.contractManagement.benefit2.title'),
        description: t(
          'promotionDetailsModal.contractManagement.benefit2.description'
        ),
      },
      {
        title: t('promotionDetailsModal.contractManagement.benefit3.title'),
        description: t(
          'promotionDetailsModal.contractManagement.benefit3.description'
        ),
      },
      {
        title: t('promotionDetailsModal.contractManagement.benefit4.title'),
        description: t(
          'promotionDetailsModal.contractManagement.benefit4.description'
        ),
      },
    ],
    upgradeInfo: {
      title: t('promotionDetailsModal.contractManagement.upgradeInfo.title'),
      description: t(
        'promotionDetailsModal.contractManagement.upgradeInfo.description'
      ),
      columns: [
        {
          items: [
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column1.item1'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column1.item2'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column1.item3'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column1.item4'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column2.item1'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column2.item2'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column2.item3'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column3.item1'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column3.item2'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column3.item3'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column4.item1'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column4.item2'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column4.item3'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column4.item4'
            ),
            t(
              'promotionDetailsModal.contractManagement.upgradeInfo.column4.item5'
            ),
          ],
        },
      ],
    },
    ctaButton: t('promotionDetailsModal.contractManagement.ctaButton', {
      returnObjects: true,
    }),
  };

  const documentManagementDetail: PromotionDetail = {
    title: t('promotionDetailsModal.documentManagement.title'),
    imageUrl: t('promotionDetailsModal.documentManagement.imageUrl'),
    wistiaId: t('promotionDetailsModal.documentManagement.video.wistiaId'),
    benefits: [
      t('promotionDetailsModal.documentManagement.benefit1', {
        returnObjects: true,
      }),
      t('promotionDetailsModal.documentManagement.benefit2', {
        returnObjects: true,
      }),
      t('promotionDetailsModal.documentManagement.benefit3', {
        returnObjects: true,
      }),
      t('promotionDetailsModal.documentManagement.benefit4', {
        returnObjects: true,
      }),
    ],
    upgradeInfo: {
      title: t('promotionDetailsModal.documentManagement.upgradeInfo.title'),
      description: t(
        'promotionDetailsModal.documentManagement.upgradeInfo.description'
      ),
      columns: [
        {
          items: [
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column1.item1'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column1.item2'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column1.item3'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column1.item4'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column2.item1'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column2.item2'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column2.item3'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column3.item1'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column3.item2'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column3.item3'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column4.item1'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column4.item2'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column4.item3'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column4.item4'
            ),
            t(
              'promotionDetailsModal.documentManagement.upgradeInfo.column4.item5'
            ),
          ],
        },
      ],
    },
    ctaButton: t('promotionDetailsModal.documentManagement.ctaButton', {
      returnObjects: true,
    }),
  };

  const creditCardsDetail: PromotionDetail = {
    title: t('promotionDetailsModal.creditCards.title'),
    imageUrl: t('promotionDetailsModal.creditCards.imageUrl'),
    wistiaId: t('promotionDetailsModal.creditCards.video.wistiaId'),
    benefits: [
      {
        title: t('promotionDetailsModal.creditCards.benefit1.title'),
        description: t(
          'promotionDetailsModal.creditCards.benefit1.description'
        ),
      },
      {
        title: t('promotionDetailsModal.creditCards.benefit2.title'),
        description: t(
          'promotionDetailsModal.creditCards.benefit2.description'
        ),
      },
      {
        title: t('promotionDetailsModal.creditCards.benefit3.title'),
        description: t(
          'promotionDetailsModal.creditCards.benefit3.description'
        ),
      },
      {
        title: t('promotionDetailsModal.creditCards.benefit4.title'),
        description: t(
          'promotionDetailsModal.creditCards.benefit4.description'
        ),
      },
    ],
    upgradeInfo: {
      title: t('promotionDetailsModal.creditCards.upgradeInfo.title'),
      description: t(
        'promotionDetailsModal.creditCards.upgradeInfo.description'
      ),
      columns: [
        {
          items: [
            t('promotionDetailsModal.creditCards.upgradeInfo.column1.item1'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column1.item2'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column1.item3'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column1.item4'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column1.item5'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.creditCards.upgradeInfo.column2.item1'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column2.item2'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column2.item3'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.creditCards.upgradeInfo.column3.item1'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column3.item2'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column3.item3'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.creditCards.upgradeInfo.column4.item1'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column4.item2'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column4.item3'),
            t('promotionDetailsModal.creditCards.upgradeInfo.column4.item4'),
          ],
        },
      ],
    },
    ctaButton: t('promotionDetailsModal.creditCards.ctaButton', {
      returnObjects: true,
    }),
  };

  const datevSelfBookerDetail: PromotionDetail = {
    title: t('promotionDetailsModal.datevSelfBooker.title'),
    imageUrl: t('promotionDetailsModal.datevSelfBooker.imageUrl'),
    wistiaId: t('promotionDetailsModal.datevSelfBooker.video.wistiaId'),
    benefits: [
      {
        title: t('promotionDetailsModal.datevSelfBooker.benefit1.title'),
        description: t(
          'promotionDetailsModal.datevSelfBooker.benefit1.description'
        ),
      },
      {
        title: t('promotionDetailsModal.datevSelfBooker.benefit2.title'),
        description: t(
          'promotionDetailsModal.datevSelfBooker.benefit2.description'
        ),
      },
      {
        title: t('promotionDetailsModal.datevSelfBooker.benefit3.title'),
        description: t(
          'promotionDetailsModal.datevSelfBooker.benefit3.description'
        ),
      },
      {
        title: t('promotionDetailsModal.datevSelfBooker.benefit4.title'),
        description: t(
          'promotionDetailsModal.datevSelfBooker.benefit4.description'
        ),
      },
    ],
    upgradeInfo: {
      title: t('promotionDetailsModal.datevSelfBooker.upgradeInfo.title'),
      description: t(
        'promotionDetailsModal.datevSelfBooker.upgradeInfo.description'
      ),
      columns: [
        {
          items: [
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column1.item1'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column1.item2'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column1.item3'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column1.item4'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column2.item1'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column2.item2'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column2.item3'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column3.item1'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column3.item2'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column3.item3'
            ),
          ],
        },
        {
          items: [
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column4.item1'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column4.item2'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column4.item3'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column4.item4'
            ),
            t(
              'promotionDetailsModal.datevSelfBooker.upgradeInfo.column4.item5'
            ),
          ],
        },
      ],
    },
    ctaButton: t('promotionDetailsModal.datevSelfBooker.ctaButton', {
      returnObjects: true,
    }),
  };

  const mobileAppDetail: PromotionDetail = {
    title: t('promotionDetailsModal.mobileApp.title'),
    imageUrl: t('promotionDetailsModal.mobileApp.imageUrl'),
    wistiaId: t('promotionDetailsModal.mobileApp.video.wistiaId'),
    benefits: [
      {
        title: t('promotionDetailsModal.mobileApp.benefit1.title'),
        description: t('promotionDetailsModal.mobileApp.benefit1.description'),
      },
      {
        title: t('promotionDetailsModal.mobileApp.benefit2.title'),
        description: t('promotionDetailsModal.mobileApp.benefit2.description'),
      },
      {
        title: t('promotionDetailsModal.mobileApp.benefit3.title'),
        description: t('promotionDetailsModal.mobileApp.benefit3.description'),
      },
      {
        title: t('promotionDetailsModal.mobileApp.benefit4.title'),
        description: t('promotionDetailsModal.mobileApp.benefit4.description'),
      },
    ],
  };

  const upsellPlusDetail: PromotionDetail = {
    title: t('promotionDetailsModal.upsellPlus.title'),
    imageUrl: t('promotionDetailsModal.upsellPlus.imageUrl'),
    wistiaId: t('promotionDetailsModal.upsellPlus.video.wistiaId'),
    benefits: [
      {
        title: t('promotionDetailsModal.upsellPlus.benefit1.title'),
        description: t('promotionDetailsModal.upsellPlus.benefit1.description'),
      },
      {
        title: t('promotionDetailsModal.upsellPlus.benefit2.title'),
        description: t('promotionDetailsModal.upsellPlus.benefit2.description'),
      },
      {
        title: t('promotionDetailsModal.upsellPlus.benefit3.title'),
        description: t('promotionDetailsModal.upsellPlus.benefit3.description'),
      },
      {
        title: t('promotionDetailsModal.upsellPlus.benefit4.title'),
        description: t('promotionDetailsModal.upsellPlus.benefit4.description'),
      },
    ],
    upgradeInfo: {
      title: t('promotionDetailsModal.upsellPlus.upgradeInfo.title'),
      description: t(
        'promotionDetailsModal.upsellPlus.upgradeInfo.description'
      ),
      columns: [
        {
          items: [
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column1.item1'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column1.item2'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column1.item3'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column1.item4'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column2.item1'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column2.item2'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column2.item3'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column3.item1'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column3.item2'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column3.item3'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column4.item1'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column4.item2'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column4.item3'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column4.item4'),
            t('promotionDetailsModal.upsellPlus.upgradeInfo.column4.item5'),
          ],
        },
      ],
    },
    ctaButton: t('promotionDetailsModal.upsellPlus.ctaButton', {
      returnObjects: true,
    }),
  };

  const reimbursementsLegacy: PromotionDetail = {
    title: t('promotionDetailsModal.reimbursementsLegacy.title'),
    imageUrl: t('promotionDetailsModal.reimbursementsLegacy.imageUrl'),
    wistiaId: '',
    benefits: [
      {
        title: t('promotionDetailsModal.reimbursementsLegacy.benefit1.title'),
        description: t(
          'promotionDetailsModal.reimbursementsLegacy.benefit1.description'
        ),
      },
      {
        title: t('promotionDetailsModal.reimbursementsLegacy.benefit2.title'),
        description: t(
          'promotionDetailsModal.reimbursementsLegacy.benefit2.description'
        ),
      },
      {
        title: t('promotionDetailsModal.reimbursementsLegacy.benefit3.title'),
        description: t(
          'promotionDetailsModal.reimbursementsLegacy.benefit3.description'
        ),
      },
      {
        title: t('promotionDetailsModal.reimbursementsLegacy.benefit4.title'),
        description: t(
          'promotionDetailsModal.reimbursementsLegacy.benefit4.description'
        ),
      },
    ],
    upgradeInfo: {
      title: t('promotionDetailsModal.reimbursementsLegacy.upgradeInfo.title'),
      description: t(
        'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.description'
      ),
      columns: [
        {
          items: [
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item1'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item2'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item3'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item4'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item5'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item6'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item7'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item8'
            ),
            t(
              'promotionDetailsModal.reimbursementsLegacy.upgradeInfo.column1.item9'
            ),
          ],
        },
      ],
    },
    ctaButton: t('promotionDetailsModal.reimbursementsLegacy.ctaButton', {
      returnObjects: true,
    }),
  };

  const upsellMaxDetail: PromotionDetail = {
    title: t('promotionDetailsModal.upsellMax.title'),
    imageUrl: t('promotionDetailsModal.upsellMax.imageUrl'),
    wistiaId: t('promotionDetailsModal.upsellMax.video.wistiaId'),
    benefits: [
      {
        title: t('promotionDetailsModal.upsellMax.benefit1.title'),
        description: t('promotionDetailsModal.upsellMax.benefit1.description'),
      },
      {
        title: t('promotionDetailsModal.upsellMax.benefit2.title'),
        description: t('promotionDetailsModal.upsellMax.benefit2.description'),
      },
      {
        title: t('promotionDetailsModal.upsellMax.benefit3.title'),
        description: t('promotionDetailsModal.upsellMax.benefit3.description'),
      },
      {
        title: t('promotionDetailsModal.upsellMax.benefit4.title'),
        description: t('promotionDetailsModal.upsellMax.benefit4.description'),
      },
    ],
    upgradeInfo: {
      title: t('promotionDetailsModal.upsellMax.upgradeInfo.title'),
      description: t('promotionDetailsModal.upsellMax.upgradeInfo.description'),
      columns: [
        {
          items: [
            t('promotionDetailsModal.upsellMax.upgradeInfo.column1.item1'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column1.item2'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column1.item3'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column1.item4'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.upsellMax.upgradeInfo.column2.item1'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column2.item2'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column2.item3'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.upsellMax.upgradeInfo.column3.item1'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column3.item2'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column3.item3'),
          ],
        },
        {
          items: [
            t('promotionDetailsModal.upsellMax.upgradeInfo.column4.item1'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column4.item2'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column4.item3'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column4.item4'),
            t('promotionDetailsModal.upsellMax.upgradeInfo.column4.item5'),
          ],
        },
      ],
    },
    ctaButton: t('promotionDetailsModal.upsellMax.ctaButton', {
      returnObjects: true,
    }),
  };

  const getPromotionDetail = () => {
    switch (promotionId) {
      case 'settings-contractSubCategories':
      case 'contractManagement':
        return contractManagementDetail;
      case 'settings-tags':
      case 'reimbursementsLegacy':
        return reimbursementsLegacy;
      case 'documentManagement':
        return documentManagementDetail;
      case 'creditCards':
        return creditCardsDetail;
      case 'datevSelfBooker':
        return datevSelfBookerDetail;
      case 'upsellPlus':
        return upsellPlusDetail;
      case 'upsellMax':
        return upsellMaxDetail;
      case 'mobileApp':
        return mobileAppDetail;
      default:
        return mobileAppDetail;
    }
  };

  return {
    promotionDetails: getPromotionDetail(),
  };
};
