import { Card, Flex, Grid, ScrollBox, Skeleton } from '@candisio/design-system';

export const LoadingReimbursementSection = () => {
  return (
    <ScrollBox scrollDirection="none" scrollbarGutter="stable">
      <Card padding="space12 space16 space14" height="124px">
        <Grid gap="space12" top={5}>
          <Skeleton height="28px" width="140px" borderRadius="6px" />
          <Grid gap="space4">
            <Flex justifyContent="space-between">
              <Skeleton height="space24" width="120px" />
              <Skeleton height="space24" width="80px" />
            </Flex>
            <Flex justifyContent="space-between">
              <Skeleton height="space24" width="60px" />
              <Skeleton height="space24" width="50px" />
            </Flex>
          </Grid>
        </Grid>
      </Card>
    </ScrollBox>
  );
};
