import { CSSObject } from '@emotion/react';
import React, { useRef } from 'react';
import {
  AriaSwitchProps,
  mergeProps,
  useFocusRing,
  useSwitch,
  VisuallyHidden,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { useToggleState } from 'react-stately';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box } from '../Box';

export interface SwitchProps
  extends Omit<
      StandardHTMLAttributes<HTMLLabelElement>,
      'onBlur' | 'onChange' | 'onFocus' | 'onKeyDown' | 'onKeyUp'
    >,
    LayoutProps {
  /**
   * (Hidden) label for the switch (required for accessiblity)
   */
  label: string;
  /**
   * (Controlled) checked state of the switch
   */
  checked?: AriaSwitchProps['isSelected'];
  /**
   * Initial checked state of the switch (uncontrolled)
   */
  defaultChecked?: AriaSwitchProps['defaultSelected'];
  /**
   * Disabled state of the switch
   */
  disabled?: AriaSwitchProps['isDisabled'];
  /**
   * Ref to the underlying input element.
   */
  inputRef?: React.RefObject<HTMLInputElement>;
  /**
   * Unique identifier of the switch
   */
  id?: AriaSwitchProps['id'];
  /**
   * Name associated with the switch when submitting a HTML form
   */
  name?: AriaSwitchProps['name'];
  /**
   * Called when the switch loses focus
   */
  onBlur?: AriaSwitchProps['onBlur'];
  /**
   * Called when switch’s checked state changes
   */
  onChange?: AriaSwitchProps['onChange'];
  /**
   * Called when the element receives focus
   */
  onFocus?: AriaSwitchProps['onFocus'];
  /**
   * Called when a key is pressed
   */
  onKeyDown?: AriaSwitchProps['onKeyDown'];
  /**
   * Called when a key is released
   */
  onKeyUp?: AriaSwitchProps['onKeyUp'];
  /**
   * Value associated with the switch when submitting a HTML form
   */
  value?: AriaSwitchProps['value'];
}

/**
 * Switch component provides visual toggle for forms and elsewhere.
 * @see [Storybook](https://candisio.github.io/design-system/?path=/docs/atoms-forms-switch)
 *
 * @param {string} label (Hidden) label for the switch (required for accessiblity)
 * @param {checked} boolean (Controlled) checked state of the switch
 * @param {defaultChecked} boolean Initial checked state of the switch (uncontrolled)
 * @param {boolean} disabled Disabled state of the switch
 * @param {string} id Unique identifier of the switch
 * @param {string} inputRef Ref to the underlying <input type="checkbox">
 * @param {string} name Name associated with the switch when submitting a HTML form
 * @param {function} onBlur Called when the switch loses focus
 * @param {function} onChange Called when switch’s checked state changes
 * @param {function} onFocus Called when the element receives focus
 * @param {function} onKeyDown Called when a key is pressed
 * @param {function} onKeyUp Called when a key is released
 * @param {string} value Value associated with the switch when submitting a HTML form
 *
 * @example
 * ```jsx
 * const [checked, setChecked] = React.useState(false);
 *
 * <Switch
 *   label="The old switcheroo"
 *   checked={checked}
 *   onChange={isChecked => setChecked(isChecked)}
 * >
 * ```
 */
export const Switch = React.forwardRef<HTMLLabelElement, SwitchProps>(
  (
    {
      checked,
      defaultChecked,
      disabled,
      id,
      inputRef,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      value,
      ...restProps
    },
    forwaredRef
  ) => {
    const { colors, shadows } = useTheme();

    const switchProps: AriaSwitchProps = {
      children: label,
      defaultSelected: defaultChecked,
      isDisabled: disabled,
      isSelected: checked,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      value,
      id,
    };

    const state = useToggleState(switchProps);
    const ref = useRef(null);
    const { inputProps } = useSwitch(switchProps, state, ref);
    const { isFocusVisible, focusProps } = useFocusRing();

    const checkedStyles = (isSelected: boolean) =>
      isSelected
        ? {
            'div ~ div': {
              background: disabled ? colors.green400 : colors.green500,
            },

            'div ~ div::after': {
              transform: 'translateX(var(--height))',
            },
          }
        : null;

    const baseStyle: CSSObject = {
      '--width': '42px',
      '--height': 'calc(var(--width) / 2)',
      '--border-radius': 'calc(var(--height) / 2)',

      display: 'inline-block',
      cursor: disabled ? 'not-allowed' : 'pointer',

      div: {
        position: 'relative',
        width: 'var(--width)',
        height: 'var(--height)',
        borderRadius: 'var(--border-radius)',
        background: colors.gray300,
        transition: 'background 0.2s',
        outline: isFocusVisible ? `3px solid ${colors.blue400}` : '',
      },

      'div::after': {
        content: '""',
        position: 'absolute',
        top: 1,
        left: 1,
        height: 'calc(var(--height) - 2px)',
        width: 'calc(var(--height) - 2px)',
        background: colors.gray0,
        boxShadow: shadows.elevatedShadow1,
        borderRadius: 'var(--border-radius)',
        transition: 'transform 0.2s',
      },

      ...checkedStyles(state.isSelected),
    };

    return (
      <Box as="label" css={baseStyle} {...restProps} ref={forwaredRef}>
        <VisuallyHidden>
          {label}
          <input
            {...mergeProps(inputProps, focusProps)}
            ref={mergeRefs([ref, inputRef || null])}
          />
        </VisuallyHidden>
        <div />
      </Box>
    );
  }
);
