import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation/zodResolver';
import { useTagNameDuplicateCheck } from '../../toolkit/hooks/useTagNameDuplicateCheck';
import { documentTagDetailsErrorMessages } from './Form/documentTagDetailsErrorMessages';
import {
  DocumentTagDetailsFormOutput,
  DocumentTagDetailsFormSchemaOptions,
  DocumentTagDetailsFormValues,
  documentTagDetailsFormSchema,
} from './Form/documentTagDetailsFormSchema';

const documentTagDefaultValues = {
  name: '',
  description: '',
};

export function useCreateTagForm({
  defaultValues,
  documentTagId,
}: {
  defaultValues: DocumentTagDetailsFormValues;
  documentTagId: string | undefined;
}) {
  const nameValidation = useTagNameDuplicateCheck();
  const context = {
    initialName: defaultValues?.name,
    nameValidation,
  };

  const form = useForm<
    DocumentTagDetailsFormOutput,
    DocumentTagDetailsFormSchemaOptions
  >({
    mode: 'onChange',
    shouldFocusError: true,
    context,
    defaultValues: {
      ...documentTagDefaultValues,
      ...defaultValues,
    },
    resolver: zodResolver({
      zodSchema: documentTagDetailsFormSchema,
      errorMessages: documentTagDetailsErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
  });

  const hasNameChanged = !!form.formState.dirtyFields.name;
  const showConfirmation = !!documentTagId && hasNameChanged;

  return { showConfirmation, form };
}
