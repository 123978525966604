/**
 * Helper functions to validate user roles
 */

import { BuiltinRoleName, User, UserRole } from 'generated-types/graphql.types';
import { isNil } from 'lodash';

export const hasRole = (user: User, role: UserRole): boolean =>
  !!user.roles?.includes(role);

export const hasMembershipRole = (
  user: User,
  membershipRole: string
): boolean => {
  return !!user.membershipRoles?.some(
    role => role.name.toLowerCase() === membershipRole.toLowerCase()
  );
};

export const isAccountant = (user: User): boolean =>
  hasRole(user, UserRole.Accountant);

export const isOwner = (user: User): boolean => {
  return (
    hasRole(user, UserRole.Owner) ||
    hasMembershipRole(user, BuiltinRoleName.Owner)
  );
};

export const isAdmin = (user: User): boolean => hasRole(user, UserRole.Admin);

export const isRequester = (user: User): boolean =>
  hasRole(user, UserRole.Requester);

export const isApprover = (user: User): boolean =>
  hasRole(user, UserRole.Approver);

export const isCardManager = (user: User): boolean =>
  hasRole(user, UserRole.CardManager);

export const isOnlyAccountant = (
  user: Pick<User, 'roles'> | undefined | null
): boolean => {
  return (
    !isNil(user) &&
    user?.roles?.length === 1 &&
    user.roles.includes(UserRole.Accountant)
  );
};

export const isOnlyApprover = (user: User | undefined | null): boolean =>
  !isNil(user) &&
  user?.roles?.length === 1 &&
  user.roles.includes(UserRole.Approver);

export const isOnlyRequester = (
  user: Pick<User, 'roles'> | undefined | null
): boolean =>
  !isNil(user) &&
  user?.roles?.length === 1 &&
  user.roles.includes(UserRole.Requester);

export const isOnlyCardManager = (user: User | undefined | null): boolean =>
  !isNil(user) &&
  user?.roles?.length === 1 &&
  user.roles.includes(UserRole.CardManager);
