import { Button, Flex, Grid } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { CreateCreditCardModal } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModal';
import { AnimatePresence, motion } from 'framer-motion';
import { UserRole } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardsHeader } from '../CreditCardsHeader';
import {
  CreditCardsTab,
  CreditCardsTabs,
} from '../CreditCardsTabs/CreditCardsTabs';
import { useScrollToTarget } from '../hooks/useScrollToTarget';
import { scrollToTarget } from '../utils/utils';
import { CardsAndRequestsOverviewWidgetContainer } from './CardsAndRequestsOverview/CardsAndRequestOverviewWidgetsContainer';
import { CreditCardTipsWidget } from './CreditCardTipsWidget';
import { FinancialOverviewContainer } from './FinancialOverview/FinancialOverviewContainer';
import { MissingInvoicesWidgetContainer } from './MissingInvoicesWidget/MissingInvoicesWidgetContainer';
import { OrganizationSettingsDrawer } from './OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import {
  RecurringPaymentsWidget,
  recurringPaymentsId,
} from './RecurringPayments/RecurringPaymentsWidget';
import { SpentOverviewWidget } from './SpentOverview/SpentOverviewWidget';
import { TeaserWidget } from './TeaserWidget';
import { TopupDrawerContainer } from './TopupDrawer/TopupDrawerContainer';

const recurringPaymentsScrollKey = 'recurring_payments_scroll';

const MotionFlex = motion(Flex);

export const CreditCardsInsights = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const { search, hash } = useLocation();

  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const { isIntersecting, ref } = useIsElementVisible();
  const [isScrollClicked, setIsScrollClicked] = useLocalStorage(
    recurringPaymentsScrollKey,
    false
  );

  useScrollToTarget(hash);

  const scrollToRecurringpayments = () => {
    setIsScrollClicked(true);
    scrollToTarget(recurringPaymentsId);
  };

  const { creditCardId, organizationSlug } = useParams<{
    creditCardId: string;
    organizationSlug: string;
  }>();

  const handleClose = () => {
    const pathname = `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}`;

    navigate({
      search,
      pathname,
    });
  };

  const isScrollButtonVisible =
    !isIntersecting && !isScrollClicked && showAllExtraFeatures;

  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  return (
    <Grid
      height="100%"
      overflowY="hidden"
      gap="space24"
      paddingLeft="space32"
      paddingTop={mainNavigationRefactorFF ? 'space20' : 'space40'}
      paddingBottom="space8">
      <Grid gap="space16" paddingRight="space32">
        <CreditCardsHeader selectedTab={CreditCardsTab.Dashboard} />
        {creditCardsStatementsFF ? (
          <CreditCardsTabs defaultSelectedKey={CreditCardsTab.Dashboard} />
        ) : (
          <UserRoleAccessWrapper
            allowedRoles={[UserRole.CardManager]}
            mode="hidden">
            <CreditCardsTabs defaultSelectedKey={CreditCardsTab.Dashboard} />
          </UserRoleAccessWrapper>
        )}
      </Grid>
      <Grid gap="space16" overflow="auto" paddingRight="space32">
        <Grid templateColumns="auto minmax(395px, 630px)" gap="space16">
          <CardsAndRequestsOverviewWidgetContainer />
          <FinancialOverviewContainer />
        </Grid>
        <Grid templateColumns="3fr 1fr" gap="space16" templateRows="500px">
          <SpentOverviewWidget />
          <MissingInvoicesWidgetContainer />
        </Grid>
        {showAllExtraFeatures ? (
          <Grid templateColumns="3fr 1fr" gap="space16" alignItems="start">
            <RecurringPaymentsWidget ref={ref} />
            <CreditCardTipsWidget />
          </Grid>
        ) : (
          <Grid justifyItems="center">
            <TeaserWidget />
          </Grid>
        )}
        <AnimatePresence>
          {isScrollButtonVisible ? (
            <MotionFlex
              position="sticky"
              bottom={0}
              justifyContent="center"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              paddingBottom="space8">
              <Button
                color="blue"
                onClick={scrollToRecurringpayments}
                icon="arrowDown">
                {t('insights.recurringPaymentsWidget.scrollButton')}
              </Button>
            </MotionFlex>
          ) : null}
        </AnimatePresence>
      </Grid>
      {creditCardId === 'request' ? (
        <CreditCardsProvider>
          <CreateCreditCardModal
            isOpen={creditCardId === 'request'}
            onClose={handleClose}
          />
        </CreditCardsProvider>
      ) : undefined}
      <TopupDrawerContainer />
      <OrganizationSettingsDrawer />
    </Grid>
  );
};
