import {
  useGetDocumentHistoryQuery,
  useEcmDocumentHistoryQuery,
} from 'generated-types/graphql.types';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';

export const useDocumentHistory = (documentId: string) => {
  const isEcmDocument = isEcmDocumentId(documentId);

  const { data: invoiceData, loading: invoiceLoading } =
    useGetDocumentHistoryQuery({
      variables: { id: documentId },
      // fetchPolicy: 'cache-and-network', is required for optimistic updates
      fetchPolicy: 'cache-and-network',
      skip: isEcmDocument || !documentId,
    });

  const { data: ecmDocumentData, loading: ecmDocumentLoading } =
    useEcmDocumentHistoryQuery({
      variables: { id: documentId },
      // fetchPolicy: 'cache-and-network', is required for optimistic updates
      fetchPolicy: 'cache-and-network',
      skip: !isEcmDocument || !documentId,
    });

  const documentData = isEcmDocument
    ? ecmDocumentData?.getAggregatedEcmDocument
    : invoiceData?.getDocument;

  const loading = invoiceLoading || ecmDocumentLoading;

  return { documentData, loading };
};
