import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import { expensesFormErrorMessages } from 'views/Reimbursement/toolkit/expensesFormErrorMessages';
import {
  ExpensesFormOutput,
  expensesFormSchema,
} from 'views/Reimbursement/toolkit/expensesFormSchema';

export const useExpenseFormsHelpers = ({
  defaultValues,
}: {
  defaultValues: ExpensesFormOutput;
}) => {
  const form = useForm<ExpensesFormOutput>({
    defaultValues,
    resetOptions: {
      keepErrors: true,
    },
    mode: 'all',
    shouldFocusError: false,
    resolver: zodResolver({
      zodSchema: expensesFormSchema,
      errorMessages: expensesFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
    }),
  });

  const { control } = form;

  const { fields, append, remove } = useFieldArray<
    ExpensesFormOutput,
    'expenses'
  >({
    control,
    name: 'expenses',
  });

  return {
    forms: fields,
    formMethods: form,
    onAppendForm: append,
    onRemoveForm: remove,
  };
};
