import {
  Modal,
  Text,
  Flex,
  Link,
  Tag,
  defaultTheme,
  Box,
  Grid,
} from '@candisio/design-system';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { EntitlementsOverviewPromo } from 'containers/Entitlements/components/Summary/EntitlementsOverviewPromo';
import { appointmentLinks } from 'containers/Entitlements/toolkit/utils';
import { useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export type EntitlementsModalProps = {
  feature: FEATURE;
  isOpen: boolean;
  onClose: () => void;
  trackDemo?: () => void;
  CustomPromoMaterial?: ReactElement;
  shortTitle?: boolean;
  hidePackages?: boolean;
};

const StyledLink = styled(Link)`
  a& {
    text-decoration: underline;
    color: ${defaultTheme.colors.gray600};
  }
`;

export const EntitlementsModal = ({
  feature,
  isOpen,
  onClose,
  trackDemo,
  hidePackages = false,
  shortTitle = false,
  CustomPromoMaterial,
}: EntitlementsModalProps) => {
  const activeEntitlement = useEntitlementsFlag();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const featureMessageMap: Record<FEATURE, ReactNode> = {
    [FEATURE.PROVISIONS]: (
      <Text fontSize="xxlarge" lineHeight="paragraph">
        <Trans t={t} i18nKey="promo.modal.titles.provisions">
          For timely closures, precise booking processes, and up-to-date core
          data – <Text fontWeight="bold">Candis for DATEV self-bookers</Text>
        </Trans>
      </Text>
    ),
    [FEATURE.ABSENCE_MANAGEMENT]: (
      <Text fontSize="xxlarge" lineHeight="paragraph">
        <Trans t={t} i18nKey="promo.modal.titles.absence">
          Whether off sick or on vacation – with our
          <Text fontWeight="bold">Absence & Substitute Management</Text>,
          approvals continue seamlessly.
        </Trans>
      </Text>
    ),
    [FEATURE.BDS]: <Text>{FEATURE.BDS}</Text>,
    [FEATURE.CONTRACTS]: (
      <Text fontSize="xxlarge" lineHeight="paragraph">
        <Trans t={t} i18nKey="promo.modal.titles.contracts">
          <Text fontWeight="semibold">
            Vertragsverwaltung mit Fristenmanagement
          </Text>
          <Text color="gray700" style={shortTitle ? { display: 'none' } : {}}>
            Mit diesen Funktionen
          </Text>
        </Trans>
      </Text>
    ),
    [FEATURE.FULL_TEXT_SEARCH]: (
      <Text fontSize="xxlarge" lineHeight="paragraph">
        <Trans t={t} i18nKey="promo.modal.titles.fullTextSearch">
          Your search for missing documents ends here - with
          <Text fontWeight="bold">full-text search</Text> you have them right at
          your fingertips.
        </Trans>
      </Text>
    ),
    [FEATURE.INTEGRATION_SAP]: <Text>{FEATURE.INTEGRATION_SAP}</Text>,
    [FEATURE.POSTING_TEXT]: <Text>{FEATURE.POSTING_TEXT}</Text>,
    [FEATURE.PURCHASE_ORDERS]: <Text>{FEATURE.PURCHASE_ORDERS}</Text>,
    [FEATURE.DOCUMENT_RELATIONS]: (
      <Text fontSize="xxlarge" lineHeight="paragraph" alignSelf="start">
        <Trans t={t} i18nKey="promo.modal.titles.documentRelations">
          <Text fontWeight="bold">Document linking</Text> and other functions
          for your <Text fontWeight="bold">document management</Text>
          are available in the Plus package
        </Trans>
      </Text>
    ),
    [FEATURE.SEPA_XML]: (
      <Text fontSize="xxlarge" lineHeight="paragraph">
        <Trans t={t} i18nKey="promo.modal.titles.sepaXML">
          Maintain <Text fontWeight="bold">full control</Text> over your
          payments and
          <Text fontWeight="bold">export directly</Text>
          via SEPA XML to your online banking.
        </Trans>
      </Text>
    ),
    [FEATURE.ECM]: (
      <Text fontSize="xxlarge" lineHeight="paragraph">
        <Trans t={t} i18nKey="promo.modal.titles.ecm">
          <Text fontWeight="bold">Dokumentenverwaltung leicht gemacht</Text>
          Mit diesen Funktionen
        </Trans>
      </Text>
    ),
    [FEATURE.REIMBURSEMENT]: undefined,
    [FEATURE.DOCUMENT_PREVIEW]: undefined,
    [FEATURE.TAGS]: undefined,
  };

  const addonTag = (
    <Box paddingLeft="space16">
      <Tag color="purple" variant="secondary">
        {t('promo.tag')}
      </Tag>
    </Box>
  );

  return (
    <Modal
      titleInfo={addonTag}
      closeLabel={t('common:common.close')}
      width="1090px"
      background="gray100"
      isOpen={isOpen}
      onClose={onClose}
      overflow="hidden"
      padding="space16 space48 space32">
      <Grid gap="space20">
        {featureMessageMap[feature]}
        {CustomPromoMaterial}
        {hidePackages ? null : (
          <EntitlementsOverviewPromo
            activeEntitlement={activeEntitlement}
            feature={feature}
          />
        )}
        <Flex direction="column" gap="space16" alignItems="center">
          <PromotionLink
            href={t(appointmentLinks[feature])}
            variant="arrow"
            trackClick={trackDemo}>
            {t('promo.modal.bookAppointment')}
          </PromotionLink>
          <Text color="gray600">
            <Trans t={t} i18nKey="promo.modal.ctaMarketingWebsiteCopy">
              These functions are part of our new package structure
              <StyledLink
                target="_blank"
                href={t('promo.modal.ctaMarketingWebsiteLink')}
                color="gray">
                candis.io/en/price
              </StyledLink>
            </Trans>
          </Text>
        </Flex>
      </Grid>
    </Modal>
  );
};
