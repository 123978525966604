import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { ExportColumnKeys, exportColumnsConfigurations } from '../consts';
import { AvailableEntity, TableType } from '../Context';
import { useGetColumns as useGetApprovalColumns } from '../elements/table/approvals/utils';
import { ContactsTableDataRow } from '../elements/table/contacts/types';
import { useColumns as useGetContactsColumns } from '../elements/table/contacts/utils';
import { PaymentConditionsTableRow } from '../elements/table/paymentConditions/types';
import { useColumns as useGetPaymentConditionsColumns } from '../elements/table/paymentConditions/utils';
import { useGetColumns as useGetProvisionColumns } from '../elements/table/provisions/utils';
import { useGetReimbursementItemsTableColumns } from '../elements/table/reimbursementItems/hooks/useGetReimbursementItemsTableColumns';
import { ExportReimbursementItemsTableColumnKeys } from '../elements/table/reimbursementItems/types';
import { useGetColumns as useGetReversalColumns } from '../elements/table/reversals/utils';
import { useColumns as useGetSettlementsColumns } from '../elements/table/settlements/utils';

type ColumnKeys =
  | DocumentTableColumnKeys
  | keyof Omit<ContactsTableDataRow, 'id'>
  | keyof Omit<PaymentConditionsTableRow, 'id'>
  | ExportReimbursementItemsTableColumnKeys;

interface UseGetDefaultConfigurationsMappedToTableTypeParams {
  tableType: TableType;
  entity?: AvailableEntity;
}

export const useGetDefaultConfigurationsMappedToTableType = ({
  tableType,
  entity,
}: UseGetDefaultConfigurationsMappedToTableTypeParams) => {
  const columnsPerTableTypeMap: Record<TableType, ColumnKeys[]> = {
    APPROVALS: useGetApprovalColumns(),
    PROVISIONS: useGetProvisionColumns(),
    REVERSALS: useGetReversalColumns(),
    SETTLEMENTS: useGetSettlementsColumns(),
    CONTACTS: useGetContactsColumns(),
    PAYMENT_CONDITIONS: useGetPaymentConditionsColumns(),
  };

  const mappedColumnsToTableType = columnsPerTableTypeMap[tableType];

  const columnsExpensesTable = useGetReimbursementItemsTableColumns();

  const isExpenseTable =
    tableType === 'APPROVALS' && entity === 'REIMBURSEMENT_ITEMS';

  const mappedColumns = isExpenseTable
    ? columnsExpensesTable
    : mappedColumnsToTableType;

  const defaultColumnsConfigurations = mappedColumns
    .map(column => {
      const configurations =
        exportColumnsConfigurations[column as ExportColumnKeys];

      // This extra check and the filter below are only for extra safety as the type of column is 'DocumentTableColumnKeys'
      // ... and we type cast it to 'ExportColumnKeys'. We do this becase 'DocumentTableColumnKeys' is a broader type than 'ExportColumnKeys' and it
      // ... contains all the columns in 'ExportColumnKeys'

      if (!configurations) {
        return undefined;
      }

      return configurations;
    })
    .filter((config): config is Configuration => !!config);

  return {
    defaultColumnsConfigurations,
    defaultColumns: mappedColumns,
  };
};
