import { Grid } from '@candisio/design-system';
import { motion } from 'framer-motion';

export const MotionOrganizationDetails = motion(Grid);
export const MotionOrganizationSwitcher = motion(Grid);

export const organizationSwitcherVariants = {
  open: {
    width: '240px',
  },
  closed: {
    width: '64px',
  },
};

export const organizationDetailsVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};
