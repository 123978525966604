import { Grid, Text } from '@candisio/design-system';
import { ColorProps } from '@candisio/design-system/src/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface SettlementEmptyStateProps {
  title?: string;
  background?: ColorProps['background'];
}

export const SettlementEmptyState = ({
  title,
  background,
}: SettlementEmptyStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid
      height="fit-content"
      background={background ?? 'gray100'}
      padding="space16"
      gap="space16"
      borderRadius="medium">
      {title && (
        <Text fontSize="large" fontWeight="semibold">
          {title}
        </Text>
      )}
      <Text color="gray600">
        {t('statements.settlementWidget.emptyStateDesc')}
      </Text>
    </Grid>
  );
};
