import { Flex, Grid, Text } from '@candisio/design-system';
import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementFormValues } from 'views/Reimbursement/toolkit/reimbursementFormSchema';

const USER_PROFILE_HASH = 'userProfile';

interface IbanFieldProps {
  label: string;
  isAddButtonVisible?: boolean;
  isEditButtonVisible?: boolean;
  isLoading: boolean;
}

export const IBAN_TEST_ID = 'iban-field';

export const IbanField = ({
  label,
  isAddButtonVisible = false,
  isEditButtonVisible = false,
  isLoading,
}: IbanFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { searchParams } = useMutateSearchParams();

  const { getFieldState, watch, trigger } =
    useFormContext<ReimbursementFormValues>();

  const ibanValue = watch('iban');

  useEffect(() => {
    // trigger validations for iban when it's value has changed since the field it's readonly and cannot trigger validation on its own
    if (ibanValue) {
      void trigger('iban');
    }
  }, [ibanValue, trigger]);

  const error = getFieldState('iban').error;

  const configurationTranslation = isEditButtonVisible
    ? t('reimbursementView.rightSection.form.iban.edit')
    : isAddButtonVisible
    ? t('reimbursementView.rightSection.form.iban.add')
    : undefined;

  return (
    <Grid data-testid={IBAN_TEST_ID}>
      <Grid gap="space4">
        <HookFormIbanField
          name="iban"
          label={
            <Flex width="max-width" justifyContent="space-between">
              <Text as="span">{label}</Text>
            </Flex>
          }
          readOnly
          isLoading={isLoading}
        />
        {error ? (
          <Text color="red500">
            {t('reimbursementView.rightSection.form.iban.error')}
          </Text>
        ) : null}
      </Grid>
      {(isEditButtonVisible || isAddButtonVisible) && (
        <RouterLink
          position="absolute"
          right="space8"
          top="0"
          to={{
            hash: USER_PROFILE_HASH,
            search: searchParams.toString(),
          }}
          style={{
            lineHeight: '1',
          }}>
          {configurationTranslation}
        </RouterLink>
      )}
    </Grid>
  );
};
