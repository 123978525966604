import { CSSObject } from '@emotion/react';
import React, { useContext } from 'react';
import { StandardInputHTMLAttributes } from '../../types';

export const FieldContext = React.createContext<FieldContext>({
  inputProps: {},
  inputRef: { current: null },
  inputCss: {},
  fieldContainerElement: null,
});

export const useFieldContext = () => useContext(FieldContext);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface FieldContext {
  label?: React.ReactNode;
  inputRef: React.RefObject<HTMLElement>;
  inputProps: StandardInputHTMLAttributes<HTMLElement>;
  inputCss?: CSSObject;
  fieldContainerElement: HTMLDivElement | null;
}
