import { useCheckTagNameDuplicationLazyQuery } from 'generated-types/graphql.types';
import { useCallback, useRef } from 'react';
import { asyncDebounce } from 'utils/asyncDebounce';

const DEFAULT_DEBOUNCE_TIME = 500;

export const useTagNameDuplicateCheck = () => {
  const abortController = useRef<AbortController>();
  const abortLatest = () => abortController.current?.abort();
  const [checkDuplicates] = useCheckTagNameDuplicationLazyQuery();

  const duplicateCheck = async (name: string): Promise<boolean> => {
    const controller = new AbortController();
    abortController.current = controller;

    try {
      const { data } = await checkDuplicates({
        fetchPolicy: 'network-only',
        variables: { name },
        context: { fetchOptions: { signal: controller.signal } },
      });

      return data?.tagsForDuplicationCheck.length === 0;
    } catch (error: any) {
      return error.name === 'AbortError';
    }
  };

  const debouncedValidation = useRef(
    asyncDebounce(duplicateCheck, DEFAULT_DEBOUNCE_TIME)
  );

  const abortableValidation = useCallback((name: string): Promise<boolean> => {
    abortLatest();

    return debouncedValidation.current(name);
  }, []);

  return abortableValidation;
};
