import moment from 'moment';
import { DateFormatters } from 'utils/date_formatter';
import { z } from 'zod';
import { getAllowedDateRange } from './getAllowedDateRange';

const MAX_YEARS_IN_FUTURE = 1;

interface InvoiceDateSchemaOptions {
  /** Maximum allowed number of years in past */
  yearsInPast?: number;
}

/** Zod schema to validate invoice date is within expected range */
export const invoiceDateSchema = ({
  yearsInPast,
}: InvoiceDateSchemaOptions) => {
  return z.string().superRefine((value, ctx) => {
    const { minDate, maxDate } = getAllowedDateRange({
      yearsInPast,
      yearsInFuture: MAX_YEARS_IN_FUTURE,
    });

    const valueMoment = moment(value, moment.ISO_8601);
    if (minDate && valueMoment.isBefore(minDate)) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'document.requestApproval.inputs.errors.invoiceDate.min',
          date: DateFormatters.compact(minDate),
        },
      });
    }

    if (maxDate && valueMoment.isAfter(maxDate)) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'document.requestApproval.inputs.errors.invoiceDate.max',
          date: DateFormatters.compact(maxDate),
        },
      });
    }
  });
};
