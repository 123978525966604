import {
  CardIssuerOnboardingStatus,
  Maybe,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { CreditCardAdoptedInfoBanner } from 'views/Dashboard/components/CreditCardAdoptedInfoBanner';
import { CreditCardNotAdoptedInfoBanner } from 'views/Dashboard/components/CreditCardNotAdoptedInfoBanner';
import { ActiveCreditCardRequestBanner } from './Requests/ActiveCreditCardRequestBanner';
import { ActiveCreditCardRequestCardPliant } from './Requests/ActivePliant';
import { PendingCreditCardRequestBanner } from './Requests/PendingCreditCardRequestBanner';

type GetLandingVariantProps = {
  userName: string;
  status?: Maybe<CardIssuerOnboardingStatus>;
};

export const creditCardInfoHash = '#info_about_cc';

export type RouteTypeProps =
  | 'Archive'
  | 'Inbox'
  | 'Dashboard'
  | 'Settings'
  | 'Export';

export const getVariantMap = ({
  userName,
  isWalletEnabled,
  isAdmin,
}: {
  userName: string;
  isWalletEnabled?: boolean;
  isAdmin: boolean;
}) => {
  return {
    [CardIssuerOnboardingStatus.Pending]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.Active]: isWalletEnabled ? (
      <ActiveCreditCardRequestBanner showTitle />
    ) : (
      <ActiveCreditCardRequestCardPliant userName={userName} />
    ),
    [CardIssuerOnboardingStatus.OnboardingEmailFailed]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingEmailSent]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingDetails]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingRisk]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingSddMandate]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingTerms]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingWait]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.Cancelled]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.Inactive]: isAdmin ? (
      <CreditCardAdoptedInfoBanner routeType="Dashboard" showTitle />
    ) : (
      <CreditCardNotAdoptedInfoBanner routeType="Dashboard" showTitle />
    ),
    unknown: isAdmin ? (
      <CreditCardAdoptedInfoBanner routeType="Dashboard" showTitle />
    ) : (
      <CreditCardNotAdoptedInfoBanner routeType="Dashboard" showTitle />
    ),
  };
};

export const useGetCardVariant = ({
  userName,
  status,
}: GetLandingVariantProps) => {
  const creditCardsSetup = useCreditCardsSetup();
  const { isAdmin } = useUserRoles();
  const variantMap = getVariantMap({
    userName,
    isWalletEnabled: creditCardsSetup.showAllExtraFeatures,
    isAdmin,
  });

  if (!creditCardsSetup.isAddonBought) {
    return <CreditCardNotAdoptedInfoBanner routeType="Dashboard" showTitle />;
  }

  return variantMap[status || 'unknown'];
};
