import * as Sentry from '@sentry/react';
import { Organization, User } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useNavigationSidebarContext } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import {
  useEffect,
  ReactNode,
  useCallback,
  createContext,
  useContext,
  useState,
} from 'react';

const INTERCOM_CHAT_SELECTOR = "[data-intercom = 'intercom-chat']";

// This is same mechanism as used by backend services to generate analyticsId.
const genAnalyticsId = async (email: string): Promise<string> => {
  const crypto = window.crypto;
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);

  // Convert ArrayBuffer to hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

/**
 * New traits should be in snake_case
 */
const computeUserTraits = (user: User) => {
  return {
    name: user.name,
    email: user.email,
    locale: user.locale,
  };
};

const initIntercom = async (
  user: User,
  organization: Pick<
    Organization,
    | 'id'
    | 'name'
    | 'slug'
    | 'salesforceAccountId'
    | 'chargebeeSubscriptionId'
    | 'realmName'
  >
) => {
  const userTraits = computeUserTraits(user);
  const userAnalyticsId =
    user.analyticsId || (await genAnalyticsId(user.email));

  window.intercomSettings = {
    ...window.intercomSettings,
    ...userTraits,
    user_id: userAnalyticsId,
    user_hash: user.intercomHash || undefined,
    alignment: 'left',
    horizontal_padding: 40,
    vertical_padding: 26,
    custom_launcher_selector: INTERCOM_CHAT_SELECTOR,
    language_override: user.locale?.toLowerCase() ?? 'de',
  };

  Sentry.getCurrentScope().setUser({
    id: user.id,
    email: user.email,
    username: user.name,
    organization: organization.id,
  });
};

interface IntercomProviderProps {
  children: ReactNode;
}

// This Provider seems rather unnecessary to me and should be integrated in
// the Analytics Provider as most of the settings in here are not Intercom
// specific and will be used by all connected analytics tools.

const IntercomContext = createContext<{
  intercomIsEnabled: boolean;
  unreadCount: number;
}>({
  intercomIsEnabled: false,
  unreadCount: 0,
});

export const useIntercomContext = () => useContext(IntercomContext);

export const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const user = useCurrentUser();
  const organization = useFullOrganization();
  const mainNavigationRefactor = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  const [unreadCount, setUnreadCount] = useState(0);

  const { isNavigationOpen } = useNavigationSidebarContext();

  const getPadding = useCallback(() => {
    if (!mainNavigationRefactor) return 40;

    if (mainNavigationRefactor) {
      return isNavigationOpen ? 300 : 80;
    }
  }, [isNavigationOpen, mainNavigationRefactor]);

  const intercomIsEnabledInTrackingSettings =
    !!user?.trackingConfiguration?.intercom;

  useEffect(() => {
    const handleError = (e: any) => {
      console.error(e);
      window.location.assign('/');
    };

    const initializeIntercom = async () => {
      if (user && organization && intercomIsEnabledInTrackingSettings) {
        await initIntercom(user, organization);
        const intercomSettings = window.intercomSettings;

        window.intercomSettings = {
          ...intercomSettings,
          hide_default_launcher: mainNavigationRefactor,
          horizontal_padding: getPadding(),
        };
      }

      if (
        user &&
        organization &&
        !intercomIsEnabledInTrackingSettings &&
        window.Intercom
      ) {
        window.Intercom('shutdown');
        window.intercomSettings = {};
      }
    };

    initializeIntercom().catch(handleError);
  }, [
    user,
    organization,
    mainNavigationRefactor,
    isNavigationOpen,
    getPadding,
    intercomIsEnabledInTrackingSettings,
  ]);

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('onUnreadCountChange', unreadCount => {
        setUnreadCount(unreadCount);
      });

      return () => {
        window.Intercom('shutdown');
      };
    }
  }, []);

  return (
    <IntercomContext.Provider
      value={{
        intercomIsEnabled: intercomIsEnabledInTrackingSettings,
        unreadCount,
      }}>
      {children}
    </IntercomContext.Provider>
  );
};
