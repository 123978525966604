import { Flex } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import { ProcessingFormFieldOptions } from './ProcessingForm';
import { ProcessingFormAmountField } from './ProcessingFormAmountField';
import { ProcessingFormDatePickerField } from './ProcessingFormDatePickerField';
import { ProcessingFormPercentField } from './ProcessingFormPercentField';

export interface ProcessingFormCashDiscountFieldsProps {
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Display fields as read only */
  readOnly?: boolean;
}

/**
 * Fields for entering cash discount:
 *
 * - discount date
 * - discount percentage
 * - discount amount
 */
export const ProcessingFormCashDiscountFields = ({
  fieldOptions,
  readOnly,
  ...restProps
}: ProcessingFormCashDiscountFieldsProps) => {
  const [t] = useTranslation();

  const flex = '1 0 9rem';

  return (
    <Flex columnGap="space8" rowGap="space16" wrap="wrap">
      <ProcessingFormDatePickerField
        flex={flex}
        name="discountDate"
        label={t('cash-discounts:fields.dueDate.label', {
          context: getTranslationContext(),
        })}
        {...fieldOptions?.discountDate}
        readOnly={readOnly || fieldOptions?.discountDate?.readOnly}
        showStatusOnReadOnly={readOnly || fieldOptions?.discountDate?.readOnly}
        {...restProps}
      />
      <ProcessingFormPercentField
        flex={flex}
        name="discountPercentage"
        label={t('cash-discounts:fields.percentage.label', {
          context: getTranslationContext(),
        })}
        maxValue={99.99}
        minValue={0.01}
        placeholder={t('cash-discounts:fields.percentage.placeholder.enabled')}
        {...fieldOptions?.discountPercentage}
        readOnly={readOnly || fieldOptions?.discountPercentage?.readOnly}
        {...restProps}
      />
      <ProcessingFormAmountField
        flex={flex}
        name="discountAmount"
        label={t('cash-discounts:fields.amount.label', {
          context: getTranslationContext(),
        })}
        placeholder={t('cash-discounts:fields.amount.placeholder.enabled')}
        minValue={0.01}
        {...fieldOptions?.discountAmount}
        readOnly={readOnly || fieldOptions?.discountAmount?.readOnly}
        {...restProps}
      />
    </Flex>
  );
};
