import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { mappedExpenseTypeToGeneralTitle } from 'views/Reimbursement/utils/getActivityTypeToReimbursementHistoryComponent';

export const ExpenseItemHospitalityInternalGuestsUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const fallBackTitle = t(
    mappedExpenseTypeToGeneralTitle['HOSPITALITY_EXPENSE']
  );

  return (
    <Grid>
      <Text>
        {t('timeline.expenseChanged', { title: expenseTitle ?? fallBackTitle })}
      </Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t(
              'reimbursementView.middleSection.form.hospitality.internalGuests.label'
            )}
          </Text>
          <Paragraph>
            {properties.newInternalGuests?.length
              ? properties.newInternalGuests.map(guest => guest)
              : t('timeline.empty')}
          </Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
