import { Flex, Grid, Text, useTheme } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import {
  DocumentsTable,
  DocumentsTableProps,
} from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentsTableData,
  NavigationTableParams,
} from 'components/DocumentsTable/types';
import { ArchiveKebabMenu } from 'components/Menu/ArchiveKebabMenu/ArchiveKebabMenu';
import { SearchField } from 'components/SearchField/SearchField';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { UserRole } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DownloadMenuButtonContainer } from 'views/Archive/Documents/DownloadMenuButtonContainer';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { EcmSearchHelpCenterButton } from '../shared/EcmSearchFeedbackButton';
import { availableFilters } from './consts';
import { useArchiveDocumentsFilters } from './hooks/useArchiveDocumentsFilters';
import { useArchiveDocumentsColumnsConfigs } from './hooks/useGetArchiveDocumentsColumnsConfigs';

export type ArchiveDocumentsTableDSProps = {
  data: Array<DocumentsTableData>;
  hasMoreData: boolean;
  isLoading: boolean;
  isUsingSearchQuery: boolean;
  onEndReached: () => void;
  organizationSlug: string;
  searchFieldOnChange: (query: string) => void;
  searchFieldValue: string;
  selectedDocumentsCount: number;
  onClick: ({ id, cursor }: NavigationTableParams) => void;
  rowOverlay?: DocumentsTableProps['rowOverlay'];
  cellWrapper?: DocumentsTableProps['cellWrapper'];
  getCellStyles?: DocumentsTableProps['getCellStyles'];
};

export const ArchiveDocumentsTable = ({
  data: tableData,
  hasMoreData,
  isLoading,
  isUsingSearchQuery,
  onEndReached,
  organizationSlug,
  searchFieldOnChange,
  searchFieldValue,
  selectedDocumentsCount,
  onClick,
  rowOverlay,
  cellWrapper,
  getCellStyles,
}: ArchiveDocumentsTableDSProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const { showImprovedSearch } = useEcm();

  const { sortBy, onSort, filters, onFilter } =
    useUrlBasedSortAndFilter<DocumentsTableData>({
      availableFilters,
    });

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigs,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useArchiveDocumentsColumnsConfigs({ filters, sortBy });

  const { space } = useTheme();

  const { filterOptions, filtersLoading } = useArchiveDocumentsFilters();

  const isTableFiltered = filters.length > 0;

  const emptyStateContent = (
    <Text textAlign="center" whiteSpace="pre-wrap">
      {isTableFiltered
        ? t('emptyState.content.filtered')
        : t('emptyState.content.empty')}
    </Text>
  );

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    discountDateWPercentage: space.space256,
    iban: space.space200,
    paymentInfo: space.space200,
    accountingArea: space.space256,
    invoiceNumber: space.space200,
    documentType: space.space200,
    purchaseOrderNumber: space.space200,
    goodsReceiptsCount: space.space200,
    sapPurchaseOrderNumber: space.space200,
  };

  const showLoadingMoreSpinner = tableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: tableData?.length,
    hasMore: hasMoreData,
    showLoadingMoreSpinner: !isLoading && showLoadingMoreSpinner,
    next: onEndReached,
  };

  const tableKey = `table-filters-${filtersLoading}-${availableDocumentColumnIds.join(
    '-'
  )}`;

  return (
    <Flex height="100%" overflow="hidden" direction="column" key={tableKey}>
      <Grid
        templateColumns={
          showImprovedSearch
            ? 'minmax(12rem, 24rem) auto 1fr'
            : 'minmax(12rem, 24rem) 1fr'
        }
        gap="space16"
        alignItems="center"
        borderTopRadius="basic"
        overflow="hidden"
        background="white"
        borderBottom="1px solid gray200"
        padding="space12">
        <SearchField
          clearTooltip={t('archive:search.clearTooltip')}
          placeholder={t('archive:search.placeholder')}
          value={searchFieldValue}
          onChange={searchFieldOnChange}
          inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
        />
        {showImprovedSearch && <EcmSearchHelpCenterButton />}
        <Grid justifySelf="end">
          <UserRoleAccessWrapper
            allowedRoles={[
              UserRole.Admin,
              UserRole.Requester,
              UserRole.Approver,
              UserRole.Accountant,
            ]}
            mode="hidden">
            <Flex gap="space8" alignItems="center">
              <ConfigurationsMenu
                configurationItems={configurationsTable}
                isLoading={isLoadingConfigs}
                onUpdateConfigurations={handleUpdateConfigurations}
                onResetConfigurations={handleResetTableConfigurations}
              />
              <DownloadMenuButtonContainer
                isTableFiltered={isTableFiltered}
                isTableLoading={isLoading}
                selectedDocumentsCount={selectedDocumentsCount}
              />
              <UserRoleAccessWrapper
                allowedRoles={[
                  UserRole.Admin,
                  UserRole.Requester,
                  UserRole.Approver,
                  UserRole.Accountant,
                ]}
                mode="hidden">
                <ArchiveKebabMenu organizationSlug={organizationSlug} />
              </UserRoleAccessWrapper>
            </Flex>
          </UserRoleAccessWrapper>
        </Grid>
      </Grid>
      <DocumentsTable
        columns={availableDocumentColumnIds}
        columnWidths={columnWidths}
        borderTopRadius="none"
        data={tableData}
        defaultEmptyStateContent={emptyStateContent}
        defaultFilters={filters}
        defaultSortBy={sortBy}
        filterOptions={filterOptions}
        infiniteScrollOptions={infiniteScrollOptions}
        isLoading={isLoading}
        isUsingSearchQuery={isUsingSearchQuery}
        minWidth="100%"
        onEndReached={onEndReached}
        onFilter={onFilter}
        onRowClick={onClick}
        onSort={onSort}
        width="max-content"
        rowOverlay={rowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={getCellStyles}
      />
    </Flex>
  );
};
