import { Button, Flex, Grid } from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TransactionsKebabMenu } from 'views/Archive/Transactions/TransactionsKebabMenu';

interface TransactionsTableToolbarProps {
  search?: string;
  configurations?: Configuration[];
  isLoading?: boolean;
  isDownloadingCSV?: boolean;
  downloadableTransactionsCount?: number;
  isTableFiltered: boolean;
  onUpdateConfigurations?: (configurations: Configuration[]) => void;
  onResetTableConfigurations?: () => void;
  onSearch: (querySearch: string) => void;
  onDownloadCSV?: () => void;
}

export const TransactionsTableToolbar = ({
  onSearch,
  onUpdateConfigurations,
  onResetTableConfigurations,
  onDownloadCSV,
  configurations,
  search,
  isLoading,
  isDownloadingCSV,
  isTableFiltered,
  downloadableTransactionsCount,
}: TransactionsTableToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const isConfigurationMenuVisible = onUpdateConfigurations && configurations;

  const isCsvDownloadButtonVisible = !!onDownloadCSV;

  const downloadButtonTranslation = isTableFiltered
    ? t('table.toolbar.downloadFilteredCTA', {
        count: downloadableTransactionsCount,
      })
    : t('table.toolbar.downloadCTA', {
        count: downloadableTransactionsCount,
      });

  return (
    <Grid
      templateColumns="minmax(12rem, 24rem) 1fr"
      borderTopRadius="basic"
      overflow="hidden"
      alignItems="center"
      background="white"
      borderBottom="1px solid gray200"
      padding="space12">
      <SearchField
        clearTooltip={t('table.toolbar.tooltip')}
        placeholder={t('table.toolbar.search')}
        value={search ?? ''}
        onChange={onSearch}
      />
      {isConfigurationMenuVisible && (
        <Flex justifySelf="right" alignItems="center" gap="space4">
          <ConfigurationsMenu
            isLoading={isLoading}
            configurationItems={configurations}
            onUpdateConfigurations={onUpdateConfigurations}
            onResetConfigurations={onResetTableConfigurations}
          />
          {isCsvDownloadButtonVisible && (
            <Flex gap="space4">
              <Button
                icon="arrowDown"
                variant="tertiary"
                loading={isDownloadingCSV || isLoading}
                disabled={isDownloadingCSV || !downloadableTransactionsCount}
                onClick={onDownloadCSV}>
                {downloadButtonTranslation}
              </Button>
              <TransactionsKebabMenu />
            </Flex>
          )}
        </Flex>
      )}
    </Grid>
  );
};
