import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Skeleton } from '../Skeleton';

const MotionSkeleton = motion(Skeleton);

const skeletonVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'easeOutIn' },
  },
};

interface LoadingSkeletonProps {
  element: React.RefObject<HTMLElement>;
  isLoading: boolean | undefined;
  borderRadius?: number | string;
}

export const LoadingSkeleton = ({
  isLoading,
  element,
  borderRadius,
}: LoadingSkeletonProps): JSX.Element | null => {
  const [style, setStyle] = useState({ height: '', width: '' });

  useEffect(() => {
    setStyle({
      height: `${element.current?.clientHeight}px`,
      width: `${element.current?.clientWidth}px`,
    });
  }, [element]);

  return isLoading ? (
    // @ts-expect-error
    <MotionSkeleton
      css={style}
      borderRadius={borderRadius}
      position="absolute"
      top="0"
      initial="hidden"
      variants={skeletonVariants}
      animate={isLoading ? 'visible' : 'hidden'}
    />
  ) : null;
};
