import { useDatevSettings } from 'hooks/useDatevSettings';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { accountsNumberSchemaWithDuplicateCheck } from 'views/Contacts/ContactDetails/ContactForm/accountsNumberSchema';
import { z } from 'zod';

export const useEmployeesAPNSettingsFormSchema = () => {
  const { data: datevSettings } = useDatevSettings();
  const integration = useIntegrationSettings();

  const accountLength =
    datevSettings?.new_datevSettings?.chartOfAccount?.accountLength;

  const glaLength = accountLength ?? undefined;

  const accountsPayableNumberSchema = accountsNumberSchemaWithDuplicateCheck({
    integration,
    accountType: 'accountsPayable',
    glaLength,
  }).nullable();

  return z.object({
    startValue: z.preprocess(
      value => (typeof value === 'string' ? value?.replaceAll(' ', '') : value),
      accountsPayableNumberSchema
    ),
  });
};

export type EmployeesAPNSettingsFormOutput = z.infer<
  ReturnType<typeof useEmployeesAPNSettingsFormSchema>
>;
export type EmployeesAPNSettingsFormValues =
  Partial<EmployeesAPNSettingsFormOutput>;
