import { Card, Grid, Heading } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ContactSettingsForm } from './ContactSettingsForm';
import { ContactSettingsLoader } from './ContactSettingsLoader';
import { useApnSuggestionSettings } from './useApnSuggestionSettings';

export const ContactSettingsContainer = () => {
  const [t] = useTranslation();

  const {
    loadingInitialValues,
    submitting,
    readOnly,
    setReadOnly,
    setStartValue,
    enabled,
    setEnabled,
    startValue,
  } = useApnSuggestionSettings();

  if (loadingInitialValues) {
    return <ContactSettingsLoader />;
  }

  return (
    <Grid gap="space16">
      <Heading as="h3">{t('settings.contacts.title')}</Heading>
      <Card paddingX="space24" paddingY="space16">
        <ContactSettingsForm
          submitting={submitting}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          enabled={enabled}
          setEnabled={setEnabled}
          startValue={startValue}
          setStartValue={setStartValue}
        />
      </Card>
    </Grid>
  );
};
