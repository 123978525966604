import { Flex, Grid, Text, TruncatedText } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { motion } from 'framer-motion';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { defaultRolesTranslations } from 'views/Settings/Roles/toolkit/utils/defaultRolesTranslations';
import { MobileAppPromotion } from 'views/Settings/TeamMembers/UsersListView/components/MobileAppPromotion';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { MembershipRole, Tab, User } from '../../hooks/useGetUsers';

interface UserItemProps {
  user: User;
  queryString: string;
  toggleDrawer: (id: string & { emailVerified: boolean }) => void;
  activeTab?: Tab;
}

const MotionGrid = motion(Grid);

const MembershipRoles = ({
  membershipRoles,
}: {
  membershipRoles: MembershipRole[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const readableRoles = membershipRoles.map(role => {
    if (role.isBuiltinRole) {
      return t(defaultRolesTranslations[role.name]);
    }

    return role.name;
  });

  const sortedRoles = readableRoles.sort((current, next) =>
    current.localeCompare(next)
  );

  const isNotLastRole = (index: number) => index !== sortedRoles.length - 1;

  return (
    <Flex gap="space8" wrap="wrap">
      {sortedRoles.map((readableRole, index) => (
        <Flex key={index} gap="space8" alignItems="center">
          <Text>{readableRole}</Text>
          {isNotLastRole(index) && <span>•</span>}
        </Flex>
      ))}
    </Flex>
  );
};

export const UserItem = ({
  user,
  queryString,
  toggleDrawer,
  activeTab,
}: UserItemProps) => {
  const [mobileAppPromotionFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.mobileAppPromotion,
  ]);

  const { hoverProps, isHovered } = useHover({});

  const { avatarUrl, email, name, id } = user;

  const membershipRoles = user.membershipRoles ?? [];

  const applicationPromo =
    mobileAppPromotionFF && activeTab === 'ACTIVE' && isHovered ? (
      <Flex width="160px" justifyContent="right">
        <MobileAppPromotion user={user} />
      </Flex>
    ) : (
      <Flex width="160px" />
    );

  return (
    <MotionGrid
      padding="space12 space20"
      templateColumns="auto 1fr 1.2fr"
      gap="space16"
      autoFlow="column"
      justifyContent="start"
      alignItems="center"
      borderBottom="1px solid gray200"
      onClick={() => toggleDrawer(id as string & { emailVerified: boolean })}
      style={{ cursor: 'pointer' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.25 }}
      {...hoverProps}>
      <AvatarWithStatusContainer
        name={name}
        img={avatarUrl ?? undefined}
        hasTooltip={false}
        userId={id}
      />
      <Grid>
        {/* @ts-ignore */}
        <TruncatedText
          fontWeight="semibold"
          fontSize="basic"
          overflowWrap="anywhere">
          {renderQueryHighlight({
            value: name,
            queryString: queryString,
          })}
        </TruncatedText>
        <Text color="gray500" overflowWrap="anywhere">
          {renderQueryHighlight({
            value: email,
            queryString: queryString,
          })}
        </Text>
      </Grid>

      <MembershipRoles membershipRoles={membershipRoles} />
      {applicationPromo}
    </MotionGrid>
  );
};
