import { isValidBIC, isValidIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { ibanSchema } from 'utils/zodFormValidation';
import {
  isSwiftCountryCodeMatch,
  swiftCodeSchema,
} from 'utils/zodFormValidation/Schemas/swiftCodeSchema';
import { z } from 'zod';

export const bankInfoSchema = z
  .object({
    iban: ibanSchema.optional().nullable(),
    swiftCode: swiftCodeSchema.optional().nullable(),
  })
  .superRefine(({ iban, swiftCode }, ctx) => {
    const isIbanRequired = swiftCode && isNil(iban);
    if (isIbanRequired) {
      ctx.addIssue({
        code: 'custom',
        path: ['iban'],
        params: {
          translationKey:
            'header.profile.tabs.update.form.iban.ibanEmptySwiftPresent',
        },
      });

      return;
    }

    const isSwiftCodeRequiredForGBIban =
      iban && iban.startsWith('GB') && !swiftCode;

    if (isSwiftCodeRequiredForGBIban) {
      ctx.addIssue({
        code: 'custom',
        path: ['swiftCode'],
        params: {
          translationKey:
            'header.profile.tabs.update.form.bic.swiftCodeRequiredForGBIban',
        },
      });

      return;
    }

    const isMismatchBetweenSwiftAndIbanCountry =
      iban &&
      swiftCode &&
      isValidIBAN(iban) &&
      isValidBIC(swiftCode) &&
      !isSwiftCountryCodeMatch({ iban, swiftCode });

    if (isMismatchBetweenSwiftAndIbanCountry) {
      ctx.addIssue({
        code: 'custom',
        path: ['swiftCode'],
        params: {
          translationKey:
            'header.profile.tabs.update.form.bic.swiftCodeIbanCountryCodeMismatch',
        },
      });

      return;
    }
  });

export type BankInfoSchemaOutput = z.infer<typeof bankInfoSchema>;

export type BankInfoSchemaValues = Partial<BankInfoSchemaOutput>;
