import React from 'react';
import { useTheme } from '../../../Theme';
import {
  ColorProps,
  LayoutProps,
  StandardHTMLAttributes,
  TypographyProps,
} from '../../../types';

import { Text } from '../Text';

export type HeadingLevel = 'h1' | 'h2' | 'h3';

export interface HeadingProps
  extends LayoutProps,
    ColorProps,
    StandardHTMLAttributes<HTMLHeadingElement> {
  as: HeadingLevel;
}

/**
 * Heading component .
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-typography-heading}
 *
 * @param {HeadingLevel} as Level of the heading ['h1' | 'h2' | 'h3']
 */
export const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ as, children, ...restProps }, ref) => {
    const { typography } = useTheme();

    let typographyProps: TypographyProps;

    switch (as) {
      case 'h1':
        typographyProps = typography.h1;
        break;

      case 'h2':
        typographyProps = typography.h2;
        break;

      case 'h3':
        typographyProps = typography.h3;
        break;

      default:
        typographyProps = typography.default;
        break;
    }

    const colorProps: ColorProps = {
      color: 'gray800',
    };

    const textProps: ColorProps & TypographyProps = {
      ...colorProps,
      ...typographyProps,
    };

    return (
      <Text as={as} {...textProps} {...restProps} ref={ref}>
        {children}
      </Text>
    );
  }
);
