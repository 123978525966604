import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { EmptyReimbursementDetailsCard } from './EmptyReimbursementDetailsCard';
import { LoadingReimbursementSection } from './LoadingReimbursementSection';
import { ReimbursementDetailsCard } from './ReimbursementDetailsCard';

export interface ReimbursementSectionProps {
  loading: boolean;
  totalAmount?: string;
  status?: ReimbursementCaseStatus;
  title?: string;
  reimbursementItemsCount: number;
}

export const ReimbursementSection = ({
  loading,
  status,
  title,
  totalAmount,
  reimbursementItemsCount,
}: ReimbursementSectionProps) => {
  if (loading) {
    return <LoadingReimbursementSection />;
  }

  if (!status || !reimbursementItemsCount) {
    return <EmptyReimbursementDetailsCard />;
  }

  return (
    <ReimbursementDetailsCard
      title={title}
      totalAmount={totalAmount}
      status={status}
    />
  );
};
