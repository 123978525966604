import { useCallback, useReducer } from 'react';
import { NavIconProps } from './useNavigationIcons';

interface IconOverflowProps {
  initialIcons: NavIconProps[];
  navigationSidebarRef: React.RefObject<HTMLDivElement>;
  bottomNavigationItemsRef: React.RefObject<HTMLDivElement>;
}

const COMPANY_SWITCHER_HEIGHT = 52;
const GRID_GAP = 8;
const ITEM_HEIGHT = 50;

interface IconState {
  visibleIcons: NavIconProps[];
  hiddenIcons: NavIconProps[];
}

type IconAction = {
  type: 'SET_ICONS';
  visibleIcons: NavIconProps[];
  hiddenIcons: NavIconProps[];
};

const iconReducer = (state: IconState, action: IconAction): IconState => {
  switch (action.type) {
    case 'SET_ICONS':
      return {
        ...state,
        visibleIcons: action.visibleIcons,
        hiddenIcons: action.hiddenIcons,
      };
    default:
      return state;
  }
};

const calculateVisibleItems = ({
  navigationSidebarRef,
  bottomNavigationItemsRef,
  items,
  visibleIcons,
  hiddenIcons,
}: {
  navigationSidebarRef: any;
  bottomNavigationItemsRef: any;
  items: NavIconProps[];
  visibleIcons: NavIconProps[];
  hiddenIcons: NavIconProps[];
}) => {
  const sidebarHeight =
    navigationSidebarRef.current.offsetHeight -
    bottomNavigationItemsRef.current?.offsetHeight -
    COMPANY_SWITCHER_HEIGHT -
    GRID_GAP;

  const maxVisibleItems = Math.floor(sidebarHeight / ITEM_HEIGHT);

  if (maxVisibleItems <= 0) return null;

  const newVisibleIcons =
    maxVisibleItems < items.length
      ? items.slice(0, maxVisibleItems - 1)
      : items;

  const newHiddenIcons =
    maxVisibleItems < items.length ? items.slice(maxVisibleItems - 1) : [];

  const iconsHaveChanged =
    newVisibleIcons.length !== visibleIcons.length ||
    newHiddenIcons.length !== hiddenIcons.length ||
    !newVisibleIcons.every((icon, index) => icon === visibleIcons[index]) ||
    !newHiddenIcons.every((icon, index) => icon === hiddenIcons[index]);

  return { iconsHaveChanged, newVisibleIcons, newHiddenIcons };
};

export const useIconOverflow = ({
  initialIcons,
  navigationSidebarRef,
  bottomNavigationItemsRef,
}: IconOverflowProps) => {
  const [{ visibleIcons, hiddenIcons }, dispatch] = useReducer(iconReducer, {
    visibleIcons: [],
    hiddenIcons: [],
  });

  const items = [...initialIcons];

  const updateVisibleItems = useCallback(() => {
    if (navigationSidebarRef.current && bottomNavigationItemsRef.current) {
      const result = calculateVisibleItems({
        navigationSidebarRef,
        bottomNavigationItemsRef,
        items,
        visibleIcons,
        hiddenIcons,
      });

      if (!result) return;

      const { iconsHaveChanged, newVisibleIcons, newHiddenIcons } = result;

      if (iconsHaveChanged) {
        dispatch({
          type: 'SET_ICONS',
          visibleIcons: newVisibleIcons,
          hiddenIcons: newHiddenIcons,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const sidebarRefCallback = useCallback(
    (node: any) => {
      if (node) {
        // @ts-ignore
        navigationSidebarRef.current = node;
        updateVisibleItems();

        const resizeObserver = new ResizeObserver(() => {
          updateVisibleItems();
        });

        resizeObserver.observe(node);

        return () => {
          resizeObserver.disconnect();
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateVisibleItems]
  );

  return {
    visibleIcons,
    hiddenIcons,
    sidebarRefCallback,
  };
};
