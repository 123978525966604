import { Text, TruncatedText } from '@candisio/design-system';
import { GoodsReceiptsTableData } from '../types';

export const ConnectedOrdersCell = ({
  value,
}: {
  value?: GoodsReceiptsTableData['orderNumber'];
}) => {
  if (!value?.length) {
    return <Text>-</Text>;
  }

  return (
    <TruncatedText wordBreak="break-word">{value.join(', ')}</TruncatedText>
  );
};
