import {
  CommentCreateErrorKind,
  useCreateCommentOnReimbursementCaseMutation,
} from 'generated-types/graphql.types';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';
import { useParams } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { getCommentCreateErrorTranslationKey } from 'components/Comment/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useTranslation } from 'react-i18next';
import { useOptimisticCacheUpdateForReimbursementComment } from './useOptimisticCacheUpdateForReimbursementComment';
import { CommentFormValues } from 'components/Comment/types';

export type CommentValuesType = Omit<CommentFormValues, 'mentionPlain'>;

export const useCreateCommentOnReimbursement = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const optimisticCacheUpdateForReimbursementComment =
    useOptimisticCacheUpdateForReimbursementComment();

  const [createComment, { loading }] =
    useCreateCommentOnReimbursementCaseMutation();

  const handleCreateComment = async (values: CommentValuesType) => {
    const { data } = await createComment({
      variables: {
        input: {
          entityId: reimbursementId,
          text: values.mention ?? '',
          mentionedUserIds: values.mentionedUserIds,
        },
      },
      update: optimisticCacheUpdateForReimbursementComment(values.mention),
      optimisticResponse: {
        __typename: 'Mutation',
        createCommentOnReimbursementCase: {
          __typename: 'CommentCreated',
          commentId: uuidV4(),
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: { id: reimbursementId },
        },
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
      ],
    });

    if (
      data?.createCommentOnReimbursementCase.__typename ===
      'CommentCreatedError'
    ) {
      const errorMessage = getCommentCreateErrorTranslationKey(
        data.createCommentOnReimbursementCase.kind ??
          CommentCreateErrorKind.UnexpectedError
      );

      error(t(errorMessage));
      return;
    }

    success(t('comment.created'));
    return null;
  };

  return {
    isCreatingComment: loading,
    onCreateComment: handleCreateComment,
  };
};
