import { FilterOptionsAccessor } from '@candisio/design-system';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportReimbursementItemsTableRow } from '../types';

export const useFilterOptions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const filterOptions: FilterOptionsAccessor<ExportReimbursementItemsTableRow> =
    useMemo(
      () => ({
        submittedAt: true,
        targetedMembership: {
          data: [],
        },
        type: {
          data: [
            {
              id: ReimbursementItemType.GeneralExpense,
              label: t(
                'reimbursementView.leftSection.reimbursementItems.general'
              ),
            },
            {
              id: ReimbursementItemType.HospitalityExpense,
              label: t(
                'reimbursementView.leftSection.reimbursementItems.hospitality'
              ),
            },
          ],
        },
      }),
      [t]
    );

  return { filterOptions };
};
