import { Button, Drawer, Grid, Heading, useId } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { useScrollToTarget } from 'views/CreditCards/hooks/useScrollToTarget';
import { useGetTeams } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { useCurrentUserAbsenceManagementUpdate } from './AbsenceManagement/useAbsenceManagementUpdate';
import { AccountSecurity } from './AccountSecurity/AccountSecurity';
import { BankInfoSchemaValues } from './BankInfo/bankInfoSchema';
import { NotificationIntegrationSection } from './NotificationIntegration/NotificationIntegrationSection';
import { SlackIntegrationCard } from './NotificationIntegration/SlackIntegrationCard';
import { useUpdateUserPaymentInfo } from './toolkit/hooks/useUpdateUserPaymentInfo';
import { useChangeEmail } from './useChangeEmail';
import { UserProfileFormsContainer } from './UserProfileFormsContainer';
import { useUpdateCurrentUser } from './useUpdateCurrentUser';

export interface UserProfileUpdateContainerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UserProfileUpdateContainer = ({
  isOpen,
  onClose,
}: UserProfileUpdateContainerProps) => {
  const [t] = useTranslation();
  const [slackIntegrationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.slackIntegration,
  ]);

  const currentUser = useCurrentUser();

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const membershipIds = currentUser?.id ? [currentUser.id] : undefined;

  const { teams, loading: isLoadingTeams } = useGetTeams({
    membershipIds,
  });

  const { handleUpdateCurrentUser, initialValues, updateCurrentUserLoading } =
    useUpdateCurrentUser();

  const { handleUpdateUserPaymentInfo, isUpdateUserPaymentInfoPending } =
    useUpdateUserPaymentInfo();

  const targetToScroll = urlSearchParams.get('scrollToTarget') ?? undefined;

  useScrollToTarget(targetToScroll);

  const paymentInfoDefaultValues: BankInfoSchemaValues = {
    iban: currentUser?.paymentInfo?.iban ?? undefined,
    swiftCode: currentUser?.paymentInfo?.swiftCode ?? undefined,
  };

  const {
    defaultValues: absenceInitialValues,
    defaultValuesLoading: absenceInitialValuesLoading,
    setAbsence,
    setAbsenceLoading,
  } = useCurrentUserAbsenceManagementUpdate();

  const {
    cancelChangeEmailRequest,
    changeEmailLoading,
    newEmail,
    resendVerificationEmail,
    cancelChangeEmailIsLoading,
    resendChangeEmailIsLoading,
  } = useChangeEmail();

  const formId = useId();

  const showForm = !absenceInitialValuesLoading && !isLoadingTeams;

  const isLoading =
    updateCurrentUserLoading ||
    changeEmailLoading ||
    setAbsenceLoading ||
    isUpdateUserPaymentInfoPending;

  return (
    <Drawer isOpen={isOpen} onClose={onClose} containFocus={false}>
      <DrawerLayout
        onClose={onClose}
        header={
          <Heading as="h3">{t('header.profile.tabs.update.title')}</Heading>
        }
        footer={
          <Button
            loading={isLoading}
            form={formId}
            type="submit"
            alignSelf="end"
            gridArea="submit"
            width="space128">
            {t('header.profile.tabs.update.form.actions.save')}
          </Button>
        }>
        <Grid gap="space24" paddingTop="space18">
          {showForm && (
            <UserProfileFormsContainer
              absenceInitialValues={absenceInitialValues}
              paymentInfoDefaultValues={paymentInfoDefaultValues}
              handleSetAbsence={setAbsence}
              onUpdateUserPaymentInfo={handleUpdateUserPaymentInfo}
              absenceInitialValuesLoading={absenceInitialValuesLoading}
              cancelChangeEmailRequest={cancelChangeEmailRequest}
              formId={formId}
              handleUpdateCurrentUser={handleUpdateCurrentUser}
              onNavigate={() => {
                navigate({
                  pathname,
                  search,
                });
              }}
              resendVerificationEmail={resendVerificationEmail}
              cancelChangeEmailIsLoading={cancelChangeEmailIsLoading}
              resendChangeEmailIsLoading={resendChangeEmailIsLoading}
              userProfileInitialValues={initialValues}
              newEmail={newEmail}
              onClose={onClose}
              teams={teams}
            />
          )}
          <AccountSecurity
            isPhoneRemovalAllowed={!Boolean(currentUser?.phoneNumber)}
            currentPhoneNumber={currentUser?.phoneNumber ?? undefined}
            onCloseModal={onClose}
          />
          {slackIntegrationFF && (
            <NotificationIntegrationSection>
              <SlackIntegrationCard />
            </NotificationIntegrationSection>
          )}
        </Grid>
      </DrawerLayout>
    </Drawer>
  );
};
