import { mergeRefs, usePopover } from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { useEffect, useState } from 'react';

const ACTIVATION_LOCAL_STORAGE_POSTFIX = 'manage-relationships-activation-seen';

export const useManageRelationshipsActivationPopover = () => {
  const [activationSeen, setActivationSeen] = useLocalStorage(
    ACTIVATION_LOCAL_STORAGE_POSTFIX,
    false
  );

  const [delayPassed, setDelayPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayPassed(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const { ref, isIntersecting: isTriggerVisible } = useIsElementVisible();
  const shouldOpenActivationPopover =
    !activationSeen && isTriggerVisible && delayPassed;

  const {
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps: _triggerProps,
    triggerRef,
    close,
  } = usePopover({
    placement: 'right',
    updatePlacementOnTriggerMovement: true,
    isOpen: shouldOpenActivationPopover,
  });

  // onPointerDown and onClick from trigger interfere with current props
  const { onPointerDown, onClick, ...triggerProps } = _triggerProps;

  const headerProps = triggerProps;

  const headerRef = mergeRefs([triggerRef, ref]);

  const handleClose = () => {
    close();
    setActivationSeen(true);
  };

  return {
    headerProps,
    headerRef,
    isOpen,
    popoverProps,
    popoverRef,
    close: handleClose,
  };
};
