import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useMemo } from 'react';
import { ReimbursementFormValues } from '../toolkit/reimbursementFormSchema';
import { Reimbursement } from './useFormattedReimbursement';
const fallbackDefaultValues = {
  title: '',
  note: undefined,
  targetMembership: undefined,
  suggestedCostCenter: undefined,
  iban: '',
  accountsPayableNumber: undefined,
};

const getSuggestedCostCenter = (
  suggestedCostCenter?:
    | Reimbursement['suggestedCostCenter']
    | Reimbursement['suggestedCostObject']
) => {
  if (suggestedCostCenter?.id && suggestedCostCenter?.readableName) {
    return {
      value: suggestedCostCenter.id,
      inputValue: suggestedCostCenter.readableName,
    };
  }

  return null;
};

interface UseInitialReimbursementFormValuesReturn {
  values: ReimbursementFormValues;
  defaultValues: ReimbursementFormValues;
}

interface UseInitialReimbursementFormValuesParams {
  reimbursement?: Reimbursement;
  accountsPayableNumber: string | undefined;
}
export const useInitialReimbursementFormValues = ({
  reimbursement,
  accountsPayableNumber,
}: UseInitialReimbursementFormValuesParams): UseInitialReimbursementFormValuesReturn => {
  const currentUserId = useCurrentUser()?.id ?? '';

  // default values for initializing the reimbursement form
  const defaultValues: ReimbursementFormValues = useMemo(() => {
    return {
      ...fallbackDefaultValues,
      targetMembership: [currentUserId],
      accountsPayableNumber,
    };
  }, [accountsPayableNumber, currentUserId]);

  // values for the reimbursement form, based on fetched reimbursement data
  const values: ReimbursementFormValues = useMemo(() => {
    const {
      note,
      title,
      targetMembership,
      suggestedCostCenter,
      iban,
      suggestedCostObject,
    } = reimbursement ?? {};

    return {
      title: title ?? '',
      note: note ?? null,
      iban: iban ?? '',
      targetMembership: targetMembership?.id
        ? [targetMembership?.id]
        : [currentUserId],
      suggestedCostCenter: getSuggestedCostCenter(suggestedCostCenter),
      suggestedCostObject: getSuggestedCostCenter(suggestedCostObject),
      accountsPayableNumber,
    };
  }, [reimbursement, currentUserId, accountsPayableNumber]);

  return {
    values,
    defaultValues,
  };
};
