import { Box } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import { DocumentProcessingContextProvider, useInboxContext } from '../Context';
import { LeftSection } from './LeftSection';
import { RightSection } from './RightSection';

const DocumentProcessingComponent = () => {
  const {
    documentId,
    mainDocumentFile,
    isDocumentFileLoading,
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
  } = useInboxContext();

  return (
    <DetailsLayout
      key={documentId}
      leftSection={<LeftSection />}
      middleSection={
        <Box
          height="100%"
          width="100%"
          paddingX="space16"
          paddingTop="space32"
          overflowY="auto">
          <DocumentViewer
            documentId={documentId}
            attachments={attachments}
            canAttachFiles={attachPermissions}
            documentFile={mainDocumentFile}
            selectedFile={selectedPdf}
            onSelectDocument={setSelectedPdf}
            isLoadingPdf={isDocumentFileLoading}
          />
        </Box>
      }
      rightSection={<RightSection />}
    />
  );
};

export const DocumentProcessing = () => (
  <DocumentProcessingContextProvider>
    <DocumentProcessingComponent />
  </DocumentProcessingContextProvider>
);
