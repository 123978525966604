import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NewsCardContent } from '../types';

export const useNewsCardContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  const newsContentCards: NewsCardContent[] = [
    {
      ...t('newsCarousel.card3', { returnObjects: true }),
      imageAlt: 'quartalsbericht',
      backgroundColor: 'yellow450',
    },
    {
      ...t('newsCarousel.card1', { returnObjects: true }),
      imageAlt: 'candis-akademie-webinar',
      backgroundColor: 'gray800',
    },
    {
      ...t('newsCarousel.card2', { returnObjects: true }),
      imageAlt: 'candis-akademie',
      backgroundColor: 'gray800',
    },
  ];

  return { newsContentCards };
};
