import { useReducer } from 'react';

type PendingApprovalsAckMsgCtx = 'update' | 'deactivate';
export type ShowPendingApprovalsAckMsg =
  | { show: false }
  | {
      show: true;
      /** Whether the error occurred when updating or deactivating the membership */
      context: PendingApprovalsAckMsgCtx;
    };

type ResetError = { action: 'reset' };
type ShowError = { action: 'show'; errorCtx: PendingApprovalsAckMsgCtx };

const reducer = (
  state: ShowPendingApprovalsAckMsg,
  update: ResetError | ShowError
): ShowPendingApprovalsAckMsg => {
  switch (update.action) {
    case 'reset':
      return { show: false };

    case 'show':
      return { show: true, context: update.errorCtx };

    default:
      return state;
  }
};

export const useShowPendingApprovalsAckMsg = () => {
  const [showPendingApprovalsAckMsg, setShowPendingApprovalsAckMsg] =
    useReducer(reducer, { show: false });

  return {
    showPendingApprovalsAckMsg,
    setShowPendingApprovalsAckMsg,
  } as const;
};
