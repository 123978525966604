import { IntegrationName } from 'generated-types/graphql.types';
import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

interface UseReimbursementResult {
  canUseReimbursement: boolean;
  canUseEmployeeAPNSettings: boolean;
}

export const supportedEmployeeAPNIntegrations = [
  IntegrationName.Datev,
  IntegrationName.DatevAdjacent,
];

export const useReimbursement = (): UseReimbursementResult => {
  const entitlements = useEntitlementsFlag();
  const { ft, show } = useFeatureToggle();
  const integration = useIntegrationSettings();
  const [travelExpenseManagementFF, employeeContactTypeFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.travelExpenseManagement,
      FEATURE_FLAGS.employeeContactType,
    ]);

  const isEmployeeAPNSupported =
    supportedEmployeeAPNIntegrations.includes(integration);

  const canUseReimbursement =
    show(ft.REIMBURSEMENT) && travelExpenseManagementFF;

  const canUseEmployeeAPNSettings =
    canUseReimbursement && employeeContactTypeFF && isEmployeeAPNSupported;

  if (entitlements === Entitlements.LEGACY) {
    return {
      canUseReimbursement: false,
      canUseEmployeeAPNSettings: false,
    };
  }

  return {
    canUseReimbursement,
    canUseEmployeeAPNSettings,
  };
};
