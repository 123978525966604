import { Flex, Grid } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { ConfigProvider } from 'antd/es';
import { ReauthenticationModal } from 'components/ReauthenticationModal/ReauthenticationModal';
import { ToastProvider } from 'components/Toast/ToastProvider';
import { GraphQLLoadingIndicator } from 'containers/GraphQLLoadingIndicator/GraphQLLoadingIndicator';
import { NotificationsContainer } from 'containers/notifications/NotificationsProvider';
import { Locale } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import moment from 'moment';
import { AnalyticsProvider } from 'providers/AnalyticsProvider';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { DocumentUploadContextProvider } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import {
  FEATURE_FLAGS,
  FeatureFlagProvider,
} from 'providers/FeatureFlagProvider';
import { FeatureToggleProvider } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import {
  IntercomProvider,
  useIntercomContext,
} from 'providers/IntercomProvider/IntercomProvider';
import { getFallbackLocale, i18n } from 'providers/LocaleProvider';
import { NavigationSidebarProvider } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import { useSelectOrganization } from 'providers/OrganizationProvider';
import { OrganizationContext } from 'providers/OrganizationProvider/OrganizationContext';
import { useTrackingPreferences } from 'providers/PrivacyConsentProvider/hooks/useTrackingPreferences';
import {
  PrivacyConsentProvider,
  usePrivacyContext,
} from 'providers/PrivacyConsentProvider/PrivacyConsentProvider';
import { ReauthenticationProvider } from 'providers/ReauthenticationProvider/ReauthenticationProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import { VWOAnalyticProvider } from 'providers/VMOAnalyticProvider/VWOAnalyticProvider';
import { ReactNode, useContext, useEffect } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps } from 'react-router-dom';
import { languagesAvailable } from 'utils/languagesAntdComponents/index';
import {
  languageToMomentLocale,
  updateAppLanguage,
} from 'utils/update-app-locale';
import { ExportManifest } from '../Integrations/Export/Manifest';
import { AppRoutes } from './AppRoutes';
import { AppBanner } from './components/AppBanner/AppBanner';
import { Header } from './components/Header';
import { LanguageToggle } from './components/Header/components/QaUtils/LanguageToggle';
import { QaUtils } from './components/Header/components/QaUtils/QaUtils';
import { useShowQaUtils } from './components/Header/components/QaUtils/utils';
import { IntercomModal } from './components/IntercomModal/IntercomModal';
import { NavigationSidebar } from './components/NavigationSidebar/NavigationSidebar';
import { Navigation } from './components/Sidebar';
import {
  AppLayoutDeprecated,
  ContentLayout,
  HeaderLayout,
  SidebarLayout,
} from './styles';

interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => (
  <Grid
    as="main"
    background="gray200"
    gap="space20"
    height="100vh"
    overflow="hidden"
    style={{
      gridTemplateColumns: 'auto 1fr',
      gridTemplateAreas: "'sidebar content'",
    }}>
    {children}
  </Grid>
);

export const RenderApp = ({
  path,
  selectedOrganization,
}: {
  path: string;
  selectedOrganization: string;
}) => {
  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  const showQaUtils = useShowQaUtils();
  const { showConsentManager, handleSavePreferences } = usePrivacyContext();
  const { trackingEnabled } = useTrackingPreferences();
  const { intercomIsEnabled } = useIntercomContext();

  return mainNavigationRefactorFF ? (
    <AppLayout>
      <AppBanner />
      <ReauthenticationModal />
      <NavigationSidebar />
      <ContentLayout id="main_content">
        {/** CAN-1126 Must be refactored to be more generic */}
        <AppRoutes path={path} selectedOrganization={selectedOrganization} />
        {/* TODO replace with NavigationSidebar */}

        <ExportManifest />
        <NotificationsContainer />
        {showQaUtils && (
          <Flex position="absolute" gap="space24" top="space0" right="space32">
            <LanguageToggle />
            <QaUtils />
          </Flex>
        )}
      </ContentLayout>
    </AppLayout>
  ) : (
    <AppLayoutDeprecated>
      <HeaderLayout>
        <Header />
      </HeaderLayout>
      <SidebarLayout>
        <Navigation />
      </SidebarLayout>
      <AppBanner />
      {!intercomIsEnabled && (
        <IntercomModal
          showConsentManager={showConsentManager}
          handleIntercomOn={() => handleSavePreferences(trackingEnabled, true)}
        />
      )}
      <ReauthenticationModal />
      <ContentLayout id="main_content">
        {/** CAN-1126 Must be refactored to be more generic */}
        <AppRoutes path={path} selectedOrganization={selectedOrganization} />
        <ExportManifest />
        <NotificationsContainer />
      </ContentLayout>
    </AppLayoutDeprecated>
  );
};

type Props = {} & RouteComponentProps<{ organizationSlug: string }>;

export const AppContainer = ({
  match: {
    path,
    params: { organizationSlug },
  },
}: Props) => {
  useSelectOrganization(organizationSlug);

  const currentUser = useCurrentUser();

  const { selectedOrganization } = useContext(OrganizationContext);

  // XXX we cannot use FFs in here

  const locale = currentUser?.locale ?? '';
  const htmlElement: HTMLElement | null = document.querySelector('html');

  useEffect(() => {
    moment.locale(languageToMomentLocale[i18n.language as Lowercase<Locale>]);
    if (locale && i18n.language !== locale.toLowerCase()) {
      updateAppLanguage(locale);
    }

    htmlElement?.setAttribute('lang', locale.toLowerCase());
  }, [htmlElement, locale]);

  if (!selectedOrganization || organizationSlug !== selectedOrganization) {
    // no selected organization on initial render
    // do not render <Header>; wait till useSelectOrganization sets
    // selectedOrganization with useEffect
    return (
      <AppLayoutDeprecated>
        <HeaderLayout />
      </AppLayoutDeprecated>
    );
  }

  if (!organizationSlug) {
    return (
      <AppLayoutDeprecated>
        <HeaderLayout>
          <Header />
        </HeaderLayout>
      </AppLayoutDeprecated>
    );
  }

  const isLocaleEqualToTargetLocale = (
    currentLocale: Locale,
    targetLocale: Locale
  ) => {
    return currentLocale === targetLocale;
  };

  return (
    /** @ts-expect-error TODO: React upgrade props types mismatch */
    <FeatureFlagProvider>
      <FeatureToggleProvider>
        <ThemeProvider>
          <PrivacyConsentProvider>
            <NavigationSidebarProvider>
              <AnalyticsProvider>
                <IntercomProvider>
                  <VWOAnalyticProvider>
                    <GraphQLLoadingIndicator>
                      <DocumentUploadContextProvider>
                        <ReauthenticationProvider>
                          <ToastProvider>
                            <RenderApp
                              path={path}
                              selectedOrganization={selectedOrganization}
                            />
                            <ConfigProvider
                              locale={
                                isLocaleEqualToTargetLocale(
                                  locale || getFallbackLocale(),
                                  Locale.En
                                )
                                  ? languagesAvailable.english
                                  : languagesAvailable.german
                              }></ConfigProvider>
                          </ToastProvider>
                        </ReauthenticationProvider>
                      </DocumentUploadContextProvider>
                    </GraphQLLoadingIndicator>
                  </VWOAnalyticProvider>
                </IntercomProvider>
              </AnalyticsProvider>
            </NavigationSidebarProvider>
          </PrivacyConsentProvider>
        </ThemeProvider>
      </FeatureToggleProvider>
    </FeatureFlagProvider>
  );
};
