import { Avatar, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

export interface PaymentCreatorProps {
  avatarUrl?: string;
  name: string;
  date: Date;
}

export const PaymentCreator = ({
  avatarUrl,
  date,
  name,
}: PaymentCreatorProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <Grid alignItems="center" gap="space16" templateColumns="auto 1fr">
      <Avatar name={name} img={avatarUrl} hasTooltip={false} />
      <Grid>
        <Text fontSize="basic">{name}</Text>
        <Text fontSize="xsmall">
          {tPayments('details.paidOn', {
            createdAt: DateFormatters.compactDatetime(date),
          })}
        </Text>
      </Grid>
    </Grid>
  );
};
