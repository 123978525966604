import {
  DocumentCommentEventDataFragment,
  ActivityUser,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { v4 as uuidV4 } from 'uuid';

interface GetOptimisticCommentProps {
  text: string;
}

export const useOptimisticComment = () => {
  const currentUser = useCurrentUser();

  const author: ActivityUser = {
    id: currentUser?.id ?? '',
    name: currentUser?.name ?? '',
    email: currentUser?.email,
    avatarUrl: currentUser?.avatarUrl,
    __typename: 'ActivityUser',
  };

  const createdComment = (text: string): DocumentCommentEventDataFragment => {
    const commentMetadata = {
      id: uuidV4(),
      createdAt: new Date().toISOString(),
    };

    return {
      __typename: 'DocumentCommentEvent',
      user: author,
      clientInfo: null,
      comment: {
        id: uuidV4(),
        text: text,
        author: {
          avatarUrl: currentUser?.avatarUrl,
        },
        __typename: 'ActivityComment',
      },
      ...commentMetadata,
    };
  };

  const getOptimisticComment = ({ text }: GetOptimisticCommentProps) =>
    createdComment(text);

  return { getOptimisticComment };
};
