import { Flex, Box, Icon, Grid, PopoverProps } from '@candisio/design-system';
import { WhatsNew } from 'components/WhatsNewPopover/WhatsNewPopover';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

export const NavigationActivationPopover = ({
  popoverProps,
  popoverRef,
  onClose,
}: {
  popoverProps: PopoverProps;
  popoverRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);

  return (
    <WhatsNew.Wrapper
      popoverProps={popoverProps}
      popoverRef={popoverRef}
      width="292px">
      <Grid gap="space24">
        <Flex gap="space24" direction="column">
          <WhatsNew.Title>{t('activationPopover.title')}</WhatsNew.Title>
          <WhatsNew.Text>{t('activationPopover.description')}</WhatsNew.Text>

          <Flex gap="space10" alignItems="center">
            <Box
              background="gray0"
              width="44px"
              height="44px"
              padding="12px"
              borderRadius="small">
              <Icon
                icon="menuIntercomAlternative"
                color="gray600"
                size="20px"
              />
            </Box>
            <WhatsNew.Text>
              {t('activationPopover.supportDescription')}
            </WhatsNew.Text>
          </Flex>

          <Flex gap="space10" alignItems="center">
            <Box
              background="gray0"
              width="44px"
              height="44px"
              padding="12px"
              borderRadius="small">
              <Icon icon="menuNotifications" color="gray600" size="20px" />
            </Box>
            <WhatsNew.Text>
              {t('activationPopover.notificationDescription')}
            </WhatsNew.Text>
          </Flex>
        </Flex>

        <Flex justifyContent="center" alignItems="center" direction="column">
          <WhatsNew.Button onClick={onClose}>
            {t('common:common.gotIt')}
          </WhatsNew.Button>
        </Flex>
      </Grid>
    </WhatsNew.Wrapper>
  );
};
