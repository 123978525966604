import { useState, DragEvent, ChangeEvent, useRef, useCallback } from 'react';
import { useAcceptedFileTypesForInvoice } from 'components/FileDropZone/useAcceptedFileTypesForInvoice';

interface DragAndDropProps {
  onFilesSelected: (
    ev: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
    files: File[]
  ) => any;
  isXmlDocumentUploadAllowed?: boolean;
}

export const useDragAndDrop = ({
  onFilesSelected,
  isXmlDocumentUploadAllowed,
}: DragAndDropProps) => {
  const [isAcceptedFileType, setIsAcceptedFileType] = useState<boolean>(false);
  const [isFileDraggable, setIsFileDraggable] = useState<boolean>(false);
  const [draggedFileCount, setDraggedFileCount] = useState<number>(0);

  const acceptedFileTypes = useAcceptedFileTypesForInvoice({
    isXmlDocumentUploadAllowed,
  });

  const filesSelected = useCallback(
    (
      ev: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
      files: File[]
    ) => {
      onFilesSelected(ev, files);
    },
    [onFilesSelected]
  );

  const onFileDrop = useCallback(
    (ev: DragEvent<HTMLDivElement>, ...data: any[]) => {
      ev.stopPropagation();
      ev.preventDefault();
      setIsFileDraggable(false);
      setDraggedFileCount(0);
      filesSelected(ev, Array.from(ev.dataTransfer.files));
    },
    [filesSelected]
  );

  const onDragOver = useCallback(
    (ev: DragEvent<HTMLDivElement>) => {
      const draggedOverFileCount = ev.dataTransfer.items.length;
      const supportedFileType = Array.from(ev.dataTransfer.items).every(itm =>
        acceptedFileTypes.includes(itm.type)
      );

      // This event is only here so that chrome
      // doesn't open the file when it is dragged in
      ev.stopPropagation();
      ev.preventDefault();
      setIsAcceptedFileType(supportedFileType);
      setIsFileDraggable(true);
      setDraggedFileCount(draggedOverFileCount);
    },
    [acceptedFileTypes]
  );

  const fileUploadRef = useRef<HTMLInputElement>(null);
  const onDropZoneClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const onFilesChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      filesSelected(ev, Array.from(ev.target.files));
    }
  };

  const onDragLeave = useCallback(() => {
    setIsFileDraggable(false);
    setDraggedFileCount(0);
  }, []);

  const hasNoFile = draggedFileCount === 0;

  const canUserDropFile = hasNoFile || isAcceptedFileType;

  return {
    isFileDraggable,
    onFileDrop,
    onDragLeave,
    onDragOver,
    onDropZoneClick,
    onFilesChanged,
    canUserDropFile,
    fileUploadRef,
  };
};
