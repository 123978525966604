import { Flex, Grid, TruncatedText } from '@candisio/design-system';
import { PercentageProgressBar } from 'components/PercentageProgressBar/PercentageProgressBar';
import { determineColorBar } from 'containers/credit-cards/CreditCardCarousel/components/AvailableBalance/utils';
import {
  CardStatus,
  CardType,
  DocumentCurrency,
  TransactionAmount,
} from 'generated-types/graphql.types';
import { Row } from 'react-table';
import { CreditCardsTableData } from 'views/CreditCards/types';
import { formatFromCentsWithCurrency } from 'views/CreditCards/utils/formatFromCentsWithCurrency';

export const AmountAgainstLimitCell = ({
  row,
  value,
}: {
  value: TransactionAmount | undefined;
  row: Row<CreditCardsTableData>;
}) => {
  const isCardActive = row.original.status === CardStatus.Active;
  const limits = {
    availableBalance: {
      value: value?.value ?? 0,
      currency: value?.currency ?? DocumentCurrency.Eur,
    },
    perInterval: {
      value: row.original.limit?.value ?? 0,
      currency: row.original.limit?.currency ?? DocumentCurrency.Eur,
    },
  };

  const percentage =
    (limits.availableBalance.value / limits.perInterval.value) * 100;

  const formattedLimitsWithCurrency = {
    availableBalance: formatFromCentsWithCurrency(
      limits.availableBalance.currency,
      limits.availableBalance.value
    ),
    perInterval: formatFromCentsWithCurrency(
      limits.perInterval.currency,
      limits.perInterval.value
    ),
  };

  const isSingleUseCard = row.original.cardType === CardType.SingleUse;

  // Showing only available balance for virtualx1 cards

  if (isSingleUseCard) {
    return (
      <TruncatedText
        wordBreak="break-all"
        color={isCardActive ? 'gray800' : 'gray600'}>
        {formattedLimitsWithCurrency.availableBalance ?? '-'}
      </TruncatedText>
    );
  }

  // Showing progress bar for virtual and physical cards
  return (
    <Grid flex={1}>
      <Flex justifyContent="space-between">
        <TruncatedText color={isCardActive ? 'gray800' : 'gray600'}>
          {formattedLimitsWithCurrency.availableBalance ?? '-'}
        </TruncatedText>
        <TruncatedText color="gray600">
          {formattedLimitsWithCurrency.perInterval ?? '-'}
        </TruncatedText>
      </Flex>
      <PercentageProgressBar
        showAnimation={false}
        color={determineColorBar(percentage)}
        percentage={percentage}
      />
    </Grid>
  );
};
