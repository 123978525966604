import { Box, Text } from '@candisio/design-system';
import { ReactNode } from 'react';

interface HeaderLayoutProps {
  children: ReactNode;
}

export const HeaderLayout = ({ children }: HeaderLayoutProps) => {
  return (
    <Box borderRadius="medium" padding="space32 space32 space32 space16">
      <Text
        id="greeter"
        fontWeight="regular"
        fontFamily="heading"
        fontSize="60px"
        color="gray800"
        textTransform="capitalize">
        {children}
      </Text>
    </Box>
  );
};
