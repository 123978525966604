import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  ExportReimbursementItemsTableColumnKeys,
  ExportReimbursementItemsTableRow,
} from './types';

const mapColumnIdToTranslationKey: Record<
  ExportReimbursementItemsTableColumnKeys,
  string
> = {
  expenseId: 'expense.table.columns.expenseId',
  grossAmount: 'expense.table.columns.grossAmount',
  reasonExpense: 'expense.table.columns.reasonExpense',
  submittedAt: 'expense.table.columns.submittedAt',
  targetedMembership: 'expense.table.columns.targetedMembership',
  titleReimbursement: 'expense.table.columns.titleReimbursement',
  exportMethod: 'expense.table.columns.exportMethod',
  exportStatus: 'expense.table.columns.exportStatus',
  type: 'expense.table.columns.type',
  canBeSelectedTooltipText: '',
  isDisabled: '',
  reimbursementColletionId: '',
} as const;

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<ExportReimbursementItemsTableRow>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  const columnId = column.id as ExportReimbursementItemsTableColumnKeys;

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
      {t(mapColumnIdToTranslationKey[columnId])}
    </TruncatedText>
  );
};
