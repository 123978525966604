import { ReactNode, createContext } from 'react';
import { Theme } from '../types';
import { defaultTheme } from './defaultTheme';

export const ThemeContext = createContext(defaultTheme);

export interface ThemeProviderProps {
  children: ReactNode;
  theme: Theme;
}

export const ThemeProvider = ({ children, theme }: ThemeProviderProps) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
);
