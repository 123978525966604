import { Box, Button, Grid, Heading, Item } from '@candisio/design-system';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { nodocumentData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Reimbursement } from '../hooks/useFormattedReimbursement';
import { ReimbursementItem } from './ReimbursementItem';

interface CompletedReimbursementListProps {
  isLoading: boolean;
  reimbursements: Reimbursement[];
  reimbursementsCount: number;
  onEndReached?: (index: number) => void;
  onReset?: () => void;
}

export const CompletedReimbursementList = ({
  isLoading,
  reimbursements,
  reimbursementsCount,
  onEndReached,
  onReset,
}: CompletedReimbursementListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <FilterableList
      heading={
        <Grid templateColumns="1fr auto" alignItems="center" width="100%">
          <Heading as="h2">{t('dashboard.list.completedListHeader')}</Heading>
          {reimbursementsCount > 0 && (
            <Button variant="tertiary" justifySelf="end">
              {t('dashboard.list.actions.goToArchiveCta')}
            </Button>
          )}
        </Grid>
      }
      isLoading={isLoading}
      onEndReached={onEndReached}
      emptyDataState={
        <Box paddingX="space16">
          <EmptyDataState
            lottieAnimation={nodocumentData}
            translation="reimbursement:dashboard.list.completedListNoData"
          />
        </Box>
      }>
      {reimbursements.map(item => (
        <Item key={item.id} textValue={item.id}>
          <ReimbursementItem {...item} />
        </Item>
      ))}
    </FilterableList>
  );
};
