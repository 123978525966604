import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUnlinkTransactionFromDocumentMutation } from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { documentHistoryQuery } from 'views/queries';
import { getCardIssuerTransactionById } from 'views/TransactionAssociation/gql';
import { getDocumentQuery } from '../DocumentProcessing/queries';

export const useUnlinkTransaction = (
  transactionId: string | undefined,
  documentId: string | undefined
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const showGqlError = useShowError();

  const [unlinkTransactionFromDocumentMutation, { loading: isUnlinkPending }] =
    useUnlinkTransactionFromDocumentMutation();

  const counterQueries = useCounterQueries();

  const unlinkTransaction = useCallback(async () => {
    if (!transactionId || !documentId) return;

    try {
      const { data, errors } = await unlinkTransactionFromDocumentMutation({
        variables: { transactionId, documentId },
        refetchQueries: [
          { query: getDocumentQuery, variables: { id: documentId } },
          { query: documentHistoryQuery, variables: { id: documentId } },
          {
            query: getCardIssuerTransactionById,
            variables: { id: transactionId },
          },
          ...counterQueries,
        ],
      });

      if (data?.unlinkTransactionFromDocument) {
        success(t('transactionDetailsCardContextMenu.successMessage'));
      }

      if (errors?.length) {
        error(t('transactionDetailsCardContextMenu.errorMessage'));
      }
    } catch (err: unknown) {
      showGqlError(err as GQLError);

      validationErrorsExtractor(err);
    }
  }, [
    counterQueries,
    documentId,
    error,
    showGqlError,
    success,
    t,
    transactionId,
    unlinkTransactionFromDocumentMutation,
  ]);

  return { isUnlinkPending, unlinkTransaction };
};
