import { BookingValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { BookingEntryKeys } from '../../utils/generateSplitBookingsFormFieldNames';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';

export interface ActionRules {
  canAddAndDeleteSplits: boolean;
  canAcceptAllSplits: boolean;
}

export interface FieldRule {
  isReadOnly: (
    status: ReimbursementCaseStatus,
    booking: BookingValues
  ) => boolean;
}

export type FieldRules = {
  [K in BookingEntryKeys]: FieldRule;
};

const READONLY_STATUSES = new Set([
  ReimbursementCaseStatus.Approving,
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exporting,
  ReimbursementCaseStatus.PartiallyExported,
]);

const isReadOnlyStatus = (status: ReimbursementCaseStatus) =>
  READONLY_STATUSES.has(status);

const isNonModifiableStatus = (status: ReimbursementCaseStatus) =>
  status === ReimbursementCaseStatus.Approved ||
  status === ReimbursementCaseStatus.Exported ||
  status === ReimbursementCaseStatus.Archived ||
  status === ReimbursementCaseStatus.Exporting ||
  status === ReimbursementCaseStatus.PartiallyExported;

const fieldRules: FieldRules = {
  splitAmount: { isReadOnly: isReadOnlyStatus },
  taxPresentation: { isReadOnly: isReadOnlyStatus },
  vatRate: { isReadOnly: isReadOnlyStatus },
  taxCode: {
    isReadOnly: (status, value) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving && !!value.taxCode),
  },
  artistSocialInsuranceCode: {
    isReadOnly: (status, booking) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving &&
        !!booking?.artistSocialInsuranceCode),
  },
  costCenter: {
    isReadOnly: (status, booking) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving &&
        !!booking.costCenter?.value),
  },
  costObject: {
    isReadOnly: (status, booking) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving &&
        !!booking.costObject?.value),
  },
  extraCostInfo: {
    isReadOnly: (status, booking) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving &&
        !!booking.extraCostInfo?.value),
  },
  generalLedgerAccount: {
    isReadOnly: (status, booking) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving &&
        !!booking.generalLedgerAccount?.value),
  },
  note: {
    isReadOnly: (status, booking) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving && !!booking.note),
  },
  postingText: {
    isReadOnly: (status, booking) =>
      isNonModifiableStatus(status) ||
      (status === ReimbursementCaseStatus.Approving && !!booking.postingText),
  },
};

export type BookingFieldRules = {
  [K in BookingEntryKeys]: { isReadOnly: boolean };
};

export const getFieldRules = (
  status: ReimbursementCaseStatus,
  bookings: BookingValues[]
): BookingFieldRules[] => {
  const rules = bookings.map(booking =>
    Object.keys(fieldRules).reduce((acc, fieldName) => {
      const key = fieldName as BookingEntryKeys;
      const fieldRule = fieldRules[key];
      const isReadOnly = fieldRule.isReadOnly(status, booking);
      acc[key] = { isReadOnly };
      return acc;
    }, {} as BookingFieldRules)
  );

  return rules;
};

export const getActionRules = (
  status: ReimbursementCaseStatus,
  rules: BookingFieldRules[]
): ActionRules => {
  const isEditingRestricted = isReadOnlyStatus(status);

  const hasEditableFields = rules.some(rule =>
    Object.values(rule).some(field => !field.isReadOnly)
  );

  return {
    canAddAndDeleteSplits: !isEditingRestricted,
    canAcceptAllSplits: hasEditableFields,
  };
};
