import {
  Button,
  Grid,
  Popover,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ReplaceButtonProps {
  disabled?: boolean;
  onConfirm?: () => void;
}

export const ReplaceButton = ({ disabled, onConfirm }: ReplaceButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isOpen, triggerProps, triggerRef, popoverProps, popoverRef, close } =
    usePopover();

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        alignSelf="center"
        variant="tertiary"
        size="small"
        disabled={disabled}>
        <TruncatedText>
          {t('reimbursementView.middleSection.form.replaceCta')}
        </TruncatedText>
      </Button>
      {!disabled && isOpen && (
        <Popover
          width="320px"
          padding="space18"
          {...popoverProps}
          ref={popoverRef}>
          <Grid gap="space20">
            {t(
              'reimbursementView.middleSection.form.replaceConfirmation.description'
            )}
            <Grid autoFlow="column" gap="space8">
              <Button onClick={close} variant="secondary">
                {t(
                  'reimbursementView.middleSection.form.replaceConfirmation.no'
                )}
              </Button>
              <Button color="red" onClick={onConfirm}>
                {t(
                  'reimbursementView.middleSection.form.replaceConfirmation.yes'
                )}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};
