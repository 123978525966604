import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { SmartFields } from 'components/Form/SplitBookingsForm/types';
import {
  GetDocumentQuery,
  useSmartFieldSuggestionsForApproverQuery,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { FieldSources } from 'models';
import { useSap } from 'orgConfig/sap';
import { isDocWithMultipleBookings } from 'utils/document-with-bookings';

/**
 * Only returns SmartFieldSuggestions for documents with single booking
 */
export const useSfsForApprovers = (
  document: ProcessedDocument | NonNullable<GetDocumentQuery['getDocument']>
) => {
  const smartFieldsValues: SmartFields = {};
  const { shouldUseSapPurchaseOrder } = useSap();

  const { data: sfsApproverData, loading } =
    useSmartFieldSuggestionsForApproverQuery({
      variables: {
        documentId: document?.id,
        contactId: document.contact?.value.id as string,
      },
      fetchPolicy: 'cache-and-network',
      skip: !document.contact?.value.id,
    });

  const sfsSuggestionForApprover = sfsApproverData?.sfsSuggestionForApprover;

  const isDocConnectedToSapPurchaseOrder =
    shouldUseSapPurchaseOrder && !isNil(document.bookings);

  if (
    !sfsSuggestionForApprover ||
    isDocConnectedToSapPurchaseOrder ||
    isDocWithMultipleBookings(document)
  ) {
    return { smartFieldsValues, loading };
  }

  if (
    !document.bookings?.[0]?.costCenter?.value &&
    sfsSuggestionForApprover.costCenter1?.value &&
    sfsSuggestionForApprover.costCenter1.confidence
  ) {
    smartFieldsValues.costCenter = {
      confidence: sfsSuggestionForApprover.costCenter1.confidence,
      source: FieldSources.smartFieldSuggestions,
      id: sfsSuggestionForApprover.costCenter1.value.id,
      readableName: sfsSuggestionForApprover.costCenter1.value.readableName,
    };
  }

  if (
    !document.bookings?.[0]?.costObject?.value &&
    sfsSuggestionForApprover.costCenter2?.value &&
    sfsSuggestionForApprover.costCenter2?.confidence
  ) {
    smartFieldsValues.costObject = {
      confidence: sfsSuggestionForApprover.costCenter2.confidence,
      source: FieldSources.smartFieldSuggestions,
      id: sfsSuggestionForApprover.costCenter2.value.id,
      readableName: sfsSuggestionForApprover.costCenter2.value.readableName,
    };
  }

  if (
    !document.bookings?.[0]?.bookingKey?.value &&
    sfsSuggestionForApprover.bookingKey?.value &&
    sfsSuggestionForApprover.bookingKey.confidence
  ) {
    smartFieldsValues.bookingKey = {
      confidence: sfsSuggestionForApprover.bookingKey.confidence,
      source: FieldSources.smartFieldSuggestions,
      id: sfsSuggestionForApprover.bookingKey.value.id,
      readableName: sfsSuggestionForApprover.bookingKey.value.readableName,
    };
  }

  if (
    !document.bookings?.[0]?.generalLedgerAccount?.value &&
    sfsSuggestionForApprover.generalLedgerAccount?.value &&
    sfsSuggestionForApprover.generalLedgerAccount.confidence
  ) {
    smartFieldsValues.generalLedgerAccount = {
      confidence: sfsSuggestionForApprover.generalLedgerAccount.confidence,
      source: FieldSources.smartFieldSuggestions,
      id: sfsSuggestionForApprover.generalLedgerAccount.value.id,
      readableName:
        sfsSuggestionForApprover.generalLedgerAccount.value.readableName,
    };
  }

  return { smartFieldsValues, loading };
};
