import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Promotion } from '../types';

export const usePromotionContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  const contractManagementPromotion: Promotion = {
    id: 'contractManagement',
    label: {
      text: t('contractManagement.label.text'),
      color: 'purple',
    },
    title: t('contractManagement.title'),
    benefits: [
      {
        text: t('contractManagement.benefit1.text'),
        imageUrl: t('contractManagement.benefit1.imageUrl'),
      },
      {
        text: t('contractManagement.benefit2.text'),
        imageUrl: t('contractManagement.benefit2.imageUrl'),
      },
      {
        text: t('contractManagement.benefit3.text'),
        imageUrl: t('contractManagement.benefit3.imageUrl'),
      },
      {
        text: t('contractManagement.benefit4.text'),
        imageUrl: t('contractManagement.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('contractManagement.ctaButton.admin.text'),
      color: 'purple',
    },
    link: {
      text: t('contractManagement.link.text'),
      url: t('contractManagement.link.url'),
      color: 'purple',
    },
  };

  const documentManagementPromotion: Promotion = {
    id: 'documentManagement',
    label: {
      text: t('documentManagement.label.text'),
      color: 'purple',
    },
    title: t('documentManagement.title'),
    benefits: [
      {
        text: t('documentManagement.benefit1.text'),
        imageUrl: t('documentManagement.benefit1.imageUrl'),
      },
      {
        text: t('documentManagement.benefit2.text'),
        imageUrl: t('documentManagement.benefit2.imageUrl'),
      },
      {
        text: t('documentManagement.benefit3.text'),
        imageUrl: t('documentManagement.benefit3.imageUrl'),
      },
      {
        text: t('documentManagement.benefit4.text'),
        imageUrl: t('documentManagement.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('documentManagement.ctaButton.admin.text'),
      color: 'purple',
    },
    link: {
      text: t('documentManagement.link.text'),
      url: t('documentManagement.link.url'),
      color: 'purple',
    },
  };

  const reimbursementsPromotionLegacy: Promotion = {
    id: 'reimbursementsLegacy',
    label: {
      text: t('reimbursementsLegacy.label.text'),
      color: 'purple',
    },
    title: t('reimbursementsLegacy.title'),
    benefits: [
      {
        text: t('reimbursementsLegacy.benefit1.text'),
        imageUrl: t('reimbursementsLegacy.benefit1.imageUrl'),
      },
      {
        text: t('reimbursementsLegacy.benefit2.text'),
        imageUrl: t('reimbursementsLegacy.benefit2.imageUrl'),
      },
      {
        text: t('reimbursementsLegacy.benefit3.text'),
        imageUrl: t('reimbursementsLegacy.benefit3.imageUrl'),
      },
      {
        text: t('reimbursementsLegacy.benefit4.text'),
        imageUrl: t('reimbursementsLegacy.benefit4.imageUrl'),
        tags: [t('reimbursementsLegacy.benefit4.tag')],
      },
    ],
    ctaButton: {
      text: t('reimbursementsLegacy.ctaButton.admin.text'),
      color: 'purple',
    },
    link: {
      text: t('reimbursementsLegacy.link.text'),
      url: t('reimbursementsLegacy.link.url'),
      color: 'purple',
      externalIcon: true,
    },
  };

  const reimbursementsPromotionNonLegacy: Promotion = {
    id: 'reimbursementsNonLegacy',
    label: {
      text: t('reimbursementsNonLegacy.label.text'),
      color: 'blue',
    },
    title: t('reimbursementsNonLegacy.title'),
    benefits: [
      {
        text: t('reimbursementsNonLegacy.benefit1.text'),
        imageUrl: t('reimbursementsNonLegacy.benefit1.imageUrl'),
        color: 'blue',
      },
      {
        text: t('reimbursementsNonLegacy.benefit2.text'),
        imageUrl: t('reimbursementsNonLegacy.benefit2.imageUrl'),
        color: 'blue',
      },
      {
        text: t('reimbursementsNonLegacy.benefit3.text'),
        imageUrl: t('reimbursementsNonLegacy.benefit3.imageUrl'),
        color: 'blue',
      },
      {
        text: t('reimbursementsNonLegacy.benefit4.text'),
        imageUrl: t('reimbursementsNonLegacy.benefit4.imageUrl'),
        color: 'blue',
        tags: [t('reimbursementsLegacy.benefit4.tag')],
      },
    ],
    link: {
      text: t('reimbursementsNonLegacy.link.text'),
      url: t('reimbursementsNonLegacy.link.url'),
      color: 'blue',
      externalIcon: true,
    },
  };

  // Datev self booker
  const provisionsPromotion: Promotion = {
    id: 'datevSelfBooker',
    label: {
      text: t('datevSelfBooker.label.text'),
      color: 'purple',
    },
    title: t('datevSelfBooker.title'),
    benefits: [
      {
        text: t('datevSelfBooker.benefit1.text'),
        imageUrl: t('datevSelfBooker.benefit1.imageUrl'),
      },
      {
        text: t('datevSelfBooker.benefit2.text'),
        imageUrl: t('datevSelfBooker.benefit2.imageUrl'),
      },
      {
        text: t('datevSelfBooker.benefit3.text'),
        imageUrl: t('datevSelfBooker.benefit3.imageUrl'),
      },
      {
        text: t('datevSelfBooker.benefit4.text'),
        imageUrl: t('datevSelfBooker.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('datevSelfBooker.ctaButton.admin.text'),
      color: 'purple',
    },
  };

  const creditCardsPromotion: Promotion = {
    id: 'creditCards',
    label: {
      text: t('creditCards.label.text'),
      color: 'blue',
    },
    title: t('creditCards.title'),
    benefits: [
      {
        text: t('creditCards.benefit1.text'),
        imageUrl: t('creditCards.benefit1.imageUrl'),
      },
      {
        text: t('creditCards.benefit2.text'),
        imageUrl: t('creditCards.benefit2.imageUrl'),
      },
      {
        text: t('creditCards.benefit3.text'),
        imageUrl: t('creditCards.benefit3.imageUrl'),
      },
      {
        text: t('creditCards.benefit4.text'),
        imageUrl: t('creditCards.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('creditCards.ctaButton.admin.text'),
      color: 'blue',
    },
  };

  const upsellPlusPromotion: Promotion = {
    id: 'upsellPlus',
    label: {
      text: t('upsellPlus.label.text'),
      color: 'purple',
    },
    title: t('upsellPlus.title'),
    benefits: [
      {
        text: t('upsellPlus.benefit1.text'),
        imageUrl: t('upsellPlus.benefit1.imageUrl'),
      },
      {
        text: t('upsellPlus.benefit2.text'),
        imageUrl: t('upsellPlus.benefit2.imageUrl'),
      },
      {
        text: t('upsellPlus.benefit3.text'),
        imageUrl: t('upsellPlus.benefit3.imageUrl'),
      },
      {
        text: t('upsellPlus.benefit4.text'),
        imageUrl: t('upsellPlus.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('upsellMax.ctaButton.admin.text'),
      color: 'purple',
    },
  };

  const upsellMaxPromotion: Promotion = {
    id: 'upsellMax',
    label: {
      text: t('upsellMax.label.text'),
      color: 'purple',
    },
    title: t('upsellMax.title'),
    benefits: [
      {
        text: t('upsellMax.benefit1.text'),
        imageUrl: t('upsellMax.benefit1.imageUrl'),
      },
      {
        text: t('upsellMax.benefit2.text'),
        imageUrl: t('upsellMax.benefit2.imageUrl'),
      },
      {
        text: t('upsellMax.benefit3.text'),
        imageUrl: t('upsellMax.benefit3.imageUrl'),
      },
      {
        text: t('upsellMax.benefit4.text'),
        imageUrl: t('upsellMax.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('upsellMax.ctaButton.admin.text'),
      color: 'purple',
    },
  };

  const mobileAppPromotion: Promotion = {
    id: 'mobileApp',
    label: {
      text: t('mobileApp.label.text'),
      color: 'blue',
    },
    title: t('mobileApp.title'),
    benefits: [
      {
        text: t('mobileApp.benefit1.text'),
        imageUrl: t('mobileApp.benefit1.imageUrl'),
      },
      {
        text: t('mobileApp.benefit2.text'),
        imageUrl: t('mobileApp.benefit2.imageUrl'),
      },
      {
        text: t('mobileApp.benefit3.text'),
        imageUrl: t('mobileApp.benefit3.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('mobileApp.ctaButton.text'),
      color: 'blue',
    },
  };

  const tagsPromotion: Promotion = t(
    'documentTagsPromo', // ignore prettier
    { returnObjects: true }
  );

  tagsPromotion.id = 'settings-tags';

  const contractSubCategoriesPromotion: Promotion = t(
    'contractCustomSubCategoriesPromo',
    { returnObjects: true }
  );

  contractSubCategoriesPromotion.id = 'settings-contractSubCategories';

  return {
    contractManagementPromotion,
    documentManagementPromotion,
    reimbursementsPromotionLegacy,
    provisionsPromotion,
    creditCardsPromotion,
    mobileAppPromotion,
    upsellPlusPromotion,
    upsellMaxPromotion,
    contractSubCategoriesPromotion,
    tagsPromotion,
    reimbursementsPromotionNonLegacy,
  };
};
