import { DocumentApprovalsFormContainer } from 'components/Form/DocumentApprovalsForm/DocumentApprovalsFormContainer';
import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';

type Props = {
  document: ProcessedDocument;
  cycleDocument: () => void;
  toggleEditMode: () => void;
};

export const Open = ({ toggleEditMode, cycleDocument, document }: Props) => {
  return (
    <DocumentApprovalsFormContainer
      document={document}
      cycleDocument={cycleDocument}
      toggleEditMode={toggleEditMode}
    />
  );
};
