import { useTheme } from '@candisio/design-system';

export const useMentionsInputSyles = (value: string) => {
  const { fontSizes, radii, space } = useTheme();

  const padding = `${space.space8} ${value ? space.space80 : space.space24} ${
    space.space8
  } ${space.space16}`;

  return {
    highlighter: {
      fontSize: fontSizes.basic,
      padding,
    },
    input: {
      fontSize: fontSizes.basic,
      padding,
    },
    suggestions: {
      borderRadius: radii.medium,
      margin: '2rem 0',
    },
  };
};
