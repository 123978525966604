export const nameToColorGenerator = (name: string): string => {
  const saturation = '50%';
  const lightness = '70%';

  // So the hash is literally just a number which is generated
  // from the name. It doesn't matter what we do with it.
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash += name.charCodeAt(i);
  }

  return `hsl(${hash % 360}, ${saturation}, ${lightness})`;
};
