import { useRequestReimbursementCaseAmendmentMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';

export const useRequestAmendment = () => {
  const [requestAmendmentAction, { loading: isRequestAmendmentActionPending }] =
    useRequestReimbursementCaseAmendmentMutation();

  const requestAmendment = useCallback(
    async (reimbursementId: string, comment: string) => {
      const response = await requestAmendmentAction({
        refetchQueries: [
          'reimbursementCasesCount',
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
        ],
        variables: {
          input: {
            caseId: reimbursementId,
            text: comment,
          },
        },
      });

      if (response.data?.requestReimbursementCaseAmendment.success) {
        return { status: 'success' };
      }

      return { status: 'error' };
    },
    [requestAmendmentAction]
  );
  return { requestAmendment, isRequestAmendmentActionPending };
};
