import {
  ActivityUser,
  ReimbursementCaseCommentAddedEvent,
  ReimbursementCaseCommentAddedEventDataFragment,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { v4 as uuidV4 } from 'uuid';

type CreatedCommentResult = Pick<
  ReimbursementCaseCommentAddedEvent,
  'id' | 'createdAt'
> &
  ReimbursementCaseCommentAddedEventDataFragment;

export const useOptimisticReimbursementComment = () => {
  const currentUser = useCurrentUser();

  const { id = '', name = '', email, avatarUrl } = currentUser ?? {};

  const author: ActivityUser = {
    id,
    name,
    email,
    avatarUrl,
    __typename: 'ActivityUser',
  };

  const createdComment = (text?: string): CreatedCommentResult => {
    return {
      __typename: 'ReimbursementCaseCommentAddedEvent',
      user: author,
      id: uuidV4(),
      createdAt: new Date().toISOString(),
      properties: {
        __typename: 'ActivityReimbursementCaseCommentAdded',
        comment: {
          __typename: 'ActivityComment',
          text,
        },
      },
    };
  };

  return createdComment;
};
