import { Button } from '@candisio/design-system';
import {
  LinkedCardTransactionState,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';

interface FailedTransactionActionProps {
  props: {
    transactionState: LinkedCardTransactionState;
    cardId: string;
    cardholderId?: string;
    vendorName: string;
  };
}

export const FailedTransactionAction = ({
  props,
}: FailedTransactionActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const orgSlug = useOrganizationId();

  const pathname = `/${orgSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`;

  const type =
    props.transactionState === LinkedCardTransactionState.PurchaseFailed
      ? TransactionType.Purchase
      : TransactionType.StatusInquiry;

  const urlSearchParams = {
    cardRefNum: props.cardId,
    status: TransactionStatus.Declined,
    type,
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    navigate({
      pathname,
      search: qs.stringify(urlSearchParams),
    });
  };

  return (
    <Button
      variant="secondary"
      size="small"
      icon="newTab"
      iconPosition="right"
      onClick={(e: MouseEvent<HTMLButtonElement>) => handleClick(e)}>
      {t('recurringPaymentList.failedCTA')}
    </Button>
  );
};
