import { CellProps as ReactTableCellProps } from 'react-table';
import { TruncatedText } from '../..';
import { Text } from '../../../Atoms/Typography';

export type CellProps<
  // We are using an older version of typescript where the use of object type is not recommended and therefore we need the following line. See issue https://github.com/microsoft/TypeScript/issues/21732
  // eslint-disable-next-line @typescript-eslint/ban-types
  TTableData extends object,
  TCellValue = any,
> = ReactTableCellProps<TTableData, TCellValue | undefined>;

/** Default cell */
// We are using an older version of typescript where the use of object type is not recommended and therefore we need the following line. See issue https://github.com/microsoft/TypeScript/issues/21732
// eslint-disable-next-line @typescript-eslint/ban-types
export const Cell = <TTableData extends object>({
  value,
}: CellProps<TTableData>) => {
  if (value === undefined) {
    return <Text>-</Text>;
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return <TruncatedText>{String(value)}</TruncatedText>;
  }

  return <>{value}</>;
};
