import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ExpenseItemTotalAmountUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { mappedExpenseTypeToGeneralTitle } from 'views/Reimbursement/utils/getActivityTypeToReimbursementHistoryComponent';

export const ExpenseItemTotalAmountUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemTotalAmountUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const fallBackTitle = reimbursementItemMetadata?.type
    ? t(mappedExpenseTypeToGeneralTitle[reimbursementItemMetadata?.type])
    : undefined;

  return (
    <Grid>
      <Text>
        {t('timeline.expenseChanged', { title: expenseTitle ?? fallBackTitle })}
      </Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.label'
            )}
          </Text>
          <Paragraph>
            {!isNil(properties.newTotalAmount)
              ? amountFormat(
                  properties.newTotalAmount,
                  properties.newCurrency,
                  { convertToBasicMonetaryUnit: true }
                )
              : t('timeline.empty')}
          </Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
