import { useReimbursementItemsQuery } from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { mapToExpense } from '../utils/mapToExpense';

export const useGetReimbursementExpenseItems = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();

  const { data, loading: loadingItems } = useReimbursementItemsQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    fetchPolicy: 'network-only',
    skip: !reimbursementId || !canUseReimbursement,
  });

  const fetchedItems = (data?.reimbursementItems ?? []).filter(
    item => item.__typename
  );

  const mappedItems = fetchedItems.map(mapToExpense);

  return { mappedItems, loadingItems };
};
