import { DebounceSettings, debounce } from 'lodash';

/**
 * `_.debounce` returns an `undefined` on the first call of an async function
 * unless you call it with a { leading: true } option, but in that case named
 * debounced function would first be invoked instantly which may not be desired
 * in some cases (e.g. debounced server validation, async name duplicate check).
 *
 * In that case you could use this wrapper.
 */

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number,
  options: DebounceSettings = {}
) {
  const resolveSet = new Set<(p: any) => void>();
  const rejectSet = new Set<(p: any) => void>();

  const debounced = debounce(
    (args: Parameters<F>) => {
      func(...args)
        .then((...res) => {
          resolveSet.forEach(resolve => resolve(...res));
          resolveSet.clear();

          return res;
        })
        .catch((...res) => {
          rejectSet.forEach(reject => reject(...res));
          rejectSet.clear();
        });
    },
    wait,
    options
  );

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      resolveSet.add(resolve);
      rejectSet.add(reject);

      return debounced(args);
    }) as ReturnType<F>;
}
