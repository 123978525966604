import { Box } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { ReactNode, useMemo } from 'react';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ReimbursementError } from 'views/Reimbursement/toolkit/types';
import { ApprovedStatusFooterActionBox } from './ApprovedStatusFooterActionBox';
import { ApprovingStatusFooterActionBox } from './ApprovingStatusFooterActionBox';
import { FooterActions, FooterActionsProps } from './FooterActions';
import { RejectedStatusFooterActionBox } from './RejectedStatusFooterActionBox';
import { ReviewStatusFooterActionBox } from './ReviewStatusFooterActionBox';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useUserRoles } from 'hooks/useUserRoles';

export type FastApproveReturn = {
  status: string;
  reimbursementItemsErrors: ReimbursementError[];
  hasReimbursementCaseErrors: boolean;
};

interface FooterActionBoxProps extends FooterActionsProps {
  reimbursement?: Reimbursement;
  isSubmittingForReview: boolean;
  isFastApprovePending?: boolean;
  isRequestAmendmentPending?: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
}

export const FooterActionBox = ({
  reimbursement,
  getExpenses,
  onCycleReimbursments,
  onSubmitForReview,
  onFastApprove,
  onRequestAmendment,
  isRequestAmendmentPending = false,
  isFastApprovePending = false,
  isSubmittingForReview,
}: FooterActionBoxProps) => {
  const currentUser = useCurrentUser();
  const { isOnlyApprover } = useUserRoles();
  const { status, createdByMembership } = reimbursement ?? {};
  const createdById = createdByMembership?.id;
  const isReimbursementOwner = currentUser?.id === createdById;

  const footer: Partial<Record<ReimbursementCaseStatus, ReactNode>> = useMemo(
    () => ({
      DRAFT: (
        <FooterActions
          getExpenses={getExpenses}
          onCycleReimbursments={onCycleReimbursments}
          onSubmitForReview={onSubmitForReview}
          isSubmittingForReview={isSubmittingForReview}
        />
      ),
      CHECK: isReimbursementOwner ? (
        <FooterActions
          getExpenses={getExpenses}
          onCycleReimbursments={onCycleReimbursments}
          onSubmitForReview={onSubmitForReview}
          isSubmittingForReview={isSubmittingForReview}
        />
      ) : null,
      REVIEW: (
        <ReviewStatusFooterActionBox
          createdById={createdById}
          isFastApprovePending={isFastApprovePending}
          onRequestAmendment={onRequestAmendment}
          isRequestAmendmentPending={isRequestAmendmentPending}
          onFastApprove={onFastApprove}
          getExpenses={getExpenses}
        />
      ),
      APPROVING: (
        <ApprovingStatusFooterActionBox
          createdById={createdById}
          isFastApprovePending={isFastApprovePending}
          onFastApprove={onFastApprove}
          getExpenses={getExpenses}
        />
      ),
      APPROVED: !isOnlyApprover && <ApprovedStatusFooterActionBox />,
      REJECTED: <RejectedStatusFooterActionBox createdById={createdById} />,
    }),
    [
      createdById,
      getExpenses,
      isFastApprovePending,
      isOnlyApprover,
      isReimbursementOwner,
      isRequestAmendmentPending,
      isSubmittingForReview,
      onCycleReimbursments,
      onFastApprove,
      onRequestAmendment,
      onSubmitForReview,
    ]
  );

  if (!status || !footer[status]) return null;

  return (
    <Box paddingRight="space14" right={-2.5}>
      {footer[status]}
    </Box>
  );
};
