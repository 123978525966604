import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PurchaseOrdersTableData } from './types';

export const GoodsReceiptCountCell = ({
  value,
}: {
  value?: PurchaseOrdersTableData['goodsReceiptCount'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  if (!value?.total) {
    return <Text>{t('addPurchaseOrderModal.emptyCellPlaceholder')}</Text>;
  }

  const parts = [];
  if (value?.open && value.open > 0) {
    parts.push(
      t('columns.linkedGoodsReceiptCount.openGoodsReceiptCount', {
        openCount: value.open,
      })
    );
  }

  if (value?.closed && value.closed > 0) {
    parts.push(
      t('columns.linkedGoodsReceiptCount.closedGoodsReceiptCount', {
        closedCount: value.closed,
      })
    );
  }

  return (
    <TruncatedText wordBreak="break-word">{parts.join(', ')}</TruncatedText>
  );
};
