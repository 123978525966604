import { Box } from '../../Box';
import { Text } from '../../Typography';

export interface GroupHeaderProps {
  header?: string;
}

export const GroupHeader = ({ header }: GroupHeaderProps) => {
  return (
    <Box
      top={0}
      zIndex={1}
      background="gray0"
      padding="8.5px space16"
      position="sticky">
      <Text color="gray500" fontSize="basic">
        {header}
      </Text>
    </Box>
  );
};
