import { Grid, Flex, CustomEmptyStateProps } from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { TabView } from 'views/Inbox/models';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { availableFilters } from './consts';
import { GoodsReceiptsKebabMenu } from './GoodsReceiptsKebabMenu';
import { GoodsReceiptsEmptyState } from './GoodsReceiptsTable/EmptyState';
import { GoodsReceiptsTable } from './GoodsReceiptsTable/GoodsReceiptsTable';
import { useGetGoodsReceipts } from './hooks/useGetGoodsReceipts';
import { useGetGoodsReceiptsTableConfigs } from './hooks/useGetGoodsReceiptsTableConfigs';
import { useGetGoodsReceiptsTableFilterOptions } from './hooks/useGetTableFilterOptions';
import { GoodsReceiptsTableData } from './types';

export const GoodsReceipts = () => {
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const initialSearchValue = searchParams.get(queryParameter) ?? '';
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const [t] = useTranslation();

  const filterOptions = useGetGoodsReceiptsTableFilterOptions();
  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<GoodsReceiptsTableData>({
      availableFilters,
    });

  const { tableData, isLoading, onLoadMore, isTableEmpty } =
    useGetGoodsReceipts({
      searchValue,
      filters,
      sortBy,
    });

  const {
    visibleColumnIds,
    columnConfigurations,
    isSavingConfigurations,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetGoodsReceiptsTableConfigs({ filters, sortBy });

  const handleFilter = useCallback(
    (filterVal: Filters<GoodsReceiptsTableData>) => {
      onFilter(filterVal);
    },
    [onFilter]
  );

  const handleSort = useCallback(
    (sortVal: SortingRule<GoodsReceiptsTableData>[]) => {
      onSort(sortVal);
    },
    [onSort]
  );

  const handleResetSearch = () => {
    setSearchValue('');
    updateSearchParam(queryParameter, '');
  };

  const isTableFiltered = Boolean(filters.length);
  const isUsingSearchQuery = Boolean(searchValue);

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <GoodsReceiptsEmptyState
        isTableFiltered={isTableFiltered}
        isUsingSearchQuery={isUsingSearchQuery}
        resetFilters={resetFilters}
        resetSearch={handleResetSearch}
      />
    ) : null;

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_GOODS_RECEIPTS}>
      <Flex height="100%" overflow="hidden" direction="column">
        <Grid
          templateColumns="minmax(12rem, 24rem) 1fr"
          borderTopRadius="basic"
          overflow="hidden"
          alignItems="center"
          borderBottom="1px solid gray200"
          background="white"
          padding="space12">
          <SearchField
            clearTooltip={t('purchase-orders:search.clearTooltip')}
            placeholder={t('purchase-orders:goodsReceipts.search.placeholder')}
            value={searchValue}
            onChange={query => {
              setSearchValue(query);
              updateSearchParam(queryParameter, query);
            }}
            inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
          />
          <Flex justifySelf="end" gap="space8">
            <ConfigurationsMenu
              isLoading={isSavingConfigurations || isResetPending}
              configurationItems={columnConfigurations}
              onUpdateConfigurations={handleUpdateConfigurations}
              onResetConfigurations={handleResetTableConfigurations}
            />
            <SyncFromSap type={SAP_SYNC.GoodsReceipts} />
            <GoodsReceiptsKebabMenu />
          </Flex>
        </Grid>
        <Flex height="100%" overflow="hidden" direction="column">
          <GoodsReceiptsTable
            key={`archive-goods-receipts-table-${visibleColumnIds.join('-')}}`}
            columns={visibleColumnIds}
            data={tableData}
            filterOptions={filterOptions}
            onEndReached={onLoadMore}
            isLoading={isLoading}
            onFilter={handleFilter}
            onSort={handleSort}
            defaultFilters={filters}
            defaultSortBy={sortBy}
            customEmptyState={emptyState}
          />
        </Flex>
      </Flex>
    </ArchiveViewLayout>
  );
};
