import {
  FastApproveReimbursementCaseMutation,
  ReimbursementErrorType,
} from 'generated-types/graphql.types';

type ReimbursementItemsError =
  FastApproveReimbursementCaseMutation['fastApproveReimbursementCase']['errors']['reimbursementItemsErrors'];

const hasInvalidBookingError = (error: ReimbursementErrorType[]) =>
  error.includes(ReimbursementErrorType.InvalidBookings);

export const filterReimbursementItemErrors = (
  items: ReimbursementItemsError
) => {
  return (items ?? [])?.reduce<{
    bookingErrors: ReimbursementItemsError;
    itemErrorsWithoutBookings: ReimbursementItemsError;
  }>(
    (acc, item) => {
      const invalidBookingErrors = item.errors.filter(({ errors }) =>
        hasInvalidBookingError(errors)
      );

      const otherErrors = item.errors.filter(
        ({ errors }) => !hasInvalidBookingError(errors)
      );

      if (invalidBookingErrors.length > 0) {
        acc?.bookingErrors?.push({
          ...item,
          errors: invalidBookingErrors,
        });
      }

      if (otherErrors.length > 0) {
        acc?.itemErrorsWithoutBookings?.push({
          ...item,
          errors: otherErrors,
        });
      }

      return acc;
    },
    {
      bookingErrors: [],
      itemErrorsWithoutBookings: [],
    }
  );
};
