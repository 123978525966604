import { Maybe, Money, PurchaseOrder } from 'generated-types/graphql.types';

export type ThreeWayMatchBookingData = {
  bookingId: string;
  price: Money;
  quantity?: number;
  description: string;
};

export type ThreeWayMatchPurchaseOrderData = {
  bookingId: string;
  price?: Money | null;
  quantity: number;
  description: string;
  articleNumber: string;
};

export type ThreeWayMatchGoodsReceiptDetail = {
  receiptNumber: string;
  quantity: number;
}[];
export type ThreeWayMatchGoodsReceiptData = {
  bookingId: string;
  quantity: number;
  description: string;
  itemNumber: string;
  items?: ThreeWayMatchGoodsReceiptDetail;
};

export type ThreeWayMatchRowData = {
  id: string;
  articleNumber: string;
  description: string;
  purchaseOrderQuantity: number;
  goodsReceiptQuantity: number;
  invoiceQuantity: number;
  purchaseOrderAmount?: Money | null;
  invoiceAmount: Money;
  isAmountMatched: boolean;
  isQuantityMatched: boolean;
  goodsReceiptsDetails?: ThreeWayMatchGoodsReceiptDetail;
};

export type PurchaseOrders = Maybe<PurchaseOrder>[];
export enum matchState {
  success = 'success',
  warning = 'warning',
  default = 'default',
}
export type MatchState = keyof typeof matchState;
