import { Button } from '@candisio/design-system';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import { useTranslation } from 'react-i18next';
import { useDisabledAttachmentButtonLabelText } from 'views/Inbox/DocumentProcessing/util/useDisabledAttachmentButtonLabelText';

interface AttachFileFromDiskButtonProps {
  canAttachFiles: CanAddAttachments;
  isAttachingFiles: boolean;
  onUploadClick: () => void;
}

export const AttachFileFromDiskButton = ({
  canAttachFiles,
  isAttachingFiles,
  onUploadClick,
}: AttachFileFromDiskButtonProps) => {
  const [t] = useTranslation();

  const disabledLabelKey = useDisabledAttachmentButtonLabelText(
    canAttachFiles.fromDisk
  );

  return (
    <Button
      data-cy="attachment-upload-button"
      iconPosition="right"
      variant="tertiary"
      size="small"
      disabled={!canAttachFiles?.fromDisk}
      label={disabledLabelKey}
      loading={isAttachingFiles}
      onClick={() => {
        onUploadClick();
      }}>
      {t('document.attachments.attachPdf')}
    </Button>
  );
};
