import { mergeRefs, usePopover } from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useEffect, useState } from 'react';

export const ACTIVATION_LOCAL_STORAGE_KEY = 'archive-preview-activation-seen';

export const useDocumentPreviewActivationPopover = () => {
  const [archiveDocumentPreviewFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.archiveDocumentPreview,
  ]);

  const [activationSeen, setActivationSeen] = useLocalStorage(
    ACTIVATION_LOCAL_STORAGE_KEY,
    false
  );

  const [delayPassed, setDelayPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setDelayPassed(true), 1000);

    return () => clearTimeout(timer);
  }, []);

  const { ref, isIntersecting: isTriggerVisible } = useIsElementVisible();

  const shouldOpenActivationPopover =
    archiveDocumentPreviewFF &&
    !activationSeen &&
    isTriggerVisible &&
    delayPassed;

  const {
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps: _triggerProps,
    triggerRef,
    close,
  } = usePopover({
    placement: 'left',
    updatePlacementOnTriggerMovement: true,
    isOpen: shouldOpenActivationPopover,
  });

  // onPointerDown and onClick from trigger interfere with current props
  const { onPointerDown, onClick, ...triggerProps } = _triggerProps;

  const targetProps = archiveDocumentPreviewFF ? triggerProps : {};
  const targetRef = archiveDocumentPreviewFF
    ? mergeRefs([triggerRef, ref])
    : undefined;

  const handleClose = () => {
    close();
    setActivationSeen(true);
  };

  return {
    targetProps,
    targetRef,
    isOpen,
    popoverProps,
    popoverRef,
    close: handleClose,
  };
};
