import {
  Grid,
  Item,
  Skeleton,
  TabItem,
  TabPanel,
  Tabs,
  useTabListState,
} from '@candisio/design-system';
import { AriaTabListProps } from '@react-types/tabs';
import { useUserRoles } from 'hooks/useUserRoles';
import { AppRouteParams } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { useSap } from 'orgConfig/sap';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import { tabViewToUrlMap } from './utils';

interface ArchiveTabsProps {
  activeTab: keyof typeof TabView;
}

type TabConfigItem = TabItem & {
  isAvailable: boolean;
};

export const TabsContainer = (props: AriaTabListProps<TabItem>) => {
  const state = useTabListState(props);

  return (
    <Grid paddingLeft="space32">
      <Tabs {...props} state={state} />
      <TabPanel key={state.selectedItem?.key} state={state} />
    </Grid>
  );
};

export const ArchiveTabs = ({ activeTab }: ArchiveTabsProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();
  const creditCards = useCreditCardsSetup();
  const { isOnlyApprover } = useUserRoles();
  const { showEcm, showEcmContractManagement } = useEcm();
  const { shouldUseSapPurchaseOrder } = useSap();
  const { ft, hide } = useFeatureToggle();
  const { variant } = useReimbursementsPromotions();
  const { canUseReimbursement } = useReimbursement();
  const { isAdmin } = useUserRoles();

  // we want to prevent items from popping in/out of the view, so wait for all config to load
  const isConfigLoading = creditCards.isOnboardingStatusLoading;

  if (isConfigLoading) {
    return (
      <Grid paddingLeft="space32">
        <Skeleton height="46px" width="10rem" />
      </Grid>
    );
  }

  let reimbursementsTabIcon: TabItem = {
    iconColor: 'purple500',
    iconTooltip: t('tag.addOn'),
    icon: 'arrowUpCircleFilled',
  };

  if (variant === 'noPromotion') {
    reimbursementsTabIcon = { tag: t('tag.new') };
  }

  if (variant === 'promotionForEntitlements') {
    reimbursementsTabIcon = { tag: t('tag.soon') };
  }

  const tabsConfig: TabConfigItem[] = [
    {
      key: TabView.ARCHIVE_DOCUMENTS,
      title: t('archive.tabs.invoices'),
      isAvailable: true,
    },
    {
      key: TabView.ARCHIVE_PURCHASE_ORDERS,
      title: t('archive.tabs.purchaseOrders'),
      isAvailable: shouldUseSapPurchaseOrder && !isOnlyApprover,
    },
    {
      key: TabView.ARCHIVE_GOODS_RECEIPTS,
      title: t('archive.tabs.goodsReceipts'),
      isAvailable: shouldUseSapPurchaseOrder && !isOnlyApprover,
    },
    {
      key: TabView.ARCHIVE_TRANSACTIONS,
      title: t('archive.tabs.transactions'),
      isAvailable: !isConfigLoading,
      ...(creditCards.isAddonBought
        ? undefined
        : {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: t('tag.addOn'),
          }),
    },
    {
      key: TabView.ARCHIVE_ECM_CONTRACTS,
      title: t('archive.tabs.ecmContracts'),
      isAvailable: !hide(ft.CONTRACTS) && !isConfigLoading,
      ...(showEcmContractManagement
        ? undefined
        : {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: t('tag.addOn'),
          }),
    },
    {
      key: TabView.ARCHIVE_REIMBURSEMENTS,
      title: t('archive.tabs.reimbursements'),
      isAvailable: canUseReimbursement || isAdmin,
      ...reimbursementsTabIcon,
    },
    {
      key: TabView.ARCHIVE_ECM_DOCUMENTS,
      title: t('archive.tabs.ecmDocuments'),
      isAvailable: !isConfigLoading,
      ...(showEcmContractManagement
        ? undefined
        : showEcm
        ? undefined
        : {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: t('tag.addOn'),
          }),
    },
  ];

  const tabs: TabItem[] = tabsConfig.filter(item => item.isAvailable);

  const handleSelectionChange = (value: Key) => {
    if (typeof value === 'bigint' || typeof value === 'number') return;

    const tab = value as TabView;

    const url = tabViewToUrlMap[tab];

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${url}`,
      { organizationSlug }
    );

    navigate(pathname);
  };

  return (
    <TabsContainer
      items={tabs}
      defaultSelectedKey={activeTab}
      onSelectionChange={handleSelectionChange}>
      {item => (
        <Item key={item.key} title={item.title} textValue={item.title}>
          {item.children}
        </Item>
      )}
    </TabsContainer>
  );
};
