import { Row } from 'react-table';
import { SelectionHeader } from '../Cell/SelectionHeader';
import { BaseTableDataType } from '../Table';

interface SelectAllHeaderProps<TableDataType extends BaseTableDataType> {
  canBeSelected?: (entity: TableDataType) => boolean | undefined;
  flatRows: Row<TableDataType>[];
  onAllRowsSelected: (selectedRows: TableDataType[]) => void;
  selectedFlatRows: Row<TableDataType>[];
}

export const SelectAllHeader = <TableDataType extends BaseTableDataType>({
  canBeSelected,
  flatRows,
  onAllRowsSelected,
  selectedFlatRows,
}: SelectAllHeaderProps<TableDataType>) => {
  const rows = flatRows.map((row) => row.original);

  const selectableRows = canBeSelected
    ? rows.filter((row) => canBeSelected(row))
    : rows;

  return (
    <SelectionHeader
      onAllRowsSelected={onAllRowsSelected}
      selectedFlatRows={selectedFlatRows}
      selectableRows={selectableRows}
    />
  );
};
