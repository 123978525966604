import { Document, PaidDocumentState } from 'generated-types/graphql.types';
import { DeepPartial } from 'utility-types';

/** A document with PaymentState as PaidDocumentState */
type PaidDocument = Omit<Document, 'paymentState'> & {
  paymentState: PaidDocumentState;
};

export const isPaidDocumentState = (state: any): state is PaidDocumentState =>
  state && state.__typename === 'PaidDocumentState';

export const isPaidDocument = (
  doc: DeepPartial<Document>
): doc is PaidDocument => doc.paymentState?.__typename === 'PaidDocumentState';
