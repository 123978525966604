import { Grid } from '@candisio/design-system';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { TagsInfoPanel } from './TagsInfoPanel';

const DOCUMENT_TAGS_GRID_WIDTH = '50rem';

export const DocumentTagsPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { tagsPromotion } = usePromotionContent();

  return (
    <SettingsLayout
      title={t('documentTags.title')}
      width={DOCUMENT_TAGS_GRID_WIDTH}>
      <Grid maxHeight="23rem">
        <PromotionCard promotion={tagsPromotion} closable={false} />
      </Grid>
      <TagsInfoPanel withFeedback={false} />
    </SettingsLayout>
  );
};
