import { DocumentType } from 'generated-types/graphql.types';

export const DocumentTypesSortBy = {
  active: 'isVisible',
  name: 'name',
  documentCount: 'documentCount',
} as const;

export type DocumentTypesSortByValues =
  (typeof DocumentTypesSortBy)[keyof typeof DocumentTypesSortBy];

export const DEFAULT_SORT_BY = DocumentTypesSortBy.active;

export const DOCUMENT_TYPE_TRANSLATION_MAP = {
  [DocumentType.Contract]:
    'ecm:storageForm.fields.documentType.options.contract',
  [DocumentType.DeliveryNote]:
    'ecm:storageForm.fields.documentType.options.deliveryNote',
  [DocumentType.Invoice]: 'ecm:storageForm.fields.documentType.options.invoice',
  [DocumentType.Offer]: 'ecm:storageForm.fields.documentType.options.offer',
  [DocumentType.OrderConfirmation]:
    'ecm:storageForm.fields.documentType.options.orderConfirmation',
  [DocumentType.Other]: 'ecm:storageForm.fields.documentType.options.other',
  [DocumentType.PurchaseOrder]:
    'ecm:storageForm.fields.documentType.options.purchaseOrder',
  [DocumentType.AccountStatement]:
    'ecm:storageForm.fields.documentType.options.accountStatement',
  [DocumentType.FeeNotice]:
    'ecm:storageForm.fields.documentType.options.feeNotice',
  [DocumentType.Guarantee]:
    'ecm:storageForm.fields.documentType.options.guarantee',
  [DocumentType.Reminder]:
    'ecm:storageForm.fields.documentType.options.reminder',
  [DocumentType.RemittanceAdvise]:
    'ecm:storageForm.fields.documentType.options.remittanceAdvise',
  [DocumentType.ExpenseReimbursementItem]:
    'ecm:storageForm.fields.documentType.options.expense',
  [DocumentType.TravelReimbursementItem]:
    'ecm:storageForm.fields.documentType.options.travel',
} as const;
