import {
  DocumentBookingAssociation,
  GoodsLineItem,
  GoodsReceiptInfo,
  Maybe,
  PurchaseOrder,
  PurchaseOrderInfo,
  SapExpenseType,
} from 'generated-types/graphql.types';

import { BookingsFormPartialData } from 'views/DocumentDetails/BookingsFormContext';
import {
  PurchaseOrders,
  ThreeWayMatchBookingData,
  ThreeWayMatchGoodsReceiptData,
  ThreeWayMatchPurchaseOrderData,
} from './types';

interface PurchaseOrderInfoExtended extends PurchaseOrderInfo {
  bookingId: string;
}
interface GoodsReceiptInfoExtended extends GoodsReceiptInfo {
  bookingId: string;
}
type GoodsReceiptsByPO = Record<
  string,
  {
    totalQuantity: number;
    items: (GoodsReceiptInfoExtended | null | undefined)[];
  }
>;

interface GoodsLineItemExtended extends GoodsLineItem {
  orderNumber: string;
  purchaseOrderId: string;
}
type GoodsReceiptGoodsLineItemExtended = {
  goodsReceiptId: string;
  receiptNumber: string;
  description: string;
  quantity: number;
  itemNumber: string;
  linkedPurchaseOrderId: string;
  bookingId: string;
};

export const getBookingItemQuantity = (
  bookingId: string,
  bookingAssociations?: DocumentBookingAssociation[]
) => {
  const bookingAssoc = bookingAssociations?.find(
    association => association?.bookingId === bookingId
  );

  if (bookingAssoc?.additionalExpenseInfo?.expenseType) {
    return undefined;
  }

  return bookingAssoc?.quantity ?? 1;
};

const groupGoodsReceiptsByBookingId = (
  goodsReceiptsInfo: (GoodsReceiptInfoExtended | null | undefined)[]
): GoodsReceiptsByPO => {
  return goodsReceiptsInfo.reduce(
    (acc, grInfo) => {
      const poId = grInfo?.bookingId;
      if (poId) {
        acc[poId] = acc[poId] || { totalQuantity: 0, items: [] };
        acc[poId].totalQuantity += grInfo?.quantity ?? 0;
        acc[poId].items.push(grInfo);
      }

      return acc;
    },
    {} as Record<
      string,
      {
        totalQuantity: number;
        items: (GoodsReceiptInfoExtended | null | undefined)[];
      }
    >
  );
};

export const getPurchaseOrdersData = (
  purchaseOrders: PurchaseOrders,
  purchaseOrdersInfo: (PurchaseOrderInfoExtended | null | undefined)[]
): ThreeWayMatchPurchaseOrderData[] => {
  const purchaseOrdersGoodsLineItems = purchaseOrders.reduce(
    (acc: GoodsLineItemExtended[], po: Maybe<PurchaseOrder>) => {
      const goodsLineItems = (po?.goodsLineItems ?? [])?.map(gli => ({
        purchaseOrderId: po?._id ?? '',
        ...gli,
        orderNumber: po?.orderNumber ?? '',
      }));

      return [...acc, ...goodsLineItems];
    },
    [] as GoodsLineItemExtended[]
  );

  return (purchaseOrdersInfo ?? []).map(poInfo => {
    const _purchaseOrderData = (purchaseOrdersGoodsLineItems ?? []).find(
      po => po.itemNumber === poInfo?.articleId
    );

    const { description, itemNumber } = _purchaseOrderData ?? {};

    return {
      bookingId: poInfo?.bookingId ?? '',
      price: _purchaseOrderData?.unitPrice,
      quantity: poInfo?.quantity ?? 0,
      description: description ?? '',
      articleNumber: itemNumber ?? '',
    };
  });
};

export const getGoodsReceiptsData = (
  purchaseOrders: PurchaseOrders,
  goodsReceiptsInfo: (GoodsReceiptInfoExtended | null | undefined)[]
): ThreeWayMatchGoodsReceiptData[] => {
  const goodsReceiptsByBookingId =
    groupGoodsReceiptsByBookingId(goodsReceiptsInfo);

  const goodsReceiptsFromPurchaseOrdersArray = purchaseOrders.flatMap(
    po => po?.goodsReceipts ?? []
  );

  // Enrich goods receipts with line items
  const enrichedGoodsReceipts: GoodsReceiptGoodsLineItemExtended[] =
    goodsReceiptsFromPurchaseOrdersArray.flatMap(gr =>
      (gr?.goodsLineItems ?? []).map(gli => {
        // Find corresponding goods receipt info
        const matchingGoodsReceiptInfo = goodsReceiptsInfo.find(
          receipt =>
            receipt?.goodsReceiptId === gr?._id &&
            receipt?.linkedPurchaseOrderId
        );

        return {
          goodsReceiptId: gr?._id ?? '',
          receiptNumber: gr?.receiptNumber ?? '',
          description: gli.description,
          quantity: gli.quantity,
          itemNumber: gli.itemNumber,
          linkedPurchaseOrderId:
            matchingGoodsReceiptInfo?.linkedPurchaseOrderId ?? '',
          bookingId: matchingGoodsReceiptInfo?.bookingId ?? '',
        };
      })
    );

  return Object.entries(goodsReceiptsByBookingId).map(
    ([bookingId, { totalQuantity, items }]) => {
      return {
        bookingId,
        quantity: totalQuantity,
        description: '', // not used in ui no need to get it
        itemNumber: '',
        items: items.map(item => ({
          receiptNumber:
            enrichedGoodsReceipts.find(
              gr => gr.goodsReceiptId === item?.goodsReceiptId
            )?.receiptNumber ?? '',
          quantity: item?.quantity ?? 0,
        })),
      };
    }
  );
};

const isBookingAdditionalExpenseOnProcessingForm = (
  booking: BookingsFormPartialData[number]
) => {
  const temproryBookingIdsAsAdditionalExpense = [
    SapExpenseType.Freight,
    SapExpenseType.Packaging,
  ];

  return temproryBookingIdsAsAdditionalExpense.includes(
    booking.bookingId as SapExpenseType
  );
};

export const getBookingsData = (
  bookings?: BookingsFormPartialData,
  bookingAssociations?: DocumentBookingAssociation[]
): ThreeWayMatchBookingData[] => {
  return (bookings ?? []).map(booking => {
    const bookingAssoc = bookingAssociations?.find(
      association => association?.bookingId === booking.bookingId
    );

    let quantity;

    if (
      isBookingAdditionalExpenseOnProcessingForm(booking) ||
      bookingAssoc?.additionalExpenseInfo?.expenseType // UI displays empty for additional expense even actual value is 1
    ) {
      quantity = undefined;
    } else if (!booking.quantity) {
      quantity = getBookingItemQuantity(booking.bookingId, bookingAssociations);
    } else {
      quantity = booking.quantity;
    }

    return {
      ...booking,
      quantity,
    };
  });
};
