import { CSSObject } from '@emotion/react';
import React from 'react';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box } from '../Box';
import { Icon } from '../Icon';

export interface StepperProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  steps: string[];
  currentIndex: number;
  showCompletedSteps?: boolean;
}

/**
 * Stepper component to display user progress
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/story/atoms-feedback-stepper}
 */
export const Stepper = React.forwardRef<HTMLDivElement, StepperProps>(
  ({ steps, currentIndex, showCompletedSteps, ...restProps }, ref) => {
    const { colors, fontWeights, lineHeights, space } = useTheme();

    const wrapperStyle: CSSObject = {
      padding: `0 ${space.space32}`,
      margin: `${space.space64} 0`,
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&::before': {
        content: '""',
        display: 'block',
        backgroundColor: colors.gray300,
        position: 'absolute',
        width: `calc(100% - ${space.space64})`,
        height: space.space3,
      },
    };

    const progressStyle: CSSObject = {
      backgroundColor: colors.gray700,
      position: 'absolute',
      height: space.space3,
      left: space.space32,
      transition: 'width 1s ease-in-out',
    };

    const listStyle: CSSObject = {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: space.space128,
    };

    const itemStyle: CSSObject = {
      width: space.space32,
      height: space.space32,
      display: 'inline-flex',
      borderRadius: '50%',
      backgroundColor: colors.gray300,
      color: colors.gray700,
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: fontWeights.semibold,
      zIndex: 1,
    };

    const itemCompleteStyle = {
      backgroundColor: colors.gray700,
      color: colors.gray0,
    };

    const itemActiveStyle = {
      backgroundColor: colors.blue500,
      color: colors.gray0,
    };

    const labelStyle: CSSObject = {
      position: 'absolute',
      top: `-${space.space32}`,
      lineHeight: lineHeights.paragraph,
      color: colors.gray700,
      fontWeight: fontWeights.semibold,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: space.space24,
      width: space.space128,
    };

    const indexHasError = currentIndex >= steps.length - 1;

    const currentProgress = indexHasError
      ? 100
      : (100 / (steps.length - 1)) * currentIndex;

    const progressWidth =
      indexHasError || showCompletedSteps
        ? `calc(100% - ${space.space64})`
        : `calc(${currentProgress}% - ${space.space32})`;

    return (
      <Box {...restProps} as="div" ref={ref} textAlign="center">
        <div css={wrapperStyle}>
          <div
            css={[
              progressStyle,
              {
                width: progressWidth,
              },
            ]}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={currentIndex === 0 ? 0 : currentProgress}
            role="progressbar"
          />
          <ul css={listStyle}>
            {steps.map((step, index) => {
              let isComplete = currentIndex > index;
              let isActive = currentIndex === index;
              const isLastStep = indexHasError && index === steps.length - 1;

              if (isLastStep) {
                isActive = true;
                isComplete = false;
              }

              return (
                <li
                  key={index}
                  css={[
                    itemStyle,
                    (isComplete || showCompletedSteps) && itemCompleteStyle,
                    isActive && itemActiveStyle,
                  ]}>
                  <span
                    css={labelStyle}
                    aria-current={isActive ? 'step' : 'false'}>
                    {step}
                  </span>
                  {isComplete || showCompletedSteps ? (
                    <Icon icon="check" />
                  ) : (
                    (index + 1).toString()
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </Box>
    );
  }
);
