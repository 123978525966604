import blogThumbnail1 from './media/blog1.png';
import blogThumbnail2 from './media/blog2.png';
import blogThumbnail3 from './media/blog3.png';

export const storageKey = 'expandable-cc-informational';

export const expandedState = {
  visible: { height: 'auto', transition: { duration: 0.5 }, opacity: 1 },
  hidden: { height: 0, opacity: 0 },
  onExit: { height: 0, transition: { duration: 0.5 }, opacity: 0 },
};

export const displayText = [
  {
    text: 'ccInformationalCard.listItem1',
  },
  {
    text: 'ccInformationalCard.listItem2',
  },
  {
    text: 'ccInformationalCard.listItem3',
  },
  {
    text: 'ccInformationalCard.listItem4',
  },
  {
    text: 'ccInformationalCard.listItem5',
  },
];

export const blogArticleManifest = [
  {
    blogLink: 'ccInformationalCard.creditCardInfoModal.blogLink1',
    thumbnail: blogThumbnail1,
  },
  {
    blogLink: 'ccInformationalCard.creditCardInfoModal.blogLink2',
    thumbnail: blogThumbnail2,
  },
  {
    blogLink: 'ccInformationalCard.creditCardInfoModal.blogLink3',
    thumbnail: blogThumbnail3,
  },
];
