import { Box, Flex, Grid } from '@candisio/design-system';
import { WidgetFormContainer } from 'components/Form/WidgetFormContainer';
import dashboardBannerQRCode from 'components/MobileAppPromotionBanner/media/dashboard-banner-QR-code.jpg';
import { MobileAppPromotionBannerWithOptions } from 'components/MobileAppPromotionBanner/MobileAppPromotionBannerWithOptions';
import { CreditCardsSection } from 'containers/credit-cards/components/CreditCardsSection';
import {
  OrganizationsInsightsDetails,
  useGetOrganizationsInsightsDetailsQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
// Use v5 version of useParams because we’re inside a v5 route
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { calculateTotalTaskAndOverdue } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/components/DataTable/utils';
import { InsightsWidgets } from '../InsightsWidgets/InsightsWidgets';
import { ContractsAdvertCard } from './components/ContractsAdvert/ContractsAdvertCard';
import { HeaderGreeter } from './components/HeaderGreeter';
import { OrganizationTodosPanel } from './components/OrganizationTodosPanel';
import { RecurringPaymentsContainer } from './components/RecurringPayments/RecurringPaymentsContainter';

export type DashboardProps = {
  onOpenWidgetModal: () => void;
  onCloseWidgetModal: () => void;
};

export const DashboardComponent = ({
  onOpenWidgetModal,
  onCloseWidgetModal,
}: DashboardProps) => {
  const history = useHistory();
  const user = useCurrentUser();
  const creditCardsSetup = useCreditCardsSetup();
  const selectedOrganization = useFullOrganization();
  const { isAdmin, isCardManager } = useUserRoles();
  const { showEcmContractManagement } = useEcm();

  const shouldShowRecurringPayments =
    creditCardsSetup.showAllExtraFeatures && (isAdmin || isCardManager);

  const organizationId = selectedOrganization?.id;
  const { data, loading } = useGetOrganizationsInsightsDetailsQuery({
    fetchPolicy: 'no-cache',
    skip: !organizationId,
    variables: organizationId ? { ids: [organizationId] } : undefined,
  });

  const organizationList = (data?.getOrganizationsInsightsDetails ||
    []) as OrganizationsInsightsDetails[];

  const selectedOrganizationData = organizationList?.find(
    ({ organization }) => organization?.slug === organizationId
  );

  const transformedOrganizationData = calculateTotalTaskAndOverdue(
    selectedOrganizationData
  );

  const { membershipId, taskBreakdown, slug } = transformedOrganizationData;

  const dispatch = useDocumentUploadDispatch();

  const visible = history.location.pathname.includes(Routes.DASHBOARD_WIDGET);

  const handleClickUploadDocument = () => {
    dispatch('showModal');
  };

  const showContractsAdvert =
    !showEcmContractManagement && !creditCardsSetup.isAddonBought;

  const showCreditCardSection =
    creditCardsSetup.showAllExtraFeatures || !creditCardsSetup.isAddonBought;

  return (
    <Box
      data-cy="dashboard-view"
      height="100vh"
      padding="0 space16 space16 space16"
      overflow="auto">
      <HeaderGreeter firstName={user?.firstName} />
      <Grid
        gap="space14"
        paddingRight="space32"
        paddingLeft="space16"
        columns={2}
        alignItems="start">
        <Box>
          <Flex direction="column" gap="space24">
            <Flex direction="column" gap="space2">
              <OrganizationTodosPanel
                loading={loading}
                organizationId={slug}
                membershipId={membershipId}
                taskBreakdown={taskBreakdown}
                onClickTask={(path: string) => history.push(path)}
                onClickUploadDocument={handleClickUploadDocument}
              />
              {shouldShowRecurringPayments && <RecurringPaymentsContainer />}
            </Flex>

            <Flex direction="column" gap="space2">
              <MobileAppPromotionBannerWithOptions
                qrCode={dashboardBannerQRCode}
                creditCardSetup={creditCardsSetup.isInUse}
                localStorageKey="mobile_app_dashboard_promotion_banner"
                trackingContext="dashboard"
              />
            </Flex>
            {showContractsAdvert ? (
              <ContractsAdvertCard />
            ) : (
              showCreditCardSection && <CreditCardsSection />
            )}
          </Flex>
        </Box>
        <InsightsWidgets onOpenWidgetModal={onOpenWidgetModal} />
      </Grid>
      <WidgetFormContainer
        visible={visible}
        onCloseWidgetModal={onCloseWidgetModal}
      />
    </Box>
  );
};
