import {
  HookFormTextField,
  HookFormTextFieldProps,
} from 'components/HookFormFields/HookFormTextField';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { POSTING_TEXT_FIELD_MAX_LENGTH } from 'views/consts';

interface VatRateFieldProps<TFormValues extends FieldValues>
  extends HookFormTextFieldProps<TFormValues> {
  name: Path<TFormValues>;
  currentValueLength?: number;
}

export const PostingTextField = <TFormValues extends FieldValues>({
  name,
  currentValueLength,
  ...props
}: VatRateFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { client } = useDatev();
  const maxLength = client ? POSTING_TEXT_FIELD_MAX_LENGTH : Infinity;

  return (
    <HookFormTextField
      name={name}
      placeholder={t('splitBookings.postingText.placeholder.enabled')}
      description={
        (currentValueLength ?? 0) > maxLength
          ? {
              text: t('splitBookings.postingText.maxLengthWarning', {
                maxLength,
              }),
              color: 'warning',
            }
          : undefined
      }
      ignorePasswordManager={false}
      {...props}
    />
  );
};
