import { Box, Flex, defaultTheme } from '@candisio/design-system';
import { PlayButton } from 'containers/Entitlements/components/Upsell/components/PlayButton';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const navatticIdsMap = {
  CONTRACTS: 'promo.contractTable.navattic.id',
  ECM: 'promo.ecm.navattic.id',
} as const;

const teaserUrlMap = {
  CONTRACTS: 'promo.contractTable.navattic.url',
  ECM: 'promo.ecm.navattic.url',
} as const;

const teaserTitleMap = {
  CONTRACTS: 'promo.contractTable.navattic.title',
  ECM: 'promo.ecm.navattic.title',
} as const;

interface TeaserBaseProps {
  children: ReactElement;
  withFading?: boolean;
  withFilter?: boolean;
  alternative?: boolean;
}

interface TeaserProps extends TeaserBaseProps {
  feature: FEATURE.ECM | FEATURE.CONTRACTS;
}

interface VideoTourProps extends TeaserBaseProps {
  videoTourUrl: string;
  videoTourTitle: string;
}

type VideoCardProps = TeaserProps | VideoTourProps;

export const VideoCard = ({
  children,
  withFading = false,
  withFilter = false,
  alternative = false,
  ...props
}: VideoCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const isPromo = 'feature' in props;

  const color = isPromo
    ? defaultTheme.colors.purple800
    : defaultTheme.colors.gray450;

  const navatticId = isPromo ? t(navatticIdsMap[props.feature]) : '';

  const videoUrl = isPromo
    ? t(teaserUrlMap[props.feature])
    : props.videoTourUrl;

  const videoTitle = isPromo
    ? t(teaserTitleMap[props.feature])
    : props.videoTourTitle;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{
        maskImage: withFading
          ? 'linear-gradient(to top, transparent 5%, #000000 15%)'
          : undefined,
      }}>
      {children}
      {withFilter && (
        <Box
          background={`linear-gradient(to bottom, ${color}19, #0000004c)`}
          opacity=".8"
          borderRadius="medium"
          height="100%"
          width="100%"
          position="absolute"
          top="0"
          left="0"
        />
      )}
      <PlayButton
        navatticId={navatticId}
        title={videoTitle}
        href={videoUrl}
        promo={isPromo}
        alternative={alternative}
      />
    </Flex>
  );
};
