import { AmountCell } from 'components/Table/Cells/Amount';
import { CellProps } from 'react-table';
import { amountFormat } from 'utils/format';
import { Amount, DocumentsTableData } from '../types';

type Value = Amount | undefined;

export const AmountCellDocument = ({
  value,
}: CellProps<DocumentsTableData, Value>) => {
  const formattedAmount = amountFormat(value?.amount, value?.currency, {
    convertToBasicMonetaryUnit: value?.isBasicMonetaryUnit ?? false,
  });

  return <AmountCell formattedAmount={formattedAmount} />;
};
