import { useQuery } from '@apollo/client';
import {
  ReimbursementCaseFiltersInput,
  ReimbursementCaseSortInput,
  ReimbursementsCursorBasedNavigationQuery,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { availabeFiltersAndSorts } from 'views/Inbox/Reimbursements/toolkit/utils';
import { reimbursementsCursorBasedNavigationQuery } from '../toolkit/queries';
import { mapToReimbursementsFilterInput } from '../utils/mapToReimbursementsFilterInput';
import { mapToReimbursementSortInput } from '../utils/mapToReimbursementSortInput';

const QUERY_PARAM = 'query';
interface ReimbursementsCursorBasedNavigationDataProps {
  skip?: boolean;
  cursor?: string | null;
  filters?: ReimbursementCaseFiltersInput;
  sortBy?: ReimbursementCaseSortInput;
}

export const useReimbursementsCursorBasedNavigationData = ({
  skip,
  cursor,
  filters: defaultFilters,
  sortBy: defaultSortBy,
}: ReimbursementsCursorBasedNavigationDataProps = {}) => {
  const { canUseReimbursement } = useReimbursement();
  const { searchParams } = useMutateSearchParams();
  const searchQuery = searchParams.get(QUERY_PARAM);

  const { sortBy, filters } = useUrlBasedSortAndFilter({
    availableFilters: availabeFiltersAndSorts,
  });

  const hasFilters = Boolean(filters.length || defaultFilters);

  const { data, loading } = useQuery<ReimbursementsCursorBasedNavigationQuery>(
    reimbursementsCursorBasedNavigationQuery,
    {
      variables: {
        cursor,
        filters: hasFilters
          ? mapToReimbursementsFilterInput(filters, defaultFilters)
          : undefined,
        sortBy: sortBy.length
          ? mapToReimbursementSortInput(sortBy)
          : defaultSortBy,
        queries: !!searchQuery
          ? {
              query: searchQuery,
            }
          : undefined,
      },
      skip: !canUseReimbursement || skip,
      fetchPolicy: 'no-cache',
    }
  );

  const prevEdges = data?.prevReimbursement;
  const nextEdges = data?.nextReimbursement;
  const pageInfo = data?.nextReimbursement?.pageInfo;

  return {
    isLoadingNavigationData: loading,
    nextReimbursementEdge: nextEdges?.edges?.[0],
    prevReimbursementEdge: prevEdges?.edges?.[0],
    reimbursementCount: pageInfo?.totalCount ?? 0,
  };
};
