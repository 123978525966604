import { IconProps } from '@candisio/design-system';
import { GoodsReceiptImportHistoryEntryStatus } from 'generated-types/graphql.types';

export const mapStatusToIcon: Record<
  GoodsReceiptImportHistoryEntryStatus,
  Pick<IconProps, 'icon' | 'color'>
> = {
  SUCCESS: {
    icon: 'checkCircle',
    color: 'green700',
  },
  FAILED: {
    icon: 'failCircle',
    color: 'red700',
  },
  PARTIALLY_SUCCESS: {
    icon: 'warning',
    color: 'yellow500',
  },
  IN_PROCESS: {
    icon: 'warning',
    color: 'yellow500',
  },
};
