import { CSSObject } from '@emotion/react';
import React from 'react';
import {
  mergeProps,
  OverlayContainer,
  PopoverAria,
  useTooltip,
} from 'react-aria';
import { TooltipTriggerState } from 'react-stately';
import { useTheme } from '../../Theme';
import { LayoutProps, PaddingProps, StandardHTMLAttributes } from '../../types';
import { useArrowCss } from '../../utils/useArrowCss';
import { Box } from '../Box';

export interface TooltipProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement>,
    PaddingProps {
  arrowStyle?: React.CSSProperties;
  isOpen?: boolean;
  placementAxis?: PopoverAria['placement'];
  state?: TooltipTriggerState;
}

/**
 * Tooltip displays information about an element.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/story/atoms-feedback-tooltip}
 *
 * @param {React.CSSProperties} arrowStyle Custom CSS for arrow
 * @param {boolean} isOpen Open state
 * @param {PlacementAxis} placementAxis Position
 */
export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      arrowStyle,
      children,
      isOpen,
      placementAxis,
      state,
      maxWidth = '60ch',
      ...restProps
    },
    ref
  ) => {
    const { tooltipProps } = useTooltip({ isOpen }, state);

    const combinedProps = mergeProps(tooltipProps, restProps);
    // to achieve stacking order from top to bottom: Tooltip > ListBoxPopup > Popover
    if (combinedProps.style) {
      combinedProps.style = { ...combinedProps.style, zIndex: 100002 };
    }

    const { shadows } = useTheme();

    const baseCss: CSSObject = {
      filter: `drop-shadow(${shadows.elevatedShadow5})`,
      overflowWrap: 'break-word',
    };

    const arrowCss = useArrowCss({
      placementAxis,
      arrowStyle,
    });

    return (
      <OverlayContainer>
        <Box
          css={[baseCss, arrowCss]}
          background="gray0"
          borderRadius="medium"
          color="gray800"
          fontSize="basic"
          paddingX="space16"
          paddingY="space12"
          maxWidth={maxWidth}
          {...combinedProps}
          ref={ref}>
          {children}
        </Box>
      </OverlayContainer>
    );
  }
);
