import {
  Cell,
  Filter,
  FilterOptionsAccessor,
  Flex,
  Table,
  TableProps,
} from '@candisio/design-system';
import { ExportStatusCell } from 'components/DocumentsTable/Cells/ExportStatusCell/ExportStatusCell';
import { DateRangeFilter } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Column, TableState } from 'react-table';
import { useExportContext } from 'views/Integrations/Export/Context';
import { AmountCellReimbursements } from 'views/Reimbursement/elements/Table/Cells/AmountCellReimbursements';
import { DateCell } from 'views/Reimbursement/elements/Table/Cells/DateCell';
import { MemberCell } from 'views/Reimbursement/elements/Table/Cells/MemberCell';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { ExportMethodCell } from './ExportMethodCell';
import { Header } from './Header';
import { TypeCell } from './TypeCell';
import {
  ExportReimbursementItemsTableColumnKeys,
  ExportReimbursementItemsTableColumns,
  ExportReimbursementItemsTableRow,
} from './types';

export interface ExportReimbursementItemsTableProps {
  data: ExportReimbursementItemsTableRow[];
  columnIds?: ExportReimbursementItemsTableColumnKeys[];
  isLoading?: boolean;
  filterOptions?: FilterOptionsAccessor<ExportReimbursementItemsTableRow>;
  borderTopRadius?: TableProps<ExportReimbursementItemsTableRow>['borderTopRadius'];
  borderBottomRadius?: TableProps<ExportReimbursementItemsTableRow>['borderBottomRadius'];
  height?: TableProps<ExportReimbursementItemsTableRow>['height'];
  defaultFilters?: TableState<ExportReimbursementItemsTableRow>['filters'];
  defaultSortBy?: TableState<ExportReimbursementItemsTableRow>['sortBy'];
  customEmptyState?: TableProps<ExportReimbursementItemsTableRow>['customEmptyState'];
  onFilter?: TableProps<ExportReimbursementItemsTableRow>['onFilter'];
  onSort?: TableProps<ExportReimbursementItemsTableRow>['onSort'];
}

const expenseTableColumns: ExportReimbursementItemsTableColumns = {
  expenseId: {
    accessor: 'expenseId',
    disableFilters: true,
    disableSortBy: true,
  },
  grossAmount: {
    accessor: 'grossAmount',
    Cell: AmountCellReimbursements,
    disableFilters: true,
  },
  reasonExpense: {
    accessor: 'reasonExpense',
    disableFilters: true,
  },
  submittedAt: {
    accessor: 'submittedAt',
    Cell: DateCell,
  },
  targetedMembership: {
    accessor: 'targetedMembership',
    disableFilters: true,
    disableSortBy: true,
    Cell: MemberCell,
  },
  titleReimbursement: {
    accessor: 'titleReimbursement',
    disableFilters: true,
  },
  type: {
    accessor: 'type',
    Cell: TypeCell,
  },
  exportStatus: {
    accessor: 'exportStatus',
    /** @ts-expect-error TODO: React upgrade props types mismatch */
    Cell: ExportStatusCell,
    disableSortBy: true,
    disableFilters: true,
  },
  exportMethod: {
    accessor: 'exportMethod',
    Cell: ExportMethodCell,
    disableSortBy: true,
    disableFilters: true,
  },
};

export const ExportReimbursementItemsTable = ({
  data,
  columnIds,
  isLoading,
  filterOptions,
  borderBottomRadius = '0',
  borderTopRadius = '0',
  defaultFilters = [],
  defaultSortBy = [],
  height,
  onFilter,
  customEmptyState,
  onSort,
}: ExportReimbursementItemsTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  const orgSlug = useOrganizationId();
  const { searchParams } = useMutateSearchParams();

  const navigate = useNavigate();

  const handleClick = (reimbursementColletionId?: string) => {
    if (!reimbursementColletionId) {
      return;
    }

    searchParams.set(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);

    navigate(
      {
        pathname: `/${orgSlug}${Routes.ARCHIVE}${Routes.REIMBURSEMENTS}/${reimbursementColletionId}`,
        search: searchParams.toString(),
      },
      {
        state: {
          from: `/${orgSlug}${Routes.EXPORTS}`,
          search: searchParams.toString(),
        },
      }
    );
  };

  const defaultColumn = useMemo(
    (): Partial<Column<ExportReimbursementItemsTableRow>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[
                column.id as keyof ExportReimbursementItemsTableRow
              ];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              const isLoading = filterOption.isLoading;

              return (
                <Filter<ExportReimbursementItemsTableRow>
                  column={column}
                  options={filterOption.data}
                  isLoading={isLoading}
                  applyFilterButton={t('filterApply')}
                  resetFilterButton={t('filterReset')}
                  searchFieldPlaceholder={t('filterSearchPlaceholder')}
                  filterLabel={t('filterLabel')}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            if (filterOption === true) {
              return (
                <DateRangeFilter
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
    }),
    [filterOptions, t]
  );

  const { tableId } = useExportContext();

  const columns = useMemo(() => {
    if (!columnIds?.length) {
      return Object.values(expenseTableColumns);
    }

    const visibleColumns = columnIds.map(column => expenseTableColumns[column]);

    return visibleColumns;
  }, [columnIds]);

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      overflow="hidden"
      height="100%">
      <Flex direction="column" overflow="hidden" height="100%">
        <Table
          isLoading={isLoading}
          height={height}
          defaultColumn={defaultColumn}
          columns={columns}
          borderTopRadius={borderTopRadius}
          borderBottomRadius={borderBottomRadius}
          customEmptyState={customEmptyState}
          onFilter={onFilter}
          onSort={onSort}
          onRowClick={({ original }) =>
            handleClick(original.reimbursementColletionId)
          }
          data={data}
          initialState={{
            filters: defaultFilters,
            sortBy: defaultSortBy,
          }}
          key={`${tableId}-${columns.join('-')}`}
        />
      </Flex>
    </Flex>
  );
};
