import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import { useGetDocumentTransactionQuery } from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useMemo } from 'react';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';

export const useDocumentTransactions = (documentId: string) => {
  const { showDocumentRelations } = useEcm();

  const { isInUse } = useCreditCardsSetup();

  const isEcmDocument = isEcmDocumentId(documentId);

  const {
    data: documentTransactionsData,
    loading: isLoading,
    error,
  } = useGetDocumentTransactionQuery({
    variables: { id: documentId },
    skip: isEcmDocument || !isInUse || !showDocumentRelations || !documentId,
  });

  const hasError = !!error;

  const { transactions = [] } = documentTransactionsData?.getDocument ?? {};

  const transaction = useMemo(() => {
    const [transaction] = transactions ?? [];

    return dataToTransactionDetails(transaction);
  }, [transactions]);

  return {
    isLoading,
    hasError,
    transaction,
  };
};
