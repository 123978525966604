import { useToastMessage } from 'components/Toast/useToastMessage';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { useExpenseFormsHelpers } from '../components/MiddleSection/utils/useExpenseFormsHelpers';
import { REIMBURSEMENT_URL_PARAM, VIEW_PARAM_VALUE } from '../Reimbursement';
import { ExpenseSchema } from '../toolkit/expensesFormSchema';
import { useCreateGeneralExpense } from './useCreateGeneralExpense';
import { useCreateHospitalityExpense } from './useCreateHospitalityExpense';

interface UseReimbursementMenuButtonActionsProps {
  formHelpers?: ReturnType<typeof useExpenseFormsHelpers>;
}

export const useReimbursementMenuButtonActions = ({
  formHelpers,
}: UseReimbursementMenuButtonActionsProps) => {
  const { t } = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { updateSearchParam } = useMutateSearchParams();

  const { error } = useToastMessage();
  const { createHospitality, createHospitalityPending } =
    useCreateHospitalityExpense();

  const { createGeneralExpense, isCreateGeneralExpensePending } =
    useCreateGeneralExpense();

  const { onAppendForm } = formHelpers ?? {};

  const handleCreateHospitalityExpense = useCallback(async () => {
    const expenseId = await createHospitality();

    if (!expenseId) {
      return;
    }

    const defaultValues: ExpenseSchema = {
      expenseId,
      reason: '',
      dayOfExpense: '',
      expenseType: 'hospitality',
      totalAmount: null,
      receiptAmount: null,
      location: '',
      tipAmount: null,
      internalGuests: null,
      externalGuests: null,
      files: [],
    };

    onAppendForm?.(defaultValues, { shouldFocus: false });
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(expenseId);
  }, [createHospitality, onAppendForm, updateSearchParam]);

  const handleCreateGeneralExpense = useCallback(async () => {
    const { status, generalExpenseId } = await createGeneralExpense(
      reimbursementId
    );

    if (status === 'success' && generalExpenseId) {
      const defaultValues: ExpenseSchema = {
        expenseDate: '',
        expenseId: generalExpenseId,
        files: [],
        expenseType: 'general',
        reason: '',
        totalAmount: null,
      };

      onAppendForm?.(defaultValues, { shouldFocus: false });
      updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
      scrollToTarget(generalExpenseId);
    } else {
      error(t('reimbursementView.createErr'));
    }
  }, [
    createGeneralExpense,
    error,
    onAppendForm,
    reimbursementId,
    t,
    updateSearchParam,
  ]);

  const isCreatingExpenseItem =
    createHospitalityPending || isCreateGeneralExpensePending;

  return {
    handleCreateHospitalityExpense,
    handleCreateGeneralExpense,
    isCreatingExpenseItem,
  };
};
