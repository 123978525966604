import {
  Query,
  ReimbursementCaseFiltersInput,
  ReimbursementCaseSortInput,
  CompletedReimbursementsCursorBasedQueryVariables,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useCallback } from 'react';
import { completedReimbursementsCursorBasedQuery } from '../toolkit/queries';
import { useFormattedReimbursement } from './useFormattedReimbursement';
import { defaultReimbursementDashboardSort } from './useOpenReimbursementsCursorBasedData';

interface UseCompleteReimbursementsCursorBasedDataProps {
  sortBy?: ReimbursementCaseSortInput;
  filters?: ReimbursementCaseFiltersInput;
}

export const useCompletedReimbursementsCursorBasedData = ({
  filters,
  sortBy,
}: UseCompleteReimbursementsCursorBasedDataProps = {}) => {
  const { canUseReimbursement } = useReimbursement();
  const toReimbursement = useFormattedReimbursement();

  const computeVariables = useCallback(
    (endCursor?: string): CompletedReimbursementsCursorBasedQueryVariables => {
      return {
        input: {
          limit: 20,
          after: endCursor,
        },
        filters,
        sortBy: sortBy ?? defaultReimbursementDashboardSort,
      };
    },
    [filters, sortBy]
  );

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'reimbursementCasesCursorBased'>
  >(
    completedReimbursementsCursorBasedQuery,
    'reimbursementCasesCursorBased',
    {
      variables: computeVariables(undefined),
      skip: !canUseReimbursement,
    },
    {
      computeVariables,
    }
  );

  const { pageInfo, edges } = data?.reimbursementCasesCursorBased ?? {};

  const totalReimbursements = pageInfo?.totalCount ?? 0;

  const reimbursementList = (edges ?? []).map(edge => ({
    ...toReimbursement({
      cursor: edge.cursor,
      ...edge.node,
    }),
  }));

  return {
    loading,
    loadMore: onLoadMore,
    reimbursementList,
    totalReimbursements,
  };
};
