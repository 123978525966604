import React, { useMemo } from 'react';
import {
  AriaMenuItemProps,
  AriaMenuOptions,
  mergeProps,
  useMenu,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { Item, useTreeState } from 'react-stately';
import { Box } from '../../Atoms/Box';
import { TruncatedText } from '../../Molecules/Typography/TruncatedText';
import { LayoutProps } from '../../types';
import { MenuListItem } from './MenuListItem';

export interface MenuItem {
  /** Is the item disabled? */
  disabled?: boolean;
  /** Item text */
  label: string;
  /** Unique identifier for the item within a list */
  id: React.Key;
  /** Action invoked by the item */
  onAction?: () => void;
  /** Custom render function for item */
  renderItem?: (itemProps: MenuItem) => JSX.Element;
  /** Whether or not to close the menu once an item is clicked */
  closeOnSelect?: boolean;
  /** Whether or not to show a separator below the item */
  showSeparator?: boolean;
}

export interface MenuProps
  extends Omit<
      React.HTMLAttributes<HTMLUListElement>,
      'onChange' | 'value' | 'defaultValue' | 'autoFocus'
    >,
    LayoutProps {
  /** Where the focus should be set */
  autoFocus?: AriaMenuOptions<MenuItem>['autoFocus'];
  /** List of menu items */
  items?: AriaMenuOptions<MenuItem>['items'];
  /** Custom render function for items */
  renderItem?: (itemProps: MenuItem) => JSX.Element;
  /** Called when the menu should close after selecting an item */
  onClose?: AriaMenuItemProps['onClose'];
  /** Menu element's unique identifier */
  id?: AriaMenuOptions<MenuItem>['id'];
  /** Identifies the element (or elements) that labels the menu */
  'aria-labelledby'?: AriaMenuOptions<MenuItem>['aria-labelledby'];
  /** Type of selection allowed among the menu items*/
  selectionMode?: AriaMenuOptions<MenuItem>['selectionMode'];
  /** Ids of the currently selected items in the menu */
  value?: (string | number)[];
  /** Ids of the currently selected items in the menu */
  defaultValue?: (string | number)[];
  /** Called when the selection changes */
  onChange?: (value: React.Key[]) => void;
}

/**
 * Default render function for menu items
 */
const defaultRenderItem = ({ label }: MenuItem) => (
  <TruncatedText wordBreak="break-all">{label}</TruncatedText>
);

/**
 * A list of actions or options that a user can choose.
 * [Storybook]{@link (https://candisio.github.io/design-system/?path=/story/atoms-menu)}
 */
export const Menu = React.forwardRef<HTMLUListElement, MenuProps>(
  (
    {
      'aria-labelledby': ariaLabelledBy,
      autoFocus = false,
      defaultValue,
      id,
      items,
      onChange,
      onClose,
      renderItem = defaultRenderItem,
      selectionMode = 'none',
      value,
      ...restProps
    },
    forwardRef
  ) => {
    const children = (item: MenuItem) => (
      <Item key={item.id} textValue={item.label}>
        {item.renderItem?.(item) ?? renderItem(item)}
      </Item>
    );

    const disabledKeys = useMemo(() => {
      const set = new Set<string | number>();
      if (items) {
        Array.from(items).forEach(({ disabled, id }) => {
          if (disabled) {
            set.add(id as any);
          }
        });
      }

      return set;
    }, [items]);

    const state = useTreeState({
      children,
      defaultSelectedKeys: defaultValue,
      disabledKeys,
      items,
      onSelectionChange: (selection) => {
        if (onChange && selection !== 'all') {
          onChange(Array.from(selection));
        }
      },
      selectedKeys: value,
      selectionMode,
    });

    const ref = React.useRef(null);
    const { menuProps } = useMenu(
      {
        id,
        autoFocus,
        items,
        'aria-labelledby': ariaLabelledBy,
        disabledKeys,
      },
      state,
      ref
    );

    const cssReset = {
      margin: 0,
      listStyle: 'none',
    };

    return (
      <Box
        as="ul"
        css={cssReset}
        {...mergeProps(menuProps, restProps)}
        ref={mergeRefs([ref, forwardRef])}
        paddingX={0}
        paddingY="space16"
        overflowY="auto">
        {Array.from(state.collection).map((item) => (
          <MenuListItem
            key={item.key}
            item={item}
            state={state}
            onAction={item?.value?.onAction}
            onClose={onClose}
            closeOnSelect={item?.value?.closeOnSelect}
            showSeparator={item?.value?.showSeparator}
          />
        ))}
      </Box>
    );
  }
);
