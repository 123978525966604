import { ErrorMessages } from 'utils/zodFormValidation';
import {
  MAX_DOCUMENT_TAG_NAME_LENGTH,
  MIN_DOCUMENT_TAG_NAME_LENGTH,
} from 'views/Settings/DocumentTags/toolkit/utils';
import { z } from 'zod';

const duplicateNameIssue = {
  code: z.ZodIssueCode.custom,
  params: {
    translationKey: 'documentTags.drawer.toast.duplicateName',
  },
};

const defaultValues = {
  nameValidation: () => Promise.resolve(true),
};

export const documentTagDetailsFormSchema = ({
  initialName,
  nameValidation,
}: DocumentTagDetailsFormSchemaOptions = defaultValues) => {
  return z.object({
    name: z
      .string()
      .trim()
      .min(MIN_DOCUMENT_TAG_NAME_LENGTH)
      .max(MAX_DOCUMENT_TAG_NAME_LENGTH)
      .refine(name => {
        if (name === initialName) return true;

        return nameValidation(name);
      }, duplicateNameIssue),
    description: z.string().trim().max(250).nullish(),
  });
};

export interface DocumentTagDetailsFormSchemaOptions {
  initialName?: string;
  nameValidation: (name: string) => Promise<boolean>;
}

export type DocumentTagDetailsFormOutput = z.infer<
  ReturnType<typeof documentTagDetailsFormSchema>
>;

export type DocumentTagDetailsFormValues =
  Partial<DocumentTagDetailsFormOutput>;

export type DocumentTagDetailsErrorMessages = ErrorMessages<
  ReturnType<typeof documentTagDetailsFormSchema>
>;
