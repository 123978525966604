import { Flex, Grid } from '@candisio/design-system';
import { SplitBookingsLayout } from 'containers/SplitBookings/components/SplitBookingsLayout';
import { AccordionItem } from 'containers/SplitBookings/components/Summary/AccordionItem';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ReimbursementSplitBookingsFormValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { generateSplitBookingEntryFieldNames } from '../utils/generateSplitBookingsFormFieldNames';
import { BookingFooterActions } from './BookingFooterActions';
import { BookingSummary } from './BookingSummary';
import { useRemainingAmount } from './hooks/useFormatAmountHelpers';
import { useReimbursementSplitBookingFormActions } from './hooks/useReimbursementItemBookingsFormActions';
import { ReimbursementSplitBookingsForm } from './ReimbursementSplitBookingsForm';
import { ReimbursementSplitBookingsFormHeader } from './ReimbursementSplitBookingsFormHeader';
import { useFormEffects } from './hooks/useFormEffects';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';

const ACCORDION_PREFIX = 'reimbursement-booking-accordion';

interface ReimbursmentItemBookingsFormContainerProps {
  status?: ReimbursementCaseStatus;
}

export const ReimbursmentItemBookingsFormContainer = ({
  status,
}: ReimbursmentItemBookingsFormContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [showErrors, setShowErrors] = useState(false);

  const {
    activeBooking,
    updateActiveBooking,
    formMethods,
    resetDrawerAndForm,
    getSplitBookingsFormRules,
  } = useReimbursementSplitBookingHelpers();

  const { fieldRules, actionRules } = getSplitBookingsFormRules(status);

  const { title, entryIndex, bookingId } = activeBooking;
  const topFields = generateSplitBookingEntryFieldNames(entryIndex);

  const { control, getValues, watch } = formMethods;
  const grossAmount = getValues(topFields.grossAmount);
  const bookings = watch(topFields.bookings) ?? [];

  const { calculateRemainingAmount } = useRemainingAmount();
  const remainingAmount = calculateRemainingAmount(grossAmount, bookings);

  const { append, remove } = useFieldArray<
    ReimbursementSplitBookingsFormValues,
    `reimbursementItemBookings.${number}.bookings`
  >({
    control,
    name: topFields.bookings,
  });

  const handleShowErrors = useCallback((val: boolean) => {
    setShowErrors(val);
  }, []);

  const {
    isUpdatingBooking,
    checkFormErrors,
    handleDeleteBooking,
    handleAcceptSplits,
    handleAddSplitBooking,
    handleSetRemainingAmount,
  } = useReimbursementSplitBookingFormActions({
    topFields,
    onAppendBooking: append,
    onRemoveBooking: remove,
    setShowErrors: handleShowErrors,
  });

  const hasOneBooking = bookings?.length === 1;
  const { bookingErrors, hasErrors } = checkFormErrors(
    entryIndex,
    remainingAmount
  );

  useFormEffects({
    hasErrors,
    remainingAmount,
    handleSetRemainingAmount,
    handleShowErrors,
  });

  const canAddAndDeleteSplits = actionRules.canAddAndDeleteSplits;

  return (
    <FormProvider {...formMethods}>
      <SplitBookingsLayout
        key={`reimbursement-split-bookings-${entryIndex}`}
        title={title}
        header={
          <ReimbursementSplitBookingsFormHeader
            fields={topFields}
            remainingAmount={remainingAmount}
            isReadOnly={!canAddAndDeleteSplits}
          />
        }>
        <Grid as="form" height="100%">
          <Grid alignContent="space-between" gap="space16">
            <Flex direction="column">
              {hasOneBooking ? (
                <ReimbursementSplitBookingsForm
                  hasOneBooking
                  entryIndex={entryIndex}
                  bookingIndex={0}
                  bookingId={bookings[0].bookingId}
                  fieldRules={fieldRules[0]}
                />
              ) : (
                bookings.map((booking, bookingIndex) => {
                  return (
                    <AccordionItem
                      key={booking.bookingId}
                      id={`${ACCORDION_PREFIX}-${bookingIndex}`}
                      open={booking.bookingId === bookingId}
                      error={!!bookingErrors[bookingIndex]}
                      onClick={() =>
                        updateActiveBooking({ bookingId: booking.bookingId })
                      }
                      summary={
                        <BookingSummary
                          splitNumber={bookingIndex + 1}
                          booking={booking}
                          onDelete={
                            canAddAndDeleteSplits
                              ? async () =>
                                  await handleDeleteBooking(bookingIndex)
                              : undefined
                          }
                        />
                      }>
                      {booking.bookingId === bookingId && (
                        <ReimbursementSplitBookingsForm
                          bookingId={bookingId}
                          entryIndex={entryIndex}
                          bookingIndex={bookingIndex}
                          fieldRules={fieldRules[bookingIndex]}
                        />
                      )}
                    </AccordionItem>
                  );
                })
              )}
            </Flex>
            <BookingFooterActions
              actionRules={actionRules}
              isLoading={isUpdatingBooking}
              onCancelClick={resetDrawerAndForm}
              onAddNewSplit={async () =>
                await handleAddSplitBooking(remainingAmount)
              }
              onAcceptBookings={async () => await handleAcceptSplits()}
              errorMessage={
                showErrors ? t('splitBookings.genericError') : undefined
              }
            />
          </Grid>
        </Grid>
      </SplitBookingsLayout>
    </FormProvider>
  );
};
