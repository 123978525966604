import type { DocumentTypeSettingFragment } from 'generated-types/graphql.types';
import { startCase } from 'lodash';
import type { DocumentTypesSortByValues } from './consts';

function sortByName(
  a: DocumentTypeSettingFragment,
  b: DocumentTypeSettingFragment
) {
  return a.name.localeCompare(b.name);
}

function sortByActive(
  a: DocumentTypeSettingFragment,
  b: DocumentTypeSettingFragment
) {
  return Number(b.isVisible) - Number(a.isVisible);
}

function sortByDocumentCount(
  a: DocumentTypeSettingFragment,
  b: DocumentTypeSettingFragment
) {
  return (b.documentCount ?? 0) - (a.documentCount ?? 0);
}

export function sortByMapper(sortBy: DocumentTypesSortByValues) {
  switch (sortBy) {
    case 'isVisible':
      return sortByActive;
    case 'documentCount':
      return sortByDocumentCount;
    case 'name':
    default:
      return sortByName;
  }
}

export function searchByName(search: string) {
  return (item: DocumentTypeSettingFragment) => {
    return startCase(item.name)
      .toLowerCase()
      .includes(search.toLowerCase().trim());
  };
}
