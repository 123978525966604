import { useInAppNotificationsPageBasedData } from 'containers/notifications/hooks/useInAppNotificationsPageBasedData';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { NotificationSummary } from './NotificationSummary/NotificationSummary';

interface NotificationSummaryContainerProps {
  onClose: () => void;
  currentCompanyOnly: boolean;
}

const localStorageKey = 'notifications_toggle';

export const InAppNotifications = ({
  onClose,
  currentCompanyOnly,
}: NotificationSummaryContainerProps) => {
  const [showOnlyUnread, setShowOnlyUnread] = useLocalStorage(
    localStorageKey,
    false
  );

  const currentOrganizationId = useOrganizationId() ?? undefined;

  const { notificationDetails, hasMore, onLoadMore, loading } =
    useInAppNotificationsPageBasedData({
      organizationId: currentCompanyOnly ? currentOrganizationId : undefined,
      showOnlyUnread,
    });

  const { notifications, totalCount, unreadNotifications } =
    notificationDetails;

  const notificationList = showOnlyUnread ? unreadNotifications : notifications;

  const handleShowOnlyUnread = (toggled: boolean) => {
    setShowOnlyUnread(toggled);
  };

  return (
    <NotificationSummary
      loading={loading}
      hasMore={hasMore}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      onClose={onClose}
      showOnlyUnread={showOnlyUnread}
      notifications={notificationList}
      onShowOnlyUnread={handleShowOnlyUnread}
      currentCompanyOnly={currentCompanyOnly}
    />
  );
};
