import {
  Button,
  Flex,
  Grid,
  Separator,
  Text,
  TruncatedText,
  useTheme,
} from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useMissingInvoicesEmailText } from 'views/CreditCards/hooks/useMissingInvoicesEmailText';
import { CardHolderCCInsightsWidgetData } from 'views/CreditCards/hooks/useTransactionsInsights';
import { DateOptionMissingInvoicesWidget } from './utils';

interface CardHolderListItemProps extends CardHolderCCInsightsWidgetData {
  dateOption: DateOptionMissingInvoicesWidget;
}

export const CardHolderListItem = ({
  dateRange,
  cardholder,
  invoicesWithoutTransaction,
  dateOption,
}: CardHolderListItemProps) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const fullName = `${cardholder.firstName} ${cardholder.lastName}`;
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const transactionViewUrl = `/${organizationId}${Routes.INBOX}${Routes.TRANSACTIONS}`;

  const starteDate = dateRange?.dateFrom;
  const endDate = dateRange?.dateTo;

  const transactionCreatedAt = !isNil(dateRange)
    ? `${starteDate}-${endDate}`
    : undefined;

  const urlSearchParams = {
    cardholderName: cardholder.id,
    transactionCreatedAt,
  };

  const emailLink = `/${organizationId}${Routes.INBOX}${
    Routes.TRANSACTIONS
  }?${qs.stringify(urlSearchParams)}`;

  const { body, subjectEmail } = useMissingInvoicesEmailText({
    cardholder,
    invoicesWithoutTransaction,
    emailLink,
    dateOption,
  });

  const handleItemClick = () => {
    navigate({
      pathname: transactionViewUrl,
      search: qs.stringify(urlSearchParams),
    });
  };

  const handleEmailClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      <Grid
        alignItems="center"
        onClick={handleItemClick}
        style={{ cursor: 'pointer' }}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingY="space20"
          paddingX="space8">
          <Grid
            alignItems="center"
            gap="space12"
            templateColumns="auto 1fr"
            flexGrow={1}>
            <AvatarWithStatusContainer
              hasTooltip={false}
              size="small"
              img={cardholder.avatarUrl}
              userId={cardholder.membershipId}
              name={fullName}
            />
            <TruncatedText fontSize="basic" width="75%">
              {fullName}
            </TruncatedText>
          </Grid>
          <Flex alignItems="center" gap="space8">
            <Text fontSize="basic">{invoicesWithoutTransaction}</Text>
            {/** Remove inline style when antD is completely removed */}
            <Button
              icon="mail"
              variant="tertiary"
              label={t('insights.missingInvoicesWidget.buttonLabel')}
              target="_blank"
              rel="noopener noreferrer"
              as="a"
              onClick={handleEmailClick}
              href={`mailto:${
                cardholder.membershipEmail
              }?subject=${subjectEmail}&body=${encodeURIComponent(body)}`}
              style={{ color: colors.gray800 }}
            />
          </Flex>
        </Flex>
      </Grid>
      <Separator color="gray200" />
    </>
  );
};
