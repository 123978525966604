import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation/zodResolver';
import { contractSubCategoryDetailsErrorMessages } from '../Form/contractSubCategoryDetailsErrorMessages';
import {
  ContractSubCategoryDetailsFormOutput,
  ContractSubCategoryDetailsFormValues,
  contractSubCategoryDetailsFormSchema,
} from '../Form/contractSubCategoryDetailsFormSchema';
import { contractSubCategoryDefaultValues } from './ContractCustomSubCategoryDetails';

export const useCreateSubCategoryForm = ({
  defaultValues,
  contractSubCategoryId,
}: {
  defaultValues: ContractSubCategoryDetailsFormValues;
  contractSubCategoryId: string | undefined;
}) => {
  // TODO: replace with actual server-side name duplicate check;
  const nameValidation = () => Promise.resolve(true);

  const context = {
    initialName: defaultValues?.name,
    nameValidation,
  };

  const form = useForm<ContractSubCategoryDetailsFormOutput>({
    // Validation will trigger on the first blur event. After that, it will
    // trigger on every change event.
    mode: 'onTouched',
    shouldFocusError: true,
    context,
    defaultValues: {
      ...contractSubCategoryDefaultValues,
      ...defaultValues,
    },
    resolver: zodResolver({
      zodSchema: contractSubCategoryDetailsFormSchema,
      errorMessages: contractSubCategoryDetailsErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
  });

  const hasNameChanged = !!form.formState.dirtyFields.name;
  const showConfirmation = !!contractSubCategoryId && hasNameChanged;

  return { showConfirmation, form };
};
