import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ListGoodsReceiptImportHistoryEntriesResult } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const goodsReceiptsImportHistory: FieldPolicy<ListGoodsReceiptImportHistoryEntriesResult> =
  {
    merge: simplePaginationMergeWithoutDuplicates(
      'ListGoodsReceiptImportHistoryEntriesResult'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      listGoodsReceiptImportHistoryEntries: goodsReceiptsImportHistory,
    },
  },
};
