import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursementMenuButtonActions } from 'views/Reimbursement/hooks/useReimbursementMenuButtonActions';
import { useExpenseFormsHelpers } from '../MiddleSection/utils/useExpenseFormsHelpers';
import { ReimbursementItemsCard } from './ReimbursementItemsCard';

interface ReimbursementItemsCardContainerProps {
  reimbursementItems: ReimbursementItemListLeftSection[];
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
}

export const ReimbursementItemsCardContainer = ({
  reimbursementItems,
  formHelpers,
}: ReimbursementItemsCardContainerProps) => {
  const {
    isCreatingExpenseItem,
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
  } = useReimbursementMenuButtonActions({ formHelpers });

  return (
    <ReimbursementItemsCard
      items={reimbursementItems}
      creatingReimbursementItem={isCreatingExpenseItem}
      onGeneralExpenseClick={handleCreateGeneralExpense}
      onHospitalityExpenseClick={handleCreateHospitalityExpense}
    />
  );
};
