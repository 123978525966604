import { Button, Grid, TruncatedText } from '@candisio/design-system';
import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { netToGross } from 'containers/SplitBookings/toolkit/utils';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat, percentageFormat } from 'utils/format';
import { BookingValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';

export interface BookingSummaryProps {
  splitNumber: number;
  booking: BookingValues;
  onDelete?: () => void;
}

export const BookingSummary = ({
  splitNumber,
  booking,
  onDelete,
}: BookingSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();
  const isOrgUsingOnlyCostObjects = !showCostCenterField && showCostObjectField;

  const { splitAmount, vatRate, taxPresentation, costCenter, costObject } =
    booking;

  const updatedSplitAmount =
    taxPresentation === TaxPresentation.Gross
      ? splitAmount
      : netToGross(splitAmount ?? 0, vatRate ?? 0);

  const formattedSplitAmount = amountFormat(
    updatedSplitAmount,
    DocumentCurrency.Eur
  );

  const formattedVatRate = !isNil(vatRate) ? percentageFormat(vatRate) : null;
  const placeholder = t('splitBookings.costCenter.placeholder.notSet');

  const getCostTypeDisplayName = () => {
    let displayName;

    if (isOrgUsingOnlyCostObjects && !!costObject?.value) {
      displayName = costObject.inputValue;
    } else {
      displayName = costCenter?.value ? costCenter?.inputValue : '';
    }

    return displayName || placeholder;
  };

  return (
    <Grid gap="space8" templateColumns="1fr auto">
      <Grid
        alignItems="center"
        autoFlow="column"
        minHeight="space24"
        gap="space8"
        templateColumns=".65fr 2fr 1.25fr 2.5fr">
        <TruncatedText color="gray400">{splitNumber.toString()}</TruncatedText>
        <TruncatedText>
          {formattedSplitAmount ??
            t('splitBookings.costCenter.placeholder.notSet')}
        </TruncatedText>
        <>
          <TruncatedText color="gray500">
            {formattedVatRate ??
              t('splitBookings.costCenter.placeholder.notSet')}
          </TruncatedText>
          <TruncatedText>{getCostTypeDisplayName()}</TruncatedText>
        </>
      </Grid>
      {onDelete ? (
        <Button
          icon="trash"
          label={t('splitBookings.deleteSplit')}
          size="xsmall"
          onClick={onDelete}
          variant="tertiary"
          color="red"
        />
      ) : null}
    </Grid>
  );
};
