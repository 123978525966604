import { gql } from '@apollo/client';
import { pageInfoDataFragment } from 'views/queries/fragments';
import {
  expenseItemArchivedEventFragment,
  expenseItemExpenseDateUpdatedEventFragment,
  expenseItemFileAddedEventFragment,
  expenseItemFileRemovedEventFragment,
  expenseItemHospitalityExternalGuestsUpdatedEventFragment,
  expenseItemHospitalityInternalGuestsUpdatedEventFragment,
  expenseItemHospitalityLocationUpdatedEventFragment,
  expenseItemHospitalityReceiptAmountUpdatedEventFragment,
  expenseItemHospitalityTipAmountUpdatedEventFragment,
  expenseItemTitleUpdatedEventFragment,
  expenseItemTotalAmountUpdatedEventFragment,
  reimbursementCaseAmendmentRequestedEventFragment,
  reimbursementCaseApprovedEventFragment,
  reimbursementCaseCommentAddedEventFragment,
  reimbursementCaseCreatedEventFragment,
  reimbursementCaseNoteUpdatedEventFragment,
  reimbursementCaseSubmittedForReviewEventFragment,
  reimbursementCaseTargetMembershipUpdatedEventFragment,
  reimbursementCaseTitleUpdatedEventFragment,
} from './fragments';

export const reimbursementCaseDataFragment = gql`
  fragment ReimbursementCaseData on ReimbursementCase {
    id
    organizationId
    createdAt
    status
    createTransfer
    contact {
      contactId
      iban
    }
    isPaid
    paidAt
    title
    note
    createdByMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    targetMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    totalAmount {
      amount
      currency
      precision
    }
    suggestedCostCenter {
      id
      readableName
    }
    suggestedCostObject {
      id
      readableName
    }
    errors {
      field
      errors
    }
  }
`;

export const reimbursementCasesPageBasedQuery = gql`
  query reimbursementCasesPageBased(
    $input: ReimbursementCasePageBasedPaginationInput!
    $filters: ReimbursementCaseFiltersInput
    $sortBy: ReimbursementCaseSortInput
  ) {
    reimbursementCasesPageBased(
      input: $input
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        ...ReimbursementCaseData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${reimbursementCaseDataFragment}
`;

export const reimbursementCaseByIdQuery = gql`
  query reimbursementCaseById($id: ID!) {
    reimbursementCaseById(id: $id) {
      ...ReimbursementCaseData
    }
  }
  ${reimbursementCaseDataFragment}
`;

export const reimbursementCasesCountQuery = gql`
  query reimbursementCasesCount($filters: ReimbursementCaseFiltersInput) {
    reimbursementCasesCount(filters: $filters) {
      total
    }
  }
`;

export const reimbursementItemsQuery = gql`
  query reimbursementItems($filters: ReimbursementItemFiltersInput!) {
    reimbursementItems(filters: $filters) {
      ... on GeneralExpenseItem {
        id
        reimbursementCaseId
        createdAt
        type
        status
        title
        expenseDate
        files {
          id
          url
          name
        }
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
      }

      ... on HospitalityExpenseItem {
        id
        reimbursementCaseId
        createdAt
        type
        status
        title
        expenseDate
        location
        files {
          id
          url
        }
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        internalGuests
        externalGuests
        receiptAmount {
          amount
          currency
          precision
        }
        tipAmount {
          amount
          currency
          precision
        }
      }
    }
  }
`;

export const reimbursementItemsListQuery = gql`
  query reimbursementItemsList($filters: ReimbursementItemFiltersInput!) {
    reimbursementItemsList: reimbursementItems(filters: $filters)
      @connection(key: "reimbursementItemsList", filter: ["filters"]) {
      ... on GeneralExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
      }

      ... on HospitalityExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
      }
    }
  }
`;

export const uniqueReimbursementCaseTargetMemberships = gql`
  query uniqueReimbursementCaseTargetMemberships(
    $input: ReimbursementCasePageBasedPaginationInput!
    $filters: ReimbursementCaseTargetMembershipsFiltersInput
    $queries: ReimbursementCaseTargetMembershipsQueryInput
    $sortBy: ReimbursementCaseTargetMembershipsSortInput
  ) {
    uniqueReimbursementCaseTargetMemberships(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    ) {
      records {
        id
        firstName
        lastName
        avatarUrl
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const getPageBasedTargetMembershipsQuery = gql`
  query getPageBasedTargetMemberships(
    $input: ReimbursementCasePageBasedPaginationInput!
    $filters: ReimbursementCaseTargetMembershipsFiltersInput
    $queries: ReimbursementCaseTargetMembershipsQueryInput
    $sortBy: ReimbursementCaseTargetMembershipsSortInput
  ) {
    uniqueReimbursementCaseTargetMemberships(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    )
      @connection(
        key: "getPageBasedTargetMembershipsInfiniteScrollPagonation"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        firstName
        lastName
        avatarUrl
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const uniqueReimbursementCaseApproversQuery = gql`
  query uniqueReimbursementCaseApprovers(
    $input: ReimbursementCasePageBasedPaginationInput!
    $filters: ReimbursementCaseApproverMembershipsFiltersInput
    $queries: ReimbursementCaseApproverMembershipsQueryInput
    $sortBy: ReimbursementCaseApproverMembershipsSortInput
  ) {
    uniqueReimbursementCaseApprovers(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    ) {
      records {
        id
        firstName
        lastName
        avatarUrl
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const getPageBasedApproversQuery = gql`
  query getPageBasedApproversQuery(
    $input: ReimbursementCasePageBasedPaginationInput!
    $filters: ReimbursementCaseApproverMembershipsFiltersInput
    $queries: ReimbursementCaseApproverMembershipsQueryInput
    $sortBy: ReimbursementCaseApproverMembershipsSortInput
  ) {
    uniqueReimbursementCaseApprovers(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    )
      @connection(
        key: "getPageBasedApproversInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        firstName
        lastName
        avatarUrl
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const reimbursementsCursorBasedNavigationQuery = gql`
  query reimbursementsCursorBasedNavigation(
    $cursor: String
    $filters: ReimbursementCaseFiltersInput
    $sortBy: ReimbursementCaseSortInput
    $queries: ReimbursementCaseQueryInput
  ) {
    prevReimbursement: reimbursementCasesCursorBased(
      input: { limit: 1, before: $cursor }
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
    nextReimbursement: reimbursementCasesCursorBased(
      input: { limit: 1, after: $cursor }
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
`;

export const completedReimbursementsCursorBasedQuery = gql`
  query completedReimbursementsCursorBased(
    $input: ReimbursementCasePaginationInput!
    $filters: ReimbursementCaseFiltersInput
    $sortBy: ReimbursementCaseSortInput
    $queries: ReimbursementCaseQueryInput
  ) {
    reimbursementCasesCursorBased(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "completedReimbursementsCursorBased"
        filter: ["filters", "queries", "sortBy"]
      ) {
      edges {
        cursor
        node {
          ...ReimbursementCaseData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${reimbursementCaseDataFragment}
  ${pageInfoDataFragment}
`;

export const openReimbursementsCursorBasedQuery = gql`
  query openReimbursementsCursorBased(
    $input: ReimbursementCasePaginationInput!
    $filters: ReimbursementCaseFiltersInput
    $sortBy: ReimbursementCaseSortInput
    $queries: ReimbursementCaseQueryInput
  ) {
    reimbursementCasesCursorBased(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "openReimbursementsCursorBased"
        filter: ["filters", "queries", "sortBy"]
      ) {
      edges {
        cursor
        node {
          ...ReimbursementCaseData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${reimbursementCaseDataFragment}
  ${pageInfoDataFragment}
`;

export const reimbursementItemsSplitBookingsQuery = gql`
  query reimbursementItemsSplitBookings(
    $filters: ReimbursementItemFiltersInput!
  ) {
    reimbursementItemsSplitBookings: reimbursementItems(filters: $filters)
      @connection(key: "reimbursementItemsSplitBookings", filter: ["filters"]) {
      ... on GeneralExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }

      ... on HospitalityExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              taxCode
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }
    }
  }
`;

export const reimbursementHistoryQuery = gql`
  query getReimbursementHistory($id: ID!) {
    reimbursementCaseById(id: $id)
      @connection(key: "getReimbursementHistory", filter: ["id"]) {
      id
      timeline {
        __typename
        id
        createdAt
        ...ReimbursementCaseTitleUpdatedEventData
        ...ExpenseItemTitleUpdatedEventData
        ...ReimbursementCaseAmendmentRequestedEventData
        ...ReimbursementCaseApprovedEventData
        ...ReimbursementCaseCreatedEventData
        ...ReimbursementCaseSubmittedForReviewEventData
        ...ReimbursementCaseTargetMembershipUpdatedEventData
        ...ReimbursementCaseNoteUpdatedEventData
        ...ExpenseItemTotalAmountUpdatedEventData
        ...ExpenseItemFileAddedEventData
        ...ExpenseItemFileRemovedEventData
        ...ExpenseItemExpenseDateUpdatedEventData
        ...ReimbursementCaseCommentAddedEventData
        ...ExpenseItemArchivedEventData
        ...ExpenseItemHospitalityExternalGuestsUpdatedEventData
        ...ExpenseItemHospitalityInternalGuestsUpdatedEventData
        ...ExpenseItemHospitalityLocationUpdatedEventData
        ...ExpenseItemHospitalityReceiptAmountUpdatedEventData
        ...ExpenseItemHospitalityTipAmountUpdatedEventData
      }
    }
  }
  ${reimbursementCaseTitleUpdatedEventFragment}
  ${expenseItemTitleUpdatedEventFragment}
  ${reimbursementCaseAmendmentRequestedEventFragment}
  ${reimbursementCaseApprovedEventFragment}
  ${reimbursementCaseCreatedEventFragment}
  ${reimbursementCaseSubmittedForReviewEventFragment}
  ${reimbursementCaseTargetMembershipUpdatedEventFragment}
  ${reimbursementCaseNoteUpdatedEventFragment}
  ${expenseItemTotalAmountUpdatedEventFragment}
  ${expenseItemFileAddedEventFragment}
  ${expenseItemFileRemovedEventFragment}
  ${expenseItemExpenseDateUpdatedEventFragment}
  ${reimbursementCaseCommentAddedEventFragment}
  ${expenseItemArchivedEventFragment}
  ${expenseItemHospitalityExternalGuestsUpdatedEventFragment}
  ${expenseItemHospitalityInternalGuestsUpdatedEventFragment}
  ${expenseItemHospitalityLocationUpdatedEventFragment}
  ${expenseItemHospitalityReceiptAmountUpdatedEventFragment}
  ${expenseItemHospitalityTipAmountUpdatedEventFragment}
`;
