import { FileState, UploadFileItem } from 'generated-types/graphql.types';
import { ExportStatus } from 'generated-types/graphql.types';

import { ExportType } from 'generated-types/graphql.types';
import { ExportItem } from 'providers/ExportNotificationsProvider';
import AddisonIcon from 'views/Integrations/Export/toolkit/images/icon-addison.svg';
import CandisCsvIcon from 'views/Integrations/Export/toolkit/images/icon-candis-csv.svg';
import DatevIcon from 'views/Integrations/Export/toolkit/images/icon-datev-api.svg';
import DatevCsv from 'views/Integrations/Export/toolkit/images/icon-datev-csv.svg';
import DatevXml from 'views/Integrations/Export/toolkit/images/icon-datev-xml.svg';
import SapCsvIcon from 'views/Integrations/Export/toolkit/images/icon-sap-csv.svg';
import ZipIcon from 'views/Integrations/Export/toolkit/images/icon-zip.svg';

import { IndicatorStatus } from './components/ProgressIndicator/index';

export const getUploadStatus = (files: UploadFileItem[]): IndicatorStatus => {
  if (files.length === 0) {
    return IndicatorStatus.IDLE;
  }

  if (files.some(file => file.state === FileState.Error)) {
    return IndicatorStatus.ERROR;
  }

  if (files.every(file => file.state === FileState.Uploaded)) {
    return IndicatorStatus.DONE;
  }

  return IndicatorStatus.PROCESSING;
};

// exports mappers
export const mapExportIcon = (type: ExportType) => {
  switch (type) {
    case ExportType.DatevCsv:
      return DatevCsv;

    case ExportType.DatevDxsoZip:
      return DatevXml;

    case ExportType.DatevDco:
    case ExportType.DatevDxso:
    case ExportType.Datev:
      return DatevIcon;

    case ExportType.AddisonFormat:
      return AddisonIcon;

    case ExportType.CandisCsv:
      return CandisCsvIcon;

    case ExportType.SapCsv:
      return SapCsvIcon;

    default:
      return ZipIcon;
  }
};

export const getExportsStatus = (e: ExportItem[]): IndicatorStatus => {
  if (e.length === 0) {
    return IndicatorStatus.IDLE;
  }

  if (e.some(exp => exp.status === ExportStatus.Failed)) {
    return IndicatorStatus.ERROR;
  }

  if (e.some(exp => exp.status === ExportStatus.PartialyExported)) {
    return IndicatorStatus.WARNING;
  }

  if (e.every(exp => exp.status === ExportStatus.Exported)) {
    return IndicatorStatus.DONE;
  }

  return IndicatorStatus.PROCESSING;
};

export const mapExportStatus = (status: ExportStatus) => {
  switch (status) {
    case ExportStatus.PartialyExported:
      return IndicatorStatus.WARNING;
    case ExportStatus.Exported:
      return IndicatorStatus.DONE;
    case ExportStatus.Exporting:
      return IndicatorStatus.PROCESSING;
    default:
      return IndicatorStatus.ERROR;
  }
};

export const isSupportedFile = (
  file: File,
  isXmlDocumentUploadAllowed?: boolean
) => {
  const lastDot = file.name.lastIndexOf('.');
  const fileExt = (
    lastDot > 0 ? file.name.substring(lastDot + 1) : ''
  ).toLowerCase();

  if (file.type === 'application/pdf') {
    return Boolean(['pdf', ''].includes(fileExt));
  }

  if (file.type === 'application/octet-stream') {
    return Boolean(
      fileExt === 'pdf' || (isXmlDocumentUploadAllowed && fileExt === 'xml')
    );
  }

  if (file.type === 'text/xml' || file.type === 'application/xml') {
    return Boolean(isXmlDocumentUploadAllowed && ['xml', ''].includes(fileExt));
  }

  if (file.type === 'text/plain') {
    return Boolean(isXmlDocumentUploadAllowed && fileExt === 'xml');
  }

  return false;
};

export const inputFieldAnimationProps = {
  initial: { opacity: 1, height: 'auto' },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: '0px' },
  transition: { duration: 0.25, ease: 'easeInOut' },
};
