import { useApolloClient } from '@apollo/client';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  UpdateContractSubCategoryMutation,
  useCreateContractSubCategoryMutation,
  useContractSubCategoryQuery,
  useUpdateContractSubCategoryMutation,
  useUpdateContractSubCategoryStatusMutation,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { staticContractSubCategories } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { ContractCustomSubCategoryDetailsProps } from '../elements/Drawer/ContractCustomSubCategoryDetails';
import { ContractSubCategoryType } from './useContractCustomSubCategoryData';

/**
 * Error messages are defined and come from BE
 * TODO: either localise errors in BE or introduce status code
 * to reliably show localised error in FE
 */
const DUPLICATE_SUBCATEGORY_NAME_ERROR_MESSAGE_SNIPPET = 'already exists';

export interface UseUpdateContractCustomSubCategoryOptions {
  contractSubCategoryId?: string;
  onCloseDrawer: () => void;
  onSubCategoryCreated?: (
    contractSubCategory: UpdateContractSubCategoryMutation['updateContractSubCategory']
  ) => void;
}

const hasDuplicateNameError = (error: GraphQLError) =>
  error.message.includes(DUPLICATE_SUBCATEGORY_NAME_ERROR_MESSAGE_SNIPPET);

export const useUpdateContractCustomSubCategory = ({
  onCloseDrawer,
  contractSubCategoryId,
  onSubCategoryCreated,
}: UseUpdateContractCustomSubCategoryOptions) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const client = useApolloClient();

  const staticCategory = contractSubCategoryId
    ? staticContractSubCategories.find(({ id }) => id === contractSubCategoryId)
    : undefined;

  const isStatic = staticCategory !== undefined;

  const { data, loading: loadingContractSubCategory } =
    useContractSubCategoryQuery({
      variables: { id: contractSubCategoryId ?? '' },
      skip: !contractSubCategoryId || isStatic,
    });

  const [createContractSubCategory, { loading: creatingContractSubCategory }] =
    useCreateContractSubCategoryMutation({
      onCompleted: data => {
        client.cache.evict({ fieldName: 'contractSubCategories' });
        onSubCategoryCreated?.(data.createContractSubCategory);
      },
    });

  const [updateContractSubCategory, { loading: updatingContractSubCategory }] =
    useUpdateContractSubCategoryMutation({
      onCompleted: () => {
        client.cache.evict({ fieldName: 'contractSubCategories' });
      },
    });

  const [
    updateContractSubCategoryStatus,
    { loading: updatingContractSubCategoryStatus },
  ] = useUpdateContractSubCategoryStatusMutation();

  const handleUpdateContractSubCategoryStatus = async (isActive: boolean) => {
    const result = await updateContractSubCategoryStatus({
      variables: {
        input: {
          id: contractSubCategoryId ?? '',
          isActive,
        },
      },
      onCompleted: () => {
        client.cache.evict({ fieldName: 'contractSubCategories' });
      },
    });

    if (
      result.data?.updateContractSubCategoryStatus.__typename !==
      'ContractSubCategory'
    ) {
      return error(t('contractCustomSubCategories.drawer.toast.error'));
    }

    if (isActive) {
      success(t('contractCustomSubCategories.drawer.toast.activated'));
    } else {
      success(t('contractCustomSubCategories.drawer.toast.archived'));
    }

    onCloseDrawer();
  };

  const handleSubmit: ContractCustomSubCategoryDetailsProps['onSubmit'] =
    async values => {
      let result;
      if (contractSubCategoryId) {
        result = await updateContractSubCategory({
          variables: {
            input: {
              id: contractSubCategoryId,
              name: values.name,
              description: values.description,
            },
          },
        });
      } else {
        result = await createContractSubCategory({
          variables: {
            input: {
              name: values.name,
              description: values.description,
            },
          },
        });
      }

      if (typeof result.errors === 'object' && result.errors.length !== 0) {
        let errorMessage = t('contractCustomSubCategories.drawer.toast.error');

        if (result.errors.find(hasDuplicateNameError)) {
          errorMessage = t(
            'contractCustomSubCategories.drawer.toast.duplicateNameError',
            { contractSubCategoryName: values.name }
          );
        }

        return error(errorMessage);
      }

      if (contractSubCategoryId) {
        success(t('contractCustomSubCategories.drawer.toast.updated'));
      } else {
        success(t('contractCustomSubCategories.drawer.toast.created'));
      }

      onCloseDrawer();
    };

  const archiveContractSubCategory = () =>
    handleUpdateContractSubCategoryStatus(false);

  const activateContractSubCategory = () =>
    handleUpdateContractSubCategoryStatus(true);

  const contractSubCategory: ContractSubCategoryType | undefined = isStatic
    ? {
        id: staticCategory.id,
        name: t(staticCategory.translationKey, { ns: 'common' }),
        description: ' ',
        isActive: true,
        isStatic: true,
      }
    : data?.contractSubCategory;

  return {
    contractSubCategory,
    loadingContractSubCategory,
    creatingContractSubCategory,
    updatingContractSubCategory,
    updatingContractSubCategoryStatus,
    archiveContractSubCategory,
    activateContractSubCategory,
    handleSubmit,
  };
};
