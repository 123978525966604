import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ExpenseItemExpenseDateUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { mappedExpenseTypeToGeneralTitle } from 'views/Reimbursement/utils/getActivityTypeToReimbursementHistoryComponent';

export const ExpenseItemFieldUpdatedDateUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ExpenseItemExpenseDateUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { newExpenseDate } = properties;

  const newFormattedDate = newExpenseDate
    ? DateFormatters.compact(new Date(newExpenseDate))
    : undefined;

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const fallBackTitle = reimbursementItemMetadata?.type
    ? t(mappedExpenseTypeToGeneralTitle[reimbursementItemMetadata?.type])
    : undefined;

  return (
    <Grid>
      <Text>
        {t('timeline.expenseChanged', { title: expenseTitle ?? fallBackTitle })}
      </Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t('reimbursementView.middleSection.form.hospitality.date.label')}
          </Text>
          <Paragraph>{newFormattedDate ?? t('timeline.empty')}</Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
