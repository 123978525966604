import { debounce } from 'lodash';
import { useEffect, useCallback } from 'react';
import { useReimbursementFormsContext } from '../context/ReimbursementFormsContext';
import { scrollToTarget } from 'views/CreditCards/utils/utils';

const DEBOUNCE_TIME = 50;
const HEIGHT_MIN = 0;
const SCROLL_TIMEOUT = 2000;

export const useVisibleReimbursementItemTracker = () => {
  const {
    setVisibleItemId,
    itemRefs,
    itemsContainerRef,
    selectedItemId,
    setSelectedItemId,
  } = useReimbursementFormsContext();

  const trackVisibleItem = useCallback(() => {
    const container = itemsContainerRef.current;
    const items = itemRefs.current;

    if (!container || !items) return;

    // If a selectedItemId is set, we prioritize scrolling to it and mark it as visible
    if (selectedItemId) {
      scrollToTarget(selectedItemId);
      setVisibleItemId(selectedItemId);
      return;
    }

    let maxVisibleArea = 0;
    let mostVisibleItemId: string | null = null;
    const containerRect = container.getBoundingClientRect();

    Object.entries(items).forEach(([id, ref]) => {
      if (ref) {
        const rect = ref.getBoundingClientRect();
        const visibleHeight =
          Math.min(rect.bottom, containerRect.bottom) -
          Math.max(rect.top, containerRect.top);

        const visibleArea = Math.max(HEIGHT_MIN, visibleHeight) * rect.width;

        if (visibleArea > maxVisibleArea) {
          maxVisibleArea = visibleArea;
          mostVisibleItemId = id;
        }
      }
    });

    if (mostVisibleItemId) {
      setVisibleItemId(mostVisibleItemId);
    }
  }, [itemsContainerRef, itemRefs, selectedItemId, setVisibleItemId]);

  useEffect(() => {
    const container = itemsContainerRef.current;
    const debouncedTrackVisibleItem = debounce(trackVisibleItem, DEBOUNCE_TIME);

    if (container) {
      container.addEventListener('scroll', debouncedTrackVisibleItem);
      window.addEventListener('resize', debouncedTrackVisibleItem);
    }

    // We call trackVisibleItem to perform an immediate visibility check
    trackVisibleItem();

    // We unset the selectedItemId after a delay to resume normal visibility tracking
    let cleanupSelectedItemId: NodeJS.Timeout;
    if (selectedItemId) {
      cleanupSelectedItemId = setTimeout(() => {
        setSelectedItemId(null);
      }, SCROLL_TIMEOUT);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', debouncedTrackVisibleItem);
      }

      debouncedTrackVisibleItem.cancel();
      window.removeEventListener('resize', debouncedTrackVisibleItem);
      if (cleanupSelectedItemId) clearTimeout(cleanupSelectedItemId);
    };
  }, [itemsContainerRef, trackVisibleItem, selectedItemId, setSelectedItemId]);
};
