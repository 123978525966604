import { AnimatePresence, motion } from 'framer-motion';
import { transition } from './animation';

const variants = {
  initial: { opacity: 0.5, zIndex: 2 },
  animate: { opacity: 1, zIndex: 1 },
  exit: { opacity: 0.5, zIndex: 0 },
};

export const CrossfadeImage = ({ src }: { src: string }) => {
  return (
    <AnimatePresence>
      <motion.img
        key={src}
        src={src}
        alt=""
        style={{
          aspectRatio: '9/6',
          objectFit: 'cover',
          gridColumn: 1,
          gridRow: 1,
        }}
        height="100%"
        width="100%"
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={transition}
        draggable={false}
      />
    </AnimatePresence>
  );
};
