import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Item,
  TabPanel,
  Tabs,
  Text,
  useModal,
  useTabListState,
} from '@candisio/design-system';
import { TabItem } from '@candisio/design-system';
import { AriaTabListProps } from '@react-types/tabs';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { DocumentTableQueryParams } from 'components/DocumentsTable/Filters/filters';
import { UserRole } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { ReactText, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { appendParamsToQueryString } from 'utils/url-helper';
import { BDSPromotionModal } from 'views/Integrations/Export/toolkit/components/BDSPromotion';
import { ModalSource } from 'views/Integrations/Export/types';
import { ApprovalsTableContainer } from './ApprovalTable';
import { useApprovalTabNavigationData } from './hooks/useApprovalTabNavigationData';
import { useApprovalTabNavigationDataDeprecated } from './hooks/useApprovalTabNavigationDataDeprecated';

const APPROVALS_PROMOTION_KEY = 'approvals_promotion_dismissed';

enum FilterParams {
  ALL = 'all',
  ASSIGNED = 'assigned',
  MINE = 'mine',
}

const useTabKey = (currentUserId?: string) => {
  const history = useHistory();
  const params: DocumentTableQueryParams =
    qs.parse(history.location.search) || {};

  if (
    'approvers' in params &&
    currentUserId &&
    params['approvers']?.includes(currentUserId)
  ) {
    return FilterParams.ASSIGNED;
  }

  if (
    'requester' in params &&
    currentUserId &&
    params['requester']?.includes(currentUserId)
  ) {
    return FilterParams.MINE;
  }

  return FilterParams.ALL;
};

interface ActiveTabsProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
}

const ActiveTabs = (props: ActiveTabsProps) => {
  const state = useTabListState(props);

  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);

  const datev = useDatev(); // BDS-checked

  const [dismissed, setDismissed] = useLocalStorage(
    APPROVALS_PROMOTION_KEY,
    false
  );

  const showBdsPromotionModal =
    !datev.bdsBought &&
    !datev.hide &&
    !dismissed &&
    state.selectedKey === FilterParams.MINE;

  const { triggerProps, modalProps, isOpen } = useModal();

  const modalSource = ModalSource.APPROVALS_PAGE;

  const onClick = () => {
    setDismissed(true);
  };

  return (
    <Grid
      templateRows={showBdsPromotionModal ? 'auto auto 1fr' : 'auto 1fr'}
      flexGrow={1}
      overflow="hidden"
      rowGap="space24">
      <Tabs {...props} state={state} />
      {showBdsPromotionModal && !dismissed && (
        <Card background="purple100" paddingY="space12">
          <Grid
            templateColumns="1fr auto auto"
            alignItems="center"
            gap="space4">
            <Grid>
              <Text fontWeight="semibold" fontSize="large">
                {t('add-on.title')}
              </Text>
              <Text fontSize="basic">{t('add-on.description')} </Text>
            </Grid>
            <Button color="purple" {...triggerProps}>
              {t('add-on.button')}
            </Button>
            <Button
              icon="close"
              variant="tertiary"
              label={t('add-on.buttonTooltip')}
              size="small"
              onClick={onClick}></Button>
          </Grid>
          {isOpen ? (
            <BDSPromotionModal modalSource={modalSource} {...modalProps} />
          ) : null}
        </Card>
      )}
      <TabPanel
        key={state.selectedItem?.key}
        state={state}
        height="100%"
        overflow="hidden"
      />
    </Grid>
  );
};

export interface TabProps {
  key: FilterParams;
  title: string;
  badge?: string | undefined;
  children: React.ReactNode;
}

type Props = RouteComponentProps<{
  organizationSlug: string;
}>;

export const ApprovalsContainer = (props: Props) => {
  const enableNewIndexInApprovalsViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInApprovalView
  );

  return enableNewIndexInApprovalsViewFF ? (
    <ApprovalsComponent {...props} />
  ) : (
    <ApprovalsComponentDeprecated {...props} />
  );
};

const ApprovalsComponent = (props: Props) => {
  const {
    approvalDocumentsBasicsQueryResult,
    approvalsRequestedByCurrentUserCount,
    approvalsAssignedToCurrentUserCount,
    allDocumentsCount,
  } = useApprovalTabNavigationData();

  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);
  const currentUser = useCurrentUser();
  const activeKey = useTabKey(currentUser?.id);
  const history = useHistory();
  const params = useParams<{ organizationSlug: string }>();
  const { organizationSlug } = params;
  const { search } = history.location;

  const firstDocToApprove =
    approvalDocumentsBasicsQueryResult.data?.approvalInvoiceDocuments
      .edges?.[0];

  const searchWithArgs = appendParamsToQueryString(search, {
    cursor: firstDocToApprove?.cursor ?? null,
  });

  const startApprovingLink = `/${organizationSlug}${Routes.APPROVALS}/${firstDocToApprove?.node.id}?${searchWithArgs}`;

  const changeFilter = useCallback(
    (tab: ReactText) => {
      const currentFilterParams = qs.parse(search) || {};
      const filterParams = {
        ...currentFilterParams,
        approvers: tab === FilterParams.ASSIGNED ? currentUser?.id : undefined,
        requester: tab === FilterParams.MINE ? currentUser?.id : undefined,
      };

      history.push({
        search: qs.stringify(filterParams),
      });
    },
    [currentUser, search, history]
  );

  const tabs: TabProps[] = [
    {
      key: FilterParams.ASSIGNED,
      title: t('tabs.showAssignedToMe'),
      badge: approvalsAssignedToCurrentUserCount,
      children: <ApprovalsTableContainer {...props} />,
    },
    {
      key: FilterParams.MINE,
      title: t('tabs.showMonitoring'),
      badge: approvalsRequestedByCurrentUserCount,
      children: <ApprovalsTableContainer {...props} />,
    },
    {
      key: FilterParams.ALL,
      title: t('tabs.showAll'),
      badge: allDocumentsCount,
      children: <ApprovalsTableContainer {...props} />,
    },
  ];

  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  return (
    <Flex as="main" direction="column" minHeight="100%" overflow="hidden">
      <Flex
        direction="column"
        gap="space16"
        padding={
          mainNavigationRefactorFF
            ? 'space20 space32 space24 space32'
            : 'space40 space32 space24 space32'
        }
        overflow="hidden"
        height="100%">
        <Flex alignItems="center" gap="space16">
          <Heading as="h1">{t('title')}</Heading>
          <Button
            size="small"
            disabled={!firstDocToApprove}
            onClick={() => history.push(startApprovingLink)}>
            {t('actions.startApproving')}
          </Button>
        </Flex>
        <UserRoleAccessWrapper
          allowedRoles={[
            UserRole.Admin,
            UserRole.Requester,
            UserRole.Approver,
            UserRole.Accountant,
          ]}>
          <ActiveTabs
            aria-label={t('tabs.ariaLabel')}
            items={tabs}
            defaultSelectedKey={activeKey}
            selectedKey={activeKey}
            onSelectionChange={changeFilter}
            children={item => (
              <Item key={item.key} title={item.title} textValue={item.title}>
                {item.children}
              </Item>
            )}
          />
        </UserRoleAccessWrapper>
      </Flex>
    </Flex>
  );
};

/**
 * @deprecated
 */
const ApprovalsComponentDeprecated = (props: Props) => {
  const {
    approvalDocumentsBasicsQueryResult,
    approvalsRequestedByCurrentUserCount,
    approvalsAssignedToCurrentUserCount,
    allDocumentsCount,
  } = useApprovalTabNavigationDataDeprecated();

  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);
  const currentUser = useCurrentUser();
  const activeKey = useTabKey(currentUser?.id);
  const history = useHistory();
  const params = useParams<{ organizationSlug: string }>();
  const { organizationSlug } = params;
  const { search } = history.location;

  const firstDocToApprove =
    approvalDocumentsBasicsQueryResult.data?.listApprovalsDocuments.edges?.[0];

  const searchWithArgs = appendParamsToQueryString(search, {
    cursor: firstDocToApprove?.cursor ?? null,
  });

  const startApprovingLink = `/${organizationSlug}${Routes.APPROVALS}/${firstDocToApprove?.node.id}?${searchWithArgs}`;

  const changeFilter = useCallback(
    (tab: ReactText) => {
      const currentFilterParams = qs.parse(search) || {};
      const filterParams = {
        ...currentFilterParams,
        approvers: tab === FilterParams.ASSIGNED ? currentUser?.id : undefined,
        requester: tab === FilterParams.MINE ? currentUser?.id : undefined,
      };

      history.push({
        search: qs.stringify(filterParams),
      });
    },
    [currentUser, search, history]
  );

  const tabs: TabProps[] = [
    {
      key: FilterParams.ASSIGNED,
      title: t('tabs.showAssignedToMe'),
      badge: approvalsAssignedToCurrentUserCount,
      children: <ApprovalsTableContainer {...props} />,
    },
    {
      key: FilterParams.MINE,
      title: t('tabs.showMonitoring'),
      badge: approvalsRequestedByCurrentUserCount,
      children: <ApprovalsTableContainer {...props} />,
    },
    {
      key: FilterParams.ALL,
      title: t('tabs.showAll'),
      badge: allDocumentsCount,
      children: <ApprovalsTableContainer {...props} />,
    },
  ];

  return (
    <Flex as="main" direction="column" minHeight="100%" overflow="hidden">
      <Flex
        direction="column"
        gap="space16"
        padding="space40 space32 space24 space32"
        overflow="hidden"
        height="100%">
        <Flex alignItems="center" gap="space16">
          <Heading as="h1">{t('title')}</Heading>
          <Button
            size="small"
            disabled={!firstDocToApprove}
            onClick={() => history.push(startApprovingLink)}>
            {t('actions.startApproving')}
          </Button>
        </Flex>
        <UserRoleAccessWrapper
          allowedRoles={[
            UserRole.Admin,
            UserRole.Requester,
            UserRole.Approver,
            UserRole.Accountant,
          ]}>
          <ActiveTabs
            aria-label={t('tabs.ariaLabel')}
            items={tabs}
            defaultSelectedKey={activeKey}
            selectedKey={activeKey}
            onSelectionChange={changeFilter}
            children={item => (
              <Item key={item.key} title={item.title} textValue={item.title}>
                {item.children}
              </Item>
            )}
          />
        </UserRoleAccessWrapper>
      </Flex>
    </Flex>
  );
};
