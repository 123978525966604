import { useEffect } from 'react';

export interface ExtendedTableState {
  /**
   * Column resize information. This will only have a value if ReactTable.useResizeColumns is being used
   */
  columnResizing?: {
    startX?: number | undefined;
    columnWidth: number;
    headerIdWidths: any;
    columnWidths: any;
    isResizingColumn?: string | undefined;
  };
}

/**
 * This hook invokes a callback function when the resizing of a column is terminated.
 */
export const useResizeObserver = (
  state: ExtendedTableState,
  callback: (columnId: string, columnSize: number) => void
) => {
  useEffect(() => {
    // We are interested in calling the resize event only when "state.columnResizing?.isResizingColumn" changes from
    // a string to undefined, because it indicates that it WAS resizing but it no longer is.
    if (state.columnResizing && !state.columnResizing?.isResizingColumn) {
      if (Array.isArray(state.columnResizing?.headerIdWidths)) {
        const headerIdWidths = state.columnResizing?.headerIdWidths as Array<
          [string, number]
        >;

        const header = headerIdWidths?.[0];

        const headerId = header?.[0];

        callback(headerId, state.columnResizing.columnWidths[headerId]);
      }
    }
  }, [callback, state.columnResizing, state.columnResizing?.isResizingColumn]);
};
