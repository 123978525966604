import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { Dispatch, ReactNode, SetStateAction, memo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { CSSProp } from 'styled-components';
import { PDFDetails } from '../utils';
import { CollaboratorsContainer } from './Collaborators/CollaboratorsContainer';
import { PdfViewerError } from './PdfViewerError';
import { PdfHeaderProps } from './ReactPdf/PdfHeader';
import { ReactPdf } from './ReactPdf/ReactPdf';

export interface PdfViewerProps {
  attachments?: PDFDetails[];
  attachmentSection?: ReactNode;
  documentFile: PDFDetails; // @TODO TGE-51 simplify how we pass and use files with PdfViewer and Attachments
  selectedFile?: PDFDetails;
  /** Handler function that is invoked whenever we select a different file to show,
   * e.g. selecting/clicking on different attachment and/or main file.
   *
   * Commonly we pass `setSelectedPdf` from `useAttachments` here.
   */
  onSelectDocument?: Dispatch<SetStateAction<PDFDetails>>;
  mobileAppPromo?: ReactNode;
  documentId?: string;
  disabledControls?: PdfHeaderProps['disabledControls'];
  pageCss?: CSSProp;
  initialScale?: number;
  maxHeight?: string;
  pdfWrapperId?: string;
  pdfControlsWrapper?: PdfHeaderProps['pdfControlsWrapper'];
}

export const PdfViewer = memo((props: PdfViewerProps) => {
  return (
    <Sentry.ErrorBoundary fallback={<PdfViewerError />}>
      <ReactPdf
        wrapper={props.documentFile ? CollaboratorsContainer : undefined}
        {...props}
      />
    </Sentry.ErrorBoundary>
  );
}, isEqual);
