import { Grid, Icon, TruncatedText, Text } from '@candisio/design-system';
import {
  AvatarStatus,
  AvatarWithStatus,
} from 'components/AvatarWithStatus/AvatarWithStatus';
import { User } from 'generated-types/graphql.types';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

export interface UserDropdownItemProps {
  name: string;
  avatarUrl?: string;
  absence?: {
    fromDate?: Date;
    toDate?: Date;
    status?: AvatarStatus;
    note?: string;
    substitute?: Pick<User, 'name' | 'avatarUrl'>;
  };
}

export const UserDropdownItem = ({
  name,
  absence,
  avatarUrl,
}: UserDropdownItemProps) => {
  const [t] = useTranslation();

  const nameText = <TruncatedText color="gray800">{name}</TruncatedText>;

  const avatar = (
    <AvatarWithStatus
      hasTooltip={false}
      name={name}
      img={avatarUrl}
      status={absence?.status}
      size="small"
    />
  );

  const userIsAbsent = isAbsent(absence);

  // No absence
  if (!userIsAbsent)
    return <DropdownItemWrapper avatar={avatar} label={nameText} />;

  const fromDate = formatAbsenceDate(absence.fromDate);

  const toDate = formatAbsenceDate(absence.toDate);
  const absenceDateText = t(getAbsenceDateText(absence.status), {
    fromDate,
    toDate,
  });

  // Absent without substitute
  if (userIsAbsent && !absence.substitute) {
    return (
      <DropdownItemWrapper
        avatar={avatar}
        label={
          <>
            {nameText}
            <Text color="gray500">{t('common.separatorSign')}</Text>
            <TruncatedText color="gray500">{absenceDateText}</TruncatedText>
          </>
        }
        description={absence.note}
      />
    );
  }

  // Absent with substitute
  return (
    <>
      {absence.substitute && (
        <DropdownItemWrapper
          avatar={avatar}
          label={
            <>
              {nameText}
              <Icon icon="arrowRight" size="space16" color="gray800" />
              <TruncatedText color="gray800">
                {absence.substitute.name}
              </TruncatedText>
            </>
          }
          description={absenceDateText}
        />
      )}
    </>
  );
};

const isAbsent = (
  absence?: UserDropdownItemProps['absence']
): absence is NonNullable<UserDropdownItemProps['absence']> => {
  return Boolean(absence) && Boolean(absence?.status);
};

const formatAbsenceDate = (date: Date | undefined) =>
  date ? DateFormatters.compact(new Date(date)) : undefined;

const getAbsenceDateText = (absenceStatus: AvatarStatus | undefined) =>
  absenceStatus === 'AWAY_SOON'
    ? 'absence.approvers.timePeriod.AWAY_SOON'
    : 'absence.approvers.timePeriod.AWAY';

interface DropdownItemWrapperProps {
  avatar: ReactNode;
  label: ReactNode;
  description?: string;
}

const DropdownItemWrapper = ({
  avatar,
  label,
  description,
}: DropdownItemWrapperProps) => {
  return (
    <Grid gap="space8" templateColumns="auto 1fr" alignItems="center">
      {avatar}
      <Grid fontSize="basic">
        <Grid
          gap="space4"
          autoFlow="column"
          alignItems="center"
          justifyContent="start"
          lineHeight="heading">
          {label}
        </Grid>
        {description && (
          <TruncatedText color="gray500" lineHeight="paragraph">
            {description}
          </TruncatedText>
        )}
      </Grid>
    </Grid>
  );
};
