import React from 'react';

import { LayoutProps, StandardHTMLAttributes } from '../../types';

import { Box } from '../Box';

export interface ImageProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLImageElement> {
  /**
   * Please provide a text description of the image. The alt text **must be**
   * translated into the user’s language.
   *
   * Alternatively, use an empty string `""` to indicate that the image does not
   * convey any specific meaning and is purely decorative.
   */
  alt: string;
  /** Image source URL */
  src: string;
}

/**
 * Behaves exactly like <img> element, but exposes the design system’s layout props
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-media-image}
 */
export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ({ alt, src, ...restProps }, ref) => {
    return <Box as="img" alt={alt} src={src} {...restProps} ref={ref} />;
  }
);
