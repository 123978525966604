import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { netToGross } from 'containers/SplitBookings/toolkit/utils';
import { useCallback } from 'react';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { BookingItem } from 'views/Reimbursement/hooks/useReimbursementSplitBookingsData';
import { BookingValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';

export const calculateSumOfSplitAmountsWithNetValues = (
  bookings?: BookingValues[]
) => {
  return (bookings ?? [])?.reduce(
    (accumulatedSum, { vatRate, splitAmount, taxPresentation }) => {
      const calculatedAmount =
        taxPresentation === TaxPresentation.Gross
          ? splitAmount
          : netToGross(splitAmount ?? 0, vatRate ?? 0);

      return roundToCurrencyPrecision(accumulatedSum + calculatedAmount);
    },
    0
  );
};

export const useRemainingAmount = () => {
  const calculateRemainingAmount = useCallback(
    (grossAmount: number, bookings?: BookingValues[]) => {
      if (!bookings?.length) return grossAmount;

      const sumOfSplitAmounts =
        calculateSumOfSplitAmountsWithNetValues(bookings);

      const remainingAmount =
        roundToCurrencyPrecision(grossAmount - sumOfSplitAmounts) ?? 0;

      return remainingAmount;
    },
    []
  );

  return { calculateRemainingAmount };
};

export const calculateSumOfBookingsSplitAmounts = (
  bookings: (BookingValues | BookingItem)[]
): number => {
  return (bookings ?? []).reduce(
    (sum, { splitAmount }) =>
      roundToCurrencyPrecision(sum + (splitAmount ?? 0)),
    0
  );
};
