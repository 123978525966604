import { Box, defaultTheme, Flex, Grid, Icon } from '@candisio/design-system';
import { HTMLAttributes } from 'react';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

interface HistoryItemProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  dot?: React.ReactNode;
  label?: React.ReactNode;
  isCollapsible?: boolean;
  opened?: string;
  hideTail?: boolean;
}

const StyledGrid = styled(Grid)<HistoryItemProps>`
  grid-template-columns: ${defaultTheme.space.space16} ${defaultTheme.space
      .space48} 1fr;
  transition: background-color 0.3s;

  &[active='true'] {
    background-color: ${defaultTheme.colors.gray0} !important;
  }

  &[active='false']:hover {
    background-color: ${defaultTheme.colors.gray50} !important;
  }

  ${p =>
    (p.isCollapsible || p.onClickCapture) &&
    `
      border-radius: ${defaultTheme.radii.medium};
      cursor: pointer;
    `}
`;

export const HistoryItem = ({
  active,
  dot,
  label,
  isCollapsible,
  opened,
  hideTail,
  onClickCapture,
  ...rest
}: HistoryItemProps) => {
  return (
    <StyledGrid
      active={active}
      isCollapsible={isCollapsible}
      onClickCapture={onClickCapture}>
      {isCollapsible && (
        <Box paddingTop={12}>
          {opened ? (
            <Icon size="space16" icon="caretUp" color="blue500" />
          ) : (
            <Icon size="space16" icon="caretDown" color="gray600" />
          )}
        </Box>
      )}
      <Flex
        style={{ height: '100%', marginTop: 12 }}
        paddingRight={16}
        gridColumnStart={2}
        direction="column"
        alignItems="center">
        <Box>{dot}</Box>
        {!hideTail && (
          <Box
            width={0}
            borderRight={`1px dashed ${defaultTheme.colors.gray400}`}
            height="100%"
          />
        )}
      </Flex>
      <Box paddingY={12} wordBreak="break-word" {...rest} />
    </StyledGrid>
  );
};
