import { DocumentCurrency } from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { formatDecimalToIntegerAmount } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { ExpenseSchemaOutput } from '../toolkit/expensesFormSchema';

export const sanitizeHospitalityFormInput = (values: ExpenseSchemaOutput) => {
  if (values.expenseType === 'hospitality') {
    return {
      fileIds: Array.isArray(values.files)
        ? values.files.map(file => file.id)
        : [],
      expenseDate: getDateConverted().dateStringToDateTimeString(
        values.dayOfExpense
      ),
      location: values.location,
      title: values.reason,
      externalGuests: values.externalGuests ? [values.externalGuests] : null,
      internalGuests: values.internalGuests ? [values.internalGuests] : null,
      receiptAmount: !isNil(values.receiptAmount)
        ? {
            amount: formatDecimalToIntegerAmount(values.receiptAmount),
            currency: DocumentCurrency.Eur,
            precision: 2,
          }
        : null,
      tipAmount: !isNil(values.tipAmount)
        ? {
            amount: formatDecimalToIntegerAmount(values.tipAmount),
            currency: DocumentCurrency.Eur,
            precision: 2,
          }
        : null,
    };
  }
};
