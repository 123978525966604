import { FilterOptionsAccessor } from '@candisio/design-system';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountingAreasList } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasList';
import { useStatusFilters } from './consts';

interface UseApprovalDocumentsDataFiltersProps {
  approvalsTableData: DocumentsTableData[];
}

export const useApprovalDocumentsDataFilters = ({
  approvalsTableData,
}: UseApprovalDocumentsDataFiltersProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const statusFilters = useStatusFilters();

  const {
    allAccountingAreasListData,
    listDataLoading: accountingAreasLoading,
  } = useAccountingAreasList();

  const [enableNewIndexInApprovalsViewFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.enableNewIndexInApprovalView,
  ]);

  const accountingAreas = useMemo(() => {
    return allAccountingAreasListData?.map(area => ({
      id: area.id,
      label: area.name,
    }));
  }, [allAccountingAreasListData]);

  const filterOptions: FilterOptionsAccessor<DocumentsTableData> =
    useMemo(() => {
      return {
        tags: enableNewIndexInApprovalsViewFF,
        status: {
          data: statusFilters?.map(statusFilter => ({
            id: statusFilter.id,
            label: t(statusFilter.label),
          })),
        },
        accountingArea: {
          data: accountingAreas,
          isLoading: accountingAreasLoading,
        },
        creditCardPayment: {
          data: [
            {
              id: 'true',
              label: t('creditCardPaymentFilter.creditCardPayment'),
            },
            {
              id: 'false',
              label: t('creditCardPaymentFilter.noCreditCardPayment'),
            },
          ],
        },
      };
    }, [
      statusFilters,
      t,
      accountingAreas,
      accountingAreasLoading,
      enableNewIndexInApprovalsViewFF,
    ]);

  return {
    filterOptions,
    filtersLoading: accountingAreasLoading,
  };
};
