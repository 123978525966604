import { Box, Card, Grid, Skeleton } from '@candisio/design-system';
import { LoadingExpenseSection } from './components/MiddleSection/LoadingExpenseSection';

interface MiddleSectionSkeletonProps {
  isItemsViewActive: boolean;
}

export const MiddleSectionSkeleton = ({
  isItemsViewActive,
}: MiddleSectionSkeletonProps) => {
  if (isItemsViewActive) {
    return (
      <Grid height="100%">
        <Grid
          height="100%"
          paddingTop="40.5px"
          paddingLeft="space16"
          paddingRight="space8">
          <Box>
            <Card
              background="gray100"
              padding="space16 space40"
              borderBottom="1px solid gray250"
              borderBottomRadius="none">
              <Grid gap="space4">
                <Grid
                  templateColumns="2fr 1.5fr"
                  gap="space16"
                  alignItems="center">
                  <Skeleton height="30px" width="200px" />
                  <Skeleton height="30px" width="100px" justifySelf="end" />
                </Grid>
              </Grid>
            </Card>
            <Card
              borderTopRadius="none"
              background="gray50"
              padding="space24 space40">
              <Grid gap="space24">
                <Grid gap="space8" columns={3}>
                  <Skeleton height="30px" width="auto" />
                  <Skeleton height="30px" width="auto" />
                  <Skeleton height="30px" width="auto" />
                </Grid>
                <Skeleton width="auto" height="100px" />
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      height="100%"
      paddingTop="40.5px"
      paddingLeft="space16"
      paddingRight="space8">
      <LoadingExpenseSection />
    </Grid>
  );
};
