import { useGetCountOfWorkflowTemplatesByApproverIdLazyQuery } from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';

interface UseWorkflowTemplatesCountProps {
  approverId: string;
}

export const useWorkflowTemplatesCount = ({
  approverId,
}: UseWorkflowTemplatesCountProps) => {
  const [
    fetchGetCountOfWorkflowTemplates,
    { data: workflowTemplatesData, loading, previousData },
  ] = useGetCountOfWorkflowTemplatesByApproverIdLazyQuery({
    variables: { approverId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
  });

  useFetchOnWindowFocus({
    fetch: fetchGetCountOfWorkflowTemplates,
  });

  const data = !loading
    ? workflowTemplatesData?.getCountOfWorkflowTemplatesByApproverId
    : previousData?.getCountOfWorkflowTemplatesByApproverId;

  const workflowTemplatesCount = {
    active: data?.active ?? 0,
    archived: data?.archived ?? 0,
    all: data?.all ?? 0,
  };

  return {
    workflowTemplatesCount,
    workflowTemplatesCountLoading:
      loading && typeof previousData === 'undefined',
  };
};
