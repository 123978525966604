import { Text } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { ExportEntity } from 'views/Integrations/Export/types';
import { ExportSummaryTitle } from '../../../components/ExportSummaryTitle';
import { SummaryAccordionCard } from '../../../components/SummaryAccordionCard';
import { parseDocumentExportStatus } from '../../../utils';

interface ReimbursementItemsSummaryProps {
  reimbursementItems: ExportEntity[];
  loading?: boolean;
}

export const ReimbursementItemsSummary = ({
  reimbursementItems,
  loading,
}: ReimbursementItemsSummaryProps) => {
  const [t] = useTranslation();

  const reimbursementItemsCounts = {
    exported: reimbursementItems.filter(
      item =>
        parseDocumentExportStatus(item.exportStatus) === ExportStatus.Exported
    ).length,
    partiallyExported: reimbursementItems.filter(
      item =>
        parseDocumentExportStatus(item.exportStatus) ===
        ExportStatus.PartialyExported
    ).length,
    failed: reimbursementItems.filter(
      item =>
        parseDocumentExportStatus(item.exportStatus) === ExportStatus.Failed
    ).length,
  };

  const reimbursementItemsSummary = [
    ...(reimbursementItemsCounts.exported
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.reimbursementItemsCount', {
                  count: reimbursementItemsCounts.exported,
                })}
                status={ExportStatus.Exported}
              />
            ),
            content: '',
          },
        ]
      : []),
    ...(reimbursementItemsCounts.partiallyExported
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.reimbursementItemsCount', {
                  count: reimbursementItemsCounts.partiallyExported,
                })}
                status={ExportStatus.PartialyExported}
              />
            ),
            content: '',
            footer: (
              <Text fontSize="small" color="yellow700">
                {t('export.exportHistorySummary.partiallyExported')}
              </Text>
            ),
          },
        ]
      : []),
    ...(reimbursementItemsCounts.failed
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.reimbursementItemsCount', {
                  count: reimbursementItemsCounts.failed,
                })}
                status={ExportStatus.Failed}
              />
            ),
            content: '',
            footer: (
              <Text fontSize="small" color="red700">
                {t('export.exportHistorySummary.failed')}
              </Text>
            ),
          },
        ]
      : []),
  ];

  const totalAccount = reimbursementItems.length;

  return totalAccount > 0 ? (
    <SummaryAccordionCard
      items={reimbursementItemsSummary}
      loading={loading}
      collapsable={false}
    />
  ) : null;
};
