import { Heading } from '@candisio/design-system';
import { DocumentAccessCard } from 'components/DocumentAccess/DocumentAcccessCard';
import { useDocumentAccessLevelsData } from 'components/DocumentAccess/useDocumentAccessLevelsData';
import { useTranslation } from 'react-i18next';

interface SensitiveContractAccessCardContainerProps {
  globalDocumentId: string;
}

export const SensitiveContractAccessCardContainer = ({
  globalDocumentId,
}: SensitiveContractAccessCardContainerProps) => {
  const { accessDataForSensitiveDocuments, loading } =
    useDocumentAccessLevelsData({
      globalDocumentId,
    });

  const [t] = useTranslation();

  return (
    <DocumentAccessCard
      accessData={accessDataForSensitiveDocuments}
      loading={loading}
      title={
        <Heading as="h3">
          {t('common:documentAccess.membership.access.contracts.title')}
        </Heading>
      }
    />
  );
};
