import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { UsePurchaseOrderFieldOptionsResult } from 'components/PurchaseOrders/hooks/usePurchaseOrderNumberFieldOptions';
import { useHover } from 'react-aria';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmptyListState } from './EmptyListState';
import { getFormattedPurchaseOrderItem } from './getFormattedPurchaseOrderItem';
import { PurchaseOrderDropdownMenu } from './PurchaseOrderDropdownMenu';

export interface PurchaseOrderFieldProps {
  contactName?: string;
  fieldOptions?: UsePurchaseOrderFieldOptionsResult;
  accountsPayableNumber?: string;
  disabled?: boolean;
}

export const PurchaseOrderListField = ({
  contactName,
  fieldOptions,
  accountsPayableNumber,
  disabled,
}: PurchaseOrderFieldProps) => {
  const [t] = useTranslation();
  const { isHovered, hoverProps } = useHover({});
  const form = useForm();

  const { items, isLoading, disabledKeys } = fieldOptions?.props || {};
  const linkPurchaseOrder = fieldOptions?.linkPurchaseOrder;

  const handlePurchaseOrderSelection = (value: string | null) => {
    const orderNumber = items?.find(item => item.key === value)?.orderNumber;
    if (value) {
      void linkPurchaseOrder?.(value, orderNumber);
    }
  };

  return (
    <FormProvider {...form}>
      <HookFormPaginatedComboBoxField
        showSeparator
        name="purchaseOrderNumber"
        itemHeight="space64"
        loading={isLoading}
        emptyListPlaceholder={
          <EmptyListState hoverProps={hoverProps} contactName={contactName} />
        }
        label={t('document.requestApproval.inputs.purchaseOrderNumber.label')}
        placeholder={t(
          'document.requestApproval.inputs.purchaseOrderNumber.listPlaceholder'
        )}
        disabled={disabled || isLoading}
        {...fieldOptions?.props}
        items={getFormattedPurchaseOrderItem({ items })}
        persistDropdown={isHovered}
        renderCustomDropdown={listbox => (
          <PurchaseOrderDropdownMenu
            hoverProps={hoverProps}
            listbox={listbox}
            accountsPayableNumber={accountsPayableNumber}
          />
        )}
        onChange={val => {
          handlePurchaseOrderSelection(val);
        }}
        disabledKeys={disabledKeys}
      />
    </FormProvider>
  );
};
