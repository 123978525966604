import { useCreateCommentOnReimbursement } from 'views/Reimbursement/hooks/useCreateCommentOnReimbursement';
import { Comment } from 'components/Comment/Comment';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ReimbursementCaseStatus, User } from 'generated-types/graphql.types';
import { useOrganizationMemberships } from 'views/Reimbursement/hooks/useOrganizationMemberships';
import { isOnlyApprover } from 'utils/authorization';

const DISABLED_MENTIONS_STATUSES = [
  ReimbursementCaseStatus.Draft,
  ReimbursementCaseStatus.Check,
];

interface ReimbursementCommentContainerProps {
  reimbursement?: Reimbursement;
}

export const ReimbursementCommentContainer = ({
  reimbursement,
}: ReimbursementCommentContainerProps) => {
  const { onCreateComment, isCreatingComment } =
    useCreateCommentOnReimbursement();
  const { organizationMemberships, loading } = useOrganizationMemberships({
    status: 'Active',
  });

  const reimbursementCreatedById = reimbursement?.createdByMembership.id;
  const status = reimbursement?.status;

  const cannotMentionUsers = status
    ? DISABLED_MENTIONS_STATUSES.includes(status)
    : false;

  const mentionableMemberships = organizationMemberships.filter(
    member =>
      !isOnlyApprover(member as User) || member.id === reimbursementCreatedById
  );

  const memberships = cannotMentionUsers ? [] : mentionableMemberships;

  return (
    <Comment
      memberships={memberships}
      loadingMemberships={loading}
      onCreateComment={onCreateComment}
      isSubmitting={isCreatingComment}
    />
  );
};
