import { defaultTheme } from '@candisio/design-system';
import { AddNewActionBox } from 'components/AddNewActionBox/AddNewActionBox';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface AddContractTypeActionBoxProps {
  onPointerDown: () => void;
}

export function AddContractTypeActionBox({
  onPointerDown,
}: AddContractTypeActionBoxProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  return (
    <AddNewActionBox
      borderTop={`1px solid ${defaultTheme.colors.gray250}`}
      borderTopRadius="0">
      <AddNewActionBox.Content>
        <AddNewActionBox.Header>
          <AddNewActionBox.HeaderTag>
            {t('common:tag.new')}
          </AddNewActionBox.HeaderTag>
          <AddNewActionBox.HeaderText>
            {t('storageForm.fields.contractType.addNew.title')}
          </AddNewActionBox.HeaderText>
        </AddNewActionBox.Header>
        <AddNewActionBox.Message>
          {t('storageForm.fields.contractType.addNew.subtitle')}
        </AddNewActionBox.Message>
      </AddNewActionBox.Content>
      <AddNewActionBox.Button onPointerDown={onPointerDown}>
        {t('storageForm.fields.contractType.addNew.button')}
      </AddNewActionBox.Button>
    </AddNewActionBox>
  );
}
