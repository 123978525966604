import { Grid, InlineSkeleton, Item, ListView } from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCallback, useMemo } from 'react';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { BookingItem } from 'views/Reimbursement/hooks/useReimbursementSplitBookingsData';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { calculateSumOfBookingsSplitAmounts } from './hooks/useFormatAmountHelpers';
import { ReimbursementItemSplitBookingItem } from './ReimbursementItemSplitBookingItem';
import { ReimbursementSplitActionButton } from './ReimbursementSplitAction';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';

export interface ReimbursementItemSplitBookingsListProps {
  status?: ReimbursementCaseStatus;
  isLoading: boolean;
  totalAmount: number;
  currency: DocumentCurrency;
  entryIndex: number;
  title: string;
  reimbursementItemId: string;
  bookings: BookingItem[];
}

export const ReimbursementItemSplitBookingsList = ({
  status,
  isLoading,
  totalAmount,
  currency,
  entryIndex,
  bookings,
  title,
  reimbursementItemId,
}: ReimbursementItemSplitBookingsListProps) => {
  const { updateSearchParam } = useMutateSearchParams();
  const {
    updateActiveBooking,
    setDrawerView,
    formMethods,
    getSplitBookingsFormRules,
  } = useReimbursementSplitBookingHelpers();
  const { setSelectedItemId } = useReimbursementFormsContext();

  const {
    formState: { errors },
  } = formMethods;

  const sumOfBookingsSplitAmounts = useMemo(
    () => calculateSumOfBookingsSplitAmounts(bookings),
    [bookings]
  );

  const { actionRules } = getSplitBookingsFormRules(status);
  const remainingAmount = totalAmount - sumOfBookingsSplitAmounts;
  const hasBookings = (bookings ?? [])?.length > 0;
  const hasRemainingAmount = Boolean(remainingAmount);
  const { canAddAndDeleteSplits } = actionRules;

  const bookingErrors =
    errors.reimbursementItemBookings?.[entryIndex]?.bookings ?? [];

  const navigateToBooking = useCallback(
    (bookingId: string) => {
      updateActiveBooking({ title, entryIndex, bookingId });
      setDrawerView('split-bookings');
      updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
      setSelectedItemId(reimbursementItemId);
    },
    [
      entryIndex,
      reimbursementItemId,
      setDrawerView,
      setSelectedItemId,
      title,
      updateActiveBooking,
      updateSearchParam,
    ]
  );

  const handleItemClick = useCallback(
    (bookingId: string) => {
      navigateToBooking(bookingId);
    },
    [navigateToBooking]
  );

  const handleSplitDocumentClick = useCallback(() => {
    const previousBooking = bookings[bookings.length - 1];
    navigateToBooking(previousBooking.id);
  }, [bookings, navigateToBooking]);

  return (
    <InlineSkeleton isLoading={isLoading} isBlockLevel>
      <Grid borderRadius="medium" overflow="hidden">
        <Grid
          borderBottom={
            hasBookings
              ? `1px solid ${!hasRemainingAmount ? 'gray200' : undefined}`
              : undefined
          }>
          <ListView>
            {(bookings ?? []).map((booking, bookingIndex) => (
              <Item key={String(bookingIndex)} textValue={booking.id}>
                <ReimbursementItemSplitBookingItem
                  bookingId={booking?.id}
                  key={booking.id}
                  bookingIndex={bookingIndex}
                  onEditSplit={() => {
                    handleItemClick(booking.id);
                  }}
                  isReadOnly={!canAddAndDeleteSplits}
                  costCenter={booking.costCenter}
                  costObject={booking.costObject}
                  splitAmount={booking.splitAmount}
                  vatRate={booking.vatRate}
                  currency={currency}
                  hasBookingError={!!bookingErrors[bookingIndex]}
                />
              </Item>
            ))}
          </ListView>
        </Grid>
        <ReimbursementSplitActionButton
          currency={currency}
          hasItems={hasBookings}
          readOnly={!canAddAndDeleteSplits}
          remainingAmount={remainingAmount}
          onSplitDocument={handleSplitDocumentClick}
        />
      </Grid>
    </InlineSkeleton>
  );
};
