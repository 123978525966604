import { Grid, Heading } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ReimbursementDashboardLayoutProps {
  children: ReactNode;
}

export const ReimbursementDashboardLayout = ({
  children,
}: ReimbursementDashboardLayoutProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  return (
    <Grid
      templateRows="auto 1fr .45fr"
      height="100%"
      overflow="hidden"
      paddingLeft="space32"
      paddingRight="space16"
      paddingTop={mainNavigationRefactorFF ? 'space20' : 'space40'}
      gap="space16">
      <Heading as="h1">{t('dashboard.header')}</Heading>
      <Grid
        gap="space20"
        background="gray200"
        templateColumns="300px repeat(2, minmax(350px, 1fr))">
        {children}
      </Grid>
    </Grid>
  );
};
