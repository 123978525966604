import { Grid, Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { ReimbursementCaseTitleUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ReimbursementCaseTitleUpdatedEvent = ({
  properties,
}: ReimbursementCaseTitleUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid>
      <Text>{t('timeline.oneFieldChanged')}</Text>
      <MetaDataWrapper>
        <div>
          <Text fontWeight="regular" color="gray500">
            {t('reimbursementView.rightSection.form.title.label')}
          </Text>
          <Paragraph>
            {properties.newTitle ? properties.newTitle : t('timeline.empty')}
          </Paragraph>
        </div>
      </MetaDataWrapper>
    </Grid>
  );
};
