import {
  ExportStatus,
  useExportNotificationsQuery,
} from 'generated-types/graphql.types';
import { usePolling } from 'hooks/usePolling';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportNotificationsContent } from './ExportNotificationsContent/ExportNotificationsContent';

export const ExportNotifications = ({ onClose }: { onClose: () => void }) => {
  const {
    clearExports,
    updateExport,
    notifications: allNotifications,
  } = useContext(ExportNotificationsContext);

  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();

  const notifications = useMemo(
    () =>
      allNotifications.filter(
        ({ organization }) => organizationSlug === organization
      ),
    [allNotifications, organizationSlug]
  );

  const activeHashes = useMemo(
    () =>
      notifications
        .filter(({ status }) => status === ExportStatus.Exporting)
        .map(({ hash }) => hash),
    [notifications]
  );

  // main notifications query to track user exports
  const { data, startPolling, stopPolling } = useExportNotificationsQuery({
    variables: { input: { hash: activeHashes } },
    fetchPolicy: 'network-only',
    skip: activeHashes.length < 1,
    context: { skipLoading: true },
  });

  usePolling({
    pollInterval: 3000,
    // when have at least one processing export, we should start tracking it
    skip: activeHashes.length < 1,
    startPolling,
    stopPolling,
  });

  // update active exports by statuses from notifications query
  useEffect(() => {
    data?.exports
      ?.filter(({ hash }) => hash && activeHashes.includes(hash))
      .forEach(({ hash, id, status }) => {
        const notification = notifications.find(n => n.hash === hash);
        const errorDetails =
          status === ExportStatus.Failed
            ? t('exports.genericError')
            : status === ExportStatus.PartialyExported
            ? t('exports.genericWarning')
            : undefined;

        if (hash && status && notification?.status !== status) {
          updateExport({
            hash,
            id,
            status,
            errorDetails,
          });
        }
      });
  }, [data?.exports, notifications, activeHashes, t, updateExport]);

  return (
    <ExportNotificationsContent
      exportItems={notifications}
      onClose={onClose}
      onClear={clearExports}
    />
  );
};
