import { defaultTheme } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { ApprovalStatus } from 'views/Inbox/models';

export const ItemApprovalStatus = styled.p<{ status: ApprovalStatus }>`
  display: flex;
  align-items: center;
  margin: 0;
  padding-bottom: 5px;
  color: ${p =>
    p.status === ApprovalStatus.APPROVED
      ? defaultTheme.colors.green500
      : p.status === ApprovalStatus.DECLINED
      ? defaultTheme.colors.red500
      : defaultTheme.colors.gray600};
`;

export const RejectedIcon = styled.img`
  margin-right: 5px;
  margin-top: 0px;
`;

export const ItemSuccessStatus = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  color: ${defaultTheme.colors.green500};
`;

export const CommentView = styled.div<{
  ['data-show-document-relations']?: boolean;
}>`
  white-space: pre-line;
  display: inline-block;
  margin-top: 4px;
  background-color: ${defaultTheme.colors.gray100};
  border: 1px solid ${defaultTheme.colors.gray250};
  color: ${defaultTheme.colors.gray800};
  padding: 8px;
  border-radius: 0.375rem;
  position: relative;
  font-weight: normal;
  font-size: ${defaultTheme.fontSizes.basic};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
`;

export const OrderList = styled.ol`
  padding-left: 1rem;
`;
