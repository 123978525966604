import { FieldPolicy, TypePolicies } from '@apollo/client';
import { InboxInvoiceDocumentsResponse } from 'generated-types/resolvers-types';
import { paginationWithRecordConnectionsMergeNoNodes } from '../pagination-results-merge';

const inboxInvoiceDocumentsPagination: FieldPolicy<InboxInvoiceDocumentsResponse> =
  {
    merge: paginationWithRecordConnectionsMergeNoNodes(
      'InboxInvoiceDocumentsResponse'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      inboxInvoiceDocuments: inboxInvoiceDocumentsPagination,
    },
  },
};
