import { Box, Grid } from '@candisio/design-system';
import { Sidebar } from 'components/Sidebar/Sidebar';
import {
  useGetPayableAmountsQuery,
  useGetPayableDocumentsInfoQuery,
  usePaymentQuery,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { DateFormatters } from 'utils/date_formatter';
import { PaymentHistory, PaymentsHistoryQuery } from './PaymentHistory';
import { CreatePayment, Payment } from './PaymentSidebar';
import { PaymentTable } from './PaymentTable';
import { useGetPaymentsTableConfigs } from './PaymentTable/hooks/useGetPaymentsTableConfigs';
import { isDisabledRow } from './PaymentTable/tableUtils';
import { paymentsHistoryQuery } from './queries';
import { SimplifiedPayableDocument, SimplifiedPayment } from './types';
import { usePayableDocumentsListQuery } from './utils/usePayableDocumentsListQuery';

const PAYMENT_HISTORY_LIMIT = 10;

export interface PaymentsProps
  extends RouteComponentProps<{ organizationSlug: string }> {}

export const Payments = ({
  history,

  match: {
    path,
    params: { organizationSlug },
  },
}: PaymentsProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  const match = useRouteMatch<{ paymentId: string }>(`${path}/:paymentId`);
  const currentPaymentId = match?.params.paymentId;

  const [selectedDocuments, setSelectedDocuments] = useState<
    SimplifiedPayableDocument[]
  >([]);

  const [selectableDocuments, setSelectableDocuments] = useState<
    SimplifiedPayableDocument[]
  >([]);

  const [exportAll, setExportAll] = useState(false);

  useEffect(() => {
    if (selectedDocuments.length !== selectableDocuments.length) {
      setExportAll(false);
    }
  }, [selectedDocuments.length, selectableDocuments.length]);

  const { sortBy, onSort } = useUrlBasedSortAndFilter({ availableFilters: [] });

  const {
    availablePaymentsColumnIds,
    configurationsTable,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isResetPending,
    isSavingConfigurations,
  } = useGetPaymentsTableConfigs({ isPaid: !!currentPaymentId, sortBy });

  const {
    data: payableAmounts,
    loading: payableAmountsLoading,
    refetch: refetchPayableAmounts,
  } = useGetPayableAmountsQuery({
    fetchPolicy: 'no-cache',
    skip: currentPaymentId !== undefined,
  });

  const { data: payableDocumentsInfo, refetch: refetchCountPayableDocuments } =
    useGetPayableDocumentsInfoQuery({
      variables: { hasIban: true },
    });

  const payableCount =
    payableDocumentsInfo?.getPayableDocumentsInfo.euValidIbanDocumentsCount ||
    0;

  const {
    payableDocumentCount,
    payableDocuments: payableDocumentsPaginationData,
    isLoading: loadingPayableDocumentsPagination,
    refetch: refetchPayableDocuments,
    onLoadMore,
    hasMoreData,
  } = usePayableDocumentsListQuery({
    sortBy,
    onCompleted: data => {
      let documents: SimplifiedPayableDocument[] = (
        data.payableDocumentsPagination.edges || []
      ).map(
        record =>
          ({
            ...record.node,
            contact: record.node.contact.name.value,
          } as SimplifiedPayableDocument)
      );

      // revisit currency preselection when we do TCP-336 (Allow transfers to GB IBANS)
      const selectableDocs = documents.filter(
        doc => !isDisabledRow(doc) && doc.currency === 'EUR'
      );

      if (selectedDocuments.length === selectableDocuments.length) {
        // this means selectAll checkbox is checked
        setSelectedDocuments(selectableDocs);
      }

      setSelectableDocuments(selectableDocs);

      return Promise.all([
        refetchCountPayableDocuments(),
        refetchPayableAmounts(),
      ]);
    },
  });

  const { data: paymentData, loading: loadingPayment } = usePaymentQuery({
    variables: { id: currentPaymentId as string },
    skip: currentPaymentId === undefined,
  });

  const paymentsHistoryPaginationResponse = usePagination<PaymentsHistoryQuery>(
    paymentsHistoryQuery,
    'payments',
    {
      context: { skipLoading: true },
      variables: { limit: PAYMENT_HISTORY_LIMIT },
    }
  );

  const {
    data: paymentsHistoryData,
    refetch: refetchPaymentHistory,
    loading: paymentHistoryLoading,
  } = paymentsHistoryPaginationResponse;

  const hasProcessedPayment =
    (paymentsHistoryData?.payments.records || []).length > 0;

  const onSepaXmlExported = () => {
    setSelectedDocuments([]);

    return Promise.all([refetchPaymentHistory(), refetchPayableDocuments()]);
  };

  let documents: SimplifiedPayableDocument[] = (
    payableDocumentsPaginationData || []
  ).map(
    record =>
      ({
        ...record.node,
        contact: record.node.contact.name.value,
      } as SimplifiedPayableDocument)
  );

  const loadingDocuments = loadingPayableDocumentsPagination;

  const paymentTableLoading =
    loadingDocuments || loadingPayment || payableAmountsLoading;

  const totalCount = payableDocumentCount;

  const selectedCurrency = selectedDocuments[0]
    ? selectedDocuments[0].currency
    : undefined;

  let payment: SimplifiedPayment | undefined = undefined;
  if (currentPaymentId && paymentData?.payment) {
    const { paidDocuments, ...rest } = paymentData.payment;

    // flatten out the paid documents object
    payment = {
      ...rest,
      paidDocuments: paidDocuments.map(
        ({ documentId, creditor, ...others }) => ({
          ...others,
          id: documentId,
          contact: creditor.name,
        })
      ),
    };
  }

  const handlePaymentSelected = (paymentId: string | null) => {
    const specificPaymentPath = paymentId ? `/${paymentId}` : '';

    return history.push(
      `/${organizationSlug}${Routes.PAYMENTS}${specificPaymentPath}`
    );
  };

  const showSidebar = currentPaymentId !== undefined || totalCount > 0;

  let sidebarHeading: string | undefined = undefined;
  if (currentPaymentId === undefined) {
    sidebarHeading = tPayments('headers.newPayment');
  } else if (payment) {
    sidebarHeading = tPayments('headers.paymentSummary', {
      date: DateFormatters.compact(new Date(payment.creationDate)),
    });
  }

  const showLoadingMoreSpinner = payableDocumentCount > 1;

  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  return (
    <Box height="100%">
      <Grid
        templateColumns="minmax(640px,1fr) 350px"
        height="100%"
        overflow="hidden">
        <Grid
          gap="space24"
          templateColumns="320px 1fr"
          padding={
            mainNavigationRefactorFF
              ? 'space4 space32 space24 space32'
              : 'space24 space24 space24 space32'
          }
          overflow="hidden">
          <PaymentHistory
            paymentHistoryLoading={paymentHistoryLoading}
            selectedPaymentId={currentPaymentId}
            numberOfDocumentsToBePaid={
              !loadingDocuments ? totalCount : undefined
            }
            paginationResponse={paymentsHistoryPaginationResponse}
            onPaymentSelected={handlePaymentSelected}
          />
          <PaymentTable
            paymentDate={
              currentPaymentId && payment?.creationDate
                ? new Date(payment?.creationDate)
                : undefined
            }
            totalDocumentCount={payableDocumentCount}
            hasMoreData={hasMoreData}
            showLoadingMoreSpinner={showLoadingMoreSpinner}
            onLoadMore={onLoadMore}
            loading={
              paymentTableLoading || isSavingConfigurations || isResetPending
            }
            documents={
              currentPaymentId ? payment?.paidDocuments || [] : documents
            }
            selectedCurrency={selectedCurrency}
            selectedDocuments={selectedDocuments}
            hasProcessedPayment={hasProcessedPayment}
            setSelectedDocuments={setSelectedDocuments}
            selectableDocuments={selectableDocuments}
            isPaid={!!currentPaymentId}
            onSort={currentPaymentId === undefined ? onSort : undefined}
            exportAll={exportAll}
            setExportAll={setExportAll}
            payableCount={payableCount}
            columns={availablePaymentsColumnIds}
            configurationsTable={configurationsTable}
            onUpdateConfigurations={handleUpdateConfigurations}
            onResetConfigurations={handleResetTableConfigurations}
          />
        </Grid>

        {showSidebar && (
          <Sidebar heading={sidebarHeading}>
            {currentPaymentId === undefined &&
              (!payableAmountsLoading || payableAmounts) && (
                <CreatePayment
                  onSepaXmlExported={onSepaXmlExported}
                  selectedDocuments={selectedDocuments}
                  allDocumentsSelected={exportAll}
                  payableTotalAmount={
                    payableAmounts?.getPayableAmounts?.totalPayableAmount ??
                    undefined
                  }
                  discountTotalAmount={
                    payableAmounts?.getPayableAmounts?.discountedAmount ??
                    undefined
                  }
                  exportAll={exportAll}
                  totalPaymentsCount={totalCount}
                />
              )}
            {currentPaymentId !== undefined && payment && (
              <Payment payment={payment} />
            )}
          </Sidebar>
        )}
      </Grid>
    </Box>
  );
};
