import { DocumentsTableData } from 'components/DocumentsTable/types';
import { SortDirection } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';

export const columnIdsToAvailableGqlSort: Record<string, string> = {
  contact: 'contact',
  invoiceDate: 'invoiceDate',
  grossAmount: 'amount',
} as const;

export const mapExportTableSortToGqlSortInput = (
  sort: Array<SortingRule<DocumentsTableData>>
) => {
  if (!sort.length) {
    return;
  }

  const [firstSort] = sort;

  const columnIdToBEField = columnIdsToAvailableGqlSort[firstSort.id];

  if (!columnIdToBEField) {
    return;
  }

  const firstSortId = firstSort.id as keyof typeof columnIdsToAvailableGqlSort;

  return {
    field: columnIdsToAvailableGqlSort[firstSortId],
    order: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};
