import { Filename } from 'components/Filename/Filename';
import { ProgressMessage } from 'components/ProgressMessage/ProgressMessage';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { ExportStatus, ExportType } from 'generated-types/graphql.types';
import { ExportItem } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationItemContainer } from 'views/AppContainer/components/Header/components/Notifications/styles';
import { exportTypeTranslationMap } from 'views/AppContainer/components/Header/components/Notifications/utils';
import { indicatorStatusTranslationMap } from 'views/AppContainer/components/Header/components/ProgressIndicator';
import {
  mapExportIcon,
  mapExportStatus,
} from 'views/AppContainer/components/Header/utils';
import { useDefineExportView } from 'views/Integrations/Export/Manifest';
import { Sidebar, View } from 'views/Integrations/Export/types';

export const ExportNotificationItem = ({
  exportItem,
  onClose,
}: {
  exportItem: ExportItem;
  onClose: () => void;
}) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { id, status, errorDetails, documents, type, hash } = exportItem;

  const parsedStatus = mapExportStatus(status ?? ExportStatus.Failed);

  const viewDef = useDefineExportView({
    exportId: id,
    sidebar: Sidebar.HISTORY,
    view: View.HISTORY,
  });

  const exportHistoryLink = `/${organizationSlug}${viewDef.url}`;

  const getLabel = useCallback(
    ({ id, type, documents }: Omit<ExportItem, 'hash' | 'organization'>) => {
      if (
        type === ExportType.DatevDco ||
        type === ExportType.DatevDxso ||
        type === ExportType.Api
      )
        return;

      if (id) {
        return (
          <RouterLink onClick={onClose} to={exportHistoryLink}>
            <Filename>
              {t('notification.label', {
                documents,
                type: t(exportTypeTranslationMap[type]),
                count: documents,
              })}
            </Filename>
          </RouterLink>
        );
      }

      return (
        <Filename>
          {t('notification.label', {
            documents,
            type: t(exportTypeTranslationMap[type]),
            count: documents,
          })}
        </Filename>
      );
    },
    [exportHistoryLink, t, onClose]
  );

  return (
    <NotificationItemContainer key={hash}>
      <ProgressMessage
        state={parsedStatus}
        errorDetails={errorDetails}
        statusLabel={t(indicatorStatusTranslationMap[parsedStatus])}
        icon={mapExportIcon(type)}
        label={getLabel({ type, documents, id, status })}
      />
    </NotificationItemContainer>
  );
};
