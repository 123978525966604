import { CSSObject } from '@emotion/react';
import React from 'react';
import { VirtuosoContext } from '../../Molecules/Table';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box } from '../Box';

export interface TableBodyProps
  extends StandardHTMLAttributes<HTMLTableSectionElement>,
    VirtuosoContext,
    LayoutProps {}

/**
 * Wraps all components rendered into body of Table.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-data-table}
 */
export const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  TableBodyProps
>(({ children, context, ...restProps }, ref) => {
  const isResizable = context?.isResizable;
  const { colors, space, table } = useTheme();
  const baseStyle: CSSObject = {
    tr: {
      minWidth: isResizable ? '100%' : 'unset',
      borderBottom: `1px solid ${colors.gray200}`,
      background: table.tableBody.tr.background,
      ':hover': {
        background: table.tableBody.tr.hover.background,
      },
      '&[aria-readonly=true]': {
        ':hover': {
          background: table.tableBody.tr.background,
        },
      },
      '&[aria-disabled=true]': {
        background: table.tableBody.tr.disabled.background,
        cursor: 'not-allowed',
        ':hover': {
          background: table.tableBody.tr.disabled.hover.background,
        },
      },
    },
    th: {
      padding: `${space.space16} ${space.space24}`,
      fontWeight: table.tableBody.th.fontWeight,
      textAlign: 'left',
    },
    td: {
      display: isResizable ? 'inline-block' : undefined,
      boxSizing: isResizable ? 'border-box' : undefined,
      padding: `${space.space10} ${space.space16}`,
      fontWeight: table.tableBody.td.fontWeight,
    },
  };

  return (
    <Box as="tbody" css={baseStyle} ref={ref} {...restProps}>
      {children}
    </Box>
  );
});
