import { AvatarStack, Flex, Tag, Text } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { ActivityUser } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

interface RoleAccessCardProps {
  users?: ActivityUser[] | null;
  roleTranslationKey: string;
  accessTranslationKey: string;
}

export const DocumentAccessUsersRow = ({
  users,
  roleTranslationKey,
  accessTranslationKey,
}: RoleAccessCardProps) => {
  const [t] = useTranslation();

  if (!users?.length) {
    return null;
  }

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" gap="space8">
        <AvatarStack limit={6} size="small" tooltipMaxHeight="400px">
          {users.map(user => (
            <AvatarWithStatusContainer
              key={user.id}
              name={user.name}
              userId={user.id}
              img={user.avatarUrl ?? undefined}
            />
          ))}
        </AvatarStack>
        <Text>{t(roleTranslationKey)}</Text>
      </Flex>
      <Tag color="gray" variant="secondary">
        {t(accessTranslationKey)}
      </Tag>
    </Flex>
  );
};
