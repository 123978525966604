import { Color as TagColor } from '@candisio/design-system';
import { UpdateUserInput } from 'generated-types/resolvers-types';
import {
  CreateUserInput,
  UserRole,
} from '../../../generated-types/graphql.types';
import { RoutePath } from '../../../utils/path_generator';
import { TeamMemberFormOutput } from './TeamMemberDetails/containers/teamMemberFormSchema';
import { RouteParams, FormData } from './types';

type PathProps = {
  organizationSlug: string;
  path: string;
  url: string;
  userId?: string;
};

export const generateTeamMembersPath = ({
  organizationSlug,
  path,
  url,
  userId,
}: PathProps) => {
  const pathPattern = new RoutePath<RouteParams>(path);

  return pathPattern.stringify(
    {
      organizationSlug,
      userId,
    },
    url
  );
};

export const getUserInfo = ({
  firstName,
  lastName,
  email,
}: Pick<FormData, 'firstName' | 'lastName' | 'email'>) => {
  return `${firstName} ${lastName} (${email})`;
};

export const getAvailableRoles = ({
  isCreditCardsInUse,
}: {
  isCreditCardsInUse: boolean;
}) => {
  const roles = [UserRole.Admin, UserRole.Approver, UserRole.Requester];

  if (isCreditCardsInUse) {
    roles.push(UserRole.CardManager);
  }

  return roles;
};

export const roleMap: Record<
  UserRole,
  {
    color: TagColor;
    roleName: string;
    roleDescription: string;
    permissionsRequiredToChange: UserRole[];
  }
> = {
  [UserRole.Admin]: {
    color: 'blue',
    roleName: 'settings.teamMembers.roles.ADMIN.title',
    roleDescription: 'settings.teamMembers.roles.ADMIN.description',
    permissionsRequiredToChange: [UserRole.Admin],
  },
  [UserRole.Approver]: {
    color: 'green',
    roleName: 'settings.teamMembers.roles.APPROVER.title',
    roleDescription: 'settings.teamMembers.roles.APPROVER.description',
    permissionsRequiredToChange: [UserRole.Admin],
  },
  [UserRole.Requester]: {
    color: 'yellow',
    roleName: 'settings.teamMembers.roles.REQUESTER.title',
    roleDescription: 'settings.teamMembers.roles.REQUESTER.description',
    permissionsRequiredToChange: [UserRole.Admin],
  },
  [UserRole.CardManager]: {
    color: 'pink',
    roleName: 'settings.teamMembers.roles.CARD_MANAGER.title',
    roleDescription: 'settings.teamMembers.roles.CARD_MANAGER.description',
    permissionsRequiredToChange: [UserRole.CardManager, UserRole.Admin],
  },
  // Accountant role is not one of the roles returned by `getAvailableRoles`
  // and won't be available to select from the "legacy" invite user form.
  [UserRole.Accountant]: {
    color: 'yellow',
    roleName: '',
    roleDescription: '',
    permissionsRequiredToChange: [UserRole.Admin],
  },
  // Owner is not one of the roles returned by `getAvailableRoles`
  // and won't be available to select
  [UserRole.Owner]: {
    color: 'red',
    roleName: '',
    roleDescription: '',
    permissionsRequiredToChange: [UserRole.Admin],
  },
};

const rolesOrder = [
  UserRole.Admin,
  UserRole.CardManager,
  UserRole.Requester,
  UserRole.Approver,
];

export const orderRoles = (isCreditCardsInUse: boolean) => {
  const availableRoles =
    getAvailableRoles({
      isCreditCardsInUse,
    }) ?? [];

  const orderedRoles = [...(availableRoles || [])]?.sort(
    (a, b) => rolesOrder.indexOf(a) - rolesOrder.indexOf(b)
  );

  return orderedRoles;
};

export const sanitizeInviteFormData = (
  formValues: TeamMemberFormOutput
): CreateUserInput => {
  const roleIds = formValues.roles.map(role => role.id);

  const teamIds = formValues.teams ?? [];

  return {
    email: formValues.email,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    locale: formValues.locale,
    roles: [],
    roleIds,
    ...(teamIds.length > 0 ? { teamIds } : {}),
  };
};

export const sanitizeUpdateFormData = (
  formValues: Omit<TeamMemberFormOutput, 'email'>
): UpdateUserInput => {
  const roleIds = formValues.roles.map(role => role.id);

  return {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    locale: formValues.locale,
    roles: [],
    roleIds,
  };
};
