import {
  GeneralExpenseItem,
  ReimbursementError,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { File } from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { isNil } from 'lodash';
import { DateFormatters } from 'utils/date_formatter';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';

export interface GeneralExpense {
  id: string;
  reason?: string;
  type: ReimbursementItemType;
  status: ReimbursementItemStatus;
  createdAt: string;
  files: File[];
  totalAmount: number | null;
  location?: string;
  errors: ReimbursementError[];
  expenseDate?: string;
}

export const mapToGeneralExpense = (
  expense: Omit<GeneralExpenseItem, '__typename' | 'bookings'>
) => {
  const converter = getDateConverted();

  return {
    id: expense.id,
    errors: expense.errors,
    status: expense.status,
    type: ReimbursementItemType.GeneralExpense,
    reason: expense.title ?? undefined,
    createdAt: DateFormatters.compact(new Date(expense.createdAt)),
    expenseDate: expense.expenseDate
      ? converter.dateTimeStringToDateString(expense.expenseDate)
      : undefined,
    totalAmount: !isNil(expense.totalAmount?.amount)
      ? formatFromCents(
          expense.totalAmount.currency,
          expense.totalAmount.amount
        )
      : null,
    files: expense.files?.length
      ? expense.files.map(file => ({
          id: file.id,
          url: file.url,
          name: file.name,
        }))
      : [],
  } satisfies GeneralExpense;
};
