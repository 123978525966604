import { Flex, Text } from '@candisio/design-system';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { bytesToMB } from 'views/DocumentDownloadHistory/utils';
import { ImportHistoryItem } from '../HistoryList';
import { ImportStatus } from './ImportStatus';

interface FileDataProps {
  item: ImportHistoryItem;
  /** boolean only for Download Archive History list */
  isDownloadVariant?: boolean;
  /** boolean only for Contacts Import History list */
  isSapVariant?: boolean;
  /** boolean for Import History list */
  isImportHistory?: boolean;
}

export const FileData = ({
  item,
  isDownloadVariant,
  isSapVariant,
  isImportHistory,
}: FileDataProps) => {
  const [t] = useTranslation();
  const fileSize = bytesToMB(item.file?.size ?? 0);
  const hasSize = !isNil(item.file?.size);
  let dateFormatter = DateFormatters.compact;
  if (isImportHistory) {
    dateFormatter = DateFormatters.compactDatetime;
  }

  return (
    <Flex
      as="p"
      color="gray500"
      fontSize="small"
      fontWeight="regular"
      gap="space4">
      {/* variant only for Download Archive History list  */}
      {isDownloadVariant && hasSize && (
        <>
          {t('archive:download.history.list.fileSizeInMB', {
            fileSize: fileSize,
          })}
          <Text>{t('importHistoryList.bulletPoint')}</Text>
        </>
      )}
      {/* SAP variant is only for the Contacts Import History list */}
      {!isNil(isSapVariant) && (
        <>
          <ImportStatus item={item} />
          <Text>{t('importHistoryList.bulletPoint')}</Text>
        </>
      )}
      {item.createdAt && (
        <>
          <Text>{dateFormatter(new Date(item.createdAt))}</Text>
          <Text>{`${t('importHistoryList.bulletPoint')}`}</Text>
        </>
      )}
      {item.username && <Text>{item.username}</Text>}
    </Flex>
  );
};
