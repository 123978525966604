import {
  Box,
  Flex,
  Grid,
  Link,
  Modal,
  Tag,
  Text,
} from '@candisio/design-system';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { contractBenefits } from 'containers/Entitlements/components/Upsell/benefits';
import { PromotionCards } from 'containers/Entitlements/components/Upsell/components/PromotionCard';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ContractsAdvertModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ContractsAdvertModal = ({
  isOpen,
  onClose,
}: ContractsAdvertModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const addonTag = (
    <Box paddingLeft="space16">
      <Tag color="purple" variant="secondary">
        {t('promo.tag')}
      </Tag>
    </Box>
  );

  return (
    <Modal
      titleInfo={addonTag}
      closeLabel={t('common:common.close')}
      width="1090px"
      background="gray100"
      isOpen={isOpen}
      onClose={onClose}
      overflow="hidden"
      padding="space16 space48 space32">
      <Grid gap="space20">
        <Text fontSize="xxlarge" lineHeight="paragraph">
          <Trans t={t} i18nKey="promo.modal.titles.contracts">
            <Text fontWeight="semibold">
              Vertragsverwaltung mit Fristenmanagement
            </Text>
            <Text color="gray700">Mit diesen Funktionen</Text>
          </Trans>
        </Text>
        <PromotionCards
          benefits={contractBenefits}
          feature={FEATURE.CONTRACTS}
        />
        <Flex direction="column" gap="space16" alignItems="center">
          <PromotionLink
            href={t('promo.dashboard.demoLink')}
            variant="calendar">
            {t('promo.modal.bookAppointment')}
          </PromotionLink>
          <Text color="gray600">
            <Trans t={t} i18nKey="promo.modal.ctaMarketingWebsiteCopy">
              These functions are part of our new package structure
              <Link
                as="a"
                color="gray"
                target="_blank"
                href={t('promo.modal.ctaMarketingWebsiteLink')}
                style={{
                  textDecoration: 'underline',
                }}>
                candis.io/en/price
              </Link>
            </Trans>
          </Text>
        </Flex>
      </Grid>
    </Modal>
  );
};
