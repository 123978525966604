import { useFastApproveReimbursementCaseMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';
import { filterReimbursementItemErrors } from '../utils/filterReimbursementItemErrors';

const EXCLUDED_ERROR_TYPE_FIELD = 'totalAmount.amount';

export const useFastApproveReimbursementCase = () => {
  const [fastApproveMutation, { loading: isFastApprovePending }] =
    useFastApproveReimbursementCaseMutation();

  const fastApprove = useCallback(
    async (reimbursementId: string) => {
      const response = await fastApproveMutation({
        variables: { id: reimbursementId },
        refetchQueries: [
          'reimbursementCasesCount',
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
        ],
      });

      const isReponseInvalid =
        !response.data?.fastApproveReimbursementCase.success;

      const reimbursementItemErrors =
        response.data?.fastApproveReimbursementCase.errors
          .reimbursementItemsErrors ?? [];

      // XXX: Backend should remove this error on their side
      const reimbursementCaseErrors = (
        response.data?.fastApproveReimbursementCase.errors
          .reimbursementCaseErrors?.errors ?? []
      ).filter(errorField => errorField.field !== EXCLUDED_ERROR_TYPE_FIELD);

      const itemsErrors =
        filterReimbursementItemErrors(reimbursementItemErrors)
          .itemErrorsWithoutBookings ?? [];

      const reimbursementItemsErrors = itemsErrors.map(reimbursementItem => ({
        id: reimbursementItem.id,
        errors: reimbursementItem.errors.length ?? 0,
        title: reimbursementItem.title ?? undefined,
        type: reimbursementItem.type,
      }));

      if (isReponseInvalid) {
        return {
          status: 'error',
          reimbursementItemsErrors: reimbursementItemsErrors,
          hasReimbursementCaseErrors: reimbursementCaseErrors.length > 0,
        };
      }

      return {
        status: 'success',
        reimbursementItemsErrors: [],
        hasReimbursementCaseErrors: false,
      };
    },
    [fastApproveMutation]
  );

  return { fastApprove, isFastApprovePending };
};
