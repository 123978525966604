import { ErrorMessages as BaseErrorMessages } from 'utils/zodFormValidation/types';
import { expensesFormSchema } from './expensesFormSchema';

type ExcludeKeys =
  | 'expenses'
  | 'expenses.0.expenseId'
  | 'expenses.0.files'
  | 'expenses.0.expenseId'
  | 'expenses'
  | 'expenses.0.expenseType'
  | 'expenses.0.files';

/** Gets hospitality form validation error messages */
export const expensesFormErrorMessages: BaseErrorMessages<
  typeof expensesFormSchema,
  ExcludeKeys
> = {
  'expenses.0.files.0.id': {
    label: 'reimbursementView.middleSection.form.hospitality.file.label',
  },
  'expenses.0.files.0.name': {
    label: 'reimbursementView.middleSection.form.hospitality.file.label',
  },
  'expenses.0.files.0.url': {
    label: 'reimbursementView.middleSection.form.hospitality.file.label',
  },
  'expenses.0.reason': {
    label: 'reimbursementView.middleSection.form.hospitality.reason.label',
  },
  'expenses.0.dayOfExpense': {
    label: 'reimbursementView.middleSection.form.hospitality.date.label',
  },
  'expenses.0.totalAmount': {
    label: 'reimbursementView.middleSection.form.hospitality.totalAmount.label',
  },
  'expenses.0.receiptAmount': {
    label:
      'reimbursementView.middleSection.form.hospitality.receiptAmount.label',
  },
  'expenses.0.tipAmount': {
    label: 'reimbursementView.middleSection.form.hospitality.tipAmount.label',
  },
  'expenses.0.location': {
    label: 'reimbursementView.middleSection.form.hospitality.location.label',
  },
  'expenses.0.internalGuests': {
    label:
      'reimbursementView.middleSection.form.hospitality.internalGuests.label',
    custom: {
      translationKey:
        'reimbursementView.middleSection.form.hospitality.guestErr',
    },
  },
  'expenses.0.externalGuests': {
    label:
      'reimbursementView.middleSection.form.hospitality.externalGuests.label',
    custom: {
      translationKey:
        'reimbursementView.middleSection.form.hospitality.guestErr',
    },
  },
  'expenses.0.expenseDate': {
    label: 'reimbursementView.middleSection.form.general.date.label',
  },
};
