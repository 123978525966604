import { useGetCardIssuerTransactionByIdQuery } from 'generated-types/graphql.types';
import { DocumentPreview } from './DocumentPreview';
import { DocumentPreviewContainerProps } from './DocumentPreviewContainer';

export const TransactionPreviewContainer = ({
  documentId,
  onClose,
  onOpen,
  showPdf,
}: DocumentPreviewContainerProps) => {
  const { data, loading } = useGetCardIssuerTransactionByIdQuery({
    variables: {
      id: documentId,
    },
  });

  const documentFile =
    data?.getCardIssuerTransactionById.documents[0].documentFile;

  if (!documentFile) return <></>;

  return (
    <DocumentPreview
      documentFile={documentFile}
      loading={loading}
      onClose={onClose}
      onOpen={onOpen}
      showPdf={showPdf}
    />
  );
};
