import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { getUpdatedConfigurationsTable } from 'utils/table/getUpdatedConfigurationsTable';
import { PaymentTableData } from '../types';

export const paymentsTableId = 'payments-table-configurations';

const defaultColumnsConfigurations: Configuration[] = [
  { id: 'status', label: 'payments:table.headers.status', isVisible: true },
  { id: 'contact', label: 'payments:table.headers.contact', isVisible: true },
  {
    id: 'paymentInfo',
    label: 'payments:table.headers.paymentInfo',
    isVisible: true,
  },
  {
    id: 'invoiceNumber',
    label: 'payments:table.headers.invoiceNumber',
    isVisible: true,
  },
  {
    id: 'invoiceDueDate',
    label: 'payments:table.headers.dueDate',
    isVisible: true,
  },
  { id: 'amount', label: 'payments:table.headers.amount', isVisible: true },
  {
    id: 'dueDateWithCashDiscount',
    label: 'payments:table.headers.dueDateWithCashDiscount',
    isVisible: true,
  },
];

interface Params {
  isPaid: boolean;
  sortBy: SortingRule<PaymentTableData>[];
}

export const useGetPaymentsTableConfigs = ({ isPaid, sortBy }: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();

  const { configurations } = useGetTableConfigurations({
    tableId: paymentsTableId,
    defaultColumnsConfigurations,
  });

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const visibileColumnsFFoff = isPaid
    ? ['contact', 'paymentInfo', 'invoiceNumber', 'invoiceDueDate', 'amount']
    : [
        'status',
        'contact',
        'paymentInfo',
        'invoiceNumber',
        'invoiceDueDate',
        'amount',
        'dueDateWithCashDiscount',
      ];

  const { updatedConfigBasedOnOrgSettings, visibleColumnIds } =
    getUpdatedConfigurationsTable(configurations, visibileColumnsFFoff);

  useUpdateColumnsBE({
    tableId: paymentsTableId,
    defaultColumnsConfigurations,
  });

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: paymentsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: paymentsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters: [],
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  return {
    availablePaymentsColumnIds: visibleColumnIds as (keyof PaymentTableData)[],
    configurationsTable: updatedConfigBasedOnOrgSettings,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isSavingConfigurations,
    isResetPending,
  };
};
