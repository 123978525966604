import { mergeProps } from '@candisio/design-system';
import {
  HookFormDatePickerField,
  HookFormDatePickerFieldProps,
} from 'components/HookFormFields/HookFormDatePickerField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

/** A `HookFormDateField` with added field metadata */
export const ProcessingFormDatePickerField = ({
  name,
  readOnly,
  showStatusOnReadOnly,
  ...restProps
}: HookFormDatePickerFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormDatePickerField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
      showStatusOnReadOnly={showStatusOnReadOnly}
    />
  );
};
