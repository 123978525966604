import { CSSObject } from '@emotion/react';
import { motion } from 'framer-motion';
import React, {
  ButtonHTMLAttributes,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AriaSelectOptions,
  HiddenSelect,
  mergeProps,
  useButton,
  useSelect,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { SelectStateOptions, useSelectState } from 'react-stately';
import { Box } from '../../Atoms/Box';
import {
  FieldContainer,
  FieldContainerProps,
  Variant,
  useFieldContext,
} from '../../Atoms/FieldContainer';
import { ClearButton } from '../../Atoms/FieldContainer/ClearButton';
import { StatusIndicator } from '../../Atoms/FieldContainer/StatusIndicator';
import { Flex } from '../../Atoms/Flex';
import { Grid } from '../../Atoms/Grid';
import { Icon } from '../../Atoms/Icon';
import { ListBoxPopup } from '../../Atoms/ListBoxPopup';
import { useTheme } from '../../Theme';
import { LayoutProps } from '../../types';
import { TruncatedText } from '../Typography';

const MotionIcon = motion(Icon);

export interface SelectFieldProps
  extends Omit<FieldContainerProps, 'children'> {
  /** Label */
  label?: string;
  /** Props for the Select component */
  select: SelectProps;
  /** Puts component in readOnly state */
  readOnly?: boolean;
  /** Puts component in disabled state */
  disabled?: boolean;
  /** Set the height of the the popover */
  popoverHeight?: string;
  /** Show message on focus */
  showMessageOnFocus?: boolean;
}

/**
 * SelectField displays a collapsible list of options and allows a user to select.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-forms-selectfield}
 */
export const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(
  (
    {
      label,
      select,
      readOnly,
      disabled,
      message,
      variant,
      popoverHeight,
      showMessageOnFocus = false,
      ...restProps
    },
    forwardedRef
  ) => {
    return (
      <FieldContainer
        ref={forwardedRef}
        readOnly={readOnly}
        disabled={disabled}
        label={label}
        message={message}
        variant={variant}
        {...restProps}>
        <Select
          {...select}
          message={message}
          variant={variant}
          popoverHeight={popoverHeight}
          showMessageOnFocus={showMessageOnFocus}
        />
      </FieldContainer>
    );
  }
);

export interface SelectProps
  extends Omit<AriaSelectOptions<any>, 'label'>,
    Pick<SelectStateOptions<any>, 'children'> {
  /** Name of the Select */
  name?: string;
  /** Called when user scrolls to the end of the list */
  onEndReached?: (index: number) => void;
  /** Automatic focus of the first item in the dropdown list when opened */
  autoFocus?: boolean;
  /** Sets the readOnly state */
  readOnly?: boolean;
  /** Sets the disabled state */
  disabled?: boolean;
  /** Use list virtualization? */
  isVirtualized?: boolean;
  /** Render function that can be passed to render custom content in the dropdown */
  renderCustomDropdown?: (list: ReactElement) => ReactNode;
  /** Necessary if we need to render inside the dropdown items taller than 32px */
  itemHeight?: LayoutProps['height'];
  /** status variant i.e success, warning, error */
  variant?: Variant;
  /** message to display in popover of status */
  message?: ReactNode;
  /** Whether to display a separator between the list items */
  showSeparator?: boolean;
  /** Allow to clear selection */
  clearable?: boolean;
  /** message to display in popover of clear button */
  clearLabel?: string;
  /** Set the height of the the popover */
  popoverHeight?: string;
  /** Show message on focus */
  showMessageOnFocus?: boolean;
}

export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      autoFocus,
      onEndReached,
      isVirtualized,
      name,
      placeholder,
      renderCustomDropdown,
      itemHeight = 'space32',
      showSeparator,
      message,
      variant,
      clearable,
      clearLabel,
      popoverHeight,
      showMessageOnFocus = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const { inputCss, inputProps, inputRef, fieldContainerElement, label } =
      useFieldContext();

    const buttonRef = useRef<HTMLDivElement | null>(null);
    const listBoxPopupRef = useRef<HTMLDivElement | null>(null);
    const { selectField, fontSizes } = useTheme();
    const [selectElement, setSelectElement] = useState<HTMLDivElement | null>(
      null
    );

    const preferredTriggerElement = fieldContainerElement ?? selectElement;

    // hopefully temporary fix for infinite render loops when importing SelectField in the frontend
    delete inputProps.id;
    const combinedProps: SelectProps = mergeProps(inputProps, restProps);

    const { disabled, readOnly, ...restCombinedProps } = combinedProps;

    let state = useSelectState({
      isDisabled: disabled,
      ...restCombinedProps,
    });

    // Get props for child elements from useSelect
    const { triggerProps, valueProps, menuProps } = useSelect(
      {
        isDisabled: disabled,
        ...restCombinedProps,
      },
      state,
      buttonRef
    );

    // Get props for the button based on the trigger props from useSelect
    const { buttonProps } = useButton(triggerProps, buttonRef);

    const onMouseClickOutside = useCallback(
      (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        const isOutsideDropdownTrigger =
          preferredTriggerElement && !preferredTriggerElement.contains(target);

        const isOutsideListBoxPopup =
          listBoxPopupRef.current && !listBoxPopupRef.current.contains(target);

        if (isOutsideDropdownTrigger && isOutsideListBoxPopup) {
          const isOverListBoxPopup =
            listBoxPopupRef.current && listBoxPopupRef.current.contains(target);

          const isOverSelectButton =
            buttonRef.current && buttonRef.current.contains(target);

          if (!(isOverListBoxPopup || isOverSelectButton)) {
            state.close();
          }
        }
      },
      [preferredTriggerElement, state]
    );

    useEffect(() => {
      document.addEventListener('click', onMouseClickOutside);

      return () => {
        document.removeEventListener('click', onMouseClickOutside);
      };
    }, [onMouseClickOutside, preferredTriggerElement, state]);

    const selectedRenderedItem = state.selectedItem?.rendered;
    const displayValue = (
      <Box
        {...valueProps}
        css={[
          inputCss,
          {
            color: selectedRenderedItem
              ? selectField.color
              : selectField.placeholderColor,
            fontStyle: disabled ? 'italic' : 'initial',
            fontSize: fontSizes.basic,
          },
          disabled && [selectField.disabled],
        ]}
        overflow="hidden"
        {...valueProps}>
        {!selectedRenderedItem ? (
          placeholder
        ) : typeof selectedRenderedItem === 'string' ? (
          <TruncatedText
            charsAfterEllipsis={5}
            maxWidth="100%"
            wordBreak="break-word"
            // fixed height and line-height to prevent unexpected trigger of
            // vertical overflow
            fontSize="basic"
            height="space20"
            lineHeight="space20">
            {selectedRenderedItem}
          </TruncatedText>
        ) : (
          selectedRenderedItem
        )}
      </Box>
    );

    const showClearButton = clearable;
    const showStatus = message || variant;

    const showFooter = showClearButton || showStatus;

    return (
      <Grid ref={mergeRefs([setSelectElement, forwardedRef])}>
        <HiddenSelect
          state={state}
          triggerRef={buttonRef}
          label={label}
          name={name}
        />
        {!disabled && !readOnly ? (
          <SelectButton
            {...buttonProps}
            autoFocus={autoFocus}
            isOpen={state.isOpen}
            ref={mergeRefs([buttonRef, inputRef])}>
            <Grid templateColumns="1fr auto" gap="space8">
              {displayValue}
              {showFooter && (
                <Flex
                  gap={showClearButton && showStatus ? 'space8' : 'space0'}
                  alignItems="center">
                  <StatusIndicator
                    message={message}
                    // @ts-expect-error BE returns default variant, which we have no use for here
                    variant={variant}
                    showMessage={showMessageOnFocus && state.isFocused}
                  />
                  {clearable && state.selectedKey !== null && (
                    <ClearButton
                      clearLabel={clearLabel}
                      onClear={() => state.setSelectedKey(null)}
                    />
                  )}
                </Flex>
              )}
            </Grid>
          </SelectButton>
        ) : (
          displayValue
        )}
        {state.isOpen && preferredTriggerElement && (
          <ListBoxPopup
            listBoxProps={{
              ...menuProps,
              isVirtualized,
              onEndReached,
              itemHeight,
              showSeparator,
              height: popoverHeight,
            }}
            renderCustomDropdown={renderCustomDropdown}
            state={state}
            triggerElement={preferredTriggerElement}
            ref={listBoxPopupRef}
          />
        )}
      </Grid>
    );
  }
);

interface SelectButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen?: boolean;
}

const SelectButton = React.forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ children, isOpen = false, ...restProps }, ref) => {
    const { space } = useTheme();

    const css: CSSObject = {
      all: 'unset',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'center',
      gap: space.space8,
      cursor: 'pointer',
    };

    return (
      <button css={css} {...restProps} ref={ref}>
        {children}
        <MotionIcon
          animate={isOpen ? 'open' : 'closed'}
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 },
          }}
          transition={{
            rotate: { duration: 0.25 },
            default: { ease: 'easeInOut' },
          }}
          aria-hidden="true"
          icon="caretDown"
          color="gray450"
          size="space16"
        />
      </button>
    );
  }
);
