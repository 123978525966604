import { CalendarDate } from '@internationalized/date';
import { useState } from 'react';

/**
 * This hook is a workaround for a bug in react-spectrum that does not allow to type in a day bigger than current month max day.
 * https://github.com/adobe/react-spectrum/issues/3256
 */
export const useDatePickerPlaceholderSync = () => {
  const defaultPlaceholderValue = new CalendarDate(2024, 1, 1);

  const [placeholderValue, setPlaceholderValue] = useState(
    defaultPlaceholderValue
  );

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth() + 1; // +1 because months are 0-indexed
      const year = today.getFullYear();
      setPlaceholderValue(new CalendarDate(year, month, day));
    } else {
      setPlaceholderValue(defaultPlaceholderValue);
    }
  };

  return { placeholderValue, handleOpenChange };
};
