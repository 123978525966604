import { useMutation } from '@apollo/client';
import { Flex, Grid, Skeleton, useId } from '@candisio/design-system';

import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useOrganizationData } from '../hooks/useOrganizationData';
import { updateOrganizationMutation } from '../mutations';
import { localeErrorMessages, localeSchema } from '../resolvers/localeResolver';
import { OrganizationCard } from './OrganizationCard';

export const OrganizationLocale = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const { isLoading, locale, organization } = useOrganizationData();
  const formId = useId();

  const { isAdmin } = useUserRoles();

  const form = useForm<any>({
    values: { locale: locale },
    resolver: zodResolver({
      zodSchema: localeSchema,
      errorMessages: {
        ...localeErrorMessages,
      },
    }),
  });

  const [updateOrganization] = useMutation(updateOrganizationMutation);

  const handleOrganizationDataUpdate = async (locale: Key | null) => {
    const result = await updateOrganization({
      variables: {
        realmName: organization?.realmName,
        value: {
          locale: locale,
        },
      },
      refetchQueries: ['getOrganizations'],
    });

    if (result.errors) {
      error(t('organisation.messages.organisation.error'));
    } else {
      success(t('organisation.messages.organisation.success'));
    }
  };

  const handleSubmit = async (locale: Key | null) => {
    const { isDirty } = form.getFieldState('locale');

    if (isDirty) {
      await handleOrganizationDataUpdate(locale);
    }
  };

  return (
    <FormProvider {...form}>
      <Grid onSubmit={form.handleSubmit(handleSubmit)} id={formId} as="form">
        <OrganizationCard
          title={t('organisation.language.title')}
          paragraph={t('organisation.language.paragraph')}
          isLoading={isLoading}>
          {isLoading ? (
            <Skeleton height="space48" width="100%" />
          ) : (
            <Flex gap="space16" alignItems="center">
              <HookFormSelectField
                name="locale"
                items={Object.values(Locale).map(value => ({
                  // eslint-disable-next-line candis/no-template-strings-inside-translation
                  children: t(`organisation.locales.${value}`),
                  key: value,
                }))}
                readOnly={!isAdmin}
                placeholder={t('organisation.placeholder.select')}
                onChange={locale => handleSubmit(locale)}
                width="space256"
              />
            </Flex>
          )}
        </OrganizationCard>
      </Grid>
    </FormProvider>
  );
};
