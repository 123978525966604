import { Flex, Grid, Text } from '@candisio/design-system';
import { Sidebar as SideBarElement } from 'components/Sidebar/Sidebar';
import { ExportStatus } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { ZipExportButton } from 'views/Integrations/Export/elements/sidebar/Init/file/zip/ZipExportButton';
import { CandisApiImage } from 'views/Integrations/Export/toolkit/components/common';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { ExportDetails } from 'views/Integrations/Export/types';
import { useZipExport } from 'views/Integrations/Export/useZipExport';
import { ExportHistorySummary } from '../../containers/ExportableEntitiesSummary/History';

export interface CandisApiExportDetailsProps {
  exportDetails: ExportDetails;
}

export const CandisApiExportDetails = ({
  exportDetails,
}: CandisApiExportDetailsProps) => {
  const [t] = useTranslation();

  const { zipExportMutationLoading, hasExportableEntity, onZipExport } =
    useZipExport();

  return (
    <SideBarElement
      heading={t('common:export.exportForm.exportCandisApi.title', {
        date: DateFormatters.compact(exportDetails?.createdAt || new Date()),
      })}>
      <Grid templateRows="auto auto 1fr" height="100%">
        <ExportInfo>
          <Flex direction="column" justifyContent="space-between" gap="space12">
            <CandisApiImage />
            <Text fontSize="basic">
              {t('common:export.exportForm.selectExportType.types.candis_api')}
            </Text>
          </Flex>
        </ExportInfo>
        {exportDetails.status !== ExportStatus.Exporting && (
          <ExportHistorySummary />
        )}
        <ZipExportButton
          hasExportableEntity={hasExportableEntity}
          onExport={onZipExport}
          buttonLabel={t('export.exportForm.actionBar.buttonLabel')}
          loading={zipExportMutationLoading}
        />
      </Grid>
    </SideBarElement>
  );
};
