import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';

interface UseAcceptedFileTypesForInvoiceProps {
  isXmlDocumentUploadAllowed?: boolean;
}

export const useAcceptedFileTypesForInvoice = ({
  isXmlDocumentUploadAllowed,
}: UseAcceptedFileTypesForInvoiceProps) => {
  const allowProcessingOfXrechnungEinvoicesFF = useCandisFeatureFlags(
    FEATURE_FLAGS.allowProcessingOfXrechnungEinvoices
  );

  const acceptedFileTypes =
    allowProcessingOfXrechnungEinvoicesFF && isXmlDocumentUploadAllowed
      ? ['application/pdf', 'application/xml', 'text/xml']
      : ['application/pdf'];

  return acceptedFileTypes;
};
