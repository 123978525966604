import { CellProps } from 'react-table';
import { Checkbox } from '../../../Atoms/Checkbox';
import { Tooltip, useTooltip } from '../../../Atoms/Tooltip';
import { BaseTableDataType } from '../Table';
import { handleRowSelection } from '../utils/handleRowSelection';

export interface SelectionCellProps<TableDataType extends BaseTableDataType>
  extends CellProps<TableDataType> {
  onRowSelected?: (row: TableDataType[]) => void;
  isDisabled?: boolean;
  isSingleSelect?: boolean;
  disabledReason?: string;
  isHovered: boolean;
  isVisible?: boolean;
  forceSelected?: boolean;
  isReadOnly?: boolean;
}

export const SelectionCell = <TableDataType extends BaseTableDataType>({
  row,
  onRowSelected,
  selectedFlatRows,
  isSingleSelect,
  isDisabled,
  disabledReason,
  isHovered,
  isVisible,
  isReadOnly,
  forceSelected,
}: SelectionCellProps<TableDataType>) => {
  const { isOpen, tooltipProps, triggerProps, triggerRef, tooltipRef } =
    useTooltip({ placement: 'right', isOpen: isHovered });

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {/* @ts-expect-error We are spreading triggerProps from useTooltip hook which contains onFocus method and the type of the Event are not compatible which would give us an error**/}
      <Checkbox
        {...triggerProps}
        ref={triggerRef}
        children=""
        data-testid={`checkbox-${row.id}`}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isSelected={row.isSelected || forceSelected}
        onChange={(isSelected) => {
          // Implement row selection with internal state of the table. Right now we are using an external state

          handleRowSelection({
            isSelected,
            isSingleSelect,
            onRowSelected,
            row,
            selectedFlatRows,
          });
        }}
      />
      {isDisabled && isOpen && disabledReason && (
        <Tooltip maxWidth="40ch" {...tooltipProps} ref={tooltipRef}>
          {disabledReason}
        </Tooltip>
      )}
    </>
  );
};
