import { CSSObject, keyframes } from '@emotion/react';
import React from 'react';
import { useTheme } from '../../Theme';
import {
  IconSize,
  LayoutProps,
  StandardHTMLAttributes,
  TokenOrCSSValue,
} from '../../types';
import { Box } from '../Box';

type Color = TokenOrCSSValue<'colors', 'color'>;

export interface SpinnerProps
  extends LayoutProps,
    StandardHTMLAttributes<SVGSVGElement> {
  size?: IconSize;
  color?: Color;
}

/**
 * Spinner is a special Icon used to denote some loading state.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-feedback-spinner}
 */
export const Spinner = React.forwardRef<SVGSVGElement, SpinnerProps>(
  ({ size = '1rem', color, ...restProps }, ref) => {
    const { spinner } = useTheme();

    const loadingAnimation = keyframes({
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    });

    const animationCSS: CSSObject = {
      animation: `${loadingAnimation} 1s linear infinite`,
    };

    return (
      <Box
        as="svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        height={size}
        width={size}
        color={color ?? spinner.color}
        css={animationCSS}
        {...restProps}
        ref={ref}>
        <circle
          opacity={spinner.opacity}
          fill="transparent"
          cx="50"
          cy="50"
          r="33"
          stroke="currentColor"
          strokeWidth="9"
        />
        <circle
          cx="50"
          cy="50"
          r="33"
          fill="transparent"
          stroke="currentColor"
          strokeWidth="9"
          strokeDasharray="220"
          strokeDashoffset="250"
        />
      </Box>
    );
  }
);
