import { Drawer, Heading } from '@candisio/design-system';
import { DocumentAccessSections } from 'components/DocumentAccess/DocumentAccesssSections';
import {
  DrawerLayout,
  DrawerLayoutProps,
} from 'components/DrawerLayout/DrawerLayout';
import { DocumentType } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export interface DocumentAccessDrawerProps
  extends Pick<DrawerLayoutProps, 'compact'> {
  isOpen: boolean;
  onCloseDrawer: () => void;
  globalDocumentId: string;
  documentType: DocumentType;
  isSensitiveDocument: boolean;
}

export const DocumentAccessDrawer = ({
  isOpen,
  onCloseDrawer,
  compact,
  globalDocumentId,
  documentType,
  isSensitiveDocument,
}: DocumentAccessDrawerProps) => {
  const [t] = useTranslation();

  return (
    <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
      <DrawerLayout
        compact={compact}
        onClose={onCloseDrawer}
        header={
          <Heading as="h3">{t('common:documentAccess.drawer.title')}</Heading>
        }>
        <DocumentAccessSections
          globalDocumentId={globalDocumentId}
          documentType={documentType}
          isSensitiveDocument={isSensitiveDocument}
        />
      </DrawerLayout>
    </Drawer>
  );
};
