import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useState } from 'react';

export const DEFAULT_INTERCOM_COOKIES_ENABLED = false;
export const DEFAULT_TRACKING_ENABLED = false;

export const useTrackingPreferences = () => {
  const currentUser = useCurrentUser();

  const [trackingEnabled, setTrackingEnabled] = useState(
    currentUser?.trackingConfiguration?.trackingEnabled ??
      DEFAULT_TRACKING_ENABLED
  );

  const [intercomCookiesEnabled, setIntercomCookiesEnabled] = useState(
    currentUser?.trackingConfiguration?.intercom ??
      DEFAULT_INTERCOM_COOKIES_ENABLED
  );

  return {
    trackingEnabled,
    intercomCookiesEnabled,
    setTrackingEnabled,
    setIntercomCookiesEnabled,
  };
};
