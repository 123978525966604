import React from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { Box } from '../../../Atoms/Box';
import { Tooltip, useTooltip } from '../../../Atoms/Tooltip';
import { Text, TextProps } from '../../../Atoms/Typography/Text';
import { isChromium } from '../../../utils/browserHelpers';
import { useOverflow } from './useOverflow';

const DEFAULT_ELEMENT = 'span';

export type TruncatedTextProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> = Omit<TextProps<TElement>, 'overflow'> & {
  charsAfterEllipsis?: number;
  children: string;
  lineClamp?: number;
};

type TruncatedTextType = <
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
>(
  props: TruncatedTextProps<TElement>
) => React.ReactElement | null;

/**
 * Text that cuts off after a given number of lines. An ellipsis (…) is
 * displayed at the cut-off point. A tooltip displays the full text on hover.
 *
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-typography-truncatedtext}
 *
 * @param {number} [lineClamp = 1] Number of lines after which to cut off the text
 * @param {normal | break-all} [wordBreak = 'normal'] Break lines only between words or anywhere, forced to 'break-all' when setting charsAfterEllipsis
 * @param {number} [charsAfterEllipsis = 0] Number of characters you want to display after truncation
 */
export const TruncatedText = React.forwardRef(
  <TElement extends React.ElementType>(
    {
      charsAfterEllipsis = 0,
      children,
      lineClamp = 1,
      wordBreak = 'break-all',
      ...restProps
    }: TruncatedTextProps<TElement>,
    forwardedRef: typeof restProps.ref
  ) => {
    const { overflowing, overflowRef } = useOverflow();

    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({ passiveTrigger: true, delay: 1000 });

    // only truncate in the middle if there are at least 3 more chars in the full string than should be displayed
    // after ellipsis and if browser is Chromium based (current implementation caused flickering in other browsers)
    if ((children.length ?? 0) - 2 <= charsAfterEllipsis || !isChromium) {
      charsAfterEllipsis = 0;
    }

    const textPreTruncation = children.slice(
      0,
      children.length - charsAfterEllipsis
    );

    const textPostTruncation = charsAfterEllipsis
      ? children.slice(-charsAfterEllipsis)
      : '';

    return (
      <>
        <Box
          as={DEFAULT_ELEMENT}
          {...(overflowing
            ? (mergeProps(restProps, triggerProps) as Record<string, unknown>)
            : restProps)}
          // See https://css-tricks.com/almanac/properties/l/line-clamp/
          css={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: lineClamp,
            // not allowing to break after any character can cause layout issues when truncating in the middle, therefore we force break-all in that case
            wordBreak: charsAfterEllipsis
              ? 'break-all'
              : lineClamp > 1
                ? 'break-word'
                : wordBreak,
            // prevent post truncation string to push the last line of text down when resizing smaller
            minWidth:
              textPostTruncation.length > 0
                ? `${textPostTruncation.length + 3}ch`
                : undefined,
            textAlign: 'start',
          }}
          ref={mergeRefs([forwardedRef, triggerRef, overflowRef])}>
          {overflowing ? (
            <>
              {/* After hours of research this seems like the cleanest non-JS solution (since `line-clamp` works
                really well, I was hesitant to get rid of it):
                This adds as many lines as necessary to push the post truncation string (which is only displayed on overflow)
                into the last line of the entire TruncatedText block */}
              {[...Array(lineClamp - 1)].map((_, index) => (
                <span
                  key={index}
                  css={{
                    float: 'right',
                    clear: 'both',
                  }}>
                  &nbsp;
                </span>
              ))}
              <Text
                css={{
                  whiteSpace: 'nowrap',
                  float: 'right',
                  clear: 'both',
                }}>
                {textPostTruncation}
              </Text>
              <Text>{textPreTruncation}</Text>
            </>
          ) : (
            <Text>{children}</Text>
          )}
        </Box>
        {overflowing && isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {children}
          </Tooltip>
        )}
      </>
    );
  }
) as TruncatedTextType;
