import { Grid, Text } from '@candisio/design-system';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import { DocumentType, ExportType } from 'views/Integrations/Export/types';
import { ApprovalsSummary } from './ApprovalsSummary';
import { BdsSummary } from './BdsSummary';
import { CoreDataSummary } from './CoreDataSummary';
import { ReimbursementItemsSummary } from './ReimbursementItemsSummary';
import { TransactionsSummary } from './TransactionsSummary';

export const InitExportSummary = ({
  readyToExportEntitiesCount,
}: {
  readyToExportEntitiesCount: Record<DocumentType, number>;
}) => {
  const [t] = useTranslation();
  const { exportType } = useContext(ExportContext);
  const { canUseReimbursement } = useReimbursement();

  const numberOfExportBatches = Object.values(
    readyToExportEntitiesCount
  ).filter(Boolean).length;

  const hasExportingEntities = numberOfExportBatches > 0;

  if (!hasExportingEntities) return null;

  const isBdsExport = exportType === ExportType.DATEV_BDS;
  const isCsvExport = exportType === ExportType.DATEV_CSV;

  const batchSeparatorText = hasExportingEntities
    ? t('export.exportSummary.batchesCount', {
        count: numberOfExportBatches,
      })
    : '';

  const separatorText = isBdsExport
    ? batchSeparatorText
    : t('export.exportSummary.willBeExported');

  return (
    <Grid gap="space12">
      <Text>{separatorText}</Text>
      <ApprovalsSummary
        readyToExportEntitiesCount={readyToExportEntitiesCount}
      />
      {(isBdsExport || isCsvExport) && (
        <BdsSummary readyToExportEntitiesCount={readyToExportEntitiesCount} />
      )}
      <TransactionsSummary
        readyToExportEntitiesCount={readyToExportEntitiesCount}
      />
      {isBdsExport && (
        <CoreDataSummary
          readyToExportEntitiesCount={readyToExportEntitiesCount}
        />
      )}
      {isBdsExport && canUseReimbursement && (
        <ReimbursementItemsSummary
          readyToExportEntitiesCount={readyToExportEntitiesCount}
        />
      )}
    </Grid>
  );
};
