import { Grid, Text, TruncatedText } from '@candisio/design-system';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { EmailImportErrorIcon } from 'components/Icons/notifications/EmailImportErrorIcon';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { EmailImportErrorNotification } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

export interface EmailImportErrorNotificationItemProps {
  data: EmailImportErrorNotification;
  onClose: () => void;
}

export const EmailImportErrorNotificationItem = ({
  data,
  onClose,
}: EmailImportErrorNotificationItemProps) => {
  const [t] = useTranslation();
  const { fromAddress } = data;
  const organizationSlug = useOrganizationId();

  return (
    <Grid
      alignItems="center"
      gap="space16"
      templateColumns="auto 1fr auto"
      paddingX="space12"
      paddingY="space8">
      <EmailImportErrorIcon />
      <Text fontWeight="bold">
        <RouterLink
          onClick={onClose}
          to={`/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}/${data.emailMessageId}`}>
          {t('uploads.errors.emailImportError', { fromAddress })}
        </RouterLink>
      </Text>
      <Grid alignItems="center" gap="space4" templateColumns="1fr auto">
        <TruncatedText color="red500">
          {t('uploads.status.error')}
        </TruncatedText>
        <InfoIcon message={t('uploads.errors.emailImportErrorInfo')} />
      </Grid>
    </Grid>
  );
};
