import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { TransactionsTableData } from 'components/Transactions/Table/types';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';

export const inboxTransactionsTableId =
  'inbox-transactions-table-configurations';

interface Params {
  filters: Filters<TransactionsTableData>;
  sortBy: SortingRule<TransactionsTableData>[];
}

export const defaultColumnsConfigurations: Configuration[] = [
  {
    id: 'type',
    isVisible: true,
    label: 'transactions:columns.type',
    isFixed: true,
  },
  {
    id: 'status',
    isVisible: true,
    label: 'transactions:columns.status',
    isFixed: true,
  },
  {
    id: 'merchantName',
    isVisible: true,
    label: 'transactions:columns.merchantName',
  },
  {
    id: 'grossAmount',
    isVisible: true,
    label: 'transactions:columns.grossAmount',
  },
  {
    id: 'category',
    isVisible: true,
    label: 'transactions:columns.category',
  },
  {
    id: 'transactionCreatedAt',
    isVisible: true,
    label: 'transactions:columns.transactionCreatedAt',
  },
  {
    id: 'cardRefNum',
    isVisible: true,
    label: 'transactions:columns.cardRefNum',
  },
  {
    id: 'cardholderName',
    isVisible: true,
    label: 'transactions:columns.cardholderName',
  },
  {
    id: 'invoiceAssociationStatus',
    isVisible: true,
    label: 'transactions:columns.invoiceAssociationStatus',
  },
];

export const useGetInboxTransactionsTableConfigs = ({
  filters,
  sortBy,
}: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const { configurations } = useGetTableConfigurations({
    tableId: inboxTransactionsTableId,
    defaultColumnsConfigurations,
  });

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: inboxTransactionsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: inboxTransactionsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  const visibleColumnIds = configurations
    .filter((config): config is Configuration => config.isVisible)
    .map(config => config.id as keyof TransactionsTableData);

  useUpdateColumnsBE({
    tableId: inboxTransactionsTableId,
    defaultColumnsConfigurations,
  });

  return {
    availableTransactionColumnIds: visibleColumnIds,
    configurationsTable: configurations,
    isResetPending,
    isSavingConfigurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  };
};
