import { Button, Flex, Heading, Link, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

interface NotAcceptedUsageAgreementProps {
  onAcceptUsageAgreement: () => void;
  isAccepting: boolean;
  createdAt: string;
}

export const NotAcceptedUsageAgreement = ({
  onAcceptUsageAgreement,
  isAccepting,
  createdAt,
}: NotAcceptedUsageAgreementProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const organization = useFullOrganization();

  return (
    <Flex direction="column" gap="space12">
      <Flex direction="column" gap="space4">
        <Heading as="h2">{t('carousel.processing.title')}</Heading>
        <Text color="gray600">
          {t('carousel.processing.issuedOn', {
            date: DateFormatters.compact(new Date(createdAt)),
          })}
        </Text>
      </Flex>
      <Flex direction="column" gap="space4">
        <Trans
          t={t}
          i18nKey="carousel.processing.usageAgreementText"
          values={{ orgName: organization?.name }}>
          In dem du die Karte verwendest stimmst du den
          <Link href={t('carousel.processing.usageAgreementLink')}>
            Nutzungsbedingungen
          </Link>
          von OrganizationName zu.
        </Trans>
        <Button
          disabled={isAccepting}
          onClick={onAcceptUsageAgreement}
          loading={isAccepting}
          alignSelf="start">
          {t('carousel.processing.ctaUsageAgreement')}
        </Button>
      </Flex>
    </Flex>
  );
};
