import {
  Box,
  Button,
  Flex,
  Grid,
  Separator,
  useTheme,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { getNotificationInfo } from 'containers/notifications/getNotificationInfo';
import { useToggleNotificationRead } from 'containers/notifications/hooks/useToggleNotificationRead';
import { motion } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';

const MotionGrid = motion(Grid);

interface NotificationListItemProps {
  notification: any;
  onClose: () => void;
}

export const NotificationListItem = ({
  notification,
  onClose,
}: NotificationListItemProps) => {
  const { colors } = useTheme();
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { isHovered, hoverProps } = useHover({});
  const { markNotificationsAsRead } = useToggleNotificationRead();

  const notificationInfo = getNotificationInfo(notification.__typename);

  const getLinkTo = notificationInfo?.getLinkTo;
  const getLinkToResult = getLinkTo?.(notification);

  if (!notificationInfo) return null;

  const NotificationIcon = notificationInfo?.icon;
  const NotificationContent = notificationInfo?.content;

  const isNotificationRead = notification?.isRead;

  const handleMarkAsRead = () => {
    if (isNotificationRead) {
      return;
    }

    markNotificationsAsRead({
      ids: [notification.id],
    });
  };

  return (
    <RouterLink
      to={getLinkToResult?.link ?? ''}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        onClose();
        handleMarkAsRead();
      }}>
      <Box background="gray0">
        <MotionGrid
          key={notification?.id}
          justifyContent="stretch"
          background="transparent"
          transition={{ ease: 'easeIn', duration: 0.3 }}
          initial={{ background: 'transparent' }}
          animate={
            isHovered
              ? { background: colors.gray50 }
              : { background: 'transparent' }
          }
          borderRadius="medium"
          {...hoverProps}>
          <Flex direction="row" padding="space10" gap="space8">
            <Flex width="100%" gap="space12" justifyContent="start">
              <Grid
                paddingTop="space2"
                alignItems="start"
                alignSelf="start"
                justifySelf="center">
                <NotificationIcon {...notification} />
              </Grid>
              <Box height="100%">
                <NotificationContent {...notification} />
              </Box>
            </Flex>
            <Grid justifyContent="start" alignItems="start" width="space32">
              {!isNotificationRead && !isHovered && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width="space32"
                  height="space32">
                  <Box
                    width="space8"
                    height="space8"
                    background="gray600"
                    borderRadius="full"
                  />
                </Flex>
              )}
              {!isNotificationRead && isHovered && (
                <Button
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleMarkAsRead();
                  }}
                  label={t('item.markAsReadCtaLabel')}
                  icon="check"
                  variant="tertiary"
                />
              )}
            </Grid>
          </Flex>
        </MotionGrid>
        <Separator height="space8" background="gray0" />
      </Box>
    </RouterLink>
  );
};
