import { Maybe } from '@graphql-tools/utils';
import {
  SplitDS,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { SummaryProps } from 'containers/SplitBookings/components/Summary/Summary';
import { isNil } from 'lodash';
import { MAX_FRACTION_DIGITS_FOR_CURRENCY } from 'utils/currency-fraction-digits';
import { roundNumber } from 'utils/roundNumber';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

type SumBooking = {
  netAmount?: Maybe<number>;
  amount?: Maybe<number>;
  vatRate?: Maybe<number>;
  taxPresentation?: Maybe<TaxPresentation>;
};

const EXIT_MARGIN = 1;
const DECIMAL_FACTOR = 10;
const INVERSE = -1;
export const sumBookings = (bookings: SumBooking[]) =>
  roundToCurrencyPrecision(
    bookings.reduce((sum, booking) => sum + (booking.amount ?? 0), 0)
  );

export const calculateRestAmount = (values: {
  grossAmount?: Maybe<number>;
  bookings: SumBooking[];
}) => {
  const grossAmount = values.grossAmount;
  const sumOfNetBookingAsGross = sumBookings(values.bookings);

  const restAmount = grossAmount
    ? roundToCurrencyPrecision(grossAmount - sumOfNetBookingAsGross)
    : 0;

  return restAmount;
};

export const grossToNet = (grossAmount: number, vatRate: number) =>
  roundToCurrencyPrecision(grossAmount / (1 + vatRate / 100));

export const netToGross = (netAmount: number, vatRate: number) =>
  roundToCurrencyPrecision(netAmount * (1 + vatRate / 100));

export const sumNetBookingsToGrossWithoutRounding = (
  bookings: SumBooking[] = []
) =>
  roundToCurrencyPrecision(
    bookings.reduce(
      (total, booking) =>
        total + netToGross(booking.netAmount ?? 0, booking.vatRate ?? 0),
      0
    )
  );

const getMarginForCurrency = (): number => {
  const margin =
    EXIT_MARGIN *
    Math.pow(DECIMAL_FACTOR, INVERSE * MAX_FRACTION_DIGITS_FOR_CURRENCY);

  return margin;
};

const getMargin = (
  sumOfBookings: number,
  grossAmount: number
): number | null => {
  const margin = getMarginForCurrency();
  const diff = roundNumber(
    grossAmount - sumOfBookings,
    MAX_FRACTION_DIGITS_FOR_CURRENCY
  );

  if (diff <= margin && diff >= INVERSE * margin) {
    return diff;
  }

  return null;
};

export const getRoundingDifference = ({
  grossAmount,
  bookings,
}: {
  grossAmount: Maybe<number>;
  bookings: SumBooking[];
}): number | null => {
  if (grossAmount === null || grossAmount === 0) return null;

  const sumOfGrossAmounts = sumBookings(bookings);

  if (sumOfGrossAmounts === grossAmount) {
    // gross amounts add up to total, we’re good
    return null;
  }

  const sumOfNetBookingAsGross = sumNetBookingsToGrossWithoutRounding(bookings);

  if (sumOfNetBookingAsGross === grossAmount) {
    // looks like a rounding error, return the rounding difference
    return roundToCurrencyPrecision(grossAmount - sumOfGrossAmounts);
  }

  const margin = getMargin(sumOfGrossAmounts, grossAmount ?? 0);
  if (margin !== null) {
    return margin;
  }

  // not a rounding error
  return null;
};

export const bookingWithNetAmount = (b: SplitDS): SumBooking => {
  return {
    amount:
      b.taxPresentation === TaxPresentation.Gross
        ? b.amount
        : netToGross(b.amount ?? 0, b.vatRate ?? 0),
    vatRate: b.vatRate,
    taxPresentation: b.taxPresentation,
    netAmount:
      b.taxPresentation === TaxPresentation.Net
        ? b.amount
        : grossToNet(b.amount ?? 0, b.vatRate ?? 0),
  };
};

export const bookingToSummaryData = ({
  currency,
  booking,
  isSapNetAmount = false,
}: {
  currency: Maybe<string>;
  booking: SplitDS;
  isSapNetAmount: boolean;
}): SummaryProps['data'] => {
  let vatRate = booking.vatRate;

  if (isSapNetAmount && !booking.taxCode) {
    vatRate = null;
  }

  return {
    currency,
    amount:
      booking.taxPresentation === TaxPresentation.Gross
        ? booking.amount
        : netToGross(booking.amount ?? 0, booking.vatRate ?? 0),
    vatRate,
    quantity: booking.sapExpenseType ? undefined : booking.quantity,
    note: booking.note,
    costCenterName: booking.costCenter?.inputValue,
  };
};

export const sumNetAmounts = (bookings: { netAmount?: number | null }[]) => {
  const netAmounts = bookings
    .map(booking => booking?.netAmount)
    .filter(netAmount => !isNil(netAmount)) as number[];

  if (netAmounts.length === 0) {
    return undefined;
  }

  return roundToCurrencyPrecision(
    netAmounts.reduce((sum, netAmount) => sum + netAmount, 0)
  );
};

export const sumTaxAmounts = (bookings: { taxAmount?: number | null }[]) => {
  const taxAmounts = bookings
    .map(booking => booking.taxAmount)
    .filter(taxAmount => !isNil(taxAmount)) as number[];

  if (taxAmounts.length === 0) {
    return undefined;
  }

  return roundToCurrencyPrecision(
    taxAmounts.reduce((sum, taxAmount) => sum + taxAmount, 0)
  );
};

export const calculateTaxAmount = (
  netAmount?: number | null,
  vatRate?: number | null
) =>
  netAmount ? roundToCurrencyPrecision(netAmount * ((vatRate ?? 0) / 100)) : 0;
