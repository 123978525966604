import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { noop } from 'lodash';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ReimbursementsTable } from 'views/Reimbursement/elements/Table/ReimbursementsTable';
import { ReimbursementTableColumnKeys } from 'views/Reimbursement/toolkit/types';
import { sampleData } from 'views/Inbox/Reimbursements/toolkit/utils';
import { PromotionWrapper, TableWrapper, Wrapper } from '../Wrapper';

interface ReimbursementsPromotionProps {
  tableColumns: ReimbursementTableColumnKeys[];
}

export const ReimbursementsPromotion = ({
  tableColumns,
}: ReimbursementsPromotionProps) => {
  const { reimbursementsPromotionLegacy, reimbursementsPromotionNonLegacy } =
    usePromotionContent();

  const { variant } = useReimbursementsPromotions();

  const promotionVariant =
    variant === 'promotionForEntitlements'
      ? reimbursementsPromotionNonLegacy
      : reimbursementsPromotionLegacy;

  return (
    <Wrapper>
      <TableWrapper>
        <ReimbursementsTable
          search=""
          visibleColumns={tableColumns}
          configurations={[]}
          data={sampleData}
          onEndReached={noop}
          onFilter={noop}
          onRowClick={noop}
          onResetTableConfigurations={noop}
          onSearch={noop}
          onSort={noop}
          onUpdateTableConfigurations={noop}
        />
      </TableWrapper>
      <PromotionWrapper>
        <PromotionsProvider promotions={[promotionVariant]}>
          <PromotionCard promotion={promotionVariant} closable={false} />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};
