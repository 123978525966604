import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ReactNode, useCallback, useEffect } from 'react';
import { loadVWO } from './utils/loadVMO';

interface VWOAnalyticProviderProps {
  children: ReactNode;
}

const vwoScriptId = 'vwoCode';
const vwoLinkId = 'vwoLink';

export const VWOAnalyticProvider = ({ children }: VWOAnalyticProviderProps) => {
  const viewer = useCurrentUser();
  const organization = useFullOrganization();

  const trackingEnabled =
    viewer?.trackingConfiguration?.trackingEnabled ?? false;

  const loadVWOAnalyticTool = useCallback(() => {
    const vwoScript = document.getElementById(vwoScriptId);
    const vwoLink = document.getElementById(vwoLinkId);
    const vwoAccountId = import.meta.env.REACT_APP_VWO_ACCOUNT_ID;

    if (
      !vwoScript &&
      !vwoLink &&
      viewer &&
      organization &&
      trackingEnabled &&
      vwoAccountId
    ) {
      const script = document.createElement('script');
      const link = document.createElement('link');
      link.rel = 'preconnect';
      link.id = vwoLinkId;
      link.href = 'https://dev.visualwebsiteoptimizer.com';
      script.id = vwoScriptId;
      script.type = 'text/javascript';
      script.async = true;

      script.onload = loadVWO(Number(vwoAccountId));

      document.head.appendChild(link);
      document.head.appendChild(script);
    }
  }, [organization, trackingEnabled, viewer]);

  useEffect(() => {
    loadVWOAnalyticTool();
  }, [loadVWOAnalyticTool]);

  return <>{children}</>;
};
