import { useCardIssuerStatementQuery } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { DateFormatters } from 'utils/date_formatter';
import { useMonthFormatter } from './useMonthFormatter';

interface useGetStatementByIdProps {
  id: string | undefined;
}

export interface StatementDetail {
  statementId: string | undefined;
  month: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  isClosed: boolean;
  closingBalance: string | undefined;
  startingBalance: string | undefined;
  hasPdfFile: boolean;
  pdfFileUrl: string | undefined;
  totalTransactionAmount: string | undefined;
  totalPaymentAmount: string | undefined;
}

export const useGetStatementById = ({ id }: useGetStatementByIdProps) => {
  const { longNotation } = useMonthFormatter();

  const { transactionAmountFormatter } = useMoneyFormatter();

  const { data, loading } = useCardIssuerStatementQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
  });

  const startFullDate = data?.cardIssuerStatement?.cardIssuer
    ?.statementPeriodStart
    ? new Date(data?.cardIssuerStatement?.cardIssuer?.statementPeriodStart)
    : undefined;

  const endFullDate = data?.cardIssuerStatement?.cardIssuer?.statementPeriodEnd
    ? new Date(data?.cardIssuerStatement?.cardIssuer?.statementPeriodEnd)
    : undefined;

  const statementDetail: StatementDetail = {
    statementId: data?.cardIssuerStatement.id,
    month: longNotation.format(startFullDate),
    startDate: startFullDate
      ? DateFormatters.compact(startFullDate)
      : undefined,
    endDate: endFullDate ? DateFormatters.compact(endFullDate) : undefined,
    isClosed: data?.cardIssuerStatement?.cardIssuer.isClosed ?? false,
    startingBalance: data?.cardIssuerStatement?.cardIssuer.openingBalance
      ? transactionAmountFormatter({
          currency:
            data?.cardIssuerStatement?.cardIssuer.openingBalance.currency,
          precision:
            data?.cardIssuerStatement?.cardIssuer.openingBalance.precision,
          value: data?.cardIssuerStatement?.cardIssuer.openingBalance.value,
        })
      : undefined,
    closingBalance: data?.cardIssuerStatement?.cardIssuer.closingBalance
      ? transactionAmountFormatter({
          currency:
            data?.cardIssuerStatement?.cardIssuer.closingBalance.currency,
          precision:
            data?.cardIssuerStatement?.cardIssuer.closingBalance.precision,
          value: data?.cardIssuerStatement?.cardIssuer.closingBalance.value,
        })
      : undefined,
    totalPaymentAmount: data?.cardIssuerStatement?.cardIssuer.totalPaymentAmount
      ? transactionAmountFormatter({
          currency:
            data?.cardIssuerStatement?.cardIssuer.totalPaymentAmount.currency,
          precision:
            data?.cardIssuerStatement?.cardIssuer.totalPaymentAmount.precision,
          value: data?.cardIssuerStatement?.cardIssuer.totalPaymentAmount.value,
        })
      : undefined,
    totalTransactionAmount: data?.cardIssuerStatement?.cardIssuer
      .totalTransactionAmount
      ? transactionAmountFormatter({
          currency:
            data?.cardIssuerStatement?.cardIssuer.totalTransactionAmount
              .currency,
          precision:
            data?.cardIssuerStatement?.cardIssuer.totalTransactionAmount
              .precision,
          value:
            data?.cardIssuerStatement?.cardIssuer.totalTransactionAmount.value,
        })
      : undefined,
    hasPdfFile: data?.cardIssuerStatement.hasPdfFile ?? false,
    pdfFileUrl: data?.cardIssuerStatement.pdfFile?.url ?? undefined,
  };

  return { statementDetail, loading, startFullDate, endFullDate };
};
