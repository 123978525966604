import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { CommentForm } from './CommentForm';
import { CommentLegacy } from './CommentLegacy';
import { CommentProps } from './types';

export const Comment = (props: CommentProps) => {
  const commentRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.commentRefactor
  );

  if (commentRefactorFF) {
    return <CommentForm {...props} />;
  } else {
    return <CommentLegacy {...props} />;
  }
};
