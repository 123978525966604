import { Button, Card, Grid, Icon, Text } from '@candisio/design-system';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { motion } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CreateDocumentRelationAction = ({
  selectedDocuments,
  onClick,
  isLinkingDocuments,
  totalCount,
}: {
  selectedDocuments: EcmDocumentsTableData[];
  totalCount: number;
  onClick: () => void;
  isLinkingDocuments: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <MotionGrid
      variants={{
        visible: { height: 'auto' },
        hidden: { height: 0 },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      width="100%"
      position="absolute"
      bottom={0}>
      <Card
        background="blue100"
        corners="all"
        boxShadow="elevatedShadow5"
        paddingX="space36"
        maxHeight="space80"
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}>
        <Grid
          placeContent="space-between"
          placeItems="center"
          autoFlow="column">
          <Text fontSize="small" lineHeight="paragraph">
            {t('documentRelationship.modal.selectedInfo', {
              selectedCount: selectedDocuments.length,
              totalCount,
            })}
          </Text>
          <Button onClick={onClick} loading={isLinkingDocuments}>
            <Grid placeContent="center" placeItems="center" autoFlow="column">
              {t('documentRelationship.modal.linkButton')}
              <Icon icon="arrowRight" size="space20" />
            </Grid>
          </Button>
        </Grid>
      </Card>
    </MotionGrid>
  );
};

const MotionGrid = motion(Grid);
