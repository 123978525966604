import { LocationListener, Location } from 'history';
import { snakeCase } from 'lodash';
import { compiledRoutes } from 'models';
import { matchRoutes } from 'react-router-dom-v5-compat';

let prevPathname: string;

/**
 * Listen and notify Segment of client-side page updates.
 *
 * Based on: https://www.pullrequest.com/blog/adding-segment-for-react/
 */
export const segmentLocationListener: LocationListener = location => {
  try {
    const { pathname } = location;
    if (prevPathname === pathname) {
      return;
    }

    const { category, sanitizedPathname, organization } =
      sanitizePathname(pathname);

    const routeInfo = getRouteInfoFromLocation({
      organizationSlug: organization,
      location,
    });

    window.analytics.page(category, routeInfo?.path ?? undefined, {
      path: sanitizedPathname,
      pathname: pathname.toLowerCase(),
      organization_id: organization,
      ...routeInfo?.params,
    });

    prevPathname = pathname;
  } catch (error) {
    console.error('Error in segmentLocationListener:', error);
  }
};

// Remove variable parts from the pathname (e.g organizationId, mongoDBId, UUID)
export const sanitizePathname = (pathname: string) => {
  const variablePartsRegExp = /\/(?:[^/]*\d[^/]*|(?:\.(?:\.)*)|$)/g;
  const pathRegExp = /\/(?<organization>[^/]+)(?:\/(?<category>[^/]+))?.*/;
  const groups = RegExp(pathRegExp).exec(pathname)?.groups || {};
  const { category, organization } = groups;

  // Remove organization and numeric variable parts from the pathname
  const sanitizedPathname = pathname
    .replace(organization ? `/${organization}` : '', '')
    .replace(variablePartsRegExp, '')
    .toLowerCase();

  return {
    category: category?.toLowerCase(),
    sanitizedPathname,
    organization: organization?.toLowerCase(),
  };
};

export const getRouteInfoFromLocation = ({
  organizationSlug,
  location,
}: {
  organizationSlug?: string;
  location: Location;
}) => {
  const normalizedRoutes = Object.values(compiledRoutes).map(route => ({
    path: `${organizationSlug}${route}`,
  }));

  const [match] = matchRoutes(normalizedRoutes, location) ?? [];
  if (!match) return null;

  const path = organizationSlug
    ? match.route.path.replace(organizationSlug, '')
    : match.route.path;

  const hasParams = Object.keys(match.params).length > 0;
  let snakeCaseParams = {};

  if (hasParams)
    snakeCaseParams = Object.entries(match.params).reduce(
      (prev, [key, value]) => {
        if (value) prev[snakeCase(key)] = value;

        return prev;
      },
      {} as Record<string, string>
    );

  return { path, params: snakeCaseParams };
};
