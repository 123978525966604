import { ProcessingFormTypeFieldItem } from 'components/Form/ProcessingForm/ProcessingFormTypeField';
import { DocumentCategory } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { DOCUMENT_TYPES } from './consts';

type DocumentTypeKeys = (typeof DOCUMENT_TYPES)[keyof typeof DOCUMENT_TYPES];

type DocumentTypeTranslationKeys = Record<DocumentTypeKeys, string> & {
  [key: string]: string | undefined;
};

export const documentTypeTranslationKeys: DocumentTypeTranslationKeys = {
  Rechnungseingang:
    'document.requestApproval.defaultDocumentTypes.Rechnungseingang',
  Rechnungsausgang:
    'document.requestApproval.defaultDocumentTypes.Rechnungsausgang',
  Kasse: 'document.requestApproval.defaultDocumentTypes.Kasse',
  Sonstige: 'document.requestApproval.defaultDocumentTypes.Sonstige',
  Rechnungskorrektur:
    'document.requestApproval.defaultDocumentTypes.Rechnungskorrektur',
  Eingangsgutschrift:
    'document.requestApproval.defaultDocumentTypes.Eingangsgutschrift',
};

/**
 * Returns a function that maps `DocumentCategory` to
 * `ProcessingFormTypeFieldItem`
 */
export const useToTypeFieldItem = () => {
  const [t] = useTranslation();

  const toTypeFieldItem = ({
    direction,
    documentType,
    isDefault,
  }: Pick<
    DocumentCategory,
    'direction' | 'documentType' | 'isDefault' | 'isRds1_0Exportable'
  >): ProcessingFormTypeFieldItem => {
    let label = documentType;
    if (isDefault) {
      const translationKey = documentTypeTranslationKeys[documentType];
      if (translationKey) label = t(translationKey);
    }

    return {
      key: documentType,
      children: label,
      direction:
        direction in DocumentDirection
          ? (direction as DocumentDirection)
          : DocumentDirection.others_document,
    };
  };

  return toTypeFieldItem;
};
