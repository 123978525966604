import { useCountOpenApprovalsQuery } from 'generated-types/graphql.types';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuApprovals = ({ ...iconProps }) => {
  const { data: approvalCount, loading: approvalCountLoading } =
    useCountOpenApprovalsQuery({
      fetchPolicy: 'cache-first',
    });

  return (
    <NavigationIcon
      icon="menuApprovals"
      count={approvalCount?.countOpenApprovals.count}
      countLoading={approvalCountLoading}
      {...iconProps}
    />
  );
};
