import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { ReactNode, useMemo } from 'react';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ReviewStatusInfoBox } from './ReviewStatusInfoBox';

interface InfoBoxProps {
  reimbursement?: Reimbursement;
}

export const InfoBox = ({ reimbursement }: InfoBoxProps) => {
  const { createdAt, status } = reimbursement ?? {};

  const infoBox: Partial<Record<ReimbursementCaseStatus, ReactNode>> = useMemo(
    () => ({
      REVIEW: <ReviewStatusInfoBox createdAt={createdAt} />,
    }),
    [createdAt]
  );

  if (!status || !infoBox[status]) return null;

  return infoBox[status];
};
