import { Grid } from '@candisio/design-system';
import { ShowPinButtonContainer } from 'containers/credit-cards/CreditCardCarousel/components/ShowPinButton';
import { SensitiveDataProps } from 'containers/credit-cards/CreditCardCarousel/types';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { usePermissionsByCreditCardId } from 'containers/credit-cards/hooks/usePermissionsByCreditCardId';
import { CardStatus, CardType } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LockAndUnlockCard } from './LockAndUnlockCard';
import { ShowAndHideCardNumbers } from './ShowAndHideCardNumbers';
import { TerminateCard } from './TerminateCard/TerminateCard';

interface CreditCardActionsProps {
  cardId: string;
  cardholder: {
    cardholderName: string;
    membershipId?: string | null;
  };
  refNum?: string | null;
  cardType: CardType;
  cardStatus: CardStatus;
  onClose: () => void;
  sensitiveData: SensitiveDataProps;
  onSetMode: (mode: DrawerMode) => void;
}

export const CreditCardActions = ({
  cardId,
  cardType,
  refNum,
  cardStatus,
  cardholder,
  onClose,
  sensitiveData,
  onSetMode,
}: CreditCardActionsProps) => {
  const user = useCurrentUser();
  const { canLock, canUnlock, canTerminate } = usePermissionsByCreditCardId({
    cardId,
  });

  const { isCardManager } = useUserRoles();

  const props = {
    refNum,
    cardId,
    cardType,
    cardStatus,
    onSetMode,
    cardholderName: cardholder.cardholderName,
    membershipId: cardholder.membershipId,
  };

  const isCardOwnedByUser = cardholder.membershipId === user?.id;

  const canUserViewCardPin =
    isCardOwnedByUser &&
    [CardType.Physical, CardType.Black].includes(cardType) &&
    cardStatus === CardStatus.Active;

  const canUserViewCardNumbers =
    isCardOwnedByUser && cardStatus === CardStatus.Active;

  const canUserLockAndUnlockCard =
    (canLock || canUnlock) &&
    [CardStatus.Active, CardStatus.Locked, CardStatus.LockedPin].includes(
      cardStatus
    );

  const canUserTerminateCard =
    (canTerminate &&
      [
        CardStatus.Active,
        CardStatus.Locked,
        CardStatus.Pending,
        CardStatus.LockedPin,
      ].includes(cardStatus)) ||
    (cardStatus === CardStatus.Processing && isCardManager);

  return (
    <Grid
      gap="space8"
      templateColumns={canUserViewCardNumbers ? '180px 1fr' : 'none'}>
      {canUserViewCardNumbers && (
        <ShowAndHideCardNumbers {...props} sensitiveData={sensitiveData} />
      )}
      <Grid autoFlow="column" gap="space8" placeContent="end">
        {canUserViewCardPin && (
          <ShowPinButtonContainer cardId={cardId} variant="tertiary" />
        )}
        {canUserLockAndUnlockCard && <LockAndUnlockCard {...props} />}
        {canUserTerminateCard && <TerminateCard onClose={onClose} {...props} />}
      </Grid>
    </Grid>
  );
};
