import { Flex, Grid } from '@candisio/design-system';
import { DocumentAccessCardSkeleton } from 'components/DocumentAccess/DocumentAccessCardSkeleton';
import { DocumentAccessUsersRow } from 'components/DocumentAccess/DocumentAccessUsersRow';
import { AccessByData } from 'components/DocumentAccess/useDocumentAccessLevelsData';
import { ReactNode } from 'react';

interface AccessCardContainerProps {
  loading: boolean;
  accessData: AccessByData[] | null;
  title?: ReactNode;
  description?: ReactNode;
}

export const DocumentAccessCard = ({
  loading,
  accessData,
  title,
  description,
}: AccessCardContainerProps) => {
  if (loading) {
    return <DocumentAccessCardSkeleton />;
  }

  if (!accessData?.some(role => role?.users?.length)) {
    return null;
  }

  return (
    <Flex direction="column" gap="space8">
      {title}
      <Grid
        width="100%"
        gap="space16"
        background="graybg"
        padding="space16"
        borderRadius="small">
        {accessData.map(
          role =>
            role && (
              <DocumentAccessUsersRow
                key={role.roleName}
                users={role.users}
                roleTranslationKey={role.roleTranslationKey}
                accessTranslationKey={role.accessTranslationKey}
              />
            )
        )}
      </Grid>
      {description}
    </Flex>
  );
};
