import {
  Button,
  Flex,
  Popover,
  usePopover,
  useTheme,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  Notification,
  NotificationData,
  UploadFileItem,
  useClearAllNotificationsMutation,
  useFileUploadsQuery,
  useGetNotificationsQuery,
  useRemoveAllMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { usePolling } from 'hooks/usePolling';
import { Routes } from 'models';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import useRouter from 'use-react-router';
import { useShowError } from 'utils/error_message';
import { useShowQaUtils } from 'views/AppContainer/components/Header/components/QaUtils/utils';
import { EmptyNotificationList } from 'views/AppContainer/components/NavigationSidebar/components/MenuItems/MenuNotifications/NotificationsPopoverContent/EmptyNotificationList/EmptyNotificationList';
import { getUploadStatus } from '../../../utils';
import { IndicatorStatus, ProgressIndicator } from '../../ProgressIndicator';
import { NotificationsList } from '../NotificationsList/NotificationsList';
import { notificationsQuery } from '../queries';
import { FilledPopoverContainer } from '../styles';

/**@deprecated */
const NotificationsPopoverContent = ({
  notifications,
  onClose,
  fileUploadsInProgress,
}: {
  notifications: Notification[];
  hasFailedEmails: boolean;
  onClose: () => void;
  fileUploadsInProgress: boolean;
}) => {
  const [t] = useTranslation();
  const showError = useShowError();
  const [clearList] = useRemoveAllMutation();
  const [clearAllNotifications] = useClearAllNotificationsMutation({
    refetchQueries: [{ query: notificationsQuery }],
  });

  const dispatch = useDocumentUploadDispatch();

  const onShowButtonClick = () => {
    dispatch('showModal');
    onClose?.();
  };

  const {
    match: {
      params: { organizationSlug },
    },
  } = useRouter<{ organizationSlug: string }>();

  const onClearAll = useCallback(async () => {
    try {
      await clearList();
      await clearAllNotifications();
    } catch (e) {
      showError(e as GQLError);
    }
  }, [clearList, clearAllNotifications, showError]);

  return (
    <FilledPopoverContainer>
      <Button
        icon="close"
        size="small"
        label={t('uploads.close')}
        data-cy="nav-upload-notifications-modal-close"
        position="absolute"
        top="space4"
        right="space8"
        onClick={onClose}
        variant="tertiary"
      />
      {!!notifications.length ? (
        <NotificationsList
          notifications={notifications}
          onClose={onClose}
          organizationSlug={organizationSlug}
        />
      ) : (
        <EmptyNotificationList />
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Button
          variant="tertiary"
          size="small"
          onClick={onClearAll}
          data-cy="nav-upload-notifications-clear"
          disabled={fileUploadsInProgress || notifications.length < 1}>
          {t('uploads.clear')}
        </Button>
        <div>
          <Button variant="tertiary" size="small" onClick={onClose}>
            <RouterLink
              fontSize="small"
              to={`/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}`}>
              {t('uploads.openEmailImport')}
            </RouterLink>
          </Button>
          <Button variant="tertiary" size="small" onClick={onShowButtonClick}>
            {t('uploads.openUploadBox')}
          </Button>
        </div>
      </Flex>
    </FilledPopoverContainer>
  );
};

/**
 * Fetches UploadNotifications from client's GraphQL schema and
 * Notifications from back-end
 */
const useCustomNotificationsQuery = () => {
  const { data: { fileUploads = [] } = {} } = useFileUploadsQuery();
  const { data, startPolling, stopPolling } = useGetNotificationsQuery();

  usePolling({ pollInterval: 10000, startPolling, stopPolling });

  const mappedFileUploads: Notification[] = fileUploads.map(file => {
    const item: UploadFileItem = { ...file, __typename: 'UploadFileItem' };
    const record: Notification = {
      id: file.id,
      data: item,
    };

    return record;
  });

  // result type from useGetNotificationsQuery isn't Notification because we're
  // extending the NotificationData type in the FE site, therefore casting is required
  const emailErrors = (data?.notifications?.records || []) as Notification[];

  const notifications: Notification[] = [...emailErrors, ...mappedFileUploads];

  return { notifications };
};

const isUploadFileItem = (data: NotificationData): data is UploadFileItem =>
  data.__typename === 'UploadFileItem';

/**@deprecated */
export const UploadEmailNotifications = () => {
  const [t] = useTranslation();
  const { notifications } = useCustomNotificationsQuery();
  const {
    close,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
    open,
  } = usePopover({ placement: 'bottom right' });

  const hasFailedEmails = useMemo(
    () =>
      notifications.some(
        notification =>
          notification.data.__typename === 'EmailImportErrorNotification'
      ),
    [notifications]
  );

  /**
   * The overall notification status is calculated following the same rules:
   * - If there are uploads in progress, show processing
   * - If there are other types of notifications (EmailImportError), show error
   * - Otherwise, show the upload status
   *
   * IMPORTANT: it currently assumes that all notification should display the error indicator
   */
  const overallStatus = useMemo(() => {
    const files = notifications
      .filter(({ data }) => isUploadFileItem(data))
      .map(({ data }) => data as UploadFileItem);

    const uploadStatus = getUploadStatus(files);

    if (
      uploadStatus !== IndicatorStatus.PROCESSING &&
      files.length !== notifications.length
    ) {
      return IndicatorStatus.ERROR;
    }

    return uploadStatus;
  }, [notifications]);

  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const setStatusColor = () => {
    if (overallStatus === IndicatorStatus.IDLE && isHovered)
      return colors.blue500;

    switch (overallStatus) {
      case IndicatorStatus.DONE:
        return colors.green500;
      case IndicatorStatus.ERROR:
        return colors.red500;
      case IndicatorStatus.WARNING:
        return colors.yellow500;
      case IndicatorStatus.PROCESSING:
        return colors.blue500;
      default:
        return colors.gray800;
    }
  };

  const showQaUtils = useShowQaUtils();

  return (
    <>
      <Flex
        {...triggerProps}
        data-cy="nav-upload-notifications"
        onClick={open}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={triggerRef}
        style={
          showQaUtils
            ? { cursor: 'pointer', width: '90px', justifyContent: 'center' }
            : { cursor: 'pointer' }
        }>
        <Flex
          alignItems="center"
          gap="space8"
          color={setStatusColor()}
          justifyContent="space-between">
          {t('uploads.label')}
          <ProgressIndicator status={overallStatus} />
        </Flex>
      </Flex>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} width="568px">
          <NotificationsPopoverContent
            onClose={() => close()}
            notifications={notifications}
            hasFailedEmails={hasFailedEmails}
            fileUploadsInProgress={overallStatus === IndicatorStatus.PROCESSING}
          />
        </Popover>
      )}
    </>
  );
};
