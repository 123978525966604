import {
  Avatar,
  Flex,
  Grid,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { NotificationProps } from '../getNotificationInfo';
export const DocumentApprovalRequestedInAppNotification = (
  props: NotificationProps<'DocumentApprovalRequestedInAppNotification'>
) => {
  const {
    requester,
    invoiceContactApprovalRequested,
    requestCreatedAt,
    invoiceAmountApprovalRequested,
    documentNumber,
  } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const formattedInvoiceAmount = amountFormat(
    invoiceAmountApprovalRequested.value,
    invoiceAmountApprovalRequested.currencyCode
  );

  const requesterName = requester.name;

  return (
    <Grid templateRows="auto 1fr auto" gap="space6" height="100%">
      <Grid autoFlow="column" alignItems="start" justifyContent="start">
        <Flex wrap="wrap" fontSize="small" gap="space4">
          <Trans
            i18nKey="notifications:events.approvalRequested.header"
            values={{
              requesterName,
              timeAgo: DateFormatters.fromNow(new Date(requestCreatedAt)),
            }}>
            {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
            <TruncatedText color="gray800" maxWidth="15ch">
              {{ requesterName }}
            </TruncatedText>
            <Text color="gray500"> requested approval from you</Text>
            <Text color="gray500" {...triggerProps} ref={triggerRef}>
              3 minutes ago
            </Text>
          </Trans>
        </Flex>
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {DateFormatters.compactDatetime(new Date(requestCreatedAt))}
          </Tooltip>
        )}
      </Grid>
      <Grid autoFlow="column" gap="space8" justifyContent="start">
        <TruncatedText color="gray800" fontSize="basic">
          {invoiceContactApprovalRequested}
        </TruncatedText>
      </Grid>
      <Grid
        autoFlow="column"
        gap="space4"
        justifyContent="start"
        fontSize="small"
        color="gray500">
        <Text>{formattedInvoiceAmount}</Text>
        <Text>•</Text>
        <Text>{documentNumber}</Text>
        <Text>•</Text>
        <TruncatedText>
          {DateFormatters.compact(new Date(requestCreatedAt))}
        </TruncatedText>
      </Grid>
    </Grid>
  );
};

export const DocumentApprovalRequestedInAppNotificationIcon = ({
  requester,
}: NotificationProps<'DocumentApprovalRequestedInAppNotification'>) => (
  <Avatar
    name={requester.name}
    img={requester.avatarUrl ?? undefined}
    hasTooltip={false}
    size="medium"
  />
);

export const DocumentApprovalRequestedInAppNotificationToast = (
  props: NotificationProps<'DocumentApprovalRequestedInAppNotification'>
) => {
  const { requester, invoiceContactApprovalRequested } = props;

  const requesterName = requester.name;

  return (
    <Grid templateRows="auto 1fr auto" gap="space6" height="100%">
      <Grid autoFlow="column" alignItems="start" justifyContent="start">
        <Flex wrap="wrap" fontSize="small" gap="space4">
          <Trans
            i18nKey="notifications:events.approvalRequested.toast.header"
            values={{
              requesterName,
            }}>
            {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
            <TruncatedText color="gray800" maxWidth="15ch">
              {{ requesterName }}
            </TruncatedText>
            <Text color="gray500"> requested approval from you</Text>
          </Trans>
        </Flex>
      </Grid>
      <Grid autoFlow="column" gap="space8" justifyContent="start">
        <TruncatedText color="gray800" fontSize="basic">
          {invoiceContactApprovalRequested}
        </TruncatedText>
      </Grid>
    </Grid>
  );
};
