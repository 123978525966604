import { keyframes } from '@emotion/react';
import React from 'react';
import {
  LayoutProps,
  StandardHTMLAttributes,
  TokenOrCSSValue,
} from '../../types';
import { Box } from '../Box';

export type Radii = TokenOrCSSValue<'radii', 'borderRadius'>;

export interface SkeletonProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  width: string;
  height: string;
  borderRadius?: Radii;
}

/**
 * Documentation goes here...
 * [Storybook]{@link (link)}
 *
 * @param {string} width (description)
 * @param {string} height (description)
 * @param {Radii} [borderRadius] (description)
 */
export const Skeleton = React.forwardRef<HTMLDivElement, SkeletonProps>(
  ({ borderRadius = 'small', ...restProps }, ref) => {
    const loading = keyframes({
      '0%': {
        backgroundPosition: '100% 50%',
      },
      to: {
        backgroundPosition: '0% 50%',
      },
    });

    return (
      <Box
        {...restProps}
        ref={ref}
        background="linear-gradient(90deg,rgba(190,190,190,.1) 25%,rgba(129,129,129,.14) 37%,rgba(190,190,190,.1) 63%)"
        borderRadius={borderRadius}
        css={{
          animation: `${loading} 5.4s ease infinite`,
          backgroundSize: '400% 100%',
        }}
      />
    );
  }
);
