import { Box, Button, Grid, Heading, Item } from '@candisio/design-system';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { nodocumentData } from 'components/Lottie/Lottie';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { Reimbursement } from '../hooks/useFormattedReimbursement';
import { ReimbursementItem } from './ReimbursementItem';

interface OpenReimbursementListProps {
  isLoading: boolean;
  reimbursements: Reimbursement[];
  reimbursementsCount: number;
  onEndReached?: (index: number) => void;
  onReset?: () => void;
}

export const OpenReimbursementList = ({
  isLoading,
  reimbursements,
  onEndReached,
}: OpenReimbursementListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const organizationSlug = useOrganizationId() ?? '';
  const navigate = useNavigate();

  const archiveReimbursementPath = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.REIMBURSEMENTS}`,
    {
      organizationSlug,
    }
  );

  const handleClick = () => {
    navigate(archiveReimbursementPath);
  };

  return (
    <FilterableList
      heading={
        <Grid templateColumns="1fr auto" alignItems="center" width="100%">
          <Heading as="h2">{t('dashboard.list.openListHeader')}</Heading>
          <Button variant="tertiary" onClick={handleClick}>
            {t('dashboard.list.actions.goToArchiveCta')}
          </Button>
        </Grid>
      }
      isLoading={isLoading}
      onEndReached={onEndReached}
      emptyDataState={
        <Box paddingX="space16">
          <EmptyDataState
            lottieAnimation={nodocumentData}
            translation="reimbursement:dashboard.list.openListNoData"
          />
        </Box>
      }>
      {reimbursements.map(item => (
        <Item key={item.id} textValue={item.id}>
          <ReimbursementItem {...item} />
        </Item>
      ))}
    </FilterableList>
  );
};
