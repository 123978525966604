import { Grid, Skeleton } from '@candisio/design-system';
import { motion } from 'framer-motion';

const MotionGrid = motion(Grid);

export const NotificationLoadingState = () => (
  <MotionGrid
    gap="space16"
    overflow="hidden"
    paddingX="space16"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ ease: 'easeOut', duration: 0.8 }}>
    {Array.from({ length: 7 }, (_, index) => (
      <Skeleton height="55px" width="100%" key={index} />
    ))}
  </MotionGrid>
);
