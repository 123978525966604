import {
  useAcknowledgeContactsImportHistoryErrorsMutation,
  useContactsImportHistoryErrorQuery,
} from 'generated-types/graphql.types';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { DateFormatters } from 'utils/date_formatter';
import { useGetContactImportErrorDetailsTranslation } from 'views/Contacts/ContactImport/ContactImportErrorsPopup/useGetContactImportErrorDetailsTranslation';
import { contactsImportHistoryErrorQuery } from 'views/Contacts/queries';

export const useContactImportErrorsPopup = () => {
  const { data, loading } = useContactsImportHistoryErrorQuery();

  const [acknowledgeContactsImportHistoryErrors] =
    useAcknowledgeContactsImportHistoryErrorsMutation({
      variables: {
        ids: data?.contactsImportHistoryError?.id
          ? [data?.contactsImportHistoryError?.id]
          : [],
      },
      refetchQueries: [{ query: contactsImportHistoryErrorQuery }],
    });

  const userWhoDidLastImport =
    data?.contactsImportHistoryError?.importedBy.name;

  const importedAt = data?.contactsImportHistoryError?.importedAt;

  const { detailsTranslation } = useGetContactImportErrorDetailsTranslation(
    importedAt,
    userWhoDidLastImport
  );

  const { track } = useAnalytics();

  const errorFileUrl = data?.contactsImportHistoryError?.errorFile.url;

  return {
    acknowledgeContactsImportHistoryErrors,
    detailsTranslation,
    loading,
    track,
    errorFileUrl,
    importedAtText: DateFormatters.compactDatetime(new Date(importedAt ?? '')),
    hasError: Boolean(data?.contactsImportHistoryError),
  };
};
