import { ErrorMessages } from 'utils/zodFormValidation';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';

const MAX_CHARS = 1000;

export const commentFormSchema = z.object({
  comment: nonEmptyString.max(MAX_CHARS),
});
export const commentFormErrorMessages: ErrorMessages<typeof commentFormSchema> =
  {
    comment: {
      label: 'reimbursementView.rightSection.form.comment.label',
    },
  };

export type CommentFormOutput = z.infer<typeof commentFormSchema>;

export type CommentFormValues = Partial<CommentFormOutput>;
