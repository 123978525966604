import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentBookingAssociation,
  useAssociatePurchaseOrdersToDocumentMutation,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSapPurchaseOrders } from 'views/DocumentDetails/components/ThreeWayMatch/hooks/useSapPurchaseOrders';
import {
  documentQueries,
  getDocumentBookingAssociationsByInvoiceId,
  getSapPurchaseOrdersQuery,
} from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';

interface UseLinkPurchaseOrderToDocumentProps {
  documentId: string;
  onCompleted?: (data: DocumentBookingAssociation[]) => void;
}

export const useLinkPurchaseOrderToDocument = ({
  documentId,
  onCompleted: onLinkingCompleted,
}: UseLinkPurchaseOrderToDocumentProps) => {
  const { purchaseOrders: linkedPurchaseOrders } =
    useSapPurchaseOrders(documentId);

  const { success, error } = useToastMessage();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const [linkPurchaseOrderToDocumentMutation, { loading: isLinkingLoading }] =
    useAssociatePurchaseOrdersToDocumentMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: documentQueries.forDraftForm, variables: { id: documentId } },
        {
          query: getDocumentBookingAssociationsByInvoiceId,
          variables: { invoiceId: documentId },
        },
        { query: getSapPurchaseOrdersQuery, variables: { id: documentId } },
        { query: documentHistoryQuery, variables: { id: documentId } },
      ],
      onCompleted: data => {
        onLinkingCompleted?.(
          data?.associatePurchaseOrdersToDocument as DocumentBookingAssociation[]
        );
      },
    });

  const linkPurchaseOrder = useCallback(
    async (purchaseOrderId: string, orderNumber?: string) => {
      if (documentId && purchaseOrderId) {
        const previoslyLinkedPurchaseOrder =
          linkedPurchaseOrders.length > 0
            ? (linkedPurchaseOrders ?? [])?.map(po => po?._id as string)
            : [];

        if (previoslyLinkedPurchaseOrder?.includes(purchaseOrderId)) {
          return;
        }

        const { data, errors } = await linkPurchaseOrderToDocumentMutation({
          variables: {
            documentId,
            purchaseOrderIds: [purchaseOrderId],
          },
        });

        if (data?.associatePurchaseOrdersToDocument?.length) {
          success(
            t('actions.linkingSuccess', {
              orderNumber,
            })
          );
        } else if (errors?.length) {
          if (
            errors[0].message.includes(
              'Goods Receipts not found for the associating Purchase Orders'
            )
          ) {
            error(t('actions.linkingErrorGoodsReceiptNotFound'));
          } else {
            error(t('actions.linkingError', { orderNumber }));
          }
        }
      }
    },
    [
      documentId,
      linkedPurchaseOrders,
      linkPurchaseOrderToDocumentMutation,
      success,
      t,
      error,
    ]
  );

  return {
    linkPurchaseOrder,
    isLinkingLoading,
  };
};
