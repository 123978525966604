import { xor } from 'lodash';
import { Row } from 'react-table';

// We are using an older version of typescript where the use of object type is not recommended and therefore we need the following line. See issue https://github.com/microsoft/TypeScript/issues/21732
// eslint-disable-next-line @typescript-eslint/ban-types
interface HandleRowSelectionParams<TableDataType extends object> {
  row: Row<TableDataType>;
  isSelected: boolean;
  isSingleSelect?: boolean;
  onRowSelected?: (row: TableDataType[]) => void;
  selectedFlatRows: Row<TableDataType>[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const handleRowSelection = <TableDataType extends object>({
  isSelected,
  row,
  selectedFlatRows,
  isSingleSelect,
  onRowSelected,
}: HandleRowSelectionParams<TableDataType>) => {
  if (isSingleSelect) {
    row.toggleRowSelected(isSelected);
    onRowSelected?.(isSelected ? [row.original] : []);
  } else {
    const newSelection = xor(selectedFlatRows, [row]);

    const selectedRows = newSelection.map((selection) => selection.original);

    onRowSelected?.(selectedRows);
  }
};
