import { Color, Tag } from '@candisio/design-system';
import { GoodsReceiptStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { goodsReceiptSapStatuses } from '../consts';

export const mapStatusesToColors: {
  [key in GoodsReceiptStatus]?: Color;
} = {
  [GoodsReceiptStatus.Closed]: 'blue',
  [GoodsReceiptStatus.Open]: 'gray',
};

export const GoodsReceiptStatusCell = ({
  status,
}: {
  status: GoodsReceiptStatus;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const label = goodsReceiptSapStatuses.find(item => item.id === status)?.label;

  return (
    <Tag color={mapStatusesToColors[status] ?? 'gray'} variant="secondary">
      {label ? t('' + label) : t('addPurchaseOrderModal.emptyCellPlaceholder')}
    </Tag>
  );
};
