import { Flex, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { ImportStatus } from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryListItem/components/ImportStatus';
import { CoreDataImportHistoryEntry } from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryListItem/CoreDataImportHistoryListItem';

interface FileDataProps {
  item: CoreDataImportHistoryEntry;
}

export const ImportData = ({ item }: FileDataProps) => {
  const [t] = useTranslation();

  return (
    <Flex
      as="p"
      color="gray500"
      fontSize="small"
      fontWeight="regular"
      gap="space4">
      <ImportStatus item={item} />
      <Text>{t('importHistoryList.bulletPoint')}</Text>
      {item.createdAt && (
        <>
          <Text>
            {DateFormatters.compactDatetime(new Date(item.createdAt))}
          </Text>
          <Text>{t('common:importHistoryList.bulletPoint')}</Text>
        </>
      )}
      <Text>{t('common:importHistoryList.createdBy.system')}</Text>
    </Flex>
  );
};
