import { ComponentType, useMemo } from 'react';
import { ExpenseDetailsCard } from '../components/MiddleSection/ExpenseDetailsCard';
import { ExpenseDetailsCardWithAction } from '../components/MiddleSection/ExpenseDetailsCardWithAction';
import { useReimbursementFormsContext } from '../context/ReimbursementFormsContext';
import { Reimbursement } from './useFormattedReimbursement';

export const useExpenseSectionComponent = (
  reimbursement?: Reimbursement
): ComponentType<any> => {
  const { status } = reimbursement ?? {};

  const { reimbursementUIConfig } = useReimbursementFormsContext();
  const { canManageReimbursementItems } = reimbursementUIConfig;

  const getExpenseSectionForStatus = useMemo(() => {
    switch (status) {
      case 'DRAFT':
        return canManageReimbursementItems
          ? ExpenseDetailsCardWithAction
          : ExpenseDetailsCard;
      case 'CHECK':
        return canManageReimbursementItems
          ? ExpenseDetailsCardWithAction
          : ExpenseDetailsCard;
      default:
        return ExpenseDetailsCard;
    }
  }, [status, canManageReimbursementItems]);

  return getExpenseSectionForStatus;
};
