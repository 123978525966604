import {
  Avatar,
  Flex,
  Grid,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { NotificationProps } from '../getNotificationInfo';
import { Comment } from './Comment/Comment';

export const TransactionCommentCreatedInAppNotification = (
  props: NotificationProps<'TransactionCommentCreatedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  const {
    author,
    commentText,
    transactionAmount,
    cardRefNum,
    merchantName,
    commentCreatedAt,
  } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const { transactionAmountFormatter } = useMoneyFormatter();

  const formattedAmount = transactionAmountFormatter({
    currency: transactionAmount?.currency,
    precision: transactionAmount?.precision ?? 2,
    value: transactionAmount?.value,
  });

  const authorName = `${author.firstName} ${author.lastName}`;

  return (
    <Grid
      templateRows="auto 1fr auto"
      height="100%"
      borderBottomRadius="medium">
      <Flex wrap="wrap" fontSize="small" gap="space4">
        <Trans
          i18nKey="notifications:events.commentAdded.notification"
          values={{
            authorName,
            timeAgo: DateFormatters.fromNow(new Date(commentCreatedAt)),
          }}>
          {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
          <TruncatedText color="gray500" maxWidth="15ch">
            {{ authorName }}
          </TruncatedText>
          <Text color="gray450">mentioned you in a comment</Text>
          <Text color="gray450" {...triggerProps} ref={triggerRef}>
            3 minutes ago
          </Text>
        </Trans>
      </Flex>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(commentCreatedAt))}
        </Tooltip>
      )}

      <Comment>
        <Text color="gray800" fontSize="basic">
          {t('events.commentText', { commentText })}
        </Text>
      </Comment>

      <Grid
        autoFlow="column"
        gap="space4"
        justifyContent="start"
        fontSize="small"
        color="gray450">
        <TruncatedText>{merchantName}</TruncatedText>
        <Text>•</Text>
        <Text>{formattedAmount}</Text>
        <Text>••••{cardRefNum}</Text>
      </Grid>
    </Grid>
  );
};

export const TransactionCommentCreatedInAppNotificationIcon = ({
  author,
}: NotificationProps<'TransactionCommentCreatedInAppNotification'>) => (
  <Avatar
    name={`${author.firstName} ${author.lastName}`}
    img={author.avatarUrl ?? undefined}
    hasTooltip={false}
    size="medium"
  />
);

export const TransactionCommentCreatedInAppNotificationToast = (
  props: NotificationProps<'TransactionCommentCreatedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  const { author, commentText } = props;

  const authorName = `${author.firstName} ${author.lastName}`;

  return (
    <Grid templateRows="auto 1fr auto" height="100%">
      <Grid autoFlow="column" alignItems="start" justifyContent="start">
        <Flex wrap="wrap" fontSize="small" gap="space4">
          <Trans
            i18nKey="notifications:events.commentAdded.toast"
            values={{
              authorName,
            }}>
            {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
            <TruncatedText color="gray800" maxWidth="15ch">
              {{ authorName }}
            </TruncatedText>
            <Text color="gray450">mentioned you in a comment</Text>
          </Trans>
        </Flex>
      </Grid>
      <Grid autoFlow="column" gap="space8" justifyContent="start">
        <TruncatedText color="gray800" fontSize="basic" width="25ch">
          {t('events.commentText', { commentText })}
        </TruncatedText>
      </Grid>
    </Grid>
  );
};
