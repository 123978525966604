export enum ReimbursementCaseStatus {
  Approved = 'APPROVED',
  Approving = 'APPROVING',
  Archived = 'ARCHIVED',
  Check = 'CHECK',
  Draft = 'DRAFT',
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  PartiallyExported = 'PARTIALLY_EXPORTED',
  Rejected = 'REJECTED',
  Review = 'REVIEW',
}
