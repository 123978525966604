import { CustomDatevWarningCode } from 'components/DocumentsTable/types';
import { ExportStatus, PaymentMethod } from 'generated-types/graphql.types';
import { ExportType as GqlExportType } from 'generated-types/resolvers-types';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { appendParamsToQueryString } from 'utils/url-helper';
import {
  DocumentExportStatus,
  ExportEntity,
  ExportType,
  TimeFrameOption,
} from '../../types';
import { deSerializeTimeFrame } from './timeframe';

export const hasTransaction = (doc: ExportEntity): boolean =>
  doc.paymentState.isPaid &&
  'method' in doc.paymentState &&
  doc.paymentState.method === PaymentMethod.CardTransaction;

export const parseDocumentExportStatus = (
  exportStatus?: DocumentExportStatus | null
): ExportStatus => {
  if (!exportStatus?.status) return ExportStatus.Pending;

  const isFailedOrPending =
    exportStatus.status === ExportStatus.Failed ||
    exportStatus.status === ExportStatus.Exporting;

  const hasWarnings =
    !!exportStatus.warnings?.length &&
    !exportStatus.warnings.includes(
      CustomDatevWarningCode.DOCUMENT_ALREADY_EXIST
    );

  const isPartiallyExported = hasWarnings && !isFailedOrPending;

  if (isPartiallyExported) return ExportStatus.PartialyExported;

  return exportStatus.status as ExportStatus;
};

export type ExportTypeWithTimeframe =
  | ExportType.DATEV_BDS
  | ExportType.DATEV_CSV
  | ExportType.SAP_CSV
  | ExportType.CANDIS_CSV;

export const isExportTypeWithTimeframe = (
  exportType: ExportType | undefined
): exportType is ExportTypeWithTimeframe =>
  !!exportType &&
  [
    ExportType.DATEV_BDS,
    ExportType.DATEV_CSV,
    ExportType.SAP_CSV,
    ExportType.CANDIS_CSV,
  ].includes(exportType);

export const getSelectedTimeFrameIndexFromQuery = (
  timeFrames: TimeFrameOption[],
  queryParams: ReturnType<typeof qs.parse>
) => {
  const { timeframe: timeFrameFromQuery } = queryParams;
  if (!timeFrameFromQuery || Array.isArray(timeFrameFromQuery)) return -1;

  const { year, month } = deSerializeTimeFrame(timeFrameFromQuery);

  return timeFrames.findIndex(tf => tf.year === year && tf.month === month);
};

export const getTimeFrameQuery = (
  search: string,
  timeframe: string,
  exportType: ExportType | null
) => {
  return `?${appendParamsToQueryString(search, {
    timeframe,
    exportType,
  })}`;
};

export const getDatevExportableEntities = (
  timeFrame: TimeFrameOption | null,
  originExportId: string,
  includeEntitiesWithoutDoc: boolean,
  shouldIncludeProvisions: boolean,
  selectedProvisionIds: string[]
) => {
  if (!timeFrame) {
    return {
      documentIds: [],
      transactionIds: [],
      cardSettlementIds: [],
      provisionIds: [],
      reversalIds: [],
      reimbursementItemsIds: [],
    };
  }

  const exportEntitiesWithoutDocs = originExportId
    ? includeEntitiesWithoutDoc
    : true;

  return {
    documentIds: timeFrame.documentIds,
    transactionIds: exportEntitiesWithoutDocs ? timeFrame.transactionIds : [],
    cardSettlementIds: exportEntitiesWithoutDocs
      ? timeFrame.cardSettlementIds
      : [],
    provisionIds: shouldIncludeProvisions ? selectedProvisionIds : [],
    reversalIds: shouldIncludeProvisions ? timeFrame.reversalIds : [],
    reimbursementItemsIds: timeFrame.reimbursementItemsIds,
  };
};

export const isDatevExportType = (
  exportType: ExportType | GqlExportType | string
): exportType is
  | ExportType.DATEV
  | ExportType.DATEV_BDS
  | GqlExportType.Datev
  | GqlExportType.DatevBds =>
  [ExportType.DATEV, ExportType.DATEV_BDS].includes(exportType as any);
