import { TabView } from 'views/Inbox/models';
import { ReimbursementsPromotion } from 'components/ProductPromotions/Reimbursement/ReimbursementsPromotion';
import { ReimbursementTableColumnKeys } from 'views/Reimbursement/toolkit/types';
import { ArchiveViewLayout } from '../../../views/Archive/components/ArchiveViewLayout';

const tableColumns: ReimbursementTableColumnKeys[] = [
  'status',
  'requesterOfTheReimbursement',
  'title',
  'grossAmount',
  'createdAt',
  'approver',
  'iban',
  'paidAt',
  'isPaid',
];

export const ReimbursementPromotionArchive = () => {
  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_REIMBURSEMENTS}>
      <ReimbursementsPromotion tableColumns={tableColumns} />
    </ArchiveViewLayout>
  );
};
