import { CSSObject } from '@emotion/react';
import { Node } from '@react-types/shared';
import React, { forwardRef } from 'react';
import { mergeProps, useOption } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { ListState } from 'react-stately';
import { TruncatedText } from '../../Molecules';
import { useTheme } from '../../Theme';
import { LayoutProps } from '../../types';
import { Box } from '../Box';

export interface OptionProps extends LayoutProps {
  item: Node<any>;
  state: ListState<any>;
  showSeparator?: boolean;
}

export const Option = forwardRef<HTMLLIElement, OptionProps>(
  ({ item, state, showSeparator = false, ...restProps }, forwardedRef) => {
    // Get props for the option element
    const ref = React.useRef(null);
    const { colors, space, fontSizes, fontWeights, option } = useTheme();

    const { optionProps, isFocused, isSelected, isDisabled } = useOption(
      {
        key: item.key,
        shouldSelectOnPressUp: true,
        shouldFocusOnHover: true,
      },
      state,
      ref
    );

    const baseStyle: CSSObject = {
      background:
        isSelected || isFocused
          ? option.active.background
          : option.default.background,
      fontWeight: isSelected ? fontWeights.semibold : fontWeights.regular,
      color: isDisabled ? colors.gray500 : colors.gray800,
      paddingTop: space.space8,
      paddingRight: space.space16,
      paddingBottom: space.space8,
      paddingLeft: space.space16,
      fontSize: fontSizes.basic,
      outline: 'none',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      transition: 'background 0.2s',
      display: 'flex',
      alignItems: 'center',
    };

    const showSeparatorStyle: CSSObject = {
      ':not(:last-child)': {
        borderBottom: `1px solid ${colors.gray250}`,
      },
    };

    return (
      <Box
        as="li"
        css={[baseStyle, showSeparator && showSeparatorStyle]}
        {...mergeProps(optionProps, restProps)}
        ref={mergeRefs([ref, forwardedRef])}>
        {typeof item.rendered === 'string' ? (
          <TruncatedText
            maxWidth="100%"
            wordBreak="break-word"
            charsAfterEllipsis={5}>
            {item.rendered}
          </TruncatedText>
        ) : (
          item.rendered
        )}
      </Box>
    );
  }
);
