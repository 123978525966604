import { UserRole } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import {
  isAccountant,
  isAdmin,
  isApprover,
  isCardManager,
  isOnlyAccountant,
  isOnlyApprover,
  isOnlyCardManager,
  isOnlyRequester,
  isOwner,
  isRequester,
} from 'utils/authorization';
import { useCandisFeatureFlags } from './useCandisFeatureFlags';
import { usePermissionsForMembership } from './usePermissionsForMembership';

export interface UserRoles {
  roles: UserRole[];
  hasRoles: boolean;
  isAccountant: boolean;
  isAdmin: boolean;
  isRequester: boolean;
  isApprover: boolean;
  /**
   * Indicates whether the membership has permission to manage credit cards
   * in the organization, not necessarily that the member has the `CARD_MANAGER` role.
   */
  isCardManager: boolean;
  isOnlyApprover: boolean;
  isOnlyRequester: boolean;
  isOnlyCardManager: boolean;
  isOnlyAccountant: boolean;
  isOwner: boolean;
}

export const useUserRoles = (): UserRoles => {
  const user = useCurrentUser();
  const permissionsManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.permissionsManagement
  );

  const { 'organization.manage_cards': manageCardsPermission } =
    usePermissionsForMembership();

  const userRoles = useMemo(() => {
    if (!user?.roles?.length) {
      return {
        roles: [],
        hasRoles: false,
        isAccountant: false,
        isAdmin: false,
        isOwner: false,
        isRequester: false,
        isApprover: false,
        isOnlyApprover: false,
        isOnlyRequester: false,
        isCardManager: false,
        isOnlyCardManager: false,
        isOnlyAccountant: false,
      };
    }

    const canManageCards = permissionsManagementFF
      ? Boolean(manageCardsPermission)
      : isCardManager(user);

    return {
      roles: user.roles,
      hasRoles: true,
      isAccountant: isAccountant(user),
      isAdmin: isAdmin(user),
      isRequester: isRequester(user),
      isApprover: isApprover(user),
      isCardManager: canManageCards,
      isOnlyApprover: isOnlyApprover(user),
      isOnlyRequester: isOnlyRequester(user),
      isOnlyCardManager: isOnlyCardManager(user),
      isOnlyAccountant: isOnlyAccountant(user),
      isOwner: isOwner(user),
    };
  }, [manageCardsPermission, permissionsManagementFF, user]);

  return userRoles;
};
