import { useApolloClient } from '@apollo/client';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CreateTagMutation,
  useCreateTagMutation,
  useTagQuery,
  useUpdateTagMutation,
  useUpdateTagStatusMutation,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentTagDetailsProps } from '../../elements/Drawer/DocumentTagDetails';

/**
 * Error messages are defined and come from BE
 * TODO: either localise errors in BE or introduce status code
 * to reliably show localised error in FE
 */
export const DUPLICATE_TAG_NAME_ERROR_MESSAGE_SNIPPET = 'already exists';

export const hasDuplicateNameError = (error: GraphQLError) =>
  error.message.includes(DUPLICATE_TAG_NAME_ERROR_MESSAGE_SNIPPET);

export interface UseDocumentTagsOptions {
  documentTagId?: string;
  onCloseDrawer: () => void;
  onTagCreated?: (tag: CreateTagMutation['createTag']) => void;
}

export const useMutateDocumentTags = ({
  onCloseDrawer,
  documentTagId,
  onTagCreated,
}: UseDocumentTagsOptions) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const client = useApolloClient();

  const { data, loading: loadingTag } = useTagQuery({
    variables: { id: documentTagId ?? '' },
    skip: !documentTagId,
  });

  const [createTag, { loading: creatingTag }] = useCreateTagMutation({
    onCompleted: data => {
      client.cache.evict({ fieldName: 'tags' });
      onTagCreated?.(data.createTag);
    },
  });

  const [updateTag, { loading: updatingTag }] = useUpdateTagMutation();
  const [updateTagStatus, { loading: updatingTagStatus }] =
    useUpdateTagStatusMutation();

  const handleUpdateTagStatus = async (isActive: boolean) => {
    const result = await updateTagStatus({
      variables: {
        input: {
          id: documentTagId ?? '',
          isActive,
        },
      },
      onCompleted: () => {
        client.cache.evict({ fieldName: 'tags' });
      },
    });

    if (result.data?.updateTagStatus.__typename !== 'Tag') {
      return error(t('documentTags.drawer.toast.error'));
    }

    if (isActive) {
      success(t('documentTags.drawer.toast.activated'));
    } else {
      success(t('documentTags.drawer.toast.archived'));
    }

    onCloseDrawer();
  };

  const handleSubmit: DocumentTagDetailsProps['onSubmit'] = async values => {
    let result;
    if (documentTagId) {
      result = await updateTag({
        variables: {
          input: {
            id: documentTagId,
            name: values.name,
            description: values.description,
          },
        },
      });
    } else {
      result = await createTag({
        variables: {
          input: {
            name: values.name,
            description: values.description,
          },
        },
      });
    }

    if (typeof result.errors === 'object' && result.errors.length !== 0) {
      let errorMessage = t('documentTags.drawer.toast.errorMerge');

      if (result.errors.find(hasDuplicateNameError)) {
        errorMessage = t('documentTags.drawer.toast.duplicateNameError', {
          tagName: values.name,
        });
      }

      return error(errorMessage);
    }

    if (documentTagId) {
      success(t('documentTags.drawer.toast.updated'));
    } else {
      success(t('documentTags.drawer.toast.created'));
    }

    onCloseDrawer();
  };

  const archiveTag = () => handleUpdateTagStatus(false);
  const activateTag = () => handleUpdateTagStatus(true);

  return {
    tag: data?.tag,
    loadingTag,
    creatingTag,
    updatingTag,
    updatingTagStatus,
    archiveTag,
    activateTag,
    handleSubmit,
  };
};
