import { Grid } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useOtherIntegration } from 'orgConfig/other';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';
import { DocumentDataExtractionsSettingsCard } from 'views/Settings/DocumentDataExtractions/SettingsCard/DocumentDataExtractionsSettingsCard';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { ContactSettingsContainer } from './components/ContactSettings/ContactSettingsContainer';
import { EmployeesAPNSettingsContainer } from './components/EmployeesAPNSettings/EmployeesAPNSettingsContainer';
import { SFSSettingsTable } from './components/SettingsTable/SFSSettingsTable';

const SFS_Settings_WIDTH = '50rem';
export const SuggestionsAndExtractions = () => {
  const [t] = useTranslation();
  const { isActiveIntegration: isSapIntegration } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const { canUseEmployeeAPNSettings } = useReimbursement();

  const showContactSettings = !shouldUseCoreDataApi && !isSapIntegration;

  return (
    <SettingsLayout
      title={t('settings.smartFieldSuggestions.title')}
      width={SFS_Settings_WIDTH}>
      <Grid gap="space32" alignContent="start">
        <SFSSettingsTable />
        {showContactSettings && <ContactSettingsContainer />}
        {canUseEmployeeAPNSettings && <EmployeesAPNSettingsContainer />}
        {!shouldUseCoreDataApi && <DocumentDataExtractionsSettingsCard />}
      </Grid>
      <InfoPanel
        externalLink={t(
          'settings.smartFieldSuggestions.infoPanel.externalLink',
          {
            returnObjects: true,
          }
        )}
        sections={t('settings.smartFieldSuggestions.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('settings.smartFieldSuggestions.infoPanel.title')}
      />
    </SettingsLayout>
  );
};
