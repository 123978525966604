import { Grid, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { getOrganizationNameInitials, getColorByOrganization } from '../utils';

const MotionGrid = motion(Grid);

export const OrganizationAvatar = ({
  organizationName,
  isNavigationOpen,
  isPopoverOpen,
  hasOnlyOneOrganization,
}: {
  organizationName: string | undefined;
  isNavigationOpen: boolean;
  isPopoverOpen: boolean;
  hasOnlyOneOrganization?: boolean;
}) => {
  const backgroundColor = getColorByOrganization(organizationName ?? '');

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'right',
      passiveTrigger: true,
      delay: 2000,
    });

  return (
    <>
      <MotionGrid
        alignContent="center"
        background={backgroundColor}
        borderRadius="small"
        height="40px"
        justifyContent="center"
        alignItems="center"
        padding="space4"
        width="40px"
        whileHover={hasOnlyOneOrganization ? undefined : { opacity: 0.8 }}
        ref={triggerRef}
        {...triggerProps}>
        <Text fontSize="xlarge" color="gray0">
          {getOrganizationNameInitials(organizationName ?? '')}
        </Text>
      </MotionGrid>
      {isOpen && !isNavigationOpen && !isPopoverOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {organizationName}
        </Tooltip>
      )}
    </>
  );
};
