import {
  Avatar,
  Flex,
  Grid,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { CardRequestType } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { NotificationProps } from '../getNotificationInfo';

const translations: { [key in CardRequestType]?: { message: string } } = {
  ChangeCardLimitRequest: {
    message: 'events.cardRequest.request.limit',
  },
  IssueNewPhysicalCardRequest: {
    message: 'events.cardRequest.request.physicalCard',
  },
  IssueNewSingleUseVirtualCardRequest: {
    message: 'events.cardRequest.request.singleUseCard',
  },
  IssueNewVirtualCardRequest: {
    message: 'events.cardRequest.request.virtualCard',
  },
};

export const CardRequestCreatedInAppNotification = (
  props: NotificationProps<'CardRequestCreatedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const {
    cardRequestType,
    cardRequestCreatedAt,
    requester,
    cardRefNum,
    newRequestedLimit,
    newRequestedTransactionLimit,
  } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const { transactionAmountFormatter } = useMoneyFormatter();

  const authorName = requester.name;

  const formattedLimit = transactionAmountFormatter({
    currency: newRequestedLimit?.currency ?? 'EUR',
    precision: newRequestedLimit?.precision ?? 2,
    value: newRequestedLimit?.value ?? 0,
  });

  const formattedTransactionLimit = transactionAmountFormatter({
    currency: newRequestedTransactionLimit?.currency ?? 'EUR',
    precision: newRequestedTransactionLimit?.precision ?? 2,
    value: newRequestedTransactionLimit?.value ?? 0,
  });

  const translation = translations[cardRequestType];

  return (
    <Grid templateRows="auto 1fr auto" gap="space6" height="100%">
      <Grid autoFlow="column" alignItems="start" justifyContent="start">
        <Flex wrap="wrap" fontSize="small" gap="space4" width="fit-content">
          <Trans
            i18nKey="notifications:events.cardRequest.notification"
            values={{
              authorName,
              timeAgo: DateFormatters.fromNow(new Date(cardRequestCreatedAt)),
            }}>
            {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
            <TruncatedText color="gray500" maxWidth="15ch">
              {{ authorName }}
            </TruncatedText>
            <Text color="gray450" {...triggerProps} ref={triggerRef}>
              3 minutes ago
            </Text>
          </Trans>
        </Flex>
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {DateFormatters.compactDatetime(new Date(cardRequestCreatedAt))}
          </Tooltip>
        )}
      </Grid>
      {translation && (
        <TruncatedText color="gray800" fontSize="basic" fontWeight="400">
          {t(translation.message, { cardRefNum: cardRefNum })}
        </TruncatedText>
      )}

      <Flex gap="space4">
        {cardRequestType !==
          CardRequestType.IssueNewSingleUseVirtualCardRequest && (
          <Flex gap="space4">
            <Text fontSize="small" color="gray450">
              {t('events.cardRequest.limit', { limit: formattedLimit })}
            </Text>
            <Text fontSize="small" color="gray450">
              •
            </Text>
          </Flex>
        )}
        <Text fontSize="small" color="gray450">
          {t('events.cardRequest.transactionLimit', {
            transactionLimit: formattedTransactionLimit,
          })}
        </Text>
      </Flex>
    </Grid>
  );
};

export const CardRequestCreatedInAppNotificationToast = (
  props: NotificationProps<'CardRequestCreatedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { cardRequestType, requester, cardRefNum } = props;

  const requesterName = requester.name;

  const translation = translations[cardRequestType];

  return (
    <Grid templateRows="auto auto" gap="space6" height="100%">
      <TruncatedText color="gray800" maxWidth="15ch">
        {requesterName}
      </TruncatedText>
      {translation && (
        <TruncatedText color="gray800" fontSize="basic">
          {t(translation.message, { cardRefNum: cardRefNum })}
        </TruncatedText>
      )}
    </Grid>
  );
};

export const CardRequestCreatedInAppNotificationIcon = ({
  requester,
}: NotificationProps<'CardRequestCreatedInAppNotification'>) => {
  return (
    <Avatar
      name={requester.name}
      img={requester.avatarUrl ?? undefined}
      hasTooltip={false}
      size="medium"
    />
  );
};
