import { Button, Grid, Text, TruncatedText } from '@candisio/design-system';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat, percentageFormat } from 'utils/format';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';

export interface ReimbursementItemSplitBookingItemProps {
  bookingId: string;
  currency: DocumentCurrency;
  bookingIndex: number;
  onEditSplit?: () => void;
  isReadOnly: boolean;
  vatRate?: number;
  splitAmount?: number;
  costCenter?: string;
  costObject?: string;
  hasBookingError: boolean;
}

export const ReimbursementItemSplitBookingItem = ({
  bookingId,
  bookingIndex,
  onEditSplit,
  isReadOnly,
  vatRate,
  splitAmount,
  costCenter,
  costObject,
  currency,
  hasBookingError,
}: ReimbursementItemSplitBookingItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();

  const isOrgUsingOnlyCostObjects = !showCostCenterField && showCostObjectField;
  const hasCostCenterInput = !!costCenter && !!costCenter;
  const hasCostObjectInput = isOrgUsingOnlyCostObjects && !!costObject;
  const showCostObjectInfo = !hasCostCenterInput && hasCostObjectInput;

  const formattedSplitAmount = amountFormat(splitAmount, currency);

  const formattedVatRate = !isNil(vatRate) ? percentageFormat(vatRate) : null;

  return (
    <Grid
      id={bookingId}
      onClick={onEditSplit}
      gap="space8"
      templateColumns="1fr space24"
      alignItems="center"
      minHeight="space24"
      padding="space8 space12"
      background={hasBookingError ? 'redbg' : undefined}
      cursor="pointer">
      <Grid gap="space8" templateColumns=".65fr 2fr 1.25fr 2.5fr">
        <TruncatedText color="gray400">
          {String(bookingIndex + 1)}
        </TruncatedText>
        <TruncatedText>
          {formattedSplitAmount ??
            t('splitBookings.costCenter.placeholder.notSet')}
        </TruncatedText>

        <>
          <TruncatedText>
            {formattedVatRate ??
              t('splitBookings.costCenter.placeholder.notSet')}
          </TruncatedText>

          {hasCostCenterInput && costCenter && (
            <TruncatedText>{costCenter}</TruncatedText>
          )}
          {showCostObjectInfo && costObject && (
            <TruncatedText>{costObject}</TruncatedText>
          )}
          {!hasCostCenterInput && !hasCostObjectInput && (
            <Text> {t('splitBookings.costCenter.placeholder.notSet')}</Text>
          )}
        </>
      </Grid>
      {onEditSplit !== undefined && (
        <Button
          color="blue"
          size="xsmall"
          variant="tertiary"
          icon={isReadOnly ? 'view' : 'edit'}
          label={
            isReadOnly
              ? t('splitBookings.viewSplit')
              : t('splitBookings.editSplit')
          }
          onClick={onEditSplit}
        />
      )}
    </Grid>
  );
};
