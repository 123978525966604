import { Grid, Spinner } from '@candisio/design-system';
import { DocumentApprovalsForm } from 'components/Form/DocumentApprovalsForm/DocumentApprovalsForm';
import { useSfsForApprovers } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/use-sfs-for-approvers';
import { useApprovalFieldOptions } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useApprovalFieldOptions';
import { useApprovalFormInitialData } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useApprovalFormInitialData';
import { DocumentTagsFieldContainer } from 'containers/Tags/DocumentTagsFieldContainer';
import {
  useBookingKeysActiveQuery,
  useOrganizationQuery,
} from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import {
  useShouldRequireGeneralLedgerAccounts,
  useShouldRequireTaxCodes,
} from 'views/utils/useShouldRequireField';
import { useShowAccountingAreaField } from 'views/utils/useShouldShowField';
import { ProcessedDocument } from '../DocumentForm/toolkit/types';

export interface DocumentApprovalsFormContainerProps {
  document: ProcessedDocument;
  cycleDocument: () => void;
  toggleEditMode?: () => void;
}

export const DocumentApprovalsFormContainer = ({
  document,
  cycleDocument,
  toggleEditMode,
}: DocumentApprovalsFormContainerProps) => {
  const { showDocumentTags } = useEcm();
  const integration = useIntegrationSettings();

  const { smartFieldsValues: smartFields, loading: loadingSfs } =
    useSfsForApprovers(document);

  const {
    fieldConditions,
    initialValues,
    isLoading: loadingInitialData,
  } = useApprovalFormInitialData({ document, smartFields });

  const { fieldOptions, isLoading: loadingFieldOptions } =
    useApprovalFieldOptions({ initialValues });

  const organizationId = useOrganizationId();
  const { data: organizationData, loading: loadingOrg } = useOrganizationQuery({
    variables: { id: organizationId },
    skip: !organizationId,
  });

  const hasCostCenters =
    organizationData?.organization?.hasCostCenters ?? false;

  const hasCostObjects =
    organizationData?.organization?.hasCostObjects ?? false;

  const { data: bookingKeysActiveData, loading: loadingBookingKeys } =
    useBookingKeysActiveQuery();

  const availableBookingKeys = bookingKeysActiveData?.bookingKeysActive ?? [];

  const shouldRequireGeneralLedgerAccount =
    useShouldRequireGeneralLedgerAccounts();

  const shouldShowAccountingAreaField = useShowAccountingAreaField({
    isAccountingAreaOnDocument: !isNilOrEmpty(
      document.accountingArea?.value.id
    ),
  });

  const shouldRequireTaxCode = useShouldRequireTaxCodes();

  const isLoading =
    loadingSfs ||
    loadingInitialData ||
    loadingFieldOptions ||
    loadingOrg ||
    loadingBookingKeys;

  if (isLoading)
    return (
      <Grid alignContent="center" height="100%" justifyContent="center">
        <Spinner size="space64" />
      </Grid>
    );

  const fromContext = {
    availableTaxCodes: availableBookingKeys,
    documentDirection: document.category?.direction?.value,
    hasCostCenters,
    hasCostObjects,
    shouldShowAccountingAreaField,
    integration,
    shouldRequireGeneralLedgerAccount,
    shouldRequireTaxCode,
  };

  return (
    <DocumentApprovalsForm
      toggleEditMode={toggleEditMode}
      cycleDocument={cycleDocument}
      document={document}
      fieldConditions={fieldConditions}
      initialValues={initialValues}
      isLoading={isLoading}
      fieldOptions={fieldOptions}
      smartFields={smartFields}
      formContext={fromContext}
      tagsField={
        showDocumentTags &&
        document.globalDocumentId && (
          <DocumentTagsFieldContainer
            isInvoice
            documentTags={document.tags?.map(t => t.id) ?? []}
            globalDocumentId={document.globalDocumentId}
            documentId={document.id}
          />
        )
      }
    />
  );
};
