import { Avatar, Card, Grid, Heading } from '@candisio/design-system';
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormChipsField } from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale } from 'generated-types/graphql.types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RolesSection } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/invite/InviteNew/RolesSection/RolesSection';
import { TeamMemberFormOutput } from '../../teamMemberFormSchema';
import { IssueCreditCard } from '../IssueCreditCard';

export interface TeamMemberDetailsProps {
  canInviterIssueCard: boolean;
  teamFieldOptions?: {
    defaultItems: Array<{ id: string; name: string }>;
    isLoading?: boolean;
    onEndReached?: () => void;
    onInputChange?: (inputValue: string) => void;
    onChange: (selectedTeams: string[]) => void;
    selectedOptions: Array<{ name: string; id: string }>;
  };
  onSubmit: (data: TeamMemberFormOutput) => Promise<any>;
  formId: string;
  hide?: {
    firstName?: boolean;
    lastName?: boolean;
    roles?: boolean;
    teams?: boolean;
  };
}

export const TeamMemberDetails = ({
  onSubmit,
  teamFieldOptions,
  formId,
  hide,
  canInviterIssueCard,
}: TeamMemberDetailsProps) => {
  const [t] = useTranslation();

  const locales = Object.values(Locale).map(value => ({
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    children: t(`locales.${value}`),
    key: value,
  }));

  const form = useFormContext<TeamMemberFormOutput>();

  return (
    <Grid
      as="form"
      id={formId}
      onSubmit={form.handleSubmit(onSubmit)}
      gap="space24"
      templateRows="1fr auto">
      <Grid gap="space24">
        <AvatarUpload readonly>
          <Avatar size="large" />
        </AvatarUpload>
        <Grid
          background="gray100"
          borderRadius="8px"
          padding="space16"
          rowGap="space12"
          columnGap="space32"
          templateColumns="1fr 1fr 1fr 1fr"
          templateRows="auto auto auto">
          {hide?.firstName ? null : (
            <Grid gridRow="1" gridColumn="1/3">
              <HookFormTextField
                label={t('settings.teamMembers.form.firstName.label')}
                name="firstName"
                autoFocus
              />
            </Grid>
          )}
          {hide?.lastName ? null : (
            <Grid gridRow="1" gridColumn="3/5">
              <HookFormTextField
                name="lastName"
                label={t('settings.teamMembers.form.lastName.label')}
              />
            </Grid>
          )}
          <Grid
            gridRow="2"
            gridColumn="1/4"
            autoFlow="column"
            gap="space20"
            alignItems="center">
            <HookFormTextField
              name="email"
              label={t('settings.teamMembers.form.email.label')}
              inputProps={{
                type: 'email',
              }}
            />
          </Grid>
          <Grid gridRow="3" gridColumn="1/3">
            <HookFormSelectField
              name="locale"
              label={t('settings.teamMembers.form.locale')}
              items={locales}
            />
          </Grid>
        </Grid>
        {canInviterIssueCard && <IssueCreditCard />}

        {hide?.roles ? null : <RolesSection />}
        {hide?.teams ? null : (
          <Grid gap="space8">
            <Heading as="h3">
              {t('settings.teamMembers.form.teams.label')}
            </Heading>
            <Card background="gray100">
              <HookFormChipsField
                name="teams"
                placeholder={t('settings.teamMembers.form.teams.placeholder')}
                emptyListPlaceholder={t(
                  'settings.teamMembers.form.teams.emptyListPlaceholder'
                )}
                {...teamFieldOptions}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
