import { noop } from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import {
  AriaPositionProps,
  mergeProps,
  useOverlayPosition,
  useOverlayTrigger,
  usePress,
} from 'react-aria';
import { OverlayTriggerProps, useOverlayTriggerState } from 'react-stately';
import { StandardHTMLAttributes } from '../../types';
import { PopoverProps } from './Popover';

export interface UsePopoverConfig extends OverlayTriggerProps {
  /** Popover placement relative to trigger element */
  placement?: AriaPositionProps['placement'];
  /** Force popover position recalculation after trigger position changes */
  updatePlacementOnTriggerMovement?: boolean;
}

/**
 * Associates a Popover with a trigger element and manages its position and state
 */
export const usePopover = ({
  placement = 'top',
  isOpen,
  defaultOpen,
  onOpenChange,
  updatePlacementOnTriggerMovement = false,
}: UsePopoverConfig = {}) => {
  const state = useOverlayTriggerState({
    isOpen,
    defaultOpen,
    onOpenChange,
  });

  const triggerRef = React.useRef<any | null>(null);
  const popoverRef = React.useRef(null);
  const [triggerOffsetTop, setTriggerOffsetTop] = useState<
    number | undefined
  >();

  // Get popover positioning props relative to the trigger
  const {
    overlayProps: positionProps,
    arrowProps,
    placement: placementAxis,
    updatePosition,
  } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: popoverRef,
    placement,
    offset: 8,
    isOpen: state.isOpen,
  });

  // Update vertical position of the popover if element's offsetTop changes
  // (e.g. its moved around the DOM after showing the popover)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (
      updatePlacementOnTriggerMovement &&
      state.isOpen &&
      triggerRef.current instanceof HTMLElement &&
      triggerOffsetTop !== triggerRef.current?.offsetTop
    ) {
      updatePosition();
      setTriggerOffsetTop(triggerRef.current?.offsetTop);
    }
  });

  // Update position of the popover if scroll event happens
  useResizeOnScroll(
    updatePlacementOnTriggerMovement && isOpen ? updatePosition : noop
  );

  // Get props for the trigger and popover. This also handles hiding the popover
  // when a parent element of the trigger scrolls (which invalidates the popover
  // positioning)
  const {
    triggerProps: { onPress, ...triggerAriaProps },
    overlayProps: popoverAriaProps,
  } = useOverlayTrigger({ type: 'dialog' }, state, triggerRef);

  const popoverProps: PopoverProps = mergeProps(
    popoverAriaProps,
    positionProps,
    {
      arrowStyle: arrowProps.style,
      isOpen: state.isOpen,
      onClose: state.close,
      placementAxis,
    }
  );

  const { pressProps } = usePress({ onPress });
  const triggerProps: StandardHTMLAttributes<HTMLElement> = mergeProps(
    triggerAriaProps,
    pressProps
  );

  return {
    open: state.open,
    close: state.close,
    isOpen: state.isOpen,
    setOpen: state.setOpen,
    toggle: state.toggle,
    triggerProps,
    popoverProps,
    triggerRef,
    popoverRef,
    updatePosition,
  };
};

const useResizeOnScroll = (onResize: () => void) => {
  useLayoutEffect(() => {
    document.addEventListener('scroll', onResize, true);

    return () => {
      document.removeEventListener('scroll', onResize, true);
    };
  }, [onResize]);
};
