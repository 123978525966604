import { CSSObject } from '@emotion/react';
import React, { ElementRef } from 'react';

import {
  AriaCheckboxProps,
  mergeProps,
  useCheckbox,
  useFocusRing,
  VisuallyHidden,
} from 'react-aria';
import { useToggleState } from 'react-stately';
import { useTheme } from '../../Theme';
import { LayoutProps } from '../../types';
import { Box } from '../Box';
import { Variant } from '../FieldContainer';
import { StatusIndicator } from '../FieldContainer/StatusIndicator';
import { Flex } from '../Flex';
import { Grid } from '../Grid';

const multiIconSvg = (
  <svg width="16" height="16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 8c0-.552.373-1 .833-1h8.334c.46 0 .833.448.833 1s-.373 1-.833 1H3.833C3.373 9 3 8.552 3 8Z" />
  </svg>
);

const checkedIconSvg = (
  <svg width="16" height="16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.707 4.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6 9.586l5.293-5.293a1 1 0 0 1 1.414 0Z" />
  </svg>
);

const CheckboxIcon = ({
  isSelected = false,
  isIndeterminate = false,
}: {
  isSelected?: boolean;
  isIndeterminate?: boolean;
}) => {
  if (isIndeterminate) return multiIconSvg;

  if (isSelected) return checkedIconSvg;

  return null;
};

type CheckboxStyleVariants = 'default' | 'readonly' | 'disabled';
type CheckboxThemeType = ReturnType<typeof useTheme>['checkbox'];
type CheckboxStyleType = CheckboxThemeType[CheckboxStyleVariants];

export interface CheckboxProps extends LayoutProps, AriaCheckboxProps {
  labelHidden?: boolean;
  message?: React.ReactNode;
  variant?: Variant;
  /** @deprecated not used internally */
  isLoading?: boolean;
  isListCheckbox?: boolean;
  showMessageOnFocus?: boolean;
}

/**
 * The Checkbox component is a typical combination of an input box which appears as checked when
 * its value has been selected and a description of its value next to it.
 *
 * @param {React.ReactNode} [children] Label
 * @param {boolean} [defaultSelected] Whether the element should be selected (uncontrolled)
 * @param {boolean} [isSelected] Whether the element should be selected (controlled)
 * @param {(isSelected: boolean) => void} [onChange] Handler that is called when the element's selection state changes.
 * @param {boolean} [isDisabled] Disabled state
 * @param {boolean} [isReadOnly] Read-only state
 * @param {boolean} [isRequired] Whether user input is required on the input before form submission.
 * @param {boolean} [isIndeterminate] PRESENTATIONAL ONLY - for when a subset of a list is selected
 * @param {boolean} [labelHidden] visually hide label
 * @param {React.ReactNode} [message] message to display in popover of status
 * @param {Variant} [variant] status variant i.e success, warning, error
 * @param {boolean} [isLoading] Form data loading state to display the input skeleton
 * @param {boolean} [showErrorMessageOnFocus] Show error message on focus
 *
 * @see [Storybook](https://candisio.github.io/design-system/?path=/docs/atoms-forms-checkbox)
 */
export const Checkbox = React.forwardRef<ElementRef<'label'>, CheckboxProps>(
  (props, ref) => {
    const {
      children,
      defaultSelected,
      isSelected: isSelectedProp,
      isIndeterminate,
      isReadOnly,
      isDisabled,
      labelHidden,
      message,
      onChange,
      onFocus,
      onBlur,
      variant,
      isLoading,
      isListCheckbox = false,
      showMessageOnFocus = false,
      value,
      'aria-label': ariaLabel,
      ...restProps
    } = props;

    const state = useToggleState(props);

    const inputRef = React.useRef<ElementRef<'input'>>(null);

    const { inputProps, labelProps } = useCheckbox(props, state, inputRef);

    const { checkbox } = useTheme();
    const { isFocused, isFocusVisible, focusProps } = useFocusRing();

    const labelStyle: CSSObject = {
      userSelect: 'none',
      color: checkbox.label.color,
      fontSize: checkbox.label.fontSize,
      lineHeight: checkbox.label.lineHeight,
    };

    const containerStyle: CSSObject = {
      gap: labelHidden ? undefined : checkbox.label.gap,
      alignItems: 'start',
      gridTemplateColumns: 'max-content auto',
      gridTemplateRows: 'auto',
    };

    const checkboxStyle: CSSObject = {
      color: 'gray0',
      height: checkbox.box.height,
      width: checkbox.box.width,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: checkbox.box.borderRadius,
      transition: 'all 200ms ease',
    };

    let styleState: CheckboxStyleType = checkbox.default;
    if (isDisabled) styleState = checkbox.disabled;
    if (isReadOnly) styleState = checkbox.readonly;

    const isSelected = state.isSelected;
    const isActive = !isReadOnly && !isDisabled;
    const hasStatusIndicator = !!variant && variant !== 'default';
    const showStatusIndicator = !isDisabled && hasStatusIndicator;

    const checkboxStyles = [
      checkboxStyle,
      styleState.checkbox.default,
      isFocusVisible && styleState.checkbox.focused,
      isSelected && !isIndeterminate && styleState.checkbox.selected,
      isIndeterminate && !isSelected && styleState.checkbox.indeterminate,
    ];

    if (isListCheckbox) {
      return (
        <Box as="label" {...labelProps} ref={ref}>
          <VisuallyHidden>
            <input
              {...mergeProps(inputProps, focusProps)}
              disabled={false}
              aria-disabled={!isActive}
              ref={inputRef}
            />
          </VisuallyHidden>
          <Box css={checkboxStyles}>
            <CheckboxIcon
              isSelected={isSelected}
              isIndeterminate={isIndeterminate}
            />
          </Box>
        </Box>
      );
    }

    // this component doesn't have a default loading state
    // because it causes flickering in the table checkboxes
    // when the user is searching
    return (
      <Grid
        as="label"
        css={[labelStyle, styleState.label.default]}
        inline
        {...mergeProps(labelProps, restProps)}
        ref={ref}>
        <VisuallyHidden>
          <input
            {...mergeProps(inputProps, focusProps)}
            disabled={false}
            aria-disabled={!isActive}
            tabIndex={isActive ? 0 : -1}
            onKeyDownCapture={(e) => {
              if (e.key === 'Enter') state.toggle();
            }}
            ref={inputRef}
          />
        </VisuallyHidden>
        <Grid css={containerStyle}>
          <Flex css={checkboxStyles}>
            <CheckboxIcon
              isSelected={isSelected}
              isIndeterminate={isIndeterminate}
            />
          </Flex>
          <Flex alignItems="center" gap="space8">
            {labelHidden ? (
              <VisuallyHidden>{children}</VisuallyHidden>
            ) : (
              // because label passed to Checkbox as children can have larger
              // line-height than would be needed for perfect alignment with the
              // checkbox, we need to force it to be positioned a bit lower.
              // This is a best guess according to currently used font-sizes and
              // line-heights and will not work if we move away from them.
              <Box top="-2px">{children}</Box>
            )}
            {showStatusIndicator && (
              <StatusIndicator
                message={message}
                //@ts-ignore - TODO: fix this annotation
                variant={variant}
                showMessage={showMessageOnFocus && isFocused}
              />
            )}
          </Flex>
        </Grid>
      </Grid>
    );
  }
);
