import { useCountPayableDocumentsQuery } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { MAX_PAYABLE_DOCUMENT_COUNT } from 'views/consts';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuPayments = ({ ...iconProps }: { showMenuIcon?: boolean }) => {
  const { isAdmin, isAccountant } = useUserRoles();
  const organization = useFullOrganization();
  const { ft, show } = useFeatureToggle();

  const { data: paymentsCount, loading: paymentsCountLoading } =
    useCountPayableDocumentsQuery({
      skip: !(isAdmin || isAccountant),
      fetchPolicy: 'cache-first',
    });

  const payableDocumentsCount = paymentsCount?.countPayableDocuments.count
    ? Math.min(
        paymentsCount?.countPayableDocuments.count,
        MAX_PAYABLE_DOCUMENT_COUNT
      )
    : undefined;

  const shouldShowPaymentMenuIcon =
    show(ft.SEPA_XML) &&
    (isAccountant || isAdmin) &&
    organization?.payment?.isActive;

  return (
    <NavigationIcon
      {...iconProps}
      icon="menuPayments"
      count={payableDocumentsCount}
      countLoading={paymentsCountLoading}
      showMenuIcon={shouldShowPaymentMenuIcon}
    />
  );
};
