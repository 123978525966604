export type BookingEntryKeys =
  | 'taxPresentation'
  | 'vatRate'
  | 'splitAmount'
  | 'note'
  | 'postingText'
  | 'taxCode'
  | 'costCenter'
  | 'costObject'
  | 'extraCostInfo'
  | 'generalLedgerAccount'
  | 'artistSocialInsuranceCode';

type EntryKeys = 'grossAmount' | 'remainingAmount' | 'currency' | 'bookings';

export type BookingFieldNames = {
  [K in BookingEntryKeys]: `reimbursementItemBookings.${number}.bookings.${number}.${K &
    string}`;
};

export type TopLevelFieldNames = {
  [K in EntryKeys]: `reimbursementItemBookings.${number}.${K & string}`;
};

export const generateSplitBookingEntryFieldNames = (
  entryIndex: number
): TopLevelFieldNames => ({
  // Top level fields
  grossAmount: `reimbursementItemBookings.${entryIndex}.grossAmount`,
  remainingAmount: `reimbursementItemBookings.${entryIndex}.remainingAmount`,
  currency: `reimbursementItemBookings.${entryIndex}.currency`,
  bookings: `reimbursementItemBookings.${entryIndex}.bookings`,
});

export const generateBookingsArrayFieldNames = (
  entryIndex: number,
  bookingIndex: number
): BookingFieldNames => ({
  // Booking entry fields
  costCenter: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.costCenter`,
  costObject: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.costObject`,
  extraCostInfo: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.extraCostInfo`,
  generalLedgerAccount: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.generalLedgerAccount`,
  splitAmount: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.splitAmount`,
  note: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.note`,
  postingText: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.postingText`,
  taxCode: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.taxCode`,
  taxPresentation: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.taxPresentation`,
  vatRate: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.vatRate`,
  artistSocialInsuranceCode: `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.artistSocialInsuranceCode`,
});

export const getBookingFieldPath = (
  entryIndex: number,
  bookingIndex: number,
  field: BookingEntryKeys
): string => {
  return `reimbursementItemBookings.${entryIndex}.bookings.${bookingIndex}.${field}`;
};

export const getTopFieldPath = (
  entryIndex: number,
  field: EntryKeys
): string => {
  return `reimbursementItemBookings.${entryIndex}.${field}`;
};
