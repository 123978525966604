import { Button } from '@candisio/design-system';
import { useGetNextFreeAccountsPayableNumberForEmployeesLazyQuery } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetEmployeesAccountsPayableNumber } from 'views/Settings/SuggestionsAndExtractions/components/EmployeesAPNSettings/useSetEmployeesAccountsPayableNumber';

interface AccountsPayableNumberActionsProps {
  currentNumber?: string;
  setCurrentNumber?: (input: string) => void;
  disabled?: boolean;
  findNextNumberOnRender: boolean;
}

export const AccountsPayableNumberActions = ({
  currentNumber,
  disabled,
  findNextNumberOnRender,
  setCurrentNumber,
}: AccountsPayableNumberActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);

  const { enabled } = useSetEmployeesAccountsPayableNumber();

  const [getNextFreeAccountsPayableNumber, { loading, called }] =
    useGetNextFreeAccountsPayableNumberForEmployeesLazyQuery({
      variables: {
        input: currentNumber?.replaceAll(' ', '') ?? '',
      },
      fetchPolicy: 'network-only',
    });

  const handleFindNext = useCallback(async () => {
    const { data: refetchData } = await getNextFreeAccountsPayableNumber({
      variables: {
        input: currentNumber?.replaceAll(' ', '') ?? '',
      },
    });

    if (refetchData?.getNextFreeAccountsPayableNumberForEmployees) {
      setCurrentNumber?.(
        refetchData.getNextFreeAccountsPayableNumberForEmployees
      );
    }
  }, [currentNumber, getNextFreeAccountsPayableNumber, setCurrentNumber]);

  useEffect(() => {
    if (findNextNumberOnRender && !called && enabled) void handleFindNext();
  }, [findNextNumberOnRender, handleFindNext, called, enabled]);

  return (
    <Button
      justifySelf="left"
      icon="next"
      variant="tertiary"
      size="small"
      iconSize="space16"
      top="6px"
      label={t(
        'form.accountsPayableNumber.findNextAccountNumber.findNextButton'
      )}
      disabled={disabled || loading}
      onClick={handleFindNext}
    />
  );
};
