import { useEffect, useRef } from 'react';
import { Filters, SortingRule } from 'react-table';

// We are using an older version of typescript where the use of object type is not recommended and therefore we need the following line. See issue https://github.com/microsoft/TypeScript/issues/21732
// eslint-disable-next-line @typescript-eslint/ban-types
interface UseControlledFiltersAndSortOptions<TableDataType extends object> {
  onSort?: (sortBy: SortingRule<TableDataType>[]) => void;
  sortBy: SortingRule<TableDataType>[];
  onFilter?: (filters: Filters<TableDataType>) => void;
  filters: Filters<TableDataType>;
}

/**
 * Enables controlling the table sorting and filtering from outside the table.
 *
 * As of the current version of React Table this is “offical” way to achieve it.
 * https://react-table.tanstack.com/docs/faq#how-can-i-use-the-table-state-to-fetch-new-data
 *
 * @todo When we upgrade to React Table v8 we can do this in a better way
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export const useControlledFiltersAndSort = <TableDataType extends object>({
  filters,
  sortBy,
  onFilter,
  onSort,
}: UseControlledFiltersAndSortOptions<TableDataType>) => {
  // we don’t want to call onSort and onFilter on initial render
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    onSort?.(sortBy);
    // the sortBy value comes from React Table so we cannot memoize it to prevent calling onSort even though just the filters were changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSort, JSON.stringify(sortBy)]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    onFilter?.(filters);
    // the filters value comes from react table so we cannot memoize it to prevent calling onFilter even though just the sorting was changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilter, JSON.stringify(filters)]);

  // this needs to come last, after the other useEffects
  useEffect(() => {
    isFirstRender.current = false;
  }, []);
};
