import { Box, Grid, Heading, ScrollBox, Tag } from '@candisio/design-system';
import {
  NavLink,
  NavLinkGroup,
  NavLinkIndicator,
  NavLinkLabel,
} from 'components/NavLink/NavLink';
import { AddOnIcon } from 'containers/Entitlements/components/Upsell/components/AddOnIcon';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useTeams } from 'orgConfig/teams/useTeams';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { MouseEventHandler, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';
import { DATEV_DOCUMENT_TYPES } from './Integrations/DATEV/consts';
import { useCheckDatevSetup } from './Integrations/DATEV/setup-checks';
import {
  useNeedsSapConfig,
  useNeedsSapCreditCardConfig,
} from './Integrations/SAP/utils';
import { SETTINGS_ROUTES } from './types';
import { useNavigate } from 'react-router-dom-v5-compat';

export interface SettingsNavSectionProps {
  children: ReactNode;
}

const scrollToTop = () => {
  const mainContent = document.getElementById('main_content');
  if (mainContent) {
    mainContent.scrollTo({
      top: 0,
    });
  }
};

const NewTag = memo(() => {
  const [t] = useTranslation();

  return (
    <Tag color="blue" variant="secondary" callout>
      {t('tag.new')}
    </Tag>
  );
});

export const SettingsNavigation = () => {
  const [t] = useTranslation();
  const { organizationSlug } = useParams<{ organizationSlug?: string }>();

  const { isAdmin, isAccountant } = useUserRoles();
  const isAdminOrAccountant = isAdmin || isAccountant;

  const { showMissingIBANIndicator } = useOrganizationOwnIbansData();

  const [newSettingsPromotionsFF, documentTypesSettingsFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.newSettingsPromotions,
      FEATURE_FLAGS.documentTypesSetting,
    ]);

  const { isTeamsFeatureEnabled } = useTeams();
  const { showDocumentTags, showContractCustomSubCategories } = useEcm();
  const { shouldUseSapConfig } = useSap();
  const creditCards = useCreditCardsSetup();
  const { shouldUseCoreDataApi, shouldUseCandisApi, shouldUseAccountingAreas } =
    useOtherIntegration();

  const indicateSapSetupNeeded = useNeedsSapConfig();
  const indicateSapCreditCardSetupNeeded = useNeedsSapCreditCardConfig();
  const {
    indicateDatevCreditCardSetupNeeded,
    hasReadClientsButNotSelected,
    hasProvisionsAndRequiresSetup,
    accountingCoreDataMissing,
  } = useCheckDatevSetup();

  const { hasOrgTaxDetails } = useOrganizationData();

  const {
    bdsBoughtButNotConnected,
    exportProvisionsFF,
    hide: hideDatev,
  } = useDatev(); // BDS-checked

  const showTagsMenu =
    (showDocumentTags || newSettingsPromotionsFF) && isAdminOrAccountant;

  const showContractSubTypesMenu =
    (showContractCustomSubCategories || newSettingsPromotionsFF) &&
    isAdminOrAccountant;

  const showDocumentTypesSettings =
    documentTypesSettingsFF && isAdminOrAccountant;
  const showDatevDocumentTypes = !hideDatev && isAdminOrAccountant;

  const STATUS_PAGE_URL = import.meta.env.STATUS_PAGE_URL;

  const isDevToolsEnabled = localStorage.getItem('enableDevTools') !== null;

  // HACK: we don't want datev document type hash link to show active state
  const navigate = useNavigate();
  const goToDatevDocumentType: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    const path = `/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}#${DATEV_DOCUMENT_TYPES}`;
    navigate(path);
    e.currentTarget.blur();
  };

  return (
    <Grid
      as="nav"
      height="100%"
      overflow="hidden"
      templateRows="auto 1fr"
      gap="space16"
      padding="space12 space32 space32 space32"
      alignContent="start">
      <Box>
        <Heading as="h1">{t('settings.heading')}</Heading>
      </Box>
      <ScrollBox padding="space0 space32 0 space32">
        <Grid gap="space4">
          <NavLinkGroup>
            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.general.items.organization"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.ORGANIZATION}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.general.items.organization')}
                </NavLinkLabel>
                {(!hasOrgTaxDetails || showMissingIBANIndicator) && (
                  <NavLinkIndicator />
                )}
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.users"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.TEAM_MEMBERS}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {isTeamsFeatureEnabled
                    ? t('settings.navigation.general.items.users')
                    : t('settings.navigation.general.items.teamMembers')}
                </NavLinkLabel>
              </NavLink>
            )}

            {isTeamsFeatureEnabled && isAdmin && (
              <NavLink
                data-cy="settings.navigation.organization.items.teams"
                to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.general.items.teams')}
                </NavLinkLabel>
                <Tag color="blue" callout>
                  {t('tag.new')}
                </Tag>
              </NavLink>
            )}

            <NavLink
              data-cy="settings.navigation.organization.items.mobileApplication"
              to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.MOBILE_APPLICATION}`}
              onClick={scrollToTop}>
              <NavLinkLabel>
                {t('settings.navigation.organization.items.mobileApplication')}
              </NavLinkLabel>
            </NavLink>

            {STATUS_PAGE_URL && (
              <NavLink
                data-cy="settings.navigation.general.items.statusPage"
                to={`/${organizationSlug}${Routes.SETTINGS}${STATUS_PAGE_URL}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.general.items.statusPage')}
                </NavLinkLabel>
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`}
                onClick={scrollToTop}
                requiresUpgrade={!creditCards.isAddonBought}>
                <NavLinkLabel>
                  {t('settings.navigation.general.items.creditCards')}
                </NavLinkLabel>

                {(shouldUseSapConfig
                  ? indicateSapCreditCardSetupNeeded
                  : indicateDatevCreditCardSetupNeeded) && (
                  <NavLinkIndicator source="credit-cards" />
                )}
                {!creditCards.isAddonBought && <AddOnIcon />}
              </NavLink>
            )}
          </NavLinkGroup>

          <NavLinkGroup>
            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.automation.items.workflow"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.WORKFLOWS}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.automation.items.workflow')}
                </NavLinkLabel>
              </NavLink>
            )}

            {isAdmin && (
              <NavLink
                data-cy="settings.navigation.automation.items.smartFieldSuggestions"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.SMART_FIELD_SUGGESTIONS}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t(
                    'settings.navigation.automation.items.smartFieldSuggestions'
                  )}
                </NavLinkLabel>
              </NavLink>
            )}
          </NavLinkGroup>

          <NavLinkGroup>
            {showDocumentTypesSettings && (
              <NavLink
                data-cy="settings.navigation.organization.items.documentTypes"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DOCUMENT_TYPES}`}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.documentTypes')}
                </NavLinkLabel>
                <NewTag />
              </NavLink>
            )}

            {showContractSubTypesMenu && (
              <NavLink
                data-cy="settings.navigation.organization.items.contractCustomSubCategories"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}`}>
                <NavLinkLabel>
                  {t(
                    'settings.navigation.organization.items.contractCustomSubCategories'
                  )}
                </NavLinkLabel>

                {!showContractCustomSubCategories &&
                  newSettingsPromotionsFF && <AddOnIcon />}
              </NavLink>
            )}

            {showDatevDocumentTypes && (
              <NavLink
                data-cy="settings.navigation.organization.items.datevDocumentTypes"
                to={DATEV_DOCUMENT_TYPES}
                onClick={goToDatevDocumentType}>
                <NavLinkLabel>
                  {t(
                    'settings.navigation.organization.items.datevDocumentTypes'
                  )}
                </NavLinkLabel>
              </NavLink>
            )}
          </NavLinkGroup>

          <NavLinkGroup showDivider={isDevToolsEnabled}>
            {showTagsMenu && (
              <NavLink
                data-cy="settings.navigation.organization.items.documentTags"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DOCUMENT_TAGS}`}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.documentTags')}
                </NavLinkLabel>
                {!showDocumentTags && newSettingsPromotionsFF && <AddOnIcon />}
              </NavLink>
            )}
            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.costCenters"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.COST_CENTER}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.costCenters')}
                </NavLinkLabel>
              </NavLink>
            )}

            {shouldUseAccountingAreas && isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.accountingAreas"
                to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.ACCOUNTING_AREAS}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.accountingAreas')}
                </NavLinkLabel>
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.bookingAccounts"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.bookingAccounts')}
                </NavLinkLabel>
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.bookingKeys"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.TAX_CODES}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.bookingKeys')}
                </NavLinkLabel>
              </NavLink>
            )}

            {!hideDatev && isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.provisions"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.PROVISIONS}`}
                onClick={scrollToTop}
                requiresUpgrade={!exportProvisionsFF}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.provisions')}
                </NavLinkLabel>
                {(exportProvisionsFF
                  ? hasProvisionsAndRequiresSetup
                  : bdsBoughtButNotConnected) && (
                  <NavLinkIndicator source="provisions" />
                )}
                {!exportProvisionsFF && <AddOnIcon />}
              </NavLink>
            )}

            {shouldUseSapConfig && isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.sap"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.SAP}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.sap')}
                </NavLinkLabel>
                {indicateSapSetupNeeded && <NavLinkIndicator source="sap" />}
              </NavLink>
            )}

            {!hideDatev && isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.datev"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.datev')}
                </NavLinkLabel>

                {(hasReadClientsButNotSelected ||
                  accountingCoreDataMissing ||
                  bdsBoughtButNotConnected) && (
                  <NavLinkIndicator source="datev" />
                )}
              </NavLink>
            )}

            {shouldUseCandisApi && isAdmin && (
              <NavLink
                data-cy="settings.navigation.organization.items.candisAPI"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.CANDIS_API}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.apiSettings')}
                </NavLinkLabel>
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.mailSync"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.mailSync')}
                </NavLinkLabel>
              </NavLink>
            )}

            {isAdminOrAccountant && (
              <NavLink
                data-cy="settings.navigation.organization.items.paymentConditions"
                to={`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.PAYMENT_CONDITIONS}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t(
                    'settings.navigation.organization.items.paymentConditions'
                  )}
                </NavLinkLabel>
              </NavLink>
            )}

            {!shouldUseCoreDataApi && isAdmin && (
              <NavLink
                data-cy="settings.navigation.organization.items.payments"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.PAYMENTS}`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t('settings.navigation.organization.items.payments')}
                </NavLinkLabel>
              </NavLink>
            )}
          </NavLinkGroup>

          {isDevToolsEnabled && (
            <NavLinkGroup showDivider={false}>
              <NavLink
                data-cy="settings.navigation.developerTools.items.graphiql.sidebarName"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DEV_TOOLS}/graphiql`}
                onClick={scrollToTop}>
                <NavLinkLabel>
                  {t(
                    'settings.navigation.developerTools.items.graphiql.sidebarName'
                  )}
                </NavLinkLabel>
              </NavLink>
            </NavLinkGroup>
          )}
        </Grid>
      </ScrollBox>
    </Grid>
  );
};
