import {
  ProcessingFormFieldItemsQuery,
  useAvailableDocumentTypesQuery,
  DocumentType,
  AvailableDocumentTypesQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import {
  OTHER_INTEGRATION_DOCUMENT_TYPES,
  SAP_DOCUMENT_TYPES,
} from 'views/Inbox/DocumentProcessing/useProcessingFormFieldOptions';
import {
  documentTypeTranslationKeys,
  useToTypeFieldItem,
} from 'views/Inbox/DocumentProcessing/useToTypeFieldItem';
import { ProcessingFormTypeFieldItem } from '../ProcessingForm/ProcessingFormTypeField';
import { useTranslation } from 'react-i18next';

export type GlobalDocumentType =
  AvailableDocumentTypesQuery['availableDocumentTypes'][number];

export const isSupportedDocumentType = (
  documentType: string
): documentType is DocumentType => {
  return Object.values(DocumentType).includes(documentType as DocumentType);
};

export type DocumentTypesFieldItem = {
  key: string;
  label: string;
  value: GlobalDocumentType;
};

export interface UseDocumentTypeFieldOptionsOptions {
  types: ProcessingFormFieldItemsQuery['types'];
  autoFocus?: boolean;
}

export interface UseDocumentTypeFieldOptionsResult {
  /** @deprecated */
  items?: ProcessingFormTypeFieldItem[];
  autoFocus?: boolean;
  groupedItems?: DocumentTypesFieldItem[][];
  newItems?: DocumentTypesFieldItem[];
}

export const useDocumentTypeFieldOptions = ({
  types = [],
  autoFocus,
}: UseDocumentTypeFieldOptionsOptions): UseDocumentTypeFieldOptionsResult => {
  const [t] = useTranslation();
  const [classificationFrontendFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.classificationFrontend,
  ]);

  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();

  const { data } = useAvailableDocumentTypesQuery({
    variables: {
      includeInactiveTypes: false,
    },
    skip: !classificationFrontendFF,
  });

  const availableDocumentTypes = data?.availableDocumentTypes.filter(
    item =>
      item.documentType !== DocumentType.ExpenseReimbursementItem &&
      item.documentType !== DocumentType.TravelReimbursementItem
  );

  const sap = useSap();
  const otherIntegration = useOtherIntegration();
  const toTypeFieldItem = useToTypeFieldItem();

  let typeDeprecated: {
    items: ProcessingFormTypeFieldItem[];
    autoFocus: boolean | undefined;
  };

  if (sap.isActive) {
    typeDeprecated = {
      items: types
        .filter(type =>
          SAP_DOCUMENT_TYPES.includes(
            type.documentType as (typeof SAP_DOCUMENT_TYPES)[number]
          )
        )
        .map(toTypeFieldItem),
      autoFocus,
    };
  } else if (otherIntegration.showDocumentTypesForOtherIntegrations) {
    typeDeprecated = {
      items: types
        .filter(type =>
          OTHER_INTEGRATION_DOCUMENT_TYPES.includes(
            type.documentType as (typeof OTHER_INTEGRATION_DOCUMENT_TYPES)[number]
          )
        )
        .map(toTypeFieldItem),
      autoFocus,
    };
  } else {
    typeDeprecated = {
      items: types.map(toTypeFieldItem),
      autoFocus,
    };
  }

  const [invoiceTypesArray, documentTypesArray] = useMemo(() => {
    const invoiceTypesArray: DocumentTypesFieldItem[] = [];
    const documentTypesArray: DocumentTypesFieldItem[] = [];

    (availableDocumentTypes ?? []).forEach(item => {
      if (item.documentCategory) {
        const translationKey =
          documentTypeTranslationKeys[item.documentCategory.documentType];

        const translatedLabel = translationKey
          ? t(translationKey)
          : item.documentCategory.documentType;

        invoiceTypesArray.push({
          key: item.documentCategory.documentType,
          label: item.documentCategory.isDefault
            ? translatedLabel
            : item.documentCategory.documentType,
          value: item,
        });
      } else if (isSupportedDocumentType(item.documentType))
        documentTypesArray.push({
          key: item.documentType,
          value: item,
          label: ecmDocumentTypeTranslationMap[item.documentType],
        });
    });

    return [invoiceTypesArray, documentTypesArray];
  }, [availableDocumentTypes, ecmDocumentTypeTranslationMap, t]);

  const groupedItems = useMemo(
    () => [invoiceTypesArray, documentTypesArray],
    [documentTypesArray, invoiceTypesArray]
  );

  const newItems = useMemo(() => {
    return [...invoiceTypesArray, ...documentTypesArray];
  }, [documentTypesArray, invoiceTypesArray]);

  if (!classificationFrontendFF) return typeDeprecated;

  return {
    groupedItems,
    autoFocus,
    newItems,
  };
};
