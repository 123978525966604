import {
  DocumentBookingAssociation,
  useGetDocumentBookingAssociationsByInvoiceIdQuery,
} from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { useCallback, useMemo } from 'react';
import { useSapPurchaseOrders } from 'views/DocumentDetails/components/ThreeWayMatch/hooks/useSapPurchaseOrders';
import { getBookingItemQuantity } from 'views/DocumentDetails/components/ThreeWayMatch/utils';
import { getIsQuantityRequired } from 'views/Inbox/DocumentProcessing/util/getIsQuantityRequired';
/**
 *
 * @param documentId
 * @returns getBookingAssociationData
 * quantity and expenseType are obtained from DocumentBookingAssociationData
 *
 * quantity is undefined if it's not required(service type purchase order or it's not sap purchase order), otherwise it's at least 1
 */

export const useGetBookingAssociation = (documentId?: string) => {
  const { shouldUsePackageFreight } = useSap();
  const { purchaseOrders } = useSapPurchaseOrders(documentId);

  const { data: bookingAssociations } =
    useGetDocumentBookingAssociationsByInvoiceIdQuery({
      variables: { invoiceId: documentId ?? '' },
      skip: !documentId || !purchaseOrders?.length,
      fetchPolicy: 'cache-and-network',
    });

  const bookingAssociationsData = useMemo(
    () =>
      (bookingAssociations?.getDocumentBookingAssociationsByInvoiceId ??
        []) as DocumentBookingAssociation[],
    [bookingAssociations?.getDocumentBookingAssociationsByInvoiceId]
  );

  const isQuantityRequired = purchaseOrders?.some(po =>
    getIsQuantityRequired(po?.purchaseOrderType)
  );

  const getBookingAssociationData = useCallback(
    (bookingId?: string) => {
      if (!bookingId) return {};
      const bookingAssoc = bookingAssociationsData.find(
        booking => booking?.bookingId === bookingId
      );

      return {
        quantity: isQuantityRequired
          ? getBookingItemQuantity(bookingId, bookingAssociationsData)
          : undefined,
        sapExpenseType: shouldUsePackageFreight
          ? bookingAssoc?.additionalExpenseInfo?.expenseType
          : undefined,
        unitPrice: isQuantityRequired ? bookingAssoc?.unitPrice : undefined,
      };
    },
    [bookingAssociationsData, isQuantityRequired, shouldUsePackageFreight]
  );

  return { isQuantityRequired, getBookingAssociationData };
};
