import { Flex, Grid, Text } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpenseFormWrapper } from './ExpenseFormWrapper';
import { ExpenseInvoicePreviewContainer } from './ExpenseInvoicePreviewContainer';
import { generateFieldNames } from './utils/generateHospitalityFormFieldNames';

interface ReadonlyHospitalityExpenseFormProps {
  formIndex: number;
  expenseId: string;
  reason: string | undefined;
  totalAmount?: number | null;
  invoice?: ExpenseInvoice;
}

export const ReadonlyHospitalityExpenseForm = ({
  formIndex,
  expenseId,
  reason,
  totalAmount,
  invoice,
}: ReadonlyHospitalityExpenseFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fields = generateFieldNames(formIndex);

  return (
    <ExpenseFormWrapper
      expenseId={expenseId}
      totalAmount={totalAmount}
      expenseType="hospitality"
      reason={reason}>
      <Grid as="form" gap="space24">
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={fields.reason}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.hospitality.reason.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.reason.placeholder'
            )}
          />
          <HookFormTextField
            name={fields.location}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.hospitality.location.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.location.placeholder'
            )}
          />
        </Grid>
        <Grid columns={4} gap="space8">
          <HookFormDatePickerField
            name={fields.dayOfExpense}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.hospitality.date.label'
            )}
          />
          <HookFormAmountField
            name={fields.receiptAmount}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.placeholder'
            )}
            currency="EUR"
          />
          <HookFormAmountField
            name={fields.tipAmount}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.placeholder'
            )}
            currency="EUR"
          />
          <HookFormAmountField
            name={fields.totalAmount}
            label={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.placeholder'
            )}
            readOnly
            currency="EUR"
          />
        </Grid>
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={fields.internalGuests}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.hospitality.internalGuests.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.internalGuests.placeholder'
            )}
            infoTooltip={{
              message: t(
                'reimbursementView.middleSection.form.hospitality.internalGuests.tooltip'
              ),
            }}
          />
          <HookFormTextField
            name={fields.externalGuests}
            readOnly
            label={
              <Flex height="space16" alignItems="center">
                <Text>
                  {t(
                    'reimbursementView.middleSection.form.hospitality.externalGuests.label'
                  )}
                </Text>
              </Flex>
            }
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.externalGuests.placeholder'
            )}
          />
        </Grid>
        <ExpenseInvoicePreviewContainer invoice={invoice} isReadOnly />
      </Grid>
    </ExpenseFormWrapper>
  );
};
