import { noop } from 'lodash';
import {
  createContext,
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
} from 'react';
import {
  defaultUIConfig,
  UIConfigProps,
} from '../hooks/useReimbursementUIConfig';

export interface FormRegistration {
  trigger?: () => void;
}

interface ReimbursementFormsContextProps {
  lastModifiedExpense: null | number;
  registerForm: (formKey: string, registration: FormRegistration) => void;
  triggerAllValidations: () => void;
  reimbursementUIConfig: UIConfigProps;
  setReimbursementUIConfig: Dispatch<SetStateAction<UIConfigProps>>;
  updateLastModifiedExpense: (expenseFormIndex: number) => void;
  visibleItemId: null | string;
  setVisibleItemId: (id: string | null) => void;
  itemsContainerRef: RefObject<HTMLDivElement>;
  itemRefs: RefObject<{ [key: string]: HTMLDivElement | null }>;
  selectedItemId: string | null;
  setSelectedItemId: (id: string | null) => void;
}

export const ReimbursementFormsContext =
  createContext<ReimbursementFormsContextProps>({
    lastModifiedExpense: null,
    registerForm: noop,
    triggerAllValidations: noop,
    reimbursementUIConfig: defaultUIConfig,
    setReimbursementUIConfig: noop,
    updateLastModifiedExpense: noop,
    visibleItemId: null,
    setVisibleItemId: noop,
    itemsContainerRef: { current: null },
    itemRefs: { current: {} },
    selectedItemId: null,
    setSelectedItemId: noop,
  });

export const useReimbursementFormsContext = () => {
  const context = useContext(ReimbursementFormsContext);
  if (!context) {
    throw new Error(
      'useReimbursementFormsContext must be used within its ReimbursementFormsProvider'
    );
  }

  return context;
};
