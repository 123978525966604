import { Button } from '@candisio/design-system';
import { SensitiveDataProps } from 'containers/credit-cards/CreditCardCarousel/types';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ShowAndHideCardNumbersProps {
  cardId: string;
  cardType: CardType;
  refNum?: string | null;
  sensitiveData: SensitiveDataProps;
}

export const ShowAndHideCardNumbers = ({
  cardId,
  sensitiveData,
}: ShowAndHideCardNumbersProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const {
    isFetchingSensitiveData,
    onShowCardNumbers,
    resetTokens,
    sensitiveDataIsShown,
  } = sensitiveData;

  const handleShowCardNumbers = async () => {
    await onShowCardNumbers(cardId);
  };

  return (
    <Button
      size="small"
      justifySelf="center"
      variant="tertiary"
      icon={sensitiveDataIsShown ? 'viewOutline' : 'view'}
      loading={isFetchingSensitiveData}
      onClick={sensitiveDataIsShown ? resetTokens : handleShowCardNumbers}>
      {sensitiveDataIsShown
        ? t('carousel.hideNumbers')
        : t('carousel.showNumbers')}
    </Button>
  );
};
