import { useGetAccountPayableNumberByMembershipIdQuery } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const useGetAccountsPayableNumber = ({ userId }: { userId: string }) => {
  const { canUseReimbursement } = useReimbursement();
  const employeeContactTypeFF = useCandisFeatureFlags(
    FEATURE_FLAGS.employeeContactType
  );

  const { data, loading } = useGetAccountPayableNumberByMembershipIdQuery({
    variables: { membershipId: userId },
    skip: !canUseReimbursement || !employeeContactTypeFF,
  });

  const accountsPayableNumber =
    data?.contactByEmployeeMembershipId?.accountsPayableNumber ?? undefined;

  return { accountsPayableNumber, isLoadingAccountsPayableNumber: loading };
};
