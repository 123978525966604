import { QueryHookOptions } from '@apollo/client';
import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { Query } from '../../../generated-types/graphql.types';
import { ContactTableColumnKeys } from './elements/table/contacts/types';
import { PaymentConditionsTableColumnKeys } from './elements/table/paymentConditions/types';
import { ExportReimbursementItemsTableColumnKeys } from './elements/table/reimbursementItems/types';
import { ExportType } from './types';

export type ExportQuery = Pick<Query, '_exports'>;
export const csvExportTypesNonDatev = [
  ExportType.SAP_CSV,
  ExportType.CANDIS_CSV,
] as const;
export const csvExportTypesDatev = [
  ExportType.DATEV_CSV,
  ExportType.DATEV_DXSO_ZIP,
  ExportType.DATEV_BDS,
] as const;

const HISTORY_PAGINATION_LIMIT = 10;

export const paginationQueryOptions: QueryHookOptions<ExportQuery> = {
  context: { skipLoading: true },
  variables: { limit: HISTORY_PAGINATION_LIMIT },
};

export const ExportErrorCodes = {
  ABORTED_BECAUSE_OF_OTHER_DOCUMENT: 'ABORTED_BECAUSE_OF_OTHER_DOCUMENT',
  DCO01007_PROBLEMATIC_DOCUMENT: 'DCO01007_PROBLEMATIC_DOCUMENT',
  NON_EXPORTABLE: 'non_exportable',
} as const;

type ExportDocumentsColumnKeys = Extract<
  DocumentTableColumnKeys,
  | 'invoiceNumber'
  | 'contact'
  | 'invoiceDate'
  | 'grossAmount'
  | 'transactionStatus'
  | 'provisionStatus'
  | 'account'
  | 'generalLedgerAccount'
  | 'deliveryDate'
  | 'netAmount'
  | 'period'
  | 'statement'
  | 'settlementPaymentStatus'
>;

type ExportReimmbursementItemsColumnKeys = Exclude<
  ExportReimbursementItemsTableColumnKeys,
  | 'exportStatus'
  | 'exportMethod'
  | 'isDisabled'
  | 'canBeSelectedTooltipText'
  | 'reimbursementColletionId'
>;

export type ExportColumnKeys =
  | ContactTableColumnKeys
  | ExportDocumentsColumnKeys
  | PaymentConditionsTableColumnKeys
  | ExportReimmbursementItemsColumnKeys;

export const exportColumnsConfigurations: Record<
  ExportColumnKeys,
  Configuration
> = {
  contact: {
    id: 'contact',
    isVisible: true,
    label: 'documents-table:columns.contact',
  },
  invoiceNumber: {
    id: 'invoiceNumber',
    isVisible: true,
    label: 'documents-table:columns.invoiceNumber',
  },
  invoiceDate: {
    id: 'invoiceDate',
    isVisible: true,
    label: 'documents-table:columns.invoiceDate',
  },
  grossAmount: {
    id: 'grossAmount',
    isVisible: true,
    label: 'documents-table:columns.grossAmount',
  },
  transactionStatus: {
    id: 'transactionStatus',
    isVisible: true,
    label: 'documents-table:columns.transactionStatus',
  },
  provisionStatus: {
    id: 'provisionStatus',
    isVisible: true,
    label: 'documents-table:columns.provisionStatus',
  },
  account: {
    id: 'account',
    isVisible: true,
    label: 'documents-table:columns.account',
  },
  generalLedgerAccount: {
    id: 'generalLedgerAccount',
    isVisible: true,
    label: 'documents-table:columns.generalLedgerAccount',
  },
  deliveryDate: {
    id: 'deliveryDate',
    isVisible: true,
    label: 'documents-table:columns.deliveryDate',
  },
  netAmount: {
    id: 'netAmount',
    isVisible: true,
    label: 'documents-table:columns.netAmount',
  },
  settlementPaymentStatus: {
    id: 'settlementPaymentStatus',
    isVisible: true,
    label: 'documents-table:columns.settlementPaymentStatus',
  },
  period: {
    id: 'period',
    isVisible: true,
    label: 'documents-table:columns.period',
  },
  statement: {
    id: 'statement',
    isVisible: true,
    label: 'documents-table:columns.statement',
  },
  name: {
    id: 'name',
    isVisible: true,
    label: 'exports:contacts.table.columns.name',
  },
  value: {
    id: 'value',
    isVisible: true,
    label: 'exports:paymentConditions.table.columns.value',
  },
  paymentConditionNumber: {
    id: 'paymentConditionNumber',
    isVisible: true,
    label: 'exports:paymentConditions.table.columns.paymentConditionNumber',
  },
  status: {
    id: 'status',
    isVisible: true,
    label: 'exports:paymentConditions.table.columns.status',
  },
  expenseId: {
    id: 'expenseId',
    isVisible: true,
    label: 'exports:expense.table.columns.expenseId',
  },
  reasonExpense: {
    id: 'reasonExpense',
    isVisible: true,
    label: 'exports:expense.table.columns.reasonExpense',
  },
  submittedAt: {
    id: 'submittedAt',
    isVisible: true,
    label: 'exports:expense.table.columns.submittedAt',
  },
  targetedMembership: {
    id: 'targetedMembership',
    isVisible: true,
    label: 'exports:expense.table.columns.targetedMembership',
  },
  titleReimbursement: {
    id: 'titleReimbursement',
    isVisible: true,
    label: 'exports:expense.table.columns.titleReimbursement',
  },
  type: {
    id: 'type',
    isVisible: true,
    label: 'exports:expense.table.columns.type',
  },
};
