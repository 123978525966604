import { forwardRef } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { ListProps } from 'react-virtuoso';
import { List } from '../List';
import { VirtuosoContext } from '../types';

export const VirtuosoGroupedList = forwardRef<
  HTMLUListElement,
  ListProps & VirtuosoContext
>(({ context, style, ...restProps }, forwardedRef) => {
  const { ref, ...listProps } = context?.listProps;

  return (
    <List
      style={{
        ...style,
        paddingTop: style?.paddingTop ?? '0px',
        paddingBottom: style?.paddingBottom ?? '0px',
      }}
      {...mergeProps(listProps, restProps)}
      ref={ref ? mergeRefs([ref, forwardedRef]) : forwardedRef}
    />
  );
});
