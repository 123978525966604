import { Cell, CellProps } from '@candisio/design-system';
import { DateFormatters } from 'utils/date_formatter';

export const DateCell = <TableDataType extends { id: string }>({
  value,
  ...restProps
}: CellProps<TableDataType, Date>) => {
  return (
    <Cell
      {...restProps}
      value={value ? DateFormatters.compact(value) : undefined}
    />
  );
};
