import { defaultTheme } from '@candisio/design-system';
import { mapExportStatusIcon } from 'components/utils/status-mapping';
import { ExportStatus } from 'generated-types/graphql.types';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { BaseItem, BaseItemProps } from './BaseItem';

export type Props = BaseItemProps & {
  active?: string | null;
  status?: ExportStatus | 'default' | 'not-ready';
  size?: 'big' | 'default';
};

export const Export = styled(BaseItem).attrs<Props>(({ status }) => ({
  src: mapExportStatusIcon(status),
}))<Props>`
  transition: background-color 0.3s;
  cursor: pointer;

  &[active='true'] {
    background-color: ${defaultTheme.colors.gray0} !important;
  }

  &[active='false']:hover {
    background-color: ${defaultTheme.colors.gray50} !important;
  }

  // main item
  ${p =>
    p.size === 'big' &&
    `
    height: 4.3125rem;
    margin-bottom: 10px !important;

    .ant-timeline-item-content div {
        font-weight: ${p.active === 'true' ? 'bold' : 'normal'}
        color: ${
          p.active === 'true'
            ? defaultTheme.colors.blue500
            : defaultTheme.colors.gray800
        }
      }
    }
    
    .ant-timeline-item-head {
      top: 50%;
      width: 1.5rem;
      height: 1.5rem;
    }
    .ant-timeline-item-tail {
      top: calc(50% + 0.75rem);
      height: calc(100% - 1rem);
    }
  `}
`;
