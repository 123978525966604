import { CellWrapperProps } from '@candisio/design-system';
import { Link, LinkProps, To } from 'react-router-dom-v5-compat';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const CellWrapperStyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  padding: 0.625rem 1rem;
  color: inherit;
  &:hover,
  &:active {
    color: inherit;
  }
`;

export function getCellWrapperLink<T extends object>(
  getLink: (arg: T) => To,
  linkProps?: Omit<LinkProps, 'to'>
) {
  return ({ row, children }: CellWrapperProps<T>) => {
    return (
      <CellWrapperStyledLink to={getLink(row.original)} {...linkProps}>
        {children}
      </CellWrapperStyledLink>
    );
  };
}
