import { Item } from '@candisio/design-system';
import { DocumentTypesFieldItem } from 'components/Form/hooks/useDocumentTypeFieldOptions';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { useTranslation } from 'react-i18next';
import { useDocumentTypeContext } from 'views/DocumentDetails/components/DocumentTypeContext';
import { StorageFormValues } from './storageFormSchema';

export interface StorageFormDocumentTypeFieldProps
  extends HookFormComboBoxFieldProps<StorageFormValues> {
  newItems?: DocumentTypesFieldItem[];
  groupedItems?: DocumentTypesFieldItem[][];
}

export const StorageFormDocumentTypeField = ({
  name,
  items: _items,
  newItems,
  groupedItems,
  ...restProps
}: StorageFormDocumentTypeFieldProps) => {
  const [t] = useTranslation();
  const { setDocumentType } = useDocumentTypeContext();

  return (
    <HookFormComboBoxField
      name={name}
      items={newItems}
      groupedLists={groupedItems}
      groupHeaders={[
        t('ecm:formSwitcher.approval'),
        t('ecm:formSwitcher.storage.title'),
      ]}
      isGroupedListMode
      onChange={newValue => {
        const newDocumentType = newItems?.find(item => item.key === newValue);
        setDocumentType(newDocumentType?.value);
      }}
      {...restProps}>
      {(item: DocumentTypesFieldItem) => (
        <Item key={item.key} textValue={item.label}>
          {item.label}
        </Item>
      )}
    </HookFormComboBoxField>
  );
};
