import { PaginatedComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { PurchaseOrderFieldItem } from '../PurchaseOrderItem';
import { useLinkPurchaseOrderToDocument } from './useLinkPurchaseOrderToDocument';
import { usePurchaseOrderFieldItems } from './usePurchaseOrderFieldItems';

export interface UsePurchaseOrderFieldOptionsResult {
  props?: PaginatedComboBoxFieldOptions<PurchaseOrderFieldItem>;
  linkPurchaseOrder: (
    purchaseOrderId: string,
    orderNumber?: string
  ) => Promise<void>;
}

export interface UsePurchaseOrderOptionsProps {
  documentId?: string;
  accountsPayableNumber?: string | null;
  documentAmount?: number | null;
}

/** Returns options for purchase order number field */
export const usePurchaseOrderFieldOptions = ({
  documentId = '',
  documentAmount,
  accountsPayableNumber,
}: UsePurchaseOrderOptionsProps): UsePurchaseOrderFieldOptionsResult => {
  const {
    isLoading,
    fetchMoreItems,
    items,
    handleDebounceSearch,
    disabledKeys,
  } = usePurchaseOrderFieldItems({ documentAmount, accountsPayableNumber });

  const { linkPurchaseOrder, isLinkingLoading } =
    useLinkPurchaseOrderToDocument({
      documentId,
    });

  return {
    linkPurchaseOrder,
    props: {
      items,
      persistFilter: true,
      disabledKeys,
      onEndReached: fetchMoreItems,
      isLoading: isLoading || isLinkingLoading,
      onSearch: async (query: string) => {
        handleDebounceSearch(query);
      },
    },
  };
};
