import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';
import { Nullable } from 'utils/extractors';

export const ownerSchema = z.object({
  owner: z.string().optional().nullable(),
});

export type OrganizationOwnerFormOutput = z.infer<typeof ownerSchema>;

export type OrganizationOwnerFormValues = Nullable<OrganizationOwnerFormOutput>;

export type OrganizationOwnerFormErrors = ErrorMessages<typeof ownerSchema>;

export const ownerErrorMessages: OrganizationOwnerFormErrors = {
  owner: { label: 'organizations.inputs.owner' },
};
