import {
  CustomEmptyStateProps,
  Filter,
  FilterOptionsAccessor,
  Flex,
  Table,
} from '@candisio/design-system';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { DateRangeFilter } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Filters, Row, SortingRule, TableState } from 'react-table';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';
import { AmountCellReimbursements } from './Cells/AmountCellReimbursements';
import { DateCell } from './Cells/DateCell';
import { DefaultCell } from './Cells/DefaultCell';
import { Header } from './Cells/Header';
import { IbanCell } from './Cells/IbanCell';
import { IsPaidCell } from './Cells/IsPaidCell';
import { MemberCell } from './Cells/MemberCell';
import { ReimbursementStatusCell } from './Cells/ReimbursementStatusCell';
import { ReimbursementTableToolbar } from './ReimbursementTableToolbar';

export type ReimbursementColumns = Record<
  ReimbursementTableColumnKeys,
  Column<ReimbursementTableRow>
>;
export interface ReimbursementsTableProps {
  data: Array<ReimbursementTableRow>;
  filterOptions?: FilterOptionsAccessor<ReimbursementTableRow>;
  visibleColumns?: Array<ReimbursementTableColumnKeys>;
  initialFilters?: TableState<ReimbursementTableRow>['filters'];
  initialSortBy?: TableState<ReimbursementTableRow>['sortBy'];
  isLoadingFilterOptions?: boolean;
  isLoadingConfigurations?: boolean;
  isLoading?: boolean;
  search: string;
  configurations: Configuration[];
  onRowClick: (id: string, cursor?: string) => void;
  onEndReached: (index: number) => void;
  onSearch: (search: string) => void;
  onResetTableConfigurations: () => void;
  onUpdateTableConfigurations: (configurations: Configuration[]) => void;
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  onSort: (sortBy: SortingRule<ReimbursementTableRow>[]) => void;
  onFilter: (filters: Filters<ReimbursementTableRow>) => void;
}

export const ReimbursementsTable = ({
  data,
  filterOptions,
  initialFilters = [],
  initialSortBy = [],
  isLoadingFilterOptions = false,
  isLoadingConfigurations = false,
  isLoading = false,
  visibleColumns = [],
  configurations,
  search,
  onEndReached,
  onResetTableConfigurations,
  onUpdateTableConfigurations,
  onSearch,
  onRowClick,
  onFilter,
  onSort,
  customEmptyState,
}: ReimbursementsTableProps) => {
  const [t] = useTranslation();

  const handleRowClick = ({ original }: Row<ReimbursementTableRow>) => {
    const { id, cursor } = original;
    if (!cursor) {
      return;
    }

    onRowClick?.(id, cursor);
  };

  const defaultColumn = useMemo((): Partial<Column<ReimbursementTableRow>> => {
    return {
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: DefaultCell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof ReimbursementTableRow];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              return (
                <Filter<ReimbursementTableRow>
                  column={column}
                  options={filterOption.data}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                  filterLabel={t('table:filterLabel')}
                  applyFilterButton={t('table:filterApply')}
                  resetFilterButton={t('table:filterReset')}
                  searchFieldPlaceholder={t('table:filterSearchPlaceholder')}
                />
              );
            }

            if (filterOption === true) {
              return (
                <DateRangeFilter
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
      disableFilters: !Boolean(filterOptions),
    };
  }, [filterOptions, t]);

  const columns = useMemo(() => {
    const allColumns: ReimbursementColumns = {
      approver: {
        accessor: 'approver',
        Cell: MemberCell,
        // TO-DO: Reintroduce filter and sort when BE is fixed
        disableSortBy: true,
        disableFilters: true,
      },
      createdAt: {
        accessor: 'createdAt',
        Cell: DateCell,
      },
      grossAmount: {
        accessor: 'grossAmount',
        Cell: AmountCellReimbursements,
      },
      iban: {
        accessor: 'iban',
        disableSortBy: true,
        disableFilters: true,
        Cell: IbanCell,
      },
      isPaid: {
        accessor: 'isPaid',
        disableSortBy: true,
        Cell: IsPaidCell,
      },
      paidAt: {
        accessor: 'paidAt',
        Cell: DateCell,
      },
      requesterOfTheReimbursement: {
        accessor: 'requesterOfTheReimbursement',
        Cell: MemberCell,
        // TO-DO: Reintroduce filter and sort when BE is fixed
        disableSortBy: true,
        disableFilters: true,
      },
      status: {
        accessor: 'status',
        Cell: ReimbursementStatusCell,
      },
      title: {
        accessor: 'title',
        disableFilters: true,
      },
    };

    if (!visibleColumns.length) {
      return Object.values(allColumns);
    }

    return visibleColumns.map(accessor => allColumns[accessor]);
  }, [visibleColumns]);

  return (
    <Flex height="100%" overflow="hidden" direction="column">
      <ReimbursementTableToolbar
        configurations={configurations}
        search={search}
        isSearchReadOnly
        onResetTableConfigurations={onResetTableConfigurations}
        onUpdateConfigurations={onUpdateTableConfigurations}
        onSearch={onSearch}
        isLoading={isLoadingConfigurations}
        key={`reimbursements-table-toolbar-${columns.join('-')}`}
      />
      <Table
        key={`reimbursements-table-${isLoadingFilterOptions}-${columns.join(
          '-'
        )}`}
        data={data}
        columns={columns}
        defaultColumn={defaultColumn}
        showDefaultEmptyState
        initialState={{
          filters: initialFilters,
          sortBy: initialSortBy,
        }}
        onRowClick={handleRowClick}
        customEmptyState={customEmptyState}
        onEndReached={onEndReached}
        onFilter={onFilter}
        onSort={onSort}
        isLoading={isLoading}
        borderTopRadius="none"
      />
    </Flex>
  );
};
