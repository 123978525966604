import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import {
  CostCenterTypes,
  DocumentCurrency,
  DocumentType,
} from 'generated-types/graphql.types';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { DefaultValues } from 'react-hook-form';
import { toCurrencyFieldItem } from '../../toCurrencyFieldItem';
import { useContactFieldOptions } from '../../useContactFieldOptions';
import { StorageFormFieldOptions } from './StorageForm';
import { StorageFormValues } from './storageFormSchema';
import { useEcmContractTypeOptions } from './useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from './useEcmDocumentTypeItems';
import { useGlobalDocumentTypeFieldOptions } from './useGlobalDocumentTypeFieldOptions';
import { usePeopleFieldOptions } from './useResponsiblePersonFieldOptions';

/**
 * Returns options for the following storage form fields:
 * - document type
 * - document sub category
 * - contact
 * - cost center
 * - currency
 * - responsible person
 */
export const useStorageFormFieldOptions = (
  defaultValues: DefaultValues<StorageFormValues>
): StorageFormFieldOptions => {
  const { ecmDocumentTypeSelectItems } = useEcmDocumentTypeOptions();
  const contactFieldOptions = useContactFieldOptions(
    defaultValues.contact?.inputValue
  );

  const { groupedItems, newItems } = useGlobalDocumentTypeFieldOptions();

  const org = useFullOrganization();
  const hasCostCenters = Boolean(org?.hasCostCenters);

  const { ecmContractTypeSelectItems } = useEcmContractTypeOptions(
    defaultValues.documentSubCategory
  );

  const showCC = hasCostCenters || defaultValues?.costCenter?.value;
  const costCenterFieldOptions = useCostCenterFieldOptions({
    skip: !showCC,
    type: CostCenterTypes.CostCenter,
  });

  const peopleFieldOptions = usePeopleFieldOptions();

  return {
    documentType: {
      items:
        // We DON'T want to store a document as an invoice because invoice
        // should always be processed. We need to exclude "Invoice" from the
        // list of document types
        ecmDocumentTypeSelectItems.filter(
          item => item.key !== DocumentType.Invoice
        ),
      groupedItems,
      newItems,
    },
    documentSubCategory: { defaultItems: ecmContractTypeSelectItems },
    contact: contactFieldOptions,
    costCenter: costCenterFieldOptions,
    currency: {
      defaultItems: Object.values(DocumentCurrency).map(toCurrencyFieldItem),
    },
    responsiblePerson: peopleFieldOptions,
    notifyPerson: peopleFieldOptions,
  };
};
