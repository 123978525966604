import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useExpensesFormDefaultValues } from './components/MiddleSection/utils/useExpensesFormDefaultValues';
import { ReimbursementFormsProvider } from './context/ReimbursementFormsProvider';
import { ReimbursementSplitBookingsProvider } from './context/ReimbursementSplitBookingsProvider';
import { useGetReimbursementExpenseItems } from './hooks/useGetReimbursementExpenseItems';
import {
  ReimbursementRouteParams,
  usePrefetchReimbursementQueries,
  useReimbursementNavigation,
} from './hooks/useReimbursementNavigation';
import { useReimbursementRouteValidator } from './hooks/useReimbursementRouteValidator';
import { useReimbursementUIConfig } from './hooks/useReimbursementUIConfig';
import { Reimbursement } from './Reimbursement';

export const REIMBURSEMENT_URL_PARAM = {
  VIEW: 'view',
  CURSOR: 'cursor',
} as const;

export const ReimbursementContainer = () => {
  const { organizationSlug, type, reimbursementId } =
    useParams<ReimbursementRouteParams>();

  const { searchParams } = useMutateSearchParams();
  const cursor = searchParams.get(REIMBURSEMENT_URL_PARAM.CURSOR);

  const { mappedItems, loadingItems } = useGetReimbursementExpenseItems();

  const defaultValues = useExpensesFormDefaultValues({ expenses: mappedItems });

  const {
    isLoadingNavigationData,
    nextReimbursementId,
    nextReimbursementLink,
    prevReimbursementId,
    prevReimbursementLink,
    reimbursementCount,
    cycleReimbursements,
  } = useReimbursementNavigation({
    cursor,
    type,
    organizationSlug,
  });

  useReimbursementRouteValidator();

  usePrefetchReimbursementQueries(nextReimbursementId, prevReimbursementId);

  const defaultUIConfig = useReimbursementUIConfig();

  return (
    <ReimbursementFormsProvider
      key={`loading-${reimbursementId}-${!!mappedItems.length}`}
      initialUIConfig={defaultUIConfig}>
      <ReimbursementSplitBookingsProvider>
        <Reimbursement
          isLoading={isLoadingNavigationData || loadingItems}
          defaultValues={defaultValues}
          cycleReimbursements={cycleReimbursements}
          nextReimbursementLink={nextReimbursementLink}
          prevReimbursementLink={prevReimbursementLink}
          reimbursementCount={reimbursementCount}
        />
      </ReimbursementSplitBookingsProvider>
    </ReimbursementFormsProvider>
  );
};
