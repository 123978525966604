// eslint-disable-next-line no-restricted-imports
import { Timeline } from 'antd/es';
import { TimelineProps } from 'antd/es/timeline';
import { NoHistoryData } from 'components/History/NoData/NoHistoryData';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ReactNode, HTMLAttributes } from 'react';
import { Collapse } from './items/Collapse';
import { Content } from './items/Content';
import { Email } from './items/Email';
import { Export } from './items/Export';
import { Neutral } from './items/Neutral';
import { Payment } from './items/Payment';
import { RenderItem } from './items/RenderItem';
import { System } from './items/System';
import { User } from './items/User';

interface HistoryProps extends TimelineProps, HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const History = ({ children, ...rest }: HistoryProps) => {
  const refactorHistoryFF = useCandisFeatureFlags(
    FEATURE_FLAGS.wipRefactorHistory
  );

  if (!children) return <NoHistoryData />;

  return refactorHistoryFF ? (
    <>
      <div {...rest}>{children}</div>
    </>
  ) : (
    <Timeline {...rest}>{children}</Timeline>
  );
};

History.System = System;
History.Export = Export;
History.Payment = Payment;
History.Email = Email;
History.Neutral = Neutral;
History.User = User;
History.Collapse = Collapse;
History.Render = RenderItem;
History.Content = Content;
