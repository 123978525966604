/**
 * Get React Aria’s secret props starting with `_`
 *
 * Honestly awful that React Aria does this kind of thing.
 */
export const getReactAriaSecretProps = (props: Record<string, any>) => {
  const secretProps: Record<string, unknown> = {};
  const otherProps: Record<string, unknown> = {};

  Object.entries(props).forEach(([key, value]) => {
    if (key.startsWith('_')) {
      secretProps[key] = value;
    } else {
      otherProps[key] = value;
    }
  });

  return { secretProps, otherProps };
};
