import { ComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ArtistSocialInsuranceFieldProps<TFormValues extends FieldValues>
  extends HookFormComboBoxFieldProps<TFormValues> {
  name: Path<TFormValues>;
}

export const ArtistSocialInsuranceField = <TFormValues extends FieldValues>({
  name,
  ...props
}: ArtistSocialInsuranceFieldProps<TFormValues> & ComboBoxFieldOptions) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <HookFormComboBoxField
      name={name}
      placeholder={t(
        'splitBookings.artistSocialInsuranceCode.placeholder.enabled'
      )}
      {...props}
    />
  );
};
