import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { ApprovalsFormFieldOptions } from 'components/Form/DocumentApprovalsForm/toolkit/types';
import { useAccountingAreaFieldOptions } from 'components/Form/hooks/useAccountingAreaFieldItems';
import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import { useGeneralLedgerAccountFieldOptions } from 'components/Form/hooks/useGeneralLedgerAccountFieldOptions';
import { SplitBookingsFormFieldOptions } from 'components/Form/SplitBookingsForm/types';
import {
  ArtistSocialInsuranceCode,
  CostCenterTypes,
  useProcessingFormFieldItemsQuery,
} from 'generated-types/graphql.types';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';
import { useToArtistSocialInsuranceCodeFieldItem } from 'views/Inbox/DocumentProcessing/useToArtistSocialInsuranceCodeFieldItem';
import {
  useShowArtistSocialInsuranceCodeField,
  useShowTaxCodeField,
  useShowCostCenterField,
  useShowCostObjectField,
  useShowExtraCostInfoField,
  useShowGeneralLedgerAccountField,
  useShowPostingTextField,
  useShowAccountingAreaField,
} from 'views/utils/useShouldShowField';

export const useApprovalFieldOptions = ({
  initialValues,
}: {
  initialValues: DocumentApprovalFormValues;
}): { fieldOptions: ApprovalsFormFieldOptions; isLoading: boolean } => {
  const showTaxCodeField = useShowTaxCodeField();
  const showPostingTextField = useShowPostingTextField();
  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();
  const showExtraCostInfoField = useShowExtraCostInfoField();
  const showGeneralLedgerAccountField = useShowGeneralLedgerAccountField();
  const showArtistSocialInsuranceCodeField =
    useShowArtistSocialInsuranceCodeField();

  const { data: fieldItemsData, loading } = useProcessingFormFieldItemsQuery();

  const showAccountingAreaField = useShowAccountingAreaField({
    isAccountingAreaOnDocument: !isNilOrEmpty(
      initialValues.accountingArea?.value
    ),
  });

  const toArtistSocialInsuranceCodeFieldItem =
    useToArtistSocialInsuranceCodeFieldItem();

  const defaultGLAs = (initialValues?.bookings || [])
    .map(b => b?.generalLedgerAccount?.value)
    .filter(Boolean) as string[];

  const generalLedgerAccountFieldOptions = useGeneralLedgerAccountFieldOptions({
    skip: !showGeneralLedgerAccountField,
    ids:
      !showGeneralLedgerAccountField && defaultGLAs.length
        ? defaultGLAs
        : undefined,
  });

  const defaultCostCenters = (initialValues?.bookings || [])
    .map(b => b?.costCenter?.value)
    .filter(Boolean) as string[];

  const costCenterFieldOptions = useCostCenterFieldOptions({
    skip: !showCostCenterField,
    type: CostCenterTypes.CostCenter,
    ids:
      !showCostCenterField && defaultCostCenters.length
        ? defaultCostCenters
        : undefined,
  });

  const defaultCostObjects = (initialValues?.bookings || [])
    .map(b => b?.costObject?.value)
    .filter(Boolean) as string[];

  const costObjectFieldOptions = useCostCenterFieldOptions({
    skip: !showCostObjectField,
    type: CostCenterTypes.CostObject,
    ids:
      !showCostObjectField && defaultCostObjects.length
        ? defaultCostObjects
        : undefined,
  });

  const defaultCostInfos = (initialValues?.bookings || [])
    .map(b => b?.extraCostInfo?.value)
    .filter(Boolean) as string[];

  const extraCostInfoFieldOptions = useCostCenterFieldOptions({
    skip: !showExtraCostInfoField,
    type: CostCenterTypes.ExtraCostInfo,
    ids:
      !showExtraCostInfoField && defaultCostInfos.length
        ? defaultCostInfos
        : undefined,
  });

  const accountingAreaFieldOptions = useAccountingAreaFieldOptions({
    skip: !showAccountingAreaField,
    accountingAreas: fieldItemsData?.accountingAreas ?? [],
  });

  const { taxCodes = [] } = fieldItemsData ?? {};

  const fieldOptions: SplitBookingsFormFieldOptions = {
    artistSocialInsuranceCode: {
      hidden:
        !showArtistSocialInsuranceCodeField &&
        !initialValues?.bookings?.[0]?.artistSocialInsuranceCode,
      props: {
        defaultItems: Object.values(ArtistSocialInsuranceCode).map(
          toArtistSocialInsuranceCodeFieldItem
        ),
      },
    },
    costCenter: costCenterFieldOptions,
    costObject: costObjectFieldOptions,
    extraCostInfo: extraCostInfoFieldOptions,
    accountingArea: accountingAreaFieldOptions,
    generalLedgerAccount: generalLedgerAccountFieldOptions,
    postingText: {
      hidden:
        !showPostingTextField && !initialValues?.bookings?.[0]?.postingText,
      props: {},
    },
    taxCode: {
      hidden: !showTaxCodeField && !initialValues?.bookings?.[0]?.taxCode,
      props: { defaultItems: taxCodes.map(toAccountingDataFieldItem) },
    },
  };

  return { fieldOptions, isLoading: loading };
};
