import React from 'react';
import {
  mergeProps,
  SeparatorProps as AriaSeparatorProps,
  useSeparator,
} from 'react-aria';
import {
  ColorProps,
  LayoutProps,
  StandardHTMLAttributes,
  StandardInputHTMLAttributes,
} from '../../types';
import { Box } from '../Box';

export interface SeparatorProps
  extends Omit<StandardInputHTMLAttributes<HTMLDivElement>, 'children'>,
    LayoutProps,
    ColorProps {
  orientation?: AriaSeparatorProps['orientation'];
}

/**
 * A visual divider between two logically separate groups of content.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-layout-separator}
 *
 * @param {Orientation} [orientation=horizontal] Orientation of the separator
 * @param {color} [color=gray300] Color of the separator
 */
export const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>(
  ({ orientation = 'horizontal', color = 'gray300', ...restProps }, ref) => {
    const { separatorProps } = useSeparator({ orientation }) as {
      separatorProps: StandardHTMLAttributes<HTMLDivElement>;
    };

    return (
      <Box
        background={color}
        width={orientation === 'vertical' ? 1 : '100%'}
        height={orientation === 'vertical' ? '100%' : 1}
        {...mergeProps(separatorProps, restProps)}
        ref={ref}
      />
    );
  }
);
