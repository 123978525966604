import { Tooltip, useTooltip } from '@candisio/design-system';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const AcquisitionReverseCheckbox = ({
  disabled,
}: {
  disabled: boolean;
}) => {
  const { t } = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const { shouldUseSapTaxCodes } = useSap();

  // Should be moved to DS
  const { isOpen, triggerRef, triggerProps, tooltipRef, tooltipProps } =
    useTooltip({
      placement: 'top',
      isDisabled: !shouldUseSapTaxCodes,
    });

  return (
    <>
      <div ref={triggerRef} {...(shouldUseSapTaxCodes ? triggerProps : {})}>
        <HookFormCheckboxField
          name="isAcquisitionReverse"
          label={t('form.acquisitionReverse.label')}
          readOnly={shouldUseSapTaxCodes ? true : disabled}
        />
      </div>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('form.readOnlySyncHint')}
        </Tooltip>
      )}
    </>
  );
};
