import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { RejectedEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from 'views/Inbox/models';
import { CommentView, ItemApprovalStatus, RejectedIcon } from '../styles';
import closeImage from './images/iconApproveNot.svg';

export const DocumentRejectedComponent = ({
  comment,
  clientInfo,
}: RejectedEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <TimelineEventWithMobileIndication
      biggerPadding
      iconName="mobileReject"
      clientName={clientInfo?.name}
      eventComponent={
        <>
          <CommentView>
            <ItemApprovalStatus status={ApprovalStatus.DECLINED}>
              <RejectedIcon src={closeImage} alt={ApprovalStatus.DECLINED} />
              {t('document.compact.history.documentRejected')}
            </ItemApprovalStatus>
            {comment?.text}
          </CommentView>
        </>
      }
    />
  );
};
