import { Grid } from '@candisio/design-system';
import {
  DocumentStatus,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { userCanApprove, userIsMonitoring } from 'views/Approvals/utils';
import { Approved } from 'views/DocumentDetails/components/Approved/Approved';
import { Exported } from 'views/DocumentDetails/components/Exported/Exported';
import { Monitoring } from 'views/DocumentDetails/components/Monitoring';
import { New } from 'views/DocumentDetails/components/New';
import { Open } from 'views/DocumentDetails/components/Open';
import { Rejected } from 'views/DocumentDetails/components/Rejected';

interface DocumentTransactionDetailsProps {
  documentId: string;
  organizationSlug: string;
  cycleTransactions: () => void;
  onToggleEditDocumentMode: () => void;
}

export const DocumentTransactionDetails = ({
  documentId,
  organizationSlug,
  cycleTransactions,
  onToggleEditDocumentMode,
}: DocumentTransactionDetailsProps) => {
  const { data } = useGetDocumentForDraftQuery({
    variables: { id: documentId },
    skip: !documentId,
  });

  const history = useHistory();
  const currentUser = useCurrentUser();
  const redirectTo = (url: string) => history.push(url);

  const document = data?.getDocument;

  const componentArgs = {
    redirectTo,
    document,
    organizationSlug,
    toggleEditMode: onToggleEditDocumentMode,
    cycleDocument: cycleTransactions,
  };

  let Content = (_props: any): JSX.Element | null => null;

  if (document) {
    if (document.status === DocumentStatus.Open) {
      const isMonitoringView =
        userIsMonitoring(document, currentUser) ||
        !userCanApprove(document, currentUser);

      Content = isMonitoringView ? Monitoring : Open;
    }

    if (document.status === DocumentStatus.New) Content = New;
    if (document.status === DocumentStatus.Approved) Content = Approved;
    if (document.status === DocumentStatus.Exported) Content = Exported;
    if (document.status === DocumentStatus.Rejected) Content = Rejected;
  }

  return (
    <Grid as="section" overflow="hidden" height="100%">
      <Content key={documentId} {...componentArgs} />
    </Grid>
  );
};
