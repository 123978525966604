import { Grid, Heading } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ArchiveHeaderProps {
  children?: ReactNode;
}

export const ArchiveHeader = ({ children }: ArchiveHeaderProps) => {
  const [t] = useTranslation();
  const mainNavigationRefactorFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mainNavigationRefactor
  );

  return (
    <Grid
      autoFlow="column"
      alignItems="center"
      padding={
        mainNavigationRefactorFF
          ? 'space20 space32 space24 space32'
          : 'space40 space32 space24 space32'
      }
      gap="space24"
      templateColumns="auto 1fr">
      <Heading as="h1">{t('archive.title')}</Heading>
      {children}
    </Grid>
  );
};
