import { Card, Grid, Heading } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ContactSettingsLoader } from '../ContactSettings/ContactSettingsLoader';
import { EmployeesAPNSettingsForm } from './EmployeesAPNSettingsForm';
import { useSetEmployeesAccountsPayableNumber } from './useSetEmployeesAccountsPayableNumber';

export const EmployeesAPNSettingsContainer = () => {
  const [t] = useTranslation();

  const {
    loadingInitialValues,
    isSettingAccountsPayable,
    setStartValue,
    enabled,
    setEnabled,
    startValue,
  } = useSetEmployeesAccountsPayableNumber();

  if (loadingInitialValues) {
    return <ContactSettingsLoader />;
  }

  return (
    <Grid gap="space16">
      <Heading as="h3">{t('settings.contacts.employeeContact.header')}</Heading>
      <Card paddingX="space24" paddingY="space16">
        <EmployeesAPNSettingsForm
          enabled={enabled}
          setEnabled={setEnabled}
          startValue={startValue}
          setStartValue={setStartValue}
          isSettingAccountsPayable={isSettingAccountsPayable}
        />
      </Card>
    </Grid>
  );
};
