import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useExportContext } from '../Context';
import { ContactTableColumnKeys } from '../elements/table/contacts/types';
import { PaymentConditionsTableColumnKeys } from '../elements/table/paymentConditions/types';
import { ExportReimbursementItemsTableColumnKeys } from '../elements/table/reimbursementItems/types';
import { View } from '../types';
import { useGetDefaultConfigurationsMappedToTableType } from './useGetDefaultConfigurationsMappedToTableType';

interface UseGetColumnIdsParams {
  tableId: string;
}

export const useGetColumnIds = <
  TableRowType extends
    | DocumentTableColumnKeys
    | ContactTableColumnKeys
    | PaymentConditionsTableColumnKeys
    | ExportReimbursementItemsTableColumnKeys
>({
  tableId,
}: UseGetColumnIdsParams) => {
  const { view, table, entity } = useExportContext();

  const { defaultColumnsConfigurations, defaultColumns } =
    useGetDefaultConfigurationsMappedToTableType({ tableType: table, entity });

  const { configurations } = useGetTableConfigurations({
    tableId,
    defaultColumnsConfigurations,
  });

  if (view === View.HISTORY) {
    return defaultColumns as TableRowType[];
  }

  const visibleColumnIds = configurations
    .filter(config => config.isVisible)
    .map(config => config.id) as TableRowType[];

  return visibleColumnIds;
};
