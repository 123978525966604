import {
  Box,
  Grid,
  ScrollBox,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { mappedTypesToItems } from '../LeftSection/ReimbursementItem';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';

interface ReimbursementItemsSummaryProps {
  reimbursmentItems: ReimbursementItemListLeftSection[];
}

export const ReimbursementItemsSummary = ({
  reimbursmentItems,
}: ReimbursementItemsSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { setSelectedItemId } = useReimbursementFormsContext();

  const handleClick = (id: string) => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    setSelectedItemId(id);
  };

  const zeroAmountPlaceholder = t(
    'splitBookings.splitAmount.placeholder.notSet'
  );

  return (
    <ScrollBox
      borderBottomRadius="medium"
      scrollDirection="y"
      scrollbarGutter="auto"
      maxHeight="250px">
      <Box as="ul" listStyle="none" padding={0}>
        {reimbursmentItems.map((item, index) => {
          const { id, type, reason, totalAmount } = item;
          const itemType = mappedTypesToItems[type];
          const amount = totalAmount ? totalAmount : zeroAmountPlaceholder;
          const text = reason ? reason : t(itemType.placeholder);

          const isLastItem = index === reimbursmentItems.length - 1;

          return (
            <Box
              as="li"
              listStyle="none"
              key={id}
              borderBottom={!isLastItem ? '1px solid gray250' : 'none'}>
              <Grid
                padding="space8 space16"
                templateColumns="81.5px 1fr"
                cursor="pointer"
                alignItems="center"
                background="gray0"
                hover={{ background: 'gray50' }}
                onClick={() => handleClick(item.id)}>
                <Box />
                <Grid
                  templateColumns="auto 1fr auto"
                  alignItems="center"
                  gap="space8">
                  {itemType.icon}
                  <TruncatedText fontSize="basic">{text}</TruncatedText>
                  <Text justifySelf="end" fontSize="basic">
                    {amount}
                  </Text>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </ScrollBox>
  );
};
