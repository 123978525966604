import { Flex, Text } from '@candisio/design-system';
import checkImage from 'assets/icon-check.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ReimbursementCaseApproved = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  return (
    <Flex alignItems="center">
      <img src={checkImage} alt={''} />
      <Text color="green500">{t('timeline.approved')}</Text>
    </Flex>
  );
};
