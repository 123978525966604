import {
  Button,
  ButtonProps,
  mergeProps,
  usePopover,
} from '@candisio/design-system';
import {
  ConfirmationPopover,
  ConfirmationPopoverProps,
} from 'components/ConfirmationPopover/ConfirmationPopover';

export interface ConfirmationButtonProps {
  /** Custom props for close button */
  closeButtonProps?: ConfirmationPopoverProps['closeButtonProps'];
  /** Close button text */
  closeText?: ConfirmationPopoverProps['closeText'];
  /** Custom props for confirm button */
  confirmButtonProps?: ConfirmationPopoverProps['confirmButtonProps'];
  /** Confirmation title text */
  confirmationTitle?: ConfirmationPopoverProps['title'];
  /** Confirmation popover width */
  confirmationPopoverWidth?: ConfirmationPopoverProps['width'];
  /** Confirm button text */
  confirmText?: ConfirmationPopoverProps['confirmText'];
  /** Called when action is confirmed */
  onConfirm: ConfirmationPopoverProps['onConfirm'];
  /** Button is disabled */
  mainButtonLabel?: string;
}

/** A Button with a confirmation popover */
export const ConfirmationButton = ({
  children,
  closeButtonProps,
  closeText,
  confirmationTitle,
  confirmationPopoverWidth,
  confirmButtonProps,
  confirmText,
  disabled,
  mainButtonLabel,
  onConfirm,
  ...restProps
}: ConfirmationButtonProps & ButtonProps) => {
  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover();

  return (
    <>
      <Button
        disabled={disabled}
        label={mainButtonLabel}
        {...mergeProps(triggerProps, restProps)}
        ref={triggerRef}>
        {children}
      </Button>
      {!disabled && isOpen && (
        <ConfirmationPopover
          width={confirmationPopoverWidth}
          closeButtonProps={closeButtonProps}
          closeText={closeText}
          confirmButtonProps={confirmButtonProps}
          confirmText={confirmText}
          onConfirm={onConfirm}
          title={confirmationTitle}
          {...popoverProps}
          ref={popoverRef}
        />
      )}
    </>
  );
};
