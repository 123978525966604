import {
  DateFilterInput,
  DateFilterOption,
  ReimbursementCaseFiltersInput,
} from 'generated-types/graphql.types';
import { Filters } from 'react-table';
import { ReimbursementTableRow } from '../toolkit/types';

export const REIMBURSEMENT_TABLE_FILTERS = {
  status: 'status',
} as const;

const mapToDateRangeFilters = (dateFilter: string[]): DateFilterInput => {
  const activeFilterValues = dateFilter?.[0] as string;
  const [from, to] = activeFilterValues?.split('-');

  return {
    dateFrom: from,
    dateTo: to,
    filterOption: DateFilterOption.Custom,
  };
};

export const mapToReimbursementsFilterInput = (
  filters: Filters<ReimbursementTableRow>,
  defaultBEFilters: ReimbursementCaseFiltersInput = {}
): ReimbursementCaseFiltersInput => {
  const mappedFilterInput = filters.reduce<ReimbursementCaseFiltersInput>(
    (filterInput, filter) => {
      const existingDateFilter = filterInput.dateRangeFilters;
      if (filter.id === 'status') {
        return {
          ...filterInput,
          statuses: filter.value,
        };
      }

      if (filter.id === 'title') {
        return {
          ...filterInput,
          titles: filter.value,
        };
      }

      if (filter.id === 'iban') {
        return {
          ...filterInput,
          ibans: filter.value,
        };
      }

      if (filter.id === 'isPaid') {
        if (
          (filter.value ?? []).length === 2 ||
          (filter.value ?? []).length === 0
        ) {
          return {
            ...filterInput,
            isPaid: null,
          };
        } else if (filter.value?.[0] === 'Paid') {
          return {
            ...filterInput,
            isPaid: true,
          };
        } else {
          return {
            ...filterInput,
            isPaid: false,
          };
        }
      }

      if (filter.id === 'paidAt') {
        return {
          ...filterInput,
          dateRangeFilters: {
            ...existingDateFilter,
            paidAt: mapToDateRangeFilters(filter.value),
          },
        };
      }

      if (filter.id === 'createdAt') {
        return {
          ...filterInput,
          dateRangeFilters: {
            ...existingDateFilter,
            createdAt: mapToDateRangeFilters(filter.value),
          },
        };
      }

      return filterInput;
    },
    defaultBEFilters
  );

  return mappedFilterInput;
};
