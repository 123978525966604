import { Text, TruncatedText } from '@candisio/design-system';
import { castArray } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DateFormatters } from 'utils/date_formatter';
import { DocumentsTableData } from '../types';

type Value = (Date | undefined)[];

export const DateCell = ({ value }: CellProps<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const values = castArray(value);

  if (values.every(v => !v)) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const allValuesAreTheSame = values.every(
    v => v?.getTime() === values?.[0]?.getTime()
  );

  if (values.length > 1 && !allValuesAreTheSame) {
    return (
      <TruncatedText color="gray600" wordBreak="break-all" fontStyle="italic">
        {t('seeSplitBookings')}
      </TruncatedText>
    );
  }

  return (
    <TruncatedText wordBreak="break-all">
      {DateFormatters.compact(values[0] as Date)}
    </TruncatedText>
  );
};
