import {
  DocumentWorkflowStatusDataFragment,
  WorkflowStepResolutionTypes,
} from 'generated-types/graphql.types';

const isUserInActiveStep = (
  stepIndex: number,
  document?: DocumentWorkflowStatusDataFragment | null,
  userId?: string
): boolean => {
  const step = document?.workflow?.steps?.[stepIndex];

  const isStepActive =
    step?.resolution?.type === WorkflowStepResolutionTypes.Pending &&
    step.id === document?.currentWorkflowStep?.id;

  const userIsApproverInStep = Boolean(
    step?.approvers?.find(approver => {
      return approver.id === userId || approver.substitute?.id === userId;
    })
  );

  return Boolean(isStepActive && userIsApproverInStep);
};

export const useUserInActiveStep = () => {
  const isUserInActiveFirstWFStep = (
    document?: DocumentWorkflowStatusDataFragment | null,
    userId?: string
  ) => isUserInActiveStep(0, document, userId);

  const isUserInActiveLastWFStep = (
    document?: DocumentWorkflowStatusDataFragment | null,
    userId?: string
  ) => {
    if (!document?.workflow?.steps?.length) {
      return false;
    }

    const lastStep = document?.workflow?.steps?.length - 1;

    return isUserInActiveStep(lastStep, document, userId);
  };

  return { isUserInActiveFirstWFStep, isUserInActiveLastWFStep };
};
