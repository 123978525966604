import { Grid, TruncatedText } from '@candisio/design-system';
import { CashDiscountFieldValue } from 'components/Form/types';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { InfoOutlineIcon } from 'components/Icons/InfoOutlineIcon';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

export const CashDiscount = ({
  cashDiscount,
  currency,
}: {
  cashDiscount?: CashDiscountFieldValue;
  currency: string;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CASH_DISCOUNTS);

  return (
    <Grid autoFlow="column" gap="space8">
      <HookFormTextField
        name="cashDiscount.dueDate"
        inputProps={{
          value: cashDiscount?.dueDate
            ? DateFormatters.compact(new Date(cashDiscount?.dueDate))
            : undefined,
        }}
        label={<TruncatedText>{t('fields.dueDate.label')}</TruncatedText>}
        readOnly
      />
      <HookFormPercentField
        name="cashDiscount.percentage"
        label={<TruncatedText>{t('fields.percentage.label')}</TruncatedText>}
        readOnly
      />
      <Grid gap="space8" templateColumns="1fr auto">
        <HookFormAmountField
          name="cashDiscount.amount"
          currency={currency}
          label={<TruncatedText>{t('fields.amount.label')}</TruncatedText>}
          readOnly
        />
      </Grid>
      <InfoOutlineIcon
        message={t('fields.amount.info')}
        disableTabbing
        style={{ marginTop: '28px' }}
      />
    </Grid>
  );
};
