import { Box, CustomEmptyStateProps } from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { queryParameter } from 'components/Table/consts';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { TransactionsTableEmptyState } from 'components/Transactions/Table/components/TransactionsTableStates/components/TransactionsTableEmptyState';
import { TransactionsTable } from 'components/Transactions/Table/TransactionsTable';
import {
  OnRowClick,
  TransactionsTableData,
  TransactionsTableProps,
  ViewUsingTransactions,
} from 'components/Transactions/Table/types';
import { useCardIssuerTransactionsData } from 'components/Transactions/Table/useCardIssuerTransactionsData';
import {
  availableFilters,
  transactionToTableData,
} from 'components/Transactions/Table/util';
import { CreditCardBannerContainer } from 'containers/credit-cards/components/CreditCardBannerContainer/CreditCardBannerContainer';
import { creditCardInfoHash } from 'containers/credit-cards/components/CreditCardsSection/utils';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { TransactionPreviewContainer } from 'containers/DocumentPreview/TransactionPreviewContainer';
import { ACTIVATION_LOCAL_STORAGE_KEY } from 'containers/DocumentPreview/useDocumentPreviewActivationPopover';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { usePreviewUpsellSeen } from 'containers/DocumentPreviewUpsell/usePreviewUpsellSeen';
import { InvoiceAssociationStatus } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { compiledRoutes, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Use v5 version of useParams because we’re inside a v5 route
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { appendParamsToQueryString } from 'utils/url-helper';
import { CreditCardInfoModal } from 'views/Dashboard/components/CreditCardInfoModal';
import { TabView } from 'views/Inbox/models';
import {
  TRANSACTION_FILTER,
  useTransactionListFilters,
} from 'views/Inbox/Transactions/useTransactionListFilters';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { useDownloadCSVFile } from './hooks/useDownloadCSVFile';
import { useGetArchiveTransactionsTableConfigs } from './hooks/useGetArchiveTransactionsTableConfigs';

type Props = RouteComponentProps<{ organizationSlug: string }>;

export const Transactions = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const [tableRowLinkFF] = useCandisFeatureFlags([FEATURE_FLAGS.tableRowLink]);
  const { showDocumentPreview, showDocumentPreviewPromo } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const { previewUpsellSeen, markPreviewUpsellSeen } = usePreviewUpsellSeen();

  const [wasActivationSeen] = useLocalStorage(
    ACTIVATION_LOCAL_STORAGE_KEY,
    false
  );

  const isAutoOpened = useRef(wasActivationSeen);
  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    null
  );

  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const history = useHistory();
  const navigate = useNavigate();
  const openModal = location.hash === creditCardInfoHash;
  const { updateSearchParam, searchParams } = useMutateSearchParams();

  const { filterOptions, isFilterLoading } = useTransactionListFilters({
    filters: [
      TRANSACTION_FILTER.status_archive,
      TRANSACTION_FILTER.invoiceAssociationStatus_archive,
      TRANSACTION_FILTER.cardholderName,
      TRANSACTION_FILTER.type,
      TRANSACTION_FILTER.cardRefNum,
      TRANSACTION_FILTER.category,
    ],
    fetchCardholdersWithTransactions: true,
  });

  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<TransactionsTableData>({
      availableFilters,
    });

  const {
    availableTransactionColumnIds,
    configurationsTable,
    isResetPending,
    isSavingConfigurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetArchiveTransactionsTableConfigs({ filters, sortBy });

  const { downloadCSV, isCreateCSVFilePending } = useDownloadCSVFile();

  const {
    transactionList,
    isLoadingTransactionList: isLoading,
    onLoadMore,
    isTableEmpty,
    isTableFiltered,
    handleDebounceSearch,
    transactionListCount,
  } = useCardIssuerTransactionsData({
    routeType: ViewUsingTransactions.ARCHIVE,
    filters,
    sortBy,
  });

  const handleDownloadCSV = useCallback(async () => {
    if (isLoading) {
      error(t('table.toolbar.downloadMessage.waitForLoading'));
    }

    const response = await downloadCSV();

    if (response.status === 'success') {
      success(t('table.toolbar.downloadMessage.success'));
    } else {
      error(t('table.toolbar.downloadMessage.error'));
    }
  }, [downloadCSV, error, isLoading, success, t]);

  const handleClick = ({ id, cursor }: OnRowClick) => {
    if (tableRowLinkFF) return;

    const searchWithCursor = appendParamsToQueryString(
      history.location.search,
      { cursor: cursor ?? '' }
    );

    history.push(
      `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/${id}?${searchWithCursor}`
    );
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <TransactionsTableEmptyState
        isTableFiltered={isTableFiltered}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<TransactionsTableData>) => {
      onFilter(filterVal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<TransactionsTableData>[]) => {
      onSort(sortVal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSort]);

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const transactionsTableData = useMemo(
    () => transactionToTableData(transactionList ?? [], { previewDocumentId }),
    [transactionList, previewDocumentId]
  );

  const transactionWithDocument = useMemo(
    () =>
      transactionsTableData.find(t =>
        hasPdf(t.invoiceAssociationStatus ?? undefined)
      ),
    [transactionsTableData]
  );

  const isDocumentPreviewReady =
    !isLoading && previewDocumentId === null && !!transactionWithDocument;

  useEffect(() => {
    if (!isDocumentPreviewReady) return;

    const shouldAutoOpenPreview =
      (showDocumentPreviewPromo && !previewUpsellSeen) ||
      (!showDocumentPreviewPromo && !isAutoOpened.current);

    if (!shouldAutoOpenPreview) return;

    setPreviewDocumentId(transactionWithDocument.id);
    if (!showDocumentPreviewPromo) isAutoOpened.current = true;
  }, [
    transactionsTableData,
    isDocumentPreviewReady,
    previewUpsellSeen,
    showDocumentPreviewPromo,
    transactionWithDocument,
  ]);

  const documentPreviewRowOverlay: TransactionsTableProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>;

              const isPdfAvailable = hasPdf(
                data.invoiceAssociationStatus ?? undefined
              );

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    setPreviewDocumentId(data.id);
                  }}
                  isSelected={data.id === previewDocumentId}
                  disabledText={
                    !isPdfAvailable
                      ? t('common:documentPreview.transactionNoPdf')
                      : undefined
                  }
                />
              );
            }
          : undefined,
      [showPreview, previewDocumentId, t]
    );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);

      const path = `/${organizationSlug}${compiledRoutes.allTransactions}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocumentId(null);
    if (!previewUpsellSeen) {
      markPreviewUpsellSeen();
    }
  }, [markPreviewUpsellSeen, previewUpsellSeen]);

  const cursor =
    transactionsTableData.find(d => d.id === previewDocumentId)?.cursor ?? null;

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
        />
      );
    }

    if (showDocumentPreview) {
      return (
        <DocumentPreviewDrawer
          documentId={previewDocumentId}
          documentPreviewView={TransactionPreviewContainer}
          closePreviewDrawer={closePreview}
          openDocument={() =>
            openDocument({ documentId: previewDocumentId, cursor })
          }
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    closePreview,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({ id, cursor }: TransactionsTableData) => {
      const searchWithCursor = appendParamsToQueryString(
        history.location.search,
        { cursor: cursor ?? '' }
      );

      return `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/${id}?${searchWithCursor}`;
    },
    [history.location.search, organizationSlug]
  );

  const cellWrapper = useMemo(
    () => (tableRowLinkFF ? getCellWrapperLink(getPath) : undefined),
    [tableRowLinkFF, getPath]
  );

  const cellStyle = tableRowLinkFF ? () => ({ padding: 'unset' }) : undefined;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_TRANSACTIONS}
      rightSide={documentPreviewDrawer}>
      <CreditCardBannerContainer routeType="Archive">
        <Box height="100%" overflow="hidden">
          <TransactionsTable
            key={`tx-table-${isFilterLoading}-${availableTransactionColumnIds.join(
              '-'
            )}`}
            onEndReached={onLoadMore}
            columns={availableTransactionColumnIds}
            onRowClick={handleClick}
            data={transactionsTableData}
            isLoading={isLoading}
            filterOptions={filterOptions}
            onSort={handleSort}
            onSearch={handleSearch}
            search={queryStringFilter}
            onFilter={handleFilter}
            defaultFilters={filters}
            customEmptyState={emptyState}
            defaultSortBy={sortBy}
            isSavingConfigurations={isSavingConfigurations || isResetPending}
            configurations={configurationsTable}
            onUpdateConfigurations={handleUpdateConfigurations}
            onResetTableConfigurations={handleResetTableConfigurations}
            onDownloadCSV={handleDownloadCSV}
            isDownloadingCSV={isCreateCSVFilePending}
            downloadableTransactionsCount={transactionListCount}
            borderTopRadius="none"
            rowOverlay={documentPreviewRowOverlay}
            cellWrapper={cellWrapper}
            getCellStyles={cellStyle}
          />
        </Box>
      </CreditCardBannerContainer>
      <CreditCardInfoModal
        isOpen={openModal}
        onClose={() => {
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            { replace: true }
          );
        }}
        routeType="Archive"
      />
    </ArchiveViewLayout>
  );
};

const hasPdf = (invoiceAssociationStatus?: InvoiceAssociationStatus) =>
  invoiceAssociationStatus === InvoiceAssociationStatus.Uploaded ||
  invoiceAssociationStatus === InvoiceAssociationStatus.AutoMatched;
