import { Grid, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import {
  SplitActionTypes,
  SplitBookingUpdatedEvent,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { isNil } from 'lodash';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormatWithoutCurrency } from 'utils/format';
import { MetaDataWrapper } from '../MetaDataWrapper';

export const getChangeType = (action?: SplitActionTypes | null) => {
  if (action === SplitActionTypes.Add) return 'created';
  if (action === SplitActionTypes.Update) return 'updated';
  if (action === SplitActionTypes.Remove) return 'deleted';

  return '';
};

export const SplitBookingUpdatedComponent = ({
  bookings,
}: SplitBookingUpdatedEvent) => {
  const [tCommon] = useTranslation();
  const title = tCommon('document.compact.history.bookings.split.title', {
    count: bookings.length,
  });

  return (
    <Grid>
      <Text fontSize="small" lineHeight="paragraph">
        {title}
      </Text>
      <Collapse>
        <MetaDataWrapper>
          {bookings.map((booking, index) => {
            return (
              <Grid gap="space8" key={index}>
                <Text
                  color="gray600"
                  fontSize="small"
                  lineHeight="paragraph"
                  fontWeight="semibold">
                  {tCommon(
                    'document.compact.history.bookings.split.splitTitle',
                    {
                      splitNumber: booking.number,
                      count: booking.fields.length,
                      // eslint-disable-next-line candis/no-template-strings-inside-translation
                      changeType: tCommon(
                        `document.compact.history.bookings.split.changeTypes.${getChangeType(
                          booking.action
                        )}`
                      ),
                    }
                  )}
                </Text>
                {booking.action !== SplitActionTypes.Remove && (
                  <Grid paddingLeft="space24" gap="space8">
                    {booking.fields.map(field => {
                      return (
                        <BookingField
                          key={field.key}
                          fieldKey={field.key}
                          value={field.value}
                          // eslint-disable-next-line candis/no-template-strings-inside-translation
                          label={tCommon(
                            `document.compact.history.bookings.split.fields.${field.key}`,
                            {
                              context: getTranslationContext(),
                            }
                          )}
                        />
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            );
          })}
        </MetaDataWrapper>
      </Collapse>
    </Grid>
  );
};

const dateFields = new Set(['dueDate', 'discountPaymentDate']);
const amountFields = new Set([
  'amount',
  'discountAmount',
  'netAmount',
  'taxAmount',
  'unitPrice',
]);

export const BookingField = ({
  fieldKey,
  value,
  label,
}: {
  fieldKey: string;
  value?: string | null;
  label: string;
}) => {
  const [tCommon] = useTranslation();
  const { friendlyFormatGLA, normalizeGLA } = useAccountingNumberFormatters();
  const valueRemovedText = tCommon(
    'document.compact.history.bookings.valueRemoved'
  );

  let displayValue = value;

  if (isNil(value)) displayValue = valueRemovedText;
  else if (dateFields.has(fieldKey))
    displayValue = DateFormatters.compact(new Date(value));
  else if (amountFields.has(fieldKey))
    displayValue = amountFormatWithoutCurrency(Number(value));
  else if (value === 'true' || value === 'false')
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    displayValue = tCommon(`document.compact.history.bookings.${value}`);
  else if (fieldKey === 'artistSocialInsuranceCode')
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    displayValue = tCommon(
      `document.requestApproval.inputs.artistSocialInsuranceCode.codes.${value}`
    );
  else if (fieldKey === 'generalLedgerAccount') {
    const [code, name] = normalizeGLA(value);
    displayValue = `${friendlyFormatGLA(code)} - ${name}`;
  }

  return (
    <Grid>
      <Text
        color="gray500"
        lineHeight="paragraph"
        fontWeight="regular"
        fontSize="small">
        {label}
      </Text>
      <Text color="gray800" fontSize="small" lineHeight="paragraph">
        {displayValue}
      </Text>
    </Grid>
  );
};
